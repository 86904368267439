import { hasOrderId } from "@/utilities/collection/addtocart";

export function getOrderId(userID) {
  let result = `${userID}-${getTodayDate()}${makeid(2)}`;
  let crc = 0;
  for (let i = 0; i < result.length; i++) {
    crc += result.charCodeAt(i);
  }
  crc %= 10;
  return result + String(crc);
}

function getTodayDate() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();

  return yyyy + mm + dd;
}

function passThroughOrderId(orderId) {
  return Promise.resolve(orderId);
}

export function getUniqueOrderId(userId) {
  return new Promise((resolve, reject) => {
    let orderId = getOrderId(userId);

    hasOrderId(orderId)
      .then(result => {
        return result ? getUniqueOrderId(orderId) : passThroughOrderId(orderId);
      })
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
}

function makeid(length) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
