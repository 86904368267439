<template>
  <div>
    <AppHeader header="e-Konsulta" />

    <v-container fluid v-show="!callEnded">
      <v-btn class="pt-0" color="primary" @click="endCall">
        End Call
      </v-btn>
      <video ref="localCam" playsinline autoplay></video>
    </v-container>

    <v-container fluid v-show="callStarted">
      <video ref="doctorCam" playsinline autoplay></video>
    </v-container>

    <v-container fluid v-if="!callStarted && !callEnded">
      Your doctor will be with you shortly. Please wait.
    </v-container>

    <v-container fluid v-if="callEnded">
      Thank you for choosing MediSure Telemedicine
      <BackButton @click="$emit('back')" class="mt-3" />
    </v-container>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import BackButton from "@/components/Controls/BackButton.vue";
import CallUtil from "@/utilities/rtc/CallUtil";

export default {
  name: "DoctorTeleconference",

  components: {
    AppHeader,
    BackButton
  },

  data: () => ({
    callStarted: false,
    callEnded: false
  }),

  methods: {
    endCall() {
      if (this.callUtil && this.callId) {
        this.callUtil.end(this.callId);
      }
    },

    onCallEnded() {
      this.callStarted = false;
      this.callEnded = true;
    },

    onLocalStream(stream) {
      this.$refs.localCam.srcObject = stream;
      this.$refs.localCam.volume = 0.0;
    },

    onRemoteStream(stream) {
      this.doctorStream = stream;
      this.callStarted = true;
    }
  },

  watch: {
    callStarted(hasStarted) {
      if (hasStarted) {
        this.$refs.doctorCam.srcObject = this.doctorStream;
      }
    }
  },

  created() {
    this.callUtil = new CallUtil(
      "teleconsultCalls",
      this.onLocalStream,
      this.onRemoteStream,
      this.onCallEnded
    );
  },

  async mounted() {
    const callInfo = {
      id: null,
      timestamp: new Date().toUTCString(),
      userCaller: "patient", // obviously this needs to be replaced with an id that identifies the user
      userCallee: "doctor" // and this one should be the user id of the doctor
    };
    this.callId = await this.callUtil.create(callInfo);
    this.callUtil.setupSnapshotCallbacks(this.callId);
  }
};
</script>
