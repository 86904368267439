const SM = 375;
const MD = 540;
const LG = 760;
const XL = 1000;

export function isLGOrSmaller(width) {
  return width <= LG;
}

export function isSmallerThanMD(width) {
  return width < MD;
}

export function isSmallerThanSM(width) {
  return width < SM;
}

export function isSMOrSmaller(width) {
  return width <= SM;
}

export function isXLOrLarger(width) {
  return width >= XL;
}

export function setWidth(width, xl, lg, md, sm, fallthrough) {
  if (width >= XL) {
    return xl;
  } else if (width >= LG) {
    return lg;
  } else if (width >= MD) {
    return md;
  } else if (width >= SM) {
    return sm;
  }

  return fallthrough;
}
