import { DB } from "@/common/store";
import { ORDER_STATUS_RETURN } from "@/common/utilities/order";
import {
  STATUS_REFUND_DECLINED,
  STATUS_REFUND_REQUESTED,
  STATUS_REFUND_VERIFY
} from "@/common/utilities/payment";

import { db } from "@/main";
import { getAddress, getFullAddress3 } from "@/common/utilities/location";
import { getPaymentMethodsDisplay } from "./payments";

export const FOR_DELIVERY = "For Delivery";
export const FOR_PICK_UP = "For Pickup";

function getDeliveryAddress(data) {
  return getFullAddress3(data.selectedLocation) + getAddress(data);
}

export function setTransactionHistory(checkout, timestamp, comment) {
  db.collection(DB.DELIVERY).add({
    timestamp: timestamp,
    courier: checkout.courier,
    location: getDeliveryAddress(checkout),
    comment: comment,
    orderid: checkout.id,
    fullqr: checkout.fullqr,
    trackingNumber: checkout.trackingNumber,
    mop: getPaymentMethodsDisplay(checkout.payments)
  });
}

export function returnRefundDisplaybyStatus(latestReturnRefund) {
  const reinstateOption = latestReturnRefund.reinstateOption;
  const status = latestReturnRefund.status;
  const line = `${this.capsFL(reinstateOption)} was ${this.capsFL(status)}.`;

  switch (status) {
    case STATUS_REFUND_DECLINED:
      return `${line}`;

    case STATUS_REFUND_REQUESTED:
      return `${line} Process may take 24hrs from Submission`;

    case STATUS_REFUND_VERIFY:
      return ORDER_STATUS_RETURN === reinstateOption
        ? `${line} Please return the item within a week`
        : "";

    default:
      return "";
  }
}
