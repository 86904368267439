<template>
  <v-card class="elevation-12 pb-8">
    <v-toolbar color="green" dark flat>
      <v-toolbar-title class="centerThis">
        Subscription Information
      </v-toolbar-title>
    </v-toolbar>

    <SummaryElement
      class="mt-8"
      header="Premium"
      :content="toMoneyString(insurances[index].premium)"
    />

    <SummaryElement
      class="mt-n3"
      header="Valid Until"
      :content="showTimeStamp(insurances[index].status.expiration.seconds)"
    />

    <div class="mt-6 mb-2 boldThis centerThis">Benefits</div>

    <div v-for="benefit in benefits" :key="benefit.name">
      <BenefitElement
        class="mt-n3"
        :header="benefit.name"
        :content="benefit.amount"
      />
    </div>

    <div class="mt-6 mb-n4 boldThis centerThis">Beneficiaries</div>

    <div v-for="beneficiary in beneficiaries" :key="beneficiary.fullName">
      <SummaryElement
        class="mt-3"
        header="Name of Beneficiary"
        :content="beneficiary.fullName"
      />

      <SummaryElement
        class="mt-n3"
        header="Relationship"
        :content="beneficiary.relationship"
      />

      <SummaryElement
        class="mt-n3"
        header="Date of Birth"
        :content="beneficiary.birthDate"
      />

      <SummaryElement
        class="mt-n3"
        header="Beneficiary Type"
        :content="beneficiary.isRevocable ? 'Revocable' : 'Irrevocable'"
      />
    </div>
  </v-card>
</template>

<script>
import { loadUserValidationData } from "@/store/user_validation";

import BenefitElement from "./BenefitElement";
import SummaryElement from "./SummaryElement";

export default {
  name: "InsuranceSubscriptionInfo",

  components: {
    BenefitElement,
    SummaryElement
  },

  props: {
    index: Number,
    insurances: Array
  },

  computed: {
    beneficiaries() {
      return this.insurances[this.index].beneficiaries;
    },

    benefits() {
      return this.insurances[this.index].benefits;
    }
  },

  data: () => ({
    dialog: false,
    userData: {}
  }),

  mounted() {
    loadUserValidationData().then(data => {
      this.userData = { ...data };

      if (!this.userData.lastname) {
        this.userData.lastname = "";
      }

      if (!this.userData.sex) {
        this.userData.sex = "";
      }

      if (!this.userData.maritalStatus) {
        this.userData.maritalStatus = "";
      }
    });
  },

  methods: {
    showTimeStamp(timestamp) {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];

      let datetime = new Date(timestamp * 1000);
      return `${
        month[datetime.getMonth()]
      } ${datetime.getDate().toString()} ${datetime.getFullYear().toString()}`;
    },

    toMoneyString(number) {
      if (!number) {
        number = 0;
      }

      let balance = number.toFixed(2);
      for (let i = 6; balance.length > i; i += 4) {
        balance = `${balance.slice(0, -1 * i)},${balance.slice(-1 * i)}`;
      }

      return `₱ ${balance}`;
    }
  }
};
</script>

<style scoped>
.boldThis {
  font-weight: bold;
}

.centerThis {
  margin: auto;
  text-align: center;
}
</style>
