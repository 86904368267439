<template>
  <v-dialog ref="dialogs" v-model="modal" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="displayTime"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>

    <v-time-picker v-show="modal" v-model="time" full-width>
      <v-spacer />

      <v-btn text color="primary" @click="modal = false">
        Cancel
      </v-btn>

      <v-btn
        text
        color="primary"
        @click.stop="$emit('onSelect', time)"
        @click="$refs.dialogs.save(time)"
      >
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import { displayAmPmTime } from "../../utilities/time";

export default {
  name: "SelectTime",

  props: {
    initialValue: String,
    label: String
  },

  data: () => ({
    modal: false,
    time: null
  }),

  mounted() {
    this.time = this.initialValue;
  },

  computed: {
    displayTime() {
      return displayAmPmTime(this.time);
    }
  }
};
</script>
