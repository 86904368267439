<template>
  <v-row>
    <v-col cols="1" />
    <v-col class="mt-n4" cols="10">
      <v-select
        return-object
        v-model="service"
        @change="serviceSelected"
        :items="services"
        item-text="displayName"
        label="Serbisyong nais mapakinabangan"
      />
    </v-col>
  </v-row>
</template>

<script>
import { DB } from "@/common/store";

import { db } from "@/main";

export default {
  name: "SelectService",

  props: {
    subheader: String
  },

  data: () => ({
    service: null,
    services: []
  }),

  mounted() {
    this.reload();
  },

  methods: {
    getServices(payload) {
      return new Promise((resolve, reject) => {
        db.collection(DB.LGU_SERVICES)
          .where("location", "==", payload.location)
          .get()
          .then(snapshot => {
            let services = [];

            snapshot.forEach(doc => {
              let data = doc.data();
              services.push({
                id: doc.id,
                name: data.name,
                location: data.location,
                serviceLocation: data.serviceLocation
              });
            });

            resolve(services);
          })
          .catch(err => reject(err));
      });
    },

    reload() {
      this.getServices({
        location: this.subheader
      }).then(docs => {
        this.service = null;
        this.services = [];

        for (let i in docs) {
          this.services.push({
            displayName: `${docs[i].name} sa ${docs[i].serviceLocation}`,
            id: docs[i].id,
            location: docs[i].serviceLocation,
            name: docs[i].name
          });
        }
      });
    },

    serviceSelected() {
      this.$emit("setSelectedService", this.service);
    }
  }
};
</script>
