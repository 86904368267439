<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="value"
          :label="text"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </template>

      <v-date-picker
        :value="value"
        :active-picker.sync="activePicker"
        :max="
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        "
        min="1900-01-01"
        @change="save($event)"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "SimpleDatePicker",

  props: {
    value: String,
    text: String
  },

  data: () => ({
    activePicker: null,
    menu: false
  }),

  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  },

  methods: {
    save(date) {
      this.$refs.menu.save(date);
      this.$emit("input", date);
    }
  }
};
</script>
