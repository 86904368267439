<template>
  <v-card flat align="center" class="mx-auto my-10" max-width="500">
    <div class="mb-5">
      MediSure Web App version {{ VERSION }}
      <span v-if="!isUpdated" class="red--text">
        <br />
        (Clear browser cache and reload page)
      </span>
    </div>

    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item>
          <v-img
            src="@/assets/home_services.jpg"
            max-height="30"
            max-width="30"
          />
          <v-img
            src="@/assets/offsite_services.jpg"
            max-height="30"
            max-width="30"
          />
          <v-img
            src="@/assets/walk_in_services.jpg"
            max-height="30"
            max-width="30"
          />

          <v-list-item-content>
            <v-list-item-title>
              Offsite
              <a href="http://www.freepik.com">Designed by stories / Freepik</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { NS_VERSION } from "@/store";
import { THIS_VERSION } from "@/store/version";

export default {
  data: () => ({
    isUpdated: false,

    VERSION: THIS_VERSION
  }),

  mounted() {
    this.$store.dispatch(`${NS_VERSION}/load`).then(() => {
      this.isUpdated = this.$store.getters[`${NS_VERSION}/isUpdated`];
    });
  }
};
</script>
