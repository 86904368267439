<template>
  <v-card class="pa-5">
    <qrcode-vue
      :value="recieveqr"
      :size="250"
      level="H"
      class="my-5 text-center"
    />
  </v-card>
</template>

<script>
import { getCurrentUserUid } from "@/common/store";

import QrcodeVue from "qrcode.vue";

export default {
  name: "MedicalHistoryUserQrId",

  components: {
    QrcodeVue
  },

  data: () => ({
    recieveqr: ""
  }),

  mounted() {
    this.recieveqr = getCurrentUserUid();
  }
};
</script>
