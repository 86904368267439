<template>
  <div class="row">
    <div class="col-12 text-truncate ml-2 primary--text">
      <strong class="textarea"> {{ fullqr }}</strong>
      {{ cartorders.join(", ") }}
    </div>
  </div>
</template>

<script>
import { loadAccountCart } from "@/utilities/collection/addtocart";

export default {
  name: "CartByDescription",

  props: {
    fullqr: String,
    id: String
  },

  data: () => ({
    cartorders: []
  }),

  mounted() {
    loadAccountCart(this.id).then(docs => {
      docs.forEach(cartorder => {
        this.cartorders.push(`${cartorder.quantity} x ${cartorder.name}`);
      });
    });
  }
};
</script>
