<template>
  <v-card class="hide-scrollbar">
    <h4 class="mt-3 text-center primary--text pt-2">
      Select Tests
    </h4>
    <p class="text-center">{{ subheader }}</p>

    <v-row class="px-6" align="center">
      <v-col cols="12" sm="6" class="py-1">
        <v-select :items="categories" label="Categories" v-model="category" />
      </v-col>

      <v-col cols="12" sm="6" class="py-1">
        <v-text-field
          clearable
          v-model="search"
          label="Search Individual Test"
          prepend-inner-icon="mdi-file-find-outline"
        />
      </v-col>
    </v-row>

    <div class="hide-scrollbar selection-container-parent">
      <div class="mt-3 selection-container">
        <v-row
          v-for="entry in filteredStat"
          :key="entry.index"
          class="ml-3 mr-5"
        >
          <v-col cols="8" :class="{ 'px-0': $vuetify.breakpoint.mobile }">
            <v-checkbox
              :input-value="entry.isSelected"
              :label="entry.name"
              @change="onCheck(entry.index, $event)"
              class="mt-0 pt-0"
            />
          </v-col>
          <v-col
            cols="4"
            class="text-right"
            :class="{ 'px-0': $vuetify.breakpoint.mobile }"
          >
            <PesoAmount :amount="entry.price" />
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="text-center">
      <v-btn small rounded @click="onConfirm" tile color="success" class="my-4">
        Confirm
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { displayAmount } from "@/common/utilities/number";
import { getTestPrice } from "@/utilities/appointment";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "IndividualTest",

  components: {
    PesoAmount
  },

  props: {
    clinicType: String,
    selectedTests: Array,
    subheader: String
  },

  data: () => ({
    category: "",
    categories: [],
    search: "",

    testList: [],

    ALL: "View All",

    displayAmount: displayAmount
  }),

  mounted() {
    this.loadTests();
  },

  computed: {
    filteredStat() {
      let isAllCategory = !this.category || this.ALL === this.category;
      let searchString = this.search.toLowerCase();

      return this.testList.filter(test => {
        return (
          (isAllCategory || test.group === this.category) &&
          (!this.search || test.name.toLowerCase().includes(searchString))
        );
      });
    }
  },

  methods: {
    loadTests() {
      this.categories = [this.ALL];

      this.testList = [];
      this.$store.getters["lab/appointment/getTests"].forEach(details => {
        this.categories.push(details.data.testname);

        details.data.testDetails.forEach(test => {
          this.testList.push({
            name: test.testName,
            price: getTestPrice(this.clinicType, test),
            group: details.data.testname,
            uid: details.id
          });
        });
      });

      this.testList.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else {
          return a.name > b.name ? 1 : 0;
        }
      });

      this.updateSelectedTests();
    },

    onCheck(index, newValue) {
      this.testList[index].isSelected = newValue;
    },

    onConfirm() {
      let testsSelected = [];

      for (let entry of this.testList) {
        if (entry.isSelected) {
          testsSelected.push({
            name: entry.name,
            price: entry.price,
            group: entry.group,
            uid: entry.uid
          });
        }
      }

      this.$emit("onTestsSelected", testsSelected);
    },

    updateSelectedTests() {
      for (let i in this.testList) {
        this.testList[i]["index"] = Number(i);
        this.testList[i].isSelected = false;

        for (let j in this.selectedTests) {
          if (
            this.testList[i].name === this.selectedTests[j].name &&
            this.testList[i].uid === this.selectedTests[j].uid
          ) {
            this.testList[i].isSelected = true;
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.hide-scrollbar {
  overflow: hidden;
}

.selection-container-parent {
  height: 300px;
  width: 100%;
}

.selection-container {
  height: 102%;
  width: 102%;
  overflow: scroll;
}
</style>
