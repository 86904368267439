<template>
  <div class="px-15">
    <h3 class="mb-15 mt-10 text-center">Information Received</h3>

    <p>
      We received your information for the purpose of registering Medisure as
      your PhilHealth Konsulta provider.
    </p>

    <p>
      Please expect a call from our staff to complete the registration process.
    </p>

    <NextButton
      @click="routeTo(ROUTE_DASHBOARD)"
      text="Return Home"
      class="mb-5 mt-15"
    />
  </div>
</template>

<script>
import { ROUTE_DASHBOARD, routeTo } from "@/router";

import NextButton from "@/components/Controls/NextButton.vue";

export default {
  name: "Completed",

  components: {
    NextButton
  },

  data: () => ({
    routeTo: routeTo,

    ROUTE_DASHBOARD: ROUTE_DASHBOARD
  })
};
</script>
