<template>
  <v-card align="center" class="pa-4">
    <v-list-item class="px-1">
      <v-list-item-avatar>
        <v-img
          class="white--text align-end"
          :aspect-ratio="16 / 9"
          height="80px"
          :src="selectedCartItem.imageUrl"
        />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          class="primary--text"
          v-text="selectedCartItem.name"
        />
        <v-list-item-subtitle v-text="selectedCartItem.category" />
        <v-list-item-subtitle v-text="selectedCartItem.variation" />
      </v-list-item-content>
    </v-list-item>

    <v-rating
      v-model="rating"
      background-color="primary lighten-3"
      color="orange"
      large
    />
    <v-textarea class="mx-4" v-model="comment" label="Comment" />

    <div class="pa-2">
      <v-btn
        class="ma-2"
        outlined
        small
        @click="dialog = true"
        fab
        color="primary"
      >
        <v-icon>mdi-camera</v-icon>
      </v-btn>

      <v-btn @click="setRating()" outlined color="primary">
        <v-icon>mdi-check</v-icon> Submit
      </v-btn>
    </div>

    <v-row>
      <v-col
        v-for="(n, index) in photoUrl"
        :key="index"
        class="d-flex child-flex"
        cols="4"
        sm="2"
        style="min-height: 90px"
      >
        <v-img v-if="n" :contain="true" :src="n">
          <div class="text-right">
            <v-btn
              @click.stop="removeImage(index)"
              fab
              color="error"
              x-small
              dark
            >
              <v-icon x-small>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-img>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="dialog" width="345">
      <v-row class="ma-0" align="center" justify="center">
        <TakePhoto
          v-on:take-photo="takePhoto"
          :rateMode="true"
          :key="rateKey"
          @closeDialog="dialog = false"
        />
      </v-row>
    </v-dialog>
  </v-card>
</template>

<script>
import { STATUS_DONE } from "@/common/utilities/payment";
import { DB } from "@/common/store";

import { db } from "@/main";
import { addRating } from "@/utilities/collection/catalog";
import { mapGetters } from "vuex";
import { setRating } from "@/utilities/collection/addtocart";

import TakePhoto from "@/common/components/controls/TakePhoto.vue";

export default {
  name: "RatingInput",

  components: {
    TakePhoto
  },

  props: {
    selectedCartItem: Object,
    checkoutId: String
  },

  data: () => ({
    comment: "",
    dialog: false,
    photoUrl: [],
    rateKey: 0,
    rating: 0
  }),

  computed: {
    ...mapGetters(["getAvatarUrl"])
  },

  methods: {
    updateToDone(checkoutId) {
      db.collection(DB.CHECKOUT)
        .doc(checkoutId)
        .set(
          { latestReturnRefund: { status: { STATUS_DONE } } },
          { merge: true }
        );
    },

    getSetRatingCatalog() {
      return new Promise((resolve, reject) => {
        let productSKU = this.selectedCartItem.productSKU;

        addRating(productSKU, this.rating, this.selectedCartItem.quantity)
          .then(() => resolve(productSKU))
          .catch(err => reject(err));
      });
    },

    removeImage(index) {
      if (index > -1) {
        this.photoUrl.splice(index, 1);
      }
    },

    setRating() {
      setRating({
        id: this.selectedCartItem.id,
        photoUrl: this.photoUrl,
        raterImage: this.getAvatarUrl,
        rating: this.rating,
        comment: this.comment
      })
        .then(() => {
          return this.getSetRatingCatalog();
        })
        .then(() => {
          this.updateToDone(this.checkoutId);
          this.$emit("rated-refresh");
        });
    },

    async takePhoto(file) {
      this.photoUrl.push(file);
      this.dialog = false;
      if (this.photoUrl.length > 0) {
        this.rateKey++;
      }
    }
  }
};
</script>
