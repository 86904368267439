<template>
  <v-card>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Status</td>
            <td class="text-right width-60">
              {{ selectedReturnRefund.status }}
            </td>
          </tr>
          <tr>
            <td>Image</td>
            <td class="width-60">
              <v-row>
                <v-col
                  class="py-4 px-1 ml-auto"
                  v-for="(n, index) in selectedReturnRefund.photoUrlSample"
                  :key="index"
                >
                  <v-img v-if="n" :src="n" height="100px" width="100px" />
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <td>Reason</td>
            <td class="text-right width-60">
              {{ selectedReturnRefund.reason }}
            </td>
          </tr>
          <tr>
            <td>Return Quantity</td>
            <td class="text-right width-60">
              {{ selectedReturnRefund.returnQuantity }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "ReturnRefundDetails",

  props: {
    selectedReturnRefund: Object
  }
};
</script>
