<template>
  <v-navigation-drawer v-model="drawerToggle" app>
    <v-divider />

    <v-list dense>
      <v-list-item :to="ROUTE_PROFILE">
        <v-list-item-avatar>
          <v-img :contain="true" :src="getAvatarUrl"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title primary--text">
            {{ getDisplayName }}
          </v-list-item-title>
          <v-list-item-title class="caption">
            View Profile
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <NavigationMenuItem
        @click="redirect()"
        icon="mdi-android-messages"
        :link="ROUTE_INBOX"
        title="Inbox"
      />

      <NavigationMenuItem
        icon="mdi-account"
        :link="ROUTE_HEALTHPROFILE"
        title="Health Profile"
      />

      <v-divider class="my-5" />

      <NavigationMenuItem
        icon="mdi-home"
        :link="ROUTE_DASHBOARD"
        title="Home"
      />

      <NavigationMenuItem
        icon="mdi-medical-bag"
        :link="ROUTE_WALKIN_MEDICAL_HISTORY"
        title="Medical Services"
      />

      <NavigationMenuItem
        icon="mdi-hand-heart"
        :link="ROUTE_INSURANCE"
        :title="$t('menu.getInsured')"
      />

      <NavigationMenuItem
        icon="mdi-pill"
        :link="ROUTE_BOTIKA"
        :isDisabled="true"
        :title="$t('menu.pharmacy')"
      />

      <NavigationMenuItem
        icon="mdi-shopping"
        :link="ROUTE_STORES"
        :title="$t('menu.store')"
      />

      <v-list-item @click="signOut">
        <v-list-item-action>
          <v-icon color="red">mdi-exit-to-app</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="error--text">Sign out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { DB, getCurrentUserUid } from "@/common/store";
import {
  ROUTE_BOTIKA,
  ROUTE_DASHBOARD,
  ROUTE_HEALTHPROFILE,
  ROUTE_INBOX,
  ROUTE_INSURANCE,
  ROUTE_PROFILE,
  ROUTE_STORES,
  routeTo
} from "@/router";
import { ROUTE_WALKIN_MEDICAL_HISTORY } from "@/router/medicalServices";
import { STATUS_UNREAD } from "@/common/utilities/message";

import { db, onAuthStateChanged } from "@/main";
import { mapGetters } from "vuex";
import { sendMessage } from "@/store";

import firebase from "firebase/app";
import NavigationMenuItem from "./Navigation/MenuItem.vue";

export default {
  name: "NavigationDrawer",

  components: {
    NavigationMenuItem
  },

  props: {
    drawer: Boolean
  },

  data: () => ({
    count: 0,
    drawerToggle: null,

    ROUTE_BOTIKA: ROUTE_BOTIKA,
    ROUTE_DASHBOARD: ROUTE_DASHBOARD,
    ROUTE_HEALTHPROFILE: ROUTE_HEALTHPROFILE,
    ROUTE_INBOX: ROUTE_INBOX,
    ROUTE_INSURANCE: ROUTE_INSURANCE,
    ROUTE_PROFILE: ROUTE_PROFILE,
    ROUTE_STORES: ROUTE_STORES,
    ROUTE_WALKIN_MEDICAL_HISTORY: ROUTE_WALKIN_MEDICAL_HISTORY
  }),

  mounted() {
    this.counts();
    this.$emit("update:drawer", this.drawerToggle);

    let shadow = window.sessionStorage.getItem("sendRegistrationMessage");
    let uid = window.sessionStorage.getItem("register_uid");
    if (shadow && uid) {
      this.sendWelcomeMessage(uid);

      window.sessionStorage.removeItem("register_uid");
      window.sessionStorage.removeItem("sendRegistrationMessage");
    }
  },

  methods: {
    async counts() {
      const querySnapshot = await db
        .collection(DB.MESSAGE_BOX)
        .where("receiver", "==", getCurrentUserUid())
        .where("status", "==", STATUS_UNREAD)
        .get();
      this.count = querySnapshot.size;
    },

    recount() {
      this.count = 0;
    },

    redirect() {
      routeTo(ROUTE_INBOX);
      this.recount();
    },

    sendWelcomeMessage(uid) {
      let body = `Salamat sa pagsali sa MediSure!!!

Nais po lamang naming kunin ang pagkakataon na ito upang iparating ang aming malugod na pagtanggap sa inyo bilang bagong miyembro.

Mabuhay po kayo!!!

Mula sa MediSure Team`;

      sendMessage({
        body: body,
        header:
          "https://firebasestorage.googleapis.com/v0/b/medisure-275117.appspot.com/o/images%2F53f56840-f3ba-11ea-ba6a-5bc4aecd9fe6.png?alt=media&token=f1229dce-47f4-41eb-831f-db9510805e13",
        name: "Admin",
        receiver: uid,
        sender: "Admin", //Sender AdminSide
        status: STATUS_UNREAD,
        subject: "Welcome to Medisure Plus!",
        timestamp: new Date()
      });
    },

    async signOut() {
      try {
        try {
          await firebase.auth().signOut();
        } finally {
          await this.$store.dispatch("resetContent");
        }
      } finally {
        onAuthStateChanged(null);
      }
    }
  },

  watch: {
    drawer(toggle) {
      if (this.drawerToggle !== toggle) {
        this.drawerToggle = toggle;
      }
    },

    drawerToggle(toggle) {
      if (this.drawer !== toggle) {
        this.$emit("update:drawer", toggle);
      }
    }
  },

  computed: {
    ...mapGetters(["getAvatarUrl", "getDisplayName"])
  }
};
</script>

<style>
.v-list-item__title > .v-badge {
  margin-top: 8px !important;
}

.v-badge__badge {
  height: 14px !important;
  min-width: 8px !important;
  padding: 2px 4px !important;
}
</style>
