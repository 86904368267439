<template>
  <div>
    <h3
      v-show="$vuetify.breakpoint.mobile"
      class="font-weight-bold mt-3 primary--text pt-2 text-center text-h6"
    >
      {{ $t("dialog.confirmation") }}
    </h3>

    <v-row class="mb-4 mt-5">
      <v-col>
        {{ $t("laboratory.appointmentConfirmationLeadingText") }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="3">Type</v-col>
      <v-col cols="8">
        {{ $store.getters["lab/appointment/getClinicType"] }}
      </v-col>
    </v-row>

    <div>
      <v-row class="mt-n4">
        <v-col cols="1"></v-col>
        <v-col cols="3">Address</v-col>
        <v-col cols="8">{{ address }}</v-col>
      </v-row>

      <v-row class="mt-n4">
        <v-col cols="1"></v-col>
        <v-col cols="3">Preferred</v-col>
        <v-col cols="8" v-if="isSpecificTime"> {{ appointmentTime }} </v-col>
        <v-col cols="8" v-else> {{ scheduleType }} ({{ scheduleDate }}) </v-col>
      </v-row>
    </div>

    <v-divider class="mx-5 mt-10" />
    <TestSummary :patients="patients" />
    <ConsolidatedTotals :patients="patients" :key="patients" class="mt-5" />

    <v-row class="mt-4">
      <v-col>
        {{ $t("laboratory.appointmentConfirmationText") }}
        <strong>
          {{ $t("laboratory.appointmentConfirmationButtonText") }}
        </strong>
        .
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getFullAddress } from "@/common/utilities/location";
import { getScheduleString } from "@/common/utilities/appointments";
import { isSpecificTime } from "@/common/utilities/appointments/schedule_type";

import ConsolidatedTotals from "./ConsolidatedTotals.vue";
import TestSummary from "@/common/components/Laboratory/TestSummary.vue";

export default {
  name: "ConfirmHomeServiceDisplay",

  components: {
    ConsolidatedTotals,
    TestSummary
  },

  data: () => ({
    patients: []
  }),

  mounted() {
    this.patients = this.$store.getters["lab/appointment/getPatients"];
  },

  computed: {
    address() {
      let loc = this.$store.getters["lab/appointment/getAppointmentLocation"];
      return loc ? getFullAddress(loc) : "";
    },

    appointmentTime() {
      return getScheduleString(
        this.$store.getters["lab/appointment/getSchedule"]
      );
    },

    isSpecificTime() {
      return isSpecificTime(
        this.$store.getters["lab/appointment/getScheduleType"]
      );
    },

    scheduleDate() {
      return this.$store.getters["lab/appointment/getScheduleDate"];
    },

    scheduleType() {
      return this.$store.getters["lab/appointment/getScheduleType"];
    }
  }
};
</script>
