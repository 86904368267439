import ISDAMain from "@/ISDA/views/ISDAMain.vue";
import PaymentCancel from "@/ISDA/views/PaymentCancel.vue";
import PaymentError from "@/ISDA/views/PaymentError.vue";
import PaymentFail from "@/ISDA/views/PaymentFail.vue";
import PaymentSuccess from "@/ISDA/views/PaymentSuccess.vue";

export const ROUTE_ISDA = "/isda";
export const ROUTE_ISDA_PAYMENT_SUCCESS = "/isda/payment/success";
export const ROUTE_ISDA_PAYMENT_FAIL = "/isda/payment/fail";
export const ROUTE_ISDA_PAYMENT_ERROR = "/isda/payment/error";
export const ROUTE_ISDA_PAYMENT_CANCEL = "/isda/payment/cancel";

export function routes() {
  return [
    {
      path: ROUTE_ISDA,
      name: "ISDA Foundation",
      component: ISDAMain
    },
    {
      path: ROUTE_ISDA_PAYMENT_SUCCESS,
      name: "Payment Success",
      component: PaymentSuccess
    },
    {
      path: ROUTE_ISDA_PAYMENT_FAIL,
      name: "Payment Fail",
      component: PaymentFail
    },
    {
      path: ROUTE_ISDA_PAYMENT_ERROR,
      name: "Payment Error",
      component: PaymentError
    },
    {
      path: ROUTE_ISDA_PAYMENT_CANCEL,
      name: "Payment Cancel",
      component: PaymentCancel
    }
  ];
}
