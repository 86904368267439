<template>
  <div>
    <p v-show="!isLoaded" class="mt-15 text-center">Loading data...</p>

    <Registration v-if="isLoaded && !isSubmitted" @onSubmit="onSubmit" />
    <Completed v-if="isLoaded && isSubmitted" />
  </div>
</template>

<script>
import { DB, getCurrentUserUid, logUserActivity } from "@/common/store";
import { NS_PHILHEALTHINFO as NAMESPACE } from "@/store";

import Completed from "../components/Completed.vue";
import Registration from "../components/Registration.vue";

export default {
  name: "Register",

  components: {
    Completed,
    Registration
  },

  data: () => ({
    isLoaded: false,
    isSubmitted: false
  }),

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");

    this.$store.dispatch(`${NAMESPACE}/load`).then(() => {
      this.isSubmitted = this.$store.getters[`${NAMESPACE}/isSubmitted`];
      this.isLoaded = true;
    });
  },

  methods: {
    onSubmit() {
      const timestamp = new Date();
      this.$store.commit(`${NAMESPACE}/setSubmissionDate`, timestamp);

      this.$store
        .dispatch(`${NAMESPACE}/save`, {
          collection: DB.PHILHEALTH_INDEX,
          docId: getCurrentUserUid()
        })
        .then(() => {
          return logUserActivity("Submit PhilHealth information", {
            timestamp: timestamp
          });
        })
        .then(() => {
          this.isSubmitted = true;
        });
    }
  }
};
</script>
