<template>
  <v-card max-width="600" class="mx-auto" elevation="0">
    <div class="mt-5 pa-4 text-center">
      <strong>{{ inputMode }} Address</strong>

      <v-text-field
        label="Fullname"
        v-model="name"
        :rules="[c => !!c || 'Field is required']"
      />
      <v-text-field
        label="Contact No"
        v-model="contact"
        :rules="[c => !!c || 'Field is required']"
      />

      <SelectLocation
        :dbLocation="'mobileServiceLocations'"
        :selectedLocation.sync="selectedLocation"
      />
      <v-text-field
        label="Unit/Bldg./Floor/House Number"
        v-model="houseNumber"
        :rules="[c => !!c || 'Field is required']"
      />
      <v-text-field
        label="Bldg./Street Name"
        v-model="street"
        :rules="[c => !!c || 'Field is required']"
      />
      <v-text-field
        label="Landmark"
        v-model="landmark"
        :rules="[c => !!c || 'Field is required']"
      />

      <v-btn
        class="mb-4"
        :disabled="!isValid"
        rounded
        small
        color="primary"
        @click="setAddress()"
      >
        {{ inputMode }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { ROUTE_ADDRESS_BOOK, ROUTE_CHECKOUT, routeTo } from "@/router";

import { makeDefault, saveDefaultDetails } from "@/store";
import { mapGetters } from "vuex";
import { setAddressBook, updateAddressBook } from "@/utilities/tindahan";

import SelectLocation from "@/components/SelectLocation.vue";
let uuid = require("uuid");

const CREATE = "create";

export default {
  name: "AddressInput",

  components: {
    SelectLocation
  },

  props: {
    mode: String
  },

  data: () => ({
    contact: "",
    houseNumber: "",
    indexID: "",
    inputMode: CREATE,
    isDefault: false,
    landmark: "",
    name: "",
    selectedLocation: null,
    street: ""
  }),

  mounted() {
    if (this.addressBook) {
      this.inputMode = "Edit";
      this.name = this.addressBook.address.name;
      this.contact = this.addressBook.address.contact;
      this.selectedLocation = this.addressBook.address.selectedLocation;
      this.houseNumber = this.addressBook.address.houseNumber;
      this.street = this.addressBook.address.street;
      this.landmark = this.addressBook.address.landmark;
      this.indexID = this.addressBook.index;
      this.isDefault = this.addressBook.isDefault;
    }

    this.$store.commit("SET_ADDRESS_BOOK_DETAILS", null);
  },

  computed: {
    ...mapGetters({
      addressBook: "getAddressBook"
    }),

    isValid() {
      return (
        this.name !== "" &&
        this.contact !== "" &&
        this.selectedLocation !== "" &&
        this.houseNumber !== "" &&
        this.street !== "" &&
        this.landmark !== ""
      );
    }
  },

  watch: {
    contact() {
      this.emitIsValid();
    },

    houseNumber() {
      this.emitIsValid();
    },

    landmark() {
      this.emitIsValid();
    },

    name() {
      this.emitIsValid();
    },

    selectedLocation() {
      this.emitIsValid();
    },

    street() {
      this.emitIsValid();
    }
  },

  methods: {
    emitIsValid() {
      this.$emit("isValid", this.isValid);
    },

    selectAddress(address, page) {
      this.$store.commit("SET_SELECTED_ADDRESS", address);
      routeTo(page);
    },

    setAddress() {
      if (CREATE === this.inputMode) {
        let addressBook = {
          [uuid.v4()]: {
            contact: this.contact,
            houseNumber: this.houseNumber,
            landmark: this.landmark,
            name: this.name,
            selectedLocation: this.selectedLocation,
            street: this.street
          }
        };

        setAddressBook(addressBook).then(doc => {
          for (let key in doc) {
            if (this.mode) {
              makeDefault({ defaultAddress: key, address: addressBook[key] });
              this.selectAddress(
                addressBook[key],
                `${ROUTE_CHECKOUT}/addressSelected`
              );
            }
          }

          if (!this.mode) {
            routeTo(ROUTE_ADDRESS_BOOK);
          }
        });
      } else {
        let addressBook = {
          contact: this.contact,
          houseNumber: this.houseNumber,
          landmark: this.landmark,
          name: this.name,
          selectedLocation: this.selectedLocation,
          street: this.street
        };
        updateAddressBook(addressBook, this.indexID).then(() => {
          if (this.isDefault) {
            saveDefaultDetails(addressBook);
          }
          routeTo(ROUTE_ADDRESS_BOOK);
        });
      }
    }
  }
};
</script>
