<template>
  <v-form elevation="0" class="container py-0">
    <h4 class="primary--text text-center pb-2">Cost Breakdown</h4>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr v-if="true">
            <td class=" px-2">Service Fee</td>
            <td class="text-right px-2">
              <PesoAmount :amount="serviceFee" />
            </td>
          </tr>
          <tr v-if="true">
            <td class="px-2">Discount</td>
            <td class="text-right px-2">
              <PesoAmount :amount="discountValue" />
            </td>
          </tr>
          <tr></tr>

          <tr>
            <td>Total</td>
            <td class="text-right">
              <strong><PesoAmount :amount="discountedTotal"/></strong>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-form>
</template>

<script>
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "CostBreakDown",

  components: {
    PesoAmount
  },

  props: {
    serviceFee: Number,
    discountValue: Number,
    discountedTotal: Number
  }
};
</script>

<style scoped>
.container {
  max-width: 900px;
}
</style>
