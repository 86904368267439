import { DB, getCurrentUserUid } from "@/common/store";

import { db } from "@/main";

function loadValidationData(dbName) {
  return new Promise((resolve, reject) => {
    db.collection(dbName)
      .doc(getCurrentUserUid())
      .get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          reject();
        }
      })
      .catch(err => reject(err));
  });
}

function loadUserValidation() {
  return new Promise((resolve, reject) => {
    loadValidationData(DB.USER_VALIDATION)
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
}

export function loadUserValidationData(target = null) {
  return new Promise((resolve, reject) => {
    if (target) {
      loadValidationData(DB.LGU_VALIDATION)
        .then(data => resolve(data))
        .catch(() => {
          loadUserValidation()
            .then(data => resolve(data))
            .catch(err => reject(err));
        });
    } else {
      loadUserValidation()
        .then(data => resolve(data))
        .catch(err => reject(err));
    }
  });
}

export function SetUserValidationData(data) {
  let targetDB = DB.USER_VALIDATION;
  if ("target" in data) {
    if (data.target) {
      targetDB = DB.LGU_VALIDATION;
    }

    delete data["target"];
  }

  db.collection(targetDB)
    .doc(getCurrentUserUid())
    .set({ ...data }, { merge: true });
}
