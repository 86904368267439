import { PAYMENT_REFUND } from "@/common/utilities/payment";

function getPaymentMethods(payments) {
  let modeOfPayments = [];

  if (payments) {
    for (let key in payments) {
      if (
        payments[key].modeOfPayment &&
        payments[key].modeOfPayment !== PAYMENT_REFUND
      ) {
        modeOfPayments.push(payments[key].modeOfPayment);
      }
    }
  }

  return [...new Set(modeOfPayments)];
}

export function getPaymentMethodsDisplay(payments) {
  return getPaymentMethods(payments).join(", ");
}
