<template>
  <v-container>
    <div v-show="promo" class="mb-2">
      <v-row style="height: 10px;">
        <v-col cols="9" sm="6" />
        <v-col cols="3" sm="6" class="py-0 pr-7 text-right">
          <v-divider />
        </v-col>
      </v-row>

      <v-row
        class="font-weight-bold"
        :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
      >
        <v-col cols="9" sm="6" class="pt-0 text-right">Subtotal</v-col>
        <v-col
          cols="3"
          sm="6"
          class="pt-0  text-right"
          :class="{ 'text-center': $vuetify.breakpoint.mobile }"
        >
          <PesoAmount :amount="subtotal" />
        </v-col>
      </v-row>

      <v-row class="mt-5" :class="{ 'pr-7': !$vuetify.breakpoint.mobile }">
        <v-col md="9" sm="6" class="pt-0 text-right">LESS Discount/s</v-col>
        <v-col
          md="3"
          sm="6"
          class="pt-0 text-right"
          :class="{ 'text-center': $vuetify.breakpoint.mobile }"
        >
          <PesoAmount :amount="promo" />
        </v-col>
      </v-row>
    </div>

    <div>
      <v-row
        style="height: 10px;"
        :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
      >
        <v-col cols="9" sm="6" class="pt-0 text-right" />
        <v-col
          cols="3"
          sm="6"
          class="py-0 text-right"
          :class="{ 'text-center': $vuetify.breakpoint.mobile }"
        >
          <v-divider />
        </v-col>
      </v-row>

      <v-row
        class="font-weight-bold pr"
        :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
      >
        <v-col cols="9" sm="6" class="py-0 text-right">Total</v-col>
        <v-col
          cols="3"
          sm="6"
          class="py-0  text-right"
          :class="{ 'text-center': $vuetify.breakpoint.mobile }"
        >
          <PesoAmount :amount="total" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "ConsolidatedTotals",

  components: {
    PesoAmount
  },

  props: {
    patients: Array
  },

  data: () => ({
    promo: 0,
    subtotal: 0,
    total: 0
  }),

  mounted() {
    if (this.patients) {
      this.promo = this.patients.reduce(
        (sum, patient) => sum + this.getPromoAmount(patient),
        0
      );

      this.subtotal = this.patients.reduce(
        (sum, patient) => sum + (patient.subtotal || 0),
        0
      );

      this.total = this.patients.reduce(
        (sum, patient) => sum + (patient.total || 0),
        0
      );
    }
  },

  methods: {
    getPromoAmount(patient) {
      return patient.promo && patient.promo.amount ? patient.promo.amount : 0;
    }
  }
};
</script>
