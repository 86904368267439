<template>
  <v-card>
    <BackButton @click="$emit('close-dialog')" class="ma-3" />
    <div class="primary--text text-center" v-if="getInvalidPwdScDetails">
      The PWD / Senior Citizen ID you submitted is invalid. Please resubmit.<br />
      Note: Please make sure to submit a clear photo ID.
    </div>

    <div class="text-center">
      <h3 class="text-center primary--text">Upload Your ID</h3>
      <v-img
        v-if="!photoUrl"
        max-width="300"
        :contain="true"
        :src="require('@/assets/avatar.jpg')"
        height="300"
      />
      <v-img
        v-else
        max-width="300"
        :contain="true"
        :src="photoUrl"
        height="300"
      />

      <div text-xs-center>
        <v-btn
          class="ma-2"
          @click.stop="dialog = true"
          fab
          color="primary"
          x-small
          dark
        >
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
        <v-btn
          class="ma-2"
          @click.stop="dialogCam = true"
          fab
          color="primary"
          x-small
          dark
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </div>

      <v-btn class="ma-2" @click.stop="setPwdSc()" color="primary" dark>
        Submit
      </v-btn>

      <v-dialog v-model="dialog" width="600">
        <UploadPhoto fullscreen v-on:upload-photo="uploadPhoto" />
      </v-dialog>

      <v-dialog v-model="dialogCam" width="600">
        <TakePhoto v-on:take-photo="takePhoto" />
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { DB, getCurrentUserUid } from "@/common/store";

import { db } from "@/main";
import { mapGetters } from "vuex";

import BackButton from "@/components/Controls/BackButton.vue";
import TakePhoto from "@/common/components/controls/TakePhoto.vue";
import UploadPhoto from "@/components/Controls/UploadPhoto.vue";

export default {
  name: "UploadPwdCs",

  components: {
    BackButton,
    TakePhoto,
    UploadPhoto
  },

  data: () => ({
    dialog: false,
    dialogCam: false,
    photoUrl: ""
  }),

  computed: {
    ...mapGetters(["getInvalidPwdScDetails"])
  },

  methods: {
    setPwdSc() {
      let pwdScDetails = {
        photoUrl: this.photoUrl,
        isVerified: false
      };

      db.collection(DB.PROFILES)
        .doc(getCurrentUserUid())
        .set({ pwdScDetails }, { merge: true })
        .then(() => {
          this.$store.commit("SET_PWDSC_DETAILS", pwdScDetails);
          this.$emit("close-dialog2");
        });
    },

    async takePhoto(file) {
      this.photoUrl = file;
      this.dialogCam = false;
    },

    async uploadPhoto(file) {
      this.photoUrl = file;
      this.dialog = false;
    }
  }
};
</script>
