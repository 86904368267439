<template>
  <div>
    <v-card sm="12" md="4" max-width="300" class="pb-4 userprofile">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="primary--text headline text-center">
            <div>
              <v-btn
                class="mx-2"
                @click.stop="openDisplayNameDialog()"
                fab
                color="primary"
                x-small
                dark
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              {{ dname }}
            </div>
          </v-list-item-title>

          <v-card-text v-if="isVerified" class="centerThis mt-n4">
            Account Verified
          </v-card-text>
          <v-card-text
            v-else-if="isVerificationRequested"
            class="centerThis mt-n4"
          >
            Account Under Verification
          </v-card-text>
          <v-card-text v-else class="centerThis mt-n4">
            Unverified Account
          </v-card-text>
        </v-list-item-content>
      </v-list-item>

      <v-img
        v-if="!newurl"
        max-width="300"
        :contain="true"
        :src="require('../assets/avatar.jpg')"
        height="300"
      >
        <div text-xs-center>
          <v-btn
            class="mx-2"
            @click.stop="dialog = true"
            fab
            color="primary"
            x-small
            dark
          >
            <v-icon>mdi-cloud-upload</v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            @click.stop="routeTo(ROUTE_CAPTURE)"
            fab
            color="primary"
            x-small
            dark
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </div>
      </v-img>

      <v-img v-else max-width="300" :contain="true" :src="newurl" height="300">
        <div text-xs-center>
          <v-btn
            class="mx-2"
            @click.stop="dialog = true"
            fab
            color="primary"
            x-small
            dark
          >
            <v-icon>mdi-cloud-upload</v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            @click.stop="routeTo(ROUTE_CAPTURE)"
            fab
            color="primary"
            x-small
            dark
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </div>
      </v-img>

      <v-dialog v-model="dialogName">
        <v-row class="background-white ma-0" align="center" justify="center">
          <v-text-field class="ml-4 mr-4" v-model="dnamePending" />
          <v-btn
            class="mb-4"
            @click="saveDisplayName"
            color="primary"
            :disabled="!dnamePending.length"
            small
          >
            Apply
          </v-btn>
        </v-row>
      </v-dialog>

      <v-card-actions class="justify-center">
        <v-btn
          v-if="isVerified || isVerificationRequested"
          dark
          color="primary"
          rounded
          small
          :to="ROUTE_VALIDATE"
        >
          Update Personal Information
        </v-btn>
        <v-btn
          v-else
          small
          rounded
          dark
          link
          :to="ROUTE_VALIDATE"
          color="primary"
        >
          - Verify Account -
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <span v-if="ename">
          <strong>Email:</strong> {{ ename }}
          <br />
        </span>
        <strong>Bantay Kard Balance:</strong>&nbsp;
        <Amount :amount="Number(getBantayKardBalance)" /> pts

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            x-small
            rounded
            dark
            :to="ROUTE_ADD_BANTAYKARD_POINTS"
          >
            Add Medisure Bantay Kard Points
          </v-btn>
        </v-card-actions>
      </v-card-text>

      <LinkAccount />
      <v-select
        :items="locales"
        label="Locale"
        item-text="label"
        item-value="value"
        max-width="300"
        class="ml-4 mr-4"
        v-model="selectedLocale"
      />
    </v-card>

    <v-card
      sm="12"
      md="4"
      max-width="300"
      class="pb-4 mt-4 userprofile centerThis"
    >
      <v-btn class="info-button primary ma-1" @click="dialogtransaction = true">
        Payment Transaction
      </v-btn>
    </v-card>

    <v-dialog v-model="dialog" width="600">
      <UploadPhoto v-on:upload-photo="uploadPhoto" />
    </v-dialog>

    <v-dialog v-model="dialogqr" width="300">
      <UserQrID />
    </v-dialog>

    <v-dialog
      v-model="dialogmedhistory"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <MedicalHistory />
    </v-dialog>

    <v-dialog v-model="dialogtransaction" width="500">
      <PaymentTransaction @close-dialog="dialogtransaction = false" />
    </v-dialog>
  </div>
</template>

<script>
import { LOCALE_CEB, LOCALE_EN, LOCALE_TL } from "@/common/utilities/locale";
import { setDisplayName } from "@/store";
import {
  ROUTE_ADD_BANTAYKARD_POINTS,
  ROUTE_CAPTURE,
  ROUTE_VALIDATE,
  routeTo
} from "@/router";

import { getCurrentUserEmail } from "@/common/store";
import { mapActions, mapGetters } from "vuex";

import Amount from "@/common/components/Amount.vue";
import LinkAccount from "@/components/Profile/LinkAccount.vue";
import MedicalHistory from "../views/MedicalHistory.vue";
import PaymentTransaction from "@/components/Profile/PaymentTransaction.vue";
import UploadPhoto from "@/components/Controls/UploadPhoto.vue";
import UserQrID from "@/components/Profile/UserQrID.vue";

import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "Profile",

  components: {
    Amount,
    LinkAccount,
    MedicalHistory,
    PaymentTransaction,
    UploadPhoto,
    UserQrID
  },

  data: () => ({
    newurl: "",
    dialog: false,
    dialogqr: false,
    dialogmedhistory: false,
    dialogName: false,
    dialogtransaction: false,
    locales: [
      {
        label: "English",
        value: LOCALE_EN
      },
      {
        label: "Tagalog",
        value: LOCALE_TL
      },
      {
        label: "Cebuano",
        value: LOCALE_CEB
      }
    ],
    selectedLocale: LOCALE_TL,

    ename: "",
    dname: "",
    dnamePending: "",

    routeTo: routeTo,

    isUpdated: false,
    ROUTE_ADD_BANTAYKARD_POINTS: ROUTE_ADD_BANTAYKARD_POINTS,
    ROUTE_CAPTURE: ROUTE_CAPTURE,
    ROUTE_VALIDATE: ROUTE_VALIDATE
  }),

  mounted() {
    this.ename = getCurrentUserEmail();
    this.dname = this.getDisplayName;
    this.selectedLocale = this.getLocale;

    let img = this.getCapturedImage.image;
    if (img) {
      this.uploadPhoto(img);
    } else {
      this.newurl = this.getAvatarUrl;
    }
  },

  methods: {
    ...mapActions([
      "saveProfile",
      "setCapturedImage",
      "setProfileKeyData",
      "setProfilePhotoUrl"
    ]),

    openDisplayNameDialog() {
      this.dnamePending = this.dname;
      this.dialogName = true;
    },

    saveDisplayName() {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .currentUser.updateProfile({
            displayName: this.dnamePending
          })
          .then(() => {
            setDisplayName(this.dnamePending);
            this.dname = this.dnamePending;
            this.dialogName = false;
            resolve();
          })
          .catch(err => reject(err));
      });
    },

    async uploadPhoto(file) {
      this.setProfilePhotoUrl(file).then(url => {
        this.newurl = url;
        this.$store.commit("UPDATE_AVATAR", this.newurl);
        this.setCapturedImage(null);
      });
      this.dialog = false;
    }
  },

  watch: {
    selectedLocale(locale) {
      this.$i18n.locale = locale;
      this.setProfileKeyData({
        key: "locale",
        data: locale
      }).then(() => {
        this.saveProfile();
      });
    }
  },

  computed: {
    ...mapGetters([
      "getAvatarUrl",
      "getBantayKardBalance",
      "getCapturedImage",
      "getDisplayName",
      "getLocale",
      "isVerificationRequested",
      "isVerified"
    ])
  }
};
</script>

<style scoped>
.background-white {
  background: white;
}

.centerThis {
  margin: auto;
  text-align: center;
}

.info-button {
  width: 280px;
}

.userprofile {
  margin: auto;
}
</style>
