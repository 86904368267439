<template>
  <div>
    <MessageBoxContainer :filter="$route.params.filter" />
  </div>
</template>

<script>
import MessageBoxContainer from "@/components/MessageBoxContainer.vue";

export default {
  name: "Messages",

  components: {
    MessageBoxContainer
  },

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");
  }
};
</script>
