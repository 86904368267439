<template>
  <div>
    <BackButton
      @click="$emit('back')"
      :class="{ 'ma-2': $vuetify.breakpoint.mobile }"
    />

    <ConfirmHomeServiceDisplay />

    <v-row class="mt-8">
      <v-col
        cols="12"
        style="text-align: right;"
        :class="{ 'ma-2': $vuetify.breakpoint.mobile }"
      >
        <ConfirmScheduleButton @click="confirmScheduleHomeService" />
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" @onClose="onNext" />
  </div>
</template>

<script>
import { ConstructDataEntry } from "@/utilities/home_service";
import { SetHomeServiceAppointment } from "@/store/diagnostic_attendee";

import BackButton from "@/components/Controls/BackButton.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import ConfirmHomeServiceDisplay from "./ConfirmHomeServiceDisplay.vue";
import ConfirmScheduleButton from "@/components/Controls/ConfirmScheduleButton.vue";

export default {
  name: "ConfirmHomeServiceSchedule",

  components: {
    BackButton,
    ConfirmDialog,
    ConfirmHomeServiceDisplay,
    ConfirmScheduleButton
  },

  methods: {
    confirmScheduleHomeService() {
      const appointment = this.$store.getters["lab/appointment/getAppointment"];
      SetHomeServiceAppointment(ConstructDataEntry(appointment)).then(() => {
        this.showConfirmationMessageHomeService();
      });
    },

    dialogText() {
      return [
        this.$t("laboratory.appointmentConfirmationDialogTextLine1"),
        this.$t("laboratory.appointmentConfirmationDialogTextLine2"),
        this.$t("laboratory.appointmentConfirmationDialogTextLine3")
      ].join("<br /><br />");
    },

    onNext() {
      this.$store.commit("lab/appointment/CLEAR_APPOINTMENTS", "", {
        root: true
      });
      this.$emit("next");
    },

    showConfirmationMessageHomeService() {
      this.$refs.confirm.open(
        this.$t("dialog.confirmation"),
        this.dialogText(),
        { noconfirm: true }
      );
    }
  }
};
</script>
