<template>
  <div>
    <div v-for="(patient, index) in patients" :key="index">
      <PatientDetails :patient="patient.patient" class="mt-5" />
      <SelectedTestsDisplay :selectedTests="patient.packages" class="mx-10" />
      <SelectedTestsDisplay :selectedTests="patient.tests" class="mx-10" />
    </div>
  </div>
</template>

<script>
import PatientDetails from "./PatientDetails.vue";
import SelectedTestsDisplay from "./SelectedTestsDisplay.vue";

export default {
  name: "TestSummary",

  components: {
    PatientDetails,
    SelectedTestsDisplay
  },

  props: {
    patients: Array
  }
};
</script>
