<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center px-1" scope="col">ITEM</th>
            <th class="text-center px-1" scope="col">QUANTITY</th>
          </tr>
        </thead>

        <tbody v-for="(cart, clist) in newCartOrders" :key="clist">
          <tr>
            <td class="pa-1">
              <v-list-item class="px-1">
                <v-checkbox
                  class="pa-0"
                  v-model="selectedCart[cart.id]"
                  :value="cart"
                  @change="updateSelectedCart(selectedCart, cart.id)"
                />
                <v-list-item-avatar class="mr-1">
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="16 / 9"
                    height="80px"
                    :src="cart.imageUrl"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="primary--text">
                    {{ cart.name }}
                    <strong
                      style="background-color: primary;"
                      class="caption primary--text pa-1"
                    >
                      {{ getPromo(cart) }}
                    </strong>
                  </v-list-item-title>
                  <v-list-item-title
                    v-show="cart.preOrderFrom > 0"
                    class="caption"
                  >
                    {{ getPreOrder(cart) }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-text="cart.category" />
                  <v-list-item-subtitle v-text="cart.variation" />
                  <v-list-item-subtitle>
                    {{ cart.quantity }} x
                    <span
                      v-if="cart.discount"
                      class="mr-1 text-decoration-line-through"
                    >
                      <PesoAmount :amount="Number(cart.originalPrice)" />
                    </span>
                    <PesoAmount :amount="Number(cart.price)" />
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <PesoAmount :amount="cart.price * cart.quantity" />
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-show="cart.reason"
                    class="primary--text"
                  >
                    Reason: {{ cart.reason }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-show="cart.rejectedAmount"
                    class="error--text"
                  >
                    Voided: {{ cart.rejectedAmount }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-show="cart.refundAmount"
                    class="error--text"
                  >
                    Cancelled: <PesoAmount :amount="cart.refundAmount" />
                  </v-list-item-subtitle>

                  <div>
                    <v-textarea
                      v-model="reason[clist]"
                      class="pb-4"
                      hide-details
                      flat
                      label="Your Reason..."
                      outlined
                      clearable
                      @input="setReason(reason[clist], cart.id)"
                    />
                    <v-row v-if="selectedCart[cart.id]" :key="imageKey">
                      <v-col
                        v-for="(n, index) in selectedCart[cart.id]
                          .latestReturnRefund.photoUrlSample"
                        :key="index"
                        class="d-flex child-flex"
                        sm="2"
                        style="min-height: 80px"
                      >
                        <v-img v-if="n" :contain="true" :src="imagePreview(n)">
                          <div class="text-right">
                            <v-btn
                              @click.stop="removeImage(cart.id, index, n)"
                              fab
                              color="error"
                              x-small
                              dark
                            >
                              <v-icon x-small>mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </v-img>
                      </v-col>
                    </v-row>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </td>

            <td
              :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
              class="text-center px-0"
            >
              <v-text-field
                v-model="returnQuantity[clist]"
                type="number"
                :min="1"
                :max="cart.quantity"
                class="pt=l-5"
                style="width: 60px;"
                single-line
                outlined
                @input="setReturnQuantity(returnQuantity[clist], cart.id)"
              />
              <v-btn
                class="ma-2"
                outlined
                small
                @click="openPhoto(cart.id)"
                fab
                color="primary"
              >
                <v-icon>mdi-camera</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog persistent v-model="dialog" width="345">
      <v-row class="ma-0" align="center" justify="center">
        <TakePhoto v-on:take-photo="takePhoto" @closeDialog="dialog = false" />
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
import { RECEIVE_FOR_PICK_UP } from "@/common/utilities/payment";

import { getPromo } from "@/utilities/discounts";
import { loadShippingPromo, setReturnRefundImages } from "@/common/store/"; //setReturnRefundImages

import PesoAmount from "@/common/components/PesoAmount.vue";
import TakePhoto from "@/common/components/controls/TakePhoto.vue";

import firebase from "firebase/app";
import "firebase/storage";

export default {
  name: "CartItem",

  components: {
    PesoAmount,
    TakePhoto
  },

  props: {
    cartorders: Array,
    receivingOption: String
  },

  data: () => ({
    dialog: false,
    imageKey: 0,
    newCartOrders: [],
    photoId: "",
    promo: false,
    reason: [],
    returnQuantity: [],
    selectedCart: {},
    startingAmount: 0,
    tempImage: [],

    // functions
    getPromo: getPromo
  }),

  mounted() {
    this.loadCartSelection();
    this.reloadOrders();
  },

  watch: {
    newCartOrders() {
      this.reloadOrders();
    },

    receivingOption() {
      this.reloadOrders();
    },

    selectedCart() {
      this.refreshCartItem();
    }
  },

  methods: {
    async constructTempImage() {
      let tempImg = [];

      this.tempImage.forEach(img => {
        tempImg.push(img.file);
      });

      return tempImg;
    },

    fillSelectedCart() {
      this.cartorders.forEach(key => {
        if (key.id && key.latestReturnRefund) {
          this.selectedCart[key.id] = key;
          this.refreshCartItem();
        }
      });
    },

    async fillVariable(cart) {
      this.returnQuantity.push(cart.quantity);

      if (cart.latestReturnRefund) {
        this.returnQuantity = [];
        this.reason.push(cart.latestReturnRefund.reason);
        this.returnQuantity.push(cart.latestReturnRefund.returnQuantity);
        cart.latestReturnRefund.photoUrlSample.forEach(imageFile => {
          this.tempImage.push({ photoId: cart.id, file: imageFile });
        });
      }

      return this.tempImage;
    },

    getFilled() {
      let isFilled = Object.keys(this.selectedCart).reduce(
        (result, key) =>
          this.selectedCart[key].latestReturnRefund &&
          this.selectedCart[key].latestReturnRefund.returnQuantity &&
          this.selectedCart[key].latestReturnRefund.reason &&
          this.selectedCart[key].latestReturnRefund.photoUrlSample.length > 0
            ? true
            : result,
        false
      );

      this.$emit("isFilled", isFilled);
    },

    getNonPreOrder() {
      let itemShippingFees = [];
      let nonPreOrder = [];

      this.cartorders.forEach(cart => {
        nonPreOrder.push(
          this.isDiscountValid(cart)
            ? this.netUnitPrice(cart) * cart.quantity
            : cart.price * cart.quantity
        );

        itemShippingFees.push(Number(cart.itemShippingFee));
      });

      let highestShippingFee = 0;
      if (RECEIVE_FOR_PICK_UP !== this.receivingOption) {
        let total = nonPreOrder.reduce((a, b) => a + b, 0);
        highestShippingFee =
          this.promo &&
          (0 === itemShippingFees.length || total >= this.startingAmount)
            ? 0
            : Math.max(...itemShippingFees);
      }

      this.$emit("highestShippingFee", highestShippingFee);
    },

    getPreOrder(cart) {
      return `Pre-order ${cart.preOrderFrom} - ${cart.preOrderTo} days`;
    },

    getRefundAmount() {
      let refundAmount = Object.values(this.selectedCart).reduce(
        (sum, cart) =>
          sum + cart.price * Number(cart.latestReturnRefund.returnQuantity),
        0
      );

      this.$emit("selected-cart", this.selectedCart);
      this.$emit("refund-amount", Number(refundAmount));
    },

    isDiscountValid(cart) {
      return new Date(cart.discountUntil.seconds * 1000) > new Date();
    },

    async iterateCartOrders() {
      this.cartorders.forEach(cart => {
        this.fillVariable(cart);

        if (!cart.latestReturnRefund) {
          cart["latestReturnRefund"] = {
            returnQuantity: cart.quantity,
            reason: "",
            photoUrlSample: []
          };
        }

        this.newCartOrders.push({
          ...cart
        });
      });

      return this.newCartOrders;
    },

    async loadCartSelection() {
      await this.iterateCartOrders();
      this.fillSelectedCart();
    },

    locateImage(tempImage) {
      let imgs = [];
      tempImage.forEach(img => {
        if (img.photoId == this.photoId) {
          imgs.push(img.file);
        }
      });
      return imgs;
    },

    netUnitPrice(cart) {
      return Number(cart.price) * ((100 - Number(cart.discount)) / 100);
    },

    openPhoto(cartId) {
      this.photoId = cartId;
      this.dialog = true;
    },

    refreshCartItem() {
      this.imageKey++;
      this.getRefundAmount();
      this.getFilled();
    },

    async reloadOrders() {
      const docs = await loadShippingPromo();
      this.promo = docs.promo;
      this.startingAmount = Number(docs.startingAmount);

      this.getNonPreOrder();
    },

    async removeImage(cartid, index, value) {
      await this.removeOnTempImage(value, index);

      if (index > -1) {
        this.selectedCart[cartid].latestReturnRefund.photoUrlSample.splice(
          index,
          1
        );
      }

      this.imageKey++;
    },

    async removeOnTempImage(value) {
      const arrayFile = this.constructTempImage(this.tempImage);

      const index = arrayFile.indexOf(value);
      if (index > -1) {
        this.tempImage.splice(index, 1);
      }

      return this.tempImage;
    },

    setReason(reason, id) {
      const KEY = "reason";
      this.selectedCart[id].latestReturnRefund[KEY] = reason;
    },

    setReturnQuantity(returnQuantity, id) {
      const KEY = "returnQuantity";
      this.selectedCart[id].latestReturnRefund[KEY] = Number(returnQuantity);
    },

    imagePreview(refPath) {
      return firebase
        .storage()
        .ref()
        .child(refPath)
        .getDownloadURL();
    },

    async takePhoto(imgBase64) {
      const KEY = "photoUrlSample";
      const path = await setReturnRefundImages(imgBase64, this.photoId);

      this.tempImage.push({ photoId: this.photoId, file: path });
      this.selectedCart[this.photoId].latestReturnRefund[
        KEY
      ] = this.locateImage(this.tempImage);

      this.refreshCartItem();
      this.dialog = false;
    },

    updateSelectedCart(cart, id) {
      if (cart[id] == null) {
        delete this.selectedCart[id];
      }

      this.refreshCartItem();
    }
  }
};
</script>
