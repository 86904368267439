import { DB } from "@/common/store";

import { db } from "@/main";

import firebase from "firebase/app";
import "firebase/storage";

export const DATA = {
  CBC: "CBC Data",
  DOCTOR: "Doctor Assessment",
  ECG: "ECG Data",
  LAB_RESULTS: "Lab Results",
  FECALYSIS: "Fecalysis",
  PAP_SMEAR: "Pap Smear Data",
  PHOTO: "Photo Data",
  TEMPERATURE: "Temperature Data",
  URINALYSIS: "Urinalysis",
  XRAY: "Xray Data"
};

export const HEADER = {
  NAME: "name",
  USER_CODE: "userCode",
  CHECKIN: "isCheckedIn",
  TRIAGE: "isTriaged",
  BLOOD_EXTRACTION: "isBloodSampleCollected",
  LAB_RESULTS: "isLabResultSubmitted",
  URINALYSIS: "isUrinalysisSubmitted",
  FECALYSIS: "isFecalysisSubmitted",
  XRAY: "hasXray",
  ECG: "hasECG",
  PAP_SMEAR: "hasPapSmear",
  DOCTOR: "hasDoctorsAssessment",
  CHECKOUT: "isCheckedOut"
};

export const STAGE = {
  CHECKIN: "Checkin Stage",
  TRIAGE: "Triage Stage",
  BLOOD_EXTRACTION: "Blood Extraction Stage",
  LAB_RESULTS: "Lab Result Stage",
  URINALYSIS: "Urinalysis Submission",
  FECALYSIS: "Fecalysis Submission",
  XRAY: "X-ray Station",
  ECG: "ECG Station",
  PAP_SMEAR: "Pap Smear Station",
  DOCTOR: "Doctor's Assessment",
  CHECKOUT: "Checkout Stage"
};

export const FILTER_ALL = "All Submissions";
export const FILTER_COMPLETE = "Complete Submissions";
export const FILTER_INCOMPLETE = "Incomplete Submissions";

function getValue(key) {
  return window.localStorage.getItem(key);
}

function setValueOrRemove(key, value) {
  if (value) {
    window.localStorage.setItem(key, value);
  } else {
    window.localStorage.removeItem(key);
  }
}

const MEDISURE_ACTIVE_OFFSITE_ID = "medisureActiveOffsiteId";
const MEDISURE_ACTIVE_OFFSITE_NAME = "medisureActiveOffsiteName";
const MEDISURE_ACTIVE_OFFSITE_SCHEDULE = "medisureActiveOffsiteSchedule";
const MEDISURE_ACTIVE_CAMERA = "medisureActiveCamera";

export function getActiveCamera() {
  return getValue(MEDISURE_ACTIVE_CAMERA);
}

export function getActiveOffsiteService() {
  return {
    id: getValue(MEDISURE_ACTIVE_OFFSITE_ID),
    name: getValue(MEDISURE_ACTIVE_OFFSITE_NAME),
    schedule: getValue(MEDISURE_ACTIVE_OFFSITE_SCHEDULE)
  };
}

export function setActiveCamera(id) {
  setValueOrRemove(MEDISURE_ACTIVE_CAMERA, id || null);
}

export function setActiveOffsiteService(details) {
  setValueOrRemove(MEDISURE_ACTIVE_OFFSITE_ID, details ? details.id : null);

  setValueOrRemove(
    MEDISURE_ACTIVE_OFFSITE_NAME,
    details ? details.serviceName : null
  );

  setValueOrRemove(
    MEDISURE_ACTIVE_OFFSITE_SCHEDULE,
    details ? details.schedule : null
  );
}

function createSubmissionEntry() {
  return {
    name: "",
    fullName: "",
    firstName: "",
    middleName: "",
    lastName: "",

    [STAGE.CHECKIN]: false,
    [STAGE.TRIAGE]: false,
    [STAGE.BLOOD_EXTRACTION]: false,
    [STAGE.URINALYSIS]: false,
    [STAGE.FECALYSIS]: false,
    [STAGE.XRAY]: false,
    [STAGE.ECG]: false,
    [STAGE.PAP_SMEAR]: false,
    [STAGE.DOCTOR]: false,
    [STAGE.CHECKOUT]: false,

    [DATA.FECALYSIS]: null
  };
}

export function mapSubmissions(scheduleId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .orderBy("timestamp", "asc")
      .get()
      .then(snapshot => {
        let submissionMap = {};

        snapshot.forEach(doc => {
          const data = doc.data();

          if (!(data.userCode in submissionMap)) {
            submissionMap[data.userCode] = createSubmissionEntry();
          }

          if (data.name) {
            submissionMap[data.userCode]["name"] = data.name;
          }
          submissionMap[data.userCode][data.type] = data.value || false;
        });

        resolve(submissionMap);
      })
      .catch(err => reject(err));
  });
}

export function mapUsers(scheduleId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_ATTENDEE)
      .where("service.id", "==", scheduleId)
      .get()
      .then(snapshot => {
        let userMap = {};

        snapshot.forEach(doc => {
          const user = doc.data().user;
          userMap[user.code] = `${user.firstName} ${user.lastName}`;
        });

        resolve(userMap);
      })
      .catch(err => reject(err));
  });
}

export function mapUsersBase(serviceId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_ATTENDEE)
      .where("service.id", "==", serviceId)
      .get()
      .then(snapshot => {
        let userMap = {};

        snapshot.forEach(doc => {
          const user = doc.data().user;
          userMap[user.code] = {
            lastName: user.lastName || "",
            firstName: user.firstName || "",
            middleName: user.middleName || "",
            fullName: `${user.firstName} ${user.middleName} ${user.lastName}`,
            gender: user.gender || "",
            birthday: user.birthday || ""
          };
        });

        resolve(userMap);
      })
      .catch(err => reject(err));
  });
}

export function consolidateMapSubmission(scheduleId) {
  return new Promise((resolve, reject) => {
    let submissions = {};

    mapSubmissions(scheduleId)
      .then(submissionMap => {
        submissions = { ...submissionMap };
        return mapUsers(scheduleId);
      })
      .then(userMap => {
        for (const userCode in userMap) {
          if (!(userCode in submissions)) {
            submissions[userCode] = createSubmissionEntry();
          }
          submissions[userCode]["name"] = userMap[userCode];
        }
        resolve(submissions);
      })
      .catch(err => reject(err));
  });
}

export function getLastSubmission(
  scheduleId,
  userCode,
  submissionType,
  lastFunction
) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .where("userCode", "==", userCode)
      .where("type", "==", submissionType)
      .orderBy("timestamp", "desc")
      .limit(1)
      .get()
      .then(snapshot => {
        resolve(lastFunction(snapshot));
      })
      .catch(err => reject(err));
  });
}

export function getLastData(scheduleId, userCode, submissionType) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .where("userCode", "==", userCode)
      .where("type", "==", submissionType)
      .orderBy("timestamp", "desc")
      .limit(1)
      .get()
      .then(snapshot => {
        let lastData = {};

        snapshot.forEach(doc => {
          lastData = { ...doc.data() };
        });

        resolve(lastData);
      })
      .catch(err => reject(err));
  });
}

export function getSubmissions(serviceId) {
  return new Promise((resolve, reject) => {
    let users = {};

    mapUsersBase(serviceId)
      .then(userMap => {
        for (const key in userMap) {
          users[key] = { ...userMap[key], userCode: key };
        }

        return consolidateMapSubmission(serviceId);
      })
      .then(submissions => {
        for (const key in submissions) {
          if (key in users) {
            users[key] = { ...submissions[key], ...users[key] };
          } else {
            users[key] = { ...submissions[key] };
          }
        }

        resolve(users);
      })
      .catch(err => reject(err));
  });
}

async function getPath(path) {
  return firebase
    .storage()
    .ref()
    .child(path)
    .getDownloadURL();
}

async function getFiles(fileList) {
  let uploadData = [];

  for (const file of fileList) {
    const url = await getPath(file.fullPath);
    uploadData.push({ file: file, url: url });
  }

  return uploadData;
}

export async function getUploadData(serviceId, userCode, dataType) {
  if (serviceId && userCode && dataType) {
    const data = await getLastData(serviceId, userCode, dataType);
    if (data.fileList) {
      const uploadData = await getFiles(data.fileList);
      return { uploadData: uploadData, id: data.id };
    } else if (data.filePath) {
      const url = await getPath(data.filePath);
      return { url: url };
    }
  }

  return { url: "" };
}

export function addService(payload) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SERVICES)
      .add({
        name: payload.name,
        schedule: { ...payload.schedule },
        version: payload.version
      })
      .then(id => resolve(id))
      .catch(err => reject(err));
  });
}

export function getOffsiteService() {
  return new Promise((resolve, reject) => {
    let today = new Date();
    today.setHours(0, 0, 0);
    db.collection(DB.OFFSITE_SERVICES)
      .where("schedule.start", ">=", today)
      .orderBy("schedule.start", "asc")
      .get()
      .then(snapshot => {
        let services = [];

        snapshot.forEach(doc => {
          let data = doc.data();
          services.push({
            id: doc.id,
            name: data.name
          });
        });

        resolve(services);
      })
      .catch(err => reject(err));
  });
}

// ------------------------ OFFSITE SERVICES ACTIONS ------------------------ //

function getServiceId(data) {
  return data.service ? data.service.id : "";
}

function getServiceName(data) {
  return data.service ? data.service.name : "";
}

export function getServiceByOffsiteAttendee(userCode) {
  return new Promise((resolve, reject) => {
    if (userCode) {
      db.collection(DB.OFFSITE_ATTENDEE)
        .where("user.code", "==", userCode)
        .get()
        .then(snapshot => {
          let services = [];
          snapshot.forEach(doc => {
            let data = doc.data();
            services.push({
              name: getServiceName(data),
              details: {
                id: getServiceId(data),
                location: data.location,
                schedule: data.timestamp,
                serviceName: getServiceName(data)
              }
            });
          });

          resolve(services);
        })
        .catch(err => reject(err));
    } else {
      resolve();
    }
  });
}
