<template>
  <div>
    <p class="text-center">
      {{ $t("laboratory.appointment.selectPreferredDateTime.header") }}
    </p>

    <v-row class="align-center justify-center pa-1">
      <v-col cols="10" sm="5" class="pa-2">
        <v-select
          v-model="type"
          :items="preferredSchedules"
          label="Preferred"
        />
      </v-col>

      <v-col cols="10" sm="5">
        <SelectDate v-model="date" />
      </v-col>
    </v-row>

    <v-row v-show="isSpecificTime" class="align-center justify-center pa-1">
      <v-col cols="8" class="pa-0">
        {{ $t("laboratory.appointment.selectPreferredDateTime.specificTime") }}
      </v-col>
    </v-row>

    <v-row
      v-show="isSpecificTime"
      class="align-center justify-center mt-0 pa-1"
    >
      <v-col cols="4">
        <SelectTime
          label="From"
          :initialValue="initialTimeStart"
          :key="initialTimeStart"
          @onSelect="timeFrom = $event"
        />
      </v-col>

      <v-col cols="4">
        <SelectTime
          label="To"
          :initialValue="initialTimeEnd"
          :key="initialTimeEnd"
          @onSelect="timeTo = $event"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  SCHEDULE_TYPE_AFTERNOON_ON_DATE,
  SCHEDULE_TYPE_ANYTIME_ON_DATE,
  SCHEDULE_TYPE_MORNING_ON_DATE,
  SCHEDULE_TYPE_SPECIFIC_TIME,
  isSpecificTime
} from "@/common/utilities/appointments/schedule_type";

import { getTime24 } from "@/common/utilities/date";
import { MergeDateAndTimeStrings } from "@/common/utilities/time";

import SelectDate from "@/common/components/controls/SelectDate.vue";
import SelectTime from "@/common/components/controls/SelectTime.vue";

export default {
  name: "SelectPreferredDateTime",

  components: {
    SelectDate,
    SelectTime
  },

  props: {
    initialValue: Object
  },

  data: () => ({
    initialDate: "",
    initialTimeStart: "",
    initialTimeEnd: "",

    preferredSchedules: [],
    suppressNotification: true,

    date: "",
    timeFrom: null,
    timeTo: null,
    type: ""
  }),

  mounted() {
    this.suppressNotification = true;
    const schedule = this.initialValue;

    if (!schedule.date) {
      let date = new Date();
      date.setDate(date.getDate() + 7);
      this.initialDate = date.toISOString().substr(0, 10);
    } else {
      this.initialDate = schedule.date;

      this.initialTimeStart = schedule.start ? getTime24(schedule.start) : "";
      this.timeFrom = schedule.start
        ? `${this.initialTimeStart}:00`
        : "00:00:00";

      this.initialTimeEnd = schedule.end ? getTime24(schedule.end) : "";
      this.timeTo = schedule.end ? `${this.initialTimeEnd}:00` : "00:00:00";
    }

    this.type = schedule.type || "";

    this.date = this.initialDate;

    this.preferredSchedules = [
      SCHEDULE_TYPE_ANYTIME_ON_DATE,
      SCHEDULE_TYPE_MORNING_ON_DATE,
      SCHEDULE_TYPE_AFTERNOON_ON_DATE,
      SCHEDULE_TYPE_SPECIFIC_TIME
    ];

    this.suppressNotification = false;
  },

  methods: {
    emitSelectedSchedule() {
      if (this.suppressNotification) {
        return;
      }

      let schedule = {
        type: this.type,
        date: this.date
      };

      const timeFrom = this.timeFrom ? `${this.timeFrom}:00` : `00:00:00`;
      schedule["start"] = MergeDateAndTimeStrings(this.date, timeFrom);

      if (this.timeTo) {
        schedule["end"] = MergeDateAndTimeStrings(
          this.date,
          `${this.timeTo}:00`
        );
      }

      this.$emit("onSelect", schedule);
    }
  },

  computed: {
    isSpecificTime() {
      return isSpecificTime(this.type);
    }
  },

  watch: {
    date() {
      this.emitSelectedSchedule();
    },

    type() {
      this.emitSelectedSchedule();
    },

    timeFrom() {
      this.emitSelectedSchedule();
    },

    timeTo() {
      this.emitSelectedSchedule();
    }
  }
};
</script>
