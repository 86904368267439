<template>
  <v-card wrap class="elevation-12">
    <v-col class="text-right">
      <v-icon v-on:click="closeDialog" class="pa-4" color="error">
        mdi mdi-close
      </v-icon>
    </v-col>

    <div class="py-10 px-15">
      <h4 class="pb-5 text-center">
        {{ subject }}
      </h4>

      <p class="caption pb-5 text-justify">
        {{ timestamp }}
      </p>

      <div v-if="isHtml" v-html="body"></div>
      <div v-else class="text-justify pre-formatted">{{ body }}</div>

      <qrcode-vue
        v-if="recieveqr"
        :value="recieveqr"
        :size="150"
        level="H"
        class="my-5"
        style="text-align: center;"
      />

      <p v-if="fileAttached" class="caption">
        Attachment
        <br />
        <v-btn
          @click="openUrl(attachment)"
          class="mx-1"
          depressed
          x-small
          color="primary"
          v-for="(attachment, at) in fileAttached"
          :key="at"
        >
          {{ attachment.name }}
        </v-btn>
      </p>
    </div>

    <div class="mt-5 text-center">
      <v-card-actions
        v-if="canConfirm"
        class="justify-center"
        style="display: inline-block;"
      >
        <v-btn
          v-on:click="closeDialog"
          :to="ROUTE_APPOINTMENTS"
          class="mx-2"
          color="primary"
          dark
          small
        >
          <v-icon class="mr-2">mdi-checkbox-marked-circle</v-icon>
          Confirm or Update
        </v-btn>
      </v-card-actions>
      <v-btn
        v-show="showUpload && !isVerifiedPwdSc"
        small
        color="primary"
        @click="dialogUploader = true"
      >
        Resubmit ID
      </v-btn>

      <v-card-actions class="justify-center" style="display: inline-block;">
        <v-btn
          v-on:click="closeDialog"
          @click="dispatchDelete"
          class="mx-2"
          color="red"
          dark
          small
        >
          <v-icon class="mr-2">mdi-delete-forever</v-icon>
          Delete
        </v-btn>
      </v-card-actions>
    </div>

    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="dialogUploader"
      width="600"
    >
      <UploadPwdCs @close-dialog2="uploadConfirmed()" />
    </v-dialog>

    <ConfirmDialog ref="confirm" @onClose="closeDialog()" />
  </v-card>
</template>

<script>
import { ACTION_REDIRECT_TO_APPOINTMENTS } from "@/common/utilities/message";
import { DB, logUserActivity } from "@/common/store";
import {
  PAYMENT_BANTAY_KARD,
  PAYMENT_ON_SITE
} from "@/common/utilities/payment";
import { ROUTE_APPOINTMENTS, ROUTE_LABORATORY, routeTo } from "@/router";
import { SERVICE_LABORATORY } from "@/common/utilities/services";
import {
  STATUS_CANCELLED,
  STATUS_CONFIRMED,
  STATUS_FOR_PAYMENT_VERIFICATION
} from "@/common/utilities/appointments";

import { db } from "@/main";
import {
  deleteMessage,
  setHomeServiceMessageboxStatus,
  statusRead
} from "@/store";
import { mapGetters } from "vuex";
import { setAppointmentHomeService } from "@/store/diagnostic_attendee";
import { timeStampToDate } from "@/common/utilities/date";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import QrcodeVue from "qrcode.vue";
import UploadPwdCs from "@/eTindahan/components/UploadPwdCs.vue";

export default {
  name: "MessageBox",

  data: () => ({
    dialog: false,
    dialogUploader: false,
    total: 0,
    invalidID: "Invalid PWD / Senior Citizen ID ",
    individualPrice: 0,
    packagePrice: 0,

    ROUTE_APPOINTMENTS: ROUTE_APPOINTMENTS,
    SERVICE_LABORATORY: SERVICE_LABORATORY
  }),

  props: {
    body: String,
    docid: String,
    fileAttached: Array,
    homeService: Object,
    recieveqr: String,
    sender: String,
    subject: String,
    timestamp: String,
    msgIndex: Number,

    message: Object,
    isHtml: Boolean
  },

  components: {
    ConfirmDialog,
    QrcodeVue,
    UploadPwdCs
  },

  computed: {
    ...mapGetters(["getPwdScDetails"]),

    bhcdetails2() {
      return {
        id: this.docid,
        clinicType: this.homeService.service.clinicType,
        selectedPreferredDateTime: this.homeService.selectedPreferredDateTime,
        homeServiceLocation: this.homeService.service.homeServiceLocation,
        contact: this.homeService.service.contact,

        registrants: this.homeService.registrants,
        isPaid: true
      };
    },

    canConfirm() {
      return (
        this.message.actions &&
        ACTION_REDIRECT_TO_APPOINTMENTS === this.message.actions.action1 &&
        new Date() < timeStampToDate(this.message.actions.validUntil)
      );
    },

    isVerifiedPwdSc() {
      return this.getPwdScDetails.isVerified;
    },

    showUpload() {
      return this.subject === this.invalidID;
    }
  },

  methods: {
    checkOut(val) {
      switch (val.paidThru) {
        case PAYMENT_BANTAY_KARD:
        case PAYMENT_ON_SITE:
          this.checkoutPayment(
            STATUS_CONFIRMED,
            this.setAgreedPaymentDetails(val)
          );
          return;
      }

      this.checkoutPayment(
        STATUS_FOR_PAYMENT_VERIFICATION,
        this.setForPaymentPaymentDetails(val)
      );
    },

    checkoutPayment(status, paymentDetails) {
      setAppointmentHomeService(paymentDetails);
      this.setHomeServiceStatus({ sID: this.sID, status: status }).then(() => {
        setHomeServiceMessageboxStatus({ docid: this.docid, status: status });
        this.showConfirmationMessage();
      });
    },

    reTitle(data) {
      return data !== "allowed" ? data : "for Confirmation";
    },

    rescheduleTime() {
      this.$store.dispatch("homeServiceData", {
        homeService: this.homeService,
        recieveqr: this.recieveqr,
        msgID: this.docid
      });

      let routeMe = `${ROUTE_LABORATORY}/4/${this.msgIndex}`;

      statusRead({
        status: "read",
        docId: this.docid
      });
      routeTo(routeMe);
    },

    confirmTime(data) {
      let parts = data.split("-");
      this.sID = parts[0];

      statusRead({
        status: "read",
        docId: this.docid
      });
      this.dialog = true;
    },

    async cancelTime(data) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to Cancel Your Home Service Appontment?",
          { noconfirm: false }
        )
      ) {
        let parts = data.split("-");
        this.setHomeServiceStatus({
          sID: parts[0],
          status: STATUS_CANCELLED
        }).then(() => {
          setHomeServiceMessageboxStatus({
            docid: this.docid,
            status: STATUS_CANCELLED
          });
          this.showConfirmationMessageCancelled();
          this.dispatchDelete();
        });
      }
    },

    setAgreedPaymentDetails(val) {
      return {
        id: this.sID,
        clinicType: this.homeService.service.clinicType,
        selectedPreferredDateTime: this.homeService.selectedPreferredDateTime,
        homeServiceLocation: this.homeService.service.homeServiceLocation,

        total: Math.round(val.total),
        paidThru: val.paidThru,
        name: val.name,
        address: val.address,
        contact: val.contact,
        courier: val.courier,

        remainingbalance: val.remainingbalance,
        isBantayKardPayment: val.isBantayKardPayment,

        registrants: this.homeService.registrants,
        isPaid: true
      };
    },

    setForPaymentPaymentDetails(val) {
      return {
        id: this.sID,
        clinicType: this.homeService.service.clinicType,
        selectedPreferredDateTime: this.homeService.selectedPreferredDateTime,
        homeServiceLocation: this.homeService.service.homeServiceLocation,

        total: Math.round(val.total),
        paidThru: val.paidThru,
        name: val.name,
        address: val.address,
        contact: val.contact,
        courier: val.courier,

        amount: val.amount,

        isBantayKardPayment: val.isBantayKardPayment,

        registrants: this.homeService.registrants,
        isPaid: false
      };
    },

    setHomeServiceStatus(payload) {
      return new Promise((resolve, reject) => {
        db.collection(DB.DIAGNOSTIC_APPOINTMENTS)
          .doc(payload.sID)
          .set({ status: payload.status }, { merge: true })
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },

    showConfirmationMessage() {
      this.$refs.confirm.open(
        this.$t("dialog.confirmation"),
        `Naitala na po ang inyong appointment. Mangyaring dumating ng 10 minutong mas maaga sa inyong appointment. Maraming salamat po!`,
        { noconfirm: true }
      );
    },

    showConfirmationMessageCancelled() {
      this.$refs.confirm.open(
        "Cancellation",
        `Na Cancel po ang inyong appointment. Maraming salamat po!`,
        { noconfirm: true }
      );
    },

    openUrl(a) {
      window.open(a.url);
    },

    closeDialog() {
      this.$emit("close-dialog");
    },

    dispatchDelete() {
      logUserActivity("Delete message", {
        docid: this.docid,
        subject: this.subject,
        body: this.body,
        sender: this.sender,
        timestamp: this.timestamp,
        recieveqr: this.recieveqr || ""
      }).then(() => {
        deleteMessage(this.docid);
      });
    },

    uploadConfirmed() {
      this.dialogUploader = false;
      this.$refs.confirm.open(
        "Confirmed",
        `Na-submit na po ang inyong PWD/SC ID. Maraming salamat po!`,
        { noconfirm: true }
      );
    }
  }
};
</script>

<style scoped>
.pre-formatted {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.floaterr {
  float: right;
}
</style>
