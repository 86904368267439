<template>
  <v-parallax height="80" dark :src="require('../assets/parallax.png')">
    <v-icon
      v-if="canClose"
      color="error"
      block
      medium
      @click.stop="$emit('onClose')"
      class="right pt-3 px-3"
    >
      mdi mdi-close
    </v-icon>

    <v-layout align-center column justify-center>
      <h3 class="font-weight-thin my-3 text-center" v-if="isSmallerThanSM">
        <strong>{{ header }}</strong>
      </h3>
      <h2 class="font-weight-thin my-3 text-center" v-else-if="isSmallerThanMD">
        <strong>{{ header }}</strong>
      </h2>
      <h1 class="font-weight-thin my-3 text-center" v-else>
        <strong>{{ header }}</strong>
      </h1>
    </v-layout>
  </v-parallax>
</template>

<script>
import { isSmallerThanMD, isSmallerThanSM } from "@/utilities/display";

export default {
  name: "AppHeader",

  props: {
    header: String,
    canClose: Boolean
  },

  computed: {
    isSmallerThanMD() {
      return isSmallerThanMD(this.$vuetify.breakpoint.width);
    },

    isSmallerThanSM() {
      return isSmallerThanSM(this.$vuetify.breakpoint.width);
    }
  }
};
</script>

<style scoped>
.right {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 3;
}
</style>
