<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center px-1" scope="col">ITEM</th>
            <th class="text-center px-1" scope="col">TOTAL</th>
            <th class="text-center px-1" scope="col" />
          </tr>
        </thead>

        <tbody>
          <tr height="50px" v-for="(cart, clist) in cartorders" :key="clist">
            <td>
              <v-list-item class="px-1">
                <v-list-item-avatar>
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="16 / 9"
                    height="80px"
                    :src="cart.imageUrl"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="primary--text">
                    {{ cart.name }}

                    <strong
                      v-if="isExpired(cart) || cart.isPWDSCPriceApplied"
                      style="background-color: primary;"
                      class="caption primary--text pa-1"
                    >
                      {{ getPromo(cart) }}
                    </strong>
                  </v-list-item-title>
                  <v-list-item-title
                    v-show="cart.preOrderFrom > 0"
                    class="caption"
                  >
                    {{ getPreOrder(cart) }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-text="cart.category" />
                  <v-list-item-subtitle v-text="cart.variation" />
                  <v-list-item-subtitle>
                    {{ cart.quantity }} x
                    <span
                      v-if="cart.discount > 0 && isExpired(cart)"
                      class="mr-1 text-decoration-line-through"
                    >
                      <PesoAmount :amount="Number(cart.originalPrice)" />
                    </span>
                    <span>
                      <PesoAmount
                        :amount="
                          Number(
                            0 === cart.discount
                              ? cart.originalPrice
                              : cart.price
                          )
                        "
                      />
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-show="cart.reason"
                    class="primary--text"
                  >
                    Reason: {{ cart.reason }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-show="cart.rejectedAmount"
                    class="error--text"
                  >
                    Voided: {{ cart.rejectedAmount }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-show="cart.refundAmount"
                    class="error--text"
                  >
                    Cancelled: <PesoAmount :amount="cart.refundAmount" />
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-if="cart.latestReturnRefund">
                    <span
                      v-if="cart.latestReturnRefund.status === STATUS_DONE"
                      class="mr-1 error--text"
                    >
                      was returned/refunded.
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </td>
            <td
              :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
              style="width: 125px;"
              class="text-right px-0"
            >
              <PesoAmount :amount="getTotalPrice(cart)" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { RECEIVE_FOR_PICK_UP, STATUS_DONE } from "@/common/utilities/payment";

import { getPromo, isExpired } from "../utilities/discounts";
import { loadShippingPromo } from "@/common/store/";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "CartItem",

  components: {
    PesoAmount
  },

  props: {
    cartorders: Array,
    receivingOption: String
  },

  data: () => ({
    promo: false,
    startingAmount: 0,

    // functions
    getPromo: getPromo,
    isExpired: isExpired,
    STATUS_DONE: STATUS_DONE
  }),

  watch: {
    cartorders() {
      this.reloadOrders();
    },

    receivingOption() {
      this.reloadOrders();
    }
  },

  methods: {
    getNonPreOrder() {
      let itemShippingFees = [];
      let nonPreOrder = [];

      this.cartorders.forEach(cart => {
        if (this.isDiscountValid(cart)) {
          this.$emit("promo-active", true);
        }

        nonPreOrder.push(
          this.isDiscountValid(cart)
            ? this.netUnitPrice(cart) * cart.quantity
            : cart.price * cart.quantity
        );

        itemShippingFees.push(Number(cart.itemShippingFee));
      });

      let highestShippingFee = 0;
      if (RECEIVE_FOR_PICK_UP !== this.receivingOption) {
        let total = nonPreOrder.reduce((a, b) => a + b, 0);
        highestShippingFee =
          this.promo &&
          (itemShippingFees.length == 0 || total >= this.startingAmount)
            ? 0
            : Math.max(...itemShippingFees);
      }

      this.$emit("highestShippingFee", highestShippingFee);
    },

    getPreOrder(cart) {
      return `Pre-order ${cart.preOrderFrom} - ${cart.preOrderTo} days`;
    },

    getTotalPrice(cart) {
      return isExpired(cart) && !cart.isPWDSCPriceApplied && 0 === cart.discount
        ? Number(cart.originalPrice) * Number(cart.quantity)
        : Number(cart.price) * Number(cart.quantity);
    },

    isDiscountValid(cart) {
      return new Date(cart.discountUntil.seconds * 1000) > new Date();
    },

    netUnitPrice(cart) {
      return Number(cart.price) * ((100 - Number(cart.discount)) / 100);
    },

    reloadOrders() {
      loadShippingPromo().then(docs => {
        this.promo = docs.promo;
        this.startingAmount = Number(docs.startingAmount);

        this.getNonPreOrder();
      });
    }
  }
};
</script>
