import { ROUTE_DASHBOARD } from "@/router";

const CREDENTIAL_FAILED = "medisureCredentialFailed";
const CREDENTIAL_LINK_FAILED = "medisureCredentialLinkFailed";
const IS_INITIALIZED = "medisureIsInitialized";
const PATH_TO_LOAD_AFTER_LOGIN = "medisurePathToLoadAfterLogin";
const SIGNIN_ERROR = "medisureSigninError";

const REGISTRATION_FIRST_NAME = "medisureFirstName";
const REGISTRATION_LAST_NAME = "medisureLastName";
const REGISTRATION_CONTACT_NUMBER = "medisureContactNumber";
const REGISTRATION_EMAIL = "medisureEmail";
const REGISTRATION_DEFAULT_LOCALE = "medisureLocale";

function GetBoolValue(key) {
  let value = window.sessionStorage.getItem(key);
  return value ? value : false;
}

function getValue(key) {
  return window.sessionStorage.getItem(key);
}

export function GetCredentialFailed() {
  return GetBoolValue(CREDENTIAL_FAILED);
}

export function getCredentialLinkFailed() {
  return GetBoolValue(CREDENTIAL_LINK_FAILED);
}

export function GetPathToLoadAfterLogin() {
  return window.sessionStorage.getItem(PATH_TO_LOAD_AFTER_LOGIN);
}

export function GetPathToLoadAfterLoginOrDefault() {
  return (
    window.sessionStorage.getItem(PATH_TO_LOAD_AFTER_LOGIN) || ROUTE_DASHBOARD
  );
}

export function getRegistrationDetails() {
  let value = getValue(REGISTRATION_FIRST_NAME);
  if (value) {
    return {
      firstName: value,
      lastName: getValue(REGISTRATION_LAST_NAME),
      contactNumber: getValue(REGISTRATION_CONTACT_NUMBER),
      email: getValue(REGISTRATION_EMAIL),
      locale: getValue(REGISTRATION_DEFAULT_LOCALE)
    };
  }

  return null;
}

export function getSigninError() {
  return GetBoolValue(SIGNIN_ERROR);
}

export function IsInitialized() {
  return GetBoolValue(IS_INITIALIZED);
}

export function RemoveRegistrationDetails() {
  window.sessionStorage.removeItem(REGISTRATION_FIRST_NAME);
  window.sessionStorage.removeItem(REGISTRATION_LAST_NAME);
  window.sessionStorage.removeItem(REGISTRATION_CONTACT_NUMBER);
  window.sessionStorage.removeItem(REGISTRATION_EMAIL);
  window.sessionStorage.removeItem(REGISTRATION_DEFAULT_LOCALE);
}

function SetValueOrRemove(key, value) {
  if (value) {
    window.sessionStorage.setItem(key, value);
  } else {
    window.sessionStorage.removeItem(key);
  }
}

export function InitializeApp() {
  SetValueOrRemove(IS_INITIALIZED, true);
}

function setValue(key, value) {
  window.sessionStorage.setItem(key, value);
}

export function SetCredentialFailed(value = false) {
  SetValueOrRemove(CREDENTIAL_FAILED, value);
}

export function setCredentialLinkFailed(value = false) {
  SetValueOrRemove(CREDENTIAL_LINK_FAILED, value);
}

export function setPathToLoadAfterLogin(value = "") {
  SetValueOrRemove(PATH_TO_LOAD_AFTER_LOGIN, value);
}

export function setRegistrationDetails(
  firstName,
  lastName,
  contactNumber,
  email,
  locale
) {
  setValue(REGISTRATION_FIRST_NAME, firstName);
  setValue(REGISTRATION_LAST_NAME, lastName);
  setValue(REGISTRATION_CONTACT_NUMBER, contactNumber);
  setValue(REGISTRATION_EMAIL, email);
  setValue(REGISTRATION_DEFAULT_LOCALE, locale);
}

export function setSigninError(value = false) {
  SetValueOrRemove(SIGNIN_ERROR, value);
}
