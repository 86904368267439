<template>
  <v-card flat class="pt-4 justify-center">
    <v-checkbox
      class="pa-0"
      v-model="withPhilhealth"
      label="Philhealth Provider"
    />

    <EditableMemberInfo v-if="isPersonalInfo" class="background-grey pa-4" />

    <div class="pa-4" v-else>
      <v-row v-show="withPhilhealth">
        <v-col class="py-0">
          <v-text-field
            v-model="philhealthId"
            label="PhilHealth Identification Number"
            :rules="philhealthRules"
            @keydown="onKeyDown"
          />
        </v-col>

        <v-col class="py-0">
          <v-autocomplete
            v-model="clientType"
            label="Client Type"
            :items="['Member', 'Dependent']"
            :rules="fieldRules"
          />
        </v-col>

        <v-col class="py-0">
          <v-autocomplete
            v-model="membershipCategory"
            label="Membership Category"
            :items="membershipCategories"
            :rules="fieldRules"
          />
        </v-col>
      </v-row>

      <v-row v-show="withPhilhealth">
        <v-col class="py-0">
          <v-text-field
            v-model="authorizationTransactionCode"
            label="Authorization Transction Code"
            :rules="fieldRules"
          />
        </v-col>
        <v-col class="py-0">
          <SimpleDatePicker text="Assessment Date" v-model="assessmentDate" />
        </v-col>
      </v-row>
    </div>

    <v-card-actions v-show="!hideButton" class="justify-center pt-0">
      <v-btn
        class="ma-0"
        v-if="isPersonalInfo"
        color="primary"
        small
        :disabled="isNextDisabled"
        @click="onNext"
      >
        Confirm
      </v-btn>

      <v-btn
        class="ma-0"
        v-else
        color="primary"
        small
        :disabled="isNextDisabledPhilhealth"
        @click="onNext"
      >
        Confirm
      </v-btn>
    </v-card-actions>

    <v-snackbar color="primary" v-model="snackbar" :timeout="2000">
      Health Profile Updated
    </v-snackbar>
  </v-card>
</template>

<script>
import { DB, getCurrentUserUid, logUserActivity } from "@/common/store";
import {
  NS_MEMBERINFO as MEMBERINFO,
  NS_PHILHEALTHINFO as PHILHEALTHINFO
} from "@/store";

import EditableMemberInfo from "@/MemberInfo/components/EditableMemberInfo.vue";
import SimpleDatePicker from "@/common/components/controls/SimpleDatePicker.vue";

export default {
  name: "MemberInfo",

  components: {
    EditableMemberInfo,
    SimpleDatePicker
  },

  props: {
    hideButton: Boolean,
    isPersonalInfo: Boolean
  },

  data: () => ({
    fieldRules: [v => !!v || "This field is required"],
    membershipCategories: [
      "Employed",
      "Indigent (NHTS)",
      "Sponsored",
      "Lifetime",
      "Senior Citizen",
      "Informal",
      "OFW (Land-based)",
      "OFW (Sea-based)"
    ],
    philhealthRules: [],
    snackbar: false
  }),

  mounted() {
    this.philhealthRules = [
      v => !!v || "This field is required",
      v => this.isPhilHealthIdValid(v) || "PhilHealth ID is not valid"
    ];

    this.$store.dispatch(`${PHILHEALTHINFO}/load`);
  },

  methods: {
    isPhilHealthIdValid(value) {
      return /\d{2}-\d{9}-\d/.test(value);
    },

    onKeyDown(event) {
      if (/^[A-Za-z]{1}$/.test(event.key)) {
        event.preventDefault();
      } else if (/^\d$/.test(event.key)) {
        switch (this.philhealthId.length) {
          case 2:
          case 12:
            this.philhealthId += "-";
            break;

          default:
            if (this.philhealthId.length > 13) {
              event.preventDefault();
            }
            break;
        }
      }
    },

    async onNext() {
      const target = {
        collection: DB.PHILHEALTH_INDEX,
        docId: getCurrentUserUid()
      };

      await this.$store.dispatch(`${MEMBERINFO}/save`, target);
      await this.$store.dispatch(`${PHILHEALTHINFO}/save`, target);

      this.snackbar = true;
      this.$emit("updateDialog", false);
      this.onSubmit();
    },

    async onSubmit() {
      const timestamp = new Date();

      await this.$store.dispatch(`${PHILHEALTHINFO}/save`, {
        collection: DB.PHILHEALTH_INDEX,
        docId: getCurrentUserUid()
      });
      await logUserActivity("Submit PhilHealth information", {
        timestamp: timestamp
      });

      this.isSubmitted = true;
    }
  },

  computed: {
    assessmentDate: {
      get() {
        return this.$store.getters[`${PHILHEALTHINFO}/getAssessmentDate`];
      },
      set(value) {
        this.$store.commit(`${PHILHEALTHINFO}/setAssessmentDate`, value);
      }
    },

    authorizationTransactionCode: {
      get() {
        return this.$store.getters[
          `${PHILHEALTHINFO}/getAuthorizationTransactionCode`
        ];
      },
      set(value) {
        this.$store.commit(
          `${PHILHEALTHINFO}/setAuthorizationTransactionCode`,
          value
        );
      }
    },

    clientType: {
      get() {
        return this.$store.getters[`${PHILHEALTHINFO}/getClientType`];
      },
      set(value) {
        this.$store.commit(`${PHILHEALTHINFO}/setClientType`, value);
      }
    },

    isNextDisabled() {
      return (
        !this.$store.getters[`${MEMBERINFO}/getFirstName`] ||
        !this.$store.getters[`${MEMBERINFO}/getMiddleName`] ||
        !this.$store.getters[`${MEMBERINFO}/getLastName`] ||
        !this.$store.getters[`${MEMBERINFO}/getBirthday`] ||
        !this.$store.getters[`${MEMBERINFO}/getSex`]
      );
    },

    isNextDisabledPhilhealth() {
      return (
        !this.philhealthId ||
        !this.isPhilHealthIdValid(this.philhealthId) ||
        !this.clientType ||
        !this.membershipCategory
      );
    },

    membershipCategory: {
      get() {
        return this.$store.getters[`${PHILHEALTHINFO}/getMembershipCategory`];
      },
      set(value) {
        this.$store.commit(`${PHILHEALTHINFO}/setMembershipCategory`, value);
      }
    },

    philhealthId: {
      get() {
        return this.$store.getters[`${PHILHEALTHINFO}/getPhilHealthId`];
      },
      set(value) {
        this.$store.commit(`${PHILHEALTHINFO}/setPhilHealthId`, value);
      }
    },

    withPhilhealth: {
      get() {
        return this.$store.getters[`${PHILHEALTHINFO}/getWithPhilhealth`];
      },
      set(value) {
        this.$store.commit(`${PHILHEALTHINFO}/setWithPhilhealth`, value);
      }
    }
  }
};
</script>
