export const banks = [
  "AllBank, Inc",
  "Asia United Bank Corporation",
  "BDO",
  "BDO Network Bank",
  "BPI/BPI Family Savings Bank",
  "BanKo, A subsidiary of BPI",
  "Bangko Mabuhay, Inc.",
  "Bank of Commerce",
  "Bayad",
  "CTBC Bank (Philippines) Corp.",
  "Camalig Bank, Inc.",
  "Cebuana Lhuillier Bank / Cebuana Xpress",
  "China Bank",
  "China Bank Savings",
  "Coins.PH",
  "Dungganon Bank, Inc.",
  "East West Banking Corporation",
  "Equicom Savings Bank, Inc.",
  "GCash",
  "GrabPay",
  "ING Bank, N.V Manila Branch",
  "Isla Bank Inc",
  "JuanCash",
  "Komo / East West Rural Bank, Inc.",
  "Landbank/OF Bank",
  "Malayan Bank Savings & Mortgage Bank, Inc.",
  "Maybank Philippines, Inc.",
  "Metrobank",
  "OmniPay",
  "PBCOM",
  "PNB",
  "PSBank",
  "Partner Rural Bank (Cotabato), Inc.",
  "PayMaya",
  "Philippine Business Bank, Inc.",
  "Philippine Trust Company",
  "Quezon Capital Rural Bank",
  "RCBC / DiskarTech",
  "Robinsons Bank Corporation",
  "Rural Bank of Guinobatan, Inc.",
  "Security Bank Corporation",
  "ShopeePay",
  "Starpay",
  "Sterling Bank of Asia, Inc.",
  "Sun Savings Bank",
  "TayoCash",
  "UCPB",
  "UCPB Savings Bank, Inc.",
  "USSC Money Services",
  "Veterans Bank"
];
