<template>
  <div class="pa-0">
    <v-card flat align="center">
      <v-card-title class="subtitle-2">
        <v-icon class="px-1" color="primary"> mdi-truck-fast-outline</v-icon>
        Shipping fee
      </v-card-title>
      <v-card-subtitle class="caption">
        {{ SHIPPING_POLICY }}
      </v-card-subtitle>
    </v-card>

    <!-- <v-card flat>
      <v-card-title class="subtitle-2">
        <v-icon class="px-1" color="primary">
          mdi-notebook-edit-outline
        </v-icon>
        COD Policy
      </v-card-title>
      <v-card-subtitle class="caption">{{ COD_POLICY }}</v-card-subtitle>
    </v-card> -->

    <v-card flat>
      <v-card-title class="subtitle-2">
        <v-icon class="px-1" color="primary"> mdi-tag-multiple-outline </v-icon>
        Return Policy
      </v-card-title>
      <v-card-subtitle class="caption">{{ RETURN_POLICY }}</v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "EventPolicy",

  data: () => ({
    RETURN_POLICY:
      "We hope you love what you've ordered! But just in case you're not 100% satisfied, we've made the return process super easy.",
    SHIPPING_POLICY: "Free standard shipping on orders over ₱300."

    // KURT!!! What does this mean?
    // COD_POLICY: "Cash on Delivery Available for orders below ₱25,000"
  })
};
</script>
