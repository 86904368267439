<template>
  <v-container fluid>
    <p
      v-for="(selectedPast, past) in pastMedicalHistory.selectedPast"
      :key="past"
    >
      {{ past + 1 }}. {{ selectedPast }}<strong class="floaterr"> Yes </strong>
    </p>

    <div>
      Comment:
      <strong class="floaterr">
        {{ pastMedicalHistory.selectedPastExpComment }}
      </strong>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "MedicalHistoryPastMedicalHistoryView",

  props: {
    pastMedicalHistory: Object
  },

  data: () => ({
    selectedPast: [],
    selectedPastExpComment: ""
  }),

  methods: {
    genData() {
      let pastMedicalHistory = {
        selectedPast: this.selectedPast,
        selectedPastExpComment: this.selectedPastExpComment
      };
      this.$emit("onPastMedicalHistory", pastMedicalHistory);
    }
  },

  watch: {
    selectedPast() {
      this.genData();
    },

    selectedPastExpComment() {
      this.genData();
    }
  }
};
</script>

<style scoped>
.floaterr {
  float: right;
}
</style>
