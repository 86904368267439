<template>
  <v-row class="py-5">
    <v-col>
      <v-card
        class="my-2 mx-1"
        min-width="320"
        min-height="130"
        @click="$emit('selectedPaymentOption', 'GCash')"
      >
        <v-card-title class="primary--text">
          <v-avatar>
            <img
              :src="require('../../assets/gcash-logo-square.png')"
              alt="John"
            />
          </v-avatar>
          GCash
        </v-card-title>
        <v-card-subtitle>
          Everything You Need To Know (Make Life Easier) - go!
        </v-card-subtitle>
      </v-card>

      <v-card
        class="my-2 mx-1"
        min-width="320"
        min-height="130"
        @click="$emit('selectedPaymentOption', 'UnionBank')"
      >
        <v-card-title class="primary--text">
          <v-avatar class="ma-2">
            <img
              :src="require('../../assets/unionbank-logo-square.png')"
              alt="John"
            />
          </v-avatar>
          UnionBank
        </v-card-title>
        <v-card-subtitle>
          "The future begins with u"
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PaymentOptions"
};
</script>

<style scoped>
.price-entry {
  text-align: right !important;
  width: 110px;
}
.floaterl {
  float: left;
}
</style>
