<template>
  <v-row class="pt-5">
    <v-col cols="12" md="4" sm="4" class="py-0">
      <v-autocomplete
        v-model="selectedProvince"
        :items="provinces"
        :search-input.sync="searchProvince"
        hide-no-data
        hide-details
        label="Provinces"
        item-text="label"
        item-value="details"
        @change="provinceChanged"
        :readonly="isDisabled"
      />
    </v-col>

    <v-col cols="12" md="4" sm="4" class="py-0">
      <v-autocomplete
        v-model="selectedMunicipality"
        :items="municipalities"
        :search-input.sync="searchMunicipality"
        hide-no-data
        hide-details
        item-text="label"
        item-value="details"
        label="Municipality / City"
        @change="municipalityChanged"
      />
    </v-col>

    <v-col cols="12" md="4" sm="4" class="py-0" v-show="!isDisabledBrgy">
      <v-autocomplete
        v-model="selectedLocation"
        :items="barangays"
        :search-input.sync="searchBarangay"
        hide-no-data
        hide-details
        :readonly="isDisabled"
        label="Barangay"
        item-text="label"
        item-value="details"
        @change="emitData"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SelectLocation",

  props: {
    isDisabledBrgy: {
      type: Boolean,
      default: false
    },
    dbLocation: String,
    isDisabled: Boolean
  },

  data: () => ({
    provinces: [],
    municipalities: [],
    barangays: [],

    selectedProvince: null,
    selectedMunicipality: null,
    selectedLocation: null,

    searchProvince: null,
    searchMunicipality: null,
    searchBarangay: null
  }),

  created() {
    this.loadProvinces();
  },

  mounted() {
    let lastSelectedLocation = {};
    if (this.$store.getters["doctor/appointment/getTransactionStatus"]) {
      lastSelectedLocation = this.$store.getters[
        "doctor/appointment/getLocation"
      ];
    } else {
      lastSelectedLocation = this.$store.state.profile.lastSelectedLocation;
    }

    if (lastSelectedLocation.province) {
      this.setSelectedProvince(lastSelectedLocation);
    }

    if (lastSelectedLocation.municipality) {
      this.loadMunicipalities(this.selectedProvince);
      this.setSelectedMunicipality(lastSelectedLocation);
    }

    if (lastSelectedLocation.barangay) {
      this.loadBarangays(this.selectedMunicipality);
      this.setSelectedBarangay(lastSelectedLocation);
    }
    if (!this.isDisabledBrgy) {
      if (lastSelectedLocation.barangay) {
        this.loadBarangays(this.selectedMunicipality);
        this.setSelectedBarangay(lastSelectedLocation);
      }
      this.$emit("update:selectedLocation", this.selectedLocation);
      this.$emit("selectedLocation", this.selectedLocation);
    } else {
      this.$emit("update:selectedLocation", this.selectedMunicipality);
      this.$emit("selectedLocation", this.selectedMunicipality);
    }
  },

  methods: {
    loadBarangays(loc) {
      this.selectedBarangays = null;
      this.barangays = [];

      this.$store
        .dispatch("getBarangays", { ...loc, dbLocation: this.dbLocation })
        .then(barangays => (this.barangays = barangays));
    },

    loadMunicipalities(loc) {
      this.selectedMunicipality = null;
      this.selectedBarangays = null;
      this.municipalities = [];
      this.barangays = [];

      this.$store
        .dispatch("getMunicipalities", { ...loc, dbLocation: this.dbLocation })
        .then(municipalities => (this.municipalities = municipalities));
    },

    loadProvinces() {
      this.$store
        .dispatch("getProvinces", this.dbLocation)
        .then(provinces => (this.provinces = provinces));
    },

    municipalityChanged(val) {
      this.selectedMunicipality = val;

      if (!this.isDisabledBrgy) {
        this.loadBarangays(val);
        this.selectedLocation = null;
        this.$emit("update:selectedLocation", this.selectedLocation);
        this.$emit("selectedLocation", this.selectedLocation);
      } else {
        this.$store.dispatch(
          "saveProfileLastSelectedLocation",
          this.selectedMunicipality
        );
        this.$emit("update:selectedLocation", this.selectedMunicipality);
        this.$emit("selectedLocation", this.selectedMunicipality);
      }
    },

    provinceChanged(val) {
      this.selectedProvince = val;
      this.loadMunicipalities(val);

      this.selectedLocation = null;
      this.$emit("update:selectedLocation", this.selectedLocation);
      this.$emit("selectedLocation", this.selectedLocation);
    },

    setSelectedBarangay(val) {
      this.selectedLocation = {
        region: val.region,
        province: val.province,
        municipality: val.municipality,
        barangay: val.barangay
      };
    },

    setSelectedMunicipality(val) {
      this.selectedMunicipality = {
        region: val.region,
        province: val.province,
        municipality: val.municipality
      };
    },

    setSelectedProvince(val) {
      this.selectedProvince = {
        region: val.region,
        province: val.province
      };
    },

    emitData() {
      this.$emit("update:selectedLocation", this.selectedLocation);
      this.$emit("selectedLocation", this.selectedLocation);
    }
  },

  watch: {
    selectedLocation(location) {
      this.$store.dispatch("saveProfileLastSelectedLocation", location);
    }
  }
};
</script>
