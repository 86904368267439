<template>
  <v-card>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr v-show="latestReturnRefund.status">
            <td>Status</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.status }}
              <span class="caption">
                ({{
                  getDateStringFromTimestamp(latestReturnRefund.requestedAt)
                }})
              </span>
            </td>
          </tr>

          <tr v-show="latestReturnRefund.selectedRefundOption">
            <td>Payment Option</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.selectedRefundOption }}
            </td>
          </tr>

          <tr v-show="latestReturnRefund.sellerNote">
            <td>Merchant Note</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.sellerNote }}
            </td>
          </tr>

          <tr v-show="latestReturnRefund.pickUpDate">
            <td>Pickup Date</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.pickUpDate }}
            </td>
          </tr>

          <tr v-show="latestReturnRefund.pickUpTime">
            <td>Pickup Time</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.pickUpTime }}
            </td>
          </tr>

          <tr v-show="latestReturnRefund.reinstateOption">
            <td>Option</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.reinstateOption }}
            </td>
          </tr>

          <tr v-show="latestReturnRefund.reasonOption">
            <td>Reason</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.reasonOption }}
            </td>
          </tr>

          <tr v-show="latestReturnRefund.accountBank && isBankPayment()">
            <td>Bank Option</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.accountBank }}
            </td>
          </tr>

          <tr v-show="latestReturnRefund.accountName && isBankPayment()">
            <td>Bank Account Name</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.accountName }}
            </td>
          </tr>

          <tr v-show="latestReturnRefund.accountNumber && isBankPayment()">
            <td>Bank Account Number</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.accountNumber }}
            </td>
          </tr>

          <tr v-show="latestReturnRefund.contact && isBankPayment()">
            <td>Contact</td>
            <td class="text-right width-60">
              {{ latestReturnRefund.contact }}
            </td>
          </tr>
          <tr v-show="latestReturnRefund.refundAmount && isBankPayment()">
            <td>Total Refund</td>
            <td class="text-right width-60">
              <PesoAmount :amount="Number(latestReturnRefund.refundAmount)" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { OPTION_BANK_ACCOUNT } from "@/common/utilities/payment";

import { getDateStringFromTimestamp } from "@/common/utilities/date";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "ReturnRefundDetails",

  components: {
    PesoAmount
  },

  props: {
    latestReturnRefund: Object
  },

  data: () => ({
    getDateStringFromTimestamp: getDateStringFromTimestamp
  }),

  methods: {
    isBankPayment() {
      return (
        OPTION_BANK_ACCOUNT === this.latestReturnRefund.selectedRefundOption
      );
    }
  }
};
</script>
