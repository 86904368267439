<template>
  <v-card flat class="pa-4">
    <RefundPaymentOptions
      :balance="balance"
      @selected-refundOption="selectedRefundOption = $event"
      @selected-accountBank="accountBank = $event"
      @selected-accountNumber="accountNumber = $event"
      @selected-accountName="accountName = $event"
      @selected-contact="contact = $event"
    />

    <v-card-actions class="pa-0 justify-center">
      <v-btn
        v-if="OPTION_BANK_ACCOUNT === selectedRefundOption"
        :disabled="!isFieldValid"
        x-small
        color="primary"
        @click="setRefund()"
      >
        Transfer to Bank
      </v-btn>

      <v-btn
        v-else
        :disabled="!isFieldValid"
        x-small
        color="primary"
        @click="setRefund()"
      >
        Request BantayKard Transfer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  OPTION_BANK_ACCOUNT,
  OPTION_BANTAY_KARD
} from "@/common/utilities/payment.js";
import { ROUTE_TRACKORDER, routeTo } from "@/router";

import { checkOutRefundPayment } from "@/store/payment";

import RefundPaymentOptions from "@/eTindahan/components/RefundPaymentOptions.vue";

export default {
  name: "RefundExcess",

  components: {
    RefundPaymentOptions
  },

  data: () => ({
    accountBank: "",
    accountName: "",
    accountNumber: "",
    balance: 0,
    contact: "",
    refundDetails: {},
    selectedRefundOption: "",

    OPTION_BANK_ACCOUNT: OPTION_BANK_ACCOUNT
  }),

  mounted() {
    this.refundDetails = this.$store.getters[
      "etindahan/checkout/getRefundDetails"
    ];
    this.balance = this.refundDetails.checkout.balance;
    this.selectedRefundOption = OPTION_BANTAY_KARD;
  },

  computed: {
    isFieldValid() {
      return (
        (this.accountBank !== "" &&
          this.accountNumber !== "" &&
          this.accountName !== "" &&
          this.contact !== "" &&
          OPTION_BANK_ACCOUNT === this.selectedRefundOption) ||
        OPTION_BANTAY_KARD === this.selectedRefundOption
      );
    }
  },

  methods: {
    getTotalRefund() {
      return this.balance;
    },

    setRefund() {
      let refundDetails = this.refundDetails.checkout;
      refundDetails.userUid = refundDetails.soldTo;
      refundDetails["isExcessPayment"] = true;
      refundDetails["paymentOption"] = this.selectedRefundOption;
      refundDetails["accountBank"] = this.accountBank;
      refundDetails["accountNumber"] = this.accountNumber;
      refundDetails["accountName"] = this.accountName;
      refundDetails["accountContact"] = this.contact;
      refundDetails["excessAmount"] = this.getTotalRefund();

      refundDetails.referenceNumber = "";

      checkOutRefundPayment(refundDetails).then(() => {
        routeTo(ROUTE_TRACKORDER);
      });
    }
  }
};
</script>
