<template>
  <v-card class="pa-0 hidden">
    <v-row class="pt-6">
      <v-col>
        <v-timeline dense>
          <v-timeline-item
            fill-dot
            class="white--text mb-12"
            color="primary"
            large
          >
            <template v-slot:icon>
              <span>TH</span>
            </template>
            <p class="headline primary--text">Transaction History</p>
            <p class="caption primary--text" />
          </v-timeline-item>

          <v-slide-x-transition group>
            <v-timeline-item
              v-for="(event, elist) in timeline"
              :key="elist"
              class="mb-4"
              color="primary"
              small
            >
              <v-row justify="space-between">
                {{ event.data.comment }}
                <br />
                {{ event.data.courier }}

                {{ getDateTime(event.data.timestamp) }}
              </v-row>
            </v-timeline-item>
          </v-slide-x-transition>
        </v-timeline>
      </v-col>

      <v-col class="pa-0">
        <v-simple-table>
          <template v-slot:default>
            <tbody v-for="(checkout, cout) in checkouts" :key="cout">
              <tr>
                <td>QR Code</td>
                <td>
                  <v-avatar class="ma-3" size="125" tile>
                    <qrcode-vue
                      class="py-2"
                      :value="checkouts[0].data.fullqr || 0"
                      :size="100"
                      level="H"
                    />
                  </v-avatar>
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{{ checkout.data.status }}</td>
              </tr>
              <tr>
                <td>Delivery/Billing Address</td>
                <td class="width-60">
                  {{ getDeliveryAddress(checkout.data) }}
                </td>
              </tr>
              <tr>
                <td>Contact</td>
                <td class="width-60">{{ checkout.data.contact }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td class="width-60">{{ checkout.data.name }}</td>
              </tr>
              <tr>
                <td>Receiving Option</td>
                <td class="width-60">{{ checkout.data.receivingOption }}</td>
              </tr>
              <tr>
                <td>Payment Method</td>
                <td class="width-60">
                  {{ getPaymentMethodsDisplay(checkout.data.payments) }}
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td class="width-60">
                  <strong><PesoAmount :amount="checkout.data.total"/></strong>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <CartItem :cartorders="cartorders" class="mt-5" />
  </v-card>
</template>

<script>
import { DB } from "@/common/store";
import { NS_STORE } from "@/store";

import { db } from "@/main";
import { getDateTime } from "@/common/utilities/date";
import { getFullAddress3, getAddress } from "@/common/utilities/location";
import { getPaymentMethodsDisplay } from "../utilities/payments";
import { mapGetters } from "vuex";

import CartItem from "@/eTindahan/components/CartItem.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";
import QrcodeVue from "qrcode.vue";

export default {
  name: "eTindahanSummaryOrder",

  components: {
    CartItem,
    PesoAmount,
    QrcodeVue
  },

  data: () => ({
    checkouts: [],
    timeline: [],

    // functions
    getDateTime: getDateTime,
    getPaymentMethodsDisplay: getPaymentMethodsDisplay
  }),

  mounted() {
    this.loadSelectedData();
  },

  computed: {
    ...mapGetters({
      cartorders: `${NS_STORE}/checkout/getCartItem`,
      orderid: `${NS_STORE}/checkout/getOrderId`
    })
  },

  methods: {
    getDeliveryAddress(data) {
      return getFullAddress3(data.selectedLocation) + getAddress(data);
    },

    loadSelectedCheckout() {
      db.collection(DB.CHECKOUT)
        .doc(this.orderid)
        .get()
        .then(doc => {
          this.checkouts = [];
          if (doc.exists) {
            this.checkouts.push({
              data: doc.data()
            });
          }
        });
    },

    loadSelectedData() {
      this.loadSelectedCheckout();
      this.loadSelectedDelivery();
    },

    loadSelectedDelivery() {
      db.collection(DB.DELIVERY)
        .where("orderid", "==", this.orderid)
        .orderBy("timestamp", "desc")
        .get()
        .then(snapshot => {
          this.timeline = [];
          snapshot.forEach(doc => {
            this.timeline.push({
              id: doc.id,
              data: doc.data()
            });
          });
        });
    }
  }
};
</script>

<style scoped>
.hidden {
  overflow: hidden; /* Hide scrollbars */
}

.width-60 {
  width: 60%;
}
</style>
