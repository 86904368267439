<template>
  <div>
    <p class="text-center mt-3 pt-3">
      Please provide your personal information.
    </p>

    <v-row class="mb-5 mt-10 px-2">
      <v-col :cols="colWidth1" class="py-0">
        <v-text-field
          v-model="philhealthId"
          label="PhilHealth Identification Number"
          :rules="philhealthRules"
          @keydown="onKeyDown"
        />
      </v-col>

      <v-col :cols="colWidth2" class="py-0">
        <v-autocomplete
          v-model="clientType"
          label="Client Type"
          :items="['Member', 'Dependent']"
          :rules="fieldRules"
        />
      </v-col>

      <v-col :cols="colWidth1" class="py-0">
        <v-autocomplete
          v-model="membershipCategory"
          label="Membership Category"
          :items="membershipCategories"
          :rules="fieldRules"
        />
      </v-col>
    </v-row>

    <EditableMemberInfo class="background-grey mt-2 px-2" />

    <NextButton
      text="Update"
      :disabled="isNextDisabled"
      @click="onNext"
      class="mb-5 mt-10"
    />
  </div>
</template>

<script>
import { DB, getCurrentUserUid } from "@/common/store";
import {
  NS_MEMBERINFO as MEMBERINFO,
  NS_PHILHEALTHINFO as PHILHEALTHINFO
} from "@/store";

import { setWidth } from "@/utilities/display";

import EditableMemberInfo from "@/MemberInfo/components/EditableMemberInfo.vue";
import NextButton from "@/components/Controls/NextButton.vue";

export default {
  name: "MemberInfo",

  components: {
    EditableMemberInfo,
    NextButton
  },

  data: () => ({
    fieldRules: [v => !!v || "This field is required"],
    membershipCategories: [
      "Employed",
      "Indigent (NHTS)",
      "Sponsored",
      "Lifetime",
      "Senior Citizen",
      "Informal",
      "OFW (Land-based)",
      "OFW (Sea-based)"
    ],
    philhealthRules: []
  }),

  mounted() {
    this.philhealthRules = [
      v => !!v || "This field is required",
      v => this.isPhilHealthIdValid(v) || "PhilHealth ID is not valid"
    ];

    this.$store.dispatch(`${PHILHEALTHINFO}/load`);
  },

  methods: {
    isPhilHealthIdValid(value) {
      return /\d{2}-\d{9}-\d/.test(value);
    },

    onKeyDown(event) {
      if (/^[A-Za-z]{1}$/.test(event.key)) {
        event.preventDefault();
      } else if (/^\d$/.test(event.key)) {
        switch (this.philhealthId.length) {
          case 2:
          case 12:
            this.philhealthId += "-";
            break;

          default:
            if (this.philhealthId.length > 13) {
              event.preventDefault();
            }
            break;
        }
      }
    },

    onNext() {
      const target = {
        collection: DB.PHILHEALTH_INDEX,
        docId: getCurrentUserUid()
      };

      this.$store
        .dispatch(`${MEMBERINFO}/save`, target)
        .then(() => {
          return this.$store.dispatch(`${PHILHEALTHINFO}/save`, target);
        })
        .then(() => {
          this.$emit("next");
        });
    }
  },

  computed: {
    authorizationTransactionCode: {
      get() {
        return this.$store.getters[
          `${PHILHEALTHINFO}/getAuthorizationTransactionCode`
        ];
      },
      set(value) {
        this.$store.commit(
          `${PHILHEALTHINFO}/setAuthorizationTransactionCode`,
          value
        );
      }
    },

    clientType: {
      get() {
        return this.$store.getters[`${PHILHEALTHINFO}/getClientType`];
      },
      set(value) {
        this.$store.commit(`${PHILHEALTHINFO}/setClientType`, value);
      }
    },

    colWidth1() {
      return setWidth(this.$vuetify.breakpoint.width, 3, 4, 6, 12, 12);
    },

    colWidth2() {
      return setWidth(this.$vuetify.breakpoint.width, 2, 3, 4, 12, 12);
    },

    isNextDisabled() {
      return (
        !this.philhealthId ||
        !this.isPhilHealthIdValid(this.philhealthId) ||
        !this.clientType ||
        !this.membershipCategory ||
        !this.$store.getters[`${MEMBERINFO}/getFirstName`] ||
        !this.$store.getters[`${MEMBERINFO}/getMiddleName`] ||
        !this.$store.getters[`${MEMBERINFO}/getLastName`] ||
        !this.$store.getters[`${MEMBERINFO}/getBirthday`] ||
        !this.$store.getters[`${MEMBERINFO}/getSex`]
      );
    },

    membershipCategory: {
      get() {
        return this.$store.getters[`${PHILHEALTHINFO}/getMembershipCategory`];
      },
      set(value) {
        this.$store.commit(`${PHILHEALTHINFO}/setMembershipCategory`, value);
      }
    },

    philhealthId: {
      get() {
        return this.$store.getters[`${PHILHEALTHINFO}/getPhilHealthId`];
      },
      set(value) {
        this.$store.commit(`${PHILHEALTHINFO}/setPhilHealthId`, value);
      }
    }
  }
};
</script>
