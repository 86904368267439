<template>
  <v-row>
    <v-col cols="1" />
    <v-col class="mt-n4" cols="10">
      <v-select
        return-object
        v-model="service"
        @change="serviceSelected"
        :items="services"
        item-text="displayName"
        label="Serbisyong nais mapakinabangan"
      />
    </v-col>
  </v-row>
</template>

<script>
import { CLINIC_TYPE_MOBILE_VAN } from "@/common/utilities/appointments";
import { DB } from "@/common/store";

import { db } from "@/main";

export default {
  name: "SelectService",

  props: {
    subheader: String,
    clinicType: String
  },

  data: () => ({
    service: null,
    services: []
  }),

  mounted() {
    this.readType();
  },

  watch: {
    clinicType() {
      this.readType();
    },

    subheader() {
      this.readType();
    }
  },

  methods: {
    readType() {
      if (CLINIC_TYPE_MOBILE_VAN === this.clinicType) {
        this.getClinicsbyMobile();
      } else {
        this.getClinicsbyStationaryOrHomeService();
      }
    },

    getClinicsbyStationaryOrHomeService() {
      db.collection(DB.DIAGNOSTIC_CLINICS)
        .where("type", "==", this.clinicType)
        .get()
        .then(snapshot => {
          this.updateServices(snapshot);
        });
    },

    getClinicsbyMobile() {
      db.collection(DB.DIAGNOSTIC_CLINICS)
        .where("type", "==", this.clinicType)
        .where("location", "==", this.subheader)
        .get()
        .then(snapshot => {
          this.updateServices(snapshot);
        });
    },

    serviceSelected() {
      this.$emit("setSelectedService", this.service);
    },

    updateServices(snapshot) {
      this.service = null;
      this.services = [];

      snapshot.forEach(doc => {
        let data = doc.data();
        this.services.push({
          displayName: `${data.name} sa ${data.location}`,
          id: doc.id,
          location: data.location,
          name: data.name
        });
      });
    }
  }
};
</script>
