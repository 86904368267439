<template>
  <div>
    <v-parallax height="25" dark :src="require('@/assets/parallax.png')" />

    <v-card color="primary" dark height="100" class="mt-2">
      <v-row
        color="grey lighten-4"
        align="center"
        justify="center"
        class="fill-height mx-0 px-0"
      >
        <v-card color="primary" class="headline text-center px-0" flat>
          {{ title }}
        </v-card>

        <br />
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",

  props: {
    title: String
  }
};
</script>
