import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import {
  DB,
  getCurrentUser,
  getCurrentUserUid,
  initializeDBNames,
  setCurrentUser,
  setCurrentUserEmail
} from "@/common/store";
import { ROUTE_PRIMARY_MEMBER, routeTo } from "@/router";
import {
  RemoveRegistrationDetails,
  getRegistrationDetails
} from "@/utilities/session";
import { addToCart, getCartContents } from "@/utilities/collection/addtocart";
import {
  areSameLocations,
  getBarangays,
  getLocations,
  getMunicipalities,
  getNCRMunicipalities,
  getProvinces
} from "@/common/location";
import { debugError, debugLog, setStaging } from "@/common/main";
import { firestoreAction, vuexfireMutations } from "vuexfire";
import {
  getUnusedGCashTransaction,
  setGcashToProcessed
} from "@/store/payment";

import { LOCALE_TL } from "@/common/utilities/locale";
import { Location } from "./location";
import { ORDER_STATUS_COMPLETED } from "@/common/utilities/order";
import { PAYMENT_GCASH } from "@/common/utilities/payment";
import { STATUS_UNREAD } from "@/common/utilities/message";
import Vue from "vue";
import Vuex from "vuex";
import { contactInfo } from "@/MemberInfo/store/contact_info";
import { contactTracing } from "@/ContactTracing/store";
import { db } from "@/main";
import { ensureUserHasUserCode } from "@/store/profile";
import firebase from "firebase/app";
import { getDateTimeFromDateAsKey } from "@/common/utilities/date";
import { getItem } from "@/utilities/collection/productvariant";
import { healthRecords } from "@/HealthRecords/store";
import i18n from "../plugins/i18n";
import { memberInfo } from "@/MemberInfo/store";
import { philhealthInfo } from "@/PhilHealth/store";
import { tindahan } from "./etindahan/checkout";
import { version } from "@/store/version";

Vue.use(Vuex);

setStaging(false);
initializeDBNames();

//----------------------------- PROFILE HELPERS -----------------------------//

export function setDisplayName(passthroughData) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PROFILES)
      .doc(getCurrentUserUid())
      .set({ displayName: getCurrentUser() }, { merge: true })
      .then(() => resolve(passthroughData))
      .catch(err => reject(err));
  });
}

function emptyAuth() {
  return {
    loggedIn: "",
    uid: "",
    email: ""
  };
}

function emptyCart() {
  return {
    cartCount: 0,
    openCart: 0,
    bantayKardRemainingBalance: 0
  };
}

function emptyProfile() {
  return {
    contact: "",
    displayName: "Not specified",
    defaultAddress: "",
    appointments: [],
    lastSelectedLocation: {
      region: "",
      province: "",
      municipality: "",
      barangay: ""
    },
    lastSelectedAddress: {
      houseNumber: "",
      street: "",
      landmark: ""
    },
    userCode: "",
    locale: LOCALE_TL,

    imageBase: require("@/assets/avatar.jpg"),
    pwdScDetails: {},
    photoUrl: "",
    token: "",

    bantayKardBalance: 0,
    hasAgreedToPolicy: false,
    insurance: [],
    verificationRequested: false,

    isVerified: false,
    roles: []
  };
}

//------------------------------ QUERY HELPERS ------------------------------//

function snapshotToArray(snapshot) {
  return new Promise(resolve => {
    let result = [];

    snapshot.forEach(doc => {
      result.push({
        id: doc.id,
        data: doc.data()
      });
    });

    resolve(result);
  });
}

// flag if feature is enabled
export const FEATURE_KONSULTA = false;
export const FEATURE_LABORATORY = false;

export const NS_CONTACT_INFO = "contactInfo";
export const NS_CONTACT_TRACING = "contactTracing";
export const NS_HEALTHRECORDS = "healthRecords";
export const NS_HEALTHRECORDS_FAMILYHISTORY = `${NS_HEALTHRECORDS}/familyHistory`;
export const NS_HEALTHRECORDS_MEDICALHISTORY = `${NS_HEALTHRECORDS}/medicalHistory`;
export const NS_HEALTHRECORDS_PERSONALSOCIALHISTORY = `${NS_HEALTHRECORDS}/personalSocialHistory`;
export const NS_HEALTHRECORDS_VITALSIGNS = `${NS_HEALTHRECORDS}/vitalSigns`;
export const NS_LGU = "lgu";
export const NS_LOCATION = "location";
export const NS_LOCATIONS_ALL = `${NS_LOCATION}/all`;
export const NS_LOCATIONS_HOMESERVICE = `${NS_LOCATION}/homeService`;
export const NS_MEMBERINFO = "memberInfo";
export const NS_PHILHEALTHINFO = "philhealthInfo";
export const NS_STORE = "etindahan";
export const NS_VERSION = "version";

export const SESSION_TIMEOUT_SECS = 5 * 60;

const allLocations = new Location("locations");
const homeServiceLocations = new Location("locations");

export default new Vuex.Store({
  modules: {
    [NS_CONTACT_INFO]: {
      namespaced: true,
      ...contactInfo
    },

    [NS_CONTACT_TRACING]: {
      namespaced: true,
      ...contactTracing
    },

    [NS_HEALTHRECORDS]: {
      namespaced: true,
      ...healthRecords
    },

    [NS_LOCATION]: {
      namespaced: true,

      modules: {
        all: {
          namespaced: true,
          ...allLocations
        },

        homeService: {
          namespaced: true,
          ...homeServiceLocations
        }
      }
    },

    [NS_MEMBERINFO]: {
      namespaced: true,
      ...memberInfo
    },

    [NS_PHILHEALTHINFO]: {
      namespaced: true,
      ...philhealthInfo
    },

    [NS_STORE]: {
      namespaced: true,

      modules: {
        checkout: {
          namespaced: true,
          ...tindahan
        }
      }
    },

    [NS_VERSION]: {
      namespaced: true,
      ...version
    }
  },

  state: {
    addressBook: {},
    selectedAddress: {},
    resultID: {},
    urinalysisData: {},
    fecalysisData: {},
    doctorsAssessment: {},
    triage: {},
    auth: emptyAuth(),

    locations: {},

    checkouts: [],
    displayheader: [],
    lguServiceAppointments: [],
    messagebox: [],
    offsiteServiceAppointments: [],
    productbox: [],
    productboxbycat: [],
    productboxsold: [],
    productboxcatsold: [],
    productboxrating: [],
    productboxcatrating: [],
    productboxprice: [],
    productboxcatprice: [],

    productboxSearch: [],
    productboxsoldSearch: [],
    productboxratingSearch: [],
    productboxpriceSearch: [],

    wishlists: [],

    profileLoaded: false,
    profile: emptyProfile(),
    homeservice: [],
    diagnostic: {
      scheduleDocs: []
    },
    summary: {},
    capture: {},
    pageRoute: {
      previous: ""
    },
    discounts: [],
    tindahan: {
      temporaryCart: [],
      cartCount: 0,
      openCart: 0,
      bantayKardRemainingBalance: 0,
      cartItem: {},
      cartItemInventory: 0,
      productDetails: {},
      itemStock: 0
    },

    modeOfPayment: "",
    searchedProducts: "",

    secondsIdle: 0,
    notification: {},
    selectedOffsiteService: ""
  },

  getters: {
    getFecalysisData(state) {
      return state.fecalysisData;
    },

    getUrinalysisData(state) {
      return state.urinalysisData;
    },

    getDoctorsAssessment(state) {
      return state.doctorsAssessment;
    },

    getResultServiceId(state) {
      return state.resultID.serviceId;
    },

    getResultServiceSchedule(state) {
      return state.resultID.serviceSchedule;
    },

    getResultUserCode(state) {
      return state.resultID.userCode;
    },

    getSelectedOffsiteService(state) {
      return state.selectedOffsiteService;
    },

    getTriage(state) {
      return state.triage;
    },

    getAddressBook(state) {
      return state.addressBook;
    },

    getAvatarUrl(state) {
      return state.profile.imageBase;
    },

    getBantayKardBalance(state) {
      return state.profile.bantayKardBalance;
    },

    getBantayKardRemainingBalance(state) {
      return state.tindahan.bantayKardRemainingBalance;
    },

    getBirthday(state) {
      return state.profile.birthday;
    },

    getCapturedImage(state) {
      return state.capture;
    },

    getCartCount(state) {
      return state.tindahan.cartCount;
    },

    getCartItem(state) {
      return state.tindahan.cartItem;
    },

    getCartItemInventory(state) {
      return state.tindahan.cartItemInventory;
    },

    getContact(state) {
      return state.profile.contact;
    },

    getDefaultAddress(state) {
      return state.profile.defaultAddress;
    },

    getDisplayName(state) {
      return state.profile.displayName;
    },

    getFirstName(state) {
      return state.profile.firstName;
    },

    getInvalidPwdScDetails(state) {
      return state.profile.invalidPwdScDetails;
    },

    getHasAgreedToPolicy(state) {
      return state.profile.hasAgreedToPolicy;
    },

    getLastName(state) {
      return state.profile.lastName;
    },

    getLastSelectedAddress(state) {
      return state.profile.lastSelectedAddress;
    },

    getLocale(state) {
      return state.profile.locale;
    },

    getMiddleName(state) {
      return state.profile.middleName;
    },

    getMinutesIdle(state) {
      return state.secondsIdle;
    },

    getModeOfPayment(state) {
      return state.modeOfPayment;
    },

    getNotification(state) {
      return state.notification;
    },

    getPreviousRoute(state) {
      return state.pageRoute.previous;
    },

    getProductDetails(state) {
      return state.tindahan.productDetails;
    },

    getPwdScDetails(state) {
      return state.profile.pwdScDetails;
    },

    getSecondsToLogout(state) {
      const secondsLeft = SESSION_TIMEOUT_SECS - state.secondsIdle;
      return secondsLeft < 0 ? 0 : secondsLeft;
    },

    getSearchedProducts(state) {
      return state.searchedProducts;
    },

    getSelectedAddress(state) {
      return state.selectedAddress;
    },

    getSex(state) {
      return state.profile.gender;
    },

    getSummaryMessage(state) {
      return state.summary.message;
    },

    getUserCode(state) {
      return state.profile.userCode;
    },

    isLoggedIn(state) {
      return state.auth.loggedIn || getCurrentUserUid() ? true : false;
    },

    isVerificationRequested(state) {
      return state.profile.verificationRequested;
    },

    isVerified(state) {
      return state.profile.isVerified;
    }
  },

  mutations: {
    ...vuexfireMutations,

    ADD_CHECKOUT(state, checkout) {
      state.checkouts.push(checkout);
    },

    addDiagnosticSchedule({ diagnostic }, data) {
      diagnostic.scheduleDocs.push(data);
    },

    addUserAppointment({ profile }, appointment) {
      profile.appointments.push(appointment);
    },

    clearDiagnosticSchedules({ diagnostic }) {
      diagnostic.scheduleDocs = [];
    },

    INCREMENT_CART_COUNT(state, count) {
      state.tindahan.cartCount = state.tindahan.cartCount + Number(count);
    },

    INCREMENT_SECONDS_IDLE(state) {
      state.secondsIdle += 1;
    },

    loadProfileData(state, data) {
      for (let key in data) {
        state.profile[key] = data[key];
      }

      state.profileLoaded = true;
    },

    reHomeServiceData({ homeservice }, data) {
      homeservice.push(data);
    },

    RESET_SECONDS_IDLE(state) {
      state.secondsIdle = 0;
    },

    RESET_STATE(state) {
      state.auth = emptyAuth();

      state.locations = {};

      state.profileLoaded = false;
      state.profile = emptyProfile();
      state.homeservice = [];
      state.diagnostic = {
        scheduleDocs: []
      };
      state.summary = {};
      state.pageRoute = {};
      state.discounts = [];
      state.tindahan = emptyCart();
      state.botika = emptyCart();
    },

    SET_SELECTED_OFFSITE_SERVICE(state, offsiteService) {
      state.selectedOffsiteService = offsiteService;
    },

    SET_ADDRESS_BOOK_DETAILS(state, addressBook) {
      state.addressBook = addressBook;
    },

    SET_AGREETOPOLICY({ profile }, policy) {
      profile.hasAgreedToPolicy = policy;
    },

    SET_CAPTURED_IMAGE({ capture }, image) {
      capture.image = image;
    },

    SET_CART_COUNT(state, count) {
      state.tindahan.cartCount = Number(count);
    },

    SET_CONTACT({ profile }, contact) {
      if (contact) {
        profile.contact = contact;
      }
    },

    SET_MODE_OF_PAYMENT(state, value) {
      state.modeOfPayment = value;
    },

    SET_NOTIFICATION(state, notification) {
      state.notification = notification;
    },

    SET_PRODUCT_DETAILS(state, productDetails) {
      state.tindahan.productDetails = productDetails;
    },

    SET_PROFILE_KEY_DATA({ profile }, payload) {
      profile[payload.key] = payload.data;
    },

    SET_PROFILE_TOKEN({ profile }, token) {
      profile.token = token;
    },

    SET_PWDSC_DETAILS({ profile }, pwdDetails) {
      profile.pwdScDetails = pwdDetails;
    },

    SET_SEARCHED_PRODUCTS(state, searchedProducts) {
      state.searchedProducts = searchedProducts;
    },

    SET_SELECTED_ADDRESS(state, selectedAddress) {
      state.selectedAddress = selectedAddress;
    },

    setResultsID(state, resultID) {
      state.resultID = { ...resultID };
    },

    setFecalysisData(state, fecalysisData) {
      state.fecalysisData = fecalysisData;
    },

    setUrinalysisData(state, urinalysisData) {
      state.urinalysisData = urinalysisData;
    },

    setDoctorAssessment(state, doctorsAssessment) {
      state.doctorsAssessment = { ...doctorsAssessment };
    },

    setTriage(state, triage) {
      state.triage = triage;
    },

    setBantayKardBalance({ profile }, balance) {
      profile.bantayKardBalance = balance;
    },

    setDiscounts({ state }, data) {
      // revisit this later, in some instances state is undefeined
      if (state) {
        state.discounts = [...data];
      }
    },

    setLocations(state, locations) {
      state.locations = locations;
    },

    setPreviousPage({ pageRoute }, prev) {
      pageRoute.previous = prev;
    },

    setProfileLastSelectedAddress({ profile }, address) {
      if (address) {
        if (address.houseNumber) {
          profile.lastSelectedAddress.houseNumber = address.houseNumber;
        }

        if (address.street) {
          profile.lastSelectedAddress.street = address.street;
        }

        if (address.landmark) {
          profile.lastSelectedAddress.landmark = address.landmark;
        }
      }
    },

    setProfileLastSelectedLaboratory({ profile }, registrants) {
      if (registrants) {
        profile.registrants = registrants;
      }
    },

    setProfileLastSelectedLocation({ profile }, location) {
      if (location) {
        if (location.region) {
          profile.lastSelectedLocation.region = location.region;
        }

        if (location.province) {
          profile.lastSelectedLocation.province = location.province;
        }

        if (location.municipality) {
          profile.lastSelectedLocation.municipality = location.municipality;
        }

        if (location.barangay) {
          profile.lastSelectedLocation.barangay = location.barangay;
        }
      }
    },

    setProfilePhoto({ profile }, url) {
      profile.photoUrl = url;
    },

    setSelectedCartDetails(state, cartItem) {
      state.tindahan.cartItem = cartItem;
    },

    setSelectedCartDetailsInventory(state, cartItem) {
      state.tindahan.cartItemInventory = cartItem;
    },

    setSummaryMessage({ summary }, message) {
      summary.message = message;
    },

    setUser(state, user) {
      if (user) {
        setCurrentUser(user.uid, user.displayName);
        setCurrentUserEmail(user.email);

        state.auth.uid = user.uid;
        state.auth.email = user.email;
        state.auth.displayName = user.displayName;
        state.auth.loggedIn = true;

        state.profile.locale = user.locale;
        i18n.locale = user.locale;

        // User is signed in.
      } else {
        setCurrentUser();
        setCurrentUserEmail();

        state.auth.loggedIn = false;
        state.auth.uid = "";
        state.auth.email = "";
        state.profileLoaded = false;
        state.profile = emptyProfile();
      }

      state.secondsIdle = 0;
    },

    setUserAppointment({ profile }, appointments) {
      profile.appointments = appointments;
    },

    setVerificationRequest({ profile }) {
      profile.verificationRequested = true;
    },

    UPDATE_AVATAR({ profile }, image) {
      profile.imageBase = image;
    }
  },

  actions: {
    addCheckout({ commit }, checkout) {
      return new Promise(() => {
        commit("ADD_CHECKOUT", checkout);
      });
    },

    addBantayKardTransaction({ dispatch }, transaction) {
      return new Promise((resolve, reject) => {
        let key = getDateTimeFromDateAsKey();

        db.collection(DB.BANTAY_KARD)
          .doc(getCurrentUserUid())
          .set(
            {
              [key]: {
                amount: transaction.amount,
                description: transaction.description,
                referenceNumber: transaction.referenceNumber || "",
                type: transaction.type,
                status: transaction.status
              }
            },
            { merge: true }
          )
          .then(() => {
            dispatch("updateBantayKardBalance", transaction.amount)
              .then(() => resolve())
              .catch(() => reject());
          })
          .catch(() => reject());
      });
    },

    addInsuranceTransaction({ state, dispatch }, transaction) {
      db.collection(DB.INSURANCE_ACCOUNT)
        .add({
          beneficiaries: { ...transaction.beneficiaries },
          benefits: { ...transaction.benefits },
          name: transaction.name,
          status: { ...transaction.status },
          premium: transaction.amount * -1,
          user: getCurrentUserUid()
        })
        .then(doc => {
          let insurances = state.profile.insurances;
          if (insurances) {
            insurances.push(doc.id);
          } else {
            insurances = [doc.id];
          }

          db.collection(DB.PROFILES)
            .doc(getCurrentUserUid())
            .update({ insurances: insurances });
        });

      dispatch("addBantayKardTransaction", transaction);
    },

    addToCart({ commit }, details) {
      return new Promise((resolve, reject) => {
        addToCart(details)
          .then(() => {
            commit("INCREMENT_CART_COUNT", details.quantity);
            resolve();
          })
          .catch(err => reject(err));
      });
    },

    agreeToPolicy({ commit }, hasAgreedToPolicy) {
      return new Promise((resolve, reject) => {
        db.collection(DB.PROFILES)
          .doc(getCurrentUserUid())
          .set({ hasAgreedToPolicy: hasAgreedToPolicy }, { merge: true })
          .then(() => {
            commit("SET_AGREETOPOLICY", hasAgreedToPolicy);
            resolve();
          })
          .catch(() => reject());
      });
    },

    getBarangays({ dispatch }, loc) {
      return new Promise((resolve, reject) => {
        dispatch("getLocations", loc.dbLocation)
          .then(locations => {
            resolve(
              getBarangays(
                locations,
                loc.region,
                loc.province,
                loc.municipality
              )
            );
          })
          .catch(err => reject(err));
      });
    },

    getLocations({ state, commit }, dbLocations) {
      return new Promise((resolve, reject) => {
        if (state.locations.size > 0) {
          resolve(state.locations);
        } else {
          getLocations(dbLocations)
            .then(locations => {
              commit("setLocations", locations);
              resolve(locations);
            })
            .catch(err => reject(err));
        }
      });
    },

    getMunicipalities({ dispatch }, loc) {
      return new Promise((resolve, reject) => {
        dispatch("getLocations", loc.dbLocation)
          .then(locations =>
            resolve(getMunicipalities(locations, loc.region, loc.province))
          )
          .catch(err => reject(err));
      });
    },

    getNCRMunicipalities({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch("getLocations", "locations")
          .then(locations => resolve(getNCRMunicipalities(locations)))
          .catch(err => reject(err));
      });
    },

    getProvinces({ dispatch }, dbLocations) {
      return new Promise((resolve, reject) => {
        dispatch("getLocations", dbLocations)
          .then(locations => resolve(getProvinces(locations)))
          .catch(err => reject(err));
      });
    },

    homeServiceData({ commit }, payload) {
      return new Promise(resolve => {
        commit("reHomeServiceData", payload);
        resolve(payload);
      });
    },

    loadAllowedDatesBHC({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        db.collection(DB.SERVICESCHEDULE)
          .where("type", "in", ["Stationary", "Mobile"])
          .where("location", "==", payload.thelocation)
          .where("start", ">=", payload.nowDate)
          .orderBy("start", "asc")
          .limit(20)
          .get()
          .then(snapshot => {
            return dispatch("resetDiagnosticSchedules", snapshot);
          })
          .then(result => resolve(result))
          .catch(err => reject(err));
      });
    },

    loadCart({ commit }) {
      return new Promise((resolve, reject) => {
        getCartContents()
          .then(snapshot => {
            let docs = [];
            let length = 0;

            snapshot.forEach(doc => {
              let data = doc.data();
              docs.push({ ...data, id: doc.id });

              if (data.quantity) {
                length += Number(data.quantity);
              }

              getItem(data.vproductSKU).then(item => {
                let itemCount = item.exists ? item.data().vstock : 0;
                if (itemCount === 0) {
                  length--;
                }
                commit("SET_CART_COUNT", length);
              });
            });

            resolve(docs);
          })
          .catch(err => reject(err));
      });
    },

    loadCheckout: firestoreAction(context => {
      return context.bindFirestoreRef(
        "checkouts",
        db
          .collection(DB.CHECKOUT)
          .where("soldTo", "==", getCurrentUserUid())
          .orderBy("soldOn", "desc")
      );
    }),

    loadDiagnosticBHCSchedule({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        db.collection(DB.DIAGNOSTIC_SCHEDULES)
          //.where("type", "in", ["Stationary", "Mobile"])
          .where("location", "==", payload.thelocation)
          .where(
            "start",
            "<",
            new Date(payload.nowDate.setDate(payload.nowDate.getDate() + 1))
          )
          .where(
            "start",
            ">",
            new Date(payload.nowDate.setDate(payload.nowDate.getDate() - 1))
          )
          .orderBy("start", "asc")
          .limit(50)
          .get()
          .then(snapshot => {
            return dispatch("resetDiagnosticSchedules", snapshot);
          })
          .then(result => resolve(result))
          .catch(err => reject(err));
      });
    },

    loadDiscounts({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.discounts.length) {
          resolve(state.discounts);
        }

        db.collection(DB.DISCOUNTS)
          .get()
          .then(snapshot => {
            let discounts = [];

            snapshot.forEach(doc => {
              discounts.push({ ...doc.data(), id: doc.id });
            });

            commit("setDiscounts", discounts);
            resolve(discounts);
          })
          .catch(err => reject(err));
      });
    },

    loadDisplay: firestoreAction(context => {
      return context.bindFirestoreRef(
        "displayheader",
        db.collection(DB.DISPLAY)
      );
    }),

    loadMessageBox: firestoreAction(context => {
      return context.bindFirestoreRef(
        "messagebox",
        db
          .collection(DB.MESSAGE_BOX)
          .where("receiver", "==", getCurrentUserUid())
          .orderBy("timestamp", "desc")
          .limit(20)
      );
    }),

    loadProductBoxSearched: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productboxSearch",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("section", "==", container.section)
          .where("tags", "array-contains-any", container.search)
          .limit(50)
      );
    }),

    loadProductBox: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productbox",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("section", "==", container.section)
          .limit(50)
      );
    }),

    loadProductBoxbyCat: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productboxbycat",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("searcheableCategory", "in", container.arrname)
          .where("section", "==", container.section)
          .limit(50)
      );
    }),

    loadProductBoxCatSortPrice: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productboxcatprice",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("searcheableCategory", "in", container.arrname)
          .where("section", "==", container.section)
          .orderBy("lowestPrice", container.direction)
          .limit(50)
      );
    }),

    loadProductBoxPrice: firestoreAction((context, container) => {
      let sort = container.direction === "asc" ? "lowestPrice" : "highestPrice";
      return context.bindFirestoreRef(
        "productboxprice",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("section", "==", container.section)
          .orderBy(sort, container.direction) //Sorting to apply TOP SALES
          .limit(50)
      );
    }),

    loadProductBoxRating: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productboxrating",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("section", "==", container.section)
          .orderBy("rating", "desc") //Sorting to apply POPULAR
          .limit(50)
      );
    }),

    loadProductBoxCatSortRating: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productboxcatrating",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("searcheableCategory", "in", container.arrname)
          .where("section", "==", container.section)
          .orderBy("rating", "desc")
          .limit(50)
      );
    }),

    loadProductBoxSold: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productboxsold",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("section", "==", container.section)
          .orderBy("sold", "desc") //Sorting to apply TOP SALES
          .limit(50)
      );
    }),

    loadProductBoxSoldSearched: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productboxsoldSearch",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("section", "==", container.section)
          .where("tags", "array-contains-any", container.search)
          .orderBy("sold", "desc") //Sorting to apply TOP SALES
          .limit(50)
      );
    }),

    loadProductBoxRatingSearched: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productboxratingSearch",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("section", "==", container.section)
          .where("tags", "array-contains-any", container.search)
          .orderBy("rating", "desc") //Sorting to apply TOP SALES
          .limit(50)
      );
    }),

    loadProductBoxPriceSearched: firestoreAction((context, container) => {
      let sort = container.direction === "asc" ? "lowestPrice" : "highestPrice";
      return context.bindFirestoreRef(
        "productboxpriceSearch",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("section", "==", container.section)
          .where("tags", "array-contains-any", container.search)
          .orderBy(sort, container.direction) //Sorting to apply TOP SALES
          .limit(50)
      );
    }),

    loadProductBoxCatSortSold: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "productboxcatsold",
        db
          .collection(DB.CATALOG)
          .where("state", "==", "available")
          .where("searcheableCategory", "in", container.arrname)
          .where("section", "==", container.section)
          .orderBy("sold", "desc")
          .limit(50)
      );
    }),

    loadProfile({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.profileLoaded) {
          resolve(state.profile);
        } else {
          let userId = getCurrentUserUid();
          db.collection(DB.PROFILES)
            .doc(userId)
            .get()
            .then(doc => {
              let data = doc.data();
              return data.displayName
                ? Promise.resolve(data)
                : setDisplayName(data);
            })
            .then(data => {
              return ensureUserHasUserCode(userId, data);
            })
            .then(data => {
              if (data.roles && data.roles.includes("Offsite Primary Member")) {
                routeTo(ROUTE_PRIMARY_MEMBER);
              }
              commit("loadProfileData", data);
              resolve(state.profile);
            })
            .catch(err => reject(err));
        }
      });
    },

    loadServiceAppointments: firestoreAction((context, mapKey) => {
      let today = new Date();
      today.setHours(0, 0, 0);

      return context.bindFirestoreRef(
        mapKey,

        db
          .collection(DB.LGU_ATTENDEE)
          .where("user.id", "==", getCurrentUserUid())
          .where("appointment.start", ">=", today)
          .orderBy("appointment.start", "asc")
          .limit(20)
      );
    }),

    loadServiceAppointmentsBHC: firestoreAction((context, mapKey) => {
      let today = new Date();
      today.setHours(0, 0, 0);

      return context.bindFirestoreRef(
        mapKey,

        db
          .collection(DB.DIAGNOSTIC_ATTENDEE)
          .where("user.id", "==", getCurrentUserUid())
          .where("appointment.start", ">=", today)
          .where("payment.isPaid", "==", true)
          .orderBy("appointment.start", "asc")
          .limit(20)
      );
    }),

    loadServiceAppointmentsOffsite: firestoreAction((context, mapKey) => {
      let today = new Date();
      today.setHours(0, 0, 0);

      return context.bindFirestoreRef(
        mapKey,

        db
          .collection(DB.OFFSITE_ATTENDEE)
          .where("user.id", "==", getCurrentUserUid())
          .where("appointment.start", ">=", today)
          .orderBy("appointment.start", "asc")
          .limit(20)
      );
    }),

    loadWishlists: firestoreAction((context, container) => {
      return context.bindFirestoreRef(
        "wishlists",
        db
          .collection(DB.CATALOG)
          .where("section", "==", container.section)
          .where("wishedBy", "array-contains", getCurrentUserUid())
          .limit(50)
      );
    }),

    reloadPageIfOldVersion({ dispatch, getters }) {
      return new Promise(resolve => {
        dispatch(`${NS_VERSION}/load`).then(() => {
          if (getters[`${NS_VERSION}/getShouldReload`]) {
            window.location.reload();
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },

    resetContent({ commit, dispatch }) {
      return new Promise(resolve => {
        dispatch(`${NS_CONTACT_INFO}/resetContent`);
        dispatch(`${NS_CONTACT_TRACING}/resetContent`);
        dispatch(`${NS_HEALTHRECORDS}/resetContent`);
        dispatch(`${NS_LOCATIONS_ALL}/resetContent`);
        dispatch(`${NS_LOCATIONS_HOMESERVICE}/resetContent`);
        dispatch(`${NS_MEMBERINFO}/resetContent`);
        dispatch(`${NS_PHILHEALTHINFO}/resetContent`);
        dispatch(`${NS_STORE}/checkout/resetContent`);
        dispatch(`${NS_VERSION}/resetContent`);

        commit("RESET_STATE");
        resolve();
      });
    },

    resetDiagnosticSchedules({ state, commit }, snapshot) {
      return new Promise(resolve => {
        commit("clearDiagnosticSchedules");

        snapshot.forEach(doc => {
          commit("addDiagnosticSchedule", {
            id: doc.id,
            data: doc.data()
          });
        });

        resolve(state.diagnostic.scheduleDocs);
      });
    },

    resetSecondsIdle({ commit, state }) {
      return new Promise(resolve => {
        commit("RESET_SECONDS_IDLE");
        resolve(state.secondsIdle);
      });
    },

    saveDoctorAppointment({ state, commit }, selectedAppointment) {
      return new Promise((resolve, reject) => {
        db.collection(DB.APPOINTMENTS)
          .doc(selectedAppointment.id)
          .get()
          .then(appointment => {
            let appointmentData = appointment.data();
            if (appointmentData.attendeeCount >= appointmentData.slots) {
              reject("Time slot full");
            } else {
              appointment.ref
                .update({
                  attendeeCount: firebase.firestore.FieldValue.increment(1),
                  attendees: firebase.firestore.FieldValue.arrayUnion({
                    profile: getCurrentUserUid(),
                    name: state.profile.displayName
                  })
                })
                .then(() => {
                  let savedAppointment = {
                    id: appointment.id,
                    type: appointmentData.type,
                    when: appointmentData.start,
                    where: appointmentData.location
                  };
                  db.collection(DB.PROFILES)
                    .doc(getCurrentUserUid())
                    .update({
                      appointments: firebase.firestore.FieldValue.arrayUnion(
                        savedAppointment
                      )
                    })
                    .then(() => {
                      commit("addUserAppointment", savedAppointment);
                      resolve();
                    })
                    .catch(err => reject(err));
                });
            }
          })
          .catch(err => reject(err));
      });
    },

    setProfileKeyData({ commit }, value) {
      return new Promise(resolve => {
        commit("SET_PROFILE_KEY_DATA", value);
        resolve(value);
      });
    },

    saveProfile({ state }) {
      return new Promise((resolve, reject) => {
        if (state.profile) {
          db.collection(DB.PROFILES)
            .doc(getCurrentUserUid())
            .update(state.profile)
            .then(() => resolve(state.profile))
            .catch(err => reject(err));
        }
      });
    },

    saveProfileContact({ commit }, contact) {
      return new Promise((resolve, reject) => {
        db.collection(DB.USER_VALIDATION)
          .doc(getCurrentUserUid())
          .set({ contact: contact }, { merge: true })
          .then(() => {
            commit("SET_CONTACT", contact);
            resolve(contact);
          })
          .catch(err => reject(err));
      });
    },

    saveProfileLastSelectedAddress({ commit }, address) {
      return new Promise((resolve, reject) => {
        db.collection(DB.PROFILES)
          .doc(getCurrentUserUid())
          .set({ lastSelectedAddress: { ...address } }, { merge: true })
          .then(() => {
            commit("setProfileLastSelectedAddress", address);
            resolve(address);
          })
          .catch(err => reject(err));
      });
    },

    saveProfileLastSelectedLocation({ state, commit }, location) {
      return new Promise((resolve, reject) => {
        if (
          !getCurrentUserUid() ||
          areSameLocations(location, state.profile.lastSelectedLocation)
        ) {
          resolve(location);
        } else {
          db.collection(DB.PROFILES)
            .doc(getCurrentUserUid())
            .set({ lastSelectedLocation: { ...location } }, { merge: true })
            .then(() => {
              commit("setProfileLastSelectedLocation", location);
              resolve(location);
            })
            .catch(err => reject(err));
        }
      });
    },

    setCapturedImage({ commit }, image) {
      return new Promise(resolve => {
        commit("SET_CAPTURED_IMAGE", image);
        resolve(image);
      });
    },

    setCartCount({ commit }, value) {
      commit("SET_CART_COUNT", value);
      return Promise.resolve(value);
    },

    setModeOfPayment({ commit }, value) {
      commit("SET_MODE_OF_PAYMENT", value);
    },

    setProfilePhotoUrl({ commit }, img) {
      return new Promise((resolve, reject) => {
        firebase
          .storage()
          .ref(`profile/${getCurrentUserUid()}`)
          .putString(img, "data_url")
          .then(doc => {
            doc.ref.getDownloadURL().then(url => {
              db.collection(DB.PROFILES)
                .doc(getCurrentUserUid())
                .set({ photoUrl: url, imageBase: img }, { merge: true })
                .then(() => {
                  commit("setProfilePhoto", url);
                  resolve(url);
                })
                .catch(err => reject(err));
            });
          })
          .catch(err => reject(err));
      });
    },

    setSelectedAddress({ commit }, address) {
      return Promise.resolve(commit("SET_SELECTED_ADDRESS", address));
    },

    setToken({ commit }, token) {
      return new Promise((resolve, reject) => {
        let userId = getCurrentUserUid();

        if (userId) {
          db.collection(DB.PROFILES)
            .doc(userId)
            .set({ token: token }, { merge: true })
            .then(() => {
              commit("SET_PROFILE_TOKEN", token);
              resolve(token);
            })
            .catch(err => reject(err));
        } else {
          resolve();
        }
      });
    },

    shouldLogout({ commit, getters, state }) {
      return new Promise(resolve => {
        if (getters["isLoggedIn"]) {
          commit("INCREMENT_SECONDS_IDLE");
          resolve(state.secondsIdle >= SESSION_TIMEOUT_SECS ? true : false);
        }
      });
    },

    updateBantayKardBalance({ state, commit }, amount) {
      return new Promise((resolve, reject) => {
        let balance = state.profile.bantayKardBalance + amount;
        db.collection(DB.PROFILES)
          .doc(getCurrentUserUid())
          .set({ bantayKardBalance: balance }, { merge: true })
          .then(() => {
            commit("setBantayKardBalance", balance);
            resolve();
          })
          .catch(() => reject());
      });
    },

    updateRegistrationDetails({ state }) {
      let value = getRegistrationDetails();

      if (value) {
        if (state.auth && state.auth.uid) {
          db.collection(DB.PROFILES)
            .doc(state.auth.uid)
            .set(
              {
                displayName: `${value.firstName} ${value.lastName}`,
                email: value.email,
                registrationNumber: value.contactNumber,
                locale: LOCALE_TL
              },
              { merge: true }
            )
            .then(() => {
              RemoveRegistrationDetails();
            });
        }
      }
    },

    updateVerificationRequest({ commit }, target) {
      let docRef = db.collection(DB.PROFILES).doc(getCurrentUserUid());

      if (target) {
        docRef.set(
          {
            lgu: {
              location: target,
              verificationRequested: true,
              verificationStatus: "Requested"
            }
          },
          { merge: true }
        );
      } else {
        docRef.set(
          { verificationRequested: true, verificationStatus: "requested" },
          { merge: true }
        );
        commit("setVerificationRequest");
      }
    }
  },

  strict: true
});

export function checkOutPayment(checkout) {
  db.collection(DB.CHECKOUT)
    .doc(checkout.checkoutdocid)
    .set({ ...checkout }, { merge: true });
}

export function countAppointmentBHC(payload) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ATTENDEEAPPOINTMENTQRBHC)
      .where("serviceID", "==", payload.serviceId)
      .where("start", "==", payload.srdate)
      .get()
      .then(querySnapshot => resolve(querySnapshot.size))
      .catch(err => reject(err));
  });
}

export function deleteMessage(docId) {
  db.collection(DB.MESSAGE_BOX)
    .doc(docId)
    .delete()
    .then(() => {
      debugLog("Document successfully deleted!");
    })
    .catch(error => {
      debugError("Error removing document: ", error);
    });
}

export function getLGUDataFromProfile() {
  return new Promise(resolve => {
    db.collection(DB.PROFILES)
      .doc(getCurrentUserUid())
      .get()
      .then(doc => {
        let data = doc.data();
        resolve(data.lgu);
      })
      .catch(() => resolve(""));
  });
}

//-------------------------- LGU LOCATIONS ACTIONS --------------------------//

export function GetLGULocations() {
  return new Promise(resolve => {
    db.collection(DB.LGU_LOCATIONS)
      .get()
      .then(querySnapshot => {
        let locations = {};

        querySnapshot.forEach(doc => {
          locations[doc.id] = doc.data();
        });

        resolve(locations);
      })
      .catch(() => resolve({}));
  });
}
//-------------------------- LGU SERVICES ACTIONS --------------------------//

export function GetServices(payload) {
  return new Promise((resolve, reject) => {
    db.collection(DB.LGU_SERVICES)
      .where("location", "==", payload.location)
      .get()
      .then(snapshot => {
        let services = [];

        snapshot.forEach(doc => {
          let data = doc.data();
          services.push({
            id: doc.id,
            name: data.name,
            location: data.location,
            serviceLocation: data.serviceLocation
          });
        });

        resolve(services);
      })
      .catch(err => reject(err));
  });
}

//-------------------------- OFFSITE SERVICES ACTIONS --------------------------//

export function GetOffsites() {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SERVICES)
      // .where("location", "==", payload.location)
      .get()
      .then(snapshot => {
        let services = [];

        snapshot.forEach(doc => {
          let data = doc.data();
          services.push({
            id: doc.id,
            name: data.name,
            location: data.location
          });
        });

        resolve(services);
      })
      .catch(err => reject(err));
  });
}

//-------------------------- LABORATORY SERVICES ACTIONS --------------------------//

export function GetClinics(payload) {
  return new Promise((resolve, reject) => {
    db.collection(DB.DIAGNOSTIC_CLINICS)
      .where("location", "==", payload.location)
      .get()
      .then(snapshot => {
        let clinics = [];

        snapshot.forEach(doc => {
          let data = doc.data();
          clinics.push({
            id: doc.id,
            name: data.name,
            location: data.location,
            serviceLocation: data.serviceLocation
          });
        });

        resolve(clinics);
      })
      .catch(err => reject(err));
  });
}

export function GetRebookHistory(serviceid) {
  return new Promise((resolve, reject) => {
    db.collection(DB.DIAGNOSTIC_HOMESERVICE)
      .doc(serviceid)
      .get()
      .then(doc => resolve(doc.data()))
      .catch(err => reject(err));
  });
}

export function loadCheckoutId() {
  return new Promise((resolve, reject) => {
    db.collection(DB.CHECKOUT)
      .where("soldTo", "==", getCurrentUserUid())
      .where("status", "==", ORDER_STATUS_COMPLETED)
      .get()
      .then(snapshot => {
        return snapshotToArray(snapshot);
      })
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function loadDiagnosticPackages() {
  return new Promise((resolve, reject) => {
    db.collection(DB.DIAGNOSTIC_PACKAGES)
      .get()
      .then(snapshot => {
        return snapshotToArray(snapshot);
      })
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function loadDiscount(payload) {
  return new Promise((resolve, reject) => {
    db.collection(DB.DISCOUNTS)
      .where("discountCode", "==", payload.promoCode)
      .get()
      .then(snapshot => {
        return snapshotToArray(snapshot);
      })
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function loadDoctorAppointments(filters) {
  return new Promise((resolve, reject) => {
    let query = db
      .collection(DB.APPOINTMENTS)
      .orderBy("start")
      .limit(filters.docsPerLoad)
      .where("type", "==", "doctor")
      .where("visitType", "==", filters.visitType)
      .where("host.specializations", "array-contains", filters.specialization);

    if (filters.location.region !== "") {
      query = query.where("location.region", "==", filters.location.region);

      if (filters.location.province !== "") {
        query = query.where(
          "location.province",
          "==",
          filters.location.province
        );

        if (filters.location.barangay !== "") {
          query = query.where(
            "location.barangay",
            "==",
            filters.location.barangay
          );
        }
      }
    }

    if (filters.lastVisible) {
      query = query.startAfter(filters.lastVisible);
    }

    query
      .get()
      .then(snapshot => {
        let appointments = [];
        let lastVisible = null;
        if (!snapshot.empty) {
          snapshot.forEach(doc => {
            appointments.push({
              id: doc.id,
              data: doc.data()
            });
          });
          lastVisible = snapshot.docs[snapshot.docs.length - 1];
        }
        resolve({
          lastVisible: lastVisible,
          appointments: appointments,
          appointmentsCount: appointments.length
        });
      })
      .catch(err => reject(err));
  });
}

export function loadDoctors(filters) {
  return new Promise((resolve, reject) => {
    let query = db
      .collection(DB.PROFILES)
      .limit(filters.docsPerLoad)
      .where("specializations", "array-contains", filters.specialization);

    if (filters.lastVisibleDoctor) {
      query = query.startAfter(filters.lastVisibleDoctor);
    }

    query
      .get()
      .then(snapshot => {
        let doctors = [];
        let lastVisible = null;
        if (!snapshot.empty) {
          snapshot.forEach(doc => {
            doctors.push({
              id: doc.id,
              data: doc.data()
            });
          });
          lastVisible = snapshot.docs[snapshot.docs.length - 1];
        }
        resolve({
          lastVisible: lastVisible,
          docs: doctors,
          docsCount: doctors.length
        });
      })
      .catch(err => reject(err));
  });
}

export function loadInsuranceAccounts() {
  return new Promise((resolve, reject) => {
    db.collection(DB.INSURANCE_ACCOUNT)
      .where("user", "==", getCurrentUserUid())
      .get()
      .then(snapshot => {
        let insurances = [];
        snapshot.forEach(doc => {
          insurances.push(doc.data());
        });
        resolve(insurances);
      })
      .catch(err => reject(err));
  });
}

export function loadInsurancePackage2(name) {
  return new Promise((resolve, reject) => {
    db.collection(DB.INSURANCE_PACKAGE)
      .where("name", "==", name)
      .get()
      .then(snapshot => {
        let packages = [];

        snapshot.forEach(doc => {
          packages.push(doc.data().packages);
        });

        resolve(packages);
      })
      .catch(err => reject(err));
  });
}

export function loadIndividualTest(clinicType) {
  return new Promise((resolve, reject) => {
    db.collection(DB.TEST_SETTINGS)
      .where("group", "==", "Addition Test")
      .where("serviceFilter", "array-contains", clinicType)
      .orderBy("testname", "asc")
      .get()
      .then(snapshot => {
        return snapshotToArray(snapshot);
      })
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function loadPackages(clinicType) {
  return new Promise((resolve, reject) => {
    db.collection(DB.TEST_SETTINGS)
      .where("group", "==", "Packages")
      .where("serviceFilter", "array-contains", clinicType)
      .orderBy("testname", "asc")
      .get()
      .then(snapshot => {
        return snapshotToArray(snapshot);
      })
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function loadProductImg(productSKU) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PRODUCTIMAGES)
      .where("productSKU", "==", productSKU)
      .get()
      .then(snapshot => {
        return snapshotToArray(snapshot);
      })
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function loadSelectedCart() {
  return new Promise((resolve, reject) => {
    getCartContents(ORDER_STATUS_COMPLETED)
      .then(snapshot => {
        return snapshotToArray(snapshot);
      })
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function processGcashReference(data) {
  return new Promise((resolve, reject) => {
    if (PAYMENT_GCASH !== data.modeOfPayment || !data.referenceNumber) {
      resolve();
    } else {
      getUnusedGCashTransaction(data.referenceNumber)
        .then(content => {
          if (content) {
            setGcashToProcessed(content.id, data.orderId)
              .then(() => resolve())
              .catch(err => reject(err));
          } else {
            resolve();
          }
        })
        .catch(err => reject(err));
    }
  });
}

export function sendMessage(messages) {
  if (messages.receiver && messages.receiver.length) {
    db.collection(DB.MESSAGE_BOX)
      .doc()
      .set({ ...messages });
  }
}

export function updateRegistrationDetailsOffsite(value, uid) {
  if (value) {
    db.collection(DB.PROFILES)
      .doc(uid)
      .set(value, { merge: true });
  }
}

export function setOffsiteMembers(memberDetails, uid) {
  if (memberDetails) {
    db.collection(DB.OFFSITE_MEMBERS)
      .doc(uid)
      .set(memberDetails, { merge: true });
  }
}

export function setDelivery(deliveries) {
  return new Promise((resolve, reject) => {
    db.collection(DB.DELIVERY)
      .add({ ...deliveries })
      .then(delivery => resolve(delivery))
      .catch(err => reject(err));
  });
}

export function setHomeServiceMessageboxStatus(payload) {
  let timestamp = new Date();
  return new Promise((resolve, reject) => {
    db.collection(DB.MESSAGE_BOX)
      .doc(payload.docid)
      .set(
        {
          homeService: { status: payload.status },
          timestamp: timestamp,
          status: STATUS_UNREAD
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function SetHomeServiceStatusRebooked(payload) {
  let timestamp = new Date();
  return new Promise((resolve, reject) => {
    db.collection(DB.DIAGNOSTIC_APPOINTMENTS)
      .doc(payload.sID)
      .set(
        {
          selectedPreferredDateTime: payload.selectedPreferredDateTime,
          status: payload.status,
          rebookedDate: timestamp,
          rebookhistory: payload.rebookhistory
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function setIdPhotoUrl(img, target) {
  const targetDB = target ? DB.LGU_VALIDATION : DB.USER_VALIDATION;

  return new Promise((resolve, reject) => {
    firebase
      .storage()
      .ref(`id/${getCurrentUserUid()}`)
      .putString(img, "data_url")
      .then(doc => {
        doc.ref.getDownloadURL().then(url => {
          db.collection(targetDB)
            .doc(getCurrentUserUid())
            .set({ idUrl: url, imageBaseId: img }, { merge: true })
            .then(() => resolve(url))
            .catch(err => reject(err));
        });
      })
      .catch(err => reject(err));
  });
}
export function statusRead(payload) {
  db.collection(DB.MESSAGE_BOX)
    .doc(payload.docId)
    .set(
      {
        status: payload.status
      },
      { merge: true }
    );
}

export function updateCardBalance(balance) {
  db.collection(DB.PROFILES)
    .doc(getCurrentUserUid())
    .set({ bantayKardBalance: balance }, { merge: true });
}

export function saveDefaultDetails(defaultDetails) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PROFILES)
      .doc(getCurrentUserUid())
      .set({ defaultDetails }, { merge: true })
      .then(() => resolve(defaultDetails))
      .catch(err => reject(err));
  });
}

export function makeDefault(details) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PROFILES)
      .doc(getCurrentUserUid())
      .set({ defaultAddress: details.defaultAddress }, { merge: true })
      .then(() => {
        return saveDefaultDetails(details.address);
      })
      .then(() => resolve(details))
      .catch(err => reject(err));
  });
}

export function getOffsiteMembersfromPrimary(payload) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_VALIDATION)
      .where("roles", "==", payload.selectedDoctor)
      .get()
      .then(snapshot => {
        let clinics = [];

        snapshot.forEach(doc => {
          let data = doc.data();
          clinics.push({
            id: doc.id,
            name: data.name,
            location: data.location
          });
        });

        resolve(clinics);
      })
      .catch(err => reject(err));
  });
}
