<template>
  <v-card width="400" align="center" flat>
    <v-col v-show="rateMode" class="text-right">
      <v-icon v-on:click="$emit('closeDialog')" class="pa-2" color="error">
        mdi mdi-close
      </v-icon>
    </v-col>

    <div class="camera pt-7 text-center">
      <video id="video" v-if="show" autoplay class="feed" />

      <v-btn class="mt-4" v-if="show" @click="takePicture" fab color="primary">
        <v-icon>mdi-camera</v-icon>
      </v-btn>
      <div v-show="capturing" class="picture">
        <canvas id="canvas" />
      </div>
    </div>

    <v-text-field
      v-show="withContact"
      v-model="receiverContact"
      label="Receiver Contact"
      class="pa-4"
      :rules="nameRules"
    />

    <div class="content">
      <section class="cropper-area" v-show="!show">
        <div class="text-center">
          <vue-cropper
            ref="cropper"
            :aspect-ratio="1 / 1"
            preview=".preview"
            alt=" "
            @cropstart="cropStart"
          />
        </div>

        <div v-if="registrationMode" class="actions">
          <a href="#" role="button" @click.prevent="retake"> Retake </a>
        </div>
        <div v-else class="actions">
          <a href="#" role="button" @click.prevent="retake"> Retake </a>
          <a href="#" role="button" @click.prevent="zoom(0.2)"> Zoom In </a>
          <a href="#" role="button" @click.prevent="zoom(-0.2)"> Zoom Out </a>

          <a
            href="#"
            role="button"
            @click.prevent="cropImage"
            :disabled="$refs.cropper"
          >
            Crop & Save
          </a>
        </div>
      </section>
    </div>
  </v-card>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "TakePhoto",

  components: {
    VueCropper
  },

  props: {
    rateMode: Boolean,
    registrationMode: Boolean,
    withContact: Boolean
  },

  data: () => ({
    receiverContact: "",
    cropImg: "",
    show: false,
    capturing: false,
    nameRules: [v => !!v || "Field is required"]
  }),

  beforeMount() {
    this.show = true;
    this.init();
  },

  mounted() {
    if (this.rateMode) {
      this.retake();
    }
  },

  watch: {
    receiverContact(value) {
      this.$emit("receiverContact", value);
    }
  },

  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$emit("take-photo", this.cropImg);
    },

    cropStart() {
      this.$emit(
        "emit-photo",
        this.$refs.cropper.getCroppedCanvas().toDataURL()
      );
    },

    init() {
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        let constraints = {
          video: {
            width: { min: 400, max: 400 },
            height: { min: 400, max: 400 },
            facingMode: "environment"
          }
        };

        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          const videoPlayer = document.querySelector("video");
          videoPlayer.srcObject = stream;

          videoPlayer.play();
        });
      }
    },

    retake() {
      this.init();
      this.show = true;
    },

    takePicture() {
      this.capturing = true;
      const video = document.querySelector("#video");

      const canvas = document.querySelector("#canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      const img = document.createElement("img");
      img.src = canvas.toDataURL("image/png");

      this.$refs.cropper.replace(img.src);
      this.$emit("emit-photo", img.src);
      this.capturing = false;
      this.show = false;
    },

    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    }
  }
};
</script>

<style scoped>
.actions {
  margin-top: 1rem;
  text-align: center;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #558b2f;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.camera {
  width: 100%;
  height: 100%;
  padding: 25px;
  box-sizing: border-box;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.feed {
  display: block;
  width: 100%;
  max-width: 100%;
  box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.35);
  margin: 0 auto;
  background-color: #171717;
  border-radius: 25px;
}

input[type="file"] {
  display: none;
}

.picture {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.picture canvas {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.preview {
  min-height: 200px;
  min-width: 200px;
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
</style>
