import {
  DOCTYPE_VITALSIGNS,
  getDocumentHeader,
  IndexedDocument
} from "@/utilities/user_collection";

const KEY_ADDITIONAL_NOTES = "additionalNotes";
const KEY_BLOOD_PRESSURE = "bloodPressure";
const KEY_BMI = "bmi";
const KEY_HEART_RATE = "heartRate";
const KEY_HEIGHT = "height";
const KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE = "middleUpperArmCircumference";
const KEY_RESPIRATORY_RATE = "respiratoryRate";
const KEY_TEMPERATURE = "temperature";
const KEY_WEIGHT = "weight";
const KEY_ZSCORE = "zscore";

function getData(state) {
  return {
    [KEY_ADDITIONAL_NOTES]: state[KEY_ADDITIONAL_NOTES],
    [KEY_BLOOD_PRESSURE]: state[KEY_BLOOD_PRESSURE],
    [KEY_BMI]: state[KEY_BMI],
    [KEY_HEART_RATE]: state[KEY_HEART_RATE],
    [KEY_HEIGHT]: state[KEY_HEIGHT],
    [KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE]:
      state[KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE],
    [KEY_RESPIRATORY_RATE]: state[KEY_RESPIRATORY_RATE],
    [KEY_TEMPERATURE]: state[KEY_TEMPERATURE],
    [KEY_WEIGHT]: state[KEY_WEIGHT],
    [KEY_ZSCORE]: state[KEY_ZSCORE]
  };
}

function getDocumentContent(state) {
  return {
    header: getDocumentHeader(DOCTYPE_VITALSIGNS, state),
    data: getData(state)
  };
}

var vitalSignsBase = new IndexedDocument(
  DOCTYPE_VITALSIGNS,
  getDocumentContent
);

export const vitalSigns = {
  state: () => ({
    ...vitalSignsBase.state,

    [KEY_ADDITIONAL_NOTES]: "",
    [KEY_BLOOD_PRESSURE]: "",
    [KEY_BMI]: "",
    [KEY_HEART_RATE]: "",
    [KEY_HEIGHT]: "",
    [KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE]: "",
    [KEY_RESPIRATORY_RATE]: "",
    [KEY_TEMPERATURE]: "",
    [KEY_WEIGHT]: "",
    [KEY_ZSCORE]: ""
  }),

  getters: {
    ...vitalSignsBase.getters,

    getAdditionalNotes(state) {
      return state[KEY_ADDITIONAL_NOTES];
    },

    getBloodPressure(state) {
      return state[KEY_BLOOD_PRESSURE];
    },

    getBMI(state) {
      return state[KEY_BMI];
    },

    getHeartRate(state) {
      return state[KEY_HEART_RATE];
    },

    getHeight(state) {
      return state[KEY_HEIGHT];
    },

    getMiddleUpperArmCircumference(state) {
      return state[KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE];
    },

    getRespiratoryRate(state) {
      return state[KEY_RESPIRATORY_RATE];
    },

    getTemperature(state) {
      return state[KEY_TEMPERATURE];
    },

    getWeight(state) {
      return state[KEY_WEIGHT];
    },

    getZscore(state) {
      return state[KEY_ZSCORE];
    }
  },

  mutations: {
    ...vitalSignsBase.mutations,

    RESET_STATE(state) {
      state[KEY_ADDITIONAL_NOTES] = "";
      state[KEY_BLOOD_PRESSURE] = "";
      state[KEY_BMI] = "";
      state[KEY_HEART_RATE] = "";
      state[KEY_HEIGHT] = "";
      state[KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE] = "";
      state[KEY_RESPIRATORY_RATE] = "";
      state[KEY_TEMPERATURE] = "";
      state[KEY_WEIGHT] = "";
      state[KEY_ZSCORE] = "";
    },

    setAdditionalNotes(state, value) {
      state.isSaved = state.isSaved && state[KEY_ADDITIONAL_NOTES] == value;
      state[KEY_ADDITIONAL_NOTES] = value;
    },

    setBloodPressure(state, value) {
      state.isSaved = state.isSaved && state[KEY_BLOOD_PRESSURE] == value;
      state[KEY_BLOOD_PRESSURE] = value;
    },

    setBMI(state, value) {
      state.isSaved = state.isSaved && state[KEY_BMI] == value;
      state[KEY_BMI] = value;
    },

    setHeartRate(state, value) {
      state.isSaved = state.isSaved && state[KEY_HEART_RATE] == value;
      state[KEY_HEART_RATE] = value;
    },

    setHeight(state, value) {
      state.isSaved = state.isSaved && state[KEY_HEIGHT] == value;
      state[KEY_HEIGHT] = value;
    },

    setMiddleUpperArmCircumference(state, value) {
      state.isSaved =
        state.isSaved && state[KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE] == value;
      state[KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE] = value;
    },

    setRespiratoryRate(state, value) {
      state.isSaved = state.isSaved && state[KEY_RESPIRATORY_RATE] == value;
      state[KEY_RESPIRATORY_RATE] = value;
    },

    setTemperature(state, value) {
      state.isSaved = state.isSaved && state[KEY_TEMPERATURE] == value;
      state[KEY_TEMPERATURE] = value;
    },

    setWeight(state, value) {
      state.isSaved = state.isSaved && state[KEY_WEIGHT] == value;
      state[KEY_WEIGHT] = value;
    },

    setZscore(state, value) {
      state.isSaved = state.isSaved && state[KEY_ZSCORE] == value;
      state[KEY_ZSCORE] = value;
    },

    setData(state, data) {
      state[KEY_ADDITIONAL_NOTES] = data[KEY_ADDITIONAL_NOTES] || "";
      state[KEY_BLOOD_PRESSURE] = data[KEY_BLOOD_PRESSURE] || "";
      state[KEY_BMI] = data[KEY_BMI] || "";
      state[KEY_HEART_RATE] = data[KEY_HEART_RATE] || "";
      state[KEY_HEIGHT] = data[KEY_HEIGHT] || "";
      state[KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE] =
        data[KEY_MIDDLE_UPPER_ARM_CIRCUMFERENCE] || "";
      state[KEY_RESPIRATORY_RATE] = data[KEY_RESPIRATORY_RATE] || "";
      state[KEY_TEMPERATURE] = data[KEY_TEMPERATURE] || "";
      state[KEY_WEIGHT] = data[KEY_WEIGHT] || "";
      state[KEY_ZSCORE] = data[KEY_ZSCORE] || "";
    },

    setDocumentId(state, data) {
      state.isSaved = state.isSaved && state.documentId === data;
      state.documentId = data;
    }
  },

  actions: {
    ...vitalSignsBase.actions,

    resetContent({ commit, dispatch }) {
      dispatch("resetDocument");
      commit("RESET_STATE");
    }
  }
};
