import { LOCALE_EN, LOCALE_TL } from "@/common/utilities/locale";
import { SERVICE_LABORATORY } from "@/common/utilities/services";

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  [LOCALE_EN]: {
    dialog: {
      confirmation: "Confirmation",
      confirmSchedule: "Confirm Schedule"
    },

    menu: {
      getInsured: "e-Insurance",
      getInsuredSubtitle:
        "Help your family get through unexpected circumstances",
      getCare: "e-Konsulta",
      getCareSubtitle: "Quick and Easy Medical Care",
      getChecked: SERVICE_LABORATORY,
      getCheckedSubtitle: "Schedule Diagnostic Tests",
      messages: "Messages",
      pharmacy: "e-Botika (Coming Soon)",
      pharmacySubtitle: "Fill Prescriptions",
      store: "e-Tindahan",
      storeSubtitle: "Shop at Home",
      lguReg: "LGU Services",
      lguRegSubtitle: "Free LGU services",
      offsiteReg: "Offsite Services"
    },

    lgu: {
      notice:
        "Ang bahaging ito ng website ay serbisyo publiko ng Medisure katuwang ang mga piling lokal na pamahalaan upang maisaayos ang paghahatid ng serbisyong pangkalusugan sa mga mamamayan ng nasabing lugar.",
      terms:
        "Nakatira ako sa %s at nais kong pakinabangan ang mga serbisyong pangkalusugan na inaalok ng barangay.",
      agreement:
        "Pumapayag akong ibahagi ang mga personal kong impormasyon sa Pamahalaan ng %s upang tiyakin ang aking pagkakakilanlan at karapatang mapakinabangan ang mga inaalok nilang serbisyong pangkalusugan.",
      rejected:
        "Ikinalulungkot po naming ibalita na hindi kayo maaaring makinabang sa mga serbisyong hatid ng Pamahalaan ng %s.",
      verification:
        "Naibahagi na po sa Pamahalaan ng %s ang inyong impormasyon. Pakiantabayanan na lang po ang sagot mula sa nasabing pamahalaang lokal.",
      healthservicesterms:
        "I am a resident of Brgy. Plainview, Mandaluyong City and I intend to avail of the health services of the barangay.",
      healthservicesagreement:
        "I agree to share my personal information with the Local Government of Brgy. Plainview, Mandaluyong City to for the purpose of verifying my identity and eligibility to avail of their programs."
    },

    insurance: {
      insuranceTerms:
        "PLEASE READ BEFORE PROCEEDING. It is understood that by clicking this box, you voluntarily give your consent for the processing of all information in compliance with Philippine laws on confidentiality and data privacy, and that you have read the terms and conditions on how we use the information you provide.",
      cbigTerms:
        "PLEASE READ BEFORE PROCEEDING. It is understood that by clicking this box, you agree to the terms and conditions of the policy as issued by Country Bankers Insurance Group (CBIG), and that MediSure has no obligation whatsoever for any claims arising from the policy other than that of CBIG.",
      benefeciaryTips:
        "An IRREVOCABLE beneficiary shares the right to manage your insurance. You will not be able to make any changes to your policy without his/her consent. A REVOCABLE beneficiary does not have suchrights.",
      benefitTitle:
        "For ages 13-70 years old, the following benefits are provided to insured individuals:",
      deathBenefit: "Loss of life due to old age or sickness.",
      accidentalDeath:
        "Loss of life due to accidents, or unforeseen events, or injury sustained in an accident",
      instantAbuloy:
        "Immediate burial assistance given to bereaved families upon submission of death certificates of insured by authorize claimants.",
      dailyConfinement:
        "Paid for a period of required confinement in any hospital in the Philippines, due to sickness or accident."
    },

    register: {
      consent:
        "PLEASE READ BEFORE PROCEEDING. It is understood that by clicking this box, you voluntarily give your consent for the processing of all information in compliance with Philippine laws on confidentiality and data privacy, and that you have read the terms and conditions on how we use the information you provide.",
      registrationTerm1: "What information do we collect and why",
      registrationTerm2:
        "MediSureOnline collects basic information and some personal information to verify your account. We only require the minimum information necessary to protect both parties from fraud.",
      registrationTerm3:
        "MediSureOnline does not collect information from children under 15 years of age.",
      registrationTerm4: "How we share the information we collect",
      registrationTerm5:
        "The information is shared only for the services you require from our site such as complying with our vendors for the facilitation of the sale of products or service.",
      registrationTerm6:
        "How you can access and control the information we collect",
      registrationTerm7:
        "MediSureOnline provides for you to access, alter, delete your profile information. You can contact us through emails, social media and other details as provided in our site for more assistance."
    },

    laboratory: {
      appointmentCancelText:
        "Are you sure you want to cancel this appointment?",
      appointmentConfirmationLeadingText: "This is your preferred schedule:",
      appointmentConfirmationText:
        "If these details are correct, please click ",
      appointmentConfirmationButtonText: "CONFIRM SCHEDULE",
      appointmentConfirmationDialogTextLine1:
        "Your reservation has been submitted for verification. Our customer representative will contact you for any concerns regarding your reservation and/or your requested test.",
      appointmentConfirmationDialogTextLine2:
        "Thank you for availing our services and we look forward to your continued patronage.",
      appointmentConfirmationDialogTextLine3: "Stay safe always.",

      appointment: {
        selectPreferredDateTime: {
          header:
            "Please select your preferred schedule. Our agents will contact you for confirmation. Click NEXT to continue.",
          specificTime:
            "Please set the time frame when you are available at this date. Please note that laboratory procedures take around 30 MINUTES to complete."
        },

        dialog: {
          confirm:
            "Your appointment is CONFIRMED. Please arrive 10 MINUTES EARLY for your scheduled appointment. Thank you!"
        }
      }
    }
  },

  [LOCALE_TL]: {
    dialog: {
      confirmation: "Kumpirmasyon",
      confirmSchedule: "Ikumpirma ang Schedule"
    },

    menu: {
      getInsured: "e-Insurance",
      getInsuredSubtitle: 'Magpa-insure dahil "Kapag Sigurado, hindi Kabado"',
      getCare: "e-Konsulta",
      getCareSubtitle: "Magpakonsulta sa espeyalistang doktor",
      getChecked: SERVICE_LABORATORY,
      getCheckedSubtitle: "Magpasuri ayon sa inyong nararamdaman",
      messages: "Mensahe",
      pharmacy: "e-Botika (Abangan)",
      pharmacySubtitle: "Magpa-deliver ng gamot gamit ang inyong  e-Reseta",
      store: "e-Tindahan",
      storeSubtitle: "Mamili ng samut-saring tinda sa murang halaga",
      lguReg: "LGU Services",
      lguRegSubtitle: "Makinabang sa mga libreng serbisyo ng gobyerno",
      offsiteReg: "Offsite Services"
    },

    lgu: {
      notice:
        "Ang bahaging ito ng website ay serbisyo publiko ng Medisure katuwang ang mga piling lokal na pamahalaan upang maisaayos ang paghahatid ng serbisyong pangkalusugan sa mga mamamayan ng nasabing lugar.",
      terms:
        "Nakatira ako sa %s at nais kong pakinabangan ang mga serbisyong pangkalusugan na inaalok ng barangay.",
      agreement:
        "Pumapayag akong ibahagi ang mga personal kong impormasyon sa Pamahalaan ng %s upang tiyakin ang aking pagkakakilanlan at karapatang mapakinabangan ang mga inaalok nilang serbisyong pangkalusugan.",
      rejected:
        "Ikinalulungkot po naming ibalita na hindi kayo maaaring makinabang sa mga serbisyong hatid ng Pamahalaan ng %s.",
      verification:
        "Naibahagi na po sa Pamahalaan ng %s ang inyong impormasyon. Pakiantabayanan na lang po ang sagot mula sa nasabing pamahalaang lokal."
    },

    insurance: {
      insuranceTerms:
        "PLEASE READ BEFORE PROCEEDING. It is understood that by clicking this box, you voluntarily give your consent for the processing of all information in compliance with Philippine laws on confidentiality and data privacy, and that you have read the terms and conditions on how we use the information you provide.",
      cbigTerms:
        "PLEASE READ BEFORE PROCEEDING. It is understood that by clicking this box, you agree to the terms and conditions of the policy as issued by Country Bankers Insurance Group (CBIG), and that MediSure has no obligation whatsoever for any claims arising from the policy other than that of CBIG.",
      benefeciaryTips:
        "An IRREVOCABLE beneficiary shares the right to manage your insurance. You will not be able to make any changes to your policy without his/her consent. A REVOCABLE beneficiary does not have suchrights.",
      benefitTitle:
        "For ages 13-70 years old, the following benefits are provided to insured individuals:",
      deathBenefit: "Loss of life due to old age or sickness.",
      accidentalDeath:
        "Loss of life due to accidents, or unforeseen events, or injury sustained in an accident",
      instantAbuloy:
        "Immediate burial assistance given to bereaved families upon submission of death certificates of insured by authorize claimants.",
      dailyConfinement:
        "Paid for a period of required confinement in any hospital in the Philippines, due to sickness or accident."
    },

    register: {
      consent:
        "BASAHIN BAGO MAGPATULOY. Sa pamamagitan ng pag-click sa kahong ito, kusang-loob ninyong ibinibigay ang inyong pahintulot sa pagproseso ng lahat ng inyong impormasyon alinsunod sa mga batas ng Pilipinas ukol sa pagiging kompidensiyal at pagkapribado ng data, at nabasa ninyo ang mga tuntunin at kundisyon kung paano namin gagamitin ang mga impormasyong iyon.",
      registrationTerm1: "What information do we collect and why",
      registrationTerm2:
        "MediSureOnline collects basic information and some personal information to verify your account. We only require the minimum information necessary to protect both parties from fraud.",
      registrationTerm3:
        "MediSureOnline does not collect information from children under 15 years of age.",
      registrationTerm4: "How we share the information we collect",
      registrationTerm5:
        "The information is shared only for the services you require from our site such as complying with our vendors for the facilitation of the sale of products or service.",
      registrationTerm6:
        "How you can access and control the information we collect",
      registrationTerm7:
        "MediSureOnline provides for you to access, alter, delete your profile information. You can contact us through emails, social media and other details as provided in our site for more assistance."
    },

    laboratory: {
      appointmentCancelText:
        "Sigurado ba kayong gusto niyong i-cancel ang appointment na ito?",
      appointmentConfirmationLeadingText:
        "Ito po ang inyong napiling schedule:",
      appointmentConfirmationText:
        "Kung tama ang mga detalyeng ito, paki-click ang ",
      appointmentConfirmationButtonText: "IKUMPIRMA ANG SCHEDULE",
      appointmentConfirmationDialogTextLine1:
        "Your reservation has been submitted for verification. Our customer representative will contact you for any concerns regarding your reservation and/or your requested test.",
      appointmentConfirmationDialogTextLine2:
        "Thank you for availing our services and we look forward to your continued patronage.",
      appointmentConfirmationDialogTextLine3: "Stay safe always.",

      appointment: {
        dialog: {
          confirm:
            "Naitala na po ang inyong appointment. Mangyaring dumating ng 10 minutong mas maaga sa inyong appointment. Maraming salamat po!"
        }
      }
    }
  },

  ceb: {
    dialog: {
      confirmation: "Confirmation",
      confirmSchedule: "Confirm Schedule"
    },

    menu: {
      getInsured: "e-Insurance",
      getInsuredSubtitle:
        "Tabangi ang imong banay sa pag atubang sa wala nimo palandunga nga mgapanghitabo.",
      getCare: "e-Konsulta",
      getCareSubtitle: "Paspas ug Sayon nga Pangkinahanlang Medical",
      getChecked: SERVICE_LABORATORY,
      getCheckedSubtitle: "Schedule sa pagpa Diagnostic Test",
      messages: "Mensahe",
      pharmacy: "e-Botika (Moabot Unya)",
      pharmacySubtitle: "Pagdugang sa Prescriptions",
      store: "e-Tindahan",
      storeSubtitle: "Pagpamalit samtang naa sa Balay",
      lguReg: "LGU Services",
      lguRegSubtitle: "",
      offsiteReg: "Offsite Services"
    },

    lgu: {
      notice:
        "Ang bahaging ito ng website ay serbisyo publiko ng Medisure katuwang ang mga piling lokal na pamahalaan upang maisaayos ang paghahatid ng serbisyong pangkalusugan sa mga mamamayan ng nasabing lugar.",
      terms:
        "Nakatira ako sa %s at nais kong pakinabangan ang mga serbisyong pangkalusugan na inaalok ng barangay.",
      agreement:
        "Pumapayag akong ibahagi ang mga personal kong impormasyon sa Pamahalaan ng %s upang tiyakin ang aking pagkakakilanlan at karapatang mapakinabangan ang mga inaalok nilang serbisyong pangkalusugan.",
      rejected:
        "Ikinalulungkot po naming ibalita na hindi kayo maaaring makinabang sa mga serbisyong hatid ng Pamahalaan ng %s.",
      verification:
        "Naibahagi na po sa Pamahalaan ng %s ang inyong impormasyon. Pakiantabayanan na lang po ang sagot mula sa nasabing pamahalaang lokal.",

      healthservicesterms1: "Nakatira ako sa ",
      healthservicesterms2:
        " at nais kong pakinabangan ang mga mga serbisyong pangkalusugan na inaalok ng barangay.",
      healthservicesagreement1:
        "Pumapayag akong ibahagi ang mga personal kong impormasyon sa Pamahalaan ng ",
      healthservicesagreement2:
        " upang tiyakin ang aking pagkakakilanlan at karapatang mapakinabangan ang mga inaalok nilang serbisyong pangkalusugan."
    },

    insurance: {
      insuranceTerms:
        "PALIHOG BASAHA SA DILI PA MOPADAYON. Atong nasabtan pinaagi sa pag tuplok(click) niining kahon, sa imong kaugalingong kabubut-on, imong gitugot ang paagi sa tanan nga kasayuran nga pinasubay sa mga balaod sa Pilipinas nga magpabilin nga hilom mahitungod sa pribado nga kasayuran og nga imong nabasa ang mga lagda og ang mga kauyunan kon giunsa pag gamit ang mga kasayuran nga imong gipahiluna.",
      cbigTerms:
        "PALIHOG BASAHA SA DILI PA MOPADAYON: Akong nasabtan nga pinaagi sa pag tup-lok(click) niining kahon, ako miuyon sa mga lagda og mga kasabutan sa mga paagi nga nahatag. Nga ang MediSure walay tulubagon bisan unsa nga mga suliran nga namugna gikan niining mga pamaagi gawas sa Country Bankers insurance Group (CBIG).",
      benefeciaryTips:
        "Ang IRREVOCABLE beneficiary lakip nga naay katungod na modumala sa imong kasiguruan. Dili mahimo nga mo himo ka og bisan unsa nga kausaban sa mga lagda nga walay pagtugot gikan sa iyaha. Ang REVOCABLE beneficiary walay sama sa maong katungod.",
      benefitTitle:
        "Sa panuigon nga 13 ngadto sa 70 , ang mosunod maoy madawat nga kasegurohan sa matag usa nga sakop:",
      deathBenefit: "Kamatayon gumikan sa kagulangon o gumikan sa balatian.",
      accidentalDeath:
        "Kamatayon gumikan sa accidente, o wala palandunga nga panghitabo,o mga bun-og nga nakuha gikan sa accidente.",
      instantAbuloy:
        "Hinanaling tabang ang madawat alang sa nag bangutan nga pamilya human mahatag ang death certificate sa na ainsured gikan sa sinaligan nga tawo.",
      dailyConfinement:
        "Pagabayaran sulud sa gikinahanglan nga gidugayon nga pagka hospital bisan diin sulod sa Pilipinas gumikan sa balatian o pagka accidente."
    },

    register: {
      consent:
        "BASAHA NGA DILI PA GI-PROCEEDING. Nakasabut nga pinaagi sa pag-klik sa kahon nga kini, boluntaryo mong gihatag ang imong pagtugot alang sa pagproseso sa tanan nga kasayuran subay sa mga balaod sa Pilipinas bahin sa pagkakumpidensyal ug pagkapribado sa datos, ug nabasa na nimo ang mga kondisyon ug kondisyon kung giunsa namon gigamit ang imong gihatag nga kasayuran.",
      registrationTerm1: "Unsa ang mga kasayuran ang atong dawaton og ngano?",
      registrationTerm2:
        "Ang MediSureOnline modawat og sagad nga kasayuran og mga kaugalingon nga kasayuran sa pag susi sa imoha nga account. Ang among gikinahanglan nga igo nga kasayuran nga gikinahanglang para nga ma panalipdan ang katungod sa partido gikan sa pangilad.",
      registrationTerm3:
        "Ang MediSureOnline nag dumili pagdawat og kasayuran gikan sa mga bata nga ubos sa panuigon nga 15 anyos.",
      registrationTerm4:
        "Giunsa namo pag sabwag ang kasayuran nga among nakuha?",
      registrationTerm5:
        "Ang kasayuran among gipahiluna para lamang sa serbisyo nga imong gikinahanglan gikan sa amoang site sama sa pagtuman sa among ginsakupan para pagpahibalo sa gibaligya nga produkto o serbisyo.",
      registrationTerm6:
        "Unsaon nimo pag sulod og pagpugong sa mga impormasyon nga nadawat?",
      registrationTerm7:
        "Ang MediSureOnline mopahiluna diha nimo sa pag gamit, pag usab, og pag tangtang para sa imong kasayuran. Pwede kami nimo nga ma kab-ot pinaagi sa email og social media nga gi han-ay sa amoang site sa dugang nga mga kasayuran."
    },

    laboratory: {
      appointmentCancelText:
        "Are you sure you want to cancel this appointment?",
      appointmentConfirmationLeadingText: "This is your preferred schedule:",
      appointmentConfirmationText:
        "If these details are correct, please click ",
      appointmentConfirmationButtonText: "CONFIRM SCHEDULE",
      appointmentConfirmationDialogTextLine1:
        "Your reservation has been submitted for verification. Our customer representative will contact you for any concerns regarding your reservation and/or your requested test.",
      appointmentConfirmationDialogTextLine2:
        "Thank you for availing our services and we look forward to your continued patronage.",
      appointmentConfirmationDialogTextLine3: "Stay safe always.",

      appointment: {
        dialog: {
          confirm:
            "Your appointment is CONFIRMED. Please arrive 10 MINUTES EARLY for your scheduled appointment. Thank you!"
        }
      }
    }
  }
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: LOCALE_TL, // set locale
  fallbackLocale: LOCALE_EN, // set fallback locale
  silentTranslationWarn: true, // disable default translation warnings
  messages // set locale messages
});

export default i18n;
