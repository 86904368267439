<template>
  <v-card class="elevation-12">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title class="centerThis">
        Subscription Information
      </v-toolbar-title>
    </v-toolbar>

    <SummaryElement
      class="mt-8"
      header="Premium"
      :content="packageDetails[selectedPackage].premium"
    />

    <div class="mt-6 boldThis centerThis">Personal Information</div>

    <SummaryElement
      header="Name of Principal"
      :content="
        userData.firstname + ' ' + userData.middlename + ' ' + userData.lastname
      "
    />

    <SummaryElement
      class="mt-n3"
      header="Date of Birth"
      :content="userData.birthdate"
    />

    <SummaryElement
      class="mt-n3"
      header="Address"
      :content="userData.housenumber"
    />

    <SummaryElement
      class="mt-n3"
      :v-if="userData.additionaladdress"
      header
      :content="userData.additionaladdress"
    />

    <SummaryElement class="mt-n3" header :content="userData.municipality" />

    <SummaryElement class="mt-n3" header :content="userData.province" />

    <div class="mt-6 boldThis centerThis" v-if="hasPadding">
      Beneficiary Data
    </div>
    <div class="mt-n6 boldThis centerThis" v-else>Beneficiary Data</div>

    <SummaryElement
      class="mt-3"
      header="Name of Beneficiary"
      v-if="beneficiaryData['1'].isValid"
      :content="beneficiaryData['1'].fullName"
    />

    <SummaryElement
      class="mt-n3"
      header="Relationship"
      v-if="beneficiaryData['1'].isValid"
      :content="beneficiaryData['1'].relationship"
    />

    <SummaryElement
      class="mt-n3"
      header="Date of Birth"
      v-if="beneficiaryData['1'].isValid"
      :content="beneficiaryData['1'].birthDate"
    />

    <SummaryElement
      class="mt-n3"
      header="Beneficiary Type"
      v-if="beneficiaryData['1'].isValid"
      :content="beneficiaryData['1'].isRevocable ? 'Revocable' : 'Irrevocable'"
    />

    <SummaryElement
      class="mt-3"
      header="Name of Beneficiary"
      v-if="beneficiaryData['2'].isValid"
      :content="beneficiaryData['2'].fullName"
    />

    <SummaryElement
      class="mt-n3"
      header="Relationship"
      v-if="beneficiaryData['2'].isValid"
      :content="beneficiaryData['2'].relationship"
    />

    <SummaryElement
      class="mt-n3"
      header="Date of Birth"
      v-if="beneficiaryData['2'].isValid"
      :content="beneficiaryData['2'].birthDate"
    />

    <SummaryElement
      class="mt-n3"
      header="Beneficiary Type"
      v-if="beneficiaryData['2'].isValid"
      :content="beneficiaryData['2'].isRevocable ? 'Revocable' : 'Irrevocable'"
    />

    <SummaryElement
      class="mt-3"
      header="Name of Beneficiary"
      v-if="beneficiaryData['3'].isValid"
      :content="beneficiaryData['3'].fullName"
    />

    <SummaryElement
      class="mt-n3"
      header="Relationship"
      v-if="beneficiaryData['3'].isValid"
      :content="beneficiaryData['3'].relationship"
    />

    <SummaryElement
      class="mt-n3"
      header="Date of Birth"
      v-if="beneficiaryData['3'].isValid"
      :content="beneficiaryData['3'].birthDate"
    />

    <SummaryElement
      class="mt-n3"
      header="Beneficiary Type"
      v-if="beneficiaryData['3'].isValid"
      :content="beneficiaryData['3'].isRevocable ? 'Revocable' : 'Irrevocable'"
    />

    <v-row>
      <v-col cols="2" />
      <v-col cols="8">
        <v-checkbox
          v-model="terms"
          type="checkbox"
          :label="$t('insurance.cbigTerms')"
        />
      </v-col>
    </v-row>

    <v-row class="mt-n6 pb-6">
      <v-col class="centerThis">
        <v-btn
          :disabled="!terms"
          @click="dialog = true"
          class="packages success"
          type="submit"
        >
          Proceed to Payment
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="600">
      <PaymentInformation
        :premium="parseFloat(this.packageDetails[this.selectedPackage].price)"
        v-on:close-dialog="paymentMade"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { loadUserValidationData } from "@/store/user_validation";

import PaymentInformation from "./PaymentInformation";
import SummaryElement from "./SummaryElement";

export default {
  name: "InsuranceSummary",

  components: {
    PaymentInformation,
    SummaryElement
  },

  props: {
    beneficiaryData: Object,
    packageDetails: Object,
    selectedPackage: String
  },

  computed: {
    hasPadding() {
      return window.innerWidth > 500;
    }
  },

  data: () => ({
    dialog: false,
    terms: false,
    userData: {}
  }),

  mounted() {
    loadUserValidationData().then(data => {
      this.userData = { ...data };

      if (!this.userData.lastname) {
        this.userData.lastname = "";
      }

      if (!this.userData.sex) {
        this.userData.sex = "";
      }

      if (!this.userData.maritalStatus) {
        this.userData.maritalStatus = "";
      }
    });
  },

  methods: {
    paymentMade() {
      this.dialog = false;
      this.$emit("payment-made");
    }
  }
};
</script>

<style scoped>
.boldThis {
  font-weight: bold;
}

.centerThis {
  margin: auto;
  text-align: center;
}
</style>
