<template>
  <div>
    <v-divider class="mb-10" />

    <v-data-table :items-per-page="15" dense :headers="headers" :items="items">
      <template v-slot:item="row">
        <tr>
          <td class="text-center">{{ row.item.userCode }}</td>
          <td class="text-left">{{ row.item.lastName }}</td>
          <td class="text-left">{{ row.item.firstName }}</td>
          <td class="text-left">{{ row.item.middleName }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "OffsitePrimaryMember",

  data: () => ({
    headers: [
      { text: "User Code", value: "userCode", align: "center" },
      { text: "Last Name", value: "lastName", align: "center" },
      { text: "First Name", value: "firstName", align: "center" },
      { text: "Middle Name", value: "middleName", align: "center" }
    ],
    items: []
  })
};
</script>
