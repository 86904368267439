<template>
  <div class="pa-4" ref="printSection">
    <Header header="PHYSICAL EXAMINATION" class="mb-5" />

    <table class="equal-columns show-borders">
      <caption class="mb-2">
        Vital Signs
      </caption>

      <tr>
        <th class="header-entry" scope="col">Height</th>
        <th class="header-entry" scope="col">Weight</th>
        <th class="header-entry" scope="col">Blood Pressure</th>
        <th class="header-entry" scope="col">Pulse</th>
        <th class="header-entry" scope="col">Respiration</th>
      </tr>
      <tr>
        <td class="height-30 show-borders text-center">
          {{ triage.height || "" }} cm
        </td>
        <td class="height-30 show-borders text-center">
          {{ triage.weight || "" }} kg
        </td>
        <td class="height-30 show-borders text-center">
          {{ triage.bp || "" }}
        </td>
        <td class="height-30 show-borders text-center">
          {{ triage.pulse || "" }}
        </td>
        <td class="height-30 show-borders text-center">
          {{ triage.rr || "" }}
        </td>
      </tr>
    </table>

    <div class="mt-5 show-borders" style="height: 160px;">
      <div class="ma-3 smaller-bold">SUMMARY OF FINDINGS</div>

      <div class="ma-3 text-left">{{ doctorsAssessment.assessment || "" }}</div>
    </div>
    <div class="d-flex mt-5">
      <TextField
        label="EXAMINING PHYSICIAN:"
        :value="doctorName"
        class="width-left"
      />
      <TextField
        label="PRC License #:"
        :value="doctorNumber"
        class="width-right"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Header from "./Header.vue";
import TextField from "../../components/TextField.vue";

export default {
  components: {
    Header,
    TextField
  },

  computed: {
    ...mapGetters({
      triage: "getTriage",
      doctorsAssessment: "getDoctorsAssessment"
    }),

    doctorName() {
      return this.doctorsAssessment && this.doctorsAssessment.doctor
        ? this.doctorsAssessment.doctor.name || ""
        : "";
    },

    doctorNumber() {
      return this.doctorsAssessment && this.doctorsAssessment.doctor
        ? this.doctorsAssessment.doctor.number || ""
        : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.equal-columns {
  table-layout: fixed;
  width: 100%;
}

.show-borders {
  border: 1px solid black;
  border-collapse: collapse;
}

.smaller-bold {
  font-size: small;
  font-weight: bold;
}

.header-entry {
  font-weight: bold;
  font-size: smaller;

  @extend .show-borders;
}

.height-30 {
  height: 30px;
}

.width-left {
  width: 450px;
}

.width-right {
  margin-left: 25px;
  width: 240px;
}
</style>
