<template>
  <v-container>
    <BackButton @click="$emit('back')" />

    <p class="font-weight-bold mb-2 mt-7 primary--text text-center text-h6">
      {{ $store.getters["lab/appointment/getClinicType"] }}
    </p>
    <p class="mb-7 text-center">
      Please select a location where you want to avail laboratory services then
      click NEXT.
    </p>

    <SelectLocation
      :dbLocation="dbLocations"
      :selectedLocation.sync="selectedLocation"
    />

    <div v-if="$store.getters['lab/appointment/getIsHomeService']">
      <v-text-field
        v-model="addressLine"
        :rules="[v => !!v || 'Address details required']"
        label="House No. / Building No., Subdivisiom"
      />

      <v-text-field
        v-model="contact"
        label="Contact Number"
        :rules="[v => !!v || 'Contact number required']"
      />
    </div>

    <NextButton :disabled="disableNextButton" @click="emitNext()" />
  </v-container>
</template>

<script>
import BackButton from "@/components/Controls/BackButton.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import SelectLocation from "@/components/SelectLocation.vue";

export default {
  name: "RemoteServices",

  components: {
    BackButton,
    NextButton,
    SelectLocation
  },

  data: () => ({
    contact: "",
    addressLine: "",

    selectedLocation: null
  }),

  mounted() {
    this.contact = this.$store.getters["lab/appointment/getContact"];
    this.addressLine = this.$store.getters["lab/appointment/getAddressLine"];
  },

  methods: {
    emitNext() {
      this.$store.commit("lab/appointment/SET_CONTACT", this.contact, {
        root: true
      });
      this.$store.commit(
        "lab/appointment/SET_LOCATION",
        {
          ...this.selectedLocation,
          addressLine: this.addressLine
        },
        { root: true }
      );

      this.$emit("next");
    }
  },

  computed: {
    dbLocations() {
      return this.$store.getters["lab/appointment/getIsHomeService"]
        ? "homeServiceLocations"
        : "mobileServiceLocations";
    },

    disableNextButton() {
      return (
        !this.selectedLocation ||
        !this.selectedLocation.barangay ||
        (this.$store.getters["lab/appointment/getIsHomeService"] &&
          (!this.contact || !this.addressLine))
      );
    }
  }
};
</script>
