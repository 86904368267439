<template>
  <div>
    <v-stepper v-model="step" flat>
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Pumili ng Serbisyo
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2">
          Pumili ng Lugar
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 3" step="3">
          Pumili ng Test
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 4" step="4">
          Pumili ng Schedule
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="5">
          {{ $t("dialog.confirmation") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          step="1"
          class="pt-0"
          :class="{ 'pa-1': $vuetify.breakpoint.mobile }"
        >
          <SelectClinicType @next="step = 2" />
        </v-stepper-content>

        <v-stepper-content
          step="2"
          class="pt-0"
          :class="{ 'pa-1': $vuetify.breakpoint.mobile }"
        >
          <SelectLocation @back="step = 1" @next="step = 3" :key="step !== 2" />
        </v-stepper-content>

        <v-stepper-content
          step="3"
          class="pt-0"
          :class="{ 'pa-1': $vuetify.breakpoint.mobile }"
        >
          <TestTab @back="step = 2" @next="step = 4" :key="step !== 3" />
        </v-stepper-content>

        <v-stepper-content
          step="4"
          :class="{ 'pa-1': $vuetify.breakpoint.mobile }"
        >
          <ScheduleTab
            @back="step = 3"
            @next="step = 5"
            :key="renderSchedule"
          />
        </v-stepper-content>

        <v-stepper-content
          step="5"
          :class="{ 'pa-1': $vuetify.breakpoint.mobile }"
        >
          <ConfirmSchedule
            @back="step = 4"
            @next="step = 1"
            :key="step !== 5"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="dialog" width="990" persistent>
      {{ payref }}
      <Checkout
        :payref="payref"
        :bhcdetails="bhcdetails"
        :sum="Number(total)"
        :total="Number(total)"
        @check-out="checkOutStationaryMobile"
        @on-confirm="step = 1"
        @dialog-close="dialogClose"
      />
    </v-dialog>

    <ConfirmDialog ref="confirm" @onClose="onConfirmationClose" />
  </div>
</template>

<script>
import { DB } from "@/common/store";
import { SERVICE_LABORATORY } from "@/common/utilities/services";

import { db } from "@/main";
import { setAppointment } from "@/store/diagnostic_attendee";
import { updateCardBalance } from "@/store";

import Checkout from "@/eTindahan/components/Checkout.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import ConfirmSchedule from "./Appointment/ConfirmSchedule.vue";
import ScheduleTab from "./Appointment/ScheduleTab.vue";
import SelectClinicType from "./Appointment/SelectClinicType.vue";
import SelectLocation from "./Appointment/SelectLocation.vue";
import TestTab from "./Appointment/TestTab.vue";

export default {
  name: "Appointment",

  components: {
    Checkout,
    ConfirmDialog,
    ConfirmSchedule,
    ScheduleTab,
    SelectClinicType,
    SelectLocation,
    TestTab
  },

  props: {
    selectedTab: Number,
    appointmentId: String
  },

  data: () => ({
    payref: SERVICE_LABORATORY,
    dialog: false,

    step: 1,

    renderSchedule: false
  }),

  mounted() {
    if (this.appointmentId) {
      this.loadAppointment(this.appointmentId).then(() => {
        this.step = this.selectedTab;
        this.renderSchedule = !this.renderSchedule;
      });
    }
  },

  methods: {
    dialogClose(val) {
      this.dialog = val;
    },

    loadAppointment(id) {
      return new Promise((resolve, reject) => {
        db.collection(DB.DIAGNOSTIC_APPOINTMENTS)
          .doc(id)
          .get()
          .then(doc => {
            if (doc.exists) {
              const data = doc.data();
              this.$store.dispatch(
                "lab/appointment/setAppointment",
                {
                  ...data,
                  id: doc.id
                },
                { root: true }
              );
            }

            resolve();
          })
          .catch(err => reject(err));
      });
    },

    onConfirmationClose() {
      this.$emit("on-confirm");
      this.$emit("dialog-close", false);
    },

    showConfirmationMessage() {
      this.$refs.confirm.open(
        this.$t("dialog.confirmation"),
        `Naitala na po ang inyong appointment. Mangyaring dumating ng 10 minutong mas maaga sa inyong appointment. Maraming salamat po!`,
        { noconfirm: true }
      );
    },

    checkOutStationaryMobile(val) {
      setAppointment({
        id: this.bhcdetails.id,

        // schedule
        scheduleId: this.bhcdetails.scheduleId,
        scheduleStart: this.bhcdetails.scheduleStart,
        scheduleEnd: this.bhcdetails.scheduleEnd,

        // slot schedule
        start: this.bhcdetails.start,
        end: this.bhcdetails.end,

        // service details
        serviceId: this.bhcdetails.service.serviceId,
        serviceLocation: this.bhcdetails.service.serviceLocation,
        serviceName: this.bhcdetails.service.serviceName,
        registrants: this.bhcdetails.registrants,
        isPaid: true,

        total: Math.round(val.total),
        paidThru: val.paidThru,
        name: val.name,
        address: val.address,
        contact: val.contact,

        remainingbalance: val.remainingbalance,
        isBantayKardPayment: val.isBantayKardPayment
      }).then(() => {
        this.showConfirmationMessage();
      });

      if (val.isBantayKardPayment) {
        updateCardBalance(val.remainingbalance);
      }
      this.dialog = false;
    }
  }
};
</script>
