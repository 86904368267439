export const ADDRESS_LINE_1 = "addressLine1";
export const BARANGAY = "barangay";
export const MUNICIPALITY = "municipality";
export const PROVINCE = "province";
export const REGION = "region";

export function getLocation(
  region,
  province,
  municipality = "",
  barangay = "",
  addressLine1 = ""
) {
  return {
    [REGION]: region,
    [PROVINCE]: province,
    [MUNICIPALITY]: municipality,
    [BARANGAY]: barangay,
    [ADDRESS_LINE_1]: addressLine1
  };
}
