<template>
  <div class="d-flex single-line">
    <div v-if="label" class="float-left pr-1 smaller-bold">{{ label }}:</div>

    <div
      class="px-2 smaller-font"
      :class="{ 'ml-5': !label, 'width-100': doFill }"
      style="border-bottom: 1px solid black;"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextField",

  props: {
    doFill: {
      type: Boolean,
      default: true
    },
    label: String,
    value: String
  }
};
</script>

<style scoped>
.single-line {
  overflow: hidden;
  white-space: nowrap;
}

.width-100 {
  width: 100% !important;
}
</style>
