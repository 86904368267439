<template>
  <v-snackbar max-width="300" top right color="primary" v-model="notify">
    <div>
      <div class="text-left caption">{{ title }}</div>

      <v-card-text class="font-weight-light caption text-justify">
        {{ subject }}
      </v-card-text>
    </div>
  </v-snackbar>
</template>

<script>
import "firebase/messaging";

export default {
  name: "NotificationBox",

  props: {
    getNotification: Object
  },

  data() {
    return {
      notify: false,
      subject: "",
      title: ""
    };
  },

  watch: {
    getNotification() {
      this.setNotificationBoxForm(
        this.getNotification.notification.title,
        this.getNotification.notification.body,
        this.getNotification.notification.image
      );
    }
  },

  methods: {
    setNotificationBoxForm(title, body) {
      this.title = title;
      this.subject = body;
      this.notify = true;
    }
  }
};
</script>

<style scoped>
.notification-box {
  display: flex;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 30%;
  height: 100px;
  background-color: #fff;
  z-index: 999;
}

#notification-close {
  position: absolute;
  color: #777;
  font: 14px;
  right: 5px;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  top: 2px;
  z-index: 10;
  cursor: pointer;
}

.notification-data {
  margin: 0 20px;
}

.notification-data p {
  margin: 10px 0;
}

.notification-notice p {
  font-size: 14px;
  font-weight: 400;
}

.notification-title p {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}

.notification-time small {
  font-size: 12px;
}

.notification-wall-number small {
  font-size: 12px;
}
</style>
