<template>
  <v-card flat class="pa-0">
    <v-toolbar v-show="!createAccount" color="green" dark flat>
      <v-toolbar-title class="centerThis py-0 my-0">
        Sign in with phone
      </v-toolbar-title>

      <v-icon color="red" medium @click="closeDialog" style="float: right;">
        mdi mdi-close
      </v-icon>
    </v-toolbar>

    <v-alert type="error" v-if="errorText">{{ errorText }}</v-alert>

    <v-form ref="form" v-model="valid">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Mobile Number"
              prepend-icon="mdi-plus"
              type="number"
              v-model="phone"
            >
              <template v-slot:append>
                <v-btn
                  depressed
                  x-small
                  color="success"
                  @click="sendOtp"
                  :disabled="phone.length != 12"
                >
                  Get OTP
                </v-btn>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" class="centerThis py-0" style="font-size: smaller;">
            {{ otpMessage }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="px-1" cols="2" sm="2">
            <v-text-field
              class="select-container"
              @input="getFocus('txt2')"
              @focus="$event.target.select()"
              maxlength="1"
              id="txt1"
              v-model="one"
              outlined
              :disabled="phone.length != 12 || !otpRequested"
            />
          </v-col>

          <v-col class="px-1" cols="2">
            <v-text-field
              class="select-container"
              @input="getFocus('txt3')"
              @focus="$event.target.select()"
              maxlength="1"
              id="txt2"
              v-model="two"
              outlined
              :disabled="phone.length != 12 || !otpRequested"
            />
          </v-col>

          <v-col class="px-1" cols="2">
            <v-text-field
              class="select-container"
              @input="getFocus('txt4')"
              @focus="$event.target.select()"
              maxlength="1"
              id="txt3"
              v-model="three"
              outlined
              :disabled="phone.length != 12 || !otpRequested"
            />
          </v-col>

          <v-col class="px-1" cols="2">
            <v-text-field
              class="select-container"
              @input="getFocus('txt5')"
              @focus="$event.target.select()"
              maxlength="1"
              id="txt4"
              v-model="four"
              outlined
              :disabled="phone.length != 12 || !otpRequested"
            />
          </v-col>

          <v-col class="px-1" cols="2">
            <v-text-field
              class="select-container"
              @input="getFocus('txt6')"
              @focus="$event.target.select()"
              maxlength="1"
              id="txt5"
              v-model="five"
              outlined
              :disabled="phone.length != 12 || !otpRequested"
            />
          </v-col>

          <v-col class="px-1" cols="2">
            <v-text-field
              class="select-container"
              id="txt6"
              @input="getFocus('txt6')"
              @focus="$event.target.select()"
              maxlength="1"
              v-model="six"
              outlined
              :disabled="phone.length != 12 || !otpRequested"
            />
          </v-col>
        </v-row>

        <p class="centerThis">{{ errorMessage }}</p>

        <p class="centerThis" v-if="!otpRules">{{ otpRules }}</p>
      </v-card-text>

      <div id="recaptcha-container" />
    </v-form>

    <div id="recaptcha-container"></div>
  </v-card>
</template>

<script>
import "firebase/auth";
import firebase from "firebase";

export default {
  name: "AuthenticationPhoneSignin",

  props: {
    linkToAccount: {
      type: Boolean,
      default: false
    },
    createAccount: Boolean
  },

  data: () => ({
    phone: "",
    appVerifier: "",
    valid: false,
    errorText: "",

    errorMessage: "",
    otpMessage: "",
    otpRequested: false,
    otpRules: true,

    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: ""
  }),

  watch: {
    one() {
      this.verifyOtp();
    },

    two() {
      this.verifyOtp();
    },

    three() {
      this.verifyOtp();
    },

    four() {
      this.verifyOtp();
    },

    five() {
      this.verifyOtp();
    },

    six() {
      this.verifyOtp();
    },

    phone() {
      this.$emit("phoneNumber", this.phone);
    }
  },

  mounted() {
    this.phone = "639";
    this.initReCaptcha();
  },

  computed: {
    disableGetOtp() {
      return this.phone.length != 12;
    },

    isComplete() {
      return (
        this.one !== "" &&
        this.two !== "" &&
        this.three !== "" &&
        this.four !== "" &&
        this.five !== "" &&
        this.six !== ""
      );
    }
  },

  methods: {
    getFocus(val) {
      if (val) {
        document.getElementById(val).focus();
      }
    },

    closeDialog() {
      this.$emit("onClose");
    },

    initReCaptcha() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          }
        }
      );

      this.appVerifier = window.recaptchaVerifier;
    },

    otpSendError(/*err*/) {
      // Error; SMS not sent
      // ...
      this.otpMessage = "Error sending OTP.";
    },

    otpSent(confirmationResult) {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;

      this.otpMessage = "OTP sent.";
    },

    signinAndLink() {
      firebase
        .auth()
        .currentUser.linkWithPhoneNumber(`+${this.phone}`, this.appVerifier)
        .then(confirmationResult => {
          this.otpSent(confirmationResult);
        })
        .catch(err => {
          this.otpSendError(err);
        });
    },

    sendOtp() {
      this.otpMessage = "Sending OTP...";
      this.otpRequested = true;

      if (this.linkToAccount) {
        this.signinAndLink();
      } else {
        this.signin();
      }
    },

    signin() {
      firebase
        .auth()
        .signInWithPhoneNumber(`+${this.phone}`, this.appVerifier)
        .then(confirmationResult => {
          this.otpSent(confirmationResult);
        })
        .catch(err => {
          this.otpSendError(err);
        });
    },

    verifyOtp() {
      if (this.isComplete) {
        const otp =
          this.one + this.two + this.three + this.four + this.five + this.six;

        window.confirmationResult
          .confirm(otp)
          .then(result => {
            this.otpRules = true;
            this.$emit("onSuccess", result);
          })
          .catch(err => {
            if ("auth/credential-already-in-use" === err.code) {
              this.errorMessage = "This phone number is used by another user";
              this.otpMessage = "";
            } else {
              this.otpRules = "Wrong OTP";
            }
          });
      }
    }
  }
};
</script>

<style scoped>
.v-text-field >>> input {
  font-size: 1.5em;
  font-weight: 100;
  text-transform: capitalize;
}

.v-text-field >>> label {
  font-size: 0.8em;
}

.v-text-field >>> button {
  font-size: 0.8em;
}

.centerThis {
  margin: auto;
  text-align: center;
}

.errorColor {
  color: red;
}

.select-container >>> input {
  text-align: center;
}
</style>
