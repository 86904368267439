<template>
  <div class="text-center">
    <v-form
      ref="form"
      v-model="valid"
      :lazy-validation="false"
      @submit.prevent="checkOut"
    >
      <div class="text-center pb-4">
        <v-btn
          :disabled="!valid || disabled"
          color="success"
          @click="validate"
          type="submit"
        >
          Checkout
        </v-btn>
      </div>
    </v-form>

    <ConfirmDialog ref="confirm" @onClose="onConfirmationClose" />
  </div>
</template>

<script>
import { getCurrentUserUid } from "@/common/store";
import { isBantayKardPayment } from "@/common/utilities/payment";
import { mapGetters } from "vuex";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";

export default {
  name: "Checkout",

  components: {
    ConfirmDialog
  },

  props: {
    total: Number,
    disabled: Boolean
  },

  data: () => ({
    valid: false
  }),

  computed: {
    ...mapGetters({
      balance: "getBantayKardBalance",
      modeOfPayment: "getModeOfPayment"
    }),

    isBantayKardPayment() {
      return isBantayKardPayment(this.modeOfPayment);
    },

    remainingbalance() {
      return Number(this.balance) - this.total;
    }
  },

  methods: {
    checkOut() {
      this.$emit("check-out", {
        total: this.total,
        soldTo: getCurrentUserUid(),
        trackingNumber: "for payment",
        remainingbalance: this.remainingbalance,
        isBantayKardPayment: this.isBantayKardPayment
      });
    },

    onConfirmationClose() {
      this.$emit("on-confirm");
      this.$emit("dialog-close", false);
    },

    validate() {
      this.$refs.form.validate();
    }
  }
};
</script>
