<template>
  <div class="ma-2">
    <div v-if="ORDER_STATUS_RETURNREFUND === status">
      <v-row style="height: 10px;">
        <v-col sm="9" cols="6" />
        <v-col sm="3" cols="6" class="py-0 pr-7 text-right">
          <v-divider />
        </v-col>
      </v-row>
      <v-row
        class="font-weight-bold pb-2"
        :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
      >
        <v-col sm="9" cols="6" class="py-0 text-right">
          Refund Amount <span v-show="isVerifyingPwdScId">*</span>
        </v-col>
        <v-col
          sm="3"
          cols="6"
          class="py-0  text-right"
          :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
        >
          <PesoAmount :amount="returnRefundDetails.refundAmount" />
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <div>
        <v-row style="height: 10px;">
          <v-col sm="9" cols="6" />
          <v-col sm="3" cols="6" class="py-0 pr-7 text-right">
            <v-divider />
          </v-col>
        </v-row>

        <v-row
          class="font-weight-bold"
          :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
        >
          <v-col sm="9" cols="6" class="pt-0 text-right">
            Subtotal <span v-show="isVerifyingPwdScId">*</span>
          </v-col>
          <v-col
            sm="3"
            cols="6"
            class="pt-0  text-right"
            :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
          >
            <PesoAmount :amount="subtotal" />
          </v-col>
        </v-row>

        <v-row
          class="font-weight-bold"
          :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
        >
          <v-col sm="9" cols="6" class="pt-0 text-right">
            Shipping Fee
          </v-col>
          <v-col
            sm="3"
            cols="6"
            class="pt-0  text-right"
            :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
          >
            <span v-show="shippingFee <= 0">* </span>
            <PesoAmount :amount="isNaN(shippingFee) ? 0 : shippingFee" />
          </v-col>
        </v-row>
      </div>

      <div>
        <v-row
          style="height: 10px;"
          :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
        >
          <v-col sm="9" cols="6" class="pt-0 text-right" />
          <v-col
            sm="3"
            cols="6"
            class="py-0 text-right"
            :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
          >
            <v-divider />
          </v-col>
        </v-row>

        <v-row
          class="font-weight-bold pb-2"
          :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
        >
          <v-col sm="9" cols="6" class="py-0 text-right">
            Total <span v-show="isVerifyingPwdScId">*</span>
          </v-col>
          <v-col
            sm="3"
            cols="6"
            class="py-0  text-right"
            :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
          >
            <PesoAmount :amount="total" />
          </v-col>
        </v-row>

        <v-row
          v-if="balance > 0"
          style="height: 10px;"
          :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
        >
          <v-col sm="9" cols="6" class="pt-0 text-right" />
          <v-col
            sm="3"
            cols="6"
            class="py-0 text-right"
            :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
          />
        </v-row>
        <v-row
          v-if="totalRefund && !IsCashOnDelivery(modeOfPayment)"
          class="font-weight-bold pr primary--text"
          :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
        >
          <v-col sm="9" cols="6" class="py-0 text-right">Refund Amount</v-col>
          <v-col
            sm="3"
            cols="6"
            class="py-0  text-right"
            :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
          >
            <PesoAmount :amount="totalRefund" />
          </v-col>
        </v-row>

        <div
          v-if="
            (balance !== 0 &&
              balance !== total &&
              paymentMade !== total &&
              status !== ORDER_STATUS_COMPLETED) ||
              PAYMENT_EXCESS_AMOUNT === paymentStatus
          "
        >
          <v-row
            class="font-weight-bold mt-1"
            :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
          >
            <v-col sm="9" cols="6" class="py-0 text-right">
              Payment Made
            </v-col>
            <v-col
              sm="3"
              cols="6"
              class="py-0 text-right"
              :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
            >
              <PesoAmount :amount="paymentMade" />
            </v-col>
          </v-row>
          <v-row
            style="height: 10px;"
            :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
          >
            <v-col sm="9" cols="6" class="pt-0 text-right" />
            <v-col
              sm="3"
              cols="6"
              class="py-0 text-right"
              :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
            >
              <v-divider />
            </v-col>
          </v-row>
          <v-row
            class="font-weight-bold pr"
            :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
          >
            <v-col sm="9" cols="6" class="py-0 text-right">
              Balance
            </v-col>
            <v-col
              sm="3"
              cols="6"
              class="py-0  text-right"
              :class="{ 'pl-1': $vuetify.breakpoint.mobile }"
            >
              <PesoAmount :amount="balance" />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <StatusOrder
      v-show="statusPendings.includes(paymentStatus)"
      :paymentStatus="paymentStatus"
      :status="status"
      :checkout="checkout"
      :paymentMade="paymentMade"
      :balance="balance"
      :total="total"
      :totalVerifiedPayments="totalVerifiedPayments"
    />

    <v-card
      flat
      class="mt-4"
      v-show="
        TARGET_NEW_PAYMENT === paymentStatus &&
          !getModeOfPayments(checkout).includes(PAYMENT_CASH_ON_DELIVERY)
      "
    >
      <span v-show="status !== ORDER_STATUS_COMPLETED || getMOP(checkout)">
        *Please prepare <PesoAmount :amount="balance" /> for COD.
      </span>
      <span v-show="ORDER_STATUS_COMPLETED === status">
        *Medisure Team is reviewing your payment of
        <PesoAmount :amount="balance" />.
      </span>
    </v-card>

    <PwdScMessage v-if="isVerifyingPwdScId" />
  </div>
</template>

<script>
import {
  INVALID_REFERENCE_NO,
  PAYMENT_CASH_ON_DELIVERY,
  PAYMENT_EXCESS_AMOUNT,
  PAYMENT_INSUFFICIENT_AMOUNT,
  TARGET_NEW_PAYMENT,
  getModeOfPayments,
  IsCashOnDelivery
} from "@/common/utilities/payment";
import {
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_RETURNREFUND
} from "@/common/utilities/order";

import PesoAmount from "@/common/components/PesoAmount.vue";
import PwdScMessage from "./PwdScMessage.vue";
import StatusOrder from "@/eTindahan/components/StatusOrder.vue";

export default {
  name: "TotalBreakdown",

  components: {
    PesoAmount,
    PwdScMessage,
    StatusOrder
  },

  data: () => ({
    statusPendings: [
      INVALID_REFERENCE_NO,
      PAYMENT_INSUFFICIENT_AMOUNT,
      PAYMENT_EXCESS_AMOUNT
    ],

    ORDER_STATUS_COMPLETED: ORDER_STATUS_COMPLETED,
    ORDER_STATUS_RETURNREFUND: ORDER_STATUS_RETURNREFUND,
    PAYMENT_CASH_ON_DELIVERY: PAYMENT_CASH_ON_DELIVERY,
    PAYMENT_EXCESS_AMOUNT: PAYMENT_EXCESS_AMOUNT,
    TARGET_NEW_PAYMENT: TARGET_NEW_PAYMENT,

    getModeOfPayments: getModeOfPayments,
    IsCashOnDelivery: IsCashOnDelivery
  }),

  props: {
    checkout: Object,
    isVerifyingPwdScId: Boolean,
    modeOfPayment: String,
    paymentMade: Number,
    paymentStatus: String,
    returnRefundDetails: Object,
    shippingFee: Number,
    status: String,
    subtotal: Number,
    total: Number,
    totalRefund: Number,
    totalVerifiedPayments: Number
  },

  computed: {
    balance() {
      return this.total - this.totalVerifiedPayments;
    }
  },

  methods: {
    getMOP(checkout) {
      return checkout
        ? getModeOfPayments(checkout).includes(PAYMENT_CASH_ON_DELIVERY)
        : false;
    }
  }
};
</script>
