<template>
  <v-container fluid>
    <h3 class="my-4">Present Medical History</h3>
    <div>
      Check those questions to which you answer yes (leave the others blank).
    </div>

    <v-container fluid>
      <p
        v-for="(selectedPresent,
        present) in presentMedicalHistory.selectedPresent"
        :key="present"
      >
        {{ present + 1 }}. {{ selectedPresent }}
        <strong class="floaterr">Yes</strong>
      </p>
    </v-container>

    <div>Do you now have or have you recently experienced?</div>
    <v-container fluid>
      <p
        v-for="(selectedPresentExp,
        presentexp) in presentMedicalHistory.selectedPresentExp"
        :key="presentexp"
      >
        {{ presentexp + 1 }}. {{ selectedPresentExp }}
        <strong class="floaterr"> Yes</strong>
      </p>

      <div>
        Comment:
        <strong class="floaterr">
          {{ presentMedicalHistory.selectedPresentExpComment }}
        </strong>
      </div>
    </v-container>

    <div fluid v-if="isFemale">
      Women only answer the following. Do you have:
    </div>
    <v-container fluid v-if="isFemale">
      <p
        class="pb-2"
        v-for="(selectedWomen,
        selectedW) in presentMedicalHistory.selectedWomen"
        :key="selectedW"
      >
        {{ selectedW + 1 }}. {{ selectedWomen }}
        <strong class="floaterr">Yes </strong>
      </p>
      <div class="py-2">
        Last Pelvic Examination Date:
        <strong class="floaterr">
          {{ presentMedicalHistory.lastPelvicExam }}
        </strong>
      </div>
    </v-container>

    <div>Men and women answer the following:</div>
    <div class="py-2">
      List any prescription medications you are now taking:
      <strong class="floaterr">
        {{ presentMedicalHistory.listPrescribedmed }}
      </strong>
    </div>
    <div class="py-2">
      Last hospitalization, including dates of and reasons for hospitalization:
      <strong class="floaterr">
        {{ presentMedicalHistory.listHopitalized }}
      </strong>
    </div>
    <div class="py-2">
      List any drug allergies:
      <strong class="floaterr">
        {{ presentMedicalHistory.listDrugAllergy }}
      </strong>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "PresentMedicalHistoryView",

  props: {
    isFemale: Boolean,
    presentMedicalHistory: Object
  },

  data: () => ({
    selectedPresent: [],
    selectedPresentExp: [],
    selectedPresentExpComment: "",
    selectedWomen: [],
    lastPelvicExam: "",
    listPrescribedmed: "",
    listHopitalized: "",
    listDrugAllergy: ""
  })
};
</script>

<style scoped>
.floaterr {
  float: right;
}
</style>
