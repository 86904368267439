<template>
  <div>
    <v-row class="mb-4">
      <v-col>
        Ito po ang inyong napiling schedule:
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="3">Type</v-col>
      <v-col cols="8">{{ clinicType }}</v-col>
    </v-row>

    <div v-if="clinicType == 'Home Service'">
      <v-row class="mt-n4">
        <v-col cols="1"></v-col>
        <v-col cols="3">Address</v-col>
        <v-col cols="8">{{ homeServiceLocation }}</v-col>
      </v-row>

      <v-row
        class="mt-n4"
        v-for="(prefDateTime, index) in selectedPrefferedDateTime"
        :key="index"
      >
        <v-col cols="1"></v-col>
        <v-col cols="3">{{ index + 1 }}. Preferred</v-col>
        <v-col cols="8">{{ prefDateTime.type }}</v-col>

        <v-row class="mt-n4" v-if="prefDateTime.type != 'Specific Time'">
          <v-col cols="1"></v-col>
          <v-col cols="3" v-if="index == 0"></v-col>
          <v-col cols="3" v-else></v-col>
          <v-col cols="8"> {{ prefDateTime.date }} </v-col>
        </v-row>
        <v-row class="mt-n4" v-else>
          <v-col cols="1"></v-col>
          <v-col cols="3" v-if="index == 0"></v-col>
          <v-col cols="3" v-else></v-col>
          <v-col cols="8"> {{ index + 1 }}. {{ getDate(prefDateTime) }} </v-col>
        </v-row>
      </v-row>
    </div>

    <div v-else>
      <v-row class="mt-n4">
        <v-col cols="1"></v-col>
        <v-col cols="3">Serbisyo</v-col>
        <v-col cols="8">{{ name }} sa {{ location }}</v-col>
      </v-row>

      <v-row class="mt-n4">
        <v-col cols="1"></v-col>
        <v-col cols="3">Petsa at Oras</v-col>
        <v-col cols="8">{{ start }}</v-col>
      </v-row>
    </div>
    <v-row class="mb-4" v-if="packageSelected != ''">
      <v-col>
        Ito po ang inyong napiling Package:
      </v-col>
    </v-row>
    <v-row class="mt-n4" v-for="(pTest, index) in packageSelected" :key="index">
      <v-row class="mb-4">
        <v-col cols="1"></v-col>
        <v-col cols="3">{{ index + 1 }}. {{ pTest.testname }} </v-col>
        <v-col cols="8"> <PesoAmount :amount="Number(pTest.price)"/></v-col>
        <v-col cols="1"></v-col>
        <v-col cols="3">Description </v-col>
        <v-col cols="8"> {{ pTest.description }}</v-col>
        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="8">
            <div v-for="(test, index) in pTest.tests" :key="'a' + index">
              {{ test }}
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-row>
    <v-row v-if="individualTest != ''" class="mb-4">
      <v-col>
        Napiling Individual Test:
      </v-col>
    </v-row>
    <v-row
      class="mt-n4"
      v-for="(individual, index) in individualTest"
      :key="'c' + index"
    >
      <v-col cols="1"></v-col>
      <v-col cols="3">{{ index + 1 }}. {{ individual.testname }}</v-col>
      <v-col cols="8"> <PesoAmount :amount="Number(individual.price)"/></v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col>
        Kung tama ang mga detalyeng ito, paki-click ang
        <strong>IKUMPIRMA ANG SCHEDULE</strong>.
      </v-col>
    </v-row>

    <v-row class="mt-n4">
      <v-col cols="1"></v-col>
      <v-col cols="3">Total Price na Babayaran</v-col>
      <v-col cols="8">
        <strong> <PesoAmount :amount="Number(total)" /> </strong>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="6">
        <v-btn color="primary" @click="onBack">Bumalik</v-btn>
      </v-col>
      <v-col cols="6" style="text-align: right;">
        <v-btn color="primary" @click="confirmFind">
          Ikumpirma ang Schedule
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="990" persistent>
      <Checkout
        :payref="'Laboratory'"
        :bhcdetails="bhcdetails"
        :sum="total"
        :total="total"
        :unfilteredPurchase="unfilteredPurchase"
        @check-out="checkOut"
      />
    </v-dialog>

    <ConfirmDialog ref="confirm" @on-close="onConfirmationClose" />
  </div>
</template>

<script>
import { REBOOKED } from "@/common/utilities/appointments";
import {
  GetRebookHistory,
  setHomeServiceMessageboxStatus,
  SetHomeServiceStatusRebooked,
  updateCardBalance
} from "@/store";
import { getDateTime, getTime, timeStampToDate } from "@/common/utilities/date";
import {
  setAppointment,
  setAppointmentHomeService,
  SetAppointmentUnpaid
} from "@/store/diagnostic_attendee";

import Checkout from "@/components/eTindahan/Checkout.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "LaboratoryConfirmSchedule",

  components: {
    Checkout,
    ConfirmDialog,
    PesoAmount
  },

  data: () => ({
    dialog: false,
    unfilteredPurchase: 0,
    bhcdetails: [],
    rebookhistory: [],
    loadedRebookHistory: []
  }),

  props: {
    total: Number,
    clinicType: String,
    selectedPrefferedDateTime: Array,
    homeServiceLocation: String,
    contact: String,
    details: Object,
    packageSelected: Array,
    serviceid: String,
    msgid: String,
    nowHistory: Object,
    individualTest: Array,
    individualPrice: Number,
    packagePrice: Number
  },

  watch: {
    serviceid() {
      GetRebookHistory(this.serviceid).then(docs => {
        this.loadedRebookHistory = docs;
      });
    }
  },

  computed: {
    hasHistory() {
      if (this.loadedRebookHistory.rebookhistory === "undefined") return false;
      return this.loadedRebookHistory.rebookhistory ? true : false;
    },

    isRebooked() {
      return this.msgid !== "" && this.serviceid !== "";
    },

    location() {
      return this.details && this.details.service.location
        ? this.details.service.location
        : "";
    },

    name() {
      return this.details && this.details.service.name
        ? this.details.service.name
        : "";
    },

    start() {
      return this.details && this.details.slot.start
        ? getDateTime(this.details.slot.start)
        : "";
    }
  },

  methods: {
    confirmFind() {
      if (this.isRebooked) {
        this.rebook();
        return;
      }

      if (this.clinicType === "Home Service") {
        this.confirmScheduleHomeService();
      } else {
        this.confirmScheduleStationaryMobile();
      }
    },

    confirmScheduleStationaryMobile() {
      SetAppointmentUnpaid({
        // schedule
        clinicType: this.clinicType,

        scheduleId: this.details.id,
        scheduleStart: this.details.schedule.start,
        scheduleEnd: this.details.schedule.end,

        // slot schedule
        start: timeStampToDate(this.details.slot.start),
        end: timeStampToDate(this.details.slot.end),

        // service details
        serviceId: this.details.service.id,
        serviceLocation: this.location,
        serviceName: this.name,

        //package details
        packageSelected: this.packageSelected,
        individualTest: this.individualTest,
        isPaid: false
      }).then(id => {
        // will reserve data object for payment
        this.bhcdetails = {
          id: id,

          // schedule
          clinicType: this.clinicType,

          scheduleId: this.details.id,
          scheduleStart: this.details.schedule.start,
          scheduleEnd: this.details.schedule.end,

          // slot schedule
          start: this.details.slot.start,
          end: this.details.slot.end,

          service: {
            // service details
            serviceId: this.details.service.id,
            serviceLocation: this.location,
            serviceName: this.name
          },

          packageSelected: this.packageSelected,
          individualTest: this.individualTest,
          isPaid: false
        };

        this.dialog = true;
      });
      // .then(() => {
      //   this.checkOutStationaryMobile();
      // });
    },

    checkOut(val) {
      if (this.clinicType == "Home Service") {
        setAppointmentHomeService({
          id: this.bhcdetails.id,

          clinicType: this.bhcdetails.clinicType,
          selectedPrefferedDateTime: this.bhcdetails.selectedPrefferedDateTime,
          homeServiceLocation: this.bhcdetails.homeServiceLocation,

          total: Math.round(val.total),
          paidThru: val.paidThru,
          name: val.name,
          address: val.address,
          contact: val.contact,
          courier: val.courier,

          remainingbalance: val.remainingbalance,
          isBantayKardPayment: val.isBantayKardPayment,

          individualTest: this.bhcdetails.individualTest,
          packageSelected: this.bhcdetails.packageSelected,
          isPaid: false
        }).then(() => {
          this.showConfirmationMessageHomeService();
        });
      } else {
        setAppointment({
          id: this.bhcdetails.id,

          // schedule
          scheduleId: this.bhcdetails.scheduleId,
          scheduleStart: this.bhcdetails.scheduleStart,
          scheduleEnd: this.bhcdetails.scheduleEnd,

          // slot schedule
          start: this.bhcdetails.start,
          end: this.bhcdetails.end,

          // service details
          serviceId: this.bhcdetails.service.serviceId,
          serviceLocation: this.bhcdetails.service.serviceLocation,
          serviceName: this.bhcdetails.service.serviceName,

          total: Math.round(val.total),
          paidThru: val.paidThru,
          name: val.name,
          address: val.address,
          contact: val.contact,
          courier: val.courier,

          remainingbalance: val.remainingbalance,
          isBantayKardPayment: val.isBantayKardPayment,

          individualTest: this.bhcdetails.individualTest,
          packageSelected: this.bhcdetails.packageSelected,
          isPaid: true
        }).then(() => {
          this.showConfirmationMessage();
        });
      }

      if (val.isBantayKardPayment) {
        updateCardBalance(val.remainingbalance);
      }
      this.dialog = false;
    },

    confirmScheduleHomeService() {
      let selectedPrefferedDateTime = this.selectedPrefferedDateTime;
      if (this.selectedPrefferedDateTime[0].from == "Invalid Date") {
        selectedPrefferedDateTime = [];
      }

      SetAppointmentUnpaid({
        // schedule
        clinicType: this.clinicType,
        selectedPrefferedDateTime: selectedPrefferedDateTime,
        homeServiceLocation: this.homeServiceLocation,
        contact: this.contact,

        isPaid: false,
        packageSelected: this.packageSelected,
        individualTest: this.individualTest
      }).then(() => {
        //id
        this.showConfirmationMessageHomeService();
        // this.bhcdetails = {
        //   id: id,

        //   // schedule
        //   clinicType: this.clinicType,
        //   selectedPrefferedDateTime: selectedPrefferedDateTime,
        //   homeServiceLocation: this.homeServiceLocation,
        //   contact: this.contact,

        //   packageSelected: this.packageSelected,
        //   individualTest: this.individualTest,
        //   isPaid: true
        // };
      });
    },

    rebook() {
      GetRebookHistory(this.serviceid).then(docs => {
        this.loadedRebookHistory = docs;

        let mergedHistory = [];
        if (this.hasHistory) {
          mergedHistory.push(this.nowHistory);
          for (let i in this.loadedRebookHistory.rebookhistory) {
            mergedHistory.push(this.loadedRebookHistory.rebookhistory[i]);
          }
        } else {
          mergedHistory.push(this.nowHistory);
        }

        SetHomeServiceStatusRebooked({
          selectedPrefferedDateTime: this.selectedPrefferedDateTime,
          sID: this.serviceid,
          status: REBOOKED,
          rebookhistory: mergedHistory
        }).then(() => {
          setHomeServiceMessageboxStatus({
            docid: this.msgid,
            status: REBOOKED
          });
          this.showConfirmationMessage();
        });
      });
    },

    getDate(prefDate) {
      return `${getDateTime(prefDate.from)} - ${getTime(prefDate.to)}`;
    },

    onBack() {
      this.$emit("on-back");
    },

    onConfirmationClose() {
      this.$emit("on-confirm");
    },

    showConfirmationMessage() {
      this.$refs.confirm.open(
        "Kumpirmasyon",
        `Naitala na po ang inyong appointment. Mangyaring dumating ng 10 minutong mas maaga sa inyong appointment. Maraming salamat po!`,
        { noconfirm: true }
      );
    },

    showConfirmationMessageHomeService() {
      this.$refs.confirm.open(
        "Kumpirmasyon",
        `Naitala na po ang inyong request na magpa-appointment. Mangyaring antayin na ma-aprubahan sa loob ng na 24 oras. Maraming salamat po!`,
        { noconfirm: true }
      );
    }
  }
};
</script>
