<template>
  <v-card
    v-if="medicalHistory"
    class="pa-5 black--text"
    color="white"
    v-model="valid"
    tile
  >
    <h3 class="my-4">
      MEDICAL HISTORY QUESTIONNAIRE
    </h3>
    <p>{{ getDate(medicalHistory.date) }}</p>
    <div class="text-justify">
      This is your medical history form, to be completed prior to your medical
      consultation. All information will be kept confidential. The forms are
      extensive, but please try to make it as accurate and complete as possible.
      Please take your time and complete it carefully and thoroughly, and then
      review it to be certain you have not left anything out. Your answers will
      help us design a primary care program that meets your individual needs.
    </div>
    <br />
    <div class="text-justify">
      If you have questions or concerns, we will help you with those after this
      form is completed. We realize that some parts of the form will be unclear
      to you. Do your best to complete the form. Your questions will be
      thoroughly addressed afterwards. It might be helpful for you to keep a
      written list of questions or concerns as you complete the medical history
      form.
    </div>

    <PresentMedicalHistory
      :isFemale="isFemale"
      :presentMedicalHistory="medicalHistory.presentMedicalHistory"
      @onPresentMedicalHistory="dataPresentMedicalHistory"
    />

    <PastMedicalHistory
      :pastMedicalHistory="medicalHistory.pastMedicalHistory"
      @onPastMedicalHistory="dataPastMedicalHistory"
    />

    <v-btn
      @click="onSavePresentMedicalHistory"
      class="ma-5"
      rounded
      color="primary"
      dark
    >
      Create New
    </v-btn>
  </v-card>

  <v-card v-else class="pa-5 black--text" color="white" v-model="valid" tile>
    <h3 class="my-4">
      MEDICAL HISTORY QUESTIONNAIRE
    </h3>
    <div class="text-justify">
      This is your medical history form, to be completed prior to your medical
      consultation. All information will be kept confidential. The forms are
      extensive, but please try to make it as accurate and complete as possible.
      Please take your time and complete it carefully and thoroughly, and then
      review it to be certain you have not left anything out. Your answers will
      help us design a primary care program that meets your individual needs.
    </div>
    <br />
    <div class="text-justify">
      If you have questions or concerns, we will help you with those after this
      form is completed. We realize that some parts of the form will be unclear
      to you. Do your best to complete the form. Your questions will be
      thoroughly addressed afterwards. It might be helpful for you to keep a
      written list of questions or concerns as you complete the medical history
      form.
    </div>

    <PresentMedicalHistory
      @onPresentMedicalHistory="dataPresentMedicalHistory"
    />

    <PastMedicalHistory @onPastMedicalHistory="dataPastMedicalHistory" />
    <v-btn
      @click="onSavePresentMedicalHistory"
      class="ma-5"
      rounded
      color="primary"
      dark
    >
      Create New
    </v-btn>
  </v-card>
</template>

<script>
import { addInformation } from "@/store/medical_history";
import { getDayDateTimeFromTimestamp } from "@/common/utilities/date";
import { getFullAddress } from "@/common/utilities/location";
import { loadUserValidationData } from "@/store/user_validation";

import PastMedicalHistory from "@/components/MedicalHistory/PastMedicalHistory.vue";
import PresentMedicalHistory from "@/components/MedicalHistory/PresentMedicalHistory.vue";

export default {
  name: "MedicalHistory",

  components: {
    PastMedicalHistory,
    PresentMedicalHistory
  },

  props: {
    medicalHistory: Object
  },

  data: () => ({
    valid: true,

    isFemale: false,
    generalInformation: [],
    presentMedicalHistory: "",
    pastMedicalHistory: "",

    // functions
    getDate: getDayDateTimeFromTimestamp
  }),

  mounted() {
    this.loadaData();
  },

  methods: {
    onSavePresentMedicalHistory() {
      let obj1 = { pastMedicalHistory: this.pastMedicalHistory };
      let obj2 = { presentMedicalHistory: this.presentMedicalHistory };
      let obj3 = { generalInformation: this.generalInformation };
      let presentMedicalHistory = { ...obj1, ...obj2, ...obj3 };

      addInformation(presentMedicalHistory);
    },

    dataPresentMedicalHistory(val) {
      this.presentMedicalHistory = val;
    },

    dataPastMedicalHistory(val) {
      this.pastMedicalHistory = val;
    },

    async loadaData() {
      const data = await loadUserValidationData(this.target);
      this.loadUserData1(data);
      this.isFemale = data.sex == "Female";
    },

    loadUserData1(data) {
      this.generalInformation = {
        fullname: `${data.firstname} ${data.middlename} ${data.lastname}`,
        maritalstatus: data.maritalStatus,
        address: getFullAddress(data),
        gender: data.sex,
        birthdate: data.birthdate,
        contact: data.contact,
        occupation: data.occupation,
        employer: data.companyname
      };
    }
  }
};
</script>
