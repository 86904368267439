import {
  DOCTYPE_MEMBERINFO,
  getDocumentHeader,
  IndexedDocument
} from "@/utilities/user_collection";

function emptyName() {
  return {
    first: "",
    middle: "",
    last: "",
    extension: ""
  };
}

function getData(state) {
  return {
    name: {
      first: state.name.first,
      middle: state.name.middle,
      last: state.name.last,
      extension: state.name.extension
    },
    birthday: state.birthday,
    sex: state.sex,

    civilStatus: state.civilStatus || "",
    companyName: state.companyName || "",

    medicalService: state.medicalService || ""
  };
}

function getDocumentContent(state) {
  return {
    header: getDocumentHeader(DOCTYPE_MEMBERINFO, state),
    data: getData(state)
  };
}

const memberInfoBase = new IndexedDocument(
  DOCTYPE_MEMBERINFO,
  getDocumentContent
);
export const memberInfo = {
  state: () => ({
    ...memberInfoBase.state,

    name: emptyName(),
    birthday: "",
    sex: "",

    civilStatus: "",
    companyName: "",

    medicalService: ""
  }),

  getters: {
    ...memberInfoBase.getters,

    getBirthday(state) {
      return state.birthday;
    },

    getCivilStatus(state) {
      return state.civilStatus;
    },

    getCompanyName(state) {
      return state.companyName;
    },

    getExtensionName(state) {
      return state.name.extension;
    },

    getFirstName(state) {
      return state.name.first;
    },

    getFullName(state) {
      const names = [state.name.first, state.name.middle, state.name.extension];
      const firstName = names.reduce(
        (prev, curr) => prev + (curr ? ` ${curr}` : ""),
        ""
      );
      const lastName = state.name.last;

      return firstName || lastName ? `${lastName}, ${firstName}` : "";
    },

    getLastName(state) {
      return state.name.last;
    },

    getMedicalService(state) {
      return state.medicalService;
    },

    getMiddleName(state) {
      return state.name.middle;
    },

    getSex(state) {
      return state.sex;
    },

    isComplete(state) {
      return state.name.first &&
        state.name.middle &&
        state.name.last &&
        state.birthday &&
        state.sex &&
        state.civilStatus
        ? true
        : false;
    }
  },

  mutations: {
    ...memberInfoBase.mutations,

    RESET_STATE(state) {
      state.name = emptyName();
      state.birthday = "";
      state.sex = "";

      state.civilStatus = "";
      state.companyName = "";

      state.medicalService = "";
    },

    setBirthday(state, data) {
      state.isSaved = state.isSaved && state.birthday === data;
      state.birthday = data;
    },

    setCivilStatus(state, data) {
      state.isSaved = state.isSaved && state.civilStatus === data;
      state.civilStatus = data;
    },

    setCompanyName(state, data) {
      state.isSaved = state.isSaved && state.companyName === data;
      state.companyName = data;
    },

    setData(state, data) {
      if (data.name) {
        state.name.first = data.name.first || "";
        state.name.middle = data.name.middle || "";
        state.name.last = data.name.last || "";
        state.name.extension = data.name.extension || "";
      }

      state.birthday = data.birthday || "";
      state.sex = data.sex || "";
      state.companyName = data.companyName || "";
      state.civilStatus = data.civilStatus || "";
      state.medicalService = data.medicalService || "";
    },

    setExtensionName(state, data) {
      state.isSaved = state.isSaved && state.name.extension === data;
      state.name.extension = data;
    },

    setFirstName(state, data) {
      state.isSaved = state.isSaved && state.name.first === data;
      state.name.first = data;
    },

    setLastName(state, data) {
      state.isSaved = state.isSaved && state.name.last === data;
      state.name.last = data;
    },

    setMedicalService(state, data) {
      state.isSaved = state.isSaved && state.medicalService === data;
      state.medicalService = data;
    },

    setMiddleName(state, data) {
      state.isSaved = state.isSaved && state.name.middle === data;
      state.name.middle = data;
    },

    setSex(state, data) {
      state.isSaved = state.isSaved && state.sex === data;
      state.sex = data;
    }
  },

  actions: {
    ...memberInfoBase.actions,

    resetContent({ commit, dispatch }) {
      dispatch("resetDocument");
      commit("RESET_STATE");
    }
  }
};
