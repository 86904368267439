<template>
  <div class="pa-1">
    <v-rating
      readonly
      x-small
      v-model="rating"
      background-color="primary lighten-3"
      color="orange"
    />
    <p>{{ comment }}</p>

    <v-row>
      <v-col
        v-for="n in photoUrl"
        :key="n"
        class="d-flex child-flex"
        cols="4"
        sm="2"
        style="min-height: 70px"
      >
        <img v-if="n" :src="n" aspect-ratio="1" alt="Feedback Photo" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "RatingInput",

  props: {
    rating: Number,
    comment: String,
    photoUrl: Array
  }
};
</script>
