<template>
  <v-container>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12 sm6 md6 lg4 v-for="item in services" :key="item.id">
          <v-hover>
            <v-card
              slot-scope="{ hover }"
              :class="`elevation-${hover ? 12 : 2}`"
            >
              <v-card flat aspect-ratio="2.75" height="44" :color="item.color">
                <h3 class="white--text headline mb-0 text-center pa-2">
                  {{ item.title }}
                </h3>
              </v-card>

              <v-card-text>
                <div>
                  <div class="text-justify body-1">
                    <v-icon
                      size="33"
                      class="mr-2 my-2"
                      :color="item.color"
                      style="float: left;"
                    >
                      {{ item.icon }}
                    </v-icon>
                    {{ item.description }}
                  </div>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="learn-more-btn"
                  :to="item.link"
                >
                  Learn more
                </v-btn>

                <v-spacer />

                <v-btn :to="item.directTo" text color="primary">
                  Get started
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
import {
  ROUTE_BHC,
  ROUTE_INSURANCE,
  ROUTE_LANDING,
  ROUTE_REGISTER
} from "@/router";
import { SERVICE_LABORATORY } from "@/common/utilities/services";

export default {
  name: "OurServices",

  data: () => ({
    services: [
      {
        id: "1",
        title: "e-Insurance",
        description:
          "A life insurance for as low as ₱ 365 a year offered by Country Bankers Insurance Group.  No medical certificate required, just fill up and submit the required details online.",
        color: "primary",
        icon: "mdi-hand-heart",
        link: `${ROUTE_LANDING}#insurancesection`,
        linkgetstarted: ROUTE_REGISTER,
        directTo: ROUTE_INSURANCE,
        urls:
          "https://firebasestorage.googleapis.com/v0/b/medisure-275117.appspot.com/o/products%2F68848750-1da4-11eb-86b6-dfb586bc5796.png?alt=media&token=d00229cd-44f1-4e7c-9efb-8b448753df8d"
      },
      {
        id: "2",
        title: SERVICE_LABORATORY,
        description:
          "Low cost with installment payment options.   Getting your annual blood test is an important component of knowing your medical history, and allow you to follow the appropriate lifestyle choice to stay healthy.",
        color: "primary",
        icon: "mdi-microscope",
        link: `${ROUTE_LANDING}#laboratorysection`,
        linkgetstarted: ROUTE_REGISTER,
        directTo: ROUTE_BHC,
        urls:
          "https://firebasestorage.googleapis.com/v0/b/medisure-275117.appspot.com/o/products%2F68848750-1da4-11eb-86b6-dfb586bc5796.png?alt=media&token=d00229cd-44f1-4e7c-9efb-8b448753df8d"
      },
      {
        id: "3",
        title: "e-Konsulta",
        description:
          "Avail of the expertise of Filipino physicians from Luzon, Visayas and Mindanao at the comforts of your home.  You can choose a physician that can speak your dialect. Ranges of consultation rates are published.",
        color: "primary",
        icon: "mdi-stethoscope",
        link: `${ROUTE_LANDING}#consultationsection`,
        linkgetstarted: ROUTE_REGISTER,
        directTo: ROUTE_INSURANCE,
        urls:
          "https://firebasestorage.googleapis.com/v0/b/medisure-275117.appspot.com/o/products%2F68848750-1da4-11eb-86b6-dfb586bc5796.png?alt=media&token=d00229cd-44f1-4e7c-9efb-8b448753df8d"
      }
    ]
  })
};
</script>
