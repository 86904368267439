import { DB, getCurrentUserUid, logUserActivity } from "@/common/store";

import { db } from "@/main";

export function addToIndex(collection, documentType, content, docId) {
  return new Promise((resolve, reject) => {
    db.collection(collection)
      .doc(docId)
      .set(
        {
          [documentType]: {
            ...content.data,
            id: content.id,
            timestamp: content.header.addedOn
          }
        },
        { merge: true }
      )
      .then(() => resolve(content.id))
      .catch(err => reject(err));
  });
}

export function addNewDocToIndex(collection, documentType, content) {
  return new Promise((resolve, reject) => {
    let docRef = db.collection(collection).doc();
    docRef
      .set({
        [documentType]: {
          ...content.data,
          id: content.id,
          timestamp: content.header.addedOn,
          userId: getCurrentUserUid()
        }
      })
      .then(() => resolve(docRef.id))
      .catch(err => reject(err));
  });
}

export function dbSubmitMedicalHistory(timestamp) {
  return new Promise((resolve, reject) => {
    db.collection(DB.MEDICALHISTORY_INDEX)
      .doc(getCurrentUserUid())
      .set({ timestamp: timestamp }, { merge: true })
      .then(() => {
        return logUserActivity("Submit medical history", {
          timestamp: timestamp
        });
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function passThroughContent(documentId, timestamp, content) {
  return new Promise(resolve => {
    content.header.addedOn = timestamp;
    content["id"] = documentId;
    resolve(content);
  });
}
