import { db } from "../main";
import { getLocation } from "../utilities/location";

function titleCase(sentence) {
  if (sentence) {
    return sentence
      .split(" ")
      .reduce((output, item) => {
        const value = item.substr(1).toLowerCase();
        const firstLetter = item.substr(0, 1).toUpperCase();
        return `${output} ${firstLetter}${value}`;
      }, "")
      .trim();
  }

  return "";
}

function normalizeLocationEntry(entry) {
  const customs = {
    "MABINI-J. RIZAL": "Mabini-J. Rizal",
    "TAWI-TAWI": "Tawi-Tawi",
    "WACK-WACK GREENHILLS": "Wack-Wack Greenhills"
  };

  return entry in customs ? customs[entry] : titleCase(entry);
}

function getProvince(label, region, province) {
  return {
    label: label,
    details: getLocation(region, province)
  };
}

const NATIONAL_CAPITAL_REGION = "National Capital Region";
const NCR = "NCR";

function getProvinces(locations) {
  let provinces = [];

  for (let r in locations) {
    for (let p in locations[r]) {
      if (NATIONAL_CAPITAL_REGION !== p) {
        provinces.push(getProvince(p, r, p));
      }
    }
  }

  provinces.sort((a, b) => (a.label > b.label ? 1 : -1));
  provinces.unshift(getProvince(NCR, NCR, NATIONAL_CAPITAL_REGION));
  return provinces;
}

export class Location {
  constructor(dbLocation) {
    this.state = {
      DB_LOCATION: dbLocation,
      isLoaded: false,

      locations: {},
      provinces: [],
      municipalities: [],
      barangays: [],

      selectedProvince: null,
      selectedLocation: getLocation("", "")
    };

    this.getters = {
      getBarangays(state) {
        return state.barangays;
      },

      getLocations(state) {
        return state.locations;
      },

      getMunicipalities(state) {
        return state.municipalities;
      },

      getProvinces(state) {
        return state.provinces;
      }
    };

    this.mutations = {
      addRegion(state, doc) {
        state.locations[doc.id] = {};

        let data = doc.data();

        for (const p in data) {
          const province = normalizeLocationEntry(p);
          state.locations[doc.id][province] = {};

          for (const m in data[p]) {
            const municipality = normalizeLocationEntry(m);
            state.locations[doc.id][province][municipality] = [];

            for (const barangay of data[p][m]) {
              state.locations[doc.id][province][municipality].push(
                normalizeLocationEntry(barangay)
              );
            }
          }
        }
      },

      RESET_STATE(state) {
        state.isLoaded = false;

        state.locations = {};
        state.provinces = [];
        state.municipalities = [];
        state.barangays = [];

        state.selectedProvince = null;
        state.selectedLocation = getLocation("", "");
      },

      setProvinces(state) {
        state.provinces = getProvinces(state.locations);
      },

      setSelectedProvince(state, data) {
        if (data && data.region && data.province) {
          const region = data.region;
          const province = data.province;

          state.municipalities = [];
          state.barangays = [];

          for (const m in state.locations[region][province]) {
            state.municipalities.push(m);
          }
          state.municipalities.sort();

          state.selectedLocation = getLocation(region, province);
          state.selectedProvince = { ...data };
        } else {
          state.barangays = [];
          state.municipalities = [];
        }
      },

      setSelectedMunicipality(state, municipality) {
        const region = state.selectedLocation.region;
        const province = state.selectedLocation.province;

        if (region && province && municipality) {
          state.barangays = state.locations[region][province][municipality];
          state.selectedLocation = getLocation(region, province, municipality);
        } else {
          state.barangays = [];
        }
      }
    };

    this.actions = {
      async load({ commit, state }) {
        if (!state.isLoaded) {
          const snapshot = await db.collection(state.DB_LOCATION).get();
          snapshot.forEach(doc => {
            commit("addRegion", doc);
          });
          commit("setProvinces");
        }
      },

      resetContent({ commit }) {
        commit("RESET_STATE");
      }
    };
  }
}
