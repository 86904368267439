import {
  DOCTYPE_CONTACTTRACING,
  getDocumentHeader,
  IndexedDocument
} from "@/utilities/user_collection";

import { emptyTextText, getTextText } from "@/utilities/fields";
import { getLocation } from "@/utilities/location";

export const HOME_SERVICE = "Home Service";

function getData(state) {
  return {
    temperature: state.temperature,

    travelOutsideMetroManila: { ...state.travelOutsideMetroManila },
    fluLikeSymptoms: { ...state.fluLikeSymptoms },
    lossOfSmell: state.lossOfSmell,
    lastPhysicalExam: { ...state.lastPhysicalExam },

    livesWithSpouse: state.livesWithSpouse,
    livesWithChild: state.livesWithChild,
    livesWithParents: state.livesWithParents,
    livesWithSiblings: state.livesWithSiblings,
    livesWithOthers: { ...state.livesWithOthers },

    medisureInteraction: state.medisureInteraction,
    address:
      HOME_SERVICE === state.medisureInteraction
        ? { ...state.address }
        : getLocation("", "")
  };
}

function getDocumentContent(state) {
  return {
    header: getDocumentHeader(DOCTYPE_CONTACTTRACING, state),
    data: getData(state)
  };
}

var contactTracingBase = new IndexedDocument(
  DOCTYPE_CONTACTTRACING,
  getDocumentContent
);
export const contactTracing = {
  state: () => ({
    ...contactTracingBase.state,

    temperature: "",

    travelOutsideMetroManila: emptyTextText(),
    fluLikeSymptoms: emptyTextText(),
    lossOfSmell: "",
    lastPhysicalExam: emptyTextText(),

    livesWithSpouse: false,
    livesWithChild: false,
    livesWithParents: false,
    livesWithSiblings: false,
    livesWithOthers: emptyTextText(),

    medisureInteraction: "",
    address: getLocation("", "")
  }),

  getters: {
    ...contactTracingBase.getters,

    getAddress(state) {
      return state.address;
    },

    getAddressLine1(state) {
      return state.address.addressLine1;
    },

    getAddressBarangay(state) {
      return state.address.barangay;
    },

    getAddressMunicipality(state) {
      return state.address.municipality;
    },

    getAddressProvince(state) {
      return state.address.province;
    },

    getAddressRegion(state) {
      return state.address.region;
    },

    getFluLikeSymptoms(state) {
      return state.fluLikeSymptoms.value;
    },

    getFluLikeSymptomsComment(state) {
      return state.fluLikeSymptoms.comment;
    },

    getLastPhysicalExam(state) {
      return state.lastPhysicalExam.value;
    },

    getLastPhysicalExamComment(state) {
      return state.lastPhysicalExam.comment;
    },

    getLivesWithChild(state) {
      return state.livesWithChild;
    },

    getLivesWithOthers(state) {
      return state.livesWithOthers.value;
    },

    getLivesWithOthersComment(state) {
      return state.livesWithOthers.comment;
    },

    getLivesWithParents(state) {
      return state.livesWithParents;
    },

    getLivesWithSiblings(state) {
      return state.livesWithSiblings;
    },

    getLivesWithSpouse(state) {
      return state.livesWithSpouse;
    },

    getLossOfSmell(state) {
      return state.lossOfSmell;
    },

    getMedisureInteraction(state) {
      return state.medisureInteraction;
    },

    getTemperature(state) {
      return state.temperature;
    },

    getTravelOutsideMetroManila(state) {
      return state.travelOutsideMetroManila.value;
    },

    getTravelOutsideMetroManilaComment(state) {
      return state.travelOutsideMetroManila.comment;
    },

    isComplete(state) {
      return state.address.barangay &&
        state.address.municipality &&
        state.address.province &&
        state.address.region
        ? true
        : false;
    }
  },

  mutations: {
    ...contactTracingBase.mutations,

    RESET_STATE(state) {
      state.temperature = "";

      state.travelOutsideMetroManila = emptyTextText();
      state.fluLikeSymptoms = emptyTextText();
      state.lossOfSmell = "";
      state.lastPhysicalExam = emptyTextText();

      state.livesWithSpouse = false;
      state.livesWithChild = false;
      state.livesWithParents = false;
      state.livesWithSiblings = false;
      state.livesWithOthers = emptyTextText();

      state.medisureInteraction = "";
      state.address = getLocation("", "");
    },

    setAddressLine1(state, value) {
      state.isSaved = state.isSaved && state.address.addressLine1 === value;
      state.address.addressLine1 = value;
    },

    setAddressBarangay(state, value) {
      state.isSaved = state.isSaved && state.address.barangay === value;
      state.address.barangay = value;
    },

    setAddressMunicipality(state, value) {
      state.isSaved = false;
      state.address.municipality = value;
    },

    setAddressProvince(state, value) {
      state.isSaved = false;
      state.address.province = value;
    },

    setAddressRegion(state, value) {
      state.isSaved = state.isSaved && state.address.region === value;
      state.address.region = value;
    },

    setData(state, data) {
      const ONE_DAY = 24 * 60 * 60 * 1000;
      if (new Date() - state.addedOn < ONE_DAY) {
        state.temperature = data.temperature;

        state.travelOutsideMetroManila = getTextText(
          data.travelOutsideMetroManila
        );
        state.fluLikeSymptoms = getTextText(data.fluLikeSymptoms);
        state.lossOfSmell = data.lossOfSmell;
        state.lastPhysicalExam = getTextText(data.lastPhysicalExam);

        state.livesWithSpouse = data.livesWithSpouse;
        state.livesWithChild = data.livesWithChild;
        state.livesWithParents = data.livesWithParents;
        state.livesWithSiblings = data.livesWithSiblings;
        state.livesWithOthers = getTextText(data.livesWithOthers);

        state.medisureInteraction = data.medisureInteraction;
        state.address = { ...data.address };
      } else {
        state.temperature = "";

        state.travelOutsideMetroManila = emptyTextText();
        state.fluLikeSymptoms = emptyTextText();
        state.lossOfSmell = "";
        state.lastPhysicalExam = emptyTextText();

        state.livesWithSpouse = false;
        state.livesWithChild = false;
        state.livesWithParents = false;
        state.livesWithSiblings = false;
        state.livesWithOthers = emptyTextText();

        state.medisureInteraction = "";
        state.address = { ...data.address };
      }

      state.isSaved = true;
    },

    setFluLikeSymptoms(state, value) {
      state.isSaved = state.isSaved && state.fluLikeSymptoms.value === value;
      state.fluLikeSymptoms.value = value;
    },

    setFluLikeSymptomsComment(state, value) {
      state.isSaved = state.isSaved && state.fluLikeSymptoms.comment === value;
      state.fluLikeSymptoms.comment = value;
    },

    setLastPhysicalExam(state, value) {
      state.isSaved = state.isSaved && state.lastPhysicalExam.value === value;
      state.lastPhysicalExam.value = value;
    },

    setLastPhysicalExamComment(state, value) {
      state.isSaved = state.isSaved && state.lastPhysicalExam.comment === value;
      state.lastPhysicalExam.comment = value;
    },

    setLivesWithChild(state, value) {
      state.isSaved = state.isSaved && state.livesWithChild === value;
      state.livesWithChild = value;
    },

    setLivesWithOthers(state, value) {
      state.isSaved = state.isSaved && state.livesWithOthers.value === value;
      state.livesWithOthers.value = value;
    },

    setLivesWithOthersComment(state, value) {
      state.isSaved = state.isSaved && state.livesWithOthers.comment === value;
      state.livesWithOthers.comment = value;
    },

    setLivesWithParents(state, value) {
      state.isSaved = state.isSaved && state.livesWithParents === value;
      state.livesWithParents = value;
    },

    setLivesWithSiblings(state, value) {
      state.isSaved = state.isSaved && state.livesWithSiblings === value;
      state.livesWithSiblings = value;
    },

    setLivesWithSpouse(state, value) {
      state.isSaved = state.isSaved && state.livesWithSpouse === value;
      state.livesWithSpouse = value;
    },

    setLossOfSmell(state, value) {
      state.isSaved = state.isSaved && state.lossOfSmell === value;
      state.lossOfSmell = value;
    },

    setMedisureInteraction(state, value) {
      state.isSaved = state.isSaved && state.medisureInteraction === value;
      state.medisureInteraction = value;
    },

    setTemperature(state, value) {
      state.isSaved = state.isSaved && state.temperature === value;
      state.temperature = value;
    },

    setTravelOutsideMetroManila(state, value) {
      state.isSaved =
        state.isSaved && state.travelOutsideMetroManila.value === value;
      state.travelOutsideMetroManila.value = value;
    },

    setTravelOutsideMetroManilaComment(state, value) {
      state.isSaved =
        state.isSaved && state.travelOutsideMetroManila.comment === value;
      state.travelOutsideMetroManila.comment = value;
    }
  },

  actions: {
    ...contactTracingBase.actions,

    resetContent({ commit, dispatch }) {
      dispatch("resetDocument");
      commit("RESET_STATE");
    }
  }
};
