<template>
  <div class="pa-0">
    <v-row justify="center">
      <h1 class="evenir colored">SPONSOR NOW!!!</h1>
    </v-row>
    <v-row class="wrap my-4" align="center" justify="center">
      <v-btn
        height="32"
        dark
        color="#7f7f7f"
        width="300"
        @click="setDenom(denomination)"
        large
        class="mx-auto denomFont evenir my-1"
        :class="{ primary: denomination == amount && !isOthers }"
        v-for="(denomination, index) in denominations"
        :key="index"
      >
        <strong> <PesoAmount :amount="Number(denomination)"/></strong>
      </v-btn>
      <v-btn
        height="32"
        dark
        color="#7f7f7f"
        width="300"
        @click="isOthers = true"
        large
        class="mx-auto denomFont evenir my-1"
        :class="{ primary: isOthers }"
      >
        <strong> OTHERS </strong>
      </v-btn>
    </v-row>
    <v-row v-show="isOthers" align="center" justify="center">
      <vuetify-money
        label="Amount"
        class="select-container pa-0"
        v-model="amount"
        placeholder="Amount"
        v-bind:options="options"
      />
    </v-row>
    <v-card class="px-4 pb-6" align="center">
      <h4 class="text-justify py-4 avenirs">
        Sponsor Information
      </h4>
      <v-form v-model="validDonor">
        <v-row>
          <v-col class="py-0" cols="12">
            <v-text-field
              class="py-0"
              v-model="donorFirstname"
              :rules="nameRules"
              label="First Name"
              required
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-text-field
              class="py-0"
              v-model="donorLastname"
              :rules="nameRules"
              label="Last Name"
              required
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-text-field
              class="py-0"
              v-model="donorEmail"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-text-field
              class="py-0"
              v-model="contact"
              label="Contact Number (Optional)"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-text-field
              class="py-0"
              v-model="city"
              label="City (Optional)"
            ></v-text-field>
          </v-col>

          <v-col class="py-0" cols="12">
            <v-text-field
              class="py-0"
              v-model="country"
              label="Country (Optional)"
            ></v-text-field>
          </v-col>

          <!-- 
          <v-col class="py-0" cols="12">
            <SimpleDatePicker text="Birthdate" v-model="birthDate" />
          </v-col>
          <v-col class="py-0" cols="12">
            <v-textarea
              class="py-0"
              v-model="comment"
              label="Comment(Optional)"
            ></v-textarea>
          </v-col>
          -->

          <v-col class="py-0" cols="12">
            <v-checkbox
              class="py-0"
              v-model="isAnonymous"
              label="Make this an anonymous donation."
            ></v-checkbox>
          </v-col>

          <v-col class="py-0" cols="12">
            <v-btn
              :disabled="!valid"
              color="primary"
              class="mr-4"
              @click="proceedPayment()"
            >
              Proceed to Payment
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-form
        ref="paymentForm"
        name="paymentForm"
        method="post"
        :action="formAction"
      >
        <input type="hidden" name="merchantId" :value="merchantId" />
        <input type="hidden" name="amount" :value="amount" />
        <input type="hidden" name="orderRef" :value="orderRef" />
        <input type="hidden" name="currCode" :value="currencyCode" />
        <input type="hidden" name="mpsMode" :value="mpsMode" />
        <input type="hidden" name="successUrl" :value="successUrl" />
        <input type="hidden" name="failUrl" :value="failUrl" />
        <input type="hidden" name="cancelUrl" :value="cancelUrl" />
        <input type="hidden" name="payType" :value="payType" />
        <input type="hidden" name="lang" :value="lang" />
        <input type="hidden" name="payMethod" :value="payMethod" />
        <input type="hidden" name="secureHash" :value="secureHash" />
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { db } from "@/main";
import sha1 from "crypto-js/sha1";
import firebase from "firebase/app";
import PesoAmount from "@/common/components/PesoAmount.vue";
import SimpleDatePicker from "@/common/components/controls/SimpleDatePicker.vue";

export default {
  name: "DonorForm",
  components: {
    PesoAmount,
    SimpleDatePicker
  },
  props: {
    project: Object
  },
  data: () => ({
    denominations: [5000, 10000, 25000, 50000],
    amount: 0,
    options: {
      locale: "en-US",
      prefix: "₱",
      suffix: "",
      length: 10,
      precision: 2
    },
    isOthers: false,
    isAnonymous: false,
    validDonor: false,
    donorFirstname: "",
    donorLastname: "",
    nameRules: [v => !!v || "Name is required"],
    donorEmail: "",
    city: "",
    country: "",
    // birthDate: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    formAction: "https://www.pesopay.com/b2c2/eng/payment/payForm.jsp",
    merchantId: "18160186",
    orderRef: "",
    contact: "",
    comment: "",
    currencyCode: "608",
    mpsMode: "NIL",
    payType: "N",
    lang: "E",
    payMethod: "ALL",
    secureHashSecret: "vBbyJrS0fPtNDoxmYiW16Pv5T1rMxtBM",
    secureHash: "",
    successUrl: "https://staging.medisureonline.com/isda/payment/success",
    failUrl: "https://staging.medisureonline.com/isda/payment/fail",
    cancelUrl: "https://staging.medisureonline.com/isda/payment/cancel"
  }),
  watch: {
    amount(val) {
      const secHashInput =
        this.merchantId +
        "|" +
        this.orderRef +
        "|" +
        this.currencyCode +
        "|" +
        val +
        "|" +
        this.payType +
        "|" +
        this.secureHashSecret;
      const hashDigest = sha1(secHashInput);
      this.secureHash = hashDigest.toString();
    }
  },
  computed: {
    valid() {
      return (
        this.donorFirstname != "" &&
        this.donorLastname != "" &&
        this.donorEmail != "" &&
        this.amount > 0
      );
    }
  },
  methods: {
    setDenom(denomination) {
      this.isOthers = false;
      this.amount = denomination;
    },
    proceedPayment() {
      if (this.validDonor) {
        db.collection("isdaProjects")
          .doc(this.project.id)
          .collection("isdaDonations")
          .doc(this.orderRef)
          .set({
            orderRef: this.orderRef,
            projectId: this.project.id,
            amount: Number(this.amount),
            donor: {
              firstName: this.donorFirstname,
              lastName: this.donorLastname,
              email: this.donorEmail,
              city: this.city,
              country: this.country,
              // birthDate: new Date(this.birthDate),
              isAnonymous: this.isAnonymous
            },
            status: "pending",
            submitTimestamp: firebase.firestore.Timestamp.fromDate(new Date())
          })
          .then(() => {
            this.$refs.paymentForm.$el.submit();
          })
          .catch(error => {
            // TODO: display error to page
            console.error("Error writing document: ", error);
          });
      }
    }
  },
  mounted() {
    const now = new Date();
    const dtPart = now
      .toISOString()
      .replaceAll("-", "")
      .replaceAll("T", "")
      .replaceAll(":", "");
    const randNum = Math.floor(Math.random() * 1000);
    const endPart = randNum.toString().padStart(3, "0");
    this.orderRef = "MEDISDA" + dtPart + endPart;
  }
};
</script>
<style scoped>
.denomFont {
  font-size: 20px;
}
.evenir {
  font-family: "Avenir", Helvetica, sans-serif;
}
.colored {
  color: #283d19;
}
</style>
