<template>
  <div min-width="250">
    <v-row v-for="(test, i) in selectedTests" :key="i">
      <v-col md="9" sm="6" class="pt-0 text-left">
        <span class="font-weight-bold">{{ test.name }}</span>
        <br v-if="test.tests" />
        <div class="ml-5 mr-2 text-caption text-justify" v-if="test.tests">
          {{ test.tests.join(", ") }}
        </div>
      </v-col>

      <v-col md="3" sm="6" class="pt-0 text-right">
        <PesoAmount :amount="test.price" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PesoAmount from "../PesoAmount.vue";

export default {
  name: "SelectedTestsDisplay",

  components: {
    PesoAmount
  },

  props: {
    selectedTests: Array
  }
};
</script>
