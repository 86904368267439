<template>
  <div>
    <v-stepper v-model="step" flat>
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          {{ stepHeader1 }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2">
          {{ stepHeader2 }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 3" step="3">
          {{ stepHeader3 }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 4" step="4">
          {{ stepHeader4 }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="5"> {{ stepHeader5 }} </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" class="pt-0">
          <SelectService @clinicType="setClinicType" />
        </v-stepper-content>

        <v-stepper-content step="2" class="pt-0">
          <MainOffice
            v-if="isStationaryClinic"
            @back="step = 1"
            @next="step = 3"
          />

          <RemoteServices
            v-if="isHomeServiceClinic || isMobileClinic"
            :dbLocations="
              isHomeServiceClinic
                ? 'homeServiceLocations'
                : 'mobileServiceLocations'
            "
            :isHomeServiceClinic="isHomeServiceClinic"
            @back="step = 1"
            @next="remoteServicesNext"
          />
        </v-stepper-content>

        <v-stepper-content step="3" class="pt-0">
          <BackButton @click="step = 2" class="mt-3" />

          <p class="text-center pt-3">
            Please select the tests you wish to avail. Click NEXT to continue.
          </p>

          <v-expansion-panels v-model="panel" multiple>
            <v-form
              ref="formDetails"
              v-model="validDetails"
              :lazy-validation="false"
              @submit.prevent="incrementRegistrant"
            >
              <v-expansion-panel
                class="px-0"
                v-for="(registrant, index) in registrants"
                :key="index"
              >
                <v-expansion-panel-header>
                  <div class="text-center ">
                    <strong>Pasyente {{ index + 1 }}</strong>
                    <v-btn
                      fab
                      text
                      x-small
                      v-if="index >= 1"
                      @click="removeRegistrant(index)"
                    >
                      <v-icon class=" floaterr" color="red">
                        mdi mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-row class="mx-2">
                    <v-text-field
                      class="px-1"
                      label="First Name"
                      v-model="registrant.firstName"
                      :rules="fieldRules"
                    />

                    <v-text-field
                      class="px-1"
                      label="Middle Name"
                      v-model="registrant.middleName"
                      :rules="fieldRules"
                    />

                    <v-text-field
                      class="px-1"
                      label="Last Name"
                      v-model="registrant.lastName"
                      :rules="fieldRules"
                    />

                    <v-autocomplete
                      v-model="registrant.gender"
                      name="gender"
                      :items="['Male', 'Female']"
                      label="Gender"
                      prepend-inner-icon="mdi-gender-male-female"
                      :rules="fieldRules"
                    />
                  </v-row>

                  <v-row class="ma-2">
                    <v-text-field
                      class="px-1"
                      v-model="registrant.housenumber"
                      label="Address"
                      :rules="fieldRules"
                    />

                    <v-text-field
                      class="px-1"
                      v-model="registrant.contact"
                      label="Contact"
                      :rules="fieldRules"
                    />

                    <v-menu
                      ref="menu"
                      v-model="registrant.menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="registrant.birthDate"
                          label="Birthday date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        ref="picker"
                        v-model="registrant.birthDate"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save(index)"
                      />
                    </v-menu>

                    <v-text-field
                      class="px-1"
                      label="Attending Physician(optional)"
                      v-model="registrant.attendingPhysician"
                    />
                  </v-row>

                  <v-checkbox
                    class="px-1"
                    v-model="registrant.isPWD"
                    :value="true"
                    label="PWD?"
                  />

                  <v-text-field
                    v-if="registrant.isSenior"
                    v-model="registrant.seniorID"
                    label="Senior Citizen ID no."
                  />
                  <v-dialog v-model="dialogPackage" width="800">
                    <Packages
                      :indexed="index"
                      :clinicType="clinicType"
                      :packageSelected="registrants[index].packageSelected"
                      @selected-package="
                        registrants[index].packageSelected = $event
                      "
                      @confirmPackage="dialogPackage = $event"
                    />
                  </v-dialog>

                  <h4 class="primary--text">{{ "Packages: " }}</h4>

                  <PackageSummary
                    class="py-2"
                    :details="registrants"
                    :clinicType="clinicType"
                    :indexed="index"
                    @removePackage="
                      (registrants[index].packagesubtotal = 0),
                        (registrants[index].discountedTotal = 0),
                        (registrants[index].subtotal = 0)
                    "
                  />

                  <v-dialog v-model="dialogIndividual" width="990">
                    <IndividualTest
                      class="mt-3"
                      :clinicType="clinicType"
                      :indexed="index"
                      :individualTest="registrants[index].individualTest"
                      @selected-individual="
                        registrants[index].individualTest = $event
                      "
                      @confirmIndividual="dialogIndividual = $event"
                    />
                  </v-dialog>

                  <h4 class="primary--text">{{ "Individual Tests: " }}</h4>

                  <IndividualSummary
                    class="py-2"
                    :details="registrants"
                    :clinicType="clinicType"
                    :indexed="index"
                    @removeIndividual="
                      (registrants[index].individualsubtotal = 0),
                        (registrants[index].discountedTotal = 0),
                        (registrants[index].subtotal = 0)
                    "
                  />

                  <SubtotalPerPatient
                    v-show="registrants[index].subtotal > 0"
                    :registrant="registrants[index]"
                    :clinicType="clinicType"
                    :indexed="index"
                    @packagesubtotal="registrant.packagesubtotal = $event"
                    @individualsubtotal="registrant.individualsubtotal = $event"
                    @subtotal="registrant.subtotal = $event"
                  />

                  <v-row class="py-6 px-2" justify="center">
                    <v-btn
                      class="mx-2"
                      small
                      rounded
                      @click="openDialog2()"
                      tile
                      color="success"
                    >
                      <v-icon left> mdi-plus </v-icon> Add Package
                    </v-btn>

                    <v-btn
                      class="mx-2"
                      small
                      rounded
                      @click="openDialog()"
                      tile
                      color="success"
                    >
                      <v-icon left> mdi-plus </v-icon> Add Individual Test
                    </v-btn>
                  </v-row>
                  <DiscountOptions
                    :registrant="registrants[index]"
                    :key="registrant.subtotal"
                    :total="registrant.subtotal"
                    :indexed="index"
                    @discountedTotal="
                      registrants[index].discountedTotal = $event
                    "
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <div class="text-center pt-4">
                <v-btn
                  :disabled="!validDetails"
                  type="sumbit"
                  class="success"
                  @click="validate()"
                >
                  Magdagdag ng Pasyente
                </v-btn>
              </div>
            </v-form>
          </v-expansion-panels>

          <v-divider class="mt-10"></v-divider>

          <Prices2
            :registrants="registrants"
            @onIndividualPrice="packagePrice = $event"
            @onPackagePrice="individualPrice = $event"
            @onPrices="total = $event"
          />

          <NextButton :disabled="!validDetails" @click="step4" />
        </v-stepper-content>

        <v-stepper-content step="4" class="pt-0">
          <BackButton @click="step = 3" class="mt-3" />

          <p v-if="!isHomeServiceClinic" class="text-center pt-3">
            Please select a schedule we prepared for you. Click NEXT to
            continue.
          </p>

          <SelectPreferredDateTime
            v-if="isHomeServiceClinic"
            class="mt-7"
            :submits="submits"
            @prefferedDateTime="preferredDateTimeSelected"
            @onHasDate="hasDateSelected"
            @onIsSpecific="hasIsSpecific"
          />

          <SelectSchedule
            v-else
            ref="selectSchedule"
            :clinicType="clinicType"
            :location="location"
            @timeslot-selected="timeslotSelected"
          />

          <NextButton
            @click="submits = true"
            :disabled="preferrence === 'Specific Time' && !hasDate"
          />
        </v-stepper-content>

        <v-stepper-content step="5" class="pt-0">
          <h3
            v-show="$vuetify.breakpoint.mobile"
            class="text-center primary--text pt-2 mt-3"
          >
            {{ stepHeader5 }}
          </h3>
          <ConfirmSchedule
            :registrants="registrants"
            :total="total"
            :individualPrice="individualPrice"
            :packagePrice="packagePrice"
            :serviceid="serviceid"
            :msgid="msgid"
            :clinicType="clinicType"
            :preferrence="preferrence"
            :selectedPrefferedDateTime="selectedPrefferedDateTime"
            :homeServiceLocation="homeServiceLocation"
            :contact="contact"
            :details="selectedTimeSlot"
            :nowHistory="nowHistory"
            @on-back="onBack"
            @on-confirm="scheduleConfirmed"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="dialog" width="990" persistent>
      {{ payref }}
      <Checkout
        :payref="payref"
        :bhcdetails="bhcdetails"
        :sum="Number(total)"
        :total="Number(total)"
        :shippingfee="shippingfee"
        :unfilteredPurchase="unfilteredPurchase"
        @check-out="checkOutStationaryMobile"
        @on-confirm="scheduleConfirmed"
        @dialog-close="dialogClose"
      />
    </v-dialog>

    <ConfirmDialog ref="confirm" @on-close="onConfirmationClose" />
  </div>
</template>

<script>
import { loadUserValidationData } from "@/store/user_validation";
import {
  loadDiagnosticAttendeeUnpaid,
  setAppointment
} from "@/store/diagnostic_attendee";
import { updateCardBalance } from "@/store";

import BackButton from "@/components/Controls/BackButton.vue";
import Checkout from "@/components/eTindahan/Checkout.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import ConfirmSchedule from "@/components/Laboratory/ConfirmSchedule.vue";
import IndividualTest from "@/components/IndividualTest.vue";
import MainOffice from "@/components/Laboratory/Location/MainOffice.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import Packages from "@/components/Packages.vue";
import Prices2 from "@/components/Laboratory/Prices2.vue";
import RemoteServices from "@/components/Laboratory/Location/RemoteServices.vue";
import SelectSchedule from "@/components/Laboratory/SelectSchedule.vue";
import SelectService from "@/components/Laboratory/BhcAppointment/SelectService.vue";

export default {
  name: "BhcAppointment",

  components: {
    BackButton,
    Checkout,
    ConfirmDialog,
    ConfirmSchedule,
    IndividualTest,
    MainOffice,
    NextButton,
    Packages,
    Prices2,
    RemoteServices,
    SelectSchedule,
    SelectService
  },

  props: {
    selectedTab: Number,
    messageIndex: Number
  },

  data: () => ({
    validDetails: false,
    registrants: [
      {
        menu: false,
        subtotal: 0,
        packagesubtotal: 0,
        individualsubtotal: 0,
        discountedTotal: 0,
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        isPWD: false,
        isSenior: false,
        seniorID: "",
        housenumber: "",
        contact: "",
        attendingPhysician: "",
        birthDate: null,
        packageSelected: [],
        individualTest: []
      }
    ],
    panel: [0],
    fieldRules: [v => !!v || "field required"],
    payref: "Laboratory",
    nowHistory: {},
    hasDate: false,
    submits: false,
    stepHeader1: "Pumili ng Serbisyo",
    stepHeader2: "Pumili ng Lugar",
    stepHeader3: "Pumili ng Test",
    stepHeader4: "Pumili ng Schedule",
    stepHeader5: "Kumpirmasyon",
    clinicType: "",
    prefference: "",
    dialog: false,
    selectedTimeSlot: null,

    step: 1,

    province: "",
    city: "",
    barangay: "",
    housenumber: "",
    location: "",
    contact: "",
    selectedLocation: null,

    selectedPrefferedDateTime: [],
    diagnosticAttendees: [],
    bhcdetails: [],

    shippingfee: 0,
    unfilteredPurchase: 0,
    serviceid: "",
    msgid: "",

    individualPrice: 0,
    packagePrice: 0,
    total: 0,
    dialogPackage: false,
    dialogIndividual: false
  }),

  watch: {
    registrants() {
      this.$store.dispatch(
        "saveProfileLastSelectedLaboratory",
        this.registrants
      );
    },

    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },

    selectedTab() {
      this.step = this.selectedTab;
      this.nowHistory = this.$store.state.messagebox[
        this.messageIndex
      ].homeService;
      let data = this.$store.state.messagebox[this.messageIndex].homeService;
      let qrid = this.$store.state.messagebox[this.messageIndex].recieveqr;
      let parts = qrid.split("-");

      this.clinicType = data.service.clinicType;
      this.registrants = [];
      this.registrants = data.registrants;
      this.serviceid = parts[0];

      let mLength = this.$store.state.homeservice.length;
      this.msgid = this.$store.state.homeservice[mLength - 1].msgID;

      //  location
      let str = data.service.homeServiceLocation;
      var pieces = str.split(", ");
      this.location = `${pieces[pieces.length - 2]}, ${
        pieces[pieces.length - 1]
      }`;
    }
  },

  mounted() {
    this.loadUserData();
    loadDiagnosticAttendeeUnpaid().then(data => {
      this.diagnosticAttendees = data;
      if (this.diagnosticAttendees.length > 0) {
        this.dialog = true;
        let bhcdetails = this.diagnosticAttendees[0].data;
        let id = this.diagnosticAttendees[0].id;
        this.bhcdetails = {
          id: id,

          // schedule
          scheduleId: bhcdetails.appointment.id,
          scheduleStart: bhcdetails.appointment.start,
          scheduleEnd: bhcdetails.appointment.end,

          // slot schedule
          start: bhcdetails.schedule.start,
          end: bhcdetails.schedule.end,
          clinicType: bhcdetails.clinicType,
          service: {
            // service details
            serviceId: bhcdetails.service.id,
            serviceLocation: bhcdetails.service.name,
            serviceName: bhcdetails.service.location
          },

          packageSelected: bhcdetails.package,
          individualTest: bhcdetails.individualTest,
          isPaid: bhcdetails.isPaid
        };

        this.packageSelected = bhcdetails.package;
        this.individualTest = bhcdetails.individualTest;
      }
    });
  },

  computed: {
    homeServiceLocation() {
      return `${this.wordSpace(this.housenumber)} ${this.location}`;
    },

    isHomeServiceClinic() {
      return "Home Service" === this.clinicType;
    },

    isMobileClinic() {
      return "Mobile" === this.clinicType;
    },

    isStationaryClinic() {
      return "Stationary" === this.clinicType;
    }
  },

  methods: {
    thePrices(val) {
      this.total = val;
    },

    theIndividualPrice(val) {
      this.individualPrice = val;
    },

    thePackagePrice(val) {
      this.packagePrice = val;
    },

    hasDateSelected(val) {
      this.hasDate = val;
    },

    hasIsSpecific(val) {
      this.prefference = val;
    },

    checkOutStationaryMobile(val) {
      setAppointment({
        id: this.bhcdetails.id,

        // schedule
        scheduleId: this.bhcdetails.scheduleId,
        scheduleStart: this.bhcdetails.scheduleStart,
        scheduleEnd: this.bhcdetails.scheduleEnd,

        // slot schedule
        start: this.bhcdetails.start,
        end: this.bhcdetails.end,

        // service details
        serviceId: this.bhcdetails.service.serviceId,
        serviceLocation: this.bhcdetails.service.serviceLocation,
        serviceName: this.bhcdetails.service.serviceName,
        registrants: this.bhcdetails.registrants,

        total: Math.round(val.total),
        paidThru: val.paidThru,
        name: val.name,
        address: val.address,
        contact: val.contact,

        referenceNo: val.referenceNo,

        remainingbalance: val.remainingbalance,
        isBantayKardPayment: val.isBantayKardPayment
      }).then(() => {
        this.showConfirmationMessage();
      });

      if (val.isBantayKardPayment) {
        updateCardBalance(val.remainingbalance);
      }
      this.dialog = false;
    },

    dialogClose(val) {
      this.dialog = val;
    },

    incrementRegistrant() {
      this.registrants.push({
        menu: false,
        subtotal: 0,
        packagesubtotal: 0,
        individualsubtotal: 0,
        discountedTotal: 0,
        firstName: "",
        middleName: "",
        lastName: "",
        housenumber: "",
        contact: "",
        birthDate: null,
        packageSelected: [],
        individualTest: [],
        isPWD: false,
        isSenior: false,
        seniorID: ""
      });

      this.panel = [this.registrants.length - 1];
    },

    loadUserData() {
      if (this.$store.state.profile.lastSelectedLaboratory != null) {
        this.registrants = this.$store.state.profile.lastSelectedLaboratory;
      } else {
        loadUserValidationData(this.target).then(data => {
          if (this.registrants.length < 2) {
            this.loadUserData1(data);
          }
        });
      }
    },

    loadUserData1(data) {
      this.registrants = [];
      this.registrants.push({
        menu: false,
        subtotal: 0,
        packagesubtotal: 0,
        individualsubtotal: 0,
        discountedTotal: 0,
        firstName: data.firstname,
        middleName: data.middlename,
        lastName: data.lastname,
        housenumber: data.housenumber,
        gender: data.sex,
        contact: data.contact,
        birthDate: data.birthdate,
        isPWD: data.isPWD,
        isSenior: data.isSenior ? data.isSenior : false,
        seniorID: data.seniorID ? data.seniorID : "",
        packageSelected: [],
        individualTest: []
      });
    },

    onBack() {
      this.step = 4;
      this.submits = false;
    },

    onConfirmationClose() {
      this.$emit("on-confirm");
      this.$emit("dialog-close", false);
    },

    scheduleConfirmed() {
      this.step = 1;
    },

    setClinicType(type) {
      this.clinicType = type;
      this.step = 2;
    },

    remoteServicesNext(details) {
      this.contact = details.contact;
      this.housenumber = details.housenumber;
      this.barangay = details.selectedLocation.barangay;
      this.city = details.selectedLocation.municipality;
      this.province = details.selectedLocation.province;
      this.location = `${this.city}, ${this.barangay}`;
      this.selectedLocation = details.selectedLocation;
      this.step = 3;
    },

    removeRegistrant(index) {
      if (index > -1) {
        this.registrants.splice(index, 1);
      }
    },

    reTitle(type) {
      switch (type) {
        case "Stationary":
          return "Main Office";

        case "Home Service":
          return "Home Service";
      }

      return "Mobile";
    },

    save(index) {
      this.registrants[index].menu = false;
      var today = new Date();
      var birthDate = new Date(this.registrants[index].birthDate);
      let age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age.age--;
      }
      this.registrants[index].isSenior = age >= 60;
    },

    selectedTypeBtn(type) {
      this.clinicType = type;
      this.step = 2;
    },

    showConfirmationMessage() {
      this.$refs.confirm.open(
        "Kumpirmasyon",
        `Naitala na po ang inyong appointment. Mangyaring dumating ng 10 minutong mas maaga sa inyong appointment. Maraming salamat po!`,
        { noconfirm: true }
      );
    },

    step4() {
      this.$store.dispatch("resetSecondsIdle").then(() => {
        this.step = 4;
      });
    },

    timeslotSelected(timeslot) {
      this.selectedTimeSlot = timeslot;
      this.step = 5;
    },

    prefferedDateTimeSelected(dateTimeSelected, selectedItem) {
      this.selectedPrefferedDateTime = dateTimeSelected;
      this.prefference = selectedItem;
      this.step = 5;
    },

    wordSpace(word) {
      return word ? `${word} ` : "";
    }
  }
};
</script>

<style scoped>
.v-expansion-panel-content__wrap {
  padding: 0 4px 4px !important;
}

.v-expansion-panels {
  display: block !important;
}
</style>
