<template>
  <v-form ref="form" :lazy-validation="false" class="pa-6">
    <v-radio-group v-model="radioGroup">
      <v-row>
        <v-col cols="9" class="px-2 py-0">
          <v-radio class="floaterl pt-5" value="promoCode" />
          <v-text-field
            class="py-0"
            label="Type promo code here"
            v-model="promoCode"
          />
          <p
            class="text-center "
            v-if="promoCode.length === 0"
            style="color: red; font-size: smaller;"
          >
            {{ promoCode }}
          </p>
          <p
            class="text-center "
            v-else-if="!validCode"
            style="color: red; font-size: smaller;"
          >
            {{ promoCode }} is not a valid promo code.
          </p>
          <p
            v-if="!validCount && validCode"
            class="text-center"
            style="color: red; font-size: smaller;"
          >
            No Promo Code left
          </p>
          <p
            v-if="!promoStarted && validCode"
            class="text-center"
            style="color: red; font-size: smaller;"
          >
            Not Yet Started
          </p>
          <p
            v-if="promoEnded && validCode"
            class="text-center"
            style="color: red; font-size: smaller;"
          >
            Expired Coupon
          </p>
        </v-col>
        <v-col class="text-right px-2" cols="3">
          <PesoAmount :amount="discountValue" />
        </v-col>
      </v-row>
    </v-radio-group>
  </v-form>
</template>

<script>
import { loadDiscount } from "@/store";
import { timeStampToDate } from "@/common/utilities/date";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "DiscountOptions",

  components: {
    PesoAmount
  },

  props: {
    total: Number
  },

  data: () => ({
    radioGroup: "",

    finTotal: 0,
    promoCode: "",
    discountValue: 0,
    validCount: true,
    validCode: false,

    promoStarted: false,
    promoEnded: false
  }),

  mounted() {
    if (this.$store.getters["doctor/appointment/getTransactionStatus"]) {
      this.radioGroup = this.$store.getters[
        "doctor/appointment/getSelectedDiscount"
      ];
    }
  },

  watch: {
    discountValue(value) {
      this.$emit("discountValue", value);
    },

    finTotal(value) {
      this.$emit("discountedTotal", value);
    },

    promoCode(value) {
      this.$emit("promoCode", value);
      if (value.length > 4) {
        this.getDiscountValue();
      } else {
        this.discountValue = 0;
        this.finTotal = this.total;
      }
    },

    radioGroup(value) {
      this.$emit("promoType", value);
      this.finTotal = 0;
      this.getDiscountValue();

      this.$store.commit("doctor/appointment/SET_SELECTED_DISCOUNT", value);
    },

    total(value) {
      if (this.radioGroup === "") {
        this.finTotal = value;
      }
    }
  },

  methods: {
    getDiscountValue() {
      this.discountValue = 0;
      loadDiscount({ promoCode: this.promoCode }).then(docs => {
        if (!docs.length) {
          this.validCode = false;
          this.retainTotal();
          return;
        }

        this.validCode = true;

        for (let key of docs) {
          if (!key.data.count) {
            this.validCount = false;
            this.retainTotal();
            return;
          }

          this.validCount = true;

          let startDate = timeStampToDate(key.data.effectiveDate.from);
          let endDate = timeStampToDate(key.data.effectiveDate.to);
          let today = new Date();

          this.promoStarted = startDate <= today;
          this.promoEnded = endDate <= today;
          if (!this.promoStarted || this.promoEnded) {
            this.retainTotal();
            return;
          }

          this.discountValue =
            "percentage" === key.data.discountType
              ? this.total * (key.data.value / 100)
              : Number(key.data.value);

          this.finTotal = this.total - this.discountValue;
        }
      });
    },

    retainTotal() {
      this.finTotal = Number(this.total);
    }
  }
};
</script>

<style scoped>
.floaterl {
  float: left;
}

.v-text-field {
  width: 180px;
}
</style>
