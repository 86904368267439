<template>
  <v-container fluid>
    <h3 class="my-4">Present Medical History</h3>
    <div>
      Check those questions to which you answer yes (leave the others blank).
    </div>

    <v-container fluid>
      <v-checkbox
        v-model="selectedPresent"
        label="Has a doctor ever said your blood pressure was too high?"
        value="Has a doctor ever said your blood pressure was too high?"
      />

      <v-checkbox
        v-model="selectedPresent"
        label="Do you ever have pain in your chest or heart?"
        value="Do you ever have pain in your chest or heart?"
      />

      <v-checkbox
        v-model="selectedPresent"
        label="Are you often bothered by a thumping of the heart?"
        value="Are you often bothered by a thumping of the heart?"
      />

      <v-checkbox
        v-model="selectedPresent"
        label="Has a doctor ever said that you have or have had heart trouble, an abnormal electrocardiogram (ECG or EKG), heart attack or coronary?"
        value="Has a doctor ever said that you have or have had heart trouble, an abnormal electrocardiogram (ECG or EKG), heart attack or coronary?"
      />

      <v-checkbox
        v-model="selectedPresent"
        label="Do you often have difficulty breathing?"
        value="Do you often have difficulty breathing?"
      />

      <v-checkbox
        v-model="selectedPresent"
        label="Do you get out of breath long before anyone else?"
        value="Do you get out of breath long before anyone else?"
      />

      <v-checkbox
        v-model="selectedPresent"
        label="Do you sometimes get out of breath when sitting still or sleeping?"
        value="Do you sometimes get out of breath when sitting still or sleeping?"
      />

      <v-checkbox
        v-model="selectedPresent"
        label="Has a doctor ever told you your cholesterol level was high?"
        value="Has a doctor ever told you your cholesterol level was high?"
      />

      <v-checkbox
        v-model="selectedPresent"
        label="Has a doctor ever told you that you have an abdominal aortic aneurysm?"
        value="Has a doctor ever told you that you have an abdominal aortic aneurysm?"
      />

      <v-checkbox
        v-model="selectedPresent"
        label="Has a doctor ever told you that you have critical aortic stenosis?"
        value="Has a doctor ever told you that you have critical aortic stenosis?"
      />
    </v-container>

    <div>Do you now have or have you recently experienced?</div>
    <v-container fluid>
      <v-checkbox
        v-model="selectedPresentExp"
        label="Chronic, recurrent or morning coughs?"
        value="Chronic, recurrent or morning coughs?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Episode of coughing up blood?"
        value="Episode of coughing up blood?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Increased anxiety or depression"
        value="Increased anxiety or depression"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Problems with recurrent fatigue, trouble sleeping or increased irritability?"
        value="Problems with recurrent fatigue, trouble sleeping or increased irritability?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Migraine or recurrent headaches?"
        value="Migraine or recurrent headaches?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Stomach or intestinal problems, such as recurrent heartburn, ulcers, constipation or diarrhea?"
        value="Stomach or intestinal problems, such as recurrent heartburn, ulcers, constipation or diarrhea?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Significant vision or hearing problems?"
        value="Significant vision or hearing problems"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Glaucoma or increased pressure in the eyes?"
        value="Glaucoma or increased pressure in the eyes?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="An infection such as pneumonia accompanied by a fever?"
        value="An infection such as pneumonia accompanied by a fever?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Significant unexplained weight loss?"
        value="Significant unexplained weight loss?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="A fever, which can cause dehydration and rapid heartbeat?"
        value="A fever, which can cause dehydration and rapid heartbeat?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="A deep vein thrombosis (blood clot)?"
        value="A deep vein thrombosis (blood clot)?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Eye conditions such as bleeding in the retina or detached retina?"
        value="Eye conditions such as bleeding in the retina or detached retina?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Cataract or lens transplant?"
        value="Cataract or lens transplant?"
      />

      <v-checkbox
        v-model="selectedPresentExp"
        label="Laser treatment or other eye surgery?"
        value="Laser treatment or other eye surgery?"
      />

      <v-textarea
        v-model="selectedPresentExpComment"
        name="input-7-1"
        label="Comment"
      />
    </v-container>

    <div fluid v-if="isFemale">
      Women only answer the following. Do you have:
    </div>

    <v-container fluid v-if="isFemale">
      <v-checkbox
        v-model="selectedWomen"
        label="Menstrual period problems?"
        value="Menstrual period problems?"
      />

      <v-checkbox
        v-model="selectedWomen"
        label="Significant childbirth – related problems?"
        value="Significant childbirth – related problems?"
      />

      <v-checkbox
        v-model="selectedWomen"
        label="Urine loss when you cough, sneeze or laugh?"
        value="Urine loss when you cough, sneeze or laugh?"
      />

      <v-text-field
        v-model="lastPelvicExam"
        label="Date of the last pelvic exam and / or Pap smear 	"
        :rules="nameRules"
      />
    </v-container>

    <div>Men and women answer the following:</div>
    <v-textarea
      v-model="listPrescribedmed"
      label="List any prescription medications you are now taking: "
      :rules="nameRules"
    />

    <v-textarea
      v-model="listHopitalized"
      label="List hospitalizations, including dates of and reasons for hospitalization:	"
      :rules="nameRules"
    />

    <v-textarea
      v-model="listDrugAllergy"
      label="List any drug allergies:"
      :rules="nameRules"
    />
  </v-container>
</template>

<script>
export default {
  name: "MedicalHistoryPresentMedicalHistory",

  props: {
    presentMedicalHistory: Object,
    isFemale: Boolean
  },

  data: () => ({
    nameRules: [v => !!v || "is required"],
    selectedPresent: [],
    selectedPresentExp: [],
    selectedPresentExpComment: "",
    selectedWomen: [],
    lastPelvicExam: "",
    listPrescribedmed: "",
    listHopitalized: "",
    listDrugAllergy: ""
  }),

  mounted() {
    if (this.presentMedicalHistory) {
      this.lastPelvicExam = this.presentMedicalHistory.lastPelvicExam;
      this.listDrugAllergy = this.presentMedicalHistory.listDrugAllergy;
      this.listHopitalized = this.presentMedicalHistory.listHopitalized;
      this.listPrescribedmed = this.presentMedicalHistory.listPrescribedmed;
      this.selectedPresentExpComment = this.presentMedicalHistory.selectedPresentExpComment;

      this.selectedPresent = this.presentMedicalHistory.selectedPresent;
      this.selectedPresentExp = this.presentMedicalHistory.selectedPresentExp;
      this.selectedWomen = this.presentMedicalHistory.selectedWomen;
    }
  },

  methods: {
    genData() {
      let presentMedicalHistory = {
        selectedPresent: this.selectedPresent,
        selectedPresentExp: this.selectedPresentExp,
        selectedPresentExpComment: this.selectedPresentExpComment,
        selectedWomen: this.selectedWomen,
        lastPelvicExam: this.lastPelvicExam,
        listPrescribedmed: this.listPrescribedmed,
        listHopitalized: this.listHopitalized,
        listDrugAllergy: this.listDrugAllergy
      };
      this.$emit("onPresentMedicalHistory", presentMedicalHistory);
    }
  },

  watch: {
    selectedPresent() {
      this.genData();
    },

    selectedPresentExp() {
      this.genData();
    },

    selectedPresentExpComment() {
      this.genData();
    },

    selectedWomen() {
      this.genData();
    },

    lastPelvicExam() {
      this.genData();
    },

    listPrescribedmed() {
      this.genData();
    },

    listHopitalized() {
      this.genData();
    },

    listDrugAllergy() {
      this.genData();
    }
  }
};
</script>
