<template>
  <div>
    <v-row>
      <v-col class="text-left">
        <v-btn class="primary" @click="onBack">
          <v-icon>mdi-step-backward</v-icon>Back
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn color="primary" @click="confirmSchedule">
          Confirm
          <v-icon>mdi-step-forward</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col>
        Ito po ang inyong napiling schedule:
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1" />
      <v-col cols="3">Serbisyo</v-col>
      <v-col cols="8">{{ name }} sa {{ location }}</v-col>
    </v-row>

    <v-row class="mt-n4">
      <v-col cols="1" />
      <v-col cols="3">Petsa at Oras</v-col>
      <v-col cols="8">{{ start }}</v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col>
        Kung tama ang mga detalyeng ito, paki-click ang
        <strong>IKUMPIRMA ANG SCHEDULE</strong>.
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" @onClose="onConfirmationClose" />
  </div>
</template>

<script>
import { DB, getCurrentUser, getCurrentUserUid } from "@/common/store";
import { SCHEDULED } from "@/common/utilities/appointments";
import { STATUS_UNREAD } from "@/common/utilities/message";

import { db } from "@/main";
import { getDateTime, timeStampToDate } from "@/common/utilities/date";

import firebase from "firebase/app";
import "firebase/auth";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";

let uuid = require("uuid");

export default {
  name: "ConfirmSchedule",

  components: {
    ConfirmDialog
  },

  props: {
    details: Object
  },

  computed: {
    location() {
      return this.details && this.details.service.serviceLocation
        ? this.details.service.serviceLocation
        : "";
    },

    name() {
      return this.details && this.details.service.name
        ? this.details.service.name
        : "";
    },

    start() {
      return this.details && this.details.slot.start
        ? getDateTime(this.details.slot.start)
        : "";
    }
  },

  methods: {
    addAttendee(scheduleId, attendeeId) {
      db.collection(DB.LGU_SCHEDULES)
        .doc(scheduleId)
        .update({
          attendees: firebase.firestore.FieldValue.arrayUnion({
            attendeeId: attendeeId,
            id: getCurrentUserUid(),
            name: getCurrentUser(),
            status: SCHEDULED
          })
        });
    },

    confirmSchedule() {
      this.setAppointment({
        // schedule
        scheduleId: this.details.id,
        scheduleStart: this.details.schedule.start,
        scheduleEnd: this.details.schedule.end,

        // slot schedule
        start: timeStampToDate(this.details.slot.start),
        end: timeStampToDate(this.details.slot.end),

        // service details
        serviceId: this.details.service.id,
        serviceLocation: this.location,
        serviceName: this.name
      }).then(() => {
        this.showConfirmationMessage();
      });
    },

    constructAppointmentData(data, timestamp) {
      return {
        timestamp: timestamp,
        appointment: {
          id: data.scheduleId,
          qrCode: uuid.v4(),
          start: data.start,
          end: data.end
        },
        schedule: {
          start: data.scheduleStart,
          end: data.scheduleEnd
        },
        service: {
          id: data.serviceId,
          name: data.serviceName,
          location: data.serviceLocation
        },
        user: {
          id: getCurrentUserUid(),
          name: getCurrentUser()
        },
        status: SCHEDULED
      };
    },

    onBack() {
      this.$emit("on-back");
    },

    onConfirmationClose() {
      this.$emit("on-confirm");
    },

    setAppointment(data) {
      return new Promise(resolve => {
        let timestamp = new Date();
        let appointmentData = this.constructAppointmentData(data, timestamp);

        db.collection(DB.LGU_ATTENDEE)
          .add(appointmentData)
          .then(doc => {
            this.addAttendee(data.scheduleId, doc.id);
            this.sendLguAppointmentMessage(doc.id, appointmentData, timestamp);
            resolve();
          })
          .catch(() => resolve());
      });
    },

    showConfirmationMessage() {
      this.$refs.confirm.open(
        this.$t("dialog.confirmation"),
        `Naitala na po ang inyong appointment. Mangyaring dumating ng 10 minutong mas maaga sa inyong appointment. Maraming salamat po!`,
        { noconfirm: true }
      );
    },

    sendLguAppointmentMessage(appointmentId, data, timestamp) {
      let body = `Magandang araw!

    Ito po ang detalye ng inyong appointment:

    Serbisyo: ${data.service.name}
    Lugar: ${data.service.location}
    Oras: ${getDateTime(data.appointment.start)}

    Mangyaring dumating ng 10 minuto bago ang inyong appointment at ipakita ang QR Code na nasa ibaba.`;

      db.collection(DB.MESSAGE_BOX).add({
        subject: "Barangay LGU Service Appointment ",
        body: body,
        receiver: getCurrentUserUid(),
        status: STATUS_UNREAD,
        recieveqr: `${appointmentId}-${data.appointment.qrCode}`,
        timestamp: timestamp,
        schedule: data.appointment.start
      });
    }
  }
};
</script>
