<template>
  <v-parallax height="700" dark :src="require('@/assets/homehead1.jpg')">
    <v-container fluid class="signincontainer">
      <div v-if="waitSpinner" class="mt-15 text-center">
        <CircularProgress />
      </div>

      <div v-else>
        <v-row>
          <v-col class="pt-10" cols="12" sm="6" align="center" />

          <v-col cols="12" sm="6">
            <v-card class="mx-auto my-12" height="450" max-width="374">
              <div v-if="waitSpinner" class="mt-15 text-center">
                <CircularProgress />
              </div>

              <div v-else class="text-center ">
                <v-alert type="error" v-if="errorText">{{ errorText }}</v-alert>

                <v-form ref="form" v-model="valid" lazy-validation class="pa-8">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    autocomplete="username"
                    label="E-mail"
                    required
                  />

                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="current-password"
                    name="password"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="showPassword = !showPassword"
                  />

                  <v-btn
                    :disabled="!valid"
                    color="primary"
                    class="mb-8 mr-4 mt-4"
                    @click="uname_pass_login"
                  >
                    Sign in
                  </v-btn>

                  <v-divider />

                  <p class="caption text-center pt-5 mb-5">or</p>

                  <AuthenticationAlternativeSignIn
                    @facebookLogin="facebook_login()"
                    @googleLogin="google_login()"
                    @phoneLogin="phoneDialog = true"
                  />
                  <div class="pt-6">
                    <router-link
                      class="floaterl py-1 caption"
                      :to="ROUTE_FORGOTPASSWORD"
                    >
                      Forgot Password?
                    </router-link>

                    <router-link
                      class="floaterr py-1 caption"
                      :to="ROUTE_CREATE_ACCOUNT"
                    >
                      Create New Account
                    </router-link>
                  </div>
                </v-form>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-dialog v-model="phoneDialog" width="400">
          <AuthenticationPhoneSignin
            @onClose="phoneDialog = false"
            @onSuccess="onPhoneLogin($event)"
          />
        </v-dialog>
      </div>
    </v-container>
  </v-parallax>
</template>

<script>
import { LOCALE_TL } from "@/common/utilities/locale";
import {
  ROUTE_CREATE_ACCOUNT,
  ROUTE_FORGOTPASSWORD,
  ROUTE_LOADING,
  routeTo
} from "@/router";

import {
  getSigninError,
  setSigninError,
  setRegistrationDetails
} from "@/utilities/session";
import { onAuthStateChanged } from "@/main";

import firebase from "firebase/app";
import "firebase/auth";

import AuthenticationAlternativeSignIn from "@/components/Authentication/AlternativeSignIn.vue";
import AuthenticationPhoneSignin from "@/components/Authentication/PhoneSignin.vue";
import CircularProgress from "@/components/CircularProgress.vue";

export default {
  name: "Signin",

  components: {
    AuthenticationAlternativeSignIn,
    AuthenticationPhoneSignin,
    CircularProgress
  },

  data: () => ({
    valid: true,
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    showPassword: false,
    password: "",
    rules: {
      required: value => !!value || "Pasword Required.",
      min: v => v.length >= 8 || "Min 8 characters"
    },
    errorText: "",
    waitSpinner: false,

    phoneDialog: false,

    ROUTE_FORGOTPASSWORD: ROUTE_FORGOTPASSWORD,
    ROUTE_CREATE_ACCOUNT: ROUTE_CREATE_ACCOUNT
  }),

  mounted() {
    if (getSigninError()) {
      this.errorText = `You registered with a different sign-in method. Please visit your Profile to login using another method.`;
      setSigninError();
    } else {
      this.errorText = "";
    }
  },

  methods: {
    facebook_login() {
      this.provider_login(new firebase.auth.FacebookAuthProvider());
    },

    google_login() {
      this.provider_login(new firebase.auth.GoogleAuthProvider());
    },

    onPhoneLogin(result) {
      this.phoneDialog = false;
      this.setRegistrationDetails(result.user);

      firebase
        .auth()
        .currentUser.updateProfile({
          displayName: result.user.phoneNumber
        })
        .then(() => {
          this.waitSpinner = false;
        })
        .catch(error => {
          this.waitSpinner = false;
          this.errorText = error;
        })
        .finally(() => {
          onAuthStateChanged(result.user);
        });
    },

    provider_login(provider) {
      this.waitSpinner = true;
      this.errorText = "";

      routeTo(ROUTE_LOADING);

      firebase.auth().signInWithRedirect(provider);
    },

    setRegistrationDetails(user) {
      setRegistrationDetails(
        user.phoneNumber,
        "",
        user.phoneNumber,
        "",
        LOCALE_TL
      );
    },

    uname_pass_login() {
      this.waitSpinner = true;
      this.errorText = "";
      this.$refs.form.validate();
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(result => onAuthStateChanged(result.user))
        .catch(error => {
          this.waitSpinner = false;
          this.errorText = error;
        });
    }
  }
};
</script>

<style scoped>
.signincontainer {
  max-width: 1200px;
}

.floaterl {
  float: left;
}

.floaterr {
  float: right;
}
</style>
