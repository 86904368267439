import { IS_STAGING } from "@/common/main";

import {
  addNewDocToIndex,
  addToIndex,
  passThroughContent
} from "./indexed_documents";
import { db } from "@/main";
import { getCurrentUserUid } from "@/common/store";
import { timeStampToDate } from "@/common/utilities/date";

export const DOCTYPE_BANTAYKARD = "bantayKard";
export const DOCTYPE_CONTACTINFO = "contactInfo";
export const DOCTYPE_CONTACTTRACING = "contactTracing";
export const DOCTYPE_FAMILYHISTORY = "familyHistory";
export const DOCTYPE_MEDICALHISTORY = "medicalHistory";
export const DOCTYPE_MEMBERINFO = "memberInfo";
export const DOCTYPE_PERSONALSOCIALHISTORY = "personalSocialHistory";
export const DOCTYPE_PHILHEALTHINFO = "philhealthInfo";
export const DOCTYPE_VITALSIGNS = "vitalSigns";

function constructAddDocumentHeader(type, timestamp, root, parent) {
  let header = {
    type: type,
    addedOn: timestamp
  };

  if (root) {
    header["root"] = root;
  } else if (parent) {
    header["root"] = parent;
  }

  if (parent) {
    header["parent"] = parent;
  }

  return header;
}

export function getDocumentHeader(type, state) {
  return constructAddDocumentHeader(
    type,
    new Date(),
    state.rootId,
    state.documentId
  );
}

export function getCurrentUserCollection() {
  return (IS_STAGING ? "test_" : "") + `user_${getCurrentUserUid()}`;
}

function dbAddDocument(content) {
  return new Promise((resolve, reject) => {
    db.collection(getCurrentUserCollection())
      .add({ document: { ...content.header }, ...content.data })
      .then(doc => resolve(doc.id))
      .catch(err => reject(err));
  });
}

function dbGetFixedDocument(fixedDocumentId) {
  return new Promise((resolve, reject) => {
    db.collection(getCurrentUserCollection())
      .doc(fixedDocumentId)
      .get()
      .then(doc => resolve(doc.exists ? doc.data() : null))
      .catch(err => reject(err));
  });
}

function dbSetFixedDocument(fixedDocumentId, content) {
  return new Promise((resolve, reject) => {
    db.collection(getCurrentUserCollection())
      .doc(fixedDocumentId)
      .set({ document: { ...content.header, id: content.id }, ...content.data })
      .then(() => resolve(content))
      .catch(err => reject(err));
  });
}

export class IndexedDocument {
  constructor(DOCTYPE, getDocumentContent) {
    this.state = {
      DOCTYPE: DOCTYPE,
      getDocumentContent: getDocumentContent,

      addedOn: "",
      rootId: "",
      parentId: "",
      documentId: "",
      isSaved: false
    };

    this.getters = {
      getAddedOn(state) {
        return state.addedOn;
      }
    };

    this.mutations = {
      RESET_DOCUMENT(state) {
        state.addedOn = "";
        state.rootId = "";
        state.parentId = "";
        state.documentId = "";
        state.isSaved = false;
      },

      saveDocument(state, document) {
        if (document) {
          state.addedOn = document.addedOn || "";
          state.rootId = document.root || "";
          state.parentId = document.parent || "";
          state.documentId = document.id || "";
        }

        state.isSaved = true;
      },

      setAddedOn(state, value) {
        state.addedOn = value;
      },

      setDocumentId(state, value) {
        state.documentId = value;
      },

      setParentId(state, value) {
        state.parentId = value;
      },

      setRootId(state, value) {
        state.rootId = value;
      }
    };

    this.actions = {
      addDocument({ commit, state }, content) {
        return new Promise((resolve, reject) => {
          dbAddDocument(content)
            .then(id => {
              content["id"] = id;

              commit("setData", content.data);
              commit("saveDocument", {
                addedOn: content.header.addedOn,
                root: content.header.root,
                parent: content.header.parent,
                id: id
              });

              return dbSetFixedDocument(state.DOCTYPE, content);
            })
            .then(data => resolve(data))
            .catch(err => reject(err));
        });
      },

      load({ commit, state }) {
        return new Promise((resolve, reject) => {
          dbGetFixedDocument(state.DOCTYPE)
            .then(data => {
              if (data) {
                commit("setAddedOn", timeStampToDate(data.document.addedOn));
                commit("setDocumentId", data.document.id);
                commit("setParentId", data.document.parentId || "");
                commit("setRootId", data.document.rootId || "");
              } else {
                commit("setAddedOn", "");
                commit("setDocumentId", "");
                commit("setParentId", "");
                commit("setRootId", "");
              }

              if (data) {
                commit("setData", data);
              }
              resolve();
            })
            .catch(err => reject(err));
        });
      },

      passThrough({ state }, content) {
        return passThroughContent(state.documentId, state.addedOn, content);
      },

      resetDocument({ commit }) {
        commit("RESET_DOCUMENT");
      },

      save({ dispatch, state }, data) {
        return new Promise((resolve, reject) => {
          let addDataFunction = state.isSaved ? "passThrough" : "addDocument";

          const content = state.getDocumentContent(state);
          dispatch(addDataFunction, content)
            .then(content1 => {
              return addToIndex(
                data.collection,
                state.DOCTYPE,
                content1,
                data.docId
              );
            })
            .then(() => resolve())
            .catch(err => reject(err));
        });
      },

      saveNewDoc({ dispatch, state }, collection) {
        return new Promise((resolve, reject) => {
          let addDataFunction = state.isSaved ? "passThrough" : "addDocument";

          const content = state.getDocumentContent(state);
          dispatch(addDataFunction, content)
            .then(content1 => {
              return addNewDocToIndex(collection, state.DOCTYPE, content1);
            })
            .then(id => resolve(id))
            .catch(err => reject(err));
        });
      }
    };
  }
}
