<template>
  <v-card class="pb-4">
    <v-row class="display_inline">
      <v-img id="cbig_logo" src="@/assets/logoCBIG.jpg" />
      <v-card-subtitle class="cbig_details">
        <a href="https://www.countrybankers.com/">
          https://www.countrybankers.com/
        </a>
        <br />
        (632) 8523-8611 to 18 &#x25CF; (632) 8524-0621 to 23
      </v-card-subtitle>
    </v-row>

    <v-row class="mx-4 mt-n5">
      <v-card-title>Alalay sa Buhay</v-card-title>
    </v-row>

    <v-row class="mx-4 mt-n5">
      <v-card-subtitle>
        {{ $t("insurance.benefitTitle") }}
      </v-card-subtitle>
    </v-row>

    <v-row class="mx-4" align="center">
      <v-col cols="12" sm="6" md="6">
        <v-card id="card1">
          <v-img
            src="@/assets/death_benefit.png"
            :contain="true"
            height="100px"
          />

          <v-card-title>Death Benefit</v-card-title>

          <v-card-subtitle>
            {{ $t("insurance.deathBenefit") }}
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-card id="card2">
          <v-img
            src="@/assets/accidental_death.jpg"
            :contain="true"
            height="100px"
          />

          <v-card-title>Accidental Death</v-card-title>

          <v-card-subtitle>
            {{ $t("insurance.accidentalDeath") }}
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-card id="card3">
          <v-img
            src="@/assets/instant_abuloy.jpg"
            :contain="true"
            height="100px"
          />

          <v-card-title>Instant Abuloy</v-card-title>

          <v-card-subtitle>
            {{ $t("insurance.instantAbuloy") }}
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-card id="card4">
          <v-img
            src="@/assets/daily_confinement.jpg"
            :contain="true"
            height="100px"
          />

          <v-card-title>Daily Confinement for 30 Days</v-card-title>

          <v-card-subtitle>
            {{ $t("insurance.dailyConfinement") }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="this.packageNames.length" class="mx-4 mt-4">
      <v-card-title>Packages</v-card-title>
    </v-row>

    <InsurancePackages
      :packageDetails="packageDetails"
      :packageNames="packageNames"
      :initialPackage="selectedPackageName"
      v-on:selected-package-name="onSelectedPackageName"
    />

    <div class="center">
      <v-btn class="subscribe success" @click="onSubscribe">Enroll</v-btn>
    </div>
  </v-card>
</template>

<script>
import InsurancePackages from "./Packages";

export default {
  name: "InsuranceOverview",

  components: {
    InsurancePackages
  },

  props: {
    packageDetails: Object,
    packageNames: Array,
    initialPackage: String
  },

  data: () => ({
    selectedPackageName: ""
  }),

  mounted() {
    if (this.initialPackage && this.initialPackage.length) {
      this.selectedPackageName = this.initialPackage;
    }

    let maxHeight = Math.max(
      this.getElementHeight("card1"),
      this.getElementHeight("card2"),
      this.getElementHeight("card3"),
      this.getElementHeight("card4")
    );

    this.setElementHeight("card1", maxHeight);
    this.setElementHeight("card2", maxHeight);
    this.setElementHeight("card3", maxHeight);
    this.setElementHeight("card4", maxHeight);
  },

  methods: {
    getElementHeight(id) {
      let element = document.getElementById(id);
      return element ? element.clientHeight : 0;
    },

    onSelectedPackageName(selectedPackageName) {
      this.selectedPackageName = selectedPackageName;
    },

    onSubscribe() {
      this.$emit("subscribe", this.selectedPackageName);
    },

    setElementHeight(id, height) {
      if (height > 0) {
        let element = document.getElementById(id);
        if (element) {
          element.style.height = height + "px";
        }
      }
    }
  }
};
</script>

<style scoped>
#cbig_logo {
  max-width: 150px;
  min-width: 70px;
  text-align: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
}

.cbig_details {
  margin-top: -15px;
  text-align: center;
}

.center {
  margin-top: 20px;
  text-align: center;
}

.display_inline {
  display: inline;
}

.subscribe {
  margin: auto;
  width: 150px;
}
</style>
