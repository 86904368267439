<template>
  <div>
    <ConfirmHomeServiceSchedule
      v-if="$store.getters['lab/appointment/getIsHomeService']"
      @back="$emit('back')"
      @next="$emit('next')"
    />

    <ConfirmStationarySchedule
      v-else
      @back="$emit('back')"
      @next="$emit('next')"
    />
  </div>
</template>

<script>
import ConfirmHomeServiceSchedule from "./ConfirmSchedule/ConfirmHomeServiceSchedule.vue";
import ConfirmStationarySchedule from "./ConfirmSchedule/ConfirmStationarySchedule.vue";

export default {
  name: "ConfirmSchedule",

  components: {
    ConfirmHomeServiceSchedule,
    ConfirmStationarySchedule
  }
};
</script>
