<template>
  <div class="pa-0">
    <div v-show="waitSpinner" class="mt-15 text-center">
      <CircularProgress />
    </div>
    <div v-show="!waitSpinner">
      <v-stepper v-model="step" flat>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ STEP_HEADER_1 }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="step > 2" step="2">
            {{ STEP_HEADER_2 }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 3" step="3">
            {{ STEP_HEADER_3 }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            step="1"
            :class="{ 'pt-2 px-1': $vuetify.breakpoint.mobile }"
          >
            <BackButton @click="routeBack()" class="ml-2 mt-3 mb-2" />
            <h3
              v-show="$vuetify.breakpoint.mobile"
              class="text-center pa-2 primary--text"
            >
              - {{ STEP_HEADER_1 }} -
            </h3>
            <ReceivingOption
              :addressSelected="addressSelected"
              :partialName="partialName"
              :partialContact="partialContact"
              @receivingOption="receivingOption = $event"
              @courier="courier = $event"
              @name="name = $event"
              @contact="contact = $event"
              @isValid="isReceivingOptionValid = $event"
            />
            <NextButton
              class="mr-2 mt-3"
              :disabled="!isReceivingOptionValid"
              @click="setNext()"
            />
          </v-stepper-content>

          <v-stepper-content
            step="2"
            :class="{ 'pt-2 px-1': $vuetify.breakpoint.mobile }"
          >
            <BackButton @click="step = 1" class="ml-2 mt-3 mb-2" />
            <h3
              v-if="$vuetify.breakpoint.mobile"
              class="text-center pa-2 primary--text"
            >
              - {{ STEP_HEADER_2 }} -
            </h3>

            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                label="Mode of Payment"
                v-model="modeofpayment"
                :items="items"
                prepend-inner-icon="mdi-contactless-payment"
                :rules="[m => !!m || 'Mode of Payment is required']"
                width="400"
              />
            </v-col>

            <CartItem
              :isVerifiedPwdSc="isVerifiedPwdSc"
              :displayAsterisk="displayAsterisk"
              :promoType="promoType"
              :cartorders="cartorders"
              :receivingOption="receivingOption"
              @highestShippingFee="highestShippingFee = $event"
              @promo-active="hasPromotionalActive = $event"
            />
            <TotalBreakdown
              :isVerifyingPwdScId="displayAsterisk"
              :subtotal="subtotal"
              :discountValue="discountValue"
              :promoType="promoType"
              :shippingFee="shippingFee"
              :total="finTotal"
              :paymentMade="paymentMade"
              :totalVerifiedPayments="totalVerifiedPayments"
            />

            <EventDisplay
              :hasPromotionalActive="hasPromotionalActive"
              :highestShippingFee="highestShippingFee"
            />

            <NextButton @click="step = 3" class="mr-2 mt-3" />
          </v-stepper-content>

          <v-stepper-content
            step="3"
            :class="{ 'pt-2 px-1': $vuetify.breakpoint.mobile }"
          >
            <BackButton @click="step = 2" class="ml-2 mt-3 mb-2" />
            <h3
              v-if="$vuetify.breakpoint.mobile"
              class="text-center pa-2 primary--text"
            >
              - {{ STEP_HEADER_3 }} -
            </h3>
            <Instructions
              :modeofpayment="modeofpayment"
              @close-dialog="closeDialog"
            />
            <div class="pa-2 text-right">
              <MOPSummary
                class="mb-2"
                :paymentBalance="paymentBalance"
                :modeofpayment="modeofpayment"
                :balance="getBantayKardBalance"
                :remainingbalance="remainingbalance"
                :finTotal="finTotal"
                @isValid="isPaymentValid = $event"
                @referenceNoTyping="referenceNo = $event"
              />

              <PwdScMessage v-if="displayAsterisk" />

              <NextButton
                text="Checkout"
                :disabled="!isPaymentValid"
                @click="checkOutPayment"
                class="mt-3"
              />
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

    <ConfirmDialog ref="confirm" @onClose="closeDialog()" />
  </div>
</template>

<script>
import { DB, getCurrentUserUid } from "@/common/store";
import { FOR_PICK_UP } from "../utilities/orders";
import {
  NS_STORE,
  processGcashReference,
  sendMessage,
  setDelivery,
  updateCardBalance
} from "@/store";
import {
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_PAYMENT_VERIFICATION
} from "@/common/utilities/order";
import {
  PAYMENT_CASH_ON_DELIVERY,
  PAYMENT_ON_SITE,
  PAYMENT_GCASH,
  isBantayKardPayment,
  isPaymentForValidation
} from "@/common/utilities/payment";
import {
  ROUTE_CART,
  ROUTE_SUMMARY_ORDER,
  ROUTE_ADDRESS_BOOK,
  ROUTE_TRACKORDER,
  routeTo,
  routeBack
} from "@/router";
import {
  STATE__SOLD,
  getAvailableItems,
  reserveItem,
  soldItem
} from "@/utilities/collection/products";
import { STATUS_UNREAD } from "@/common/utilities/message";

import {
  assignRemainingStock,
  setItemsSold,
  setPromoAvailed
} from "@/utilities/collection/addtocart";
import { checkOutPayment, checkOutNewPayment } from "@/store/payment";
import { db } from "@/main";
import { displayAmount } from "@/common/utilities/number";
import { getFullAddress } from "@/common/utilities/location";
import { getFullNameLoaded } from "@/common/utilities/profile";
import {
  getItem as productVariantGetItem,
  loadItems as productVariantLoadItems,
  reduceItemQuantity as productVariantReduceItemQuantity
} from "@/utilities/collection/productvariant";
import { getUniqueOrderId } from "@/utilities/order_id";
import { isLaboratoryService } from "@/common/utilities/services";
import { loadProfileAddress } from "@/utilities/tindahan";
import { loadUserValidationData } from "@/store/user_validation";
import { mapGetters } from "vuex";
import { setInventory as catalogSetInventory } from "@/utilities/collection/catalog";

import BackButton from "@/components/Controls/BackButton.vue";
import CartItem from "@/eTindahan/components/CartItem.vue";
import CircularProgress from "@/components/CircularProgress.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import EventDisplay from "@/eTindahan/components/EventDisplay.vue";
import Instructions from "@/components/Payment/Instructions.vue";
import MOPSummary from "@/components/Payment/MOPSummary.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import PwdScMessage from "@/eTindahan/components/PwdScMessage.vue";
import ReceivingOption from "@/eTindahan/components/ReceivingOption.vue";
import TotalBreakdown from "@/eTindahan/components/TotalBreakdown.vue";

const CHECKOUT = `${NS_STORE}/checkout/`;

export default {
  components: {
    BackButton,
    CartItem,
    CircularProgress,
    ConfirmDialog,
    EventDisplay,
    Instructions,
    MOPSummary,
    NextButton,
    PwdScMessage,
    ReceivingOption,
    TotalBreakdown
  },

  props: {
    addressSelect: String
  },

  mounted() {
    this.items = [
      isLaboratoryService ? PAYMENT_CASH_ON_DELIVERY : PAYMENT_ON_SITE,
      PAYMENT_GCASH
    ];
    this.modeofpayment = PAYMENT_CASH_ON_DELIVERY;

    if (this.paymentBalance > 0) {
      this.step = 2;
    }
  },

  data: () => ({
    contact: "",
    courier: "",
    discountValue: 0,
    hasPromotionalActive: false,
    hasPwdSc: false,
    highestShippingFee: 0,
    isPaymentValid: false,
    isReceivingOptionValid: false,
    items: [],
    modeofpayment: "",
    name: "",
    partialContact: "",
    partialName: "",
    promoType: "",
    receivingOption: "",
    referenceNo: "",
    serialNumbers: [],
    shippingFee: 0,
    step: 1,
    waitSpinner: false,

    houseNumber: "",
    street: "",
    landmark: "",
    selectedLocation: null,
    addressSelected: {},

    PAYMENT_CASH_ON_DELIVERY: PAYMENT_CASH_ON_DELIVERY,
    ROUTE_ADDRESS_BOOK: ROUTE_ADDRESS_BOOK,
    STEP_HEADER_1: "Delivery Method",
    STEP_HEADER_2: "Purchase Summary",
    STEP_HEADER_3: "Payment Confirmation",

    routeBack: routeBack,
    routeTo: routeTo
  }),

  computed: {
    ...mapGetters(["getBantayKardBalance", "getPwdScDetails"]),
    ...mapGetters({
      checkoutDetails: `${CHECKOUT}getCheckoutDetails`,
      cartorders: `${CHECKOUT}getCartItem`,
      paymentBalance: `${CHECKOUT}getBalance`,
      paymentMade: `${CHECKOUT}getPaymentMade`,
      paymentStatus: `${CHECKOUT}getpaymentStatus`,
      subtotal: `${CHECKOUT}getCartTotal`,
      totalVerifiedPayments: `${CHECKOUT}getTotalVerifiedPayments`
    }),

    displayAsterisk() {
      return this.hasPwdSc && !this.getPwdScDetails.isVerified;
    },

    finTotal() {
      return Number(this.subtotal) + this.shippingFee;
    },

    isAddressSelected() {
      return "addressSelected" === this.addressSelect;
    },

    isForPickup() {
      return FOR_PICK_UP === this.receivingOption;
    },

    isVerifiedPwdSc() {
      return this.isVerified(this.getPwdScDetails);
    },

    remainingbalance() {
      return Number(this.getBantayKardBalance) - Number(this.finTotal);
    }
  },

  watch: {
    cartorders(value) {
      this.hasPwdSc = false;

      value.forEach(item => {
        if (item.isPWDSCPriceApplied) {
          this.hasPwdSc = true;
        }
      });
    },

    highestShippingFee(value) {
      this.shippingFee = this.isForPickup ? 0 : Number(value);
    },

    modeofpayment(value) {
      this.$store.dispatch("setModeOfPayment", value);
    },

    receivingOption() {
      if (this.isForPickup) {
        this.loadForPickupAddress();
      } else if (this.isAddressSelected) {
        this.loadSelectedAddress();
      } else {
        this.loadDefaultAddress();
      }
    }
  },

  methods: {
    checkCatalog(orderIndex, totalOrders) {
      return new Promise(resolve => {
        if (orderIndex >= this.cartorders.length) {
          resolve(totalOrders);
        } else {
          let order = this.cartorders[orderIndex];

          this.passCatalog(
            order.vproductSKU,
            Number(order.quantity),
            this.cartorders.length,
            this.cartorders[orderIndex].name
          )
            .then(result => {
              return this.checkCatalog(orderIndex + 1, totalOrders + result);
            })
            .then(result => resolve(result));
        }
      });
    },

    checkOutPayment() {
      if (this.paymentBalance > 0 || this.paymentMade === this.finTotal) {
        this.updatePayments();
        return;
      }

      let thisOrderId = "";
      this.waitSpinner = true;
      this.checkCatalog(0, 0).then(() => {
        getUniqueOrderId(this.$store.getters["getUserCode"])
          .then(orderId => {
            thisOrderId = orderId;
            return this.updateCatalog(0, 0, STATE__SOLD);

            // KURT!!! Handle if order does not match actual quantity
          })
          .then(() => {
            return checkOutPayment(
              this.constructCheckoutPaymentDetails(thisOrderId)
            );
          })
          .then(doc => {
            this.processCheckoutPayment(doc.id, doc.transactionNumber);
          });
      });
    },

    closeDialog() {
      this.$emit("close-dialog");
    },

    constructAddress() {
      return {
        barangay: this.selectedLocation.barangay,
        municipality: this.selectedLocation.municipality,
        province: this.selectedLocation.province,
        region: this.selectedLocation.region,
        addressLine: `${this.houseNumber}, ${this.street}, ${this.landmark}`
      };
    },

    constructBillingAddress() {
      return {
        selectedLocation: this.selectedLocation,
        houseNumber: this.houseNumber,
        street: this.street,
        landmark: this.landmark
      };
    },

    constructCheckoutPaymentDetails(fullqr) {
      return {
        status: isPaymentForValidation(this.modeofpayment)
          ? ORDER_STATUS_PAYMENT_VERIFICATION
          : ORDER_STATUS_PROCESSING,
        trackingNumber: fullqr,
        fullqr: fullqr,

        name: this.name,
        userUid: getCurrentUserUid(),

        receivingOption: this.receivingOption,
        selectedLocation: this.selectedLocation,
        courier: this.courier,

        houseNumber: this.houseNumber,
        street: this.street,
        landmark: this.landmark,
        billingAddress: this.constructBillingAddress(),

        contact: this.contact,

        promoType: this.promoType,
        discountValue: this.discountValue,

        subtotal: this.subtotal,
        shippingFee: this.shippingFee,
        total: this.finTotal,

        modeOfPayment: this.modeofpayment,

        ...this.getSpecificDetails()
      };
    },

    generateDelivery(docid, comment, fullqr, shippingFee) {
      let deliveryAddress = {
        selectedLocation: this.selectedLocation,
        houseNumber: this.houseNumber,
        street: this.street,
        landmark: this.landmark
      };

      let delivery = {
        timestamp: new Date(),
        courier: this.courier,
        location: deliveryAddress,
        comment: comment,
        orderid: docid,
        shippingFee: shippingFee,
        trackingNumber: fullqr,
        mop: this.modeofpayment,

        status: ORDER_STATUS_PROCESSING,

        fullqr: fullqr
      };

      setDelivery(delivery).then(() => {
        this.waitSpinner = false;
        routeTo(ROUTE_SUMMARY_ORDER);
      });
    },

    generateQR(docid, fullqr) {
      db.collection(DB.CHECKOUT)
        .doc(docid)
        .set({ id: docid, fullqr: fullqr }, { merge: true });
    },

    getPurchaseEmailBody(docid) {
      let firstname = this.name.length ? ` ${this.name}` : "";

      return (
        // eslint-disable-next-line prettier/prettier
        `Hi ${firstname},

Your order is being prepared. Please expect delivery to ${getFullAddress(
          this.constructAddress()
        )} within a week.

Order Details:
    Order ID: ${docid}
    Purchase Amount: ₱ ${displayAmount(Number(this.finTotal))}
    Mode of Payment: ${this.modeofpayment}`
      );
    },

    getRemainingStock() {
      this.cartorders.forEach(cartItem => {
        productVariantGetItem(cartItem.vproductSKU).then(doc => {
          this.updateEachItemProductReserve(cartItem, doc.data().vstock);
          assignRemainingStock(cartItem.id, doc.data().vstock);
          routeTo(ROUTE_CART);
        });
      });
    },

    getSpecificDetails() {
      const KEYS = [
        "paymentBalance",
        "paymentMade",
        "referenceNumber",
        "totalVerifiedPayments"
      ];

      return KEYS.reduce((result, key) => {
        if (this[key]) {
          result[key] = this[key];
        }

        return result;
      }, {});
    },

    isDiscountValid(cart) {
      return new Date(cart.discountUntil.seconds * 1000) > new Date();
    },

    isVerified(details) {
      if (Object.keys(details).length === 0) {
        return "Please Upload Your ID";
      } else {
        return details.isVerified ? "Verified" : "Verifying";
      }
    },

    loadAddress(address) {
      this.addressSelected = address;

      if (address) {
        this.name = address.name || "";
        this.contact = address.contact || "";
        this.selectedLocation = address.selectedLocation || "";
        this.houseNumber = address.houseNumber || "";
        this.street = address.street || "";
        this.landmark = address.landmark || "";
      }
    },

    loadDefaultAddress() {
      loadProfileAddress().then(profile => {
        this.loadAddress(profile.defaultDetails);
      });
    },

    loadForPickupAddress() {
      loadUserValidationData("").then(data => {
        this.partialName = getFullNameLoaded(data);
        this.partialContact = data.contact;
      });

      this.selectedLocation = {
        barangay: "Addition Hills",
        municipality: "Mandaluyong City",
        region: "NCR",
        province: "National Capital Region"
      };

      this.houseNumber = "928";
      this.street = "Luna Mencias";
      this.landmark = "Medisure Office";
    },

    loadSelectedAddress() {
      this.loadAddress(this.$store.getters["getSelectedAddress"]);
    },

    passCatalog(vproductSKU, quantity, length, name) {
      return new Promise((resolve, reject) => {
        productVariantGetItem(vproductSKU)
          .then(doc => {
            let remainingStock = Number(doc.data().vstock);
            if (remainingStock < quantity) {
              length = remainingStock;

              let message = `${name} has ${remainingStock} remaining stock`;
              this.showConfirmationMessage(message);
            }

            resolve(length);
          })
          .catch(err => reject(err));
      });
    },

    processCheckoutPayment(orderId, fullQr) {
      setItemsSold(orderId, fullQr, this.serialNumbers);
      if (isBantayKardPayment(this.modeofpayment)) {
        updateCardBalance(this.remainingbalance);
      }

      this.updateCartPromo();
      this.sendMessage(fullQr);
      this.generateQR(orderId, fullQr);
      this.generateDelivery(
        orderId,
        "Your order is being prepared.",
        orderId,
        this.shippingFee
      );

      this.$store
        .dispatch("setCartCount", 0)
        .then(() => {
          return this.$store.dispatch(`${CHECKOUT}setOrderId`, orderId);
        })
        .finally(() => {
          processGcashReference({
            docId: orderId,
            modeOfPayment: this.modeofpayment,
            referenceNumber: this.referenceNo,
            orderId: fullQr
          });
        });
    },

    reduceItemQuantity(productSku, variantSku, quantity) {
      return new Promise(resolve => {
        let actualQuantityReduced = 0;

        productVariantReduceItemQuantity(variantSku, quantity)
          .then(result => {
            actualQuantityReduced = result;
            return productVariantLoadItems(productSku);
          })
          .then(docs => {
            let catalogInventory = docs.reduce(
              (total, currentValue) => total + currentValue.data.vstock,
              0
            );

            return catalogSetInventory(productSku, Number(catalogInventory));
          })
          .finally(() => resolve(actualQuantityReduced));
      });
    },

    reduceQuantity(order, productSku, quantity) {
      return new Promise(resolve => {
        this.updateEachItemProduct(order.vproductSKU, quantity);
        this.reduceItemQuantity(
          productSku,
          order.vproductSKU,
          Number(quantity)
        ).then(result => resolve(result));
      });
    },

    sendMessage(fullqr) {
      sendMessage({
        name: "Admin AI-Store",
        subject: "e-Tindahan Purchase",
        body: this.getPurchaseEmailBody(fullqr),
        receiver: getCurrentUserUid(),
        sender: "Admin",
        status: STATUS_UNREAD,
        timestamp: new Date()
      });
    },

    setNext() {
      let address = {
        houseNumber: this.houseNumber,
        street: this.street,
        landmark: this.landmark
      };

      this.$store
        .dispatch("saveProfileLastSelectedAddress", address)
        .then(() => {
          this.step = 2;
        });
    },

    async showConfirmationMessage(text) {
      if (
        await this.$refs.confirm.open("Confirmatiom", text, {
          noconfirm: false
        })
      ) {
        this.getRemainingStock();
      }
    },

    soldItem(idColl, index, sold, timestamp) {
      return new Promise(resolve => {
        if (index >= idColl.length) {
          resolve(sold);
        } else {
          soldItem(idColl[index], timestamp)
            .then(result => {
              if (result) {
                sold++;
              }
            })
            .finally(() => {
              return this.soldItem(idColl, index + 1, sold, timestamp);
            })
            .then(result => resolve(result));
        }
      });
    },

    updateCartPromo() {
      this.cartorders.forEach(cart => {
        if (this.isDiscountValid(cart)) {
          setPromoAvailed(cart.id);
        }
      });
    },

    updateCatalog(orderIndex, totalOrders) {
      return new Promise(resolve => {
        if (orderIndex >= this.cartorders.length) {
          resolve(totalOrders);
        } else {
          let order = this.cartorders[orderIndex];
          this.reduceQuantity(order, order.productSKU, Number(order.quantity))
            .then(result => {
              return this.updateCatalog(orderIndex + 1, totalOrders + result);
            })
            .then(result => resolve(result));
        }
      });
    },

    updateEachItemProduct(vproductSKU, qty) {
      getAvailableItems(vproductSKU, qty).then(snapshot => {
        let idColl = [];

        snapshot.forEach(doc => {
          idColl.push(doc.id);
          this.serialNumbers.push({
            serialNumber: doc.id,
            vproductSKU: vproductSKU
          });
        });

        return this.soldItem(idColl, 0, 0, new Date());
      });
    },

    updateEachItemProductReserve(cartItem, qty) {
      getAvailableItems(cartItem.vproductSKU, qty).then(snapshot => {
        snapshot.forEach(doc => {
          reserveItem(doc.id, new Date());
        });
      });
    },

    updatePayments() {
      let details = {
        ...this.constructCheckoutPaymentDetails(this.checkoutDetails.fullqr),
        id: this.checkoutDetails.id
      };

      if (PAYMENT_CASH_ON_DELIVERY === this.modeofpayment) {
        this.$store.commit(`${CHECKOUT}setBalance`, 0);
      }

      checkOutNewPayment(details).then(() => {
        routeTo(ROUTE_TRACKORDER);
      });
    }
  }
};
</script>
