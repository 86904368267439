<template>
  <v-card class="pa-0 hidden">
    <v-card-actions class="justify-center pa-5">
      <div class="text-center primary--text">
        We are processing your order.
      </div>
    </v-card-actions>

    <Track />
  </v-card>
</template>

<script>
import Track from "@/eTindahan/views/Track.vue";

export default {
  name: "eTindahanSummaryOrder",

  components: {
    Track
  }
};
</script>
