<template>
  <div>
    <BackButton @click="$emit('back')" />

    <h3 class="mt-10 text-center">Family History</h3>

    <v-row class="mb-0 mt-5">
      <v-col :cols="checkedCol" class="text-center">
        <p class="text-subtitle-2 text-center">
          Father's Family
        </p>
      </v-col>
      <v-col :cols="checkedCol" class="text-center">
        <p class="text-subtitle-2 text-center">
          Mother's Family
        </p>
      </v-col>
      <v-col :cols="itemCol" class="text-center">
        <p class="text-subtitle-2 text-center">
          Illness
        </p>
      </v-col>
    </v-row>

    <v-row
      v-for="(illness, i) in checkedHistory"
      :key="i"
      class="mt-0 py-5"
      :class="{ 'background-grey': !(i % 2) }"
    >
      <v-col :cols="checkedCol" class="pa-0 text-center">
        <v-simple-checkbox
          @input="onFatherChange(illness.illness, $event)"
          :value="illness.father"
          :ripple="false"
          class="ma-0 minimized pt-0"
        />
      </v-col>
      <v-col :cols="checkedCol" class="pa-0 text-center">
        <v-simple-checkbox
          @input="onMotherChange(illness.illness, $event)"
          :value="illness.mother"
          :ripple="false"
          class="ma-0 minimized pt-0"
        />
      </v-col>
      <v-col :cols="itemCol" class="pa-0">
        {{ illness.illness }}
      </v-col>
    </v-row>

    <v-row
      v-for="(illness, i) in textHistory"
      :key="i + checkedHistory.length"
      class="mt-2"
      :class="{
        'background-grey': isBackgroundGreyText(i),
        'py-5': noChecked(illness.illness),
        'pt-5': eitherChecked(illness.illness)
      }"
    >
      <v-col :cols="checkedCol" class="pa-0 text-center">
        <v-simple-checkbox
          @input="onFatherChecked(illness.illness, $event)"
          :value="getFatherChecked(illness.illness)"
          :ripple="false"
          class="ma-0 minimized pt-0"
        />
      </v-col>
      <v-col :cols="checkedCol" class="pa-0 text-center">
        <v-simple-checkbox
          @input="onMotherChecked(illness.illness, $event)"
          :value="getMotherChecked(illness.illness)"
          :ripple="false"
          class="ma-0 minimized pt-0"
        />
      </v-col>
      <v-col :cols="itemCol" class="pa-0">
        {{ illness.illness }}
      </v-col>

      <v-col v-show="!isXLOrLarger" :cols="paddingCol" class="mt-0 pa-0" />
      <v-col
        v-show="getFatherChecked(illness.illness)"
        :cols="illnessTypeCol"
        class="pa-0 text-center"
      >
        <v-text-field
          @input="onFatherComment(illness.illness, $event)"
          :value="getFatherComment(illness.illness)"
          label="Type? (Father's Family)"
          class="illness-comment pa-0"
          :class="{ 'mt-0': isXLOrLarger, 'mt-4': !isXLOrLarger }"
        />
      </v-col>

      <v-col
        cols="8"
        v-show="isXLOrLarger && bothChecked(illness.illness)"
        class="mt-0 pa-0"
      />
      <v-col
        :cols="paddingCol"
        v-show="getFatherChecked(illness.illness)"
        class="mt-0 pa-0"
      />
      <v-col
        :cols="illnessTypeCol"
        v-show="getMotherChecked(illness.illness)"
        class="pa-0 text-center"
      >
        <v-text-field
          @input="onMotherComment(illness.illness, $event)"
          :value="getMotherComment(illness.illness)"
          label="Type? (Mother's Family)"
          class="illness-comment pa-0"
          :class="{
            'mt-0': isXLOrLarger || getFatherChecked(illness.illness),
            'mt-4': !(isXLOrLarger || getFatherChecked(illness.illness))
          }"
        />
      </v-col>
    </v-row>

    <NextButton @click="onNext" class="mb-5 mt-15" />
  </div>
</template>

<script>
import { NS_HEALTHRECORDS_FAMILYHISTORY as NAMESPACE } from "@/store";

import { getCurrentUserUid } from "@/common/store";
import { isXLOrLarger, setWidth } from "@/utilities/display";

import BackButton from "@/components/Controls/BackButton.vue";
import NextButton from "@/components/Controls/NextButton.vue";

export default {
  name: "FamilyHistory",

  components: {
    BackButton,
    NextButton
  },

  props: {
    collection: String
  },

  mounted() {
    this.$store.dispatch(`${NAMESPACE}/load`);
  },

  methods: {
    bothChecked(illness) {
      return this.getFatherChecked(illness) && this.getMotherChecked(illness);
    },

    eitherChecked(illness) {
      return this.getFatherChecked(illness) || this.getMotherChecked(illness);
    },

    getFatherChecked(key) {
      return this.$store.getters[`${NAMESPACE}/getFatherChecked`](key);
    },

    getFatherComment(key) {
      return this.$store.getters[`${NAMESPACE}/getFatherComment`](key);
    },

    getMotherChecked(key) {
      return this.$store.getters[`${NAMESPACE}/getMotherChecked`](key);
    },

    getMotherComment(key) {
      return this.$store.getters[`${NAMESPACE}/getMotherComment`](key);
    },

    isBackgroundGreyText(i) {
      return !((Object.keys(this.checkedHistory).length + i) % 2);
    },

    onFatherChange(key, value) {
      this.$store.commit(`${NAMESPACE}/setFatherData`, {
        key: key,
        value: value
      });
    },

    onFatherChecked(key, value) {
      this.$store.commit(`${NAMESPACE}/setFatherChecked`, {
        key: key,
        value: value
      });
    },

    onFatherComment(key, value) {
      this.$store.commit(`${NAMESPACE}/setFatherComment`, {
        key: key,
        value: value
      });
    },

    onMotherChange(key, value) {
      this.$store.commit(`${NAMESPACE}/setMotherData`, {
        key: key,
        value: value
      });
    },

    onMotherChecked(key, value) {
      this.$store.commit(`${NAMESPACE}/setMotherChecked`, {
        key: key,
        value: value
      });
    },

    onMotherComment(key, value) {
      this.$store.commit(`${NAMESPACE}/setMotherComment`, {
        key: key,
        value: value
      });
    },

    onNext() {
      this.$store
        .dispatch(`${NAMESPACE}/save`, {
          collection: this.collection,
          docId: getCurrentUserUid()
        })
        .then(() => {
          this.$emit("next");
        });
    },

    noChecked(illness) {
      return !this.getFatherChecked(illness) && !this.getMotherChecked(illness);
    },

    setWidth(xl, lg, md, sm, fall) {
      return setWidth(this.$vuetify.breakpoint.width, xl, lg, md, sm, fall);
    }
  },

  computed: {
    checkedCol() {
      return this.setWidth(2, 3, 2, 4, 4);
    },

    checkedHistory() {
      return this.$store.getters[`${NAMESPACE}/getCheckedHistory`];
    },

    illnessTypeCol() {
      return this.setWidth(4, 6, 8, 10, 11);
    },

    isXLOrLarger() {
      return isXLOrLarger(this.$vuetify.breakpoint.width);
    },

    itemCol() {
      return this.setWidth(4, 6, 8, 4, 4);
    },

    paddingCol() {
      return this.setWidth(0, 6, 4, 2, 1);
    },

    textHistory() {
      return this.$store.getters[`${NAMESPACE}/getTextHistory`];
    }
  }
};
</script>

<style scoped>
.minimized .v-input--selection-controls__ripple {
  margin: 0;
  padding: 0;
  height: 24px;
  width: 24px;
  left: 0;
  top: 0;
}
</style>
