<template>
  <div align="center">
    <v-row class="pb-4 container">
      <v-col sm="4" cols="12">
        <v-card>
          <v-img src="@/assets/walk_in_services.jpg" height="200px" />

          <v-card-title
            class="justify-center selectCursor primary--text"
            @click="selectService(MEDICAL_WALK_IN)"
          >
            Walk-in
          </v-card-title>

          <v-card-actions>
            <v-spacer />

            <v-btn text x-small class="caption" @click="show1 = !show1">
              more
              <v-icon>
                {{ show1 ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show1">
              <v-divider />

              <v-card-text>
                A walk-in clinic accepts patients on a walk-in basis and with no
                appointment required. A number of healthcare service providers
                fall under the walk-in clinic umbrella including urgent care
                centers. Walk-in clinics offer the advantages of being
                accessible and often inexpensive.
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>

      <v-col sm="4" cols="12">
        <v-card>
          <v-img src="@/assets/home_services.jpg" height="200px" />

          <v-card-title
            class="justify-center selectCursor primary--text"
            @click="selectService(MEDICAL_HOME_SERVICE)"
          >
            Home Service
          </v-card-title>
          <v-card-actions>
            <v-spacer />

            <v-btn text x-small class="caption" @click="show2 = !show2">
              more
              <v-icon>
                {{ show2 ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show2">
              <v-divider />

              <v-card-text>
                Offers is a health care service utilizing the expertise and
                clinical skills of a multidisciplinary team of physicians,
                registered nurses, physical therapists,
                nutritionists/dieticians, medical technologists and trained
                caregivers visits directly to your home.
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>

      <v-col sm="4" cols="12">
        <v-card>
          <v-img src="@/assets/offsite_services.jpg" height="200px" />

          <v-card-title
            class="justify-center selectCursor primary--text"
            @click="selectService(MEDICAL_OFFSITE)"
          >
            Offsite
          </v-card-title>

          <v-card-actions>
            <v-spacer />

            <v-btn class="caption" text x-small @click="show3 = !show3">
              more
              <v-icon>
                {{ show3 ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show3">
              <v-divider />

              <v-card-text>
                Offers medical care that is provided at a location not owned or
                operated by the state.
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogPolicy">
      <AgreementPolicy :selectedService="selectedService" @next="next" />
    </v-dialog>
  </div>
</template>

<script>
import {
  MEDICAL_WALK_IN,
  MEDICAL_HOME_SERVICE,
  MEDICAL_OFFSITE
} from "@/common/utilities/appointments";
import { NS_MEMBERINFO as NAMESPACE } from "@/store";

import AgreementPolicy from "../components/AgreementPolicy.vue";

export default {
  name: "SelectServices",

  components: {
    AgreementPolicy
  },

  data: () => ({
    dialogPolicy: false,
    selectedService: "",
    show1: false,
    show2: false,
    show3: false,

    MEDICAL_WALK_IN: MEDICAL_WALK_IN,
    MEDICAL_HOME_SERVICE: MEDICAL_HOME_SERVICE,
    MEDICAL_OFFSITE: MEDICAL_OFFSITE
  }),

  methods: {
    next() {
      this.$store.commit(
        `${NAMESPACE}/setMedicalService`,
        this.selectedService
      );
      this.$emit("next");
      this.dialogPolicy = false;
    },

    selectService(service) {
      this.dialogPolicy = true;
      this.selectedService = service;
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 900px;
}

.selectCursor {
  cursor: pointer;
}
</style>
