<template>
  <div>
    <div v-show="loading" class="my-10 text-center">
      <CircularProgress />
    </div>

    <v-row v-show="!loading">
      <v-col :cols="entryRowCol0" class="py-0 select-container">
        <v-text-field
          label="House No., Street, Village"
          v-model="addressLine1"
          :readonly="disabled"
        />
      </v-col>

      <v-col :cols="entryRowCol" class="py-0 select-container">
        <v-autocomplete
          v-model="province"
          :items="provinces"
          :search-input.sync="searchProvince"
          hide-no-data
          hide-details
          label="Provinces"
          item-text="label"
          item-value="details"
          @change="onProvinceChange"
        />
      </v-col>

      <v-col :cols="entryRowCol" class="py-0 select-container">
        <v-autocomplete
          v-model="municipality"
          :items="municipalities"
          :search-input.sync="searchMunicipality"
          hide-no-data
          hide-details
          label="Municipality / City"
          item-text="label"
          item-value="value"
          @change="onMunicipalityChange"
          :disabled="!hasSelectedProvince"
          :readonly="disabled"
        />
      </v-col>

      <v-col :cols="entryRowCol" class="py-0 select-container">
        <v-autocomplete
          v-model="barangay"
          :items="barangays"
          :search-input.sync="searchBarangay"
          hide-no-data
          hide-details
          label="Barangay"
          @change="onBarangayChange"
          :disabled="!hasSelectedMunicipality"
          :readonly="disabled"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { setWidth } from "@/utilities/display";

import CircularProgress from "@/components/CircularProgress.vue";

export default {
  name: "Address",

  components: {
    CircularProgress
  },

  props: {
    NAMESPACE: String,
    NS_DATA: String,

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    hasSelectedMunicipality: false,
    hasSelectedProvince: false,

    municipality: "",
    province: "",

    loading: true,
    searchProvince: null,
    searchMunicipality: null,
    searchBarangay: null
  }),

  mounted() {
    this.onMount();
  },

  methods: {
    // override values from parent
    loadAddress(address) {
      this.onProvinceChange({
        region: address.region,
        province: address.province,
        municipality: address.municipality,
        barangay: "",
        addressLine1: ""
      });

      this.onMunicipalityChange(address.municipality);

      this.barangay = address.barangay;
      this.addressLine1 = address.addressLine1;
    },

    onBarangayChange(value) {
      this.$store.commit(`${this.NS_DATA}/setAddressBarangay`, value);
      this.$emit("onChange", this.barangay);
    },

    async onMount() {
      if (this.NAMESPACE && this.NS_DATA) {
        await this.$store.dispatch(`${this.NS_DATA}/load`);
        await this.$store.dispatch(`${this.NAMESPACE}/load`);

        this.province = this.$store.getters[
          `${this.NS_DATA}/getAddressProvince`
        ];

        this.municipality = this.$store.getters[
          `${this.NS_DATA}/getAddressMunicipality`
        ];

        this.loading = false;

        // delay to force this trigger to go after dependent components have
        // mounted
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          this.$emit("onMount");
        });
      }
    },

    onMunicipalityChange(value) {
      this.barangay = "";

      this.hasSelectedMunicipality = value ? true : false;

      this.$store.commit(`${this.NAMESPACE}/setSelectedMunicipality`, value);
      this.$store.commit(`${this.NS_DATA}/setAddressMunicipality`, value);
      this.$emit("onChange", this.municipality);
    },

    onProvinceChange(value) {
      this.municipality = "";
      this.barangay = "";

      this.hasSelectedMunicipality = false;
      this.hasSelectedProvince = value.region && value.province ? true : false;

      this.$store.commit(`${this.NAMESPACE}/setSelectedProvince`, value);
      this.$store.commit(`${this.NS_DATA}/setAddressRegion`, value.region);
      this.$store.commit(`${this.NS_DATA}/setAddressProvince`, value.province);
      this.$store.commit(`${this.NS_DATA}/setAddressMunicipality`, "");
      this.$store.commit(`${this.NS_DATA}/setAddressBarangay`, "");
    }
  },

  computed: {
    addressLine1: {
      get() {
        return this.$store.getters[`${this.NS_DATA}/getAddressLine1`];
      },
      set(value) {
        this.$store.commit(`${this.NS_DATA}/setAddressLine1`, value);
        this.$emit("onChange");
      }
    },

    barangay: {
      get() {
        return this.$store.getters[`${this.NS_DATA}/getAddressBarangay`];
      },
      set(value) {
        this.$store.commit(`${this.NS_DATA}/setAddressBarangay`, value);
        this.$emit("onChange");
      }
    },

    barangays() {
      return this.$store.getters[`${this.NAMESPACE}/getBarangays`];
    },

    entryRowCol() {
      return setWidth(this.$vuetify.breakpoint.width, 4, 6, 6, 12, 12);
    },

    entryRowCol0() {
      return setWidth(this.$vuetify.breakpoint.width, 12, 12, 12, 12, 12);
    },

    municipalities() {
      return this.$store.getters[`${this.NAMESPACE}/getMunicipalities`];
    },

    provinces() {
      return this.$store.getters[`${this.NAMESPACE}/getProvinces`];
    }
  },

  watch: {
    NAMESPACE() {
      this.onMount();
    },

    NS_DATA() {
      this.onMount();
    }
  }
};
</script>
