<template>
  <div>
    <BackButton
      @click="$emit('back')"
      :class="{ 'ma-2': $vuetify.breakpoint.mobile }"
    />

    <SelectPreferredDateTime
      :initialValue="initialValue"
      @onSelect="onSelect"
      class="mt-7"
    />

    <NextButton
      @click="onNext"
      :disabled="isNextDisabled"
      :class="{ 'ma-2': $vuetify.breakpoint.mobile }"
    />
  </div>
</template>

<script>
import { SCHEDULE_TYPE_SPECIFIC_TIME } from "@/common/utilities/appointments/schedule_type";

import BackButton from "@/components/Controls/BackButton.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import SelectPreferredDateTime from "@/components/Laboratory/SelectPreferredDateTime.vue";

export default {
  name: "ScheduleTab",

  components: {
    BackButton,
    NextButton,
    SelectPreferredDateTime
  },

  data: () => ({
    isNextDisabled: true,

    initialValue: {},
    preferredDateTime: {}
  }),

  mounted() {
    this.initialValue = {
      ...this.$store.getters["lab/appointment/getSchedule"]
    };
    this.onSelect(this.initialValue);
  },

  methods: {
    onNext() {
      this.$store.commit(
        "lab/appointment/SET_SCHEDULE",
        this.preferredDateTime,
        { root: true }
      );
      this.$emit("next");
    },

    onSelect(preferredDateTime) {
      this.preferredDateTime = { ...preferredDateTime };

      if (this.preferredDateTime && this.preferredDateTime.type) {
        if (SCHEDULE_TYPE_SPECIFIC_TIME === this.preferredDateTime.type) {
          this.isNextDisabled =
            !this.preferredDateTime.date ||
            !this.preferredDateTime.start ||
            !this.preferredDateTime.end;
        } else {
          this.isNextDisabled = this.preferredDateTime.date ? false : true;
        }
      } else {
        this.isNextDisabled = true;
      }
    }
  }
};
</script>
