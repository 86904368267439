import "core-js/stable";
import "regenerator-runtime/runtime";
import "mutationobserver-shim";
import "firebase/firestore";
import "firebase/auth";
import "./plugins/vuetify-money.js";
import "./registerServiceWorker";

import {
  GetPathToLoadAfterLoginOrDefault,
  InitializeApp,
  SetCredentialFailed,
  setCredentialLinkFailed,
  setPathToLoadAfterLogin,
  setRegistrationDetails
} from "@/utilities/session";
import router, { ROUTE_DASHBOARD, routeTo } from "./router";

import App from "./App.vue";
import { LOCALE_TL } from "@/common/utilities/locale";
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import VueYoutube from "vue-youtube";
import firebase from "firebase/app";
import i18n from "./plugins/i18n";
import { setDB } from "./common/store";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
Vue.use(VueScrollTo);
Vue.use(VueYoutube);

firebase.initializeApp({
  apiKey: "AIzaSyD6lB8DibYrb5pR8K5P-2jC-05JNeGN-NI",
  authDomain: "medisure-275117.firebaseapp.com",
  databaseURL: "https://medisure-275117.firebaseio.com",
  projectId: "medisure-275117",
  storageBucket: "medisure-275117.appspot.com",
  messagingSenderId: "449387984097",
  appId: "1:449387984097:web:70e2cf37d24bc150be73a0",
  measurementId: "G-HWP7L6P2NQ"
});

Vue.prototype.$messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export const db = firebase.firestore();
setDB(db);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");

function userLoggedIn(user) {
  store.commit("setUser", user);
  InitializeApp();

  store.dispatch("updateRegistrationDetails").then(() => {
    store.dispatch("loadProfile").then(profile => {
      if (profile.locale) {
        i18n.locale = profile.locale || LOCALE_TL;
      }
    });

    // user just logged in
    routeTo(GetPathToLoadAfterLoginOrDefault());
  });
}

export function onAuthStateChanged(user) {
  if (user) {
    // User logged in
    userLoggedIn(user);
    window.sessionStorage.setItem("medisureCurrentUserEmail", user.email);
  } else {
    // User logged out
    setPathToLoadAfterLogin();
    store.commit("setUser", user);
    routeTo(ROUTE_DASHBOARD);
  }
}

firebase
  .auth()
  .getRedirectResult()
  .then(result => {
    if (result.credential) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      //var token = result.credential.accessToken;
      // ...
    }
    const firstName = result.user.displayName;

    setRegistrationDetails(
      firstName.split(" ")[0],
      "",
      "",
      result.user.email,
      LOCALE_TL
    );
    // The signed-in user info.
    onAuthStateChanged(result.user);
    window.sessionStorage.setItem(
      "medisureCurrentUserEmail",
      result.user.email
    );
    window.sessionStorage.setItem("register_uid", result.user.uid);
  })
  .catch(error => {
    if ("auth/account-exists-with-different-credential" === error.code) {
      SetCredentialFailed(true);
    } else if ("auth/credential-already-in-use" === error.code) {
      setCredentialLinkFailed(true);
    }
  });
