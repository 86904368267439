<template>
  <v-card elevation="0">
    <v-container class="pt-0">
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="false"
        @submit.prevent="checkOut"
      >
        <v-row style="margin-right: 0px;">
          <v-col :cols="12" md="12" sm="12" style="background-color: #ECEFF1;">
            <SummaryBHCCheckout
              v-if="payref == 'Laboratory'"
              :bhcdetails="bhcdetails"
              :clinicType="this.bhcdetails.clinicType"
              :total="total"
            />

            <p class="headline primary--text">Order Summary</p>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Order Subtotal</td>
                    <td class="price-entry">
                      <PesoAmount :amount="Math.round(sum)" />
                    </td>
                  </tr>
                  <tr v-if="payref != 'Laboratory'">
                    <td>Shipping Charges</td>
                    <td class="price-entry">
                      <PesoAmount :amount="shippingfee" />
                    </td>
                  </tr>
                  <tr>
                    <td>Purchase Total</td>
                    <td class="price-entry">
                      <strong><PesoAmount :amount="total"/></strong>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-radio-group v-model="radioGroup">
              <div class="mt-10">Promos</div>

              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-italic">
                        <v-radio class="floaterl" value="Senior Citizen" />
                        Senior Citizen -20%
                      </td>
                      <td class="price-entry font-italic">
                        <PesoAmount
                          class="text-decoration-line-through"
                          :amount="discountValue1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="font-italic">
                        <v-radio class="floaterl" value="PWD" />
                        PWD -20%
                      </td>
                      <td class="price-entry font-italic">
                        <PesoAmount
                          class="text-decoration-line-through"
                          :amount="discountValue1"
                        />
                      </td>
                    </tr>
                    <p
                      class="text-center mb-0"
                      v-if="!validCode"
                      style="color: red; font-size: smaller;"
                    >
                      {{ promoCode }} is not a valid promo code.
                    </p>
                    <p
                      v-if="!validCount && validCode"
                      class="text-center pt-4 mb-0"
                      style="color: red; font-size: smaller;"
                    >
                      No Promo Code left
                    </p>
                    <p
                      v-if="!promoStarted && validCode"
                      class="text-center pt-4 mb-0"
                      style="color: red; font-size: smaller;"
                    >
                      Not Yet Started
                    </p>
                    <p
                      v-if="promoEnded && validCode"
                      class="text-center pt-4 mb-0"
                      style="color: red; font-size: smaller;"
                    >
                      Expired Coupon
                    </p>
                    <tr>
                      <td class="font-italic">
                        <v-radio class="floaterl pt-5" value="promoCode">
                        </v-radio>
                        <v-text-field
                          class="pt-0"
                          label="Type promo code here"
                          v-model="promoCode"
                        />
                      </td>
                      <td class="price-entry font-italic">
                        <PesoAmount
                          class="text-decoration-line-through"
                          :amount="discountValue2"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Final Total</td>
                      <td class="price-entry">
                        <strong><PesoAmount :amount="finTotal"/></strong>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-radio-group>

            <div class="text-center pt-5">
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="validate"
                type="submit"
              >
                Proceed to Payment
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog v-model="dialog" width="800">
      <v-card class="pa-4">
        <PayeeDetails
          ref="payeeDetails"
          :initialModeOfPayment="initialModeOfPayment"
          :key="initialModeOfPayment"
          :payref="payref"
          @address="setAddress"
          @contact="setContact"
          @courier="setCourier"
          @isValid="setIsPayeeDetailsValid"
          @modeOfPayment="setModeOfPayment"
          @name="setName"
        />
        <MOPSummary
          :modeofpayment="modeofpayment"
          :balance="balance"
          :remainingbalance="remainingbalance"
          :finTotal="finTotal"
          @amountTyping="setAmount"
          @referenceNoTyping="setReferenceNo"
          @dateToPayTyping="setDateToPay"
          @isValid="setisValidMOP"
        />
        <div class="text-center pt-5">
          <v-btn
            v-if="remainingbalance < 0 && modeofpayment === 'Bantay Kard'"
            color="error"
            class="mr-4"
          >
            Not Enough Balance
          </v-btn>
          <v-btn
            v-else
            :disabled="!IsPayeeDetailsValid || !isValidMOP"
            color="success"
            class="mr-4"
            @click="checkOutPayment"
          >
            Checkout
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirm" @on-close="onConfirmationClose" />
  </v-card>
</template>

<script>
import { cancelAppointment } from "@/store/diagnostic_attendee";
import {
  checkOutPayment,
  loadDiscount,
  sendMessage,
  setDelivery
} from "@/store";
import { displayAmount } from "@/common/utilities/number";
import { getCurrentUserUid } from "@/common/store";
import { getDateTime, timeStampToDate } from "@/common/utilities/date";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import MOPSummary from "@/components/Payment/MOPSummary.vue";
import PayeeDetails from "@/components/Payment/PayeeDetails.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";
import SummaryBHCCheckout from "@/components/Laboratory/SummaryBHCCheckout.vue";

export default {
  name: "Checkout",

  components: {
    ConfirmDialog,
    PayeeDetails,
    MOPSummary,
    SummaryBHCCheckout,
    PesoAmount
  },

  props: {
    payref: String,
    shippingfee: Number,
    sum: Number,
    total: Number,
    bhcdetails: Object,
    checkoutdocid: String,
    fullqr: String
  },

  data: () => ({
    radioGroup: "",
    balance: 0,
    valid: false,
    name: "",
    contact: "",
    address: "",
    courier: "",
    modeofpayment: "",
    IsPayeeDetailsValid: false,
    isValidMOP: false,

    referenceNo: "",
    amount: 0,
    dateToPay: "",
    finTotal: 0,
    promoCode: "",
    discountValue1: 0,
    discountValue2: 0,
    validCount: true,
    validCode: false,
    validDate: false,

    promoStarted: false,
    promoEnded: false,
    promoExpired: false,

    initialModeOfPayment: "Bantay Kard",
    dialog: false
  }),

  mounted() {
    this.loadBantayCard();
    if (!this.radioGroup) {
      this.finTotal = this.total;
    }
  },

  watch: {
    promoCode() {
      if (this.promoCode.length > 4) {
        this.getDiscountValue();
      } else {
        this.discountValue2 = 0;
        this.finTotal = this.total;
      }
    },

    radioGroup() {
      this.finTotal = 0;

      switch (this.radioGroup) {
        case "":
        case "promoCode":
          this.discountValue2 = 0;
          this.finTotal = this.total;
          break;

        case "PWD":
        case "Senior Citizen": {
          let promoCodeValue = 0.2;
          this.discountValue1 = this.total * promoCodeValue;
          this.finTotal = Number(this.total - this.discountValue1);
          break;
        }

        default:
          this.getDiscountValue();
          break;
      }
    },

    total() {
      if (!this.radioGroup) {
        this.finTotal = this.total;
      }
    }
  },

  computed: {
    proceedProcessing() {
      return ["Bantay Kard", "Cash on Delivery"].includes(this.modeofpayment);
    },

    remainingbalance() {
      return Number(this.balance) - Number(this.finTotal);
    },

    start() {
      return this.bhcdetails && this.bhcdetails.start
        ? getDateTime(this.bhcdetails.start)
        : "";
    }
  },

  methods: {
    checkOutPayment() {
      let comment = "";
      let trackingNumber = "";
      let status = "";

      if (this.proceedProcessing) {
        comment = "Your order is being prepared.";
        trackingNumber = "medisure is processing your order";
        status = "processing";
        this.sendMessage();
      } else {
        comment = "Your order is for payment verification";
        trackingNumber = "for payment verification";
        status = "payment verification";
      }

      let details = {
        checkoutdocid: this.checkoutdocid,
        name: this.name,
        address: this.address,
        contact: this.contact,
        courier: this.courier,
        paidThru: this.modeofpayment,

        amount: this.amount,
        referenceNo: this.referenceNo,
        dateToPay: this.dateToPay,
        status: status,
        trackingNumber: trackingNumber
      };
      checkOutPayment(details);

      let delivery = {
        timestamp: new Date(),
        courier: this.courier,
        location: "test",
        comment: comment,
        orderid: this.checkoutdocid,
        trackingNumber: trackingNumber,
        mop: this.modeofpayment,
        status: status
      };
      setDelivery(delivery);

      this.$emit("openSummary", true);
    },

    sendMessage() {
      sendMessage({
        name: "Admin AI-Store",
        subject: "e-Tindahan Purchase",
        body: this.getPurchaseEmailBody(),
        receiver: getCurrentUserUid(),
        sender: "Admin", //Sender AdminSide
        status: "unread",
        recieveqr: this.fullqr,
        timestamp: new Date()
      });
    },

    getPurchaseEmailBody() {
      let firstname = this.name.length > 0 ? ` ${this.name.length}` : "";

      return (
        // eslint-disable-next-line prettier/prettier
        `Hi ${firstname},

Your order is being prepared. Please expect delivery to ${this.address.trim()} within a week.

Order Details:
    Order ID: ${this.checkoutdocid}
    Purchase Amount: ₱ ${displayAmount(Number(this.finTotal))}
    Mode of Payment: ${this.modeofpayment}`
      );
    },

    retainTotal() {
      this.finTotal = Number(this.total);
    },

    processDiscountValue(docs) {
      this.validCode = true;

      for (let key of docs) {
        if (key.data.count == 0) {
          this.validCount = false;
          this.retainTotal();
          return;
        }

        this.validCount = true;

        let startDate = timeStampToDate(key.data.effectiveDate.from);
        let endDate = timeStampToDate(key.data.effectiveDate.to);
        let today = new Date();

        this.promoStarted = startDate <= today;
        this.promoEnded = endDate <= today;
        if (!this.promoStarted || this.promoEnded) {
          this.retainTotal();
          return;
        }

        let discountType = key.data.discountType;
        if (discountType === "percentage") {
          let percentage = key.data.value / 100;
          this.discountValue2 = this.total * percentage;
        } else {
          this.discountValue2 = Number(key.data.value);
        }

        if (this.radioGroup === "promoCode") {
          this.finTotal = Number(this.total - this.discountValue2);
        }
      }
    },

    getDiscountValue() {
      this.discountValue2 = 0;
      loadDiscount({ promoCode: this.promoCode }).then(docs => {
        if (docs.length == 0) {
          this.validCode = false;
          this.retainTotal();
        } else {
          this.processDiscountValue(docs);
        }
      });
    },

    cancelTransaction() {
      cancelAppointment(this.bhcdetails);
      this.showConfirmationMessageCancel();
    },

    getDiscountVal() {
      let discounts = ["PWD", "Senior Citizen"];
      return discounts.includes(this.radioGroup)
        ? this.discountValue1
        : this.discountValue2;
    },

    checkOut() {
      if (this.checkoutdocid != "") {
        this.dialog = true; // already initiated must reopen dialog
        return;
      }

      if (!this.bhcdetails) {
        this.$emit("check-out", {
          // these are items from catalog
          subtotal: Math.round(this.sum),
          total: this.finTotal,
          promoType: this.radioGroup,
          promoCode: this.promoCode,
          discountValue: this.getDiscountVal(),
          soldTo: getCurrentUserUid(),
          status: "loadingid",
          trackingNumber: "for payment",
          shippingfee: this.shippingfee,
          remainingbalance: this.remainingbalance,
          isBantayKardPayment: this.isBantayKardPayment
        });
      } else {
        this.$emit("check-out", {
          // these are items from catalog
          name: this.name,
          contact: this.contact,
          address: this.address,
          paidThru: this.modeofpayment,
          referenceNo: this.referenceNo,

          total: this.finTotal,
          soldTo: getCurrentUserUid(),
          remainingbalance: this.remainingbalance,
          isBantayKardPayment: this.isBantayKardPayment
        });
      }
      this.dialog = true;
    },

    loadBantayCard() {
      this.balance = this.$store.state.profile.bantayKardBalance;
    },

    onConfirmationClose() {
      this.$emit("on-confirm");
      this.$emit("dialog-close", false);
    },

    reService(data) {
      return data.clinicType === "Home Service" ? "" : data.service.name;
    },

    setName(name) {
      this.name = name;
    },

    setAddress(address) {
      this.address = address;
    },

    setContact(contact) {
      this.contact = contact;
    },

    setCourier(courier) {
      this.courier = courier;
    },

    setModeOfPayment(modeOfPayment) {
      this.modeofpayment = modeOfPayment;
    },

    setIsPayeeDetailsValid(isValid) {
      this.IsPayeeDetailsValid = isValid;
    },

    setAmount(val) {
      this.amount = val;
    },

    setReferenceNo(val) {
      this.referenceNo = val;
    },

    setDateToPay(val) {
      this.dateToPay = val;
    },

    setisValidMOP(isValid) {
      this.isValidMOP = isValid;
    },

    showConfirmationMessageCancel() {
      this.$refs.confirm.open(
        "Kumpirmasyon",
        `Ang naturang transaction ay na cancel`,
        { noconfirm: true }
      );
    },

    validate() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style scoped>
.price-entry {
  text-align: right !important;
  width: 110px;
}
.floaterl {
  float: left;
}
</style>
