import MedicalHistory from "@/MedicalServices/views/MedicalHistory.vue";

export const ROUTE_WALKIN_MEDICAL_HISTORY = "/medicalServices/medicalHistory";

export function routes(guard) {
  return [
    {
      path: ROUTE_WALKIN_MEDICAL_HISTORY,
      name: "Medical History",
      component: MedicalHistory,
      beforeEnter: guard
    }
  ];
}
