export function getPromo(cart) {
  if (cart.isPromotionalPriceApplied && cart.discount > 0) {
    return `(${cart.discount}% Off)`;
  } else if (!cart.isPWDSCPriceApplied || cart.discount <= 0) {
    return "";
  }

  return cart.pwdSc ? "(PWD/SC Price)" : `(${cart.discount}% Off)`;
}

export function isExpired(cart) {
  let now = new Date();
  let vdiscountUntil = new Date(cart.discountUntil.seconds * 1000);
  return vdiscountUntil >= now || cart.isPWDSCPriceApplied;
}

export function isNotExpiredPromo(discountUntil) {
  return discountUntil >= new Date();
}
