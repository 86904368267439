<template>
  <v-app id="app">
    <Timeout id="timeout1" class="text-center" />

    <NavigationDrawer
      class="no-print"
      :drawer.sync="drawer"
      v-if="isLoggedIn"
    />

    <AppBarMenu v-show="isdaRoute" class="no-print" :drawer.sync="drawer" />

    <v-main :class="{ 'pa-0': !isdaRoute }">
      <v-container fluid class="pa-0">
        <router-view :key="$route.fullPath" />
      </v-container>

      <div class="footerpadding" />

      <AppFooter class="no-print" />
    </v-main>

    <NotificationBox :getNotification="getNotification" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppBarMenu from "@/components/AppBarMenu.vue";
import AppFooter from "@/components/AppFooter.vue";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import NotificationBox from "@/components/NotificationBox.vue";
import Timeout from "@/components/Timeout.vue";

export default {
  name: "App",

  components: {
    AppBarMenu,
    AppFooter,
    NavigationDrawer,
    NotificationBox,
    Timeout
  },

  data: () => ({
    drawer: null
  }),

  computed: {
    ...mapGetters(["getMinutesIdle", "getNotification", "isLoggedIn"]),

    isdaRoute() {
      return this.$route.name != "ISDA Foundation";
    }
  },

  beforeUpdate() {
    if (this.isLoggedIn) {
      this.loadCart();
    }
  },

  mounted() {
    this.monitorMovement();
  },

  methods: {
    ...mapActions(["loadCart", "resetSecondsIdle", "shouldLogout"]),

    monitorMovement() {
      window.onmousemove = () => {
        this.resetSecondsIdle();
      };

      window.onscroll = () => {
        this.resetSecondsIdle();
      };
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-grey {
  background: #f0f0f0;
}

.main-container {
  max-width: 1000px;
}

#timeout1 {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  @page {
    size: auto;
    margin: 0mm;
    margin-top: -15mm;
  }
}
</style>
