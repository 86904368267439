<template>
  <v-container>

    <v-row class="pb-4">
      <Header />
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="pt-0">
        <v-row>
          <Content />
        </v-row>
        <v-divider class="pb-6"></v-divider>
      </v-col>
      <v-col cols="12" sm="6" class="pt-0">
        <DonorForm :project="selectedProject" />
      </v-col>
    </v-row>
    <Footer />
  </v-container>
</template>

<script>
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { db } from "@/main";
import Content from "@/ISDA/views/Content.vue";
import DonorForm from "@/ISDA/views/DonorForm.vue";
// import RecentDonation from "@/ISDA/views/RecentDonation.vue";
import Header from "@/ISDA/components/Header.vue";
import Footer from "@/ISDA/components/Footer.vue";

export default {
  name: "ISDAMain",
  components: {
    Content,
    Header,
    Footer,
    DonorForm
  },
  data: () => ({
    projects: [],
    selectedProject: null,
    donateAmount: "#donateAmount"
  }),
  methods: {
    setSelectedProject(project) {
      this.selectedProject = project;
    }
  },
  mounted() {
    const loadProjects = async () => {
      const snapshot = await db.collection("isdaProjects").get();
      snapshot.forEach(doc => {
        this.selectedProject = {
          id: doc.id,
          ...doc.data()
        };
      });
    };

    loadProjects();
  }
};
</script>
<style scoped>
.wrap {
  margin: auto;
}
.select-container >>> input {
  text-align: center;
}
.colored {
  color: #283d19;
}

p {
  font-family: "Avenir", Helvetica, sans-serif;
}

.fontLarge {
  font-size: 60px;
}
.fontMedium {
  font-size: 22px;
}
</style>
