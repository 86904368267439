import Main from "@/Offsite2022/views/Main.vue";
import MedicalHistory from "@/Offsite2022/views/MedicalHistory.vue";

// Ignore SonarLint warnings for lines below
//import MobileVanSignup from "@/components/Laboratory/BhcAppointment/MobileVanSignup.vue";

export const ROUTE_OFFSITE_MAIN = "/offsite";
export const ROUTE_OFFSITE_MEDICAL_HISTORY = "/offsite/medicalHistory";

export function routes(guard) {
  return [
    /*
    {
      path: "/offsite",
      name: "Offsite",
      component: MobileVanSignup,
      beforeEnter: guard // Using guard before entering the route
    }
    */

    {
      path: ROUTE_OFFSITE_MEDICAL_HISTORY,
      name: "Offsite - Medical History",
      component: MedicalHistory,
      beforeEnter: guard // Using guard before entering the route
    },

    {
      path: ROUTE_OFFSITE_MAIN,
      name: "Offsite",
      component: Main,
      beforeEnter: guard // Using guard before entering the route
    }
  ];
}
