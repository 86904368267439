import { DB } from "@/common/store";

import { db } from "@/main";
import { generateUserCode } from "@/common/utilities/user_code";

function addUserCode(userId, userData, userCode) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PROFILES)
      .doc(userId)
      .set({ userCode: userCode }, { merge: true })
      .then(() => resolve({ ...userData, userCode: userCode }))
      .catch(err => reject(err));
  });
}

function addUniqueUserCode(userId, userData) {
  return new Promise((resolve, reject) => {
    let userCode = generateUserCode();

    db.collection(DB.PROFILES)
      .where("userCode", "==", userCode)
      .limit(1)
      .get()
      .then(snapshot => {
        return snapshot.size > 0
          ? addUniqueUserCode(userId, userData)
          : addUserCode(userId, userData, userCode);
      })
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
}

export function ensureUserHasUserCode(userId, userData) {
  return new Promise(resolve => {
    if (userData.userCode) {
      resolve(userData);
    } else {
      addUniqueUserCode(userId, userData).then(data => resolve(data));
    }
  });
}
