function emptyTrackCheckout() {
  return {
    soldOn: "",
    trackingNumber: "",
    sendqrid: "",
    receiveqrid: "",
    promoCode: "",
    promoType: "",
    soldTo: "",
    status: "",
    shippingfee: 0,
    discountValue: 0,
    subtotal: 0,
    total: 0
  };
}

export const tindahan = {
  state: () => ({
    trackingId: "",
    trackCheckout: emptyTrackCheckout(),
    checkoutdocid: "",
    orderId: "",
    cartTotal: 0,
    cartItem: [],
    paymentMade: 0,
    balance: 0,
    checkoutDetails: {},
    paymentStatus: "",
    refundDetails: {},
    cartDetails: [],
    totalVerifiedPayments: 0
  }),

  getters: {
    getTrackingNumber(state) {
      return state.trackCheckout.trackingNumber;
    },

    getStatus(state) {
      return state.trackCheckout.status;
    },

    getTotal(state) {
      return state.trackCheckout.total;
    },

    getCheckoutDocId(state) {
      return state.checkoutdocid;
    },

    getTrackingId(state) {
      return state.trackingId;
    },

    getOrderId(state) {
      return state.orderId;
    },

    getCartItem(state) {
      return state.cartItem;
    },

    getCartTotal(state) {
      return state.cartTotal;
    },

    getPaymentMade(state) {
      return state.paymentMade;
    },

    getBalance(state) {
      return state.balance;
    },

    getCheckoutDetails(state) {
      return state.checkoutDetails;
    },

    getpaymentStatus(state) {
      return state.paymentStatus;
    },

    getRefundDetails(state) {
      return state.refundDetails;
    },

    getCartDetails(state) {
      return state.cartDetails;
    },

    getTotalVerifiedPayments(state) {
      return state.totalVerifiedPayments;
    }
  },

  mutations: {
    RESET_STATE(state) {
      state.trackingId = "";
      state.trackCheckout = emptyTrackCheckout();
      state.checkoutdocid = "";
      state.cartItem = [];
    },

    SET_CART_DETAILS(state, data) {
      state.cartDetails = data;
    },

    SET_ORDER_ID(state, data) {
      state.orderId = data;
    },

    SET_REFUND_DETAILS(state, data) {
      state.refundDetails = data;
    },

    setTrackCheckout(state, data) {
      state.trackCheckout = { ...data };
      state.checkoutdocid = data.id;
    },

    setTrackingId(state, data) {
      state.trackingId = data;
    },

    setCartItem(state, data) {
      state.cartItem = data;
    },

    setPaymentMade(state, data) {
      state.paymentMade = data;
    },

    setpaymentStatus(state, data) {
      state.paymentStatus = data;
    },

    setCheckoutDetails(state, data) {
      state.checkoutDetails = data;
    },

    setBalance(state, data) {
      state.balance = data;
    },

    setCartTotal(state, data) {
      state.cartTotal = data;
    },

    setTotalVerifiedPayments(state, data) {
      state.totalVerifiedPayments = data;
    }
  },

  actions: {
    resetContent({ commit }) {
      return new Promise(resolve => {
        commit("RESET_STATE");
        resolve();
      });
    },

    setCartDetails({ commit }, data) {
      return new Promise(resolve => {
        commit("SET_CART_DETAILS", data);
        resolve(data);
      });
    },

    setOrderId({ commit }, data) {
      return new Promise(resolve => {
        commit("SET_ORDER_ID", data);
        resolve(data);
      });
    },

    setRefundDetails({ commit }, data) {
      return new Promise(resolve => {
        commit("SET_REFUND_DETAILS", data);
        resolve(data);
      });
    }
  }
};
