<template>
  <div>
    <h4 class="text-center primary--text pt-2">
      {{ "Packages" }}
    </h4>

    <v-layout row wrap class="pa-3">
      <div
        class="col-md-6 col-sm-6 col-xs-12 pa-1"
        v-for="(groupList, t) in groupList"
        :key="t"
      >
        <v-hover v-slot:default="{ hover }" open-delay="100">
          <v-card :elevation="hover ? 16 : 2" class="px-6 py-4" height="100%">
            <div class="text-center">
              <b class="primary--text">
                <v-checkbox
                  v-model="radioGroup"
                  :value="groupList.data"
                  :label="
                    `${groupList.data.testname} (₱${groupList.data.price})`
                  "
                ></v-checkbox>
              </b>
            </div>
            <v-divider class="pa-2"></v-divider>
            <div v-for="(tests, t) in groupList.data.tests" :key="t">
              {{ t + 1 }}. {{ tests }}
            </div>
            <div>Description: {{ groupList.data.description }}</div>
          </v-card>
        </v-hover>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { loadPackages } from "@/store";

export default {
  name: "AvailableTest",

  props: {
    clinicType: String,
    packageSelected: Array
  },
  data: () => ({
    groupList: [],
    radioGroup: []
  }),

  watch: {
    clinicType() {
      this.loadTests();
    },
    radioGroup() {
      this.$emit("selected-package", this.radioGroup);
    },
    packageSelected() {
      this.radioGroup = this.packageSelected;
    }
  },
  methods: {
    loadTests() {
      this.groupList = [];
      loadPackages(this.clinicType).then(docs => {
        this.groupList = docs;
      });
    }
  }
};
</script>

<style scoped>
.floaterl {
  float: left;
}
</style>
