<template>
  <div class="mt-5" style="text-align: right;">
    <v-btn color="primary" @click="recordActivityAndEmit" :disabled="disabled">
      {{ text }} <v-icon>mdi-step-forward</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "NextButton",

  props: {
    disabled: Boolean,

    text: {
      type: String,
      default: "Next"
    }
  },

  methods: {
    recordActivityAndEmit() {
      this.$store.dispatch("resetSecondsIdle").then(() => {
        this.$emit("click");
      });
    }
  }
};
</script>
