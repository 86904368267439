<template>
  <v-container fluid class="storescontainer p-0">
    <ViewProductContainer :toOpen="toOpen" />
  </v-container>
</template>

<script>
import ViewProductContainer from "@/eBotika/components/ViewProductContainer.vue";

export default {
  name: "Botika",

  components: { ViewProductContainer },

  props: {
    openCart: String
  },

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");
  },

  computed: {
    toOpen() {
      return this.openCart === "openCart";
    }
  }
};
</script>

<style scoped>
.storescontainer {
  padding: 0px !important;
}
</style>
