<template>
  <div class="mt-5 mx-5">
    <BackButton
      @click="routeBack()"
      class="mt-5"
      :class="{ 'ma-2': $vuetify.breakpoint.mobile }"
    />

    <v-layout row wrap justify-center>
      <v-flex xs6>
        <v-select
          label="Service"
          v-model="serviceName"
          :items="services"
          item-text="name"
          item-value="name"
        />
      </v-flex>
    </v-layout>

    <div class="ma-5" :class="{ 'ma-2': $vuetify.breakpoint.mobile }">
      <p class="mt-10">
        Ang bahaging ito ng site ay para sa mga empleyadong magsasagawa ng
        kanilang <strong>Annual Physical Exam (APE)</strong> katuwang ang
        Medisure.
      </p>

      <p class="mt-10">
        Upang mapabilis ang proseso sa araw ng APE, mangyaring ipagbigay alam sa
        Medisure ang inyong <strong>Medical History</strong> sa pamamagitan ng
        pagsagot sa mga susunod na katanungan.
      </p>

      <p class="mt-10">
        Para sa mga katanungan, huwag po kayong mag-atubiling mag-email sa
        <a href="mailto:mso.admin@medisureonline.com">
          mso.admin@medisureonline.com
        </a>
        o tumawag sa <a href="tel:+639668769058">(0966) 876 9058</a>.
      </p>
      <p class="mt-10">
        I-click ang "NEXT" para magpatuloy.
      </p>

      <v-dialog v-model="privacyDialog">
        <PrivacyPolicy @closeDialog="privacyDialog = false" />
      </v-dialog>

      <v-row v-show="!hasAgreedToPolicyFromStore" class="pt-4" align="center">
        <v-col class="d-flex" cols="12">
          <v-checkbox v-model="hasAgreedToPolicy" hide-details class="mt-0" />
          <p class="pt-1">
            I acknowledge that I have read and agree to the
            <span class="underline primary--text" @click="privacyDialog = true">
              Terms and Conditions
            </span>
            and
            <span class="underline primary--text" @click="privacyDialog = true">
              Privacy Policy.
            </span>
          </p>
        </v-col>
      </v-row>
    </div>

    <NextButton
      :disabled="!hasAgreedToPolicy || !serviceName"
      @click="next"
      class="mt-15 mb-5"
      :class="{ 'ma-2': $vuetify.breakpoint.mobile }"
    />
  </div>
</template>

<script>
import { ROUTE_OFFSITE_MEDICAL_HISTORY } from "@/router/offsite";

import { getOffsiteService } from "@/store/offsiteService";
import { mapGetters } from "vuex";
import { routeBack, routeTo } from "@/router";

import BackButton from "@/components/Controls/BackButton.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

export default {
  name: "Main",

  components: {
    BackButton,
    NextButton,
    PrivacyPolicy
  },

  data: () => ({
    hasAgreedToPolicy: false,
    serviceName: "",
    services: [],

    privacyDialog: false,
    routeBack: routeBack
  }),

  mounted() {
    this.hasAgreedToPolicy = this.hasAgreedToPolicyFromStore || false;

    getOffsiteService().then(services => {
      this.services = services;
      this.serviceName = services[0];
    });
  },

  computed: {
    ...mapGetters({ hasAgreedToPolicyFromStore: "getHasAgreedToPolicy" })
  },

  methods: {
    async next() {
      this.$store.commit("SET_SELECTED_OFFSITE_SERVICE", this.serviceName);
      if (this.hasAgreedToPolicy !== this.hasAgreedToPolicyFromStore) {
        try {
          await this.$store.dispatch("agreeToPolicy", this.hasAgreedToPolicy);
        } finally {
          // intentionally empty
        }
      }

      routeTo(ROUTE_OFFSITE_MEDICAL_HISTORY);
    }
  }
};
</script>

<style scoped>
.underline {
  text-decoration: underline;
  cursor: pointer;
}
</style>
