<template>
  <div>
    <v-row align="center">
      <v-col cols="12" sm="6" class="mt-2">
        <SelectClinic
          ref="service"
          :clinicType="clinicType"
          :subheader="location"
          @setSelectedService="loadLGUAppointments"
        />
      </v-col>
      <v-col cols="12" sm="6" class="pt-0">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Petsa"
              :disabled="!selectedService"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
            :allowed-dates="allowedDates"
          />
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <div v-if="!selectedService">
          <p class="text-center">{{ noServiceSelected }}</p>
        </div>
        <div v-else-if="!availableDates.length">
          <p class="text-center">{{ noScheduleNotice }}</p>
        </div>
        <div v-else>
          <p>Pumili ng oras</p>
          <v-list shaped>
            <v-list-item-group
              color="primary"
              v-for="(timeSlot, i) in schedules"
              :key="i"
            >
              <v-list-item :disabled="new Date() > timeStamp(timeSlot.slot)">
                <v-list-item-icon>
                  <v-icon>mdi-clock</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title @click="onTimeSlotSelect(timeSlot)">
                    {{ getTimeSlot(timeSlot.slot) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { DB, getCurrentUserUid } from "@/common/store";

import { db } from "@/main";
import { getScheduleString } from "@/common/utilities/appointments";
import {
  getDateString,
  getDateStringFromTimestamp
} from "@/common/utilities/date";
import {
  GetShortTimeFromTimestamp,
  GetSlotCompare
} from "@/common/utilities/time";
import { GetScheduleSlots } from "@/store/diagnostic_schedule";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import SelectClinic from "./SelectClinic.vue";

export default {
  name: "ConfirmSchedule",

  components: {
    ConfirmDialog,
    SelectClinic
  },

  props: {
    location: String,
    clinicType: String
  },

  data: () => ({
    availableDates: [],
    date: "",
    menu1: false,
    schedules: [],
    selectedService: null
  }),

  computed: {
    noScheduleNotice() {
      return "Walang schedule para sa serbisyong napili niyo. Mangyaring makipag-ugnayan sa LGU para makakuha ng schedule.";
    },

    noServiceSelected() {
      return "Pumili ng serbisyong nais niyong mapakinabangan.";
    }
  },

  methods: {
    allowedDates(a) {
      return this.availableDates.includes(a);
    },

    getServiceScheduleDates(serviceId) {
      return new Promise(resolve => {
        let today = new Date();
        today.setHours(0, 0, 0);
        db.collection(DB.DIAGNOSTIC_SCHEDULES)
          .where("service.id", "==", serviceId)
          .where("schedule.start", ">=", today)
          .orderBy("schedule.start", "asc")
          .get()
          .then(snapshot => {
            let schedules = [];

            snapshot.forEach(doc => {
              let scheduleDate = getDateStringFromTimestamp(
                doc.data().schedule.start
              );

              if (!schedules.includes(scheduleDate)) {
                schedules.push(scheduleDate);
              }
            });

            resolve(schedules);
          })
          .catch(() => resolve([]));
      });
    },

    getTimeSlot(data) {
      return `${GetShortTimeFromTimestamp(
        data.start
      )} - ${GetShortTimeFromTimestamp(data.end)}`;
    },

    isAppointmentSet(payload) {
      return new Promise(resolve => {
        let start = new Date(payload.scheduleStart.seconds * 1000);

        db.collection(DB.DIAGNOSTIC_ATTENDEE)
          .where("service.id", "==", payload.serviceID)
          .where("schedule.start", "==", start)
          .where("user.id", "==", getCurrentUserUid())
          .get()
          .then(snapshot => {
            let data = null;

            snapshot.forEach(doc => {
              if (!data) {
                data = { id: doc.id, ...doc.data() };
              }
            });

            resolve(data);
          })
          .catch(() => resolve(null));
      });
    },

    loadLGUAppointments(selectedService) {
      if (selectedService && selectedService.id) {
        this.selectedService = selectedService;

        this.getServiceScheduleDates(selectedService.id).then(schedules => {
          this.availableDates = schedules;
          if (this.availableDates.length) {
            this.date = this.availableDates[0];
          }
        });
      }
    },

    onTimeSlotSelect(timeslot) {
      this.isAppointmentSet({
        serviceID: timeslot.service.id,
        scheduleStart: timeslot.schedule.start
      }).then(result => {
        if (result) {
          this.showAlreadyScheduledMessage(timeslot, result.appointment);
        } else {
          this.$emit("timeslot-selected", timeslot);
        }
      });
    },

    showAlreadyScheduledMessage(timeslot, appointment) {
      let location = timeslot.service.location;
      let name = timeslot.service.name;

      this.$refs.confirm.open(
        "Nakatala na",
        `Nakatala na po kayo sa schedule na ito:<br><br>
        &nbsp;&nbsp;&nbsp;&nbsp;${name} sa ${location}<br>
        &nbsp;&nbsp;&nbsp;&nbsp;${getScheduleString(appointment)}`,
        { noconfirm: true }
      );
    },

    timeStamp(data) {
      return GetSlotCompare(data.start);
    }
  },

  watch: {
    date() {
      if (this.date) {
        GetScheduleSlots({
          nowDate: getDateString(new Date(this.date)),
          serviceId: this.selectedService.id
        }).then(slots => (this.schedules = slots));
      } else {
        this.schedules = [];
      }
    }
  }
};
</script>
