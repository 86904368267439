<template>
  <v-row>
    <v-col cols="1" />
    <v-col class="mt-n4" cols="10">
      <v-autocomplete
        v-model="serviceDetails"
        :items="services"
        @change="serviceSelected"
        item-text="name"
        item-value="details"
      />
    </v-col>
  </v-row>
</template>

<script>
import { getServiceByOffsiteAttendee } from "@/store/offsiteService";

export default {
  name: "SelectService",

  props: {
    userCode: String
  },

  data: () => ({
    services: [],
    serviceDetails: ""
  }),

  watch: {
    serviceDetails(value) {
      this.$emit("serviceDetails", value);
    },

    userCode(value) {
      if (value) {
        this.getServiceByAttendee();
      }
    }
  },

  methods: {
    getServiceByAttendee() {
      getServiceByOffsiteAttendee(this.userCode).then(services => {
        this.services = services;
        if (this.services.length > 0) {
          this.serviceDetails = this.services[0].details;
          this.$emit("serviceDetails", this.serviceDetails);
        }
        this.$emit("onLoad", this.services);
      });
    },

    serviceSelected() {
      this.$emit("serviceDetails", this.serviceDetails);
    }
  }
};
</script>
