<template>
  <div>
    <MedicalHistoryFlow @onSubmit="onSubmit" v-show="!isSubmitted" />
    <Completed v-show="isSubmitted" />
  </div>
</template>

<script>
import { DB, getCurrentUserUid, logUserActivity } from "@/common/store";
import { NS_MEMBERINFO, NS_PHILHEALTHINFO } from "@/store";

import { db } from "@/main";
import { dbSubmitMedicalHistory } from "@/utilities/indexed_documents";

import Completed from "../components/MedicalHistoryCompleted.vue";
import MedicalHistoryFlow from "@/HealthRecords/components/MedicalHistoryFlow.vue";

export default {
  name: "MedicalHistory",

  components: {
    Completed,
    MedicalHistoryFlow
  },

  data: () => ({
    isSubmitted: false
  }),

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");
  },

  methods: {
    async onNext() {
      const target = {
        collection: DB.PHILHEALTH_INDEX,
        docId: getCurrentUserUid()
      };

      await this.$store.dispatch(`${NS_MEMBERINFO}/save`, target);
      await this.$store.dispatch(`${NS_PHILHEALTHINFO}/save`, target);
      await this.onSubmitPhilhealth();
    },

    async onSubmit() {
      await dbSubmitMedicalHistory(new Date());
      await db.collection(DB.OFFSITE_ATTENDEE).add({
        timestamp: new Date(),
        user: {
          firstName: this.$store.getters[`${NS_MEMBERINFO}/getFirstName`],
          middleName: this.$store.getters[`${NS_MEMBERINFO}/getMiddleName`],
          lastName: this.$store.getters[`${NS_MEMBERINFO}/getLastName`],
          birthday: this.$store.getters[`${NS_MEMBERINFO}/getBirthday`],
          gender: this.$store.getters[`${NS_MEMBERINFO}/getSex`],
          code: this.$store.getters[`getUserCode`],
          id: getCurrentUserUid()
        },
        action: "Submitted Medical History",
        medicalService: this.$store.getters[
          `${NS_MEMBERINFO}/getMedicalService`
        ],
        service: null
      });

      await this.onNext();
    },

    async onSubmitPhilhealth() {
      const timestamp = new Date();

      await this.$store.dispatch(`${NS_PHILHEALTHINFO}/save`, {
        collection: DB.PHILHEALTH_INDEX,
        docId: getCurrentUserUid()
      });
      await logUserActivity("Submit PhilHealth information", {
        timestamp: timestamp
      });
      this.isSubmitted = true;
    }
  }
};
</script>
