<template>
  <v-stepper v-model="step" flat>
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1">
        {{ stepHeader1 }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step :complete="step > 2" step="2">
        {{ stepHeader2 }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step :complete="step > 3" step="3">
        {{ stepHeader3 }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        step="1"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <v-card class="py-5" align="center" flat>
          <h4
            v-if="$vuetify.breakpoint.mobile"
            class="text-center pa-4 primary--text"
          >
            {{ stepHeader1 }}
          </h4>

          <v-row class="py-5" align="center" justify="center">
            <v-col cols="12">
              <h4 class="text-center">Bantay Kard Balance</h4>
              <strong class="text-h5  primary--text">
                {{ name }}
              </strong>

              <p class="text-h5 primary--text">
                <PesoAmount :amount="$store.state.profile.bantayKardBalance" />
              </p>
            </v-col>
          </v-row>

          <v-row class="py-5 px-2" align="center" justify="center">
            <v-col cols="12">
              <h4 class="text-center">Select an Amount</h4>
            </v-col>

            <v-btn
              width="110"
              @click.stop="others = false"
              @click="amount = denomination"
              large
              class="ma-1"
              v-for="(denomination, index) in denominations"
              :key="index"
              outlined
              color="primary"
            >
              <PesoAmount :amount="Number(denomination)" />
            </v-btn>
            <v-btn @click="others = true" large outlined color="primary">
              Others
            </v-btn>
          </v-row>
        </v-card>

        <v-row class="pa-2" align="center" justify="center">
          <v-col v-if="!others" cols="6" sm="3">
            <p class="text-center primary--text text-h5">
              <PesoAmount :amount="Math.abs(amount)" />
            </p>
          </v-col>
          <v-col v-else cols="5" sm="3">
            <vuetify-money
              class="select-container"
              v-model="amount"
              placeholder="Amount"
              v-bind:options="options"
            />
          </v-col>
        </v-row>

        <v-col class="text-right">
          <NextButton @click="step = 2" class="mt-3" />
        </v-col>
      </v-stepper-content>

      <v-stepper-content
        step="2"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <BackButton @click="step = 1" class="mt-3" />
        <h4 v-if="$vuetify.breakpoint.mobile" class="text-center primary--text">
          {{ stepHeader2 }}
        </h4>

        <PaymentOptions @selectedPaymentOption="setService" />
      </v-stepper-content>

      <v-stepper-content
        step="3"
        :class="{ 'pa-2': $vuetify.breakpoint.mobile }"
      >
        <BackButton @click="step = 2" class="mt-3" />

        <h4
          v-if="$vuetify.breakpoint.mobile"
          class="text-center pa-4 primary--text"
        >
          {{ stepHeader3 }}
        </h4>

        <h4 class="text-center pa-2 primary--text">
          {{ paidThru }}
        </h4>

        <div class="pb-4" v-if="isGCashPayment">
          <v-list-item v-for="(instruction, index) in gcash" :key="index">
            <v-list-item-content>
              <v-list-item-title>
                <strong class="primary--text pb-2">
                  {{ instruction.step }}
                </strong>
              </v-list-item-title>
              {{ instruction.content }}
            </v-list-item-content>
          </v-list-item>
        </div>

        <div class="pb-4" v-else>
          <v-list-item v-for="(instruction, index) in unionbank" :key="index">
            <v-list-item-content>
              <v-list-item-title>
                <strong class="primary--text pb-2">
                  {{ instruction.step }}
                </strong>
              </v-list-item-title>
              {{ instruction.content }}
            </v-list-item-content>
          </v-list-item>
        </div>

        <LoadBantayKard
          :paidThru="paidThru"
          :amount="Math.abs(amount)"
          @paymentOptionsSelected="paidThru = $event"
        />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { getCurrentUser } from "@/common/store";
import { isGCashPayment } from "@/common/utilities/payment";
import { mapGetters } from "vuex";

import BackButton from "@/components/Controls/BackButton.vue";
import LoadBantayKard from "@/components/Payment/LoadBantayKard";
import NextButton from "@/components/Controls/NextButton.vue";
import PaymentOptions from "@/components/Payment/PaymentOptions";
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "AddBantayKardPoints",

  components: {
    LoadBantayKard,
    PesoAmount,
    BackButton,
    NextButton,
    PaymentOptions
  },

  data: () => ({
    amount: 0,
    denominations: [100, 250, 500, 1000, 5000, 10000],

    gcash: [
      {
        step: "Step 1",
        title: "MediSure GCash +639498695758",
        icon: "mdi-numeric-1",
        content:
          "Tap Send Money on the GCash dashboard, then tap on Express Send"
      },
      {
        step: "Step 2",
        title: "MediSure GCash +639498695758",
        icon: "mdi-numeric-2",
        content:
          "Input the recipient's mobile number MediSure GCash (09498695758)"
      },
      {
        step: "Step 3",
        title: "MediSure GCash +639498695758",
        icon: "mdi-numeric-3",
        content:
          "Review the confirmation page to double-check the recipient's GCash name, mobile number, and amount. The recipient's GCash-registered name (the person's first nameand first letter of their last name) will appear in boldabove their name in your phonebook."
      },
      {
        step: "Step 4",
        title: "MediSure GCash +639498695758",
        icon: "mdi-numeric-3",
        content:
          "Seeing the in-app receipt means that your Send Money wassuccessful. You can save this screenshot to your phone by tapping the save button on the upper right corner. Finally Copy the Reference Number and Paste on the Field Below."
      }
    ],

    options: {
      locale: "en-US",
      prefix: "₱",
      suffix: "",
      length: 10,
      precision: 2
    },

    others: false,
    paidThru: "",
    step: 1,

    stepHeader1: "Bantay Kard",
    stepHeader2: "Select Payment",
    stepHeader3: "Payment Details",

    unionbank: [
      {
        step: "Step 1",
        title: "MediSure UB Account 0024 00008195",
        icon: "mdi-numeric-1",
        content: "Tap Transfer Funds and select where to transfer money."
      },
      {
        step: "Step 2",
        title: "MediSure GCash +639498695758",
        icon: "mdi-numeric-2",
        content: "Choose the account where you want to take money from."
      },
      {
        step: "Step 3",
        title: "MediSure GCash +639498695758",
        icon: "mdi-numeric-3",
        content:
          "Key in the account details to transfer money to, MediSure UnionBank Account (0024 00008195)"
      },
      {
        step: "Step 4",
        title: "MediSure GCash +639498695758",
        icon: "mdi-numeric-3",
        content: "Key in the amount, frequency and note details"
      },
      {
        step: "Step 5",
        title: "MediSure GCash +639498695758",
        icon: "mdi-numeric-3",
        content:
          "Seeing the in-app receipt means that your Send Money wassuccessful. You can save this screenshot to your phone by tapping the save button on the upper right corner. Finally Copy the Reference Number and Paste on the Field Below."
      }
    ],

    // functions
    isGCashPayment: isGCashPayment
  }),

  mounted() {
    this.amount = Number(this.getBantayKardRemainingBalance).toFixed(2);
  },

  computed: {
    ...mapGetters(["getBantayKardRemainingBalance"]),

    name() {
      return getCurrentUser();
    }
  },

  methods: {
    setService(service) {
      this.paidThru = service;
      this.step = 3;
    }
  }
};
</script>

<style scoped>
.select-container >>> input {
  text-align: center;
}
</style>
