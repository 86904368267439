<template>
  <div>
    <!-- FOR BANTAY CARD -->
    <v-col
      v-if="bhcdetails.clinicType === 'Home Service'"
      :cols="12"
      md="8"
      sm="12"
      style="background-color: #ECEFF1;"
    >
      <p class="headline primary--text">Order Summary</p>

      <v-row class="mb-4">
        <v-col>
          Ito po ang inyong napiling schedule:
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" />
        <v-col cols="3">Type</v-col>
        <v-col cols="8">{{ bhcdetails.clinicType }}</v-col>
      </v-row>

      <div v-if="bhcdetails.clinicType === 'Home Service'">
        <v-row class="mt-n4">
          <v-col cols="1" />
          <v-col cols="3">Address</v-col>
          <v-col cols="8">{{ bhcdetails.homeServiceLocation }}</v-col>
        </v-row>

        <v-row
          class="mt-n4"
          v-for="(prefDateTime, index) in bhcdetails.selectedPreferredDateTime"
          :key="index"
        >
          <v-col cols="1" />
          <v-col cols="3">{{ index + 1 }}. Preferred</v-col>
          <v-col cols="8">{{ prefDateTime.type }}</v-col>

          <v-row class="mt-n4" v-if="prefDateTime.type != 'Specific Time'">
            <v-col cols="1" />
            <v-col cols="3" />
            <v-col cols="8"> {{ prefDateTime.date }} </v-col>
          </v-row>

          <v-row class="mt-n4" v-else>
            <v-col cols="4" />
            <v-col cols="8">
              {{ index + 1 }}. {{ getDate(prefDateTime) }}
            </v-col>
          </v-row>
        </v-row>
      </div>

      <div v-else>
        <v-row class="mt-n4">
          <v-col cols="1" />
          <v-col cols="3">Serbisyo</v-col>
          <v-col cols="8">
            {{ bhcdetails.name }} sa {{ bhcdetails.location }}
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col cols="1" />
          <v-col cols="3">Petsa at Oras</v-col>
          <v-col cols="8">{{ bhcdetails }}</v-col>
        </v-row>
      </div>
      <v-row class="mb-4" v-if="bhcdetails.packageSelected !== ''">
        <v-col>
          Ito po ang inyong napiling Package:
        </v-col>
      </v-row>

      <v-row
        class="mt-n4"
        v-for="(pTest, index) in bhcdetails.packageSelected"
        :key="index"
      >
        <v-row class="mb-4">
          <v-col cols="1" />
          <v-col cols="3">{{ index + 1 }}. {{ pTest.testname }} </v-col>
          <v-col cols="8"> <PesoAmount :amount="Number(pTest.price)"/></v-col>

          <v-col cols="1" />
          <v-col cols="3">Description </v-col>
          <v-col cols="8"> {{ pTest.description }}</v-col>

          <v-row>
            <v-col cols="4" />
            <v-col cols="8">
              <div v-for="(test, index) in pTest.tests" :key="'a' + index">
                {{ test }}
              </div>
            </v-col>
          </v-row>
        </v-row>
      </v-row>

      <v-row v-if="bhcdetails.individualTest !== ''" class="mb-4">
        <v-col>
          Napiling Individual Test:
        </v-col>
      </v-row>

      <v-row
        class="mt-n4"
        v-for="(individual, index) in bhcdetails.individualTest"
        :key="'c' + index"
      >
        <v-col cols="1" />
        <v-col cols="3">{{ index + 1 }}. {{ individual.testname }}</v-col>
        <v-col cols="8">
          <PesoAmount :amount="Number(individual.price)" />
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>
          Kung tama ang mga detalyeng ito, paki-click ang
          <strong>IKUMPIRMA ANG SCHEDULE</strong>.
        </v-col>
      </v-row>

      <v-row class="mt-n4">
        <v-col cols="1" />
        <v-col cols="3">Total Price na Babayaran</v-col>
        <v-col cols="8">
          <strong> <PesoAmount :amount="Number(total)" /> </strong>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else>
      <p class="headline primary--text">Order Summary</p>
      <v-row class="mb-4">
        <v-col>
          Ito po ang inyong napiling schedule:
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" />
        <v-col cols="3">Type</v-col>
        <v-col cols="8">{{ bhcdetails.clinicType }}</v-col>
      </v-row>

      <div v-if="isHomeService">
        <v-row class="mt-n4">
          <v-col cols="1" />
          <v-col cols="3">Address</v-col>
          <v-col cols="8">{{ bhcdetails.service.location }}</v-col>
        </v-row>

        <v-row
          class="mt-n4"
          v-for="(prefDateTime, index) in bhcdetails.selectedPreferredDateTime"
          :key="index"
        >
          <v-col cols="1" />
          <v-col cols="3">{{ index + 1 }}. Preferred</v-col>
          <v-col cols="8">{{ prefDateTime.type }}</v-col>

          <v-row class="mt-n4" v-if="prefDateTime.type != 'Specific Time'">
            <v-col cols="4" />
            <v-col cols="8"> {{ prefDateTime.date }} </v-col>
          </v-row>

          <v-row class="mt-n4" v-else>
            <v-col cols="4" />
            <v-col cols="8">
              {{ index + 1 }}. {{ getDate(prefDateTime) }}
            </v-col>
          </v-row>
        </v-row>
      </div>

      <div v-else-if="bhcdetails.serviceDetail != null">
        <v-row class="mt-n4">
          <v-col cols="1" />
          <v-col cols="3">Serbisyo</v-col>
          <v-col cols="8">
            {{ bhcdetails.serviceDetail.name }} sa
            {{ bhcdetails.serviceDetail.location }}
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col cols="1" />
          <v-col cols="3">Petsa at Oras</v-col>
          <v-col cols="8">{{ bhcdetails.selectedPrefferedDateTime.sta }}</v-col>
        </v-row>
      </div>

      <div v-else>
        <v-row class="mt-n4">
          <v-col cols="1" />
          <v-col cols="3">Serbisyo</v-col>
          <v-col cols="8">
            {{ bhcdetails.service.serviceName }} sa
            {{ bhcdetails.service.serviceLocation }}
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col cols="1" />
          <v-col cols="3">Petsa at Oras</v-col>
          <v-col cols="8">{{ getDate2(bhcdetails.start) }}</v-col>
        </v-row>
      </div>
      <v-row class="mb-4" v-if="bhcdetails.packageSelected != ''">
        <v-col>
          Ito po ang inyong napiling Package:
        </v-col>
      </v-row>

      <v-row
        class="mt-n4"
        v-for="(pTest, index) in bhcdetails.packageSelected"
        :key="index"
      >
        <v-row class="mb-4">
          <v-col cols="1" />
          <v-col cols="3">{{ index + 1 }}. {{ pTest.testname }} </v-col>
          <v-col cols="8"> <PesoAmount :amount="Number(pTest.price)"/></v-col>

          <v-col cols="1" />
          <v-col cols="3">Description </v-col>
          <v-col cols="8"> {{ pTest.description }}</v-col>

          <v-row>
            <v-col cols="4" />
            <v-col cols="8">
              <div v-for="(test, index) in pTest.tests" :key="'a' + index">
                {{ test }}
              </div>
            </v-col>
          </v-row>
        </v-row>
      </v-row>
      <v-row v-if="bhcdetails.individualTest != ''" class="mb-4">
        <v-col>
          Napiling Individual Test:
        </v-col>
      </v-row>

      <v-row
        class="mt-n4"
        v-for="(individual, index) in bhcdetails.individualTest"
        :key="'c' + index"
      >
        <v-col cols="1" />
        <v-col cols="3">{{ index + 1 }}. {{ individual.testname }}</v-col>
        <v-col cols="8">
          <PesoAmount :amount="Number(individual.price)" />
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>
          Kung tama ang mga detalyeng ito, paki-click ang
          <strong>IKUMPIRMA ANG SCHEDULE</strong>.
        </v-col>
      </v-row>

      <v-row class="mt-n4">
        <v-col cols="1" />
        <v-col cols="3">Total Price na Babayaran</v-col>
        <v-col cols="8">
          <strong> <PesoAmount :amount="Number(total)" /> </strong>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { getDateTime, getTime } from "@/common/utilities/date";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "SummaryBHCCheckout",

  components: {
    PesoAmount
  },

  props: {
    clinicType: String,
    bhcdetails: Object,
    total: Number
  },

  methods: {
    getDate(prefDate) {
      return `${getDateTime(prefDate.from)} - ${getTime(prefDate.to)}`;
    },

    getDate2(start) {
      return `${getDateTime(start)}`;
    }
  }
};
</script>
