export const THIS_VERSION = "0.4.7";

import { IS_STAGING } from "@/common/main";

import { db } from "@/main";

const LAST_QUERY_DATE = "medisureLastQueryDate";
const ONE_HOUR = 60 * 60 * 1000;

function shouldRecheck(loadVersionDate) {
  return !loadVersionDate || new Date() - loadVersionDate > ONE_HOUR;
}

function shouldRecheck2() {
  let lastQueryDate = window.sessionStorage.getItem(LAST_QUERY_DATE);
  return !lastQueryDate || new Date() - new Date(lastQueryDate) > ONE_HOUR;
}

export const version = {
  state: () => ({
    deployedVersion: "",

    loadedVersion: "",
    loadVersionDate: "",

    shouldReload: false
  }),

  getters: {
    getDeployedVersion(state) {
      return state.deployedVersion;
    },

    getLoadedVersion(state) {
      return state.loadedVersion;
    },

    getLoadVersionDate(state) {
      return state.loadVersionDate;
    },

    getShouldReload(state) {
      return state.shouldReload;
    },

    isUpdated(state) {
      return THIS_VERSION === state.deployedVersion;
    }
  },

  mutations: {
    RESET_STATE(state) {
      state.deployedVersion = "";
      state.loadedVersion = "";
      state.loadVersionDate = "";
      state.shouldReload = false;
    },

    setDeployedVersion(state, value) {
      state.deployedVersion = value;
    },

    setLoadedVersion(state, value) {
      state.loadedVersion = value;
    },

    setLoadVersionDate(state, value) {
      state.loadVersionDate = value;
    },

    setShouldReload(state, value) {
      state.shouldReload = value;
    }
  },

  actions: {
    load({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        if (state.deployedVersion && !shouldRecheck(state.loadVersionDate)) {
          resolve();
        } else {
          dispatch("loadData")
            .then(() => resolve())
            .catch(err => reject(err));
        }
      });
    },

    loadData({ commit, state }) {
      return new Promise((resolve, reject) => {
        db.collection("settings")
          .doc(IS_STAGING ? "test_app" : "app")
          .get()
          .then(doc => {
            if (doc.exists && doc.data().version) {
              const deployedVersion = doc.data().version;

              commit("setLoadedVersion", THIS_VERSION);
              commit("setDeployedVersion", deployedVersion);

              if (shouldRecheck(state.loadVersionDate) && shouldRecheck2()) {
                window.sessionStorage.setItem(LAST_QUERY_DATE, new Date());

                commit("setLoadVersionDate", new Date());
                commit("setShouldReload", deployedVersion !== THIS_VERSION);
              }
            }

            resolve();
          })
          .catch(err => reject(err));
      });
    },

    resetContent({ commit }) {
      window.sessionStorage.removeItem(LAST_QUERY_DATE);
      commit("RESET_STATE");
    }
  }
};
