<template>
  <div>
    <AppHeader header="e-Konsulta" />

    <v-container fluid class="main-container">
      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <v-stepper-step :complete="currentStep > 1" step="1">
            {{ stepHeader1 }}
          </v-stepper-step>

          <v-stepper-step :complete="step > 2" step="2">
            {{ stepHeader2 }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="step > 3" step="3">
            {{ stepHeader3 }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="step > 4" step="4">
            {{ stepHeader4 }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="step > 5" step="5">
            {{ stepHeader5 }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <h3
              v-show="$vuetify.breakpoint.mobile"
              class="text-center primary--text pt-2"
            >
              - {{ stepHeader1 }} -
            </h3>
            <v-col class="text-right">
              <v-btn
                :disabled="!filtersComplete"
                color="primary"
                @click="filterDoctor"
              >
                Next
                <v-icon>mdi-step-forward</v-icon>
              </v-btn>
            </v-col>
            <FilterDoctorService
              :specializations="specializations"
              :selectedSpecialization.sync="selectedSpecialization"
              :selectedVisitType.sync="selectedVisitType"
              :showNearFirst.sync="showNearFirst"
              :selectedLocation.sync="selectedLocation"
            />
          </v-stepper-content>

          <v-stepper-content step="2">
            <h3
              v-show="$vuetify.breakpoint.mobile"
              class="text-center primary--text pt-2"
            >
              - {{ stepHeader1 }} -
            </h3>

            <v-row>
              <v-col class="text-left">
                <v-btn color="primary" @click="step = 1">
                  <v-icon>mdi-step-backward</v-icon>Back
                </v-btn>
              </v-col>
            </v-row>
            <SelectDoctor
              :step="step"
              :selectedSpecialization="selectedSpecialization"
              :selectedVisitType="selectedVisitType"
              :showNearFirst="showNearFirst"
              :selectedLocation="selectedLocation"
              @selectedDoctor="doctorSelected"
            />
          </v-stepper-content>

          <v-stepper-content step="3">
            <h3
              v-show="$vuetify.breakpoint.mobile"
              class="text-center primary--text pt-2"
            >
              - {{ stepHeader3 }} -
            </h3>
            <v-col class="text-left">
              <v-btn color="primary" @click="step = 2">
                <v-icon>mdi-step-backward</v-icon>Back
              </v-btn>
            </v-col>

            <SelectDoctorAppointment
              v-if="selectedDoctor != null"
              ref="selectSchedule"
              :selectedDoctor="selectedDoctor.id"
              :clinicType="selectedVisitType"
              :location="selectedLocation"
              @timeslot-selected="timeslotSelected"
            />
          </v-stepper-content>

          <v-stepper-content step="4">
            <h3
              v-show="$vuetify.breakpoint.mobile"
              class="text-center primary--text pt-2"
            >
              - {{ stepHeader4 }} -
            </h3>
            <v-row>
              <v-col class="text-left">
                <v-btn color="primary" @click="step = 3">
                  <v-icon>mdi-step-backward</v-icon>Back
                </v-btn>
              </v-col>
            </v-row>
            <Costing
              :payref="'Doctor'"
              :serviceFee="serviceFee"
              :unfilteredPurchase="true"
              @costDetails="costDetailsSelected"
            />
          </v-stepper-content>

          <v-stepper-content step="5">
            <h3
              v-show="$vuetify.breakpoint.mobile"
              class="text-center primary--text pt-2"
            >
              - {{ stepHeader5 }} -
            </h3>
            <v-row>
              <v-col class="text-left">
                <v-btn color="primary" @click="step = 4">
                  <v-icon>mdi-step-backward</v-icon>Back
                </v-btn>
              </v-col>
            </v-row>
            <ConfirmDoctorAppointment :isComplete="isComplete" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import { ROUTE_DASHBOARD, routeTo } from "@/router";

import FilterDoctorService from "@/components/FilterDoctorService.vue";
import Costing from "@/components/Konsulta/Costing.vue";
import SelectDoctorAppointment from "@/components/SelectDoctorAppointment.vue";
import ConfirmDoctorAppointment from "@/components/ConfirmDoctorAppointment.vue";
import SelectDoctor from "@/components/SelectDoctor.vue";

export default {
  name: "DoctorAppointment",

  components: {
    SelectDoctor,
    FilterDoctorService,
    SelectDoctorAppointment,
    ConfirmDoctorAppointment,
    Costing
  },

  props: {
    appointmentId: String,
    selectedTab: Number
  },

  data: () => ({
    serviceFee: 900,
    discountedTotal: 0,
    serviceDoctor: "",
    stepHeader1: "Select Services",
    stepHeader2: "Select Doctor",
    stepHeader3: "Select Appointment",
    stepHeader4: "Payment",
    stepHeader5: "Confirmation",
    step: 1,
    selectedSpecialization: "Family Medicine",
    selectedVisitType: "Video Consultation",
    showNearFirst: true,
    selectedLocation: null,
    specializations: [],
    selectedDoctor: null,
    selectedTimeSlot: null,
    isComplete: false
  }),

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");
  },

  computed: {
    filtersComplete() {
      if (this.showNearFirst && !this.selectedLocation) {
        return false;
      }

      if (this.selectedVisitType === "Clinic Visit" && !this.selectedLocation) {
        return false;
      }

      return this.selectedSpecialization;
    }
  },

  methods: {
    confirmSchedule() {
      this.$store
        .dispatch("saveDoctorAppointment", this.selectedDoctorAppointment)
        .then(() => {
          routeTo(ROUTE_DASHBOARD);
        });
    }
  }
};
</script>
