export default class MediaUtil {
  static async getStream() {
    const sourceInfos = await navigator.mediaDevices.enumerateDevices();
    const selectSource = sourceInfos.find(si => si.kind === "videoinput");

    const videoSource = {
      sourceId: selectSource ? selectSource.deviceId : ""
    };

    const mandatory = {
      minWidth: 640,
      minHeight: 480,
      minFrameRate: 30
    };

    const videoConstraints = {
      mandatory: mandatory,
      optional: [videoSource]
    };

    const constraints = {
      video: videoConstraints,
      audio: true
    };

    return navigator.mediaDevices.getUserMedia(constraints);
  }
}
