<template>
  <v-app-bar flat app dark color="primary">
    <v-app-bar-nav-icon
      v-if="isLoggedIn"
      @click.stop="$emit('update:drawer', !drawer)"
    />

    <v-toolbar-title v-bind:style="{ cursor: 'pointer' }" @click="redirect()">
      <span class="grey--text text--lighten-1" v-show="isLoggedIn">
        {{ $route.name }}
      </span>

      <span class="grey--text text--lighten-1" v-show="!isLoggedIn">Medi</span>
      <span class="grey--text text--lighten-2" v-show="!isLoggedIn"
        >Sure <span class="grey--text text--lighten-2"><sup>Plus</sup></span>
      </span>
    </v-toolbar-title>

    <v-spacer />

    <div class="text-center" v-if="hasCartItem && isLoggedIn">
      <v-badge :content="getCartCount" :value="getCartCount" overlap>
        <v-btn color="white" @click.stop="toCart" dark text v-if="isLoggedIn">
          <v-icon>mdi-cart</v-icon>
        </v-btn>
      </v-badge>
    </div>

    <v-btn text :to="ROUTE_SIGNIN" v-if="!isLoggedIn && !isSignIn" class="px-0">
      <span class="white--text text--lighten-1">
        <v-icon>mdi-account</v-icon> Sign In
      </span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {
  ROUTE_CART,
  ROUTE_SIGNIN,
  ROUTE_STORES,
  routeTo,
  toSubMenu
} from "@/router";
import {
  TEMPORARY_CART,
  TEMPORARY_WISHLIST
} from "@/utilities/collection/addtocart";

import { getCurrentUserUid, logUserActivity } from "@/common/store";
import { mapGetters } from "vuex";
import { updateWishlist } from "@/utilities/collection/catalog";

export default {
  name: "AppBarMenu",

  data: () => ({
    isSignIn: false,

    ROUTE_SIGNIN: ROUTE_SIGNIN
  }),

  props: {
    drawer: Boolean
  },

  watch: {
    $route() {
      this.isSignInRouted();
    },

    isLoggedIn(value) {
      if (value) {
        this.wishlist()
          .then(doRedirect => {
            return this.cart(doRedirect);
          })
          .then(doRedirect => {
            if (doRedirect) {
              routeTo(ROUTE_STORES);
            }
          });
      }
    }
  },

  mounted() {
    this.isSignInRouted();
  },

  computed: {
    ...mapGetters(["getCartCount", "isLoggedIn"]),

    hasCartItem() {
      return this.getCartCount > 0;
    }
  },

  methods: {
    async cart(doRedirect) {
      let temporaryCart = sessionStorage.getItem(TEMPORARY_CART);
      if (temporaryCart !== "null") {
        let product = JSON.parse(temporaryCart);
        if (product.name) {
          product.soldTo = getCurrentUserUid();

          await this.$store.dispatch("addToCart", product);
          window.sessionStorage.setItem(TEMPORARY_CART, null);
          await logUserActivity("Add from Temp Cart", product);
          await this.$store.dispatch("loadCart");
          return true;
        }
      }

      return doRedirect;
    },

    isSignInRouted() {
      this.isSignIn = this.$router.currentRoute.fullPath === ROUTE_SIGNIN;
    },

    redirect() {
      toSubMenu(this.$route.path);
    },

    toCart() {
      routeTo(ROUTE_CART);
    },

    async wishlist() {
      let temporaryWishlist = sessionStorage.getItem(TEMPORARY_WISHLIST);
      if (temporaryWishlist !== "null") {
        let wish = JSON.parse(temporaryWishlist);
        if (wish) {
          let objectWish = {
            docid: wish.docid,
            iname: getCurrentUserUid(),
            onWishlist: wish.onWishlist
          };

          await updateWishlist(objectWish);
          window.sessionStorage.setItem(TEMPORARY_WISHLIST, null);
          return true;
        }
      }

      return false;
    }
  }
};
</script>
