<template>
  <div>
    <div class="mt-10 mx-10">
      <v-row>
        <v-col cols="12">
          <EditableMemberInfo @onChange="onMemberInfoChange" class="pb-7" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <EditableContactInfo @onChange="onContactInfoChange" class="mt-0" />
        </v-col>
      </v-row>

      <v-row class="background-grey pb-2 pt-5 px-2">
        <v-col :cols="temperatureCol" class="py-0 select-container">
          <v-text-field v-model="temperature" label="Temperature" />
        </v-col>
      </v-row>

      <v-row class="mt-0 pb-5 pt-7 px-2">
        <v-col :cols="leadRowCol" class="py-0">
          Did you travel outside of Metro Manila in the last 7 days?
        </v-col>

        <v-col
          :cols="valueRowCol"
          class="select-container"
          :class="{ 'py-0': isSmallerThanMD, 'pb-0 pt-3': !isSmallerThanMD }"
        >
          <v-select v-model="travelOutsideMetroManila" :items="['Yes', 'No']" />
        </v-col>

        <v-col
          :cols="valueTextRowCol"
          class="select-container"
          :class="{ 'py-0': isSmallerThanMD, 'pb-0 pt-3': !isSmallerThanMD }"
        >
          <v-text-field
            v-model="travelOutsideMetroManilaComment"
            label="Where?"
            v-show="'Yes' === travelOutsideMetroManila"
          />
        </v-col>
      </v-row>

      <v-row class="background-grey mt-0 pb-2 pt-5 px-2">
        <v-col :cols="leadRowCol" class="py-0">
          Do you have flu like symptoms like coughs or cold?
        </v-col>

        <v-col
          :cols="valueRowCol"
          class="select-container"
          :class="{ 'py-0': isSmallerThanMD, 'pb-0 pt-3': !isSmallerThanMD }"
        >
          <v-select v-model="fluLikeSymptoms" :items="['Yes', 'No']" />
        </v-col>

        <v-col
          :cols="valueTextRowCol"
          class="select-container"
          :class="{ 'py-0': isSmallerThanMD, 'pb-0 pt-3': !isSmallerThanMD }"
        >
          <v-text-field
            v-model="fluLikeSymptomsComment"
            label="Since when?"
            v-show="'Yes' === fluLikeSymptoms"
          />
        </v-col>
      </v-row>

      <v-row class="mt-0 pb-5 pt-7 px-2">
        <v-col :cols="leadRowCol" class="py-0">
          Did you experience loss of smell or taste in the last 3 days?
        </v-col>

        <v-col
          :cols="valueRowCol"
          class="select-container"
          :class="{ 'py-0': isSmallerThanMD, 'pb-0 pt-3': !isSmallerThanMD }"
        >
          <v-select v-model="lossOfSmell" :items="['Yes', 'No']" />
        </v-col>
      </v-row>

      <v-row class="background-grey mt-0 pb-2 pt-5 px-2">
        <v-col :cols="leadRowCol" class="py-0">
          When was your last physical examination?
        </v-col>

        <v-col :cols="physicalExamCol" class="py-0 select-container">
          <v-select
            v-model="lastPhysicalExam"
            :items="['Year', 'Did not have any']"
          />
        </v-col>

        <v-col :cols="physicalExamTextCol" class="pt-1 py-0 select-container">
          <v-text-field
            v-model="lastPhysicalExamComment"
            v-show="'Year' === lastPhysicalExam"
          />
        </v-col>
      </v-row>

      <v-row class="mt-0 pb-2 pt-8 px-2">
        <v-col :cols="leadRowCol" class="py-0">
          Who do you live with?
        </v-col>

        <v-col :cols="leadRowCol" class="pt-5 select-container">
          <v-checkbox v-model="livesWithSpouse" label="Wife / Husband" />
          <v-checkbox v-model="livesWithChild" label="Son / Daughter" />
          <v-checkbox v-model="livesWithParents" label="Parents" />
          <v-checkbox v-model="livesWithSiblings" label="Brother / Sister" />
          <span class="d-inline-flex select-container">
            <v-checkbox v-model="livesWithOthers" label="Others" class="pr-5" />
            <v-text-field
              v-model="livesWithOthersComment"
              v-show="livesWithOthers"
              class="mt-n1"
            />
          </span>
        </v-col>
      </v-row>

      <v-row class="background-grey mt-0 pb-0 pt-5 px-2 text-center">
        <v-col :cols="medisureInteractionCol">
          Medisure Interaction
        </v-col>

        <v-col :cols="medisureInteractionPaddingCol">
          &nbsp;
        </v-col>

        <v-col
          :cols="medisureInteractionValueCol"
          class="pt-0 select-container"
        >
          <v-select
            v-model="medisureInteraction"
            :items="['Clinic Visit', HOME_SERVICE]"
          />
        </v-col>

        <v-col cols="12" v-show="isHomeService">
          <HomeServiceLocation
            ref="homeServiceLocation"
            @onChange="onHomeServiceLocationChange"
          />
        </v-col>
      </v-row>

      <p class="mt-10">
        I hereby attest to the truthfulness in the foregoing and I agree to
        share the above information for purposes of contact tracing.
      </p>

      <NextButton
        @click="onNext"
        text="Submit"
        :disabled="isSubmitDisabled"
        class="mb-10 mt-5"
      />
    </div>

    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { DB } from "@/common/store";
import { HOME_SERVICE } from "../store";
import {
  NS_CONTACT_INFO as CONTACTINFO,
  NS_CONTACT_TRACING as NAMESPACE,
  NS_MEMBERINFO as MEMBERINFO
} from "@/store";
import { ROUTE_DASHBOARD, routeTo } from "@/router";

import { isSmallerThanMD, setWidth } from "@/utilities/display";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import EditableContactInfo from "@/MemberInfo/components/EditableContactInfo.vue";
import EditableMemberInfo from "@/MemberInfo/components/EditableMemberInfo.vue";
import HomeServiceLocation from "../components/HomeServiceLocation.vue";
import NextButton from "@/components/Controls/NextButton.vue";

export default {
  name: "ContactTracingForm",

  components: {
    ConfirmDialog,
    EditableContactInfo,
    EditableMemberInfo,
    HomeServiceLocation,
    NextButton
  },

  data: () => ({
    isContactInfoComplete: false,
    isMemberInfoComplete: false,
    isHomeServiceLocationComplete: false,

    HOME_SERVICE: HOME_SERVICE
  }),

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");

    this.$store.dispatch(`${NAMESPACE}/load`).then(() => {
      this.$refs.homeServiceLocation.uncheckSameAddress();
      const address = this.$store.getters[`${NAMESPACE}/getAddress`];
      this.$refs.homeServiceLocation.setAddress(address);
    });
  },

  methods: {
    async confirm() {
      if (
        await this.$refs.confirm.open(
          "Confirmatiom",
          "Contact trace information submitted",
          { noconfirm: false }
        )
      ) {
        routeTo(ROUTE_DASHBOARD);
      }
    },

    isCustomFieldValid(field, optionalField, requiredFieldValue) {
      return field && (field !== requiredFieldValue || optionalField)
        ? true
        : false;
    },

    onContactInfoChange(value) {
      this.isContactInfoComplete = value;
    },

    onHomeServiceLocationChange(value) {
      this.isHomeServiceLocationComplete = value;
    },

    onMemberInfoChange(value) {
      this.isMemberInfoComplete = value;
    },

    onNext() {
      const target = {
        collection: DB.CONTACTTRACING_INDEX,
        docId: 0
      };

      this.$store
        .dispatch(`${MEMBERINFO}/saveNewDoc`, target.collection)
        .then(id => {
          target.docId = id;
          return this.$store.dispatch(`${CONTACTINFO}/save`, target);
        })
        .then(() => {
          return this.$store.dispatch(`${NAMESPACE}/save`, target);
        })
        .then(() => {
          this.confirm();
        });
    }
  },

  computed: {
    fluLikeSymptoms: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getFluLikeSymptoms`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setFluLikeSymptoms`, value);
      }
    },

    fluLikeSymptomsComment: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getFluLikeSymptomsComment`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setFluLikeSymptomsComment`, value);
      }
    },

    isHomeService() {
      return this.HOME_SERVICE === this.medisureInteraction;
    },

    isResponseComplete() {
      let response = this.isCustomFieldValid(
        this.fluLikeSymptoms,
        this.fluLikeSymptomsComment,
        "Yes"
      );

      response &= this.isCustomFieldValid(
        this.lastPhysicalExam,
        this.lastPhysicalExamComment,
        "Year"
      );

      response &= this.lossOfSmell ? true : false;

      response &= this.medisureInteraction ? true : false;
      response &= this.temperature ? true : false;
      response &= this.isCustomFieldValid(
        this.travelOutsideMetroManila,
        this.travelOutsideMetroManilaComment,
        "Yes"
      );
      response &= this.isContactInfoComplete ? true : false;

      response &= !this.isHomeService || this.isHomeServiceLocationComplete;

      return response;
    },

    isSmallerThanMD() {
      return isSmallerThanMD(this.$vuetify.breakpoint.width);
    },

    isSubmitDisabled() {
      return !(
        this.isContactInfoComplete &&
        this.isMemberInfoComplete &&
        this.isResponseComplete
      );
    },

    lastPhysicalExam: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLastPhysicalExam`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setLastPhysicalExam`, value);
      }
    },

    lastPhysicalExamComment: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLastPhysicalExamComment`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setLastPhysicalExamComment`, value);
      }
    },

    leadRowCol() {
      return setWidth(this.$vuetify.breakpoint.width, 12, 12, 12, 12, 12);
    },

    livesWithChild: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLivesWithChild`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setLivesWithChild`, value);
      }
    },

    livesWithOthers: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLivesWithOthers`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setLivesWithOthers`, value);
      }
    },

    livesWithOthersComment: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLivesWithOthersComment`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setLivesWithOthersComment`, value);
      }
    },

    livesWithParents: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLivesWithParents`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setLivesWithParents`, value);
      }
    },

    livesWithSiblings: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLivesWithSiblings`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setLivesWithSiblings`, value);
      }
    },

    livesWithSpouse: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLivesWithSpouse`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setLivesWithSpouse`, value);
      }
    },

    lossOfSmell: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLossOfSmell`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setLossOfSmell`, value);
      }
    },

    medisureInteraction: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getMedisureInteraction`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setMedisureInteraction`, value);
      }
    },

    medisureInteractionCol() {
      return setWidth(this.$vuetify.breakpoint.width, 12, 12, 12, 12, 12);
    },

    medisureInteractionPaddingCol() {
      return setWidth(this.$vuetify.breakpoint.width, 4, 4, 2, 2, 2);
    },

    medisureInteractionValueCol() {
      return setWidth(this.$vuetify.breakpoint.width, 4, 4, 8, 8, 8);
    },

    physicalExamCol() {
      return "Did not have any" === this.lastPhysicalExam
        ? setWidth(this.$vuetify.breakpoint.width, 4, 4, 12, 12, 12)
        : setWidth(this.$vuetify.breakpoint.width, 2, 2, 6, 6, 6);
    },

    physicalExamTextCol() {
      return "Did not have any" === this.lastPhysicalExam
        ? setWidth(this.$vuetify.breakpoint.width, 12, 12, 12, 12, 12)
        : setWidth(this.$vuetify.breakpoint.width, 2, 2, 6, 6, 6);
    },

    temperature: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getTemperature`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setTemperature`, value);
      }
    },

    temperatureCol() {
      return setWidth(this.$vuetify.breakpoint.width, 2, 3, 4, 6, 6);
    },

    travelOutsideMetroManila: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getTravelOutsideMetroManila`];
      },
      set(value) {
        this.$store.commit(`${NAMESPACE}/setTravelOutsideMetroManila`, value);
      }
    },

    travelOutsideMetroManilaComment: {
      get() {
        return this.$store.getters[
          `${NAMESPACE}/getTravelOutsideMetroManilaComment`
        ];
      },
      set(value) {
        this.$store.commit(
          `${NAMESPACE}/setTravelOutsideMetroManilaComment`,
          value
        );
      }
    },

    valueRowCol() {
      return setWidth(this.$vuetify.breakpoint.width, 2, 2, 3, 4, 4);
    },

    valueTextRowCol() {
      return setWidth(this.$vuetify.breakpoint.width, 10, 10, 9, 8, 8);
    }
  }
};
</script>

<style scoped>
.select-container >>> input {
  margin-top: 0;
  padding-top: 0;
}

.select-container >>> .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.select-container >>> .v-text-field {
  margin-top: 0;
  padding-top: 0;
}
</style>
