<template>
  <v-row class="px-2">
    <v-col
      :cols="leadRowCol"
      class="pa-0"
      :class="{ 'text-center': 12 === leadRowCol }"
    >
      <p class="text-subtitle-2 text-center mb-0">
        {{ header || "&nbsp;" }}
      </p>
    </v-col>

    <v-col
      :cols="valueRowCol"
      class="pa-0"
      :class="{ 'text-center': 12 === valueRowCol }"
    >
      <p class="text-subtitle-2 text-center mb-0">
        {{ content }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { setWidth } from "@/utilities/display";

export default {
  name: "InfoEntry",

  props: {
    header: String,
    content: String
  },

  computed: {
    leadRowCol() {
      return setWidth(this.$vuetify.breakpoint.width, 4, 4, 4, 12, 12);
    },

    valueRowCol() {
      return setWidth(this.$vuetify.breakpoint.width, 8, 8, 8, 12, 12);
    }
  }
};
</script>
