<template>
  <v-container>
    <v-row v-if="this.packageNames.length" class="mt-n4">
      <v-select
        class="packages"
        v-model="selectedPackageName"
        :items="packageNames"
        @change="$emit('selected-package-name', selectedPackageName)"
      />
    </v-row>

    <v-row class="mt-n4" v-if="hasPadding">
      <v-col cols="2" />
      <v-col cols="6">Premium</v-col>
      <v-col cols="3">{{ this.selectedPackagePremium }}</v-col>
    </v-row>
    <v-row class="mt-n4" v-else>
      <v-col cols="7" class="pl-4">Premium</v-col>
      <v-col cols="5">{{ this.selectedPackagePremium }}</v-col>
    </v-row>

    <v-row v-if="hasPadding">
      <v-col cols="2" />
      <v-col cols="6">
        <strong>Benefits</strong>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="7" class="pl-4">
        <strong>Benefits</strong>
      </v-col>
    </v-row>

    <v-layout
      v-for="details in this.selectedPackageBenefits"
      :key="details.name"
      class="mt-n4"
    >
      <v-row v-if="hasPadding">
        <v-col cols="2" />
        <v-col cols="6" class="py-6 px-0">- {{ details.name }}</v-col>
        <v-col cols="3">{{ details.amount }}</v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="7" class="py-6 px-0">- {{ details.name }}</v-col>
        <v-col cols="5" class="py-6 px-0">{{ details.amount }}</v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "InsurancePackages",

  props: {
    packageDetails: Object,
    packageNames: Array,
    initialPackage: String
  },

  data: () => ({
    selectedPackageName: ""
  }),

  computed: {
    hasPadding() {
      return window.innerWidth > 500;
    },

    selectedPackage() {
      return this.packageDetails[this.selectedPackageName];
    },

    selectedPackageBenefits() {
      return this.selectedPackage ? this.selectedPackage.benefits : [];
    },

    selectedPackagePremium() {
      return this.selectedPackage ? this.selectedPackage.premium : "₱ 0.00";
    }
  },

  mounted() {
    if (0 === this.selectedPackageName.length) {
      if (this.initialPackage && this.initialPackage.length) {
        this.selectedPackageName = this.initialPackage;
      } else if (this.packageNames.length) {
        this.selectedPackageName = this.packageNames[0];
      }
    }

    this.$emit("selected-package-name", this.selectedPackageName);
  }
};
</script>

<style scoped>
.packages {
  margin: auto;
  max-width: 200px;
}
</style>
