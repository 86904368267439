<template>
  <span>
    <v-row v-if="false">
      <v-btn icon large class="login_css" @click="$emit('facebookLogin')">
        <v-img
          src="@/assets/btn_continue_with_facebook.png"
          alt="Continue with Facebook"
        />
      </v-btn>
    </v-row>

    <v-row>
      <v-btn class="login_css mt-1" icon large @click="$emit('googleLogin')">
        <v-img
          src="@/assets/btn_google_signin_dark_normal_web.png"
          alt="Sign in with Google"
        />
      </v-btn>
    </v-row>

    <v-row>
      <v-btn class="login_css mt-2" icon large @click="$emit('phoneLogin')">
        <v-img src="@/assets/btn_signinphone.png" alt="Mobile phone signin" />
      </v-btn>
    </v-row>
  </span>
</template>

<script>
export default {
  name: "AuthenticationAlternativeSignIn"
};
</script>

<style scoped>
.login_css {
  margin: auto;
  padding-top: 0 !important;
  padding: 0 !important;
}
</style>
