export function getAge(birthDate) {
  if (birthDate && birthDate instanceof Date) {
    let today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();

    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  return 0;
}

export function getLabScheduleFullName(userData) {
  const name = [userData.firstName || "", userData.middleName || ""].join(" ");

  if (userData.lastName) {
    return (name ? `${userData.lastName}, ${name}` : userData.lastName).trim();
  }

  return name.trim();
}
