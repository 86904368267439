<template>
  <div>
    <p class="text-center mt-3 pt-3">
      Please select your desired laboratory service.
    </p>

    <v-row>
      <v-col v-if="!isSmallerThanMD" cols="3" />
      <v-col :cols="isSmallerThanMD ? 12 : 6">
        <div class="mt-10 mx-5">
          <h3>
            <span class="primary--text">Williams Primary Care Facility</span>
          </h3>

          <div class="mt-2 mx-5">
            <h4>Annual Physical Examination (APE)</h4>
            <div class="mx-10">
              <div class="mt-2">
                <v-btn @click="stationaryWalkin()">
                  {{ TRANSACTION_TYPE_WALKIN }}
                </v-btn>
              </div>
              <div class="mt-2">
                <v-btn @click="stationaryAppointment()">
                  {{ TRANSACTION_TYPE_APPOINTMENT }}
                </v-btn>
              </div>
            </div>
          </div>

          <div class="mt-2 mx-5">
            <h4>Pre-employment Examination (Pre-Emp)</h4>
            <div class="mx-10">
              <div class="mt-2">
                <v-btn @click="stationaryWalkin()">
                  {{ TRANSACTION_TYPE_WALKIN }}
                </v-btn>
              </div>
              <div class="mt-2">
                <v-btn @click="stationaryAppointment()">
                  {{ TRANSACTION_TYPE_APPOINTMENT }}
                </v-btn>
              </div>
            </div>
          </div>

          <div class="mt-2 mx-5">
            <h4>
              Other Laboratory Services
            </h4>
            <div class="mx-10">
              <div class="mt-2">
                <v-btn @click="stationarySchedule()">
                  {{ TRANSACTION_TYPE_SCHEDULE }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10 mx-5">
          <h3>
            Home Service
          </h3>
          <div class="mx-10">
            <div class="mt-2">
              <v-btn @click="homeServiceSchedule()">
                {{ TRANSACTION_TYPE_SCHEDULE }}
              </v-btn>
            </div>
          </div>
        </div>

        <div class="mt-10 mx-5">
          <h3>
            <span class="primary--text">Mobile Van Service (Coming Soon)</span>
          </h3>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  CLINIC_TYPE_HOME_SERVICE,
  CLINIC_TYPE_STATIONARY,
  TRANSACTION_TYPE_APPOINTMENT,
  TRANSACTION_TYPE_SCHEDULE,
  TRANSACTION_TYPE_WALKIN
} from "@/common/utilities/appointments";
import { isSmallerThanMD } from "@/common/utilities/display";

export default {
  name: "SelectClinicType",

  data: () => ({
    TRANSACTION_TYPE_APPOINTMENT: TRANSACTION_TYPE_APPOINTMENT,
    TRANSACTION_TYPE_SCHEDULE: TRANSACTION_TYPE_SCHEDULE,
    TRANSACTION_TYPE_WALKIN: TRANSACTION_TYPE_WALKIN
  }),

  computed: {
    isSmallerThanMD() {
      return isSmallerThanMD(this.$vuetify.breakpoint.width);
    }
  },

  methods: {
    homeServiceSchedule() {
      this.selectedType(CLINIC_TYPE_HOME_SERVICE, TRANSACTION_TYPE_SCHEDULE);
    },

    selectedType(clinicType, transactionType) {
      this.$store.commit("lab/appointment/SET_CLINIC_TYPE", clinicType, {
        root: true
      });

      this.$store.dispatch("lab/appointment/loadPackages");
      this.$store.dispatch("lab/appointment/loadTests");

      this.$emit("next", {
        clinicType: clinicType,
        transactionType: transactionType
      });
    },

    stationaryAppointment() {
      this.selectedType(CLINIC_TYPE_STATIONARY, TRANSACTION_TYPE_APPOINTMENT);
    },

    stationarySchedule() {
      this.selectedType(CLINIC_TYPE_STATIONARY, TRANSACTION_TYPE_SCHEDULE);
    },

    stationaryWalkin() {
      this.selectedType(CLINIC_TYPE_STATIONARY, TRANSACTION_TYPE_WALKIN);
    }
  }
};
</script>
