import { DB, getCurrentUserUid } from "@/common/store";

import { db } from "@/main";

import "firebase/firestore";

export function addInformation(data) {
  let timestamp = new Date();
  return new Promise(resolve => {
    db.collection(DB.MEDICAL_HISTORY)
      .doc()
      .set(
        { ...data, userId: getCurrentUserUid(), date: timestamp },
        { merge: true }
      )
      .then(() => resolve())
      .catch(() => resolve());
  });
}

export function loadMedicalHistory() {
  return new Promise((resolve, reject) => {
    db.collection(DB.MEDICAL_HISTORY)
      .where("userId", "==", getCurrentUserUid())
      .orderBy("date", "desc")
      .get()
      .then(snapshot => {
        let medicalhistory = [];
        snapshot.forEach(doc => {
          medicalhistory.push({
            id: doc.id,
            data: doc.data()
          });
        });
        resolve(medicalhistory);
      })
      .catch(err => reject(err));
  });
}

export function loadSelectedMedicalHistory(idRef) {
  return new Promise((resolve, reject) => {
    db.collection(DB.MEDICAL_HISTORY)
      .doc(idRef)
      .get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          reject();
        }
      })
      .catch(err => reject(err));
  });
}
