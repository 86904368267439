import { LOCALE_EN, isLocaleTagalog } from "../locale";

import { getDate } from "../date";
import { getScheduleString } from "../appointments";

export const SCHEDULE_TYPE_AFTERNOON_ON_DATE = "Afternoon on Selected Date";
export const SCHEDULE_TYPE_ANYTIME_ON_DATE = "Anytime on Selected Date";
export const SCHEDULE_TYPE_MORNING_ON_DATE = "Morning on Selected Date";
export const SCHEDULE_TYPE_SPECIFIC_TIME = "Specific Time";

function anyTimePrefix(locale) {
  return isLocaleTagalog(locale) ? "kahit anong oras sa" : "any time on";
}

function afternoonPrefix(locale) {
  return isLocaleTagalog(locale) ? "hapon ng" : "the afternoon of";
}

function morningPrefix(locale) {
  return isLocaleTagalog(locale) ? "umaga ng" : "the morning of";
}

export function getScheduleText(schedule, locale = LOCALE_EN) {
  let date = getDate(schedule.date);

  switch (schedule.type) {
    case SCHEDULE_TYPE_AFTERNOON_ON_DATE:
      return `${afternoonPrefix(locale)} ${date}`;

    case SCHEDULE_TYPE_ANYTIME_ON_DATE:
      return `${anyTimePrefix(locale)} ${date}`;

    case SCHEDULE_TYPE_MORNING_ON_DATE:
      return `${morningPrefix(locale)} ${date}`;

    case SCHEDULE_TYPE_SPECIFIC_TIME:
      return getScheduleString(schedule);
  }

  return "";
}

export function isSpecificTime(scheduleType) {
  return SCHEDULE_TYPE_SPECIFIC_TIME === scheduleType;
}
