<template>
  <div>
    <h3 class="mb-5 text-center">PhilHealth Information</h3>

    <InfoEntry
      header="PhilHealth ID"
      :content="philhealthString"
      class="background-grey py-2"
    />

    <InfoEntry header="Full Name" :content="fullName" class="py-2" />
    <InfoEntry
      header="Date of Birth"
      :content="birthday"
      class="background-grey py-2"
    />
    <InfoEntry header="Gender" :content="sex" class="py-2" />
  </div>
</template>

<script>
import {
  NS_MEMBERINFO as MEMBERINFO,
  NS_PHILHEALTHINFO as PHILHEALTHINFO
} from "@/store";

import InfoEntry from "@/components/Controls/InfoEntry.vue";

export default {
  name: "PhilHealthInformation",

  components: {
    InfoEntry
  },

  computed: {
    birthday() {
      return this.$store.getters[`${MEMBERINFO}/getBirthday`];
    },

    clientType() {
      return this.$store.getters[`${PHILHEALTHINFO}/getClientType`];
    },

    fullName() {
      return this.$store.getters[`${MEMBERINFO}/getFullName`];
    },

    philhealthId() {
      return this.$store.getters[`${PHILHEALTHINFO}/getPhilHealthId`];
    },

    philhealthString() {
      return `${this.philhealthId} (${this.clientType})`;
    },

    sex() {
      return this.$store.getters[`${MEMBERINFO}/getSex`];
    }
  }
};
</script>
