<template>
  <div class="row text-center ma-2">
    <v-row v-if="!wishlists.length">
      <v-col class="text-center">
        No item on your wishlist.
      </v-col>
    </v-row>

    <div
      v-else
      class="col-md-3 col-sm-3 col-xs-12 pa-1"
      v-for="(boxlist, blist) in wishlists"
      :key="blist"
    >
      <v-hover v-slot:default="{ hover }">
        <v-card v-model="hisid" class="mx-auto">
          <v-img
            class="white--text align-end"
            :aspect-ratio="2 / 2"
            :src="boxlist.imageUrl"
          >
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                style="height: 100%;"
              >
                <v-btn outlined>
                  {{
                    boxlist.inventory !== "0"
                      ? "Stock: " + boxlist.inventory
                      : "Out of Stock"
                  }}
                </v-btn>
              </div>
            </v-expand-transition>
          </v-img>

          <v-card-text class="pa-0">
            <div>
              <strong
                class="black--text"
                href="/product"
                style="text-decoration: none"
              >
                {{ boxlist.name }}
              </strong>
            </div>

            <CatalogRating :rating="boxlist.rating" :sold="boxlist.sold" />

            <strong v-if="boxlist.priceRange" class="primary--text mb-1">
              {{ boxlist.priceRange }}
            </strong>
            <strong v-else class="primary--text mb-1">
              <PesoAmount :amount="Number(boxlist.price)" />
            </strong>
          </v-card-text>

          <v-card-actions>
            <v-btn icon @click="removeWishlist(false, boxlist.id)">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>

            <v-spacer />

            <v-btn
              @click="loadata(boxlist)"
              @click.stop="dialog = true"
              x-small
              color="primary"
              fab
            >
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-hover>
    </div>

    <ConfirmDialog ref="confirm" @onClose="closeDialog()" />
  </div>
</template>
<script>
import { ROUTE_PRODUCTDETAILS, routeTo } from "@/router";
import { SERVICE_TINDAHAN } from "@/common/utilities/services";

import { getCurrentUserUid, logUserActivity } from "@/common/store";
import { mapState } from "vuex";
import { updateWishlist } from "@/utilities/collection/catalog";

import CatalogRating from "@/eTindahan/components/CatalogRating.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "Wishlists",

  components: {
    CatalogRating,
    ConfirmDialog,
    PesoAmount
  },

  data: () => ({
    hisid: ""
  }),

  computed: {
    ...mapState(["wishlists"])
  },

  mounted() {
    this.$store.dispatch("loadWishlists", { section: SERVICE_TINDAHAN });
  },

  methods: {
    constructProductDetails(boxlist) {
      let productDetails = {
        docid: boxlist.id,
        owner: boxlist.owner,
        selectedItemrating: boxlist.rating,
        selectedItemsold: boxlist.sold,
        name: boxlist.name,
        description: boxlist.description,
        downloadUrl: boxlist.imageUrl,
        category: boxlist.category,

        isPulled: boxlist.isPulled,

        priceRange: boxlist.priceRange || 0,
        price: Number(boxlist.price),

        inventory: Number(boxlist.inventory),

        itemShippingFee: boxlist.itemShippingFee,
        merchantName: boxlist.merchantName,
        merchantShippingFee: boxlist.merchantShippingFee,
        preOrderFrom: boxlist.preOrderFrom,
        preOrderTo: boxlist.preOrderTo,

        stockWarning: boxlist.stockWarning,
        pwdSc: boxlist.pwdSc,
        wishedBy: boxlist.wishedBy || []
      };

      if (boxlist.discount) {
        productDetails["discount"] = Number(boxlist.discount);
      }

      if (boxlist.discountUntil && boxlist.discountUntil.seconds) {
        productDetails["discountUntil"] = new Date(
          boxlist.discountUntil.seconds * 1000
        );
      }

      return productDetails;
    },

    loadata(boxlist) {
      this.$store.commit(
        "SET_PRODUCT_DETAILS",
        this.constructProductDetails(boxlist),
        { root: true }
      );

      logUserActivity("View Product", { target: this.hisid }).then(() => {
        routeTo(ROUTE_PRODUCTDETAILS);
      });
    },

    async removeWishlist(onWishlist, productSKU) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to remove on your Wishlist?",
          { noconfirm: false }
        )
      ) {
        updateWishlist({
          docid: productSKU,
          iname: getCurrentUserUid(),
          onWishlist: onWishlist
        });
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
    }
  }
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}

@media (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
