<template>
  <v-card>
    <h4 class="mt-3 text-center primary--text pt-2">
      Select Discounts
    </h4>
    <p class="text-center">{{ subheader }}</p>

    <div class="mt-10">
      <v-radio-group v-model="promoName">
        <v-row class="pr-3">
          <v-col cols="8" class="pt-0">
            <v-radio :value="PROMO_CODE" class="ml-5 pt-2">
              <template v-slot:label>
                <v-text-field
                  v-model="promoCode"
                  label="Type promo code here"
                  :messages="errorMessage"
                  class="py-0 pr-5 renderError"
                />
              </template>
            </v-radio>
          </v-col>
          <v-col cols="4" class="pt-6  text-right">
            <PesoAmount :amount="promoDiscount" />
          </v-col>
        </v-row>

        <v-row v-if="isSenior">
          <v-col cols="9" class="pt-0">
            <v-radio
              label="Senior Citizen Discount (Less 20%)"
              :value="SENIOR_CITIZEN"
              class="ml-5"
            />
          </v-col>
          <v-col cols="4" class="pt-0 text-right">
            <PesoAmount :amount="pwdDiscount" />
          </v-col>
        </v-row>

        <v-row v-if="isPWD" class="pr-3">
          <v-col cols="8" class="pt-0">
            <v-radio
              label="PWD Discount (Less 20%)"
              :value="PWD"
              class="ml-5"
            />
          </v-col>
          <v-col cols="4" class="pt-0 text-right">
            <PesoAmount :amount="pwdDiscount" />
          </v-col>
        </v-row>
      </v-radio-group>
    </div>

    <div class="text-center">
      <v-btn small rounded @click="onConfirm" tile color="success" class="my-4">
        Confirm
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import {
  ComputePromoCodeDiscount,
  PROMO_CODE,
  PWD,
  SENIOR_CITIZEN
} from "@/utilities/discounts";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "SelectedDiscount",

  components: {
    PesoAmount
  },

  props: {
    discounts: Array,
    isPWD: String,
    isSenior: String,
    promo: Object,
    subheader: String,
    subtotal: Number
  },

  data: () => ({
    errorMessage: "",

    promoCode: "",
    promoDescription: "",
    promoDiscount: 0,
    promoName: "",

    PROMO_CODE: PROMO_CODE,
    PWD: PWD,
    SENIOR_CITIZEN: SENIOR_CITIZEN
  }),

  mounted() {
    if (Object.keys(this.promo).length) {
      this.promoName = this.promo.name;
      this.promoCode = this.promo.promoCode;
      this.promoDiscount = this.promo.amount;
    }
  },

  methods: {
    onConfirm() {
      let promoDetails = {};

      switch (this.promoName) {
        case this.PWD:
        case this.SENIOR_CITIZEN:
          promoDetails = this.pwdSeniorPromoDetails();
          break;

        default:
          if (this.promoDiscount < 0) {
            promoDetails = this.promoCodePromoDetails();
          }
          break;
      }

      this.$emit("setPromo", promoDetails);
    },

    pwdSeniorPromoDetails() {
      return {
        name: this.promoName,
        promoCode: "",
        description: `${this.promoName} Discount (-20%)`,
        amount: this.pwdDiscount
      };
    },

    promoCodePromoDetails() {
      return {
        name: "promoCode",
        promoCode: this.promoCode,
        description: this.promoDescription,
        amount: this.promoDiscount
      };
    }
  },

  watch: {
    promoCode() {
      if (this.promoCode && this.promoCode.length > 4) {
        let result = ComputePromoCodeDiscount(
          this.discounts,
          this.promoCode,
          this.subtotal
        );

        this.promoDescription = result.description;
        this.promoDiscount = result.amount;
        this.errorMessage = result.error;
      } else {
        this.promoDiscount = 0;
        this.errorMessage = this.promoCode
          ? `${this.promoCode} is not a valid promo code.`
          : "";
      }
    }
  },

  computed: {
    pwdDiscount() {
      return this.subtotal * -0.2;
    }
  }
};
</script>

<style scoped>
.renderError >>> .v-messages {
  color: red !important;
}
</style>
