<template>
  <v-btn color="primary" @click="recordActivityAndEmit">
    {{ $t("dialog.confirmSchedule") }}
  </v-btn>
</template>

<script>
export default {
  name: "ConfirmScheduleButton",

  methods: {
    recordActivityAndEmit() {
      this.$store.dispatch("resetSecondsIdle").then(() => {
        this.$emit("click");
      });
    }
  }
};
</script>
