<template>
  <div>
    <BackButton @click="$emit('back')" />

    <h3 class="mb-5 mt-10 text-center">Personal and Social History</h3>

    <v-row class="background-grey mt-5 mx-0 px-2 py-2">
      <v-col :cols="leadRowCol" class="px-0">Smoking</v-col>
      <v-col :cols="selectRowCol" class="pa-0 pr-2 select-container">
        <v-select
          :items="smokingSelections"
          v-model="smoking"
          placeholder="This is required"
        />
      </v-col>

      <v-col
        :cols="detailsSpacerCol"
        v-show="hasDetailsSpacer && showSmokingDetails(smoking)"
        class="pa-0"
      />
      <v-col
        :cols="detailsRowCol"
        v-show="showSmokingDetails(smoking)"
        class="pa-0 pr-2 select-container"
      >
        <div>
          <v-select
            :items="smokerSelections"
            v-model="smokerSelection"
            placeholder="This is required"
            class="pr-2 py-0"
          />

          <div class="mt-n3 pa-0 select-container">
            <v-text-field
              v-model="smokingDetails"
              :label="SINCE_WHEN"
              v-if="STOPPED_SMOKING === smokerSelection"
              class="centered-input mt-1 py-2"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-0 px-2 py-5">
      <v-col :cols="leadRowCol" class="px-0">Alcohol</v-col>
      <v-col :cols="selectRowCol" class="pa-0 pr-2 select-container">
        <v-select
          :items="alcoholSelections"
          v-model="alcohol"
          placeholder="This is required"
        />
      </v-col>

      <v-col
        :cols="detailsSpacerCol"
        v-show="hasDetailsSpacer && showAlcoholDetails(alcohol)"
        class="pa-0"
      />
      <v-col
        :cols="detailsRowCol"
        v-show="showAlcoholDetails(alcohol)"
        class="pa-0 text-center"
      >
        <div>
          Specify # of glasses <br />

          <div class="d-inline-flex">
            <div width="50%" class="pa-0 select-container">
              <v-text-field
                v-model="alcoholGlasses"
                class="centered-input mt-1 pa-0"
              />
            </div>
            <div width="50%" class="pa-0 pl-2 select-container">
              <v-select :items="timePeriods" v-model="timePeriod" />
            </div>
          </div>

          <div class="mt-n3 pa-0 select-container">
            <v-text-field
              v-model="lastIntake"
              label="Last date of intake"
              class="centered-input mt-3 pa-0"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="background-grey mx-0 py-5 px-2">
      <v-col :cols="leadRowCol" class="px-0">Illicit Drugs</v-col>
      <v-col :cols="selectRowCol" class="pa-0 pr-2 select-container">
        <v-select
          :items="illicitDrugsSelections"
          v-model="illicitDrugs"
          placeholder="This is required"
        />
      </v-col>

      <v-col
        :cols="detailsSpacerCol"
        v-show="hasDetailsSpacer && showIllicitDrugsDetails(illicitDrugs)"
        class="pa-0"
      />
      <v-col
        :cols="detailsRowCol"
        v-show="showIllicitDrugsDetails(illicitDrugs)"
        class="mt-1 pa-0 select-container"
      >
        <v-text-field
          v-model="illicitDrugsDetails"
          class="centered-input mt-0 pa-0"
          :label="SINCE_WHEN"
        />
      </v-col>
    </v-row>

    <Illnesses
      header="Present Illnesses"
      :namespace="NAMESPACE"
      getValue="getIllness"
      getValues="getIllnesses"
      setValue="setIllnessValue"
      setComment="setIllnessComment"
      class="mt-15"
    />

    <Illnesses
      header="Immunization History"
      :namespace="NAMESPACE"
      getValue="getImmunization"
      getValues="getImmunizations"
      placeholder="Please specify date"
      setValue="setImmunizationValue"
      setComment="setImmunizationComment"
      class="mt-15"
    />

    <h4 class="mb-5 mt-15 text-center">
      <u>Maintenance Medication</u>
    </h4>

    <v-textarea
      v-model="maintenanceMedications"
      placeholder="Place any medication information here."
    />

    <NextButton
      @click="onNext"
      :disabled="isSubmitDisabled"
      class="mb-5 mt-15"
    />
  </div>
</template>

<script>
import {
  KEY_ALCOHOL,
  KEY_ILLICIT_DRUGS,
  KEY_SMOKING
} from "@/HealthRecords/store/personal_social_history";
import { NS_HEALTHRECORDS_PERSONALSOCIALHISTORY as NAMESPACE } from "@/store";

import { getCurrentUserUid } from "@/common/store";
import { isLGOrSmaller, setWidth } from "@/utilities/display";

import BackButton from "@/components/Controls/BackButton.vue";
import Illnesses from "@/HealthRecords/components/Illnesses.vue";
import NextButton from "@/components/Controls/NextButton.vue";

const I_DONT_DRINK = "I don't drink";
const NON_SMOKER = "Non-smoker";
const SMOKER = "Smoker";
const STOPPED_USING = "Stopped using";
const STOPPED_SMOKING = "Stopped smoking";

export default {
  name: "PersonalSocialHistory",

  components: {
    BackButton,
    Illnesses,
    NextButton
  },

  props: {
    collection: String
  },

  data: () => ({
    alcoholSelections: [
      "I don't drink",
      "Seldom",
      "Regularly",
      "Frequently",
      "Others"
    ],

    illicitDrugsSelections: [
      "Not a user",
      STOPPED_USING,
      "Occasionally",
      "Regularly"
    ],

    smokerSelections: [
      "Stopped smoking",
      "Less than 10 sticks a day",
      "10 to 20 sticks a day",
      "More than 20 sticks a day"
    ],

    smokingSelections: [NON_SMOKER, SMOKER, STOPPED_SMOKING],

    timePeriods: ["per month", "per week", "per day"],

    NAMESPACE: NAMESPACE,
    SINCE_WHEN: "Since when?",
    STOPPED_SMOKING: STOPPED_SMOKING
  }),

  mounted() {
    this.smokerSelections = [
      this.STOPPED_SMOKING,
      "Less than 10 sticks a day",
      "10 to 20 sticks a day",
      "More than 20 sticks a day"
    ];

    this.$store.dispatch(`${this.NAMESPACE}/load`);
  },

  methods: {
    onNext() {
      this.$store
        .dispatch(`${this.NAMESPACE}/save`, {
          collection: this.collection,
          docId: getCurrentUserUid()
        })
        .then(() => {
          this.$emit("next");
        });
    },

    setWidth(xl, lg, md, sm, fall) {
      return setWidth(this.$vuetify.breakpoint.width, xl, lg, md, sm, fall);
    },

    showAlcoholDetails(value) {
      switch (value) {
        case "":
        case I_DONT_DRINK:
          return false;
      }

      return true;
    },

    showIllicitDrugsDetails(value) {
      return STOPPED_USING === value;
    },

    showSmokingDetails(value) {
      return SMOKER === value;
    }
  },

  computed: {
    alcohol: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getValue`](KEY_ALCOHOL);
      },
      set(value) {
        if (!this.showAlcoholDetails(value)) {
          this.$store.commit(`${this.NAMESPACE}/setAlcoholGlasses`, "");
          this.$store.commit(`${this.NAMESPACE}/setAlcoholLastIntake`, "");
          this.$store.commit(`${this.NAMESPACE}/setAlcoholPeriod`, "");
        }

        this.$store.commit(`${this.NAMESPACE}/setValue`, {
          key: KEY_ALCOHOL,
          value: value
        });
      }
    },

    alcoholGlasses: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getAlcoholGlasses`];
      },
      set(value) {
        this.$store.commit(`${this.NAMESPACE}/setAlcoholGlasses`, value);
      }
    },

    detailsRowCol() {
      return this.setWidth(5, 5, 6, 10, 10);
    },

    detailsSpacerCol() {
      return this.setWidth(4, 4, 6, 2, 2);
    },

    hasDetailsSpacer() {
      return isLGOrSmaller(this.$vuetify.breakpoint.width);
    },

    illicitDrugs: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getValue`](
          KEY_ILLICIT_DRUGS
        );
      },
      set(value) {
        if (!this.showIllicitDrugsDetails(value)) {
          this.$store.commit(`${this.NAMESPACE}/setComment`, {
            key: KEY_ILLICIT_DRUGS,
            value: ""
          });
        }

        this.$store.commit(`${this.NAMESPACE}/setValue`, {
          key: KEY_ILLICIT_DRUGS,
          value: value
        });
      }
    },

    illicitDrugsDetails: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getComment`](
          KEY_ILLICIT_DRUGS
        );
      },
      set(value) {
        this.$store.commit(`${this.NAMESPACE}/setComment`, {
          key: KEY_ILLICIT_DRUGS,
          value: value
        });
      }
    },

    isSubmitDisabled: {
      get() {
        return !this.alcohol || !this.smoking || !this.illicitDrugs;
      }
    },

    lastIntake: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getAlcoholLastIntake`];
      },
      set(value) {
        this.$store.commit(`${this.NAMESPACE}/setAlcoholLastIntake`, value);
      }
    },

    leadRowCol() {
      return this.setWidth(4, 4, 6, 6, 6);
    },

    maintenanceMedications: {
      get() {
        return this.$store.getters[
          `${this.NAMESPACE}/getMaintenanceMedications`
        ];
      },
      set(value) {
        this.$store.commit(
          `${this.NAMESPACE}/setMaintenanceMedications`,
          value
        );
      }
    },

    selectRowCol() {
      return this.setWidth(3, 3, 6, 6, 6);
    },

    showStoppedUsing() {
      return STOPPED_USING === this.illicitDrugs;
    },

    smokerDetails: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getSmokingDetails`];
      },
      set(value) {
        this.$store.commit(`${this.NAMESPACE}/setSmokingDetails`, value);
      }
    },

    smokerSelection: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getComment`](KEY_SMOKING);
      },
      set(value) {
        if (this.STOPPED_SMOKING === value) {
          this.$store.commit(`${this.NAMESPACE}/setSmokingDetails`, "");
        }

        this.$store.commit(`${this.NAMESPACE}/setComment`, {
          key: KEY_SMOKING,
          value: value
        });
      }
    },

    smoking: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getValue`](KEY_SMOKING);
      },
      set(value) {
        this.smokerSelection = this.showSmokingDetails(value)
          ? this.smokerSelections[0]
          : "";

        this.$store.commit(`${this.NAMESPACE}/setValue`, {
          key: KEY_SMOKING,
          value: value
        });
      }
    },

    smokingDetails: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getSmokingDetails`];
      },
      set(value) {
        this.$store.commit(`${this.NAMESPACE}/setSmokingDetails`, value);
      }
    },

    smokingPlaceholderText() {
      return NON_SMOKER === this.smoking ? this.SINCE_WHEN : "";
    },

    timePeriod: {
      get() {
        return this.$store.getters[`${this.NAMESPACE}/getAlcoholPeriod`];
      },
      set(value) {
        this.$store.commit(`${this.NAMESPACE}/setAlcoholPeriod`, value);
      }
    }
  }
};
</script>

<style scoped>
.select-container >>> .v-input {
  padding-top: 0;
}

.select-container >>> input {
  text-align: center;
}
</style>
