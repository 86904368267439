<template>
  <v-card class="my-2 mx-1">
    <v-toolbar
      color="primary"
      class="text-center white--text mb-5 pa-0"
      height="35"
    >
      <v-list-item-title class="text-center">
        Test Results
      </v-list-item-title>
    </v-toolbar>

    <SelectService
      :userCode="userCode"
      @serviceDetails="onServiceSelected"
      @onLoad="onServiceLoad"
    />

    <div class="text-left pb-5 px-2">
      <div class="pa-0">
        <strong class="text-subtitle-2">Doctor's Assessment</strong>:
        <span>
          <u @click="openLink(ROUTE_PHYSICAL_EXAM)" class="primary--text">
            View
          </u>
        </span>
        <br />
      </div>

      <div v-show="cbc" class="pa-0">
        <strong class="text-subtitle-2">CBC</strong>:
        <span>
          <a :href="cbc" target="_blank">Open in new tab</a>
        </span>
        <br />
      </div>

      <div v-show="labResult.length > 0" class="pa-0">
        <strong class="text-subtitle-2">Lab Results</strong>:
        <br />
        <span v-for="(result, index) in labResult" :key="index">
          <a :href="result.url" target="_blank">{{ result.file.testType }}</a>
          <br />
        </span>
      </div>

      <div v-show="ecg" class="pa-0">
        <strong class="text-subtitle-2">ECG</strong>:
        <span>
          <a :href="ecg" target="_blank">Open in new tab</a>
        </span>
        <br />
      </div>

      <div v-show="papSmear" class="pa-0">
        <strong class="text-subtitle-2">Pap Smear</strong>:
        <span>
          <a :href="papSmear" target="_blank">Open in new tab</a>
        </span>
        <br />
      </div>

      <div v-show="xray" class="pa-0">
        <strong class="text-subtitle-2">Xray</strong>:
        <span>
          <a :href="xray" target="_blank">Open in new tab</a>
        </span>
        <br />
      </div>

      <div class="pa-0">
        <strong class="text-subtitle-2">Fecalyis</strong>:
        <span>
          <u class="primary--text" @click="openLink(ROUTE_FECALYSIS)">
            Open in new tab
          </u>
        </span>
        <br />
      </div>

      <div class="pa-0">
        <strong class="text-subtitle-2">Urinalysis</strong>:
        <span>
          <u class="primary--text" @click="openLink(ROUTE_URINALYSIS)">
            Open in new tab
          </u>
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import { DATA, getUploadData } from "@/store/offsiteService";
import {
  ROUTE_PHYSICAL_EXAM,
  ROUTE_URINALYSIS,
  ROUTE_FECALYSIS
} from "@/router/results.js";

import {
  getDoctorAssessment,
  getTriage,
  getUrinalysisData,
  getUrinalysisDataFromResult,
  getFecalysisData,
  getFecalysisDataFromResult
} from "@/store/submissions";
import { routeTo } from "@/router";

import SelectService from "@/HealthRecords/components/SelectService.vue";

export default {
  components: {
    SelectService
  },

  props: {
    userCode: String
  },

  data: () => ({
    isLoading: true,

    labResult: [],
    ecg: "",
    cbc: "",
    papSmear: "",
    xray: "",
    doctor: "",
    activeServiceId: "",
    activeServiceSchedule: null,

    fecalysis: "",
    urinalysis: "",
    assessment: "",
    urinalysisData: {},
    fecalysisData: {},
    triage: {},
    doctorAssessment: {},

    ROUTE_PHYSICAL_EXAM: ROUTE_PHYSICAL_EXAM,
    ROUTE_URINALYSIS: ROUTE_URINALYSIS,
    ROUTE_FECALYSIS: ROUTE_FECALYSIS
  }),

  watch: {
    async activeServiceId(value) {
      this.isLoading = true;

      this.cbc = "";
      this.labResult = [];
      this.ecg = "";
      this.papSmear = "";
      this.xray = "";
      this.doctor = "";

      if (value) {
        try {
          await this.setData(value);
        } finally {
          this.isLoading = false;
        }
      }
      this.isLoading = false;
    }
  },

  methods: {
    getAssessment(details) {
      return details ? details.assessment : "";
    },

    async getFecalysis(serviceId, userCode) {
      this.fecalysisData = {};

      try {
        const result = await getFecalysisData(serviceId, userCode);
        if (Object.keys(result).length > 0) {
          const data = getFecalysisDataFromResult(result);
          this.fecalysisData = { ...data };
          this.$store.commit("setFecalysisData", this.fecalysisData);
        }
      } catch (err) {
        this.errorMessage = `Get submissions error: ${err}`;
      }
    },

    async getTriage(serviceId, userCode) {
      this.triage = await getTriage(serviceId, userCode);
      this.$store.commit("setTriage", this.triage);

      return this.triage.scheduleId;
    },

    async getUploadData(value, key, defaultValue = "") {
      const data = await getUploadData(value, this.userCode, key);
      return data.url || defaultValue;
    },

    async getUrinalysis(serviceId, userCode) {
      this.urinalysisData = {};

      try {
        const result = await getUrinalysisData(serviceId, userCode);
        const data = getUrinalysisDataFromResult(result);
        this.urinalysisData = { ...data };
        this.$store.commit("setUrinalysisData", this.urinalysisData);
        this.loadAssessment(serviceId, userCode);
      } catch (err) {
        this.errorMessage = `Get submissions error: ${err}`;
      }
    },

    async loadAssessment(serviceId, userCode) {
      const scheduleId = await this.getTriage(serviceId, userCode);

      const details = await getDoctorAssessment(scheduleId, userCode);
      this.doctorAssessment = this.getAssessment(details);
      this.$store.commit("setDoctorAssessment", details);
    },

    onServiceLoad(services) {
      this.$emit("hasTest", services && services.length > 0);
    },

    onServiceSelected(details) {
      this.activeServiceId = details.id;
      this.activeServiceSchedule = details.schedule;
      this.$store.commit("setResultsID", {
        serviceId: this.activeServiceId,
        serviceSchedule: this.activeServiceSchedule,
        userCode: this.userCode
      });
    },

    openLink(val) {
      routeTo(val);
    },

    async setData(value) {
      this.cbc = await this.getUploadData(value, DATA.CBC);
      this.labResult = await this.getUploadData(value, DATA.LAB_RESULTS, []);
      this.ecg = await this.getUploadData(value, DATA.ECG);
      this.papSmear = await this.getUploadData(value, DATA.PAP_SMEAR);
      this.xray = await this.getUploadData(value, DATA.XRAY);
      this.doctor = await this.getUploadData(value, DATA.DOCTOR);

      await this.getFecalysis(value, this.userCode);
      await this.getUrinalysis(value, this.userCode);
    }
  }
};
</script>

<style scoped>
u {
  text-decoration: underline;
  cursor: pointer;
}
</style>
