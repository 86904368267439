<template>
  <div>
    <p class="caption mb-0" v-if="rating">
      Rating: {{ rating.toFixed(2) }} | Sold: {{ sold }}
    </p>

    <p class="caption mb-0" v-else>
      Try this Product
    </p>

    <v-rating
      :value="rating"
      color="yellow darken-3"
      background-color="grey darken-1"
      empty-icon="$ratingFull"
      half-increments
      hover
      x-small
      readonly
    />
  </div>
</template>

<script>
export default {
  name: "CatalogRating",

  props: {
    rating: Number,
    sold: Number
  }
};
</script>
