<template>
  <v-container>
    <p class="text-center mt-3 pt-3">
      Please select your preferred medical service.
    </p>

    <v-layout row wrap class="pa-3">
      <div
        class="col-md-12 col-sm-12 col-xs-12 mt-3 pa-1"
        v-for="(clinicinf, t) in clinicInfo"
        :key="t"
      >
        <v-hover v-slot:default="{ hover }" open-delay="100">
          <v-card
            :elevation="hover ? 16 : 2"
            class="px-6 py-4"
            height="100%"
            @click="$emit('clinicType', clinicinf.type)"
            :disabled="clinicinf.disabled"
          >
            <div class="text-center">
              <b class="primary--text">
                {{ reTitle(clinicinf.type, clinicinf.disabled) }}
                <v-divider class="pa-2"></v-divider>
              </b>
            </div>
            <div>{{ clinicinf.description }}</div>
          </v-card>
        </v-hover>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "SelectService",

  data: () => ({
    clinicInfo: [
      {
        id: 1,
        type: "Stationary",
        description: `Offers Diagnostic Laboratory and X-Ray Services in our Station at Medisure Plus Diagnostic Laboratory 16B Williams, Mandaluyong, Metro Manila, for the convenience of our patients who prefer to have tests done at their preferred time.`,
        disabled: true
      },
      {
        id: 2,
        type: "Home Service",
        description:
          "Offers Home Service Diagnostic Laboratory and X-Ray Services for the convenience of our patients who prefer to have tests done at their preferred time and place.",
        disabled: true
      },
      {
        id: 3,
        type: "Mobile",
        description:
          "Offers Diagnostic Laboratory and X-Ray Services for the convenience of our patients who prefer to have tests done at their preferred time.",
        disabled: true
      }
    ]
  }),

  methods: {
    reTitle(type, isDisabled) {
      switch (type) {
        case "Stationary":
          type = "Main Office";
          break;

        case "Home Service":
          break;

        default:
          type = "Mobile";
          break;
      }

      return isDisabled ? `${type} (Coming Soon)` : type;
    }
  }
};
</script>
