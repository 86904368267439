export const SERVICE_LABORATORY = "e-Laboratory";
export const SERVICE_DOCTOR = "e-Konsulta";
export const SERVICE_TINDAHAN = "e-Tindahan";
export const SERVICE_BOTIKA = "e-Botika";
export const SERVICE_BANTAY_KARD = "BantayKard";

export function isLaboratoryService(service) {
  return service === SERVICE_LABORATORY;
}

export function isDOCTORService(service) {
  return service === SERVICE_DOCTOR;
}

export function isTindahanService(service) {
  return service === SERVICE_TINDAHAN;
}
export function isBotikaService(service) {
  return service === SERVICE_BOTIKA;
}
export function isBantayKard(service) {
  return service === SERVICE_BANTAY_KARD;
}
