<template>
  <span v-if="displayAmount.startsWith('-')" style="color: red">
    ({{ displayAmount.substring(1, displayAmount.length) }})
  </span>
  <span v-else>{{ displayAmount }}</span>
</template>

<script>
import { displayAmount } from "../utilities/number";

export default {
  name: "Amount",

  props: {
    amount: Number
  },

  computed: {
    displayAmount() {
      return displayAmount(this.amount);
    }
  }
};
</script>
