<template>
  <div class="pa-0">
    <v-card>
      <v-tabs v-model="tab" color="primary" centered>
        <v-tab v-model="search">
          <v-badge
            v-if="countProcessing > 0"
            color="primary"
            :content="countProcessing"
          >
            Processing
          </v-badge>
          <div class="pa-0" v-else>Processing</div>
        </v-tab>

        <v-tab v-model="search">
          <v-badge
            v-if="countToShip > 0"
            color="primary"
            :content="countToShip"
          >
            to Ship
          </v-badge>
          <div class="pa-0" v-else>to Ship</div>
        </v-tab>

        <v-tab v-model="search">
          <v-badge
            v-if="countShipping > 0"
            color="primary"
            :content="countShipping"
          >
            To Receive
          </v-badge>
          <div class="pa-0" v-else>To Receive</div>
        </v-tab>

        <v-tab v-model="search">
          <v-badge
            v-if="countCompleted > 0"
            color="primary"
            :content="countCompleted"
          >
            Completed
          </v-badge>
          <div class="pa-0" v-else>Completed</div>
        </v-tab>

        <v-tab v-model="search">
          <v-badge
            v-if="countCancelled > 0"
            color="primary"
            :content="countCancelled"
          >
            Cancelled/Void
          </v-badge>
          <div class="pa-0" v-else>Cancelled/Void</div>
        </v-tab>

        <v-tab v-model="search">
          <v-badge
            v-if="countReturnRefund > 0"
            color="primary"
            :content="countReturnRefund"
          >
            Return/Refund
          </v-badge>
          <div class="pa-0" v-else>Return/Refund</div>
        </v-tab>

        <v-tab-item :value="0">
          <OrderTab
            :status="search"
            :filteredStat="filteredStat"
            noEntryContent="No orders for processing"
          />
        </v-tab-item>

        <v-tab-item :value="1">
          <OrderTab
            :status="search"
            :filteredStat="filteredStat"
            noEntryContent="No orders to Ship"
          />
        </v-tab-item>

        <v-tab-item :value="2">
          <OrderTab
            :status="search"
            :filteredStat="filteredStat"
            noEntryContent="No orders for shipping"
          />
        </v-tab-item>

        <v-tab-item :value="3">
          <OrderTab
            :status="search"
            :filteredStat="filteredStat"
            noEntryContent="No orders completed"
          />
        </v-tab-item>

        <v-tab-item :value="4">
          <OrderTab
            :status="search"
            :filteredStat="filteredStat"
            noEntryContent="No orders cancelled"
          />
        </v-tab-item>

        <v-tab-item :value="5">
          <OrderTab
            :status="search"
            :filteredStat="filteredStat"
            noEntryContent="No orders return/refund"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_PAYMENT_VERIFICATION,
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_RETURNREFUND,
  ORDER_STATUS_SHIPPING,
  ORDER_STATUS_TOSHIP
} from "@/common/utilities/order";

import { mapState } from "vuex";

import OrderTab from "@/eTindahan/components/OrderTab.vue";

export default {
  name: "TrackOrder",

  components: {
    OrderTab
  },

  data: () => ({
    countCancelled: 0,
    countCompleted: 0,
    countProcessing: 0,
    countReturnRefund: 0,
    countShipping: 0,
    countToShip: 0,
    search: ORDER_STATUS_PROCESSING,
    tab: null
  }),

  created() {
    this.loadCheckouts();
  },

  computed: {
    ...mapState(["checkouts"]),

    filteredStat() {
      this.checkouts.forEach(checkout => {
        if (checkout.latestReturnRefund) {
          this.iterateCheckout(checkout, checkout.latestReturnRefund);
        }
      });

      if (this.search !== ORDER_STATUS_PROCESSING) {
        return this.checkouts.filter(checkout => {
          return checkout.status === this.search;
        });
      } else {
        return this.checkouts.filter(checkout => {
          return [
            ORDER_STATUS_PROCESSING,
            ORDER_STATUS_PAYMENT_VERIFICATION
          ].includes(checkout.status);
        });
      }
    }
  },

  methods: {
    iterateCheckout(checkout, lastestReturnRefund) {
      this.$store.dispatch("addCheckout", {
        ...checkout,
        lastestReturnRefund,
        status: ORDER_STATUS_RETURNREFUND
      });
    },

    loadCheckouts() {
      this.$store.dispatch("loadCheckout");
    }
  },

  watch: {
    checkouts(value) {
      this.countProcessing = 0;
      this.countToShip = 0;
      this.countShipping = 0;
      this.countCompleted = 0;
      this.countCancelled = 0;
      this.countReturnRefund = 0;

      value.forEach(checkout => {
        switch (checkout.status) {
          case ORDER_STATUS_CANCELLED:
            return this.countCancelled++;

          case ORDER_STATUS_COMPLETED:
            return this.countCompleted++;

          case ORDER_STATUS_PAYMENT_VERIFICATION:
            return this.countProcessing++;

          case ORDER_STATUS_PROCESSING:
            return this.countProcessing++;

          case ORDER_STATUS_RETURNREFUND:
            return this.countReturnRefund++;

          case ORDER_STATUS_SHIPPING:
            return this.countShipping++;

          case ORDER_STATUS_TOSHIP:
            return this.countToShip++;
        }
      });
    },

    search() {
      this.loadCheckouts();
    },

    tab(value) {
      switch (value) {
        case 0:
          this.search = ORDER_STATUS_PROCESSING;
          break;

        case 1:
          this.search = ORDER_STATUS_TOSHIP;
          break;

        case 2:
          this.search = ORDER_STATUS_SHIPPING;
          break;

        case 3:
          this.search = ORDER_STATUS_COMPLETED;
          break;

        case 4:
          this.search = ORDER_STATUS_CANCELLED;
          break;

        case 5:
          this.search = ORDER_STATUS_RETURNREFUND;
          break;
      }
    }
  }
};
</script>
