<template>
  <v-container>
    <Header />
    <h1 class="text-center fontLarge colored py-8">
      THANK YOU FOR YOUR SPONSORSHIP!!
    </h1>

    <p class="fontMedium py-8">
      You will be helping residents of Siargao Island. You can visit
      <a href="medisureonline.com/isda">isdafoundation.com</a> for updates on
      their programs and projects.
    </p>
    <Footer />
  </v-container>
</template>

<script>
import Header from "@/ISDA/components/Header.vue";
import Footer from "@/ISDA/components/Footer.vue";
export default {
  name: "PaymentSuccess",
  components: {
    Header,
    Footer
  },

  data: () => ({
    orderReference: ""
  }),

  mounted() {
    this.orderReference = this.$route.query.Ref;
  }
};
</script>
<style scoped>
.wrap {
  margin: auto;
}
.select-container >>> input {
  text-align: center;
}
.colored {
  color: #283d19;
}

p {
  font-family: "Avenir", Helvetica, sans-serif;
}

.fontLarge {
  font-size: 60px;
}
.fontMedium {
  font-size: 22px;
}
</style>
