<template>
  <div
    :class="{
      'px-5': $vuetify.breakpoint.mobile,
      'px-15': !$vuetify.breakpoint.mobile
    }"
  >
    <h3 class="mb-15 mt-10 text-center">Medical History Recorded</h3>

    <p class="text-center">
      We received your medical history. Thank you!
    </p>

    <NextButton
      @click="routeTo(ROUTE_DASHBOARD)"
      text="Return Home"
      class="mb-5 mt-15"
    />
  </div>
</template>

<script>
import { ROUTE_DASHBOARD, routeTo } from "@/router";

import NextButton from "@/components/Controls/NextButton.vue";

export default {
  name: "Completed",

  components: {
    NextButton
  },

  data: () => ({
    ROUTE_DASHBOARD: ROUTE_DASHBOARD,

    routeTo: routeTo
  })
};
</script>
