<template>
  <v-container fluid>
    <div>
      Check those questions to which your answer is yes (leave others blank).
    </div>

    <v-row>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Heart problem if so, how many years ago? "
          value="Heart problem if so, how many years ago? "
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Diseases of the arteries"
          value="Diseases of the arteries"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Diabetes or abnormal blood-sugar tests"
          value="Diabetes or abnormal blood-sugar tests"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Epilepsy or seizures"
          value="Epilepsy or seizures"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox v-model="selectedPast" label="Stroke" value="Stroke" />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Nervous or emotional problems"
          value="Nervous or emotional problems"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox v-model="selectedPast" label="Anemia" value="Anemia" />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Thyroid problems"
          value="Thyroid problems"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Pneumonia"
          value="Pneumonia"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox v-model="selectedPast" label="Asthma" value="Asthma" />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Abnormal chest X-ray"
          value="Abnormal chest X-ray"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Other lung disease"
          value="Other lung diseasey"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Injuries to back, arms, legs or joint"
          value="Injuries to back, arms, legs or joint"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Jaundice or gall bladder problems"
          value="Jaundice or gall bladder problems"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="selectedPast"
          label="Allergies (Food, etc..)"
          value="Allergies (Food, etc..)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          v-model="selectedPastExpComment"
          name="input-7-1"
          label="Comment"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MedicalHistoryPastMedicalHistory",

  props: {
    pastMedicalHistory: Object
  },

  data: () => ({
    selectedPast: [],
    selectedPastExpComment: ""
  }),

  mounted() {
    if (this.pastMedicalHistory) {
      this.selectedPast = this.pastMedicalHistory.selectedPast;
      this.selectedPastExpComment = this.pastMedicalHistory.selectedPastExpComment;
    }
  },

  methods: {
    genData() {
      let pastMedicalHistory = {
        selectedPast: this.selectedPast,
        selectedPastExpComment: this.selectedPastExpComment
      };
      this.$emit("onPastMedicalHistory", pastMedicalHistory);
    }
  },

  watch: {
    selectedPast() {
      this.genData();
    },

    selectedPastExpComment() {
      this.genData();
    }
  }
};
</script>
