<template>
  <v-card align="center" class="pa-2">
    <p class="caption">
      Note: The serial number of every item must still be attached and make sure
      that the photos are clear. Otherwise, your request will be declined.
    </p>

    <CartSelection
      class="mt-5"
      :cartorders="cartorders"
      :modeOfPayment="modeOfPayment"
      :status="status"
      @selected-cart="selectedCart = $event"
      @refund-amount="refundAmount = $event"
      @isFilled="isFilled = $event"
    />

    <v-select
      v-model="reasonOption"
      class="px-2 select-container"
      :items="getReasons"
      label="Select Reason"
      :rules="fieldRules"
    />

    <div class="pa-0">
      <vuetify-money
        class="select-container"
        v-model="refundAmount"
        placeholder="Amount"
        v-bind:options="options"
      />

      <RefundPaymentOptions
        :latestReturnRefund="getReturnRefund()"
        :key="refundAmount"
        :balance="refundAmount"
        @selected-refundOption="selectedRefundOption = $event"
        @selected-accountBank="accountBank = $event"
        @selected-accountNumber="accountNumber = $event"
        @selected-accountName="accountName = $event"
        @selected-contact="contact = $event"
        @isValid="validPayment = $event"
      />
    </div>

    <div class="mb-4">
      <v-btn
        :disabled="!isValid() || !isFilled || !validPayment"
        @click="setReturnRefund()"
        outlined
        color="primary"
      >
        <v-icon>mdi-check</v-icon> Submit
      </v-btn>
    </div>

    <ConfirmDialog ref="confirm" @onClose="closeDialog()" />
  </v-card>
</template>

<script>
import { DB, loadBankCharge } from "@/common/store";
import {
  OPTION_BANK_ACCOUNT,
  STATUS_REFUND_REQUESTED
} from "@/common/utilities/payment";
import {
  ORDER_STATUS_RETURN,
  getReasonsReturn
} from "@/common/utilities/order";
import { ROUTE_TRACKORDER, routeTo } from "@/router";

import { checkOutReturnRefundPayment } from "@/store/payment";
import { db } from "@/main";
import { mapGetters } from "vuex";

import CartSelection from "@/common/components/CartSelection.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import RefundPaymentOptions from "@/eTindahan/components/RefundPaymentOptions.vue";

export default {
  name: "ReturnRefundInput",

  components: {
    CartSelection,
    ConfirmDialog,
    RefundPaymentOptions
  },

  data: () => ({
    accountBank: "",
    accountName: "",
    accountNumber: "",
    checkout: [],
    contact: "",
    getReasons: getReasonsReturn(),
    isFilled: false,
    modeOfPayment: "",
    options: {
      locale: "en-US",
      prefix: "₱",
      suffix: "",
      length: 10,
      precision: 2
    },
    reasonOption: "",
    refundAmount: 0,
    reinstateOption: ORDER_STATUS_RETURN,
    selectedCart: [],
    selectedRefundOption: "",
    status: "",
    validPayment: false,
    fieldRules: [v => !!v || "field required"],
    bankCharge: 0
  }),

  mounted() {
    this.checkout = this.$store.getters[
      "etindahan/checkout/getRefundDetails"
    ].checkout;
    this.modeOfPayment = this.checkout.modeOfPayment;
    this.status = this.checkout.status;
    this.fillCheckout(this.checkout);
  },

  computed: {
    ...mapGetters(["getAvatarUrl"]),

    ...mapGetters({
      cartorders: "etindahan/checkout/getCartDetails"
    })
  },

  watch: {
    selectedRefundOption() {
      loadBankCharge().then(docs => {
        this.bankCharge =
          OPTION_BANK_ACCOUNT === this.selectedRefundOption
            ? docs.bankCharge
            : 0;
      });
    }
  },

  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },

    fillCheckout(checkout) {
      if (checkout.latestReturnRefund) {
        this.reinstateOption = checkout.latestReturnRefund.reinstateOption;
        this.reasonOption = checkout.latestReturnRefund.reasonOption;
      }
    },

    getReturnRefund() {
      if (this.checkout.latestReturnRefund) {
        return this.checkout.latestReturnRefund;
      }
    },

    isValid() {
      return (
        Object.values(this.selectedCart).length > 0 &&
        this.reinstateOption !== "" &&
        this.reasonOption !== "" &&
        this.refundAmount >= 0
      );
    },

    setCartReturnRefund(timestamp, status, orderId) {
      Object.values(this.selectedCart).forEach(cart => {
        let details = {
          latestReturnRefund: {
            refundAmount:
              cart.price * Number(cart.latestReturnRefund.returnQuantity),
            status: status,
            requestedAt: timestamp,
            reinstateOption: this.reinstateOption,
            photoUrlSample: cart.latestReturnRefund.photoUrlSample,
            reason: cart.latestReturnRefund.reason,
            returnQuantity: Number(cart.latestReturnRefund.returnQuantity),
            maxQuantity: Number(cart.quantity),
            orderId: orderId
          }
        };

        db.collection(DB.ADDTOCART)
          .doc(cart.id)
          .set(details, { merge: true });
      });
    },

    setCheckoutReturnRefund(timestamp, status, orderId) {
      return new Promise((resolve, reject) => {
        let refDetails = {
          reinstateOption: this.reinstateOption,
          refundAmount: this.refundAmount,
          status: status,
          requestedAt: timestamp,
          selectedRefundOption: this.selectedRefundOption,
          reasonOption: this.reasonOption,
          bankCharge: this.bankCharge,
          accountBank: this.accountBank,
          accountName: this.accountName,
          accountNumber: this.accountNumber,
          contact: this.contact
        };

        let details = {
          latestReturnRefund: {
            ...refDetails
          }
        };

        db.collection(DB.CHECKOUT)
          .doc(orderId)
          .set(details, { merge: true })
          .then(() => resolve(details))
          .catch(error => reject(error));
      });
    },

    setPayment(timestamp) {
      let checkoutDetails = {
        ...this.checkout,
        reinstateOption: this.reinstateOption,
        refundAmount: this.refundAmount,
        status: STATUS_REFUND_REQUESTED,
        requestedAt: timestamp,
        paymentOption: this.selectedRefundOption,
        accountBank: this.accountBank,
        accountNumber: this.accountNumber,
        accountName: this.accountName,
        contact: this.contact,
        balance: this.refundAmount,
        bankCharge: this.bankCharge
      };
      checkOutReturnRefundPayment(checkoutDetails);
    },

    async setReturnRefund() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Please review the return/refund information to be submitted.",
          { noconfirm: false }
        )
      ) {
        let timestamp = new Date();
        let status = STATUS_REFUND_REQUESTED;
        let orderId = this.checkout.id;

        await this.setCheckoutReturnRefund(timestamp, status, orderId);
        this.setCartReturnRefund(timestamp, status, orderId);
        this.setPayment(timestamp);

        routeTo(ROUTE_TRACKORDER);
      }
    }
  }
};
</script>

<style scoped>
.select-container >>> input {
  text-align: center;
}
</style>
