import {
  STATUS_CONFIRMED,
  STATUS_FOR_PAYMENT_VERIFICATION
} from "@/common/utilities/appointments";

import { getCurrentUser, getCurrentUserUid } from "@/common/store";
import { isPaymentForValidation } from "@/common/utilities/payment";

function getStatus(modeOfPayment) {
  if (modeOfPayment == undefined) return "";
  return isPaymentForValidation(modeOfPayment)
    ? STATUS_FOR_PAYMENT_VERIFICATION
    : STATUS_CONFIRMED;
}

export function constructDataEntry(data) {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  let returnData = {
    clinicType: data.clinicType,
    specialization: data.specialization,
    doctorDetails: { ...data.doctorDetails },
    appointment: { ...data.appointment },
    schedule: { ...data.schedule },
    bill: {
      ...data.bill,
      dateToPay: tomorrow
    },
    payment: { ...data.payment },
    service: { ...data.service },
    status: getStatus(data.payment.modeOfPayment),
    timestamp: new Date(),
    user: {
      id: getCurrentUserUid(),
      name: getCurrentUser()
    }
  };

  if (data.id) {
    returnData["id"] = data.id;
  }

  return returnData;
}
