import { DB, getCurrentUserUid } from "@/common/store";

import { db } from "@/main";

function constructData(data) {
  let d = data.registrants;
  return {
    firstName: d.firstName,
    middleName: d.middleName,
    lastName: d.lastName,
    birthDate: d.birthDate,
    email: d.email,
    contact: d.contact,
    location: {
      barangay: d.location.barangay,
      municipality: d.location.municipality,
      province: d.location.province,
      houseNumber: d.houseNumber,
      address: d.address
    }
  };
}

export function setRegistrant(registrants) {
  let registrant = constructData(registrants);
  db.collection(DB.COVID_VACCINE)
    .doc()
    .set({
      ...registrant,
      host: getCurrentUserUid(),
      timestamp: new Date()
    });
}
