<template>
  <v-form ref="form1">
    <v-row>
      <v-col class="ml-8" cols="12" sm="5" v-if="hasPadding">
        <v-text-field
          label="Full Name"
          v-model="fullName"
          :rules="nameRules"
          prepend-icon="mdi-account-box-multiple"
          :disabled="disabled"
          @change="emitData"
        />
      </v-col>

      <v-col class="ml-4" cols="11" v-else>
        <v-text-field
          label="Full Name"
          v-model="fullName"
          :rules="nameRules"
          prepend-icon="mdi-account-box-multiple"
          :disabled="disabled"
          @change="emitData"
        />
      </v-col>

      <v-col cols="12" sm="5" v-if="hasPadding">
        <v-autocomplete
          label="Relationship to the Principal"
          v-model="relationship"
          :rules="nameRules"
          :disabled="disabled"
          :items="relationships"
        />
      </v-col>
    </v-row>

    <v-row v-if="!hasPadding">
      <v-col class="ml-4 mt-n7" cols="11">
        <v-text-field
          label="Relationship to the Principal"
          v-model="relationship"
          :rules="nameRules"
          :disabled="disabled"
          @change="emitData"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="ml-8 mt-n10" cols="12" sm="5" v-if="hasPadding">
        <SelectDate v-model="birthDate" :disabled="disabled" />
      </v-col>
      <v-col class="ml-4 mt-n7" cols="11" v-else>
        <SelectDate v-model="birthDate" :disabled="disabled" />
      </v-col>

      <v-col class="mt-n10 display-flex" cols="12" sm="5" v-if="hasPadding">
        <v-autocomplete
          label="Beneficiary Type"
          v-model="beneficiaryType"
          :disabled="disabled"
          :items="beneficiaryTypes"
          :rules="beneficiaryTypeRules"
          @change="emitData"
        />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="disabled" v-bind="attrs" v-on="on">
              mdi-help-circle
            </v-icon>
          </template>
          <span>
            <div class="text-width">
              {{ $t("insurance.benefeciaryTips") }}
            </div>
          </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="!hasPadding">
      <v-col class="ml-4 mt-n7 display-flex" cols="11" v-if="!hasPadding">
        <v-autocomplete
          label="Beneficiary Type"
          v-model="beneficiaryType"
          :disabled="disabled"
          :items="beneficiaryTypes"
          :rules="beneficiaryTypeRules"
          @change="emitData"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="disabled" v-bind="attrs" v-on="on">
              mdi-help-circle
            </v-icon>
          </template>
          <span>
            <div class="text-width">
              {{ $t("insurance.benefeciaryTips") }}
            </div>
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SelectDate from "@/common/components/controls/SelectDate.vue";

export default {
  name: "InsuranceBeneficiary",

  components: {
    SelectDate
  },

  props: {
    disabled: Boolean,
    id: String
  },

  computed: {
    hasPadding() {
      return window.innerWidth > 500;
    }
  },

  data: () => ({
    relationships: [
      "Mother",
      "Father",
      "Son",
      "Daughter",
      "Wife",
      "Husband",
      "Sister",
      "Brother"
    ],
    fullName: "",
    nameRules: [v => !!v || "Full Name is required"],

    relationship: "",

    birthDate: "",

    beneficiaryType: "",
    beneficiaryTypes: ["Revocable", "Irrevocable"],
    beneficiaryTypeRules: [b => !!b || "Beneficiary Type is required"]
  }),

  watch: {
    birthDate() {
      this.emitData();
    }
    },

  methods: {
    emitData() {
      let hasContent =
        this.fullName ||
        this.relationship ||
        this.birthDate ||
        this.beneficiaryType;

      if (!hasContent) {
        this.$refs.form1.reset();
      }
      this.$emit("beneficiary-data", {
        id: this.id,
        isValid: hasContent ? this.$refs.form1.validate() : false,
        hasContent: hasContent ? true : false,
        beneficiaryType: this.beneficiaryType,
        birthDate: this.birthDate,
        fullName: this.fullName,
        relationship: this.relationship
      });
    }
  }
};
</script>

<style scoped>
.display-flex {
  display: flex;
}

.text-width {
  width: 280px;
}
</style>
