<template>
  <v-card class="elevation-12">
    <v-toolbar color="green" dark flat>
      <v-toolbar-title class="centerThis">
        Terms and Conditions
      </v-toolbar-title>
      <v-col cols="2" class="floaterr pt-10">
        <v-select
          :items="locales"
          label="Locale"
          item-text="label"
          item-value="value"
          max-width="200"
          v-model="selectedLocale"
        />
      </v-col>
    </v-toolbar>

    <v-card-text>
      <v-checkbox
        v-model="terms"
        type="checkbox"
        :label="$t('register.consent')"
      />
    </v-card-text>

    <v-card-title class="mt-n10 wrap-properly">
      {{ $t("register.registrationTerm1") }}
    </v-card-title>

    <v-card-text>
      {{ $t("register.registrationTerm2") }}
    </v-card-text>

    <v-card-text>
      {{ $t("register.registrationTerm3") }}
    </v-card-text>

    <v-card-title class="mt-n2 wrap-properly">
      {{ $t("register.registrationTerm4") }}
    </v-card-title>

    <v-card-text>
      {{ $t("register.registrationTerm5") }}
    </v-card-text>

    <v-card-title class="mt-n2 wrap-properly">
      {{ $t("register.registrationTerm6") }}
    </v-card-title>

    <v-card-text>
      {{ $t("register.registrationTerm7") }}
    </v-card-text>

    <div class="continue mt-4 pb-6">
      <v-btn
        class="success"
        :disabled="!terms"
        @click="$emit('continue-registration')"
      >
        Continue Registration
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { LOCALE_CEB, LOCALE_EN, LOCALE_TL } from "@/common/utilities/locale";

export default {
  name: "RegistrationTerms",

  data: () => ({
    terms: false,
    locales: [
      {
        label: "English",
        value: LOCALE_EN
      },
      {
        label: "Tagalog",
        value: LOCALE_TL
      },
      {
        label: "Cebuano",
        value: LOCALE_CEB
      }
    ],
    selectedLocale: LOCALE_TL
  }),
  watch: {
    selectedLocale(locale) {
      this.$i18n.locale = locale;
    }
  }
};
</script>

<style scoped>
.floaterr {
  float: right;
}
.centerThis {
  margin: auto;
  text-align: center;
}

.continue {
  margin: auto;
  text-align: center;
}

.wrap-properly {
  word-break: break-word;
}
</style>
