<template>
  <div class="mt-15 text-center">
    <CircularProgress />
  </div>
</template>

<script>
import CircularProgress from "@/components/CircularProgress.vue";

export default {
  name: "Loading",

  components: {
    CircularProgress
  }
};
</script>
