<template>
  <div>
    <v-row align="center">
      <v-col cols="12">
        <v-select
          :items="specializations"
          label="Specialization"
          item-text="label"
          item-value="value"
          v-model="selectedSpecializationData"
          @change="
            $emit('update:selectedSpecialization', selectedSpecialization)
          "
        ></v-select>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" md="6">
        <v-select
          :items="visitTypes"
          label="Visit Type"
          v-model="selectedVisitTypeData"
          @change="$emit('update:selectedVisitType', selectedVisitTypeData)"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" v-if="selectedVisitType == 'Video Consultation'">
        <v-checkbox
          v-model="showNearFirstData"
          label="Show doctors near me first"
        ></v-checkbox>
      </v-col>
    </v-row>
    <SelectLocation
      :selectedLocation.sync="selectedLocation"
      v-if="locationNeeded"
    />
  </div>
</template>

<script>
import specializationsJson from "@/assets/specializations.json";
import SelectLocation from "@/components/SelectLocation.vue";

export default {
  name: "FilterDoctorService",

  components: {
    SelectLocation
  },

  props: {
    selectedSpecialization: {
      type: String,
      required: true
    },
    selectedVisitType: {
      type: String,
      required: true
    },
    showNearFirst: Boolean
  },

  data: () => ({
    specializations: [],
    visitTypes: ["Video Consultation", "Clinic Visit"],
    locationNeeded: true,
    selectedLocation: null,
    selectedVisitTypeData: "",
    showNearFirstData: false,

    selectedSpecializationData: {}
  }),

  created() {
    this.selectedVisitTypeData = this.selectedVisitType;
    this.showNearFirstData = this.showNearFirst;

    for (let i in specializationsJson) {
      let s = specializationsJson[i];
      let lbl = s.specialization;
      if (s.description !== "") {
        lbl += " (" + s.description + ")";
      }
      this.specializations.push({
        label: lbl,
        value: s.specialization
      });
    }
  },

  mounted() {
    this.selectedSpecializationData = this.selectedSpecialization;
  },

  watch: {
    selectedVisitType(type) {
      if (type == "Clinic Visit") {
        this.locationNeeded = true;
      } else {
        this.locationNeeded = this.showNearFirst;
      }
    },

    showNearFirstData(val) {
      this.locationNeeded = val;
      this.$emit("update:showNearFirst", this.showNearFirstData);
    },

    selectedLocation(location) {
      // propagate change in location
      this.$emit("update:selectedLocation", location);
    }
  }
};
</script>
