<template>
  <div>
    <BackButton @click="$emit('back')" />

    <p class="text-center mt-3 pt-3">
      Please provide your personal information.
    </p>

    <EditableMemberInfo class="mt-10" />
    <EditableContactInfo class="pa-2" />

    <PhilhealthInfo
      :hideButton="true"
      :isPersonalInfo="false"
      :collection="COLLECTION"
    />

    <NextButton :disabled="isNextDisabled" @click="onNext" class="mb-5 mt-10" />
  </div>
</template>

<script>
import { DB, getCurrentUserUid } from "@/common/store";
import {
  NS_MEMBERINFO as NAMESPACE,
  NS_CONTACT_INFO as CONTACTINFO,
  NS_PHILHEALTHINFO as PHILHEALTHINFO
} from "@/store";

import BackButton from "@/components/Controls/BackButton.vue";
import EditableContactInfo from "@/MemberInfo/components/EditableContactInfo.vue";
import EditableMemberInfo from "@/MemberInfo/components/EditableMemberInfo.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import PhilhealthInfo from "@/PhilHealth/components/PhilhealthInfo.vue";

export default {
  name: "MemberInfo",

  components: {
    BackButton,
    EditableContactInfo,
    EditableMemberInfo,
    NextButton,
    PhilhealthInfo
  },

  props: {
    collection: String
  },

  data: () => ({
    COLLECTION: DB.PHILHEALTH_INDEX
  }),

  methods: {
    async onNext() {
      await this.$store.dispatch(`${NAMESPACE}/save`, {
        collection: this.collection,
        docId: getCurrentUserUid()
      });
      await this.$store.dispatch(`${CONTACTINFO}/save`, {
        collection: this.collection,
        docId: getCurrentUserUid()
      });
      this.$emit("next");
    },

    philhealthValid() {
      if (this.$store.getters[`${PHILHEALTHINFO}/getWithPhilhealth`]) {
        return (
          !this.$store.getters[`${PHILHEALTHINFO}/getPhilHealthId`] ||
          !this.$store.getters[`${PHILHEALTHINFO}/getMembershipCategory`] ||
          !this.$store.getters[`${PHILHEALTHINFO}/getClientType`] ||
          !this.$store.getters[
            `${PHILHEALTHINFO}/getAuthorizationTransactionCode`
          ] ||
          !this.$store.getters[`${PHILHEALTHINFO}/getAssessmentDate`]
        );
      }
      return false;
    }
  },

  computed: {
    isNextDisabled() {
      return (
        !this.$store.getters[`${NAMESPACE}/getFirstName`] ||
        !this.$store.getters[`${NAMESPACE}/getMiddleName`] ||
        !this.$store.getters[`${NAMESPACE}/getLastName`] ||
        !this.$store.getters[`${NAMESPACE}/getBirthday`] ||
        !this.$store.getters[`${NAMESPACE}/getSex`] ||
        !this.$store.getters[`${CONTACTINFO}/isComplete`] ||
        this.philhealthValid()
      );
    }
  }
};
</script>
