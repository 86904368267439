import { DB } from "@/common/store";

import { db } from "@/main";

import firebase from "firebase/app";
import "firebase/firestore";

// document fields
const CANCELLED_AT = "cancelledAt";
const INVENTORY = "inventory";
const RATING = "rating";
const RATING_COUNT = "ratingCount";
const RATING_TOTAL = "ratingTotal";
const SOLD = "sold";

function getUpdatedRatingCount(data) {
  return data.ratingCount ? Number(data.ratingCount) + 1 : 1;
}

function getUpdatedRatingTotal(data, rating) {
  return data.ratingTotal ? Number(data.ratingTotal) + rating : rating;
}

function getUpdatedRating(data, rating) {
  return getUpdatedRatingTotal(data, rating) / getUpdatedRatingCount(data);
}

function getUpdatedSold(data, sold) {
  return data.sold ? Number(data.sold) + sold : sold;
}

export function addRating(productSku, rating, quantitySold) {
  return new Promise((resolve, reject) => {
    let docRef = db.collection(DB.CATALOG).doc(productSku);

    db.runTransaction(transaction => {
      return transaction.get(docRef).then(doc => {
        if (!doc.exists) {
          return Promise.reject("Document does not exist!");
        } else {
          let data = doc.data();
          return transaction.update(docRef, {
            [RATING]: getUpdatedRating(data, rating),
            [RATING_COUNT]: getUpdatedRatingCount(data),
            [RATING_TOTAL]: getUpdatedRatingTotal(data, rating),
            [SOLD]: getUpdatedSold(data, quantitySold),
            [CANCELLED_AT]: new Date()
          });
        }
      });
    })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

function getCurrentStock(data) {
  let currentStock = data.inventory ? Number(data.inventory) : 0;
  return currentStock > 0 ? currentStock : 0;
}

export function increaseItemQuantity(itemId, quantity) {
  return new Promise((resolve, reject) => {
    let docRef = db.collection(DB.CATALOG).doc(itemId);

    db.runTransaction(transaction => {
      return transaction.get(docRef).then(doc => {
        if (!doc.exists) {
          return Promise.reject("Document does not exist!");
        } else {
          return transaction.update(docRef, {
            [INVENTORY]: getCurrentStock(doc.data()) + quantity
          });
        }
      });
    })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function reduceItemQuantity(itemId, quantity) {
  return new Promise(resolve => {
    let docRef = db.collection(DB.CATALOG).doc(itemId);

    db.runTransaction(transaction => {
      return transaction.get(docRef).then(doc => {
        if (!doc.exists) {
          return Promise.reject("Document does not exist!");
        } else {
          let currentStock = getCurrentStock(doc.data());
          quantity = currentStock >= quantity ? quantity : currentStock;

          return transaction.update(docRef, {
            [INVENTORY]: currentStock - quantity
          });
        }
      });
    })
      .then(() => resolve(quantity))
      .catch(() => resolve(0));
  });
}

export function setInventory(itemId, quantity) {
  return new Promise((resolve, reject) => {
    db.collection(DB.CATALOG)
      .doc(itemId)
      .set({ [INVENTORY]: quantity }, { merge: true })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function updateWishlist(details) {
  return new Promise((resolve, reject) => {
    db.collection(DB.CATALOG)
      .doc(details.docid)
      .update({
        wishedBy: details.onWishlist
          ? firebase.firestore.FieldValue.arrayUnion(details.iname)
          : firebase.firestore.FieldValue.arrayRemove(details.iname)
      })
      .then(() => resolve(details))
      .catch(err => reject(err));
  });
}
