<template>
  <div>
    <v-checkbox
      v-model="isSameAsAddress"
      label="Same address above"
      @change="setSameAddress()"
    />

    <Address
      :NAMESPACE="NAMESPACE"
      :NS_DATA="NS_DATA"
      :disabled="isSameAsAddress"
      @onChange="onChange"
      ref="address"
      class="background-grey mt-0 py-5 px-2"
      :class="{ blur: isSameAsAddress }"
    />
  </div>
</template>

<script>
import {
  NS_CONTACT_INFO,
  NS_CONTACT_TRACING,
  NS_LOCATIONS_HOMESERVICE
} from "@/store";

import Address from "@/components/Address.vue";

export default {
  name: "HomeServiceLocation",

  components: {
    Address
  },

  data: () => ({
    isSameAsAddress: true,

    NAMESPACE: NS_LOCATIONS_HOMESERVICE,
    NS_DATA: NS_CONTACT_TRACING
  }),

  mounted() {
    this.setSameAddress();
  },

  methods: {
    onChange() {
      const isComplete = this.$store.getters[`${this.NS_DATA}/isComplete`];
      this.$emit("onChange", isComplete);
    },

    setAddress(address) {
      this.$refs.address.loadAddress(address);
      this.onChange();
    },

    setSameAddress() {
      if (this.isSameAsAddress) {
        this.setAddress(this.$store.getters[`${NS_CONTACT_INFO}/getAddress`]);
      }
    },

    uncheckSameAddress() {
      this.isSameAsAddress = false;
    }
  }
};
</script>

<style scoped>
.blur {
  filter: opacity(0.5);
}
</style>
