<template>
  <div>
    <AppHeader header="Upcoming Appointments" />

    <v-container fluid class="main-container">
      <v-tabs color="primary" centered>
        <v-tab>{{ SERVICE_LABORATORY }}</v-tab>
        <v-tab-item>
          <DiagnosticClinicsAppointments
            :key="renderAppointments"
            @onConfirm="confirm"
            @onUpdate="update"
          />
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import DiagnosticClinicsAppointments from "@/components/Appointments/DiagnosticClinics.vue";

export default {
  name: "Appointments",

  components: {
    AppHeader,
    DiagnosticClinicsAppointments
  },

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");
  }
};
</script>
