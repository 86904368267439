import { DB } from "@/common/store";

import { db } from "@/main";
import { getDateStringFromTimestamp } from "@/common/utilities/date";

export function GetScheduleSlots(payload) {
  return new Promise(resolve => {
    db.collection(DB.LGU_SCHEDULES)
      .where("service.id", "==", payload.serviceId)
      .where("schedule.start", ">", new Date(payload.nowDate + " 00:00:00"))
      .where("schedule.start", "<", new Date(payload.nowDate + " 24:00:00"))
      .orderBy("schedule.start", "asc")
      .orderBy("slot.start", "asc")
      .get()
      .then(snapshot => {
        let slots = [];

        snapshot.forEach(doc => {
          slots.push({ id: doc.id, ...doc.data() });
        });

        resolve(slots);
      })
      .catch(() => resolve([]));
  });
}

export function GetServiceScheduleDates(serviceId) {
  return new Promise(resolve => {
    let today = new Date();
    today.setHours(0, 0, 0);

    db.collection(DB.LGU_SCHEDULES)
      .where("service.id", "==", serviceId)
      .where("schedule.start", ">=", today)
      .orderBy("schedule.start", "asc")
      .get()
      .then(snapshot => {
        let schedules = [];

        snapshot.forEach(doc => {
          let scheduleDate = getDateStringFromTimestamp(
            doc.data().schedule.start
          );

          if (!schedules.includes(scheduleDate)) {
            schedules.push(scheduleDate);
          }
        });

        resolve(schedules);
      })
      .catch(() => resolve([]));
  });
}
