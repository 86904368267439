export const CURRENT_SESSION_ID = "medisureSessionId";
export const CURRENT_USER = "medisureCurrentUser";
export const CURRENT_USER_UID = "medisureCurrentUserUid";
export const CURRENT_USER_EMAIL = "medisureCurrentUserEmail";

function getValue(key) {
  const item = window.sessionStorage.getItem(key);
  return "null" === item ? "" : item;
}

export function getCurrentSessionId() {
  return getValue(CURRENT_SESSION_ID);
}

export function getCurrentUser() {
  return getValue(CURRENT_USER) || "Not specified";
}

export function getCurrentUserEmail() {
  return getValue(CURRENT_USER_EMAIL);
}

export function getCurrentUserUid() {
  return getValue(CURRENT_USER_UID);
}

function setValueOrRemove(key, value) {
  if (value) {
    window.sessionStorage.setItem(key, value);
  } else {
    window.sessionStorage.removeItem(key);
  }
}

export function setCurrentSessionId(value = "") {
  return setValueOrRemove(CURRENT_SESSION_ID, value);
}

export function setCurrentUserEmail(value = "") {
  return setValueOrRemove(CURRENT_USER_EMAIL, value);
}

export function setCurrentUserName(value = "") {
  return setValueOrRemove(CURRENT_USER, value);
}

export function setCurrentUserUid(value = "") {
  return setValueOrRemove(CURRENT_USER_UID, value);
}
