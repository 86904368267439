<template>
  <div>
    <h3 class="mb-5 text-center">Personal Information</h3>

    <InfoEntry
      header="Full Name"
      :content="fullName"
      class="background-grey py-2"
    />
    <InfoEntry header="Date of Birth" :content="birthday" class="py-2" />
    <InfoEntry header="Gender" :content="sex" class="background-grey py-2" />
  </div>
</template>

<script>
import { NS_MEMBERINFO as NAMESPACE } from "@/store";

import InfoEntry from "@/components/Controls/InfoEntry.vue";

export default {
  name: "MemberInformation",

  components: {
    InfoEntry
  },

  computed: {
    birthday() {
      return this.$store.getters[`${NAMESPACE}/getBirthday`];
    },

    fullName() {
      return this.$store.getters[`${NAMESPACE}/getFullName`];
    },

    sex() {
      return this.$store.getters[`${NAMESPACE}/getSex`];
    }
  }
};
</script>
