<template>
  <div>
    <h4 class="text-center primary--text pt-2">
      {{ "Individual Test" }}
    </h4>

    <v-layout row wrap class="pa-3">
      <div
        class="col-md-6 col-sm-6 col-xs-12 pa-1"
        v-for="(groupList, t) in groupList"
        :key="t"
      >
        <v-card class="px-6 py-4" height="100%">
          <div class="text-center">
            <b class="">
              <v-checkbox
                class="floaterl primary--text"
                v-model="selected"
                :value="groupList.data"
                :label="`${groupList.data.testname} (₱${groupList.data.price})`"
              ></v-checkbox>
            </b>
          </div>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { loadIndividualTest } from "@/store";

export default {
  name: "IndividualTest",

  props: {
    clinicType: String,
    individualTest: Array
  },
  data: () => ({
    groupList: [],
    selected: []
  }),

  watch: {
    clinicType() {
      this.loadTests();
    },
    selected() {
      this.$emit("selected-individual", this.selected);
    },
    individualTest() {
      this.selected = this.individualTest;
    }
  },

  methods: {
    loadTests() {
      this.groupList = [];
      loadIndividualTest(this.clinicType).then(docs => {
        this.groupList = docs;
      });
    }
  }
};
</script>

<style scoped>
.floaterl {
  float: left;
}
</style>
