<template>
  <div>
    <v-row>
      <v-col cols="12" class="pa-0">
        <Address
          :NAMESPACE="NAMESPACE"
          :NS_DATA="NS_DATA"
          @onChange="onChange"
          @onMount="$emit('onMount')"
          class="background-grey ma-0 py-5 px-2"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0 py-5">
      <v-col :cols="entryRowCol" class="py-0 select-container">
        <v-text-field
          label="Mobile No (Cellphone Number)"
          v-model="contactNumber"
        />
      </v-col>

      <v-col :cols="entryRowCol" class="py-0 select-container">
        <v-text-field label="Email" v-model="email" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { NS_CONTACT_INFO, NS_LOCATIONS_ALL } from "@/store";

import { setWidth } from "@/utilities/display";

import Address from "@/components/Address.vue";

export default {
  name: "EditableContactInfo",

  components: {
    Address
  },

  data: () => ({
    loading: true,

    NAMESPACE: NS_LOCATIONS_ALL,
    NS_DATA: NS_CONTACT_INFO
  }),

  methods: {
    onChange() {
      const isComplete = this.$store.getters[`${NS_CONTACT_INFO}/isComplete`];
      this.$emit("onChange", isComplete);
    }
  },

  computed: {
    contactNumber: {
      get() {
        return this.$store.getters[`${NS_CONTACT_INFO}/getContactNumber`];
      },
      set(value) {
        this.$store.commit(`${NS_CONTACT_INFO}/setContactNumber`, value);
        this.onChange();
      }
    },

    email: {
      get() {
        return this.$store.getters[`${NS_CONTACT_INFO}/getEmail`];
      },
      set(value) {
        this.$store.commit(`${NS_CONTACT_INFO}/setEmail`, value);
        this.onChange();
      }
    },

    entryRowCol() {
      return setWidth(this.$vuetify.breakpoint.width, 4, 6, 6, 12, 12);
    }
  }
};
</script>
