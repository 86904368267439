<template>
  <div>
    <v-container class="pa-0">
      <v-tabs v-model="selectedTab" background-color="white">
        <v-tab>Benefits</v-tab>
        <v-tab-item>
          <InsuranceOverview
            :key="reloadPackages"
            :packageDetails="packageDetails"
            :packageNames="packageNames"
            :initialPackage="selectedPackage"
            v-on:subscribe="onSubscribe"
          />
        </v-tab-item>

        <v-tab>Enroll</v-tab>
        <v-tab-item>
          <InsuranceForm
            :key="reloadPackages"
            :packageDetails="packageDetails"
            :packageNames="packageNames"
            :initialPackage="selectedPackage"
            v-on:payment-made="paymentMade"
          />
        </v-tab-item>

        <v-tab>Coverage</v-tab>
        <v-tab-item>
          <InsuranceHistoryContainer ref="container" />
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import { loadInsurancePackage2 } from "@/store";

import InsuranceForm from "@/components/Insurance/Form.vue";
import InsuranceHistoryContainer from "@/components/Insurance/HistoryContainer.vue";
import InsuranceOverview from "@/components/Insurance/Overview.vue";

export default {
  name: "Insurance",

  components: {
    InsuranceForm,
    InsuranceHistoryContainer,
    InsuranceOverview
  },

  props: {
    inputTab: String
  },

  data: () => ({
    packageDetails: {},
    packageNames: [],
    reloadPackages: 0,
    selectedPackage: "",
    selectedTab: null
  }),

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");

    if (this.inputTab) {
      const value = parseInt(this.inputTab);
      this.selectedTab = value > 2 ? 2 : value;
    }

    this.loadPackages();
  },

  methods: {
    loadPackage(_package) {
      let { name, premium, benefits } = _package;

      if (name) {
        this.packageNames.push(name);
        this.packageDetails[name] = {
          name: name,
          premium: "₱ " + premium,
          price: premium,
          benefits: []
        };

        for (let i in benefits) {
          this.packageDetails[name].benefits.push({
            name: benefits[i].name,
            amount: "₱ " + benefits[i].amount
          });
        }
      }
    },

    loadPackages() {
      loadInsurancePackage2("Alalay sa Buhay").then(docs => {
        for (let i in docs) {
          this.loadPackage(docs[i]);
        }
        this.reloadPackages += 1;
      });
    },

    onSubscribe(selectedPackage) {
      this.selectedPackage = selectedPackage;
      this.selectedTab = 1;
      this.reloadPackages += 1;
    },

    paymentMade() {
      if (this.$refs.container) {
        this.$refs.container.reload();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
