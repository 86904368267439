<template>
  <v-card flat class="pa-8">
    <BackButton
      @click="$emit('closeDialog')"
      :class="{ 'ma-2': $vuetify.breakpoint.mobile }"
    />
    <h2 class="text-center">Privacy Policy</h2>

    <div class="body-2 mt-10 text-justify">
      <p>
        <strong>Important Notice</strong>
      </p>

      <p>
        This website uses cookies and other similar technologies to collect
        information about your device to improve your experience when visiting
        our site. By continuing to browse the site, you are agreeing to use of
        these information in accordance with our Data Privacy Statement.
      </p>

      <p>
        Medisureonline Inc. uses cookies to provide necessary website
        functionality, enhance your site experience and for analytical purposes.
        By using our website you agree to to our Privacy Policy and our Cookies
        Policy.
      </p>

      <p>
        This policy was last updated on November 12, 2021.
      </p>
    </div>

    <div class="body-2 mt-10 text-justify">
      <p>
        <strong>Data Privacy Policy</strong>
      </p>

      <p>
        One of the main priorities of Medisureonline Inc., is the privacy of our
        visitors and users of the Medisure Plus website. We value the
        confidentiality, integrity, transparency and supports privacy protection
        of your personal data.
      </p>

      <p>
        This Policy document contains types of information that is collected,
        recorded and how we use it. Medisureonline Inc. is compliance with the
        Data Privacy Act of 2012, Republic Act No. 10173.
      </p>

      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>
    </div>

    <div class="body-2 mt-10 text-justify">
      <p>
        <strong>Information we collect</strong>
      </p>

      <p>
        Medisureonline Inc., will receive and store information that you enter
        on our sites or give to us in any other way such as but not limited to
        the following:
      </p>

      <p class="ml-10">
        <ul>
          <li>Name</li>
          <li>Address</li>
          <li>Age</li>
          <li>Email address</li>
          <li>Telephone/Cellphone Number</li>
          <li>Medical History</li>
        </ul>
      </p>

      <p>
        Some of the information you submit to us may contain sensitive
        information such as health information, necessary for us to provide
        our services to you.
      </p>

      <p>
        Other information we collect pertains to the use of our sites,
        interaction with us through email and chat, This information includes
        computer and connection information, such as statistics on your page
        views, traffic to and from our sites, referral URL, ad data, your IP
        address, and device identifiers. This information also may include
        your transaction history, and your web log information, how you search
        for our sites, the websites you click on from our sites or emails,
        whether and when you open our emails, and your browsing activities
        across other websites.
      </p>
    </div>

    <div class="body-2 mt-10 text-justify">
      <p>
        <strong>How we use your information</strong>
      </p>

      <p>
        We use the information we collect in various ways, including to:
      </p>

      <p class="ml-10">
        <ul>
          <li>
            Communicate with you with regards to products and services availed
            of through our website through emails or your telephone number
          </li>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </li>
          <li>Find and prevent fraud</li>
        </ul>
      </p>

      <p>
        Medisureonline Inc., is committed to maintain and secure the
        confidentiality of your information. Only authorized employees are
        authorized to access any or all information in our database system. We
        may share information we collect about you with third parties who we
        have engaged to help us provide the services you need. We have a
        written contract (MOA) in place with said the third parties/business
        associate requiring protection of the privacy and security of your
        health information. Only for the purpose of performing a service in
        our behalf.
      </p>

      <p>
        We will only share your information only if required to conduct our
        professional operations or as required by law.
      </p>

      <p>
        We will not use or disclose your information for any personal or other
        without your consent.
      </p>
    </div>

    <div class="body-2 mt-10 text-justify">
      <p>
        <strong>Data Quality and Security Measure</strong>
      </p>

      <p>
        We use reasonable security measures to protect the confidentiality of
        personal information under our control and appropriately limit access
        to it. We use a variety of information security measures to protect
        your online transactions with us. Our site uses encryption technology,
        such as Secure Sockets Layer (SSL), to protect your personal
        information during data transport. SSL protects information you submit
        via our website, such as ordering information including your name,
        address and credit card number. That being said, we cannot ensure or
        warrant the security of any information you transmit to us, and you do
        so at your own risk. We have taken reasonable steps to ensure the
        integrity and confidentiality of personally identifiable information
        that you may provide. You should understand, however, that electronic
        transmissions via the internet are not necessarily secure from
        interception, and so we cannot absolutely guarantee the security or
        confidentiality of such transmissions.
      </p>
    </div>

    <div class="body-2 mt-10 text-justify">
      <p>
        <strong>Our Advertising Partners</strong>
      </p>

      <p>
        Medisureonline Inc.’s Privacy Policy does not apply to other
        advertisers or websites. . Thus, we are advising you to consult the
        respective Privacy Policies of these third-party ad servers for more
        detailed information. It may include their practices and instructions
        about how to opt-out of certain options.
      </p>
    </div>

    <div class="body-2 mt-10 text-justify">
      <p>
        <strong>Data Retention</strong>
      </p>

      <p>
        We will retain your information for as long as your account is active
        or as needed to provide you services, comply with our legal
        obligations, resolve disputes and enforce our agreements.
      </p>
    </div>

    <div class="body-2 mt-10 text-justify">
      <p>
        <strong>GDPR Data Protection Rights</strong>
      </p>

      <p>
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following:
      </p>

      <p class="ml-10">
        <ul>
          <li>
            The right to access - You have the right to request copies of your
            personal data.
          </li>
          <li>
            The right to rectification - You have the right to request that we
            correct any information that you may think is inaccurate and
            incomplete.
          </li>
          <li>
            The right to erasure/blocking - You have the right to request that
            we erase, suspend, withdraw or order of blocking, removal or
            destruction of your personal data, under certain conditions.
          </li>
          <li>
            The right to restrict processing - You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </li>
          <li>
            The right to object to processing - You have the right to object to
            our processing of your personal data, under certain conditions.
          </li>
          <li>
            The right to data portability - You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </li>
        </ul>
      </p>

      <p>
        If you would like to a request, we have one month to respond to you.
        If you would like to exercise any of these rights, please contact us.
      </p>
    </div>

    <div class="body-2 mt-10">
      <p>
        <strong>How to reach us:</strong>
      </p>

      <p class="ml-10">
        Data Privacy Officer<br />
        Email address:
        <a href="mailto:dpo@medisureonline.com">dpo@medisureonline.com</a><br />
        Telephone Number: (02) 8563-0689<br />
        Address: 928 Luna Mencias, Addition Hills, Mandaluyong City 1550<br />
      </p>
    </div>

    <div class="body-2 mt-10 text-justify">
      <p>
        <strong>Change to this Policy</strong>
      </p>

      <p>
        Medisureonline Inc. reserves the right to change, revise and update
        this Policy accordingly as per relevant laws and regulations
        applicable. Any relevant updates will be posted on the Medisureonline
        Inc. website.
      </p>
    </div>
  </v-card>
</template>

<script>
import BackButton from "@/components/Controls/BackButton.vue";

export default {
  name:"PrivacyPolicy",

  components: {
    BackButton
  }
}
</script>
