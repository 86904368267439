<template>
  <div>
    <BackButton @click="$emit('back')" />

    <p class="mt-10 text-center">
      Please confirm that the details below are correct and click SUBMIT to
      continue.
    </p>

    <PhilHealthInformation class="mt-10" />
    <PastMedicalHistory class="mt-15" />
    <FamilyHistory class="mt-15" />
    <PersonalSocialHistory class="mt-15" />

    <NextButton @click="onNext" text="Submit" class="mb-5 mt-15" />
  </div>
</template>

<script>
import BackButton from "@/components/Controls/BackButton.vue";
import FamilyHistory from "@/HealthRecords/components/Confirmation/FamilyHistory.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import PastMedicalHistory from "@/HealthRecords/components/Confirmation/PastMedicalHistory.vue";
import PersonalSocialHistory from "@/HealthRecords/components/Confirmation/PersonalSocialHistory.vue";
import PhilHealthInformation from "./Confirmation/PhilHealthInformation.vue";

export default {
  name: "Confirmation",

  components: {
    BackButton,
    FamilyHistory,
    NextButton,
    PastMedicalHistory,
    PersonalSocialHistory,
    PhilHealthInformation
  },

  methods: {
    onNext() {
      this.$store.dispatch("resetSecondsIdle").then(() => {
        this.$emit("next");
      });
    }
  }
};
</script>
