<template>
  <v-container fluid class="medicalrecordscontainer px-1">
    <v-card
      class="ma-2"
      v-if="!isUserVerified"
      :disabled="isVerificationRequested"
      :to="ROUTE_VALIDATE"
    >
      <v-card-actions class="justify-center">
        <v-card-title v-text="verificationButtonText" />
      </v-card-actions>
    </v-card>
    <v-card
      v-if="!hasHistory && isUserVerified"
      @click="createNew = true"
      class="ma-2"
    >
      <v-card-actions class="justify-center">
        <v-card-title v-text="'Create My Medical History'" />
      </v-card-actions>
    </v-card>

    <div v-if="createNew" :key="createNew">
      <MedicalHistory v-if="hasHistory" :medicalHistory="mhistories[0].data" />
      <MedicalHistory v-else />
    </div>

    <div v-else>
      <v-carousel hide-delimiter-background height="100%" :key="carouselSlide">
        <v-carousel-item v-for="(mhistory, mhid) in mhistories" :key="mhid">
          <div class="body-1">
            <MedicalHistorySummary
              :medicalHistory="mhistory.data"
              :showEditable="mhid"
              @create-new="createNewMH"
              @refresh-slide="refreshSlide"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
  </v-container>
</template>

<script>
import { ROUTE_VALIDATE } from "@/router";

import { loadMedicalHistory } from "@/store/medical_history";

var moment = require("moment");

import MedicalHistory from "@/views/MedicalHistory.vue";
import MedicalHistorySummary from "@/components/Profile/MedicalHistorySummary.vue";

export default {
  name: "MedicalRecords",

  components: {
    MedicalHistory,
    MedicalHistorySummary
  },

  data: () => ({
    isUserVerified: false,
    isVerificationRequested: false,
    carouselSlide: 0,
    createNew: false,
    createNewest: false,
    moment: moment,
    dialog: false,
    mhistories: [],
    myDate: "",
    hisid: "",
    componentKey: 0,

    ROUTE_VALIDATE: ROUTE_VALIDATE
  }),

  mounted() {
    this.loadHistory();
    this.isUserVerified = this.$store.getters["isVerified"];
    this.isVerificationRequested = this.$store.state.profile.verificationRequested;
  },

  computed: {
    hasHistory() {
      return this.mhistories.length;
    },

    verificationButtonText() {
      return this.isVerificationRequested
        ? "Your Details are Being Verified"
        : "Verify Details to Proceed";
    }
  },

  methods: {
    createNewMH(val) {
      this.createNew = val;
    },

    forceRerender() {
      this.componentKey += 1;
      this.componentKey += 1;
    },

    loadata(mhistory) {
      this.hisid = mhistory.id;
      this.forceRerender();
      this.$emit("dataIdRef", this.hisid);
      this.$emit("dialog-closeMR");
    },

    refreshSlide() {
      this.carouselSlide += 1;
    },

    loadHistory() {
      loadMedicalHistory().then(data => {
        this.mhistories = data;
      });
    }
  }
};
</script>

<style scoped>
.medicalrecordscontainer {
  max-width: 1080px;
}
</style>
