<template>
  <v-container>
    <h1>Payment Error</h1>
    <p>Reference: {{ orderReference }}</p>
    <p>Error: {{ errorMessage }}</p>
  </v-container>
</template>

<script>
export default {
  name: "PaymentError",

  data: () => ({
    orderReference: "",
    errorMessage: ""
  }),

  mounted() {
    this.orderReference = this.$route.query.Ref;
    this.errorMessage = this.$route.query.errorMsg;
  }
};
</script>
