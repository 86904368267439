<template>
  <div class="mx-auto">
    <v-card-actions>
      <v-col class="text-right">
        <v-btn
          class="ma-2"
          outlined
          fab
          color="primary"
          small
          @click="createNew()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-card-actions>

    <AddressList :mode="mode" @open-edit="openEdit($event)" />
  </div>
</template>

<script>
import { ROUTE_ADDRESS_INPUT, ROUTE_CHECKOUT, routeTo } from "@/router";

import { mapGetters } from "vuex";

import AddressList from "@/eTindahan/components/AddressList.vue";

export default {
  name: "AddressBook",

  components: {
    AddressList
  },

  props: {
    mode: String
  },

  computed: {
    ...mapGetters(["getPreviousRoute"])
  },

  methods: {
    createNew() {
      this.$store.commit("SET_ADDRESS_BOOK_DETAILS", null);
      routeTo(
        ROUTE_CHECKOUT === this.getPreviousRoute.path
          ? `${ROUTE_ADDRESS_INPUT}/checkout`
          : ROUTE_ADDRESS_INPUT
      );
    },

    openEdit(details) {
      this.$store.commit("SET_ADDRESS_BOOK_DETAILS", details);
      routeTo(ROUTE_ADDRESS_INPUT);
    }
  }
};
</script>
