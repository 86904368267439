<template>
  <span>
    <v-simple-table v-if="!filteredStat.length">
      <template v-slot:default>
        <tbody>
          <td colspan="2" class="format-entry py-2 text-center white--text">
            {{ noEntryContent }}
          </td>
        </tbody>
      </template>
    </v-simple-table>

    <v-card flat v-else>
      <v-expansion-panels multiple v-model="panel">
        <v-expansion-panel
          align="center"
          v-for="(checkout, cout) in filteredStat"
          :key="cout"
        >
          <p class="error--text">{{ upperCase(checkout) }}</p>
          <v-expansion-panel-header align="center" class="pa-1">
            <v-row>
              <v-col class="pt-4">
                <CartByDescription
                  :id="checkout.id"
                  :fullqr="checkout.fullqr"
                />
              </v-col>
              <v-col class="pt-4">
                <v-btn
                  v-if="checkout.latestReturnRefund"
                  x-small
                  color="primary"
                  @click="openReturnRefundDetails(checkout.latestReturnRefund)"
                >
                  Return/Refund Details
                </v-btn>
              </v-col>
              <v-col v-if="isOrderProcessing(checkout)">
                <v-btn
                  x-small
                  color="error"
                  text
                  @click="cancelOrder(checkout)"
                >
                  <v-icon>mdi-trash-can</v-icon> Cancel
                </v-btn>
              </v-col>
              <v-col>
                <v-btn text color="primary" @click="loadData(checkout)">
                  <v-icon>mdi-truck-fast-outline</v-icon>
                  <span class="ml-2">Track</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <CartByID
              :checkout="checkout"
              :receivingOption="checkout.receivingOption"
              :status="status"
              :id="checkout.id"
              :discountValue="checkout.discountValue"
              :modeOfPayment="getPaymentMethodsDisplay(checkout.payments)"
              :shippingFee="checkout.shippingFee"
              :promoType="checkout.promoType"
              :fullqr="checkout.fullqr"
              @cart-orders="cartorders = $event"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-dialog v-model="returnRefundDialog" width="400">
      <v-card flat class="pa-4">
        <PickupDetail :latestReturnRefund="latestReturnRefund" />
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </span>
</template>

<script>
import { DB } from "@/common/store";
import { NS_STORE } from "@/store";
import {
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_CANCELLED
} from "@/common/utilities/order";
import { ROUTE_TRACK, routeTo } from "@/router";

import { db } from "@/main";
import { getPaymentMethodsDisplay } from "../utilities/payments";
import { increaseItemQuantity as catalogIncreaseItemQuantity } from "@/utilities/collection/catalog";
import { increaseItemQuantity as productVariantIncreaseItemQuantity } from "@/utilities/collection/productvariant";
import { makeProductsAvailable } from "@/utilities/collection/products";
import { setTransactionHistory } from "../utilities/orders";

import CartByDescription from "@/eTindahan/components/CartByDescription.vue";
import CartByID from "@/eTindahan/components/CartByID.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import PickupDetail from "@/eTindahan/components/PickupDetail.vue";

export default {
  name: "OrderTab",

  components: {
    CartByDescription,
    CartByID,
    ConfirmDialog,
    PickupDetail
  },

  props: {
    filteredStat: Array,
    noEntryContent: String,
    status: String
  },

  data: () => ({
    cartorders: [],
    hisidi: "",
    latestReturnRefund: {},
    panel: [0],
    returnRefundDialog: false,

    getPaymentMethodsDisplay: getPaymentMethodsDisplay
  }),

  methods: {
    async cancelOrder(data) {
      if (
        await this.$refs.confirm.open(
          this.$t("dialog.confirmation"),
          "Sigurado ba kayong i-cancel ang order na ito?"
        )
      ) {
        let comment = `You cancelled Order ${data.id}`;
        setTransactionHistory(data, new Date(), comment);

        db.collection(DB.CHECKOUT)
          .doc(data.id)
          .set({ status: ORDER_STATUS_CANCELLED }, { merge: true })
          .finally(() => {
            this.cartorders.forEach(cartItem => {
              this.updateInventory(
                cartItem.productSKU,
                cartItem.vproductSKU,
                Number(cartItem.quantity)
              );
            });
          });
      }
    },

    isOrderProcessing(checkout) {
      return ORDER_STATUS_PROCESSING === checkout.status;
    },

    loadData(checkout) {
      this.$store
        .dispatch(`${NS_STORE}/checkout/setOrderId`, checkout.id)
        .then(() => {
          this.$store.commit(
            `${NS_STORE}/checkout/setCartItem`,
            this.cartorders
          );
        })
        .finally(() => {
          routeTo(ROUTE_TRACK);
        });
    },

    openReturnRefundDetails(latestReturnRefund) {
      this.latestReturnRefund = latestReturnRefund;
      this.returnRefundDialog = true;
    },

    updateInventory(prSKU, vprSKU, qty) {
      productVariantIncreaseItemQuantity(vprSKU, qty);
      catalogIncreaseItemQuantity(prSKU, qty);
      makeProductsAvailable(vprSKU, qty);
    },

    upperCase(checkout) {
      return checkout.warningStatus
        ? `${checkout.warningStatus.toUpperCase()} - ${checkout.reason}`
        : "";
    }
  }
};
</script>

<style scoped>
.format-entry {
  background-color: #66883f;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
