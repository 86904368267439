const HEMA = "HEMA";
const CM = "CM";
const CHEM = "CHEM";
const RTPCR = "RT-PCR";

export function titleCase(sentence) {
  if (sentence) {
    return sentence
      .split(" ")
      .reduce((output, item) => {
        const value = item.substr(1).toLowerCase();
        const firstLetter = item.substr(0, 1).toUpperCase();
        return `${output} ${firstLetter}${value}`;
      }, "")
      .trim();
  }

  return "";
}

export function valueOrEmptyString(content) {
  return content || "";
}

export function normalizeTags(tags) {
  let tagsNormalized = [];
  tags.forEach(tag => {
    tagsNormalized.push(tag.toLowerCase());
  });
  return tagsNormalized;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getTestName(str) {
  const cutFirst = str.split("_")[1];
  const firstPart = cutFirst.split("_")[0];
  return convertTestName(firstPart);
}

export function convertTestName(testName) {
  switch (testName) {
    case CHEM:
      return "Clinical Chemistry";

    case CM:
      return "Clinical Microscopy";

    case HEMA:
      return "Hematology";

    case RTPCR:
      return "RT-PCR";

    default:
      return "Special Tests";
  }
}
