import {
  DOCTYPE_MEDICALHISTORY,
  getDocumentHeader,
  IndexedDocument
} from "@/utilities/user_collection";

import { emptyBooleanText } from "@/utilities/fields";

const HOSPITALIZATIONS = "hospitalizations";
const ILLNESSES = "illnesses";

export const MEASLES = "Measles (Tigdas)";
export const MUMPS = "Mumps (Beke)";
export const RUBELLA = "Rubella (Tigdas Hangin)";
export const CHICKEN_POX = "Chicken Pox";
export const RHEUMATIC_FEVER = "Rheumatic Fever";
export const POLIO = "Polio";
export const OTHERS = "Others";

function emptyIllnesses() {
  return {
    [MEASLES]: emptyBooleanText(),
    [MUMPS]: emptyBooleanText(),
    [RUBELLA]: emptyBooleanText(),
    [CHICKEN_POX]: emptyBooleanText(),
    [RHEUMATIC_FEVER]: emptyBooleanText(),
    [POLIO]: emptyBooleanText(),
    [OTHERS]: emptyBooleanText()
  };
}

// to preserve order in iterations
function saveIllnesses(data) {
  return {
    [MEASLES]: data[MEASLES] || emptyBooleanText(),
    [MUMPS]: data[MUMPS] || emptyBooleanText(),
    [RUBELLA]: data[RUBELLA] || emptyBooleanText(),
    [CHICKEN_POX]: data[CHICKEN_POX] || emptyBooleanText(),
    [RHEUMATIC_FEVER]: data[RHEUMATIC_FEVER] || emptyBooleanText(),
    [POLIO]: data[POLIO] || emptyBooleanText(),
    [OTHERS]: data[OTHERS] || emptyBooleanText()
  };
}

function getDocumentContent(state) {
  return {
    header: getDocumentHeader(DOCTYPE_MEDICALHISTORY, state),
    data: {
      [HOSPITALIZATIONS]: [...state.hospitalizations],
      [ILLNESSES]: { ...state.illnesses }
    }
  };
}

var medicalHistoryBase = new IndexedDocument(
  DOCTYPE_MEDICALHISTORY,
  getDocumentContent
);
export const medicalHistory = {
  state: () => ({
    ...medicalHistoryBase.state,

    hospitalizations: [],
    illnesses: emptyIllnesses(),

    lastHopitalizations: [],
    lastIllnesses: emptyIllnesses()
  }),

  getters: {
    ...medicalHistoryBase.getters,

    getHospitalizations(state) {
      return state.hospitalizations;
    },

    getIllnesses(state) {
      return state.illnesses;
    },

    getIllnessValue: state => key => {
      return state.illnesses[key].value;
    },

    hasIllnessValueChanged: state => key => {
      return state.illnesses[key].value !== state.lastIllnesses[key].value;
    }
  },

  mutations: {
    ...medicalHistoryBase.mutations,

    addHospitalization(state, data) {
      state.isSaved = false;
      state.hospitalizations.push(data);
    },

    deleteHospitalization(state, index) {
      if (index > -1 && index < state.hospitalizations.length) {
        state.isSaved = false;
        state.hospitalizations.splice(index, 1);
      }
    },

    RESET_STATE(state) {
      state.hospitalizations = [];
      state.illnesses = emptyIllnesses();
    },

    setData(state, data) {
      state.hospitalizations = data.hospitalizations || [];
      state.illnesses = saveIllnesses(data.illnesses) || emptyIllnesses();

      state.lastHopitalizations = [...state.hospitalizations];
      state.lastIllnesses = { ...state.illnesses };
    },

    setHospitalizations(state, data) {
      state.isSaved =
        state.isSaved && state.hospitalizations.length === data.length;
      if (state.isSaved) {
        state.isSaved = state.hospitalizations.reduce(
          (returnValue, currentElement, index) => {
            return (
              returnValue &&
              currentElement.hospitalization === data[index].hospitalization &&
              currentElement.date === data[index].date &&
              currentElement.reasons === data[index].reasons
            );
          },
          true
        );
      }

      state.hospitalizations = [...data];
    },

    setIllnessComment(state, data) {
      state.isSaved =
        state.isSaved && state.illnesses[data.key].comment === data.value;
      state.illnesses[data.key].comment = data.value;
    },

    setIllnessValue(state, data) {
      state.isSaved =
        state.isSaved && state.illnesses[data.key].value === data.value;
      state.illnesses[data.key].value = data.value;
    }
  },

  actions: {
    ...medicalHistoryBase.actions,

    resetContent({ commit, dispatch }) {
      dispatch("resetDocument");
      commit("RESET_STATE");
    }
  }
};
