import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.lightGreen.darken3, // #558b2f
        secondary: colors.lime.darken4, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
        error: colors.red.darken1
      }
    }
  }
});
