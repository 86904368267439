<template>
  <div>
    <v-stepper v-model="step" flat>
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          {{ stepHeader1 }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2">
          {{ stepHeader2 }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 3" step="3">
          {{ stepHeader3 }}
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          step="1"
          class="pt-0"
          :class="{ 'pa-1': $vuetify.breakpoint.mobile }"
        >
          <h3
            v-show="$vuetify.breakpoint.mobile"
            class="text-center primary--text pt-2"
          >
            - {{ stepHeader1 }} -
          </h3>

          <RxPhotoCapture @onPhotoCapture="onRxPhotoCapture" />
        </v-stepper-content>

        <v-stepper-content
          step="2"
          class="pt-0"
          :class="{ 'pa-1': $vuetify.breakpoint.mobile }"
        >
          <h3
            v-show="$vuetify.breakpoint.mobile"
            class="text-center primary--text pt-2"
          >
            - {{ stepHeader2 }} -
          </h3>
          <RxPhotoReview
            :prescriptionImage="prescriptionImage"
            @onRxSubmission="onRxSubmission"
            @back="step -= 1"
            @next="step += 1"
          />
        </v-stepper-content>

        <v-stepper-content
          step="3"
          class="pt-0"
          :class="{ 'pa-1': $vuetify.breakpoint.mobile }"
        >
          <h3
            v-show="$vuetify.breakpoint.mobile"
            class="text-center primary--text pt-2"
          >
            - {{ stepHeader3 }} -
          </h3>
          <RxSubmissionReceipt :rxSubmissionId="rxSubmissionId" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import RxPhotoCapture from "@/eBotika/components/RxPhotoCapture.vue";
import RxPhotoReview from "@/eBotika/components/RxPhotoReview.vue";
import RxSubmissionReceipt from "@/eBotika/components/RxSubmissionReceipt.vue";

export default {
  components: {
    RxPhotoCapture,
    RxPhotoReview,
    RxSubmissionReceipt
  },

  data: () => ({
    stepHeader1: "Provide Prescription",
    stepHeader2: "Review Snapshot",
    stepHeader3: "Submission Receipt",
    step: 1,
    prescriptionImage: "",
    rxSubmissionId: ""
  }),

  mounted() {
    let img = this.getCapturedImage.image;
    if (img) {
      this.prescriptionImage = img;
      this.step += 1;
    }
  },

  methods: {
    onRxPhotoCapture(image) {
      this.prescriptionImage = image;
      this.step += 1;
    },

    onRxSubmission(submission) {
      this.rxSubmissionId = submission.submissionId;

      // TO DO!!! Set prescription

      this.step += 1;
    }
  },

  computed: {
    ...mapGetters(["getCapturedImage"])
  }
};
</script>
