<template>
  <div>
    <h3
      v-if="cartitems.length === 0"
      class="px-8 py-8 primary--text text-center"
    >
      "You have no items on cart."
    </h3>

    <div class="pa-0" v-else>
      <div v-if="hasPrescription" class="px-8 pt-2 primary--text text-center">
        Please take note that your prescription will be amended to reflect the
        quantity purchased and we will take the prescription if completely
        fulfilled upon delivery.
      </div>

      <v-simple-table class="tbl">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center px-0" scope="col">ITEM</th>
              <th class="text-center" scope="col">QUANTITY</th>
              <th class="text-center" scope="col">TOTAL</th>
            </tr>
          </thead>

          <tbody>
            <tr height="90px" v-for="(cart, clist) in cartitems" :key="clist">
              <td class="pl-1 pr-0">
                <v-list-item class="px-0">
                  <v-list-item-avatar>
                    <v-img
                      class="white--text align-end"
                      :aspect-ratio="16 / 9"
                      height="80px"
                      :src="cart.imageUrl"
                    />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="truncate">
                      {{ cart.name }}
                      <strong
                        v-if="isExpired(cart) || cart.isPWDSCPriceApplied"
                        style="background-color: primary;"
                        class="caption primary--text pa-1"
                      >
                        {{ getPromo(cart) }}
                      </strong>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="caption"
                      v-if="cart.preOrderFrom > 0"
                    >
                      {{ getPreOrder(cart) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="cart.hasVariant">
                      {{ cart.variation }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ getStockDisplay(arrInventory[clist], quant[clist]) }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle>
                      <span
                        v-if="cart.discount > 0 && isExpired(cart)"
                        class="mr-2 text-decoration-line-through"
                      >
                        <PesoAmount :amount="Number(cart.originalPrice)" />
                      </span>
                      <span>
                        <PesoAmount
                          :amount="
                            cart.discount === 0
                              ? Number(cart.originalPrice)
                              : Number(cart.price)
                          "
                        />
                      </span>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle>
                      <v-btn
                        v-if="
                          !getDisableOutOfStock(
                            arrInventory[clist],
                            quant[clist]
                          )
                        "
                        class="mr-2"
                        outlined
                        x-small
                        color="primary"
                        dark
                        @click="editItem(cart, arrInventory[clist])"
                      >
                        Edit
                      </v-btn>

                      <v-btn
                        outlined
                        x-small
                        color="error"
                        dark
                        @click="selectedItemData(cart, clist)"
                      >
                        Remove
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </td>

              <td class="px-0 text-center" style="width: 60px;">
                <v-text-field
                  v-if="
                    !getDisableOutOfStock(arrInventory[clist], quant[clist])
                  "
                  class="pt-5 select-container"
                  style="width: 60px;"
                  single-line
                  outlined
                  v-model.number="quant[clist]"
                  :label="(quant[clist] = String(cart.quantity))"
                  type="number"
                  :min="1"
                  :max="arrInventory[clist]"
                  @input="
                    updateNewQty(
                      cart.id,
                      quant[clist],
                      arrInventory[clist],
                      cart.quantity,
                      clist,
                      cart.productSKU
                    )
                  "
                />
              </td>
              <td
                v-if="!getDisableOutOfStock(arrInventory[clist], quant[clist])"
                class="px-1 price-entry"
                :class="{ 'pr-4': !$vuetify.breakpoint.mobile }"
              >
                <PesoAmount
                  :amount="Number(cart.price) * Number(cart.quantity)"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr class="price-entry">
              <td class="text-right">
                <strong>
                  <div class="pt-4">
                    Total <span v-show="displayAsterisk">*</span>
                  </div>
                </strong>
              </td>
              <td
                class="price-entry"
                :class="{ 'pr-1': $vuetify.breakpoint.mobile }"
              >
                <v-divider class="price-entry pt-4"></v-divider>
                <strong><PesoAmount :amount="total"/></strong>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <PwdScMessage v-if="displayAsterisk" class="mb-7" />

      <Checkout
        :disabled="disableCheckout || !isProcessInitialized"
        :total="total"
        @check-out="checkOut"
      />
    </div>
  </div>
</template>

<script>
import { NS_STORE } from "@/store";
import { ROUTE_EDIT_CARTITEM, ROUTE_CHECKOUT, routeTo } from "@/router";

import {
  deleteItemFromCart,
  setItemQuantity
} from "@/utilities/collection/addtocart";
import { getItem } from "@/utilities/collection/productvariant";
import { getPromo, isExpired } from "../utilities/discounts";
import { logUserActivity } from "@/common/store";
import { mapGetters } from "vuex";

import Checkout from "@/eTindahan/components/Checkout.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";
import PwdScMessage from "../components/PwdScMessage.vue";

export default {
  name: "Cart",

  components: {
    Checkout,
    PesoAmount,
    PwdScMessage
  },

  data: () => ({
    cartStat: "",
    sum: 0,

    arrInventory: [],
    cartitems: [],

    quant: [],

    hasPrescription: false,
    hasPwdSc: false,
    cartProcessed: 0,

    // functions
    getPromo: getPromo,
    isExpired: isExpired
  }),

  created() {
    this.loadmyCart();
  },

  computed: {
    ...mapGetters(["getPwdScDetails"]),

    displayAsterisk() {
      return this.hasPwdSc && !this.getPwdScDetails.isVerified;
    },

    disableCheckout() {
      return this.cartitems.length === 1 && this.cartStat.length > 0;
    },

    isProcessInitialized() {
      return this.cartProcessed === this.cartitems.length;
    },

    total() {
      return Number(this.sum);
    }
  },

  methods: {
    checkInventoryHasVariant(key) {
      getItem(key.vproductSKU).then(doc => {
        if (doc && doc.exists) {
          let inventory = Number(doc.data().vstock);
          this.arrInventory.push(inventory);

          if (inventory < Number(key.quantity)) {
            this.cartStat = "Out of Stock: " + inventory;
          } else {
            this.sum += Number(key.price) * Number(key.quantity);
          }

          this.cartProcessed++;
        }
      });
    },

    checkOut() {
      this.$store.commit(`${NS_STORE}/checkout/setBalance`, 0);
      this.$store.commit(`${NS_STORE}/checkout/setpaymentStatus`, {});
      this.$store.commit(
        `${NS_STORE}/checkout/setCartItem`,
        this.getCartItems()
      );
      this.$store.commit(`${NS_STORE}/checkout/setCartTotal`, this.total);
      routeTo(ROUTE_CHECKOUT);
    },

    checkPrescription(cartitems) {
      this.hasPrescription = false;

      cartitems.forEach(item => {
        if (item.category.includes("Prescription Drugs (Rx)")) {
          this.hasPrescription = true;
        }
      });
    },

    checkPwdSc(cartitems) {
      this.hasPwdSc = false;

      cartitems.forEach(item => {
        if (item.isPWDSCPriceApplied) {
          this.hasPwdSc = true;
        }
      });
    },

    checkQuantity() {
      this.sum = 0;
      this.cartProcessed = 0;
      for (let key of this.cartitems) {
        if (key.quantity > 0) {
          this.checkInventoryHasVariant(key);
        }
      }
    },

    clear() {
      this.sum = Number("");
      this.arrInventory = [];
    },

    closeDialog() {
      this.$emit("close-dialog");
    },

    deleteItemCart(selectedsku, selectedqty, cartId) {
      deleteItemFromCart(cartId).then(() => {
        logUserActivity("Remove from Cart", {
          target: selectedsku,
          quantity: selectedqty
        });
      });
    },

    editItem(cart, inventory) {
      this.$store.commit("setSelectedCartDetails", cart, { root: true });
      this.$store.commit("setSelectedCartDetailsInventory", inventory, {
        root: true
      });
      routeTo(ROUTE_EDIT_CARTITEM);
    },

    getCartItems() {
      let newCartItems = [];

      this.cartitems.forEach((item, index) => {
        if (
          !this.getDisableOutOfStock(this.arrInventory[index], item.quantity)
        ) {
          newCartItems.push(item);
        }
      });

      return newCartItems;
    },

    getDisableOutOfStock(stocks, inputStock) {
      return 0 === stocks && inputStock > stocks;
    },

    getPreOrder(cart) {
      return `Pre-order ${cart.preOrderFrom} - ${cart.preOrderTo} days`;
    },

    getStockDisplay(stocks, inputStock) {
      return inputStock > stocks ? this.cartStat : stocks;
    },

    isVerified(details) {
      if (Object.keys(details).length === 0) {
        return "Please upload your ID";
      } else {
        return details.isVerified ? "Verified" : "Verifying";
      }
    },

    loadmyCart() {
      this.$store.dispatch("loadCart").then(docs => {
        if (docs.length === 0) {
          this.$store.dispatch("setCartCount", 0);
        }

        this.cartitems = [...docs];
        this.checkPrescription(docs);
        this.checkPwdSc(docs);
        this.checkQuantity();
      });
    },

    selectedItemData(cart, clist) {
      this.arrInventory.splice(clist, 1);
      this.clear();
      this.deleteItemCart(cart.productSKU, cart.quantity, cart.id);
      this.loadmyCart();
    },

    setQuantity(cartId, quantity, prodSKU) {
      setItemQuantity(cartId, quantity).then(() => {
        logUserActivity("Update Quantity on Cart", {
          target: prodSKU,
          quantity: quantity
        });
      });
    },

    updateNewQty(cartid, quantity, remainingstock, orig, clist, prodSKU) {
      this.cartStat = "";

      if (this.quant[clist] == 0) {
        this.deleteItemCart(prodSKU, quantity, cartid);
      } else {
        this.quant = [];
        if (remainingstock >= quantity) {
          this.setQuantity(cartid, quantity, prodSKU);
        } else {
          alert("Not Enough Stock");
          this.cartitems = [];
          this.setQuantity(cartid, orig, prodSKU);
        }

        this.loadmyCart();
      }
    }
  }
};
</script>

<style scoped>
.price-entry {
  text-align: right;
  width: 110px;
}

@media only screen and (max-width: 600px) {
  .tbl {
    width: 50vh;
    table-layout: auto;
    width: 100%;
  }
}

.select-container >>> input {
  text-align: center;
}

.truncate {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
