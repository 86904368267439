<template>
  <v-card>
    <v-col class="text-right pa-1">
      <v-icon color="red" block medium @click.stop="closeDialog">
        mdi mdi-close
      </v-icon>
    </v-col>

    <div class="text-center">
      <v-img width="600" :src="imageUrl" />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    imageUrl: String
  },

  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    }
  }
};
</script>
