<template>
  <div class="pa-4 text-center">
    <h4 class="text-center pb-4">
      {{ "Price Summary" }}
    </h4>

    <v-row v-show="getPackageTotal() + getIndividualTotal() > 0">
      <v-col class="py-0 text-left" cols="6">
        <strong> Total Purchase</strong>
      </v-col>
      <v-col class="py-0 text-right" cols="6">
        <strong>
          <PesoAmount
            :amount="Number(getPackageTotal() + getIndividualTotal())"
          />
        </strong>
      </v-col>
    </v-row>

    <v-row v-show="getPackageTotal() + getIndividualTotal() > 0">
      <v-col class="py-0 text-left" cols="6">
        <strong> Your Discount</strong>
      </v-col>
      <v-col class="py-0 text-right" cols="6">
        <strong>
          (
          <PesoAmount
            :amount="
              Number(
                getPackageTotal() + getIndividualTotal() - getDiscountedTotal()
              )
            "
          />)
        </strong>
      </v-col>
    </v-row>

    <v-row v-show="getDiscountedTotal() > 0">
      <v-col class="py-0 text-left" cols="6">
        <strong> Total</strong>
      </v-col>
      <v-col class="py-0 text-right" cols="6">
        <strong> <PesoAmount :amount="Number(getDiscountedTotal())"/></strong>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "SelectService",

  components: {
    PesoAmount
  },

  props: {
    registrants: Array
  },

  methods: {
    getPackageTotal() {
      let arr = [];
      for (let i of this.registrants) {
        arr.push(i.packagesubtotal);
      }
      let total = arr.reduce((a, b) => a + b, 0);
      this.$emit("onPackagePrice", total);
      return total;
    },

    getIndividualTotal() {
      let arr = [];
      for (let i of this.registrants) {
        arr.push(i.individualsubtotal);
      }
      let total = arr.reduce((a, b) => a + b, 0);
      this.$emit("onIndividualPrice", total);
      return total;
    },

    getDiscountedTotal() {
      let arr = [];
      for (let i of this.registrants) {
        arr.push(i.discountedTotal);
      }
      let total = arr.reduce((a, b) => a + b, 0);
      this.$emit("onPrices", total);
      return total;
    }
  }
};
</script>
