<template>
  <div>
    <BackButton
      @click="$emit('back')"
      :class="{ 'ma-2': $vuetify.breakpoint.mobile }"
    />

    <p class="font-weight-bold mb-2 mt-7 primary--text text-center text-h6">
      {{ $store.getters["lab/appointment/getClinicType"] }}
    </p>
    <p class="mb-7 text-center">
      Add patient/s, select tests, then click NEXT to continue.
      <br v-show="freezePage" />
      <span v-show="freezePage" class="red--text">
        Uploading data, please wait...
      </span>
    </p>

    <v-dialog v-model="updatePatientDialog" width="800">
      <UpdatePatientData
        :data="patientDataForUpdate"
        @onError="savePatientDataError"
        @onSave="savePatientData"
        :key="renderUpdatePatientData"
      />
    </v-dialog>

    <div v-for="(scheduledPatient, i) in getPatients" :key="i">
      <PatientCard
        :data="scheduledPatient"
        @onDelete="onPatientDelete"
        @onUpdate="onPatientUpdate"
        @triggerPatientUpdate="updatePatientData(scheduledPatient.patient)"
        :key="renderPatientCard"
        class="mt-5"
      />
    </div>

    <v-card
      v-show="getTotalCost > 0"
      :key="renderTotal"
      class="font-weight-bold mt-5 mx-1 px-4 pt-7"
      elevation="3"
    >
      <v-row>
        <v-col cols="9" sm="6" class="py-0">
          TOTAL PRICE
        </v-col>
        <v-col
          cols="3"
          sm="6"
          class="pt-0  text-right"
          :class="{
            'pr-13': !$vuetify.breakpoint.mobile,
            'text-center': $vuetify.breakpoint.mobile
          }"
        >
          <PesoAmount :amount="getTotalCost" />
        </v-col>
      </v-row>
    </v-card>

    <v-row align="center" justify="center" class="pa-3" no-gutters>
      <v-col class="px-1" cols="10" sm="6">
        <v-select
          :items="patientNames"
          v-model="selectedPatient"
          item-text="name"
          return-object
          :disabled="freezePage"
        />
      </v-col>
      <v-col class="centerThis">
        <v-btn
          small
          class="px-1"
          @click="addPatient"
          color="primary"
          :disabled="freezePage"
        >
          Add Patient
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-5 text-center d-flex"> </v-row>

    <NextButton
      @click="$emit('next')"
      :disabled="isNextButtonDisabled"
      :class="{ 'ma-2': $vuetify.breakpoint.mobile }"
    />
  </div>
</template>

<script>
import { DB, getCurrentUserUid } from "@/common/store";

import { db } from "@/main";
import { getLabScheduleFullName } from "@/utilities/profile";
import { getLabScheduleProfile } from "@/common/utilities/profile";
import { mapGetters } from "vuex";

import BackButton from "@/components/Controls/BackButton.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import PatientCard from "./PatientCard.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";
import UpdatePatientData from "./UpdatePatientData.vue";

export default {
  name: "TestTab",

  components: {
    BackButton,
    NextButton,
    PatientCard,
    PesoAmount,
    UpdatePatientData
  },

  data: () => ({
    patientDataForUpdate: {},
    renderUpdatePatientData: false,
    updatePatientDialog: false,

    patients: {},

    patientNames: [],
    selectedPatient: { id: 0, name: "" },

    freezePage: false,

    discounts: [],
    renderPatientCard: false,

    renderTotal: false,

    hasNoSelectedTest: true,

    ADD_NEW_PATIENT: "Add New Patient"
  }),

  mounted() {
    this.$store.dispatch("loadDiscounts").then(discounts => {
      this.discounts = [...discounts];
      this.renderPatientCard = !this.renderPatientCard;
    });

    this.patientNames = [];

    this.$store.dispatch("loadProfile").then(profile => {
      let currentUserId = getCurrentUserUid();
      let currentUser = getLabScheduleProfile(profile);

      if (!this.findPatient(currentUserId)) {
        let fullName = getLabScheduleFullName(currentUser);
        let currentUserName = fullName ? `Me (${fullName})` : "Me";
        this.patientNames = [{ id: currentUserId, name: currentUserName }];
      }

      this.patients[currentUserId] = { ...currentUser, id: currentUserId };
      this.addAssignedPatients();

      this.updateHasNoSelectedTest();
      this.renderPatientCard = !this.renderPatientCard;
    });
  },

  computed: {
    ...mapGetters({
      findPatient: "lab/appointment/findPatient",
      getPatients: "lab/appointment/getPatients",
      getTotalCost: "lab/appointment/getTotalCost"
    }),

    isNextButtonDisabled() {
      return (
        !this.getPatients ||
        !this.getPatients.length ||
        this.hasNoSelectedTest ||
        this.freezePage
      );
    }
  },

  methods: {
    addAssignedPatients() {
      this.getAssignedUsers(getCurrentUserUid()).then(users => {
        users.forEach(user => {
          const fullName = getLabScheduleFullName(user);

          if (!this.findPatient(user.id)) {
            this.patientNames.push({ id: user.id, name: fullName });
          }

          this.patients[user.id] = { ...user };
        });

        this.addNewPatientOption();
      });
    },

    addNewPatientOption() {
      this.patientNames.push({ id: 0, name: this.ADD_NEW_PATIENT });
      this.selectedPatient = this.patientNames[0];
    },

    addPatient() {
      if (this.selectedPatient.name === this.ADD_NEW_PATIENT) {
        this.patientDataForUpdate = {};
        this.showUpdatePatientDataDialog();
      } else {
        this.$store.commit(
          "lab/appointment/ADD_PATIENT",
          {
            patient: { ...this.patients[this.selectedPatient.id] }
          },
          { root: true }
        );

        let index = this.patientNames.indexOf(this.selectedPatient);
        this.patientNames.splice(index, 1);

        this.selectedPatient = this.patientNames[0];
      }
    },

    getAssignedUsers(currentUserId) {
      return new Promise((resolve, reject) => {
        db.collection(DB.PROFILES)
          .where("assignedTo", "array-contains", currentUserId)
          .orderBy("lastName")
          .orderBy("firstName")
          .orderBy("middleName")
          .get()
          .then(snapshot => {
            let data = [];

            snapshot.forEach(doc => {
              data.push({ ...doc.data(), id: doc.id });
            });

            resolve(data);
          })
          .catch(err => reject(err));
      });
    },

    insertCurrentUser(patient) {
      let fullName = getLabScheduleFullName(patient.patient);
      fullName = fullName ? `Me (${fullName})` : "Me";
      this.patientNames.unshift({ id: patient.patient.id, name: fullName });

      this.selectedPatient = this.patientNames[0];
    },

    insertOtherUser(patient) {
      let fullName = getLabScheduleFullName(patient.patient);

      const firstEntry = this.patientNames[0];
      if (getCurrentUserUid() === firstEntry.id) {
        this.patientNames.splice(0, 1);
      }

      this.patientNames.pop();

      this.patientNames.push({ id: patient.patient.id, name: fullName });
      this.patientNames.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else {
          return a.name > b.name ? 1 : 0;
        }
      });

      if (getCurrentUserUid() === firstEntry.id) {
        this.patientNames.unshift({ id: firstEntry.id, name: fullName });
      }

      this.addNewPatientOption();
    },

    onPatientDelete(patient) {
      this.updatePatientNames(patient);

      this.$store.commit("lab/appointment/DELETE_PATIENT", patient, {
        root: true
      });
      this.$store.commit("lab/appointment/UPDATE_BILL_TOTAL", "", {
        root: true
      });
    },

    onPatientUpdate() {
      this.renderTotal = !this.renderTotal;
      this.updateHasNoSelectedTest();
    },

    savePatientData(data) {
      this.freezePage = true;

      if (data.isAdded) {
        this.patients[data.data.id] = { ...data.data };
      }

      this.renderPatientCard = !this.renderPatientCard;
      this.updatePatientDialog = false;
      this.freezePage = false;
    },

    savePatientDataError() {
      this.updatePatientDialog = false;
    },

    showUpdatePatientDataDialog() {
      this.updatePatientDialog = true;
      this.renderUpdatePatientData = !this.renderUpdatePatientData;
    },

    updateHasNoSelectedTest() {
      this.hasNoSelectedTest = this.getPatients.reduce(
        (hasNoSelectedTest, scheduledPatient) => {
          return (
            hasNoSelectedTest &&
            (!scheduledPatient.packages || !scheduledPatient.packages.length) &&
            (!scheduledPatient.tests || !scheduledPatient.tests.length)
          );
        },
        true
      );
    },

    updatePatientData(data) {
      this.patientDataForUpdate = { ...data };
      this.showUpdatePatientDataDialog();
    },

    updatePatientNames(patient) {
      if (getCurrentUserUid() === patient.patient.id) {
        this.insertCurrentUser(patient);
      } else {
        this.insertOtherUser(patient);
      }
    }
  }
};
</script>

<style scoped>
.centerThis {
  margin: auto;
  text-align: center;
}
</style>
