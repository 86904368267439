<template>
  <div class="mt-5 text-center">
    <v-container>
      <v-row align="center" justify="center">
        <v-col
          cols="12"
          lg="6"
          md="6"
          class="d-flex flex-column justify-center align-center"
        >
          <v-card flat>
            <TakePhoto v-on:take-photo="uploadPhoto" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ROUTE_PROFILE, ROUTE_RXPRESCRIPTION, routeTo } from "@/router";

import { mapActions, mapGetters } from "vuex";

import TakePhoto from "@/common/components/controls/TakePhoto.vue";

export default {
  name: "Capture",

  components: {
    TakePhoto
  },

  methods: {
    ...mapActions(["setCapturedImage"]),

    async uploadPhoto(file) {
      this.loading = true;

      this.setCapturedImage(file).then(() => {
        routeTo(
          this.getPreviousRoute.name === "Buy Prescription Drugs"
            ? ROUTE_RXPRESCRIPTION
            : ROUTE_PROFILE
        );
      });
    }
  },

  computed: {
    ...mapGetters(["getPreviousRoute"])
  }
};
</script>
