<template>
  <v-card>
    <v-card flat aspect-ratio="2.75" height="44" color="primary">
      <h3 class="white--text headline mb-0 text-center pa-2">
        Medical History
      </h3>
    </v-card>

    <v-card-text class="mt-4">
      <div class="text-justify body-1">
        Lagyan ng <strong><em>check</em></strong> ang mga pangungusap na angkop
        sa inyo.
      </div>

      <div class="ml-5 mt-3">
        <v-checkbox label="Ako ay nag-positibo sa COVID-19." />
        <v-checkbox
          class="mt-n3"
          label="Ako ay may nakasama o nakasalamuhang nag-positibo sa COVID-19 sa nakaraang dalawang linggo (14 na araw)."
        />
        <v-checkbox
          class="mt-n3"
          label="Ako ay nanggaling sa ibang bansa sa nakaraang dalawang linggo (14 na araw)."
        />
        <v-checkbox
          class="mt-n3"
          label="Ako ay nakisalamuha sa maraming tao sa pagtitipon sa nakaraang dalawang linggo (14 na araw)."
        />
        <v-checkbox
          class="mt-n3"
          label="Ako ay nakagat ng aso sa nakaraang 4 na linggo at nabakunahan."
        />
        <v-checkbox
          class="mt-n3"
          label="Ako ay nabakunahan ng COVID-19 vaccine sa nakaraang 4 na linggo at nabakunahan."
        />
        <v-checkbox class="mt-n3" label="Ako ay sinasalinan ng dugo." />
        <v-checkbox
          class="mt-n3"
          label="Ako ay umiinom ng Prednisone / Steroids o antiviral drugs."
        />
        <v-checkbox
          class="mt-n3"
          label="Ako ay may allergy sa latex, pagkain, gamot o bakuna."
        />
        <v-checkbox
          class="mt-n3"
          label="Ako ay nagkaroon ng malalang reaksyon pagkatapos mabakunahan."
        />
        <v-checkbox
          class="mt-n3"
          label="Ako ay buntis o may planong magbuntis sa darating na buwan"
        />
        <v-checkbox class="mt-n3" label="Ako ay nagpapasuso" />
        <v-checkbox
          class="mt-n3"
          label="Ako ay kasama sa COVID-19 Clinical Study"
        />
      </div>

      <div class="mt-3 text-justify body-1">
        Lagyan ng <strong><em>check</em></strong> kung nakaramdam ka ng mga
        sumusunod na sintomas sa loob ng nakaraang dalawang linggo (14 na araw).
      </div>

      <div class="ml-5 mt-3">
        <v-checkbox label="Lagnat" />
        <v-checkbox class="mt-n3" label="Sipon" />
        <v-checkbox class="mt-n3" label="Ubo" />
        <v-checkbox
          class="mt-n3"
          label="Pananakit ng lalamunan (Sore Throat)"
        />
        <v-checkbox class="mt-n3" label="Pananakit ng katawan" />
        <v-checkbox class="mt-n3" label="Kawalan ng pangamoy at panlasa" />
        <v-checkbox class="mt-n3" label="Hingal/hirap sa paghinga" />
        <v-checkbox class="mt-n3" label="Pagtatae" />
      </div>

      <div class="mt-3 text-justify body-1">
        Lagyan ng <strong><em>check</em></strong> kung ikaw ay mayroon o
        nagkaroon ng mga sumusunod na karamdaman.
      </div>

      <div class="ml-5 mt-3">
        <v-checkbox label="Sakit sa baga" />
        <v-checkbox class="mt-n3" label="Sakit sa puso" />
        <v-checkbox class="mt-n3" label="Hika" />
        <v-checkbox class="mt-n3" label="Sakit sa bato" />
        <v-checkbox class="mt-n3" label="Diabetes" />
        <v-checkbox class="mt-n3" label="Altapresyon" />
        <v-checkbox class="mt-n3" label="Sakit sa dugo" />
        <v-checkbox class="mt-n3" label="Cancer" />
        <v-checkbox class="mt-n3" label="Leukemia" />
        <v-checkbox class="mt-n3" label="Organ Transplant" />
        <v-checkbox class="mt-n3" label="HIV" />
        <v-checkbox class="mt-n3" label="Epilepsy" />
        <v-checkbox class="mt-n3" label="Allergy sa gamot o pagkain" />
        <v-checkbox class="mt-n3" label="Iba pang sakit" />
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn text color="primary" class="learn-more-btn">
        Learn more
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary">
        Get started
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CovidVaccineMedicalHistory"
};
</script>
