<template>
  <div class="text-center">
    <div class="body pa-1">
      <span class="mdi mdi-map-marker-radius-outline" /> Delivery Address
    </div>

    <v-card @click="goToSelectAddress()">
      <v-card-text v-if="deliveryAddress">
        <div class="text--primary">
          {{ contactDetails }}
        </div>
        <div>
          {{ deliveryAddress }}
        </div>
      </v-card-text>

      <v-card-text v-else>
        Register address
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ROUTE_ADDRESS_BOOK, routeTo } from "@/router";

import { getAddress, getFullAddress3 } from "@/common/utilities/location";

export default {
  name: "AddressBox",

  props: {
    addressSelected: Object
  },

  computed: {
    contactDetails() {
      return this.addressSelected
        ? [this.addressSelected.name, this.addressSelected.contact].join(", ")
        : "";
    },

    deliveryAddress() {
      return this.addressSelected
        ? [
            getFullAddress3(this.addressSelected.selectedLocation),
            getAddress(this.addressSelected)
          ].join(", ")
        : "";
    }
  },

  methods: {
    goToSelectAddress() {
      routeTo(`${ROUTE_ADDRESS_BOOK}/select`);
    }
  }
};
</script>
