<template>
  <div class="text-center">
    <v-card elevation="0" class="container">
      <v-row>
        <v-col cols="12" sm="6">
          <PayeeDetails
            ref="payeeDetails"
            :initialModeOfPayment="initialModeOfPayment"
            :key="initialModeOfPayment"
            :payref="$store.getters['doctor/appointment/getPayRef']"
            @address="address = $event"
            @contact="contact = $event"
            @courier="courier = $event"
            @isValid="isValid = $event"
            @modeOfPayment="modeofpayment = $event"
            @name="name = $event"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <CostBreakdown
            :key="$store.getters['doctor/appointment/getServiceFee']"
            :serviceFee="$store.getters['doctor/appointment/getServiceFee']"
            :discountValue="
              $store.getters['doctor/appointment/getDiscountedValue']
            "
            :discountedTotal="
              $store.getters['doctor/appointment/getDiscountedTotal']
            "
          />
          <MOPSummary
            :modeofpayment="modeofpayment"
            :balance="$store.state.profile.bantayKardBalance"
            :remainingbalance="remainingbalance"
            :finTotal="$store.getters['doctor/appointment/getDiscountedTotal']"
            @referenceNoTyping="referenceNo = $event"
            @isValid="isValidMOP = $event"
          />
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col cols="12" style="text-align: right;">
          <v-btn color="primary" @click="confirmPaymentDetails">
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <ConfirmDialog ref="confirm" @on-close="onConfirmationClose" />
  </div>
</template>

<script>
import {
  PAYMENT_BANTAY_KARD,
  isPaymentForValidation
} from "@/common/utilities/payment";
import { ROUTE_DOCTOR, routeTo } from "@/router";

import { constructDataEntry } from "@/utilities/doctor";
import { SetDoctorAppointment } from "@/store/doctor_attendee";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import CostBreakdown from "@/components/Konsulta/CostBreakdown.vue";
import MOPSummary from "@/components/Payment/MOPSummary.vue";
import PayeeDetails from "@/components/Payment/PayeeDetails.vue";

export default {
  name: "Checkout",

  components: {
    ConfirmDialog,
    MOPSummary,
    PayeeDetails,
    CostBreakdown
  },

  data: () => ({
    balance: 0,
    name: "",
    contact: "",
    address: "",
    courier: "",
    modeofpayment: "",
    isValidMOP: false,

    referenceNo: "",

    initialModeOfPayment: PAYMENT_BANTAY_KARD
  }),

  computed: {
    remainingbalance() {
      return (
        Number(this.balance) -
        Number(this.$store.getters["doctor/appointment/getDiscountedTotal"])
      );
    }
  },

  methods: {
    confirmPaymentDetails() {
      let payment = {
        address: this.address,
        contactNumber: this.contact,
        isPaid: !isPaymentForValidation(this.modeofpayment),
        modeOfPayment: this.modeofpayment,
        name: this.name,
        referenceNumber: this.referenceNo,
        timestamp: new Date()
      };

      this.$store.commit(
        "doctor/appointment/SET_ADDRESS_LINE",
        payment.address,
        {
          root: true
        }
      );
      this.$store.commit("doctor/appointment/SET_PAYMENT", payment, {
        root: true
      });
      this.$store.commit(
        "doctor/appointment/SET_CONTACT",
        payment.contactNumber,
        {
          root: true
        }
      );
      this.$store.commit("doctor/appointment/SET_TRANSACTION_STATUS", false, {
        root: true
      });

      let appointment = this.$store.getters[
        "doctor/appointment/getAppointment"
      ];
      SetDoctorAppointment(constructDataEntry(appointment)).then(() => {
        this.showConfirmationMessageHomeService();
      });
    },

    dialogText() {
      return [
        this.$t("laboratory.appointmentConfirmationDialogTextLine1"),
        this.$t("laboratory.appointmentConfirmationDialogTextLine2"),
        this.$t("laboratory.appointmentConfirmationDialogTextLine3")
      ].join("<br /><br />");
    },

    showConfirmationMessageHomeService() {
      this.$refs.confirm.open(
        this.$t("dialog.confirmation"),
        this.dialogText(),
        { noconfirm: true }
      );
    },

    onConfirmationClose() {
      this.$emit("on-confirm");
      this.$emit("dialog-close", false);
      routeTo(ROUTE_DOCTOR);
    }
  }
};
</script>

<style scoped>
.price-entry {
  text-align: right !important;
  width: 110px;
}

.floaterl {
  float: left;
}
.container {
  max-width: 850px;
}
</style>
