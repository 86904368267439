<template>
  <div>
    <AppHeader header="Laboratory" />

    <v-container fluid class="bhccontainer">
      <BhcAppointment
        :selectedTab="selectedTab"
        :messageIndex="Number(messageIndex)"
      />
    </v-container>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import BhcAppointment from "@/components/Laboratory/BhcAppointment.vue";

export default {
  name: "MenuItems",

  components: {
    AppHeader,
    BhcAppointment
  },

  props: {
    inputTab: String,
    messageIndex: String
  },

  data: () => ({
    selectedTab: 1
  }),

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");

    if (this.inputTab) {
      const value = parseInt(this.inputTab);
      this.selectedTab = value > 4 ? 4 : value;
    }
  }
};
</script>

<style scoped>
.bhccontainer {
  max-width: 1080px;
}
</style>
