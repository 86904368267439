<template>
  <div>
    <h3 class="mb-5 text-center" v-if="hasHeader">Family History</h3>

    <div v-if="familyHistoryChecked.length || familyHistoryText.length">
      <v-row class=" my-1 mx-0">
        <v-col :cols="checkedCol" class="text-center py-0">
          <p class="text-subtitle-2 text-center mb-0">
            Father's Family
          </p>
        </v-col>
        <v-col :cols="checkedCol" class="text-center py-0">
          <p class="text-subtitle-2 text-center mb-0">
            Mother's Family
          </p>
        </v-col>
        <v-col :cols="itemCol" class="text-center py-0">
          <p class="text-subtitle-2 text-center mb-0">
            Illness
          </p>
        </v-col>
      </v-row>

      <v-row
        v-for="(item, i) in familyHistoryChecked"
        :key="i"
        class="mt-0 mx-0 "
        :class="{ 'background-grey': !(i % 2) }"
      >
        <v-col :cols="checkedCol" class="text-center">
          <p class="text-subtitle-2 text-center py-0 mb-0">
            {{ item.father ? "&check;" : "" }}
          </p>
        </v-col>
        <v-col :cols="checkedCol" class="text-center">
          <p class="text-subtitle-2 text-center py-0 mb-0">
            {{ item.mother ? "&check;" : "" }}
          </p>
        </v-col>
        <v-col :cols="itemCol">
          <p class="text-subtitle-2 text-center py-0 mb-0">
            {{ item.illness }}
          </p>
        </v-col>
      </v-row>

      <v-row
        v-for="(item, i) in familyHistoryText"
        :key="i + familyHistoryChecked.length"
        class="mt-0 mx-0 "
        :class="{ 'background-grey': !((i + familyHistoryChecked.length) % 2) }"
      >
        <v-col :cols="checkedCol" class="text-center">
          <p class="text-subtitle-2 text-center py-0 mb-0">
            {{ item["father"].value ? "&check;" : "" }}
          </p>
        </v-col>
        <v-col :cols="checkedCol" class="text-center">
          <p class="text-subtitle-2 text-center py-0 mb-0">
            {{ item["mother"].value ? "&check;" : "" }}
          </p>
        </v-col>
        <v-col :cols="itemCol">
          <p class="text-subtitle-2 text-center py-0 mb-0">
            {{ item.illness }}
          </p>
        </v-col>

        <v-col
          :cols="paddingCol"
          v-show="item['father'].value || item['mother'].value"
          class="mt-0 pa-0"
        >
        </v-col>
        <v-col :cols="illnessTypeCol">
          <span v-if="item['father'].value">
            <em class="text-subtitle-2 text-center py-0 mb-0">
              Father: {{ item["father"].comment }}
            </em>
            <br />
          </span>
          <span v-if="item['mother'].value">
            <em class="text-subtitle-2 text-center py-0 mb-0">
              Mother: {{ item["mother"].comment }}
            </em>
            <br />
          </span>
        </v-col>
      </v-row>
    </div>

    <div v-else class="text-center">None</div>
  </div>
</template>

<script>
import { NS_HEALTHRECORDS_FAMILYHISTORY as NAMESPACE } from "@/store";

import { setWidth } from "@/utilities/display";

export default {
  name: "Confirmation",

  props: {
    hasHeader: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    checkedCol() {
      return setWidth(this.$vuetify.breakpoint.width, 2, 3, 2, 4, 4);
    },

    familyHistoryChecked() {
      return this.$store.getters[`${NAMESPACE}/getCheckedHistory`].filter(
        item => {
          return item["father"] || item["mother"];
        }
      );
    },

    familyHistoryText() {
      return this.$store.getters[`${NAMESPACE}/getTextHistory`].filter(item => {
        return item["father"].value || item["mother"].value;
      });
    },

    illnessTypeCol() {
      return setWidth(this.$vuetify.breakpoint.width, 4, 6, 8, 10, 11);
    },

    itemCol() {
      return setWidth(this.$vuetify.breakpoint.width, 4, 6, 8, 4, 4);
    },

    paddingCol() {
      return setWidth(this.$vuetify.breakpoint.width, 0, 6, 4, 2, 1);
    }
  }
};
</script>
