<template>
  <v-card class="pb-4">
    <InsurancePackages
      :initialPackage="initialPackage"
      :packageDetails="packageDetails"
      :packageNames="packageNames"
      v-on:selected-package-name="onSelectedPackageName"
    />

    <v-row v-if="!isUserVerified">
      <v-btn
        class="verify success mx-o mt-2"
        :disabled="isVerificationRequested"
        :to="ROUTE_VALIDATE"
      >
        {{ verificationButtonText }}
      </v-btn>
    </v-row>

    <v-row>
      <h4 class="ml-6 mt-6">Beneficiary(ies)</h4>
    </v-row>

    <InsuranceBeneficiary
      class="mt-n2"
      :disabled="!isUserVerified"
      id="1"
      ref="beneficiary1"
      v-on:beneficiary-data="onBeneficiaryData"
    />

    <v-row class="mt-6" v-if="hasPadding">
      <v-col class="mt-n10" cols="12" sm="11">
        <v-divider class="ml-6" />
      </v-col>
    </v-row>
    <v-row class="mt-4 mb-3" v-else>
      <v-col class="ml-4 mt-n7" cols="11">
        <v-divider />
      </v-col>
    </v-row>

    <InsuranceBeneficiary
      class="mt-n8"
      id="2"
      ref="beneficiary2"
      :disabled="!isUserVerified"
      v-on:beneficiary-data="onBeneficiaryData"
    />

    <v-row class="mt-6" v-if="hasPadding">
      <v-col class="mt-n10" cols="12" sm="11">
        <v-divider class="ml-6" />
      </v-col>
    </v-row>
    <v-row class="mt-4 mb-3" v-else>
      <v-col class="ml-4 mt-n7" cols="11">
        <v-divider />
      </v-col>
    </v-row>

    <InsuranceBeneficiary
      class="mt-n6"
      id="3"
      :disabled="!isUserVerified"
      ref="beneficiary3"
      v-on:beneficiary-data="onBeneficiaryData"
    />

    <v-row class="mt-n4">
      <v-col class="ml-6 mr-6" cols="11">
        <v-checkbox
          v-model="terms"
          type="checkbox"
          :key="beneficiaryUpdated"
          :disabled="!valid || !isUserVerified"
          :label="$t('insurance.insuranceTerms')"
        />
      </v-col>
    </v-row>

    <v-row class="mt-n4 mb-2">
      <v-btn
        :disabled="!terms"
        @click="dialog = true"
        class="packages success mx-o mt-3"
        type="submit"
      >
        Subscribe
      </v-btn>
    </v-row>

    <v-dialog v-model="dialog" width="800">
      <InsuranceSummary
        :beneficiaryData="beneficiaryData"
        :packageDetails="packageDetails"
        :selectedPackage="selectedPackageName"
        v-on:payment-made="paymentMade"
      />
    </v-dialog>

    <v-dialog v-model="paymentMadeDialog" width="800" :key="paymentMadeDialog">
      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title class="centerThis">Payment Made</v-toolbar-title>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import InsuranceBeneficiary from "./Beneficiary";
import InsurancePackages from "./Packages";
import InsuranceSummary from "./Summary";

import { ROUTE_VALIDATE } from "@/router";

import { logUserActivity } from "@/common/store";

export default {
  name: "InsuranceForm",

  components: {
    InsuranceBeneficiary,
    InsurancePackages,
    InsuranceSummary
  },

  props: {
    packageNames: Array,
    packageDetails: Object,
    initialPackage: String
  },

  data: () => ({
    beneficiaryData: {},
    beneficiaryUpdated: 0,
    dialog: false,
    isUserVerified: false,
    isVerificationRequested: false,
    paymentMadeDialog: false,
    selectedPackageName: "",
    terms: false,
    valid: false,

    ROUTE_VALIDATE: ROUTE_VALIDATE
  }),

  computed: {
    hasPadding() {
      return window.innerWidth > 500;
    },

    verificationButtonText() {
      return this.isVerificationRequested
        ? "Your Details are Being Verified"
        : "Verify Details to Proceed";
    }
  },

  mounted() {
    let baseData = {
      isValid: false,
      hasContent: false,
      beneficiaryType: "",
      birthDate: "",
      fullName: "",
      relationship: ""
    };

    this.beneficiaryData["1"] = { ...baseData };
    this.beneficiaryData["2"] = { ...baseData };
    this.beneficiaryData["3"] = { ...baseData };

    this.isUserVerified = this.$store.state.profile.isVerified;
    this.isVerificationRequested = this.$store.state.profile.verificationRequested;
  },

  methods: {
    getBeneficiaryData(key) {
      let data = this.beneficiaryData[key];
      if (data.isValid) {
        return {
          birthDate: data.birthDate,
          fullName: data.fullName,
          isRevocable: data.beneficiaryType == "Irrevocable",
          relationship: data.relationship
        };
      }

      return null;
    },

    getBeneficiaries() {
      let beneficiaries = [];

      let data = this.getBeneficiaryData("1");
      if (data) {
        beneficiaries.push({ ...data });
      }

      data = this.getBeneficiaryData("2");
      if (data) {
        beneficiaries.push({ ...data });
      }

      data = this.getBeneficiaryData("3");
      if (data) {
        beneficiaries.push({ ...data });
      }

      return beneficiaries;
    },

    onBeneficiaryData(beneficiaryData) {
      let id = beneficiaryData.id;
      this.beneficiaryData[id].isValid = beneficiaryData.isValid;
      this.beneficiaryData[id].hasContent = beneficiaryData.hasContent;
      this.beneficiaryData[id].beneficiaryType =
        beneficiaryData.beneficiaryType;
      this.beneficiaryData[id].birthDate = beneficiaryData.birthDate;
      this.beneficiaryData[id].fullName = beneficiaryData.fullName;
      this.beneficiaryData[id].relationship = beneficiaryData.relationship;

      this.valid =
        this.beneficiaryData["1"].isValid &&
        (this.beneficiaryData["2"].isValid ||
          !this.beneficiaryData["2"].hasContent) &&
        (this.beneficiaryData["3"].isValid ||
          !this.beneficiaryData["3"].hasContent);

      this.beneficiaryUpdated += 1;
    },

    onSelectedPackageName(selectedPackageName) {
      this.selectedPackageName = selectedPackageName;
    },

    paymentMade() {
      let selectedPackage = this.packageDetails[this.selectedPackageName];
      let paymentDate = new Date();
      let beneficiaries = this.getBeneficiaries();

      let transaction = {
        amount: selectedPackage.price * -1,
        description: "Premium Payment for " + selectedPackage.name,

        beneficiaries: beneficiaries,
        benefits: { ...selectedPackage.benefits },
        name: selectedPackage.name,

        status: {
          expiration: new Date(
            paymentDate.getFullYear() + 1,
            paymentDate.getMonth(),
            paymentDate.getDate()
          ),
          isActive: true,
          paymentDate: paymentDate
        }
      };

      this.$store.dispatch("addInsuranceTransaction", transaction);
      logUserActivity("Add Insurance", transaction);

      this.$refs.beneficiary1.clearData();
      this.$refs.beneficiary2.clearData();
      this.$refs.beneficiary3.clearData();

      this.$emit("payment-made");

      this.dialog = false;
      this.paymentMadeDialog = true;
    }
  }
};
</script>

<style scoped>
.centerThis {
  margin: auto;
  text-align: center;
}

.packages {
  margin: auto;
  max-width: 200px;
}

.verify {
  margin: auto;
}
</style>
