<template>
  <v-card>
    <v-card flat aspect-ratio="2.75" height="44" color="primary">
      <h3 class="white--text headline mb-0 text-center pa-2">
        Pre-Booking Details
      </h3>
    </v-card>

    <v-card-text class="mt-4">
      <div class="text-justify body-1">
        Lorem ipsum
      </div>

      <div class="text-justify body-1 mt-4">
        May <strong>{{ registrants }}</strong> nang nakapagpatala para sa
        bakuna.
      </div>

      <div class="text-center mt-8">
        <v-btn class="success" @click="$emit('next')">
          Gusto kong magpatala
        </v-btn>
      </div>

      <!-- footer spacer -->
      <div class="mb-4"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import { getPrebookingCount } from "@/common/store";

export default {
  name: "CovidVaccinePreBookingDetails",

  data: () => ({
    registrants: 0
  }),

  mounted() {
    getPrebookingCount().then(messageCount => {
      this.registrants = messageCount;
    });
  }
};
</script>
