import {
  DOCTYPE_PERSONALSOCIALHISTORY,
  getDocumentHeader,
  IndexedDocument
} from "@/utilities/user_collection";

import { emptyBooleanText, emptyText, emptyTextText } from "@/utilities/fields";

export const ALLERGIES = "Allergies";
export const ASTHMA = "Asthma";
export const CANCER = "Cancer";
export const DIABETIC_MELLITUS = "Diabetic Mellitus";
export const GOITER = "Goiter";
export const HYPERTENSION = "Hypertension";
export const PTB = "PTB (Pulmonary Tuberculosis)";
export const OTHERS = "Others";

export const COVID19 = "Covid-19";
export const COVID19_1STSHOT = "Covid-19 (First Shot)";
export const COVID19_2NDSHOT = "Covid-19 (Second Shot)";
export const COVID19_BOOSTER = "Covid-19 (Booster Shot)";
export const DIPTHERIA_TETANUS = "Diptheria Tetanus";
export const HEPATITIS = "Hepatitis";
export const INFLUENZA = "Influenza";
export const PNEUMOCOCCAL = "Pneumococcal";

export const KEY_ALCOHOL = "alcohol";
export const KEY_ILLICIT_DRUGS = "illicitDrugs";
const KEY_IMMUNIZATION_HISTORY = "immunizationHistory";
const KEY_MAINTENANCE_MEDICATIONS = "maintenanceMedications";
const KEY_PRESENT_ILLNESSES = "presentIllnesses";
export const KEY_SEXUAL_ACTIVITY = "sexualActivity";
export const KEY_SMOKING = "smoking";

function emptyIllnesses() {
  return {
    [HYPERTENSION]: emptyBooleanText(), //false,
    [DIABETIC_MELLITUS]: emptyBooleanText(), //false,
    [ASTHMA]: emptyBooleanText(), //false,
    [PTB]: emptyBooleanText(), //false,
    [GOITER]: emptyBooleanText(), //false,
    [CANCER]: emptyBooleanText(), //false,
    [ALLERGIES]: emptyBooleanText(),
    [OTHERS]: emptyBooleanText()
  };
}

// to preserve order in iterations
function saveIllnesses(data) {
  return {
    [HYPERTENSION]: data[HYPERTENSION],
    [DIABETIC_MELLITUS]: data[DIABETIC_MELLITUS],
    [ASTHMA]: data[ASTHMA],
    [PTB]: data[PTB],
    [GOITER]: data[GOITER],
    [CANCER]: data[CANCER],
    [ALLERGIES]: data[ALLERGIES],
    [OTHERS]: data[OTHERS]
  };
}

function emptyImmunizations() {
  return {
    [DIPTHERIA_TETANUS]: emptyBooleanText(),
    [HEPATITIS]: emptyBooleanText(),
    [INFLUENZA]: emptyBooleanText(),
    [PNEUMOCOCCAL]: emptyBooleanText(),
    //[COVID19]: emptyBooleanText(),
    [COVID19_1STSHOT]: emptyBooleanText(),
    [COVID19_2NDSHOT]: emptyBooleanText(),
    [COVID19_BOOSTER]: emptyBooleanText(),
    [OTHERS]: emptyBooleanText()
  };
}

// to preserve order in iterations
function saveImmunizations(data) {
  return {
    [DIPTHERIA_TETANUS]: data[DIPTHERIA_TETANUS] || emptyBooleanText(),
    [HEPATITIS]: data[HEPATITIS] || emptyBooleanText(),
    [INFLUENZA]: data[INFLUENZA] || emptyBooleanText(),
    [PNEUMOCOCCAL]: data[PNEUMOCOCCAL] || emptyBooleanText(),
    //[COVID19]: data[COVID19],
    [COVID19_1STSHOT]: data[COVID19_1STSHOT] || emptyBooleanText(),
    [COVID19_2NDSHOT]: data[COVID19_2NDSHOT] || emptyBooleanText(),
    [COVID19_BOOSTER]: data[COVID19_BOOSTER] || emptyBooleanText(),
    [OTHERS]: data[OTHERS] || emptyBooleanText()
  };
}

function emptySexualActivity() {
  return {
    value: "",
    timesAWeek: "",
    partners: "",
    lastUpdate: ""
  };
}

function emptyAlcohol() {
  return {
    value: "",
    glasses: "",
    period: "",
    lastIntake: "",
    lastUpdate: ""
  };
}

function emptySmoking() {
  return {
    value: "",
    comment: "",
    details: "",
    lastUpdate: ""
  };
}

function getData(state) {
  return {
    [KEY_SMOKING]: { ...state[KEY_SMOKING] },
    [KEY_ALCOHOL]: { ...state[KEY_ALCOHOL] },
    [KEY_ILLICIT_DRUGS]: { ...state[KEY_ILLICIT_DRUGS] },
    [KEY_SEXUAL_ACTIVITY]: { ...state[KEY_SEXUAL_ACTIVITY] },
    [KEY_PRESENT_ILLNESSES]: { ...state[KEY_PRESENT_ILLNESSES] },
    [KEY_IMMUNIZATION_HISTORY]: { ...state[KEY_IMMUNIZATION_HISTORY] },
    [KEY_MAINTENANCE_MEDICATIONS]: { ...state[KEY_MAINTENANCE_MEDICATIONS] }
  };
}

function getDocumentContent(state) {
  return {
    header: getDocumentHeader(DOCTYPE_PERSONALSOCIALHISTORY, state),
    data: getData(state)
  };
}

var personalSocialHistoryBase = new IndexedDocument(
  DOCTYPE_PERSONALSOCIALHISTORY,
  getDocumentContent
);
export const personalSocialHistory = {
  state: () => ({
    ...personalSocialHistoryBase.state,

    [KEY_ALCOHOL]: emptyAlcohol(),
    [KEY_ILLICIT_DRUGS]: emptyTextText(),
    [KEY_SEXUAL_ACTIVITY]: emptySexualActivity(),
    [KEY_SMOKING]: emptySmoking(),

    [KEY_PRESENT_ILLNESSES]: emptyIllnesses(),
    [KEY_IMMUNIZATION_HISTORY]: emptyImmunizations(),
    [KEY_MAINTENANCE_MEDICATIONS]: emptyText()
  }),

  getters: {
    ...personalSocialHistoryBase.getters,

    getAlcoholGlasses(state) {
      return state[KEY_ALCOHOL].glasses;
    },

    getAlcoholLastIntake(state) {
      return state[KEY_ALCOHOL].lastIntake;
    },

    getAlcoholPeriod(state) {
      return state[KEY_ALCOHOL].period;
    },

    getComment: state => key => {
      return state[key].comment;
    },

    getIllness: state => key => {
      return state[KEY_PRESENT_ILLNESSES][key].value;
    },

    getIllnesses(state) {
      return state[KEY_PRESENT_ILLNESSES];
    },

    getImmunization: state => key => {
      return state[KEY_IMMUNIZATION_HISTORY][key].value;
    },

    getImmunizations(state) {
      return state[KEY_IMMUNIZATION_HISTORY];
    },

    getLastUpdate: state => key => {
      return state[key].lastUpdate;
    },

    getMaintenanceMedications(state) {
      return state[KEY_MAINTENANCE_MEDICATIONS].value;
    },

    getSexualActivityPartners(state) {
      return state[KEY_SEXUAL_ACTIVITY].partners;
    },

    getSexualActivityTimesAWeek(state) {
      return state[KEY_SEXUAL_ACTIVITY].timesAWeek;
    },

    getSmokingDetails(state) {
      return state[KEY_SMOKING].details;
    },

    getValue: state => key => {
      return state[key].value;
    }
  },

  mutations: {
    ...personalSocialHistoryBase.mutations,

    RESET_STATE(state) {
      state[KEY_ALCOHOL] = emptyAlcohol();
      state[KEY_ILLICIT_DRUGS] = emptyTextText();
      state[KEY_SEXUAL_ACTIVITY] = emptySexualActivity();
      state[KEY_SMOKING] = emptySmoking();

      state[KEY_PRESENT_ILLNESSES] = emptyIllnesses();
      state[KEY_IMMUNIZATION_HISTORY] = emptyImmunizations();
      state[KEY_MAINTENANCE_MEDICATIONS] = emptyText();
    },

    setAlcoholGlasses(state, value) {
      state.isSaved = state.isSaved && state[KEY_ALCOHOL].glasses == value;
      state[KEY_ALCOHOL].glasses = value;
    },

    setAlcoholLastIntake(state, value) {
      state.isSaved = state.isSaved && state[KEY_ALCOHOL].lastIntake == value;
      state[KEY_ALCOHOL].lastIntake = value;
    },

    setAlcoholPeriod(state, value) {
      state.isSaved = state.isSaved && state[KEY_ALCOHOL].period == value;
      state[KEY_ALCOHOL].period = value;
    },

    setComment(state, data) {
      state.isSaved = state.isSaved && state[data.key].comment == data.value;
      state[data.key].comment = data.value;
    },

    setData(state, data) {
      state[KEY_ALCOHOL] = data[KEY_ALCOHOL] || emptyAlcohol();
      state[KEY_ILLICIT_DRUGS] = data[KEY_ILLICIT_DRUGS] || emptyTextText();
      state[KEY_SEXUAL_ACTIVITY] =
        data[KEY_SEXUAL_ACTIVITY] || emptySexualActivity();
      state[KEY_SMOKING] = data[KEY_SMOKING] || emptyTextText();
      state[KEY_PRESENT_ILLNESSES] = data[KEY_PRESENT_ILLNESSES]
        ? saveIllnesses(data[KEY_PRESENT_ILLNESSES])
        : emptyIllnesses();
      state[KEY_IMMUNIZATION_HISTORY] = data[KEY_IMMUNIZATION_HISTORY]
        ? saveImmunizations(data[KEY_IMMUNIZATION_HISTORY])
        : emptyImmunizations();
      state[KEY_MAINTENANCE_MEDICATIONS] =
        data[KEY_MAINTENANCE_MEDICATIONS] || emptyText();
    },

    setDocumentId(state, data) {
      state.isSaved = state.isSaved && state.documentId === data;
      state.documentId = data;
    },

    setIllnessComment(state, data) {
      state.isSaved =
        state.isSaved &&
        state[KEY_PRESENT_ILLNESSES][data.key].comment === data.value;
      state[KEY_PRESENT_ILLNESSES][data.key].comment = data.value;
    },

    setIllnessValue(state, data) {
      state.isSaved =
        state.isSaved &&
        state[KEY_PRESENT_ILLNESSES][data.key].value === data.value;
      state[KEY_PRESENT_ILLNESSES][data.key].value = data.value;
    },

    setImmunizationComment(state, data) {
      state.isSaved =
        state.isSaved &&
        state[KEY_IMMUNIZATION_HISTORY][data.key].comment === data.value;
      state[KEY_IMMUNIZATION_HISTORY][data.key].comment = data.value;
    },

    setImmunizationValue(state, data) {
      state.isSaved =
        state.isSaved &&
        state[KEY_IMMUNIZATION_HISTORY][data.key].value === data.value;
      state[KEY_IMMUNIZATION_HISTORY][data.key].value = data.value;
    },

    setMaintenanceMedications(state, value) {
      state.isSaved =
        state.isSaved && state[KEY_MAINTENANCE_MEDICATIONS].value === value;
      if (!state.isSaved) {
        state[KEY_MAINTENANCE_MEDICATIONS].value = value;
        state[KEY_MAINTENANCE_MEDICATIONS].lastUpdate = new Date();
      }
    },

    setSexualActivityPartners(state, value) {
      state.isSaved =
        state.isSaved && state[KEY_SEXUAL_ACTIVITY].partners == value;
      state[KEY_SEXUAL_ACTIVITY].partners = value;
    },

    setSexualActivityTimesAWeek(state, value) {
      state.isSaved =
        state.isSaved && state[KEY_SEXUAL_ACTIVITY].timesAWeek == value;
      state[KEY_SEXUAL_ACTIVITY].timesAWeek = value;
    },

    setSmokingDetails(state, value) {
      state.isSaved = state.isSaved && state[KEY_SMOKING].details == value;
      state[KEY_SMOKING].details = value;
    },

    setValue(state, data) {
      state.isSaved = state.isSaved && state[data.key].value == data.value;
      state[data.key].value = data.value;
    }
  },

  actions: {
    ...personalSocialHistoryBase.actions,

    resetContent({ commit, dispatch }) {
      dispatch("resetDocument");
      commit("RESET_STATE");
    }
  }
};
