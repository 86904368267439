<template>
  <v-card>
    <v-form
      ref="formDetails"
      v-model="validDetails"
      :lazy-validation="false"
      @submit.prevent="proceedRegistrant"
    >
      <v-card flat aspect-ratio="2.75" height="44" color="primary">
        <h3 class="white--text headline mb-0 text-center pa-2">
          Pre-Booking Information
        </h3>
      </v-card>

      <div v-for="(registrant, index) in registrants" :key="index">
        <div class="mb-0 text-center pa-2">
          <strong>Individual {{ index + 1 }}</strong>
          <v-icon
            v-if="index >= 1"
            class="pa-3 floaterr"
            color="red"
            @click="removeRegistrant(index)"
          >
            mdi mdi-close
          </v-icon>
        </div>

        <v-row class="mx-2">
          <v-text-field
            class="px-1"
            label="First Name"
            v-model="registrant.firstName"
            :rules="fieldRules"
          />

          <v-text-field
            class="px-1"
            label="Middle Name"
            v-model="registrant.middleName"
            :rules="fieldRules"
          />

          <v-text-field
            class="px-1"
            label="Last Name"
            v-model="registrant.lastName"
            :rules="fieldRules"
          />
        </v-row>

        <v-row class="mx-2">
          <v-text-field
            class="px-1"
            v-model="registrant.email"
            label="Email Address"
            :rules="fieldRules"
          />
          <v-text-field
            class="px-1"
            v-model="registrant.contact"
            label="Contact"
            :rules="fieldRules"
          />
          <v-menu
            ref="menu"
            v-model="registrant.menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="registrant.birthDate"
                label="Birthday date"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="registrant.birthDate"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="save(index)"
            />
          </v-menu>
        </v-row>

        <v-row class="mx-2">
          <v-col cols="1" v-if="index >= 1">
            <v-checkbox v-model="registrant.place" />
          </v-col>

          <v-text-field
            class="px-1"
            :readonly="registrant.place && index >= 1"
            v-model="registrant.houseNumber"
            label="House Number No./Building No., Subdivision"
            :rules="fieldRules"
          />

          <v-text-field
            class="px-1"
            :readonly="registrant.place && index >= 1"
            v-model="registrant.address"
            label="Additional Address Information (Optional)"
          />
        </v-row>

        <v-row class="mx-5">
          <SelectLocation
            :isDisabled="registrant.place && index >= 1"
            @selectedLocation="
              selectedLocation((location = $event), index, registrant)
            "
          />
        </v-row>

        <v-divider class="py-4" />
      </div>

      <v-card-text class="mt-4 px-6">
        <v-row>
          <v-col cols="4">
            <v-btn class="success" @click="$emit('back')">
              <v-icon>mdi-step-backward</v-icon> Bumalik
            </v-btn>
          </v-col>

          <v-col cols="4" class="text-center">
            <v-btn
              class="success"
              @click="
                incrementRegistrant(
                  registrants[index].houseNumber,
                  registrants[index].address,
                  registrants[index].email,
                  registrants[index].contact
                )
              "
            >
              Magdagdag
            </v-btn>
          </v-col>

          <v-col cols="4" class="text-right">
            <v-btn
              class="success"
              @click="validate"
              :disabled="!validDetails"
              type="sumbit"
            >
              Itala
              <v-icon>mdi-step-forward</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- footer spacer -->
        <div class="mb-4" />
      </v-card-text>
    </v-form>

    <ConfirmDialog ref="confirm" @onClose="onConfirmationClose" />
  </v-card>
</template>

<script>
import { loadUserValidationData } from "@/store/user_validation";
import { setRegistrant } from "@/store/prebooking";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import SelectLocation from "@/components/SelectLocation.vue";

export default {
  name: "CovidVaccinePreBookingInformation",

  components: {
    ConfirmDialog,
    SelectLocation
  },

  data: () => ({
    target: "",
    index: 0,
    validDetails: false,
    fieldRules: [v => !!v || "field required"],
    registrants: [
      {
        menu: false,
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        contact: "",
        birthDate: null,
        houseNumber: "",
        address: "",
        place: true,
        location: ""
      }
    ]
  }),

  mounted() {
    this.loadUserData();
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },

  methods: {
    loadUserData() {
      this.registrants = [];
      loadUserValidationData(this.target).then(data => {
          this.loadUserData1(data);
      });
    },

    loadUserData1(data) {
      this.registrants.push({
        firstName: data.firstname,
        middleName: data.middlename,
        lastName: data.lastname,
        birthDate: data.birthdate,
        email: this.$store.state.auth.email,
        contact: data.contact,
        houseNumber: data.housenumber,
        address: data.additionaladdress
      });
    },

    save(index) {
      this.registrants[index].menu = false;
    },

    incrementRegistrant(houseNumber, address, email, contact) {
      this.registrants.push({
        firstName: "",
        middleName: "",
        lastName: "",
        email: email,
        contact: contact,
        houseNumber: houseNumber,
        address: address,
        place: true,
        location: this.location
      });
    },

    proceedRegistrant() {
      for (let registrant in this.registrants) {
        setRegistrant({ registrants: this.registrants[registrant] });
      }
      this.showConfirmationMessage();
    },

    removeRegistrant(index) {
      if (index > -1) {
        this.registrants.splice(index, 1);
      }
    },

    showConfirmationMessage() {
      this.$refs.confirm.open(
        this.$t("dialog.confirmation"),
        `Naitala na po ang inyong appointment. Maraming salamat po!`,
        { noconfirm: true }
      );
    },

    onConfirmationClose() {
      this.$emit("on-confirm");
      this.registrants = [
        {
          firstName: "",
          middleName: "",
          lastName: "",
          houseNumber: "",
          address: ""
        }
      ];
    },

    selectedLocation(location, index, registrant) {
      // propagate change in location
      if (index == 0) {
        let loc = { location };
        this.registrants[0] = Object.assign(registrant, loc);
      } else {
        let loc = { location };
        this.registrants[index] = Object.assign(registrant, loc);
      }
      this.$emit("update:selectedLocation", location);
    },

    validate() {
      this.$refs.formDetails.validate();
    }
  }
};
</script>

<style scoped>
.floaterr {
  float: right;
}
</style>
