<template>
  <v-list-item link :to="link" :disabled="isDisabled">
    <v-list-item-action>
      <v-icon color="primary">{{ icon }}</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "NavigationMenuItem",

  props: {
    icon: String,
    isDisabled: {
      default: false,
      type: Boolean
    },
    link: String,
    title: String
  }
};
</script>
