let moment = require("moment");

export const PAYMENT_BANTAY_KARD = "Bantay Kard";
export const PAYMENT_CASH_ON_DELIVERY = "Cash on Delivery";
export const PAYMENT_FOR_PICKUP = "For Pickup";
export const PAYMENT_GCASH = "GCash";
export const PAYMENT_LYKA_GEMS = "Lyka Gems";
export const PAYMENT_ON_SITE = "Payment on Site";
export const PAYMENT_SWIFTPAY = "Swiftpay";
export const PAYMENT_UNIONBANK = "UnionBank";
export const PAYMENT_VERIFICATION = "payment verification";
export const PAYMENT_REFUND = "Refund";

export const PAYMENT_STATUS_FOR_VERIFICATION = "For Verification";
export const PAYMENT_STATUS_REJECTED = "Rejected";
export const PAYMENT_STATUS_VERIFIED = "Verified";

// reasons
export const PAYMENT_SUCCESS = "Payment Success";
export const INVALID_REFERENCE_NO = "Invalid Reference Number";
export const PAYMENT_INSUFFICIENT_AMOUNT = "Insufficient Amount";
export const PAYMENT_EXCESS_AMOUNT = "Excess Amount";

// refund option
export const OPTION_BANTAY_KARD = "Bantay Kard";
export const OPTION_BANK_ACCOUNT = "Bank Account";

// bantaykard types
export const TYPE_CASH_IN = "Cash in";
export const TYPE_REFUND = "Refund";
export const TYPE_COUPON = "Coupon";
export const TYPE_PAYMENT = "Payment";

// bantaykard Status
export const STATUS_SUCCESS = "Success";
export const STATUS_FAILED = "Failed";

// receiving option
export const RECEIVE_FOR_PICK_UP = "For Pickup";

// refund requests
export const STATUS_REFUND_REQUESTED = "requestedRefund";
export const STATUS_RETURN_REQUESTED = "requestedReturn";
export const STATUS_REFUND_VERIFY = "refundVerified";
export const STATUS_REFUND_DECLINED = "refundDeclined";
export const STATUS_REFUND_FORWARDED = "refundForwarded";
export const STATUS_REFUND_RECEIVED = "refundReceived";
export const STATUS_DONE = "done";

// payment checkout target step
export const TARGET_PAYMENT = "payment";
export const TARGET_NEW_PAYMENT = "new payment";
export const TARGET_REFUND_PAYMENT = "refund payment";
export const TARGET_RETURN_REFUND_PAYMENT = "return refund payment";

export function healthServiceMOP() {
  return [
    PAYMENT_BANTAY_KARD,
    PAYMENT_ON_SITE,
    PAYMENT_UNIONBANK,
    PAYMENT_GCASH,
    PAYMENT_LYKA_GEMS
  ];
}

export function getModeOfPayments(checkout) {
  if (checkout) {
    let modeOfPayments = [];
    Object.keys(checkout.payments).forEach(key => {
      modeOfPayments.push(checkout.payments[key].modeOfPayment);
    });
    return modeOfPayments;
  }
}

export function getLatestRefund(refDetails) {
  if (refDetails) {
    let latestDate = "";

    Object.keys(refDetails)
      .sort((a, b) => {
        return (
          moment(a, "DD/MM/YYYY").toDate() - moment(b, "DD/MM/YYYY").toDate()
        );
      })
      .forEach(key => {
        latestDate = refDetails[key];
      });

    return latestDate;
  }
}

export function isBantayKardPayment(payment) {
  return PAYMENT_BANTAY_KARD === payment;
}

export function IsCashOnDelivery(payment) {
  return PAYMENT_CASH_ON_DELIVERY === payment;
}

export function IsForPickupPayment(payment) {
  return PAYMENT_FOR_PICKUP === payment;
}

export function isGCashPayment(payment) {
  return PAYMENT_GCASH === payment;
}

export function IsNotCashOnDelivery(payment) {
  return PAYMENT_CASH_ON_DELIVERY !== payment;
}

export function isPaymentForValidation(payment) {
  switch (payment) {
    case PAYMENT_GCASH:
    case PAYMENT_LYKA_GEMS:
    case PAYMENT_UNIONBANK:
      return true;
  }

  return false;
}

export function isPaymentOnSite(payment) {
  return PAYMENT_ON_SITE === payment;
}

export function isSwiftpay(payment) {
  return PAYMENT_SWIFTPAY === payment;
}

export function isUnionBankPayment(payment) {
  return PAYMENT_UNIONBANK === payment;
}
