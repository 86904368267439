import "firebase/firestore";

import MediaUtil from "./MediaUtil";
import { db } from "@/main";

const configuration = { iceServers: [{ url: "stun:stun.l.google.com:19302" }] };

export class CallError extends Error {
  constructor(message) {
    // 'Error' breaks prototype chain here
    super(message);

    // restore prototype chain
    const actualProto = new.target.prototype;

    Object.setPrototypeOf(this, actualProto);
  }
}

export default class CallUtil {
  constructor(callsCollection, onLocalStream, onRemoteStream, onCallEnded) {
    this.callsCollection = callsCollection;
    this.onLocalStream = onLocalStream;
    this.onRemoteStream = onRemoteStream;
    this.onCallEnded = onCallEnded;

    this.unsubscribeCallDoc = null;
    this.unsubscribeCallee = null;
    this.unsubscribeCaller = null;
    this.unsubscribeRemoteName = null;
  }

  async create(callInfo) {
    console.debug("create");
    await this.setupWebrtc();
    if (!this.pc) {
      throw new CallError("Failed to make a call. RTCPeerConnection not set");
    }

    const callRef = await db.collection(this.callsCollection).add(callInfo);

    await this.collectIceCandidates(callRef, "caller", "callee");

    const offer = await this.pc.createOffer();
    this.pc.setLocalDescription(offer);

    callRef.update({
      offer: {
        type: offer.type,
        sdp: offer.sdp
      }
    });

    return callRef.id;
  }

  async join(callId) {
    console.debug(`Joining ${callId}`);

    const callDocRef = db.collection(this.callsCollection).doc(callId);
    const callDoc = await callDocRef.get({
      source: "server"
    });

    if (!callDoc.exists) {
      throw new CallError(`Call Document not available for ${callId}`);
    }

    const data = callDoc.data();
    if (data && !data.offer) {
      throw new CallError(`No call offer available for ${callId}`);
    }

    await this.setupWebrtc();
    if (!this.pc) {
      throw new CallError("Failed to join call. RTCPeerConnection not set");
    }

    await this.collectIceCandidates(callDocRef, "callee", "caller");

    this.pc.setRemoteDescription(new RTCSessionDescription(data.offer));

    const answer = await this.pc.createAnswer();
    this.pc.setLocalDescription(answer);
    await callDocRef.update({
      answer: {
        type: answer.type,
        sdp: answer.sdp
      }
    });
  }

  end(callId) {
    console.debug(`End call ${callId}`);

    if (this.hasEnded) {
      return;
    }
    this.hasEnded = true;

    this.streamCleanUp();

    this.firestoreCleanUp(callId);

    if (this.pc) {
      this.pc.close();
      this.pc = null;
    }

    if (this.unsubscribeCallDoc) {
      this.unsubscribeCallDoc();
      this.unsubscribeCallDoc = null;
    }
    if (this.unsubscribeCallee) {
      this.unsubscribeCallee();
      this.unsubscribeCallee = null;
    }
    if (this.unsubscribeCaller) {
      this.unsubscribeCaller();
      this.unsubscribeCaller = null;
    }
    if (this.unsubscribeRemoteName) {
      this.unsubscribeRemoteName();
      this.unsubscribeRemoteName = null;
    }

    this.onCallEnded();
  }

  setupSnapshotCallbacks(callId) {
    const callRef = db.collection(this.callsCollection).doc(callId);

    this.unsubscribeCallDoc = callRef.onSnapshot(snapshot => {
      if (snapshot) {
        const data = snapshot.data();
        if (
          data &&
          data.answer &&
          this.pc &&
          this.pc &&
          !this.pc.remoteDescription
        ) {
          this.pc.setRemoteDescription(new RTCSessionDescription(data.answer));
        }
      }
    });

    const checkEndCall = snapshot => {
      if (snapshot) {
        snapshot.docChanges().forEach(change => {
          if (change.type === "removed") {
            this.end(callRef.id);
          }
        });
      }
    };

    this.unsubscribeCallee = callRef
      .collection("callee")
      .onSnapshot(checkEndCall);

    this.unsubscribeCaller = callRef
      .collection("caller")
      .onSnapshot(checkEndCall);
  }

  async setupWebrtc() {
    this.pc = new RTCPeerConnection(configuration);

    this.localStream = await MediaUtil.getStream();
    if (this.localStream) {
      this.pc.addStream(this.localStream);
      this.onLocalStream(this.localStream);
    }

    this.pc.onaddstream = event => {
      this.remoteStream = event.stream;
      this.onRemoteStream(this.remoteStream);
    };
  }

  async collectIceCandidates(callRef, localName, remoteName) {
    if (!this.pc) {
      return;
    }

    this.pc.onicecandidate = event => {
      if (event.candidate) {
        callRef.collection(localName).add({
          candidate: event.candidate.candidate,
          sdpMLineIndex: event.candidate.sdpMLineIndex,
          sdpMid: event.candidate.sdpMid
        });
      }
    };

    this.unsubscribeRemoteName = callRef
      .collection(remoteName)
      .onSnapshot(snapshot => {
        if (snapshot) {
          snapshot.docChanges().forEach(change => {
            if (change.type === "added" && this.pc) {
              const init = change.doc.data();
              console.debug(init);
              const candidate = new RTCIceCandidate(init);
              console.debug(candidate);
              this.pc.addIceCandidate(candidate);
            }
          });
        }
      });
  }

  async streamCleanUp() {
    if (this.localStream) {
      this.localStream.getTracks().forEach(t => t.stop());
      this.localStream = null;
      this.remoteStream = null;
    }
  }

  async firestoreCleanUp(callId) {
    console.debug(`Clearing call id: ${callId}`);

    const callRef = db.collection(this.callsCollection).doc(callId);
    const callDoc = await callRef.get({
      source: "server"
    });

    if (callDoc.exists) {
      const calleeCandidateRef = callRef.collection("callee");
      const calleeCandidate = await calleeCandidateRef.get();
      calleeCandidate.forEach(async candidate => {
        await candidate.ref.delete();
      });
      const callerCandidateRef = callRef.collection("caller");
      const callerCandidate = await callerCandidateRef.get();
      callerCandidate.forEach(async candidate => {
        await candidate.ref.delete();
      });

      await callRef.delete();
    }
  }
}
