<template>
  <div>
    <BackButton @click="$emit('back')" class="mt-3" />

    <h3
      v-show="$vuetify.breakpoint.mobile"
      class="font-weight-bold mt-3 primary--text pt-2 text-center text-h6"
    >
      {{ $t("dialog.confirmation") }}
    </h3>

    <v-row class="mb-4 mt-5">
      <v-col>
        {{ $t("laboratory.appointmentConfirmationLeadingText") }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1" />
      <v-col cols="3">Type</v-col>
      <v-col cols="8">{{ appointmentClinicType }}</v-col>
    </v-row>

    <div v-if="isHomeService(appointmentClinicType)">
      <v-row class="mt-n4">
        <v-col cols="1" />
        <v-col cols="3">Address</v-col>
        <v-col cols="8">{{ address }}</v-col>
      </v-row>

      <v-row class="mt-n4">
        <v-col cols="1" />
        <v-col cols="3">Preferred</v-col>
        <v-col cols="8">
          {{ appointment.schedule.type }}
          <span v-if="!isSpecificTime(appointment.schedule.type)">
            ({{ appointment.schedule.date }})
          </span>
        </v-col>
      </v-row>

      <v-row class="mt-n4" v-if="isSpecificTime(appointment.schedule.type)">
        <v-col cols="4" />
        <v-col cols="8">
          {{ appointment.schedule.start }} - {{ appointment.schedule.end }}
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-row class="mt-n4">
        <v-col cols="1" />
        <v-col cols="3">Serbisyo</v-col>
        <v-col cols="8">{{ name }} sa {{ location }}</v-col>
      </v-row>

      <v-row class="mt-n4">
        <v-col cols="1" />
        <v-col cols="3">Petsa at Oras</v-col>
        <v-col cols="8">{{ start }}</v-col>
      </v-row>
    </div>

    <v-divider class="mx-5 mt-10" />
    <TestSummary :patients="appointmentPatients" />
    <ConsolidatedTotals :patients="patients" class="mt-5" />

    <v-row class="mt-4">
      <v-col>
        {{ $t("laboratory.appointmentConfirmationText") }}
        <strong>
          {{ $t("laboratory.appointmentConfirmationButtonText") }}
        </strong>
        .
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="6" />
      <v-col cols="6" style="text-align: right;">
        <ConfirmScheduleButton @click="confirmFind" />
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="990" persistent>
      <Checkout
        :payref="SERVICE_LABORATORY"
        :bhcdetails="bhcdetails"
        :sum="total"
        :total="total"
        :unfilteredPurchase="unfilteredPurchase"
        @check-out="checkOut"
      />
    </v-dialog>

    <ConfirmDialog ref="confirm" @onClose="onConfirm" />
  </div>
</template>

<script>
import { REBOOKED, isHomeService } from "@/common/utilities/appointments";
import { SERVICE_LABORATORY } from "@/common/utilities/services";

import { ConstructDataEntry } from "@/utilities/home_service";
import {
  SetHomeServiceStatusRebooked,
  setHomeServiceMessageboxStatus,
  GetRebookHistory,
  updateCardBalance
} from "@/store";
import { getDateTime, timeStampToDate } from "@/common/utilities/date";
import { isSpecificTime } from "@/common/utilities/appointments/schedule_type";
import {
  SetAppointmentUnpaid,
  setAppointment,
  setAppointmentHomeService,
  SetHomeServiceAppointment
} from "@/store/diagnostic_attendee";

import BackButton from "@/components/Controls/BackButton.vue";
import Checkout from "@/eTindahan/components/Checkout.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import ConfirmScheduleButton from "@/components/Controls/ConfirmScheduleButton.vue";
import ConsolidatedTotals from "./ConsolidatedTotals.vue";
import TestSummary from "@/common/components/Laboratory/TestSummary.vue";

export default {
  name: "ConfirmStationarySchedule",

  components: {
    BackButton,
    Checkout,
    ConfirmDialog,
    ConfirmScheduleButton,
    ConsolidatedTotals,
    TestSummary
  },

  props: {
    appointment: Object,

    total: Number,
    clinicType: String,
    selectedPreferredDateTime: Array,
    contact: String,
    details: Object,
    registrants: Array,
    serviceid: String,
    msgid: String,
    nowHistory: Object,

    patients: Array
  },

  data: () => ({
    dialog: false,
    unfilteredPurchase: 0,
    bhcdetails: [],
    rebookhistory: [],
    loadedRebookHistory: [],

    SERVICE_LABORATORY: SERVICE_LABORATORY,

    isHomeService: isHomeService,
    isSpecificTime: isSpecificTime
  }),

  watch: {
    serviceid() {
      GetRebookHistory(this.serviceid).then(docs => {
        this.loadedRebookHistory = docs;
      });
    }
  },

  computed: {
    address() {
      let location = this.appointment.service.location;
      return [
        location.addressLine,
        [location.barangay, location.municipality, location.province].join(", ")
      ].join(" ");
    },

    appointmentClinicType() {
      return this.appointment && this.appointment.clinicType
        ? this.appointment.clinicType
        : "";
    },

    appointmentPatients() {
      return this.appointment && this.appointment.patients
        ? this.appointment.patients
        : [];
    },

    hasHistory() {
      return (
        this.loadedRebookHistory.rebookhistory !== "undefined" &&
        this.loadedRebookHistory.rebookhistory !== null
      );
    },

    isRebooked() {
      return this.msgid !== "" && this.serviceid !== "";
    },

    location() {
      return this.details && this.details.service.location
        ? this.details.service.location
        : "";
    },

    name() {
      return this.details && this.details.service.name
        ? this.details.service.name
        : "";
    },

    start() {
      return this.details && this.details.slot.start
        ? getDateTime(this.details.slot.start)
        : "";
    }
  },

  methods: {
    confirmFind() {
      if (this.isRebooked) {
        this.rebook();
      } else if (isHomeService(this.clinicType)) {
        this.confirmScheduleHomeService();
      } else {
        this.confirmScheduleStationaryMobile();
      }
    },

    confirmScheduleStationaryMobile() {
      SetAppointmentUnpaid({
        // schedule
        clinicType: this.clinicType,

        scheduleId: this.details.id,
        scheduleStart: this.details.schedule.start,
        scheduleEnd: this.details.schedule.end,

        // slot schedule
        start: timeStampToDate(this.details.slot.start),
        end: timeStampToDate(this.details.slot.end),

        // service details
        serviceId: this.details.service.id,
        serviceLocation: this.location,
        serviceName: this.name,

        //package details
        registrants: this.registrants,
        isPaid: false
      }).then(id => {
        // will reserve data object for payment
        this.bhcdetails = {
          id: id,

          // schedule
          clinicType: this.clinicType,

          scheduleId: this.details.id,
          scheduleStart: this.details.schedule.start,
          scheduleEnd: this.details.schedule.end,

          // slot schedule
          start: this.details.slot.start,
          end: this.details.slot.end,

          // service details
          service: {
            serviceId: this.details.service.id,
            serviceLocation: this.location,
            serviceName: this.name
          },

          registrants: this.registrants,
          isPaid: false
        };

        this.dialog = true;
      });
    },

    checkOut(val) {
      if (isHomeService(this.clinicType)) {
        setAppointmentHomeService({
          id: this.bhcdetails.id,

          clinicType: this.bhcdetails.clinicType,
          selectedPreferredDateTime: this.bhcdetails.selectedPreferredDateTime,
          homeServiceLocation: this.bhcdetails.homeServiceLocation,

          total: Math.round(val.total),
          paidThru: val.paidThru,
          name: val.name,
          address: val.address,
          contact: val.contact,
          courier: val.courier,

          remainingbalance: val.remainingbalance,
          isBantayKardPayment: val.isBantayKardPayment,
          registrants: this.bhcdetails.registrants,
          isPaid: false
        }).then(() => {
          this.showConfirmationMessageHomeService();
        });
      } else {
        setAppointment({
          id: this.bhcdetails.id,

          // schedule
          scheduleId: this.bhcdetails.scheduleId,
          scheduleStart: this.bhcdetails.scheduleStart,
          scheduleEnd: this.bhcdetails.scheduleEnd,

          // slot schedule
          start: this.bhcdetails.start,
          end: this.bhcdetails.end,

          // service details
          serviceId: this.bhcdetails.service.serviceId,
          serviceLocation: this.bhcdetails.service.serviceLocation,
          serviceName: this.bhcdetails.service.serviceName,

          total: Math.round(val.total),
          paidThru: val.paidThru,
          name: val.name,
          address: val.address,
          contact: val.contact,
          courier: val.courier,

          remainingbalance: val.remainingbalance,
          isBantayKardPayment: val.isBantayKardPayment,
          registrants: this.bhcdetails.registrants,
          isPaid: true
        }).then(() => {
          this.showConfirmationMessage();
        });
      }

      if (val.isBantayKardPayment) {
        updateCardBalance(val.remainingbalance);
      }
      this.dialog = false;
    },

    confirmScheduleHomeService() {
      SetHomeServiceAppointment(ConstructDataEntry(this.appointment)).then(
        () => {
          this.showConfirmationMessageHomeService();
        }
      );
    },

    rebook() {
      GetRebookHistory(this.serviceid).then(docs => {
        this.loadedRebookHistory = docs;

        let mergedHistory = [];
        if (this.hasHistory) {
          mergedHistory.push(this.nowHistory);
          for (let i in this.loadedRebookHistory.rebookhistory) {
            mergedHistory.push(this.loadedRebookHistory.rebookhistory[i]);
          }
        } else {
          mergedHistory.push(this.nowHistory);
        }

        SetHomeServiceStatusRebooked({
          selectedPreferredDateTime: this.selectedPreferredDateTime,
          sID: this.serviceid,
          status: REBOOKED,
          rebookhistory: mergedHistory
        }).then(() => {
          setHomeServiceMessageboxStatus({
            docid: this.msgid,
            status: REBOOKED
          });
          this.showConfirmationMessage();
        });
      });
    },

    onConfirm() {
      this.$emit("next");
    },

    showConfirmationMessage() {
      this.$refs.confirm.open(
        this.$t("dialog.confirmation"),
        `Naitala na po ang inyong appointment. Mangyaring dumating ng 10 minutong mas maaga sa inyong appointment. Maraming salamat po!`,
        { noconfirm: true }
      );
    },

    showConfirmationMessageHomeService() {
      this.$refs.confirm.open(
        this.$t("dialog.confirmation"),
        `${this.$t(
          "laboratory.appointmentConfirmationDialogTextLine1"
        )}<br /><br />${this.$t(
          "laboratory.appointmentConfirmationDialogTextLine2"
        )}<br /><br />${this.$t(
          "laboratory.appointmentConfirmationDialogTextLine3"
        )}`,
        { noconfirm: true }
      );
    }
  }
};
</script>
