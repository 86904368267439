<template>
  <div>
    <Completed v-show="isSubmitted" />
  </div>
</template>

<script>
import Completed from "../components/MedicalHistoryCompleted.vue";

export default {
  name: "MedicalHistory",

  components: {
    Completed
  },

  data: () => ({
    isSubmitted: false
  }),

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");
  }
};
</script>
