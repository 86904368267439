<template>
  <p class="text-center primary--text pt-2">{{ name }}</p>
</template>

<script>
import { getFullName } from "@/common/utilities/profile";

export default {
  name: "PatientDetails",

  props: {
    patient: Object
  },

  computed: {
    name() {
      return getFullName(this.patient);
    }
  }
};
</script>
