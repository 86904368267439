<template>
  <v-btn class="pt-0" color="primary" @click="recordActivityAndEmit">
    <v-icon>mdi-step-backward</v-icon> Back
  </v-btn>
</template>

<script>
export default {
  name: "BackButton",

  methods: {
    recordActivityAndEmit() {
      this.$store.dispatch("resetSecondsIdle").then(() => {
        this.$emit("click");
      });
    }
  }
};
</script>
