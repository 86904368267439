<template>
  <div>
    <div class="pa-0 text-caption op" :class="{ disabled: disabled }">
      Date
    </div>

    <input
      type="date"
      :value="value"
      @input="$emit('input', $event.target.value)"
      min="1900-01-01"
      max="2030-12-31"
      :disabled="disabled"
      :class="{ disabled: disabled }"
    />

    <hr />
  </div>
</template>

<script>
export default {
  name: "SelectDate",
  props: ["value", "disabled"]
};
</script>

<style scoped>
.disabled {
  opacity: 0.7;
}

.op {
  opacity: 0.7;
}
</style>
