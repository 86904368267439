import {
  DB,
  getCurrentUser,
  getCurrentUserUid,
  logPaymentTransaction
} from "@/common/store";
import {
  SCHEDULED,
  STATUS_FOR_PAYMENT_VERIFICATION
} from "@/common/utilities/appointments";

import { db } from "@/main";
import { getDateTime, getDateTimeFromDateAsKey } from "@/common/utilities/date";

import firebase from "firebase/app";
import "firebase/firestore";

let uuid = require("uuid");

function addAttendee(scheduleId, attendeeId, status) {
  db.collection(DB.DOCTOR_SCHEDULES)
    .doc(scheduleId)
    .update({
      attendees: firebase.firestore.FieldValue.arrayUnion({
        attendeeId: attendeeId,
        id: getCurrentUserUid(),
        name: getCurrentUser(),
        status: status
      })
    });
}

function removeAttendeeFromSchedule(scheduleId) {
  return new Promise(resolve => {
    db.collection(DB.DOCTOR_SCHEDULES)
      .doc(scheduleId)
      .get()
      .then(doc => {
        const data = doc.data();
        const currentUser = getCurrentUserUid();

        let attendees = [];
        for (const attendee of data.attendees) {
          if (currentUser !== attendee.id) {
            attendees.push({ ...attendee });
          }
        }

        db.collection(DB.DOCTOR_SCHEDULES)
          .doc(scheduleId)
          .set({ attendees: attendees }, { merge: true })
          .then(() => resolve())
          .catch(() => resolve());
      })
      .catch(() => resolve());
  });
}

function constructAppointmentData(data, timestamp, status) {
  return {
    doctor: data.doctor,
    selectedSpecialization: data.selectedSpecialization,
    selectedVisitType: data.selectedVisitType,
    timestamp: timestamp,

    appointment: {
      id: data.scheduleId,
      qrCode: uuid.v4(),
      start: data.start,
      end: data.end
    },
    schedule: {
      start: data.scheduleStart,
      end: data.scheduleEnd
    },
    service: {
      id: data.serviceId,
      name: data.serviceName,
      location: data.serviceLocation
    },
    user: {
      id: getCurrentUserUid(),
      name: getCurrentUser()
    },
    status: status,
    payment: {
      isPaid: data.isPaid
    }
  };
}

function constructAppointmentDataPayment(data, timestamp) {
  let status;
  if (data.paidThru === "Bantay Kard" || data.paidThru === "Payment on Site") {
    status = SCHEDULED;
  } else {
    status = STATUS_FOR_PAYMENT_VERIFICATION;
  }
  return {
    registrants: data.registrants,
    appointment: {
      id: data.scheduleId,
      qrCode: uuid.v4(),
      start: data.start,
      end: data.end
    },
    service: {
      id: data.serviceId,
      name: data.serviceName,
      location: data.serviceLocation
    },
    payment: {
      timestamp: timestamp,
      isPaid: data.isPaid,
      total: data.total,
      paidThru: data.paidThru,
      remainingbalance: data.remainingbalance,
      name: data.name,
      address: data.address,
      contact: data.contact,
      referenceNo: data.referenceNo
    },
    status: status
  };
}

function constructAppointmentDataPayment2(data, timestamp) {
  if (data.paidThru === "Bantay Kard" || data.paidThru === "Payment on Site") {
    return {
      id: data.id,

      clinicType: data.clinicType,
      selectedPrefferedDateTime: data.selectedPrefferedDateTime,
      homeServiceLocation: data.homeServiceLocation,

      registrants: data.registrants,
      payment: {
        timestamp: timestamp,
        isPaid: data.isPaid,
        total: data.total,
        paidThru: data.paidThru,

        remainingbalance: data.remainingbalance,
        name: data.name,
        address: data.address,
        contact: data.contact
      },
      timestamp: timestamp
    };
  } else {
    return {
      id: data.id,

      clinicType: data.clinicType,
      selectedPrefferedDateTime: data.selectedPrefferedDateTime,
      homeServiceLocation: data.homeServiceLocation,

      registrants: data.registrants,
      payment: {
        timestamp: timestamp,
        isPaid: data.isPaid,
        total: data.total,
        paidThru: data.paidThru,
        name: data.name,
        address: data.address,
        contact: data.contact,

        amount: data.amount,
        referenceNo: data.referenceNo,
        dateToPay: data.dateToPay
      },
      timestamp: timestamp
    };
  }
}

function sendBHCAppointmentMessage(appointmentId, data, timestamp) {
  let body = `Magandang araw!

Ito po ang detalye ng inyong appointment:

Serbisyo: ${data.service.name}
Lugar: ${data.service.location}
Oras: ${getDateTime(data.appointment.start)}

Mangyaring dumating ng 10 minuto bago ang inyong appointment at ipakita ang QR Code na nasa ibaba.`;

  db.collection(DB.MESSAGE_BOX).add({
    subject: "Laboratory Appointment ",
    body: body,
    receiver: getCurrentUserUid(),
    status: "unread",
    recieveqr: `${appointmentId}-${data.appointment.qrCode}`,
    timestamp: timestamp,
    schedule: data.appointment.start,
    mobileStationary: data
  });
}

export function DeleteAppointment(data) {
  return new Promise(resolve => {
    db.collection(DB.DOCTOR_ATTENDEE)
      .doc(data.id)
      .delete()
      .then(() => {
        removeAttendeeFromSchedule(data.appointment.id).then(() => resolve());
      });
  });
}

export function CancelAppointment(data) {
  return new Promise(resolve => {
    db.collection(DB.DOCTOR_ATTENDEE)
      .doc(data.id)
      .delete()
      .then(() => resolve());
  });
}

export function IsAppointmentSet(payload) {
  return new Promise(resolve => {
    let start = new Date(payload.scheduleStart.seconds * 1000);

    db.collection(DB.DOCTOR_ATTENDEE)
      .where("service.id", "==", payload.serviceID)
      .where("schedule.start", "==", start)
      .where("user.id", "==", getCurrentUserUid())
      .get()
      .then(snapshot => {
        let data = null;

        snapshot.forEach(doc => {
          if (!data) {
            data = { id: doc.id, ...doc.data() };
          }
        });

        resolve(data);
      })
      .catch(() => resolve(null));
  });
}

export function loadDiagnosticAttendeeUnpaid() {
  return new Promise((resolve, reject) => {
    db.collection(DB.DOCTOR_ATTENDEE)
      .where("user.id", "==", getCurrentUserUid())
      .where("payment.isPaid", "==", false)
      .get()
      .then(snapshot => {
        let attendee = [];
        snapshot.forEach(doc => {
          attendee.push({
            id: doc.id,
            data: doc.data()
          });
        });
        resolve(attendee);
      })
      .catch(err => reject(err));
  });
}

export function SetAppointment(data) {
  return new Promise((resolve, reject) => {
    let timestamp = new Date();
    let operation = "Diagnostic Payment";
    let appointmentData = constructAppointmentDataPayment(data, timestamp);

    db.collection(DB.DOCTOR_ATTENDEE)
      .doc(data.id)
      .set(appointmentData, { merge: true })
      .then(() => {
        if (["Bantay Kard", "Payment on Site"].includes(data.paidThru)) {
          logPaymentTransaction(timestamp, operation, appointmentData)
            .then(() => {
              addAttendee(data.scheduleId, data.id, data);
              sendBHCAppointmentMessage(data.id, appointmentData, timestamp);
              resolve();
            })
            .catch(err => reject(err));
        } else {
          addAttendee(
            data.scheduleId,
            data.id,
            STATUS_FOR_PAYMENT_VERIFICATION
          );
          resolve();
        }
      });
  });
}

export function SetAppointmentConfirm(data) {
  return new Promise((resolve, reject) => {
    db.collection(DB.DOCTOR_ATTENDEE)
      .doc(data.docid)
      .set({ payment: data.paymentDetails }, { merge: true })
      .then(() => {
        return logPaymentTransaction(new Date(), "Diagnostic Payment", data);
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function SetAppointmentHomeService(data) {
  let timestamp = new Date();
  let appointmentData = constructAppointmentDataPayment2(data, timestamp);

  return new Promise((resolve, reject) => {
    logPaymentTransaction(timestamp, "Diagnostic Payment", appointmentData)
      .then(() => {
        return db
          .collection(DB.DOCTOR_HOMESERVICE)
          .doc(data.id)
          .set(appointmentData, { merge: true });
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function SetAppointmentUnpaid(data) {
  return new Promise(resolve => {
    let timestamp = new Date();
    let appointmentData = constructAppointmentData(data, timestamp, "proccess");

    db.collection(DB.DOCTOR_ATTENDEE)
      .add(appointmentData)
      .then(doc => resolve(doc.id))
      .catch(() => resolve());
  });
}
//new
function constructUpdatedEntry(data) {
  return {
    ...data,
    history: {
      [getDateTimeFromDateAsKey()]: {
        schedule: { ...data.schedule },
        transaction: "admin_set"
      }
    }
  };
}
export function SetDoctorAppointment(data) {
  return new Promise((resolve, reject) => {
    if (data.id) {
      db.collection(DB.DOCTOR_APPOINTMENTS)
        .doc(data.id)
        .set({ ...constructUpdatedEntry(data) }, { merge: true })
        .then(() => resolve(data.id))
        .catch(err => reject(err));
    } else {
      db.collection(DB.DOCTOR_APPOINTMENTS)
        .add(data)
        .then(doc => resolve(doc.id))
        .catch(err => reject(err));
    }
  });
}
