<template>
  <div class="pa-5">
    <v-stepper v-model="step" flat>
      <v-stepper-header style="overflow: hidden;">
        <v-stepper-step :complete="step > 1" step="1">
          Personal Information
        </v-stepper-step>

        <v-stepper-step :complete="step > 2" step="2">
          Past Medical History
        </v-stepper-step>

        <v-stepper-step :complete="step > 3" step="3">
          Family History
        </v-stepper-step>

        <v-stepper-step :complete="step > 4" step="4">
          Personal and Social History
        </v-stepper-step>

        <v-stepper-step step="5">
          Confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" class="pt-0">
          <MemberInfo
            :collection="COLLECTION"
            @next="step = 2"
            class="mt-10 mx-5"
          />
        </v-stepper-content>

        <v-stepper-content step="2" class="pt-0">
          <PastMedicalHistory
            :collection="COLLECTION"
            @back="step = 1"
            @next="step = 3"
            class="mt-10 mx-5"
          />
        </v-stepper-content>

        <v-stepper-content step="3" class="pt-0">
          <FamilyHistory
            :collection="COLLECTION"
            @back="step = 2"
            @next="step = 4"
            class="mt-10 mx-5"
          />
        </v-stepper-content>

        <v-stepper-content step="4" class="pt-0">
          <PersonalSocialHistory
            :collection="COLLECTION"
            @back="step = 3"
            @next="step = 5"
            class="mt-10 mx-5"
          />
        </v-stepper-content>

        <v-stepper-content step="5" class="pt-0">
          <Confirmation
            @back="step = 4"
            @next="$emit('onSubmit')"
            class="mt-10 mx-5"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { DB } from "@/common/store";

import Confirmation from "./Confirmation.vue";
import FamilyHistory from "@/HealthRecords/components/FamilyHistory.vue";
import MemberInfo from "./MemberInfo.vue";
import PastMedicalHistory from "@/HealthRecords/components/PastMedicalHistory.vue";
import PersonalSocialHistory from "@/HealthRecords/components/PersonalSocialHistory.vue";

export default {
  name: "Registration",

  components: {
    Confirmation,
    FamilyHistory,
    MemberInfo,
    PastMedicalHistory,
    PersonalSocialHistory
  },

  data: () => ({
    COLLECTION: DB.PHILHEALTH_INDEX,
    step: 1
  })
};
</script>
