<template>
  <div>
    <div v-if="selectedAppointment">
      <p>Confirm Schedule</p>
      <p>{{ selectedAppointment.title }}</p>
      <p>
        Location: {{ selectedAppointment.data.location.municipality }},
        {{ selectedAppointment.data.location.barangay }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmDoctorAppointment",

  props: {
    selectedAppointment: Object
  }
};
</script>
