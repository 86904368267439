<template>
  <v-container fluid class="main-container">
    <div v-if="waitSpinner" class="mt-15 text-center">
      <CircularProgress />
    </div>

    <div v-else>
      <v-row>
        <v-col cols="12" outlined>
          <v-card class="mx-auto">
            <v-card-title class="headline">Member Information</v-card-title>
            <v-form ref="formDetails" v-model="validDetails">
              <v-card-text>
                <v-text-field
                  v-model="firstName"
                  :rules="[f => !!f || 'First name is required']"
                  label="First Name"
                  @change="clearErrors"
                  required
                />

                <v-text-field
                  v-model="lastName"
                  :rules="[l => !!l || 'Last name is required']"
                  label="Last Name"
                  @change="clearErrors"
                  required
                />

                <v-text-field
                  v-model="contactNumber"
                  label="Contact Number (Optional)"
                  @change="clearErrors"
                />
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" outlined>
          <v-card class="mx-auto">
            <v-card-title class="headline">Register</v-card-title>
            <v-alert type="error" v-if="errorText">{{ errorText }}</v-alert>
            <v-form ref="form" v-model="valid">
              <v-card-text>
                <v-text-field
                  v-model="email"
                  :rules="[
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                  ]"
                  label="Email"
                  class="mt-4"
                  required
                />

                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[passwordRules.required, passwordRules.min]"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="showPassword = !showPassword"
                />

                <v-text-field
                  v-model="rePassword"
                  :append-icon="showRepassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[passwordRules.required, passwordConfirmationRule]"
                  :type="showRepassword ? 'text' : 'password'"
                  name="rePassword"
                  label="Re-enter Password"
                  @click:append="showRepassword = !showRepassword"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mb-3 ml-2 mr-4"
                  @click="setProvider('email')"
                >
                  Register
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" outlined>
          <v-card class="mx-auto">
            <v-card-title class="headline">Alternative</v-card-title>
            <v-alert type="error" v-if="altErrorText">
              {{ altErrorText }}
            </v-alert>
            <v-card-subtitle>
              You may create a new account or sign in using your existing
              accounts.
              <div class="py-6">
                <AuthenticationAlternativeSignIn
                  @facebookLogin="setProvider('FB')"
                  @googleLogin="setProvider('Google')"
                  @phoneLogin="setProvider('Phone')"
                />
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" width="800">
        <RegistrationTerms v-on:continue-registration="continueRegistration" />
      </v-dialog>

      <v-dialog v-model="phoneDialog" width="400">
        <AuthenticationPhoneSignin
          class="ma-0"
          @onClose="phoneDialog = false"
          @onSuccess="onPhoneLogin()"
        />
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { ROUTE_LOADING, routeTo } from "@/router";
import { STATUS_UNREAD } from "@/common/utilities/message";
import { LOCALE_TL } from "@/common/utilities/locale";

import { getCurrentUserUid } from "@/common/store";
import { onAuthStateChanged } from "@/main";
import { sendMessage } from "@/store";
import { setRegistrationDetails } from "@/utilities/session";

import firebase from "firebase/app";
import "firebase/auth";

import AuthenticationAlternativeSignIn from "@/components/Authentication/AlternativeSignIn.vue";
import AuthenticationPhoneSignin from "@/components/Authentication/PhoneSignin.vue";
import CircularProgress from "@/components/CircularProgress.vue";
import RegistrationTerms from "@/components/RegistrationTerms.vue";

export default {
  name: "Register",

  components: {
    AuthenticationAlternativeSignIn,
    AuthenticationPhoneSignin,
    CircularProgress,
    RegistrationTerms
  },

  mounted() {
    this.valid = false;
    this.validDetails = false;
  },

  data: () => ({
    valid: false,
    validDetails: false,

    firstName: "",
    lastName: "",

    contactNumber: "",

    email: "",
    showPassword: false,
    password: "",
    passwordRules: {
      required: value => !!value || "Pasword Required.",
      min: v => v.length >= 8 || "Min 8 characters"
    },
    showRepassword: false,
    rePassword: "",

    altErrorText: "",
    errorText: "",

    phoneDialog: false,
    dialog: false,
    provider: "",

    waitSpinner: false
  }),

  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.rePassword || "Password must match";
    }
  },

  methods: {
    setRegistrationDetails() {
      setRegistrationDetails(
        this.firstName,
        this.lastName,
        this.contactNumber,
        this.email,
        LOCALE_TL
      );
    },

    setProvider(provider) {
      this.altErrorText = "";
      this.errorText = "";

      if (this.$refs.formDetails.validate()) {
        if ("email" === provider && !this.$refs.form.validate()) {
          return;
        }

        this.provider = provider;
        this.dialog = true;
      } else {
        const message = "Please fill up missing Member Information details.";
        if ("email" === provider) {
          this.errorText = message;
        } else {
          this.altErrorText = message;
        }
      }
    },

    sendWelcomeMessage() {
      let body = `Salamat sa pagsali sa MediSure!!!

Nais po lamang naming kunin ang pagkakataon na ito upang iparating ang aming malugod na pagtanggap sa inyo bilang bagong miyembro.

Mabuhay po kayo!!!

Mula sa MediSure Team`;

      sendMessage({
        body: body,
        header:
          "https://firebasestorage.googleapis.com/v0/b/medisure-275117.appspot.com/o/images%2F53f56840-f3ba-11ea-ba6a-5bc4aecd9fe6.png?alt=media&token=f1229dce-47f4-41eb-831f-db9510805e13",
        name: "Admin",
        receiver: getCurrentUserUid(),
        sender: "Admin", //Sender AdminSide
        status: STATUS_UNREAD,
        subject: "Welcome to Medisure Plus!",
        timestamp: new Date()
      });
    },

    registerNew() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(result => {
          result.user
            .updateProfile({
              displayName: `${this.firstName} ${this.lastName}`
              // TODO: photoURL: "https://example.com/jane-q-user/profile.jpg"
            })
            .catch(error => {
              this.errorText = error;
            })
            .finally(() => {
              this.sendWelcomeMessage();
              onAuthStateChanged(result.user);
              this.waitSpinner = false;
            });
        })
        .catch(error => {
          this.waitSpinner = false;
          this.errorText = error;
        });
    },

    onPhoneLogin() {
      this.setRegistrationDetails();

      firebase
        .auth()
        .currentUser.updateProfile({
          displayName: `${this.firstName} ${this.lastName}`
          // TODO: photoURL: "https://example.com/jane-q-user/profile.jpg"
        })
        .then(() => {
          this.waitSpinner = false;
          this.sendWelcomeMessage();
        })
        .catch(error => {
          this.waitSpinner = false;
          this.errorText = error;
        });

      this.sendWelcomeMessage();
    },

    loginWithProvider(provider) {
      window.sessionStorage.setItem("sendRegistrationMessage", true);

      routeTo(ROUTE_LOADING);
      firebase.auth().signInWithRedirect(provider);
    },

    continueRegistration() {
      this.dialog = false;

      if ("Phone" === this.provider) {
        this.phoneDialog = true;
      } else {
        this.waitSpinner = true;
        this.setRegistrationDetails();

        if ("email" === this.provider) {
          this.registerNew();
        } else {
          const provider =
            "FB" === this.provider
              ? new firebase.auth.FacebookAuthProvider()
              : new firebase.auth.GoogleAuthProvider();
          this.loginWithProvider(provider);
        }
      }
    },

    clearErrors() {
      this.altErrorText = "";
      this.errorText = "";
    }
  }
};
</script>
