<template>
  <v-card flat tile width="100%" class="primary white--text text-center pt-5">
    <div v-if="!isLoggedIn" class="pb-0">
      <v-btn :href="FB" target="_blank" class="mx-4 pb-2 white--text" icon>
        <v-icon size="32px">mdi-facebook</v-icon>
      </v-btn>

      <v-btn :href="TWITTER" target="_blank" class="mx-4 pb-2 white--text" icon>
        <v-icon size="32px">mdi-twitter</v-icon>
      </v-btn>

      <v-btn :href="IG" target="_blank" class="mx-4 pb-2 white--text" icon>
        <v-icon size="32px">mdi-instagram</v-icon>
      </v-btn>

      <br />

      <v-card
        href="mailto:mso.tindahan@medisureonline.com"
        color="primary"
        class="white--text"
        flat
      >
        <v-icon color="white" size="32px" class="py-0">mdi-email</v-icon>
        mso.tindahan@medisureonline.com
      </v-card>

      <v-card
        href="tel:+639668769058"
        color="primary"
        class="white--text py-0"
        flat
      >
        <v-icon color="white" size="32px" class="py-0">mdi-phone</v-icon>
        (0966) 876 9058
      </v-card>
    </div>

    <div class="mt-5">
      <router-link
        :to="ROUTE_PRIVACY_POLICY"
        class="text-decoration-none white--text"
        flat
      >
        Privacy Policy
      </router-link>

      &bull;

      <router-link
        :to="ROUTE_TERMS_AND_CONDITION"
        class="text-decoration-none white--text"
        flat
      >
        Terms and Conditions
      </router-link>

      &bull;

      <router-link
        :to="ROUTE_ABOUT"
        class="text-decoration-none white--text"
        flat
      >
        About
      </router-link>
    </div>

    <v-card-text class="headline white--text mb-0 mt-5 py-0">
      "Sure Ka Na, Abot Kaya Pa"
    </v-card-text>
    <v-card-text class="mt-0 pt-0 white--text">
      {{ new Date().getFullYear() }} — <strong>MediSure</strong>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  ROUTE_ABOUT,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_AND_CONDITION
} from "@/router";

import { mapGetters } from "vuex";

export default {
  name: "AppFooter",

  data: () => ({
    FB: "https://www.facebook.com/MediSurePH",
    IG: "https://www.instagram.com/medisureplus/",
    TWITTER: "https://twitter.com/medisureplus",

    ROUTE_ABOUT: ROUTE_ABOUT,
    ROUTE_PRIVACY_POLICY: ROUTE_PRIVACY_POLICY,
    ROUTE_TERMS_AND_CONDITION: ROUTE_TERMS_AND_CONDITION
  }),

  computed: {
    ...mapGetters(["isLoggedIn"])
  }
};
</script>
