import {
  DOCTYPE_PHILHEALTHINFO,
  getDocumentHeader,
  IndexedDocument
} from "@/utilities/user_collection";

function getData(state) {
  return {
    assessmentDate: state.assessmentDate,
    authorizationTransactionCode: state.authorizationTransactionCode,
    clientType: state.clientType,
    membershipCategory: state.membershipCategory,
    philHealthId: state.philHealthId,
    submissionDate: state.submissionDate,
    withPhilhealth: state.withPhilhealth
  };
}

function getDocumentContent(state) {
  return {
    header: getDocumentHeader(DOCTYPE_PHILHEALTHINFO, state),
    data: getData(state)
  };
}

const philhealthInfoBase = new IndexedDocument(
  DOCTYPE_PHILHEALTHINFO,
  getDocumentContent
);
export const philhealthInfo = {
  state: () => ({
    ...philhealthInfoBase.state,

    assessmentDate: "",
    authorizationTransactionCode: "",
    clientType: "",
    membershipCategory: "",
    philHealthId: "",
    submissionDate: "",
    withPhilhealth: ""
  }),

  getters: {
    ...philhealthInfoBase.getters,

    getAssessmentDate(state) {
      return state.assessmentDate;
    },

    getAuthorizationTransactionCode(state) {
      return state.authorizationTransactionCode;
    },

    getClientType(state) {
      return state.clientType;
    },

    getMembershipCategory(state) {
      return state.membershipCategory;
    },

    getPhilHealthId(state) {
      return state.philHealthId;
    },

    getWithPhilhealth(state) {
      return state.withPhilhealth;
    },

    isSubmitted(state) {
      return state.submissionDate;
    }
  },

  mutations: {
    ...philhealthInfoBase.mutations,

    RESET_STATE(state) {
      state.assessmentDate = "";
      state.authorizationTransactionCode = "";
      state.clientType = "";
      state.membershipCategory = "";
      state.philHealthId = "";
      state.submissionDate = "";
      state.withPhilhealth = "";
    },

    setAssessmentDate(state, value) {
      state.isSaved = state.isSaved && state.assessmentDate === value;
      state.assessmentDate = value;
    },

    setAuthorizationTransactionCode(state, value) {
      state.isSaved =
        state.isSaved && state.authorizationTransactionCode === value;
      state.authorizationTransactionCode = value;
    },

    setClientType(state, data) {
      state.isSaved = state.isSaved && state.clientType === data;
      state.clientType = data;
    },

    setData(state, data) {
      state.withPhilhealth = data.withPhilhealth || "";
      state.philHealthId = data.philHealthId || "";
      state.clientType = data.clientType || "";
      state.membershipCategory = data.membershipCategory || "";
      state.authorizationTransactionCode =
        data.authorizationTransactionCode || "";
      state.assessmentDate = data.assessmentDate || "";
      state.submissionDate = data.submissionDate || "";
    },

    setMembershipCategory(state, value) {
      state.isSaved = state.isSaved && state.membershipCategory === value;
      state.membershipCategory = value;
    },

    setPhilHealthId(state, data) {
      state.isSaved = state.isSaved && state.philHealthId === data;
      state.philHealthId = data;
    },

    setSubmissionDate(state, value) {
      state.isSaved = state.isSaved && state.submissionDate === value;
      state.submissionDate = value;
    },

    setWithPhilhealth(state, value) {
      state.isSaved = state.isSaved && state.withPhilhealth === value;
      state.withPhilhealth = value;
    }
  },

  actions: {
    ...philhealthInfoBase.actions,

    resetContent({ commit, dispatch }) {
      dispatch("resetDocument");
      commit("RESET_STATE");
    }
  }
};
