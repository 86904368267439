import { DATA, STAGE, getLastSubmission } from "@/store/offsiteService";
import { DB, getCurrentUserUid } from "@/common/store";

import store from "@/store";
import { db } from "@/main";

export default function addData(scheduleId, userCode, type, data) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add({
        ...data,
        scheduleId: scheduleId,
        timestamp: new Date(),
        type: type,
        userCode: userCode,
        value: true
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function addFecalysisData(scheduleId, userCode, data) {
  return addData(scheduleId, userCode, DATA.FECALYSIS, data);
}

export function addUrinalysisData(scheduleId, userCode, data) {
  return addData(scheduleId, userCode, DATA.URINALYSIS, data);
}

function processSnapshotSubmission(snapshot) {
  let submissions = {
    fecalysis: false,
    photo: "",
    temperature: "",
    urinalysis: false,
    xray: false
  };

  snapshot.forEach(doc => {
    const data = doc.data();
    if (STAGE.CHECKIN === data.type) {
      submissions.photo = data.photo || "";
      submissions.temperature = data.temperature || "";
    }
  });

  return submissions;
}

export function getCheckinData(scheduleId, userCode) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .where("userCode", "==", userCode)
      .get()
      .then(snapshot => {
        resolve(processSnapshotSubmission(snapshot));
      })
      .catch(err => reject(err));
  });
}

function lastFecalysis(snapshot) {
  let lastSubmission = null;
  let fecalysis = false;

  snapshot.forEach(doc => {
    const data = doc.data();
    if (!lastSubmission || lastSubmission < data.timestamp) {
      lastSubmission = data.timestamp;
      fecalysis = true;
    }
  });

  return { fecalysis: fecalysis };
}

export function getFecalysisDataFromResult(result) {
  return {
    color: !result ? "" : result.color,
    consistency: !result ? "" : result.consistency,

    wbc: !result ? "" : result.wbc,
    rbc: !result ? "" : result.rbc,
    fatGlobules: !result ? "" : result.fatGlobules,
    yeastCells: !result ? "" : result.yeastCells,
    bacteria: !result ? "" : result.bacteria,

    parasites:
      result && result.parasites
        ? [...result.parasites]
        : [{ hpo: "", parasite: "" }],

    remarks: !result ? "" : result.remarks
  };
}

function lastFecalysisData(snapshot) {
  let fecalysisData = {};

  snapshot.forEach(doc => {
    fecalysisData = getFecalysisDataFromResult(doc.data());
  });

  return fecalysisData;
}

function lastUrinalysis(snapshot) {
  let lastSubmission = null;
  let urinalysis = false;

  snapshot.forEach(doc => {
    const data = doc.data();
    if (!lastSubmission || lastSubmission < data.timestamp) {
      lastSubmission = data.timestamp;
      urinalysis = true;
    }
  });

  return { urinalysis: urinalysis };
}

function getValue(obj, key) {
  return obj && obj[key] ? obj[key] : "";
}

export function getUrinalysisDataFromResult(result) {
  return {
    color: getValue(result, "color"),
    clarity: getValue(result, "clarity"),

    wbc: getValue(result, "wbc"),
    rbc: getValue(result, "rbc"),
    epithelialCells: getValue(result, "epithelialCells"),
    bacteria: getValue(result, "bacteria"),
    mucusThread: getValue(result, "mucusThread"),
    amorphousUrates: getValue(result, "amorphousUrates"),
    amorphousPhosphate: getValue(result, "amorphousPhosphate"),
    yeastCells: getValue(result, "yeastCells"),

    urobilinogen: getValue(result, "urobilinogen"),
    bilirubin: getValue(result, "bilirubin"),
    protein: getValue(result, "protein"),
    blood: getValue(result, "blood"),
    ketone: getValue(result, "ketone"),
    nitrite: getValue(result, "nitrite"),
    wbcChemicalExamination: getValue(result, "wbcChemicalExamination"),
    glucose: getValue(result, "glucose"),
    specificGravity: getValue(result, "specificGravity"),
    ph: getValue(result, "ph"),

    remarks: getValue(result, "remarks")
  };
}

function lastUrinalysisData(snapshot) {
  let urinalysisData = {};

  snapshot.forEach(doc => {
    urinalysisData = getUrinalysisDataFromResult(doc.data());
  });

  return urinalysisData;
}

export function getFecalysis(scheduleId, userCode) {
  return getLastSubmission(scheduleId, userCode, DATA.FECALYSIS, lastFecalysis);
}

export function getFecalysisData(scheduleId, userCode) {
  return getLastSubmission(
    scheduleId,
    userCode,
    DATA.FECALYSIS,
    lastFecalysisData
  );
}

export function getUrinalysis(scheduleId, userCode) {
  return getLastSubmission(
    scheduleId,
    userCode,
    DATA.URINALYSIS,
    lastUrinalysis
  );
}

export function getUrinalysisData(scheduleId, userCode) {
  return getLastSubmission(
    scheduleId,
    userCode,
    DATA.URINALYSIS,
    lastUrinalysisData
  );
}

export function getBaseData(submissionType, scheduleId, userCode, value) {
  return {
    encoder: getCurrentUserUid(),
    scheduleId: scheduleId,
    timestamp: new Date(),
    type: submissionType,
    userCode: userCode,
    value: value
  };
}

export function addSubmission(submissionType, scheduleId, userCode, value) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add({
        ...getBaseData(submissionType, scheduleId, userCode, value)
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function addDoctorAssessment(scheduleId, userCode, data) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add({
        ...getBaseData(STAGE.DOCTOR, scheduleId, userCode, true),
        ...data
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function addTriage(scheduleId, userCode, data) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .add({
        ...getBaseData(STAGE.TRIAGE, scheduleId, userCode, true),
        ...data
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

function getData(scheduleId, userCode, stage) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .where("userCode", "==", userCode)
      .where("type", "==", stage)
      .orderBy("timestamp", "desc")
      .limit(1)
      .get()
      .then(snapshot => {
        let details = {};

        snapshot.forEach(doc => {
          details = { ...doc.data() };
        });

        resolve(details);
      })
      .catch(err => reject(err));
  });
}

export function getDoctorAssessment(scheduleId, userCode) {
  return scheduleId ? getData(scheduleId, userCode, STAGE.DOCTOR) : {};
}

function processSnapshot(snapshot) {
  let submissions = {
    [STAGE.CHECKIN]: false,
    [STAGE.TRIAGE]: false,
    [STAGE.BLOOD_EXTRACTION]: false,
    [STAGE.URINALYSIS]: false,
    [STAGE.FECALYSIS]: false,
    [STAGE.XRAY]: false,
    [STAGE.ECG]: false,
    [STAGE.PAP_SMEAR]: false,
    [STAGE.DOCTOR]: false,
    [STAGE.CHECKOUT]: false
  };

  snapshot.forEach(doc => {
    const data = doc.data();

    switch (data.type) {
      case STAGE.CHECKIN:
        submissions[STAGE.CHECKIN] = data.value || false;
        break;

      case STAGE.TRIAGE:
        submissions[STAGE.TRIAGE] = true;
        break;

      case STAGE.BLOOD_EXTRACTION:
        submissions[STAGE.BLOOD_EXTRACTION] = data.value || false;
        break;

      case STAGE.URINALYSIS:
        submissions[STAGE.URINALYSIS] = data.value || false;
        break;

      case STAGE.FECALYSIS:
        submissions[STAGE.FECALYSIS] = data.value || false;
        break;

      case STAGE.XRAY:
        submissions[STAGE.XRAY] = data.value || false;
        break;

      case STAGE.ECG:
        submissions[STAGE.ECG] = data.value || false;
        break;

      case STAGE.PAP_SMEAR:
        submissions[STAGE.PAP_SMEAR] = data.value || false;
        break;

      case STAGE.DOCTOR:
        submissions[STAGE.DOCTOR] = true;
        break;

      case STAGE.CHECKOUT:
        submissions[STAGE.CHECKOUT] = data.value || false;
        break;
    }
  });

  return submissions;
}

export function getScheduleData(scheduleId, userCode) {
  return new Promise((resolve, reject) => {
    db.collection(DB.OFFSITE_SUBMISSIONS)
      .where("scheduleId", "==", scheduleId)
      .where("userCode", "==", userCode)
      .orderBy("timestamp", "asc")
      .get()
      .then(snapshot => resolve(processSnapshot(snapshot)))
      .catch(err => reject(err));
  });
}

export function getTriage(scheduleId, userCode) {
  return getData(scheduleId, userCode, STAGE.TRIAGE);
}

export function getMySchedulesList() {
  return getMySchedules(STAGE.DOCTOR, store.state.profile.userCode);
}

function getMySchedules(stage, userCode) {
  return new Promise((resolve, reject) => {
    db.collection(DB.CENTRALIZE_APPOINTMENTS)
      .where("type", "==", stage)
      .where("userCode", "==", userCode)
      .where("timeScheduleConfirmed", "==", true)
      .orderBy("timestamp", "desc")
      .get()
      .then(snapshot => {
        let details = [];
        let resultId = [];

        snapshot.forEach(doc => {
          if (!resultId.includes(doc.data().serviceId)) {
            // Get Latest By Service
            resultId.push(doc.data().serviceId);
            details.push({ ...doc.data(), id: doc.id });
          }
        });

        resolve(details);
      })
      .catch(err => reject(err));
  });
}
