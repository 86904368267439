<template>
  <v-form class="pa-2" ref="form" :lazy-validation="false">
    <v-autocomplete
      label="Mode of Payment"
      v-model="modeOfPayment"
      prepend-inner-icon="mdi-contactless-payment"
      :items="items"
      :rules="[m => !!m || 'Mode of Payment is required']"
    />

    <v-text-field
      label="Name"
      v-model="name"
      class="py-1"
      :rules="[n => !!n || 'Name is required']"
    />
    <v-row>
      <v-col cols="4" sm="3">
        <v-select
          label="Service"
          v-model="contactType"
          class="pa-1"
          :items="contactTypes"
        />
      </v-col>
      <v-col cols="8" sm="9">
        <v-text-field
          label="Contact Number (required)"
          v-model="contact"
          type="tel"
          class="inputPrice pa-1"
          @keyup="contactChanged"
          :maxlength="isLandline ? 10 : 11"
          :hint="isLandline ? 'ex. 0868263992' : 'ex. 09123456789'"
          :rules="[rules.required, isLandline ? rules.min10 : rules.min11]"
        />
      </v-col>
    </v-row>

    <p class="text-center primary--text">Billing Address</p>
    <SelectLocation
      class="px-2"
      :dbLocation="'mobileServiceLocations'"
      :selectedLocation.sync="selectedLocation"
    />
    <v-text-field
      label="Unit/Bldg./Floor/House Number"
      v-model="houseNumber"
      class="px-2"
      :rules="[c => !!c || 'Field is required']"
    />
    <v-text-field
      label="Bldg./Street Name"
      v-model="street"
      class="px-2"
      :rules="[c => !!c || 'Field is required']"
    />
    <v-text-field
      label="Landmark"
      v-model="landmark"
      class="px-2"
      :rules="[c => !!c || 'Field is required']"
    />
  </v-form>
</template>

<script>
import {
  CONTACT_PHONE,
  contactTypes,
  isLandline,
  isPhone
} from "@/common/utilities/contacts";
import {
  PAYMENT_CASH_ON_DELIVERY,
  PAYMENT_ON_SITE
} from "@/common/utilities/payment";

import { isLaboratoryService } from "@/common/utilities/services";
import { loadProfileAddress } from "@/utilities/tindahan";

import SelectLocation from "@/components/SelectLocation.vue";

export default {
  name: "PayeeDetails",

  components: {
    SelectLocation
  },

  props: {
    isAddressSelected: Boolean
  },

  data: () => ({
    modeOfPayment: "",
    name: "",
    items: [],
    contactType: CONTACT_PHONE,

    contactTypes: contactTypes,

    contact: "",
    houseNumber: "",
    street: "",
    landmark: "",
    selectedLocation: null,

    rules: {
      required: value => !!value || "Pasword Required.",
      min10: v => v.length >= 10 || "Min 10 characters",
      min11: v => v.length >= 11 || "Min 11 characters"
    }
  }),

  mounted() {
    if (this.isAddressSelected) {
      this.saveAddress(this.$store.getters["getSelectedAddress"]);
    } else {
      loadProfileAddress().then(profile => {
        this.saveAddress(profile.defaultDetails);
      });
    }

    this.initializePaymentModes();
    this.modeOfPayment = PAYMENT_CASH_ON_DELIVERY;
  },

  methods: {
    emitIsValid() {
      this.$emit("isValid", this.isValid());
    },

    initializePaymentModes() {
      this.items = [
        isLaboratoryService ? PAYMENT_CASH_ON_DELIVERY : PAYMENT_ON_SITE
      ];

      this.items.sort();
    },

    isValid() {
      return (
        (isLandline(this.contactType) || this.contact.length == 11) &&
        (isPhone(this.contactType) || this.contact.length == 10) &&
        this.modeOfPayment !== "" &&
        this.name !== "" &&
        this.houseNumber !== "" &&
        this.street !== "" &&
        this.landmark !== ""
      );
    },

    saveAddress(address) {
      this.name = address.name;
      this.contact = address.contact;
      this.houseNumber = address.houseNumber;
      this.street = address.street;
      this.landmark = address.landmark;
      this.selectedLocation = address.selectedLocation;
    }
  },

  watch: {
    address(value) {
      this.$emit("address", value);
      this.emitIsValid();
    },

    contact(value) {
      this.$emit("contact", value);
      this.emitIsValid();
    },

    contactType() {
      this.contact = "";
    },

    houseNumber(value) {
      this.$emit("houseNumber", value);
      this.emitIsValid();
    },

    isLandline() {
      return isLandline(this.contactType);
    },

    landmark(value) {
      this.$emit("landmark", value);
      this.emitIsValid();
    },

    modeOfPayment(value) {
      this.$emit("modeOfPayment", value);
      this.emitIsValid();
    },

    name(value) {
      this.$emit("name", value);
      this.emitIsValid();
    },

    selectedLocation(value) {
      this.$emit("selectedLocation", value);
      this.emitIsValid();
    },

    street(value) {
      this.$emit("street", value);
      this.emitIsValid();
    }
  }
};
</script>

<style scoped>
.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
