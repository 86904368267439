<template>
  <v-row>
    <v-col cols="2" />
    <v-col cols="5">{{ header }}</v-col>
    <v-col class="text-right" cols="3">{{ displayContent }}</v-col>
  </v-row>
</template>

<script>
export default {
  name: "InsurancePaymentElement",

  props: {
    header: String,
    content: Number
  },

  data: () => ({
    displayContent: "₱ 0.00"
  }),

  mounted() {
    this.displayContent = this.toMoneyString(this.content);
  },

  methods: {
    toMoneyString(number) {
      if (!number) {
        number = 0;
      }

      let balance = number.toFixed(2);
      for (let i = 6; balance.length > i; i += 4) {
        balance = balance.slice(0, -1 * i) + "," + balance.slice(-1 * i);
      }

      return "₱ " + balance;
    }
  }
};
</script>
