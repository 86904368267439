<template>
  <div min-width="250">
    <h4 v-if="header" class="primary--text">{{ header }}</h4>

    <div class="mt-5">
      <v-row v-for="(test, i) in selectedTests" :key="i">
        <v-col cols="9" sm="6" class="pt-0 text-left">
          <span class="font-weight-bold">{{ test.name }}</span>
          <br v-if="test.tests" />
          <div class="ml-5 mr-2 text-caption text-justify" v-if="test.tests">
            {{ test.tests.join(", ") }}
          </div>
        </v-col>
        <v-col cols="3" sm="6" class="pt-0 text-right">
          <div style="display: inline-block;">
            <PesoAmount :amount="test.price" />
          </div>
          <v-btn
            @click="remove(i)"
            class="mt-n1"
            icon
            style="display: inline-block;"
          >
            <v-icon right color="error">
              mdi-trash-can
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "SelectedTests",

  components: {
    PesoAmount
  },

  props: {
    header: String,
    selectedTests: Array
  },

  methods: {
    remove(i) {
      this.$emit("removeTest", i);
    }
  }
};
</script>
