<template>
  <div>
    <AppHeader header="e-Konsulta" />

    <v-container fluid class="main-container">
      <v-btn block elevation="3" large @click="scheduleDoctorAppointment">
        Schedule an Appointment
      </v-btn>

      <v-card
        align="center"
        v-for="(appoint, index) in appointments"
        :key="index"
      >
        <v-list-item three-line @click="meetDoctor">
          <v-list-item-content>
            <v-list-item-title>
              {{ appoint.encoderName }}
              <p class="caption">{{ appoint.consultationType }}</p>
            </v-list-item-title>
            <v-list-item-subtitle>
              Schedule {{ appoint.scheduleDate }}
              {{ timeAmPmConvert(appoint.timeSchedule.start) }} -
              {{ timeAmPmConvert(appoint.timeSchedule.end) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {
  ROUTE_DOCTOR_APPOINTMENT,
  ROUTE_DOCTOR_TELECONFERENCE,
  routeTo
} from "@/router";

import { getMySchedulesList } from "@/store/submissions";
import { timeAmPmConvert } from "@/common/utilities/date";

import AppHeader from "@/components/AppHeader.vue";

export default {
  name: "Doctor",

  components: {
    AppHeader
  },

  data: () => ({
    appointments: [],
    timeAmPmConvert: timeAmPmConvert
  }),

  mounted() {
    getMySchedulesList().then(docs => {
      this.appointments = docs;
    });
  },

  methods: {
    meetDoctor() {
      routeTo(ROUTE_DOCTOR_TELECONFERENCE);
    },

    scheduleDoctorAppointment() {
      routeTo(ROUTE_DOCTOR_APPOINTMENT);
    }
  }
};
</script>
