<template>
  <v-card color="#0033009D" class="pa-5">
    <h1 class="text-center font-size white--text pa-2">
      Dahil mahal namin kayo, libre ang pagrehistro. Tara na! Register na!
    </h1>
    <div class="text-center">
      <v-btn color="primary" :to="ROUTE_CREATE_ACCOUNT" class="white--text">
        Mag Register!
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { ROUTE_CREATE_ACCOUNT } from "@/router";

export default {
  name: "RegisterAd",

  data: () => ({
    ROUTE_CREATE_ACCOUNT: ROUTE_CREATE_ACCOUNT
  })
};
</script>
