<template>
  <div>
    <div v-if="searching && !doctors.length" class="text-center">
      <v-progress-circular :size="150" :width="7" color="green" indeterminate />
    </div>

    <div v-else-if="!doctors.length">
      <p>
        Sorry. No doctor available based on your search filters.
      </p>
    </div>

    <div v-else>
      <v-list-item-group color="primary">
        <v-list-item v-for="(doc, i) in doctors" :key="i">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="doc.data.displayName"
              @click="selectDoctor(doc)"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </div>
  </div>
</template>

<script>
import { loadDoctors } from "@/store";

export default {
  name: "SelectDoctor",

  props: {
    currentStep: Number,
    selectedSpecialization: {
      type: String,
      required: true
    },
    selectedVisitType: {
      type: String,
      required: true
    },
    showNearFirst: {
      type: Boolean,
      required: true
    },
    selectedLocation: Object,
    selectedDoctor: Object
  },

  data: () => ({
    doctors: [],
    searching: false,
    bottom: false,
    lastVisibleDoctor: null
  }),

  mounted() {
    window.addEventListener("scroll", this.scrollListener);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollListener);
  },

  watch: {
    currentStep(step, prev) {
      if (prev == 1 && step == 2) {
        this.loadDoctors();
      }
    },

    bottom(bottom) {
      if (bottom) {
        this.loadDoctors();
      }
    }
  },

  methods: {
    loadDoctors() {
      this.searching = true;
      let filters = {
        specialization: this.selectedSpecialization,
        visitType: this.selectedVisitType,
        showNearFirst: true,
        location: this.selectedLocation,
        docsPerLoad: 20,
        lastVisibleDoctor: this.lastVisibleDoctor
      };

      loadDoctors(filters).then(ret => {
        if (ret.docsCount > 0) {
          this.doctors = this.doctors.concat(ret.docs);
          this.lastVisibleDoctor = ret.lastVisible;
        }
        this.searching = false;
      });
    },

    scrollListener() {
      this.bottom = this.bottomVisible();
    },

    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    },

    selectDoctor(doc) {
      this.$emit("update:selectedDoctor", doc);
    }
  }
};
</script>
