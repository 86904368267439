<template>
  <div>
    <BackButton @click="$emit('back')" />

    <p class="mt-10 text-center">
      Please confirm that the details below are correct and click SUBMIT to
      continue.
    </p>

    <MemberInformation class="mt-10" />

    <NextButton @click="onNext" text="Submit" class="mb-5 mt-15" />
  </div>
</template>

<script>
import BackButton from "@/components/Controls/BackButton.vue";
import MemberInformation from "./Confirmation/MemberInformation.vue";
import NextButton from "@/components/Controls/NextButton.vue";

export default {
  name: "Confirmation",

  components: {
    BackButton,
    MemberInformation,
    NextButton
  },

  methods: {
    onNext() {
      this.$store.dispatch("resetSecondsIdle").then(() => {
        this.$emit("next");
      });
    }
  }
};
</script>
