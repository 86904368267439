<template>
  <div class="text-left">
    <v-container fluid class="text-center">
      <v-img
        src="@/assets/hart.png"
        alt="MediSure"
        width="100"
        class="center-image mb-2"
      />

      <strong>MEDISURE PLUS DIAGNOSTICS LABORATORY</strong><br />
      <strong>MEDISURE ONLINE INC.</strong><br />

      16B WILLIAMS ST. HIGHWAY HILLS, MADALUYONG CITY<br />
      <span class="mr-5">TEL. NO.: 864-28781</span>
      <span>EMAIL ADDRESS: mso.lab@medisureonline.com</span><br /><br />

      <strong>
        {{ header }}
        <br v-show="subheader" />
        <span v-show="subheader">{{ subheader }}</span>
      </strong>
    </v-container>

    <strong>Patient's Name:</strong>
    &nbsp;{{ lastName }}, {{ firstName }} {{ middleName }}<br />

    <strong>Birthday:</strong> {{ dateOfBirth }}<br />

    <strong>Age/Sex:</strong> {{ age }} / {{ gender }}<br />

    <strong>Report Date:</strong> {{ apeDateDisplay }}
  </div>
</template>

<script>
import { NS_MEMBERINFO } from "@/store";

import {
  computeAge,
  timeStampToDate,
  getVerboseDateStringFromTimestamp
} from "@/common/utilities/date";
import { mapGetters } from "vuex";

export default {
  props: {
    header: String,
    subheader: String
  },

  computed: {
    ...mapGetters({
      apeDate: "getResultServiceSchedule",
      userCode: "getResultUserCode",
      dateOfBirth: `${NS_MEMBERINFO}/getBirthday`,
      firstName: `${NS_MEMBERINFO}/getFirstName`,
      gender: `${NS_MEMBERINFO}/getSex`,
      lastName: `${NS_MEMBERINFO}/getLastName`,
      middleName: `${NS_MEMBERINFO}/getMiddleName`
    }),

    age() {
      return this.apeDate
        ? computeAge(timeStampToDate(this.apeDate), new Date(this.dateOfBirth))
        : 0;
    },

    apeDateDisplay() {
      return getVerboseDateStringFromTimestamp(this.apeDate);
    }
  }
};
</script>

<style scoped>
/* A4: 715px width */

.center-image {
  margin: auto;
}
</style>
