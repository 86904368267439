<template>
  <div>
    <RemoteServices
      v-if="$store.getters['lab/appointment/getIsHomeService']"
      @back="$emit('back')"
      @next="$emit('next')"
    />

    <MainOffice v-else @back="$emit('back')" @next="$emit('next')" />
  </div>
</template>

<script>
import MainOffice from "./Location/MainOffice.vue";
import RemoteServices from "./Location/RemoteServices.vue";

export default {
  name: "SelectLocation",

  components: {
    MainOffice,
    RemoteServices
  }
};
</script>
