<template>
  <div>
    <v-parallax height="100" dark :src="require('../assets/parallax.png')">
      <v-layout align-center column justify-center>
        <h1 class="display-1 font-weight-thin mb-3">
          <strong> LGU Health Services</strong>
        </h1>
        <h1 class="body-1 font-weight-thin mb-3">
          <strong> {{ lguLocation }}</strong>
        </h1>
      </v-layout>
    </v-parallax>

    <v-container fluid class="lgucontainer">
      <div v-if="['Pending', 'Requested'].includes(validationStatus)">
        <v-card class="pa-10">
          <v-col class="text-center"> {{ verifyingText }} </v-col>
        </v-card>
      </div>
      <div v-else-if="'Rejected' === validationStatus">
        <v-card class="pa-10">
          <v-col class="text-center"> {{ rejectedText }} </v-col>
        </v-card>
      </div>
      <div v-else-if="'Verified' === validationStatus">
        <LguAppointment :location="lguLocation" />
      </div>
      <div v-else-if="!validationStatus.length">
        <SelectLgu
          @locationSelected="lguSelected"
          @validationStarted="validationStarted"
        />
      </div>
      <div v-else>
        <Validate
          :target="lguLocation"
          @validationSubmitted="validationSubmitted"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import { getLGUDataFromProfile } from "@/store";

import LguAppointment from "@/components/LguServices/LguAppointment.vue";
import SelectLgu from "@/components/LguServices/SelectLgu.vue";
import Validate from "@/views/Validate.vue";

export default {
  name: "MenuItems",

  components: {
    LguAppointment,
    SelectLgu,
    Validate
  },

  data: () => ({
    lguLocation: "",
    lgustat: ""
  }),

  mounted() {
    getLGUDataFromProfile().then(lgu => {
      if (lgu) {
        this.lguLocation = lgu.location;
        this.lgustat = lgu.verificationStatus;
      }
    });
  },

  computed: {
    rejectedText() {
      return this.$t("lgu.rejected").replace("%s", this.lguLocation);
    },

    validationStatus() {
      return this.lgustat ? this.lgustat : "";
    },

    verifyingText() {
      return this.$t("lgu.verification").replace("%s", this.lguLocation);
    }
  },

  methods: {
    lguSelected(lguLocation) {
      this.lguLocation = lguLocation;
    },

    validationSubmitted() {
      this.lgustat = "Pending";
    },

    validationStarted() {
      this.lgustat = "validate";
    }
  }
};
</script>

<style scoped>
.lgucontainer {
  max-width: 1080px;
}
</style>
