import { getCurrentUserUid, logUserActivity } from "@/common/store";

import About from "@/views/About.vue";
import AboutUs from "@/views/AboutUs.vue";
import AddBantayKardPoints from "@/views/AddBantayKardPoints.vue";
import AddressBook from "@/eTindahan/views/AddressBook.vue";
import AddressInput from "@/eTindahan/views/AddressInput.vue";
import Appointments from "@/views/Appointments.vue";
import BHC from "@/views/BHC.vue";
import Botika from "@/views/Botika.vue";
import Capture from "@/views/Capture.vue";
import Cart from "@/eTindahan/views/Cart.vue";
import Checkout from "@/eTindahan/views/Checkout.vue";
import ContactTracingForm from "@/ContactTracing/views/Form.vue";
import CovidVaccine from "@/views/CovidVaccine.vue";
import CreateAccount from "@/views/CreateAccount.vue";
import Doctor from "@/views/Doctor.vue";
import DoctorAppointment from "@/views/DoctorAppointment.vue";
import DoctorTeleconference from "@/views/DoctorTeleconference.vue";
import EditCartItem from "@/eTindahan/views/EditCartItem.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import HealthProfile from "@/HealthRecords/views/HealthProfile.vue";
import Home from "@/views/Home.vue";
import Insurance from "@/views/Insurance.vue";
import LGUServices from "@/views/LGUServices.vue";
import Laboratory from "@/views/Laboratory.vue";
import Landing from "@/views/Landing.vue";
import Loading from "@/views/Loading.vue";
import MedicalHistory from "@/HealthRecords/views/MedicalHistory.vue";
import MedicalRecords from "@/views/MedicalRecords.vue";
import Menu from "@/views/Menu.vue";
import Messages from "@/views/Messages.vue";
import OffsitePrimaryMember from "@/views/OffsitePrimaryMember.vue";
import OffsiteRegistration from "@/views/OffsiteRegistration.vue";
import OrderHistory from "@/eTindahan/views/OrderHistory.vue";
import Payment from "@/views/Payment.vue";
import PhilHealthRegister from "@/PhilHealth/views/Register.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import ProductDetails from "@/eTindahan/views/ProductDetails.vue";
import Profile from "@/views/Profile.vue";
import RefundExcess from "@/eTindahan/views/RefundExcess.vue";
import Register from "@/views/Register.vue";
import ReturnRefundInput from "@/eTindahan/views/ReturnRefundInput.vue";
import RxPrescription from "@/eBotika/views/RxPrescription.vue";
import SearchedProducts from "@/eTindahan/views/SearchedProducts.vue";
import Signin from "@/views/Signin.vue";
import Stores from "@/views/Stores.vue";
import Summary from "@/views/Summary.vue";
import SummaryOrder from "@/eTindahan/views/SummaryOrder.vue";
import TermsAndCondition from "@/views/TermsAndCondition.vue";
import Track from "@/eTindahan/views/Track.vue";
import TrackOrder from "@/eTindahan/views/TrackOrder.vue";
import Validate from "@/views/Validate.vue";
import ViewProduct from "@/views/ViewProduct.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import Wishlists from "@/eTindahan/views/Wishlists.vue";
import { routes as isdaRoutes } from "./isda";
import { routes as offsiteRoutes } from "./offsite";
import { routes as resultRoutes } from "./results";
import { setPathToLoadAfterLogin } from "@/utilities/session";
import store from "@/store";
import { routes as walkinRoutes } from "./medicalServices";

Vue.use(VueRouter);

export const ROUTE_ABOUTUS = "/aboutus";
export const ROUTE_ABOUT = "/about";
export const ROUTE_ADD_BANTAYKARD_POINTS = "/addbantaykardpoints";
export const ROUTE_APPOINTMENTS = "/appointments";
export const ROUTE_BHC = "/bhc";
export const ROUTE_BOTIKA = "/botika";
export const ROUTE_CAPTURE = "/capture";

export const ROUTE_CHECKOUT = "/checkout";
export const ROUTE_CONTACTTRACING = "/contacttracing";
export const ROUTE_DASHBOARD = "/";

export const ROUTE_DOCTOR = "/doctor";
export const ROUTE_DOCTOR_APPOINTMENT = "/doctorappointment";
export const ROUTE_DOCTOR_TELECONFERENCE = "/doctorteleconference";

export const ROUTE_FORGOTPASSWORD = "/forgotPassword";
export const ROUTE_HEALTHPROFILE = "/healthprofile";
export const ROUTE_INBOX = "/inbox";
export const ROUTE_INSURANCE = "/insurance";
export const ROUTE_LABORATORY = "/laboratory";
export const ROUTE_LANDING = "/landing";
export const ROUTE_LOADING = "/loading";
export const ROUTE_MEDICALHISTORY = "/medicalhistory";
export const ROUTE_ORDERHISTORY = "/orderhistory";
export const ROUTE_PAYMENT = "/payment";
export const ROUTE_PRIVACY_POLICY = "/privacyPolicy";
export const ROUTE_TERMS_AND_CONDITION = "/termsAndCondition";

export const ROUTE_PHILHEALTH = "/philhealth/register";
export const ROUTE_PROFILE = "/profile";
export const ROUTE_REGISTER = "/register";
export const ROUTE_CREATE_ACCOUNT = "/createAccount";

export const ROUTE_OFFSITE_REGISTER = "/offsiteRegistration";
export const ROUTE_PRIMARY_MEMBER = "/offsitePrimaryMember";
export const ROUTE_RXPRESCRIPTION = "/rxprescription";
export const ROUTE_SIGNIN = "/signin";
export const ROUTE_EDIT_CARTITEM = "/editcartitem";
export const ROUTE_VALIDATE = "/validate";

export const ROUTE_STORES = "/stores";
export const ROUTE_ADDRESS_BOOK = "/addressbook";
export const ROUTE_ADDRESS_INPUT = "/addressinput";
export const ROUTE_SEARCHED_PRODUCTS = "/searchedproducts";
export const ROUTE_PRODUCTDETAILS = "/productdetails";
export const ROUTE_WISHLISTS = "/wishlists";
export const ROUTE_CART = "/cart";
export const ROUTE_SUMMARY = "/summary";
export const ROUTE_SUMMARY_ORDER = "/summaryorder";
export const ROUTE_TRACK = "/track";
export const ROUTE_TRACKORDER = "/trackorder";
export const ROUTE_REFUND_EXCESS = "/refundexcess";
export const ROUTE_RETURN_REFUND_INPUT = "/returnrefundinput";

//e-TindahanRoutes
const eTindahanSubpages = [
  ROUTE_STORES,
  ROUTE_ADDRESS_BOOK,
  ROUTE_ADDRESS_INPUT,
  ROUTE_SEARCHED_PRODUCTS,
  ROUTE_PRODUCTDETAILS,
  ROUTE_WISHLISTS,
  ROUTE_CART,
  ROUTE_SUMMARY,
  ROUTE_SUMMARY_ORDER,
  ROUTE_TRACK,
  ROUTE_TRACKORDER
];

async function navigateToNext(to) {
  await logUserActivity("Navigation", { target: to.name ? to.name : to.path });
  await store.dispatch("resetSecondsIdle");
}

const guard = async (to, from, next) => {
  store.commit("setPreviousPage", from);

  // redirect to sign in page if user is not signed up yet
  if (!getCurrentUserUid()) {
    setPathToLoadAfterLogin(to.path);
    await store.dispatch("resetSecondsIdle");
    return next({ path: ROUTE_REGISTER, replace: true });
  } else if (store.getters["isLoggedIn"]) {
    try {
      await store.dispatch("loadProfile");
      await navigateToNext(to);
      return next();
    } catch (err) {
      await navigateToNext(to);
      return next(ROUTE_DASHBOARD);
    }
  } else {
    await navigateToNext(to);
    return next();
  }
};

const loggedOutGuard = async (_to, _from, next) => {
  return store.getters["isLoggedIn"] ? next(ROUTE_DASHBOARD) : next();
};

const routes = [
  {
    path: ROUTE_DASHBOARD,
    name: "Home",
    component: Home
  },
  {
    path: ROUTE_PRIVACY_POLICY,
    name: "Privacy Policy",
    component: PrivacyPolicy
  },
  {
    path: ROUTE_TERMS_AND_CONDITION,
    name: "Terms And Conditions",
    component: TermsAndCondition
  },
  {
    path: ROUTE_SIGNIN,
    name: "Signin",
    component: Signin,
    beforeEnter: loggedOutGuard
  },
  {
    path: ROUTE_REGISTER,
    name: "Register",
    component: Register,
    beforeEnter: loggedOutGuard
  },
  {
    path: ROUTE_CREATE_ACCOUNT,
    name: "Create Account",
    component: CreateAccount,
    beforeEnter: loggedOutGuard
  },
  {
    path: ROUTE_OFFSITE_REGISTER,
    name: "Offsite Registration",
    component: OffsiteRegistration,
    beforeEnter: loggedOutGuard
  },
  {
    path: ROUTE_OFFSITE_REGISTER,
    name: "Offsite Primary Member",
    component: OffsitePrimaryMember,
    beforeEnter: loggedOutGuard
  },
  {
    path: ROUTE_LOADING,
    name: "Loading",
    component: Loading,
    beforeEnter: loggedOutGuard
  },
  {
    path: ROUTE_FORGOTPASSWORD,
    name: "Forgot Password",
    component: ForgotPassword,
    beforeEnter: loggedOutGuard
  },

  {
    path: ROUTE_PROFILE,
    name: "Profile",
    component: Profile,
    beforeEnter: guard
  },
  {
    path: ROUTE_DASHBOARD,
    name: "Dashboard",
    component: Menu,
    beforeEnter: guard
  },
  {
    path: ROUTE_DOCTOR,
    name: "Doctor",
    component: Doctor,
    beforeEnter: guard
  },
  {
    path: ROUTE_DOCTOR_APPOINTMENT,
    name: "DoctorAppointment",
    component: DoctorAppointment,
    beforeEnter: guard
  },
  {
    path: ROUTE_DOCTOR_TELECONFERENCE,
    name: "DoctorTeleconference",
    component: DoctorTeleconference,
    beforeEnter: guard
  },
  {
    path: ROUTE_INBOX,
    name: "Inbox",
    component: Messages,
    beforeEnter: guard
  },
  {
    path: ROUTE_APPOINTMENTS,
    name: "Appointments",
    component: Appointments,
    beforeEnter: guard
  },
  {
    path: "/medicalrecords",
    name: "Medical Records",
    component: MedicalRecords,
    beforeEnter: guard
  },
  {
    path: ROUTE_MEDICALHISTORY,
    name: "Medical History",
    component: MedicalHistory,
    beforeEnter: guard
  },

  {
    path: `${ROUTE_INSURANCE}/:inputTab`,
    name: "e-Insurance ",
    component: Insurance,
    props: true,
    beforeEnter: guard
  },
  {
    path: ROUTE_INSURANCE,
    name: "e-Insurance",
    component: Insurance,
    beforeEnter: guard
  },
  {
    path: ROUTE_STORES,
    name: "e-Tindahan (Beta)",
    component: Stores
  },
  {
    path: `${ROUTE_STORES}/:openCart`,
    name: "e-Tindahan ",
    component: Stores,
    props: true,
    beforeEnter: guard
  },
  {
    path: ROUTE_BOTIKA,
    name: "e-Botika",
    component: Botika,
    beforeEnter: guard
  },

  {
    path: ROUTE_VALIDATE,
    name: "Validate",
    component: Validate,
    beforeEnter: guard
  },
  {
    path: "/viewproduct",
    name: "View Product",
    component: ViewProduct,
    beforeEnter: guard
  },
  {
    path: ROUTE_LANDING,
    name: "Landing",
    component: Landing
  },
  {
    path: ROUTE_ORDERHISTORY,
    name: "OrderHistory",
    component: OrderHistory,
    beforeEnter: guard
  },
  {
    path: ROUTE_BHC,
    name: "BHC",
    component: BHC,
    beforeEnter: guard
  },
  {
    path: `${ROUTE_BHC}/:inputTab/:messageIndex`,
    component: BHC,
    props: true,
    beforeEnter: guard
  },
  {
    path: ROUTE_LABORATORY,
    name: "e-Laboratory",
    component: Laboratory,
    beforeEnter: guard
  },
  {
    path: `${ROUTE_LABORATORY}/:inputTab/:appointmentId`,
    component: Laboratory,
    beforeEnter: guard
  },
  {
    path: ROUTE_PAYMENT,
    name: "Payment",
    component: Payment,
    beforeEnter: guard
  },
  {
    path: ROUTE_ABOUTUS,
    name: "AboutUs",
    component: AboutUs
  },

  {
    path: ROUTE_ABOUT,
    name: "About",
    component: About
  },

  {
    path: "/lguServices",
    name: "LGU Services",
    component: LGUServices,
    beforeEnter: guard
  },
  {
    path: "/covidVaccine",
    name: "Covid Vaccine",
    component: CovidVaccine,
    beforeEnter: guard
  },
  {
    path: ROUTE_PHILHEALTH,
    name: "Register for PhilHealth Konsulta",
    component: PhilHealthRegister,
    beforeEnter: guard
  },
  {
    path: ROUTE_ADD_BANTAYKARD_POINTS,
    name: "Add Bantay Kard Points",
    component: AddBantayKardPoints,
    beforeEnter: guard
  },
  {
    path: ROUTE_TRACKORDER,
    name: "Track Order",
    component: TrackOrder,
    beforeEnter: guard
  },
  {
    path: ROUTE_CART,
    name: "Cart",
    component: Cart
  },
  {
    path: ROUTE_CHECKOUT,
    name: "Checkout",
    component: Checkout,
    beforeEnter: guard
  },
  {
    path: `${ROUTE_CHECKOUT}/:addressSelect`,
    component: Checkout,
    props: true,
    beforeEnter: guard
  },
  {
    path: ROUTE_TRACK,
    name: "Track",
    component: Track,
    beforeEnter: guard
  },
  {
    path: ROUTE_EDIT_CARTITEM,
    name: "Edit Cart Item",
    component: EditCartItem,
    beforeEnter: guard
  },
  {
    path: ROUTE_PRODUCTDETAILS,
    name: "Product Details",
    component: ProductDetails
  },
  {
    path: ROUTE_SUMMARY_ORDER,
    name: "Order Summary",
    component: SummaryOrder,
    beforeEnter: guard
  },
  {
    path: ROUTE_RXPRESCRIPTION,
    name: "Buy Prescription Drugs",
    component: RxPrescription,
    beforeEnter: guard
  },
  {
    path: ROUTE_CONTACTTRACING,
    name: "Contact Tracing Form",
    component: ContactTracingForm,
    beforeEnter: guard
  },
  {
    path: ROUTE_SUMMARY,
    name: "Summary",
    component: Summary,
    beforeEnter: guard
  },
  {
    path: ROUTE_HEALTHPROFILE,
    name: "Health Profile",
    component: HealthProfile,
    beforeEnter: guard
  },
  {
    path: ROUTE_CAPTURE,
    name: "Capture",
    component: Capture,
    beforeEnter: guard
  },
  {
    path: ROUTE_WISHLISTS,
    name: "Wishlists",
    component: Wishlists,
    beforeEnter: guard
  },
  {
    path: ROUTE_ADDRESS_BOOK,
    name: "Address Book",
    component: AddressBook,
    beforeEnter: guard
  },
  {
    path: `${ROUTE_ADDRESS_BOOK}/:mode`,
    name: "Address Book ",
    component: AddressBook,
    props: true,
    beforeEnter: guard
  },
  {
    path: ROUTE_ADDRESS_INPUT,
    name: "Address Input",
    component: AddressInput,
    beforeEnter: guard
  },
  {
    path: `${ROUTE_ADDRESS_INPUT}/:mode`,
    name: "Address Input ",
    component: AddressInput,
    props: true,
    beforeEnter: guard
  },
  {
    path: ROUTE_SEARCHED_PRODUCTS,
    name: "Searched Products",
    component: SearchedProducts,
    beforeEnter: guard
  },
  {
    path: ROUTE_REFUND_EXCESS,
    name: "Refund Excess Payment",
    component: RefundExcess,
    beforeEnter: guard
  },
  {
    path: ROUTE_RETURN_REFUND_INPUT,
    name: "Return Refund Input",
    component: ReturnRefundInput,
    beforeEnter: guard
  },

  ...offsiteRoutes(guard),
  ...walkinRoutes(guard),
  ...resultRoutes(guard),
  ...isdaRoutes()
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export function routeBack() {
  router.go(-1);
}

export function routeTo(path) {
  if (router.currentRoute.fullPath !== path) {
    router.push(path);
  }
}

export default router;

export function toSubMenu(path) {
  if (eTindahanSubpages.includes(path)) {
    routeTo(ROUTE_STORES);
  }
}
