import { DB, getCurrentUserUid } from "@/common/store";

import { db } from "@/main";

import firebase from "firebase/app";

export function buildTemporaryDetails(details) {
  let productDetails = details.productDetails;
  let productOptions = details.options;

  return {
    soldTo: getCurrentUserUid(),

    productSKU: productDetails.docid,
    owner: productDetails.owner || "",
    selectedItemrating: productDetails.selectedItemrating,
    selectedItemsold: productDetails.selectedItemsold,
    name: productDetails.name,
    description: productDetails.description,
    imageUrl: productDetails.downloadUrl,
    category: productDetails.category,

    isPulled: productDetails.isPulled,

    priceRange: productDetails.priceRange,

    inventory: productDetails.inventory,

    preOrderFrom: productDetails.preOrderFrom || "",
    preOrderTo: productDetails.preOrderTo || "",
    itemShippingFee: productDetails.itemShippingFee || 0,
    merchantName: productDetails.merchantName || "",
    variation: productDetails.variation || "",
    pwdSc: productDetails.pwdSc,

    variantoption: productOptions.variantoption,
    variantname: productOptions.variantname,
    hasVariant: productOptions.hasVariant,
    quantity: productOptions.quantity,
    vproductSKU: productOptions.vproductSKU,

    price: productOptions.price,
    discount: productOptions.discount,
    discountUntil: productOptions.discountUntil,
    promotionalDiscount: productOptions.promotionalDiscount,
    promotionalPrice: productOptions.promotionalPrice,
    isPromotionalPriceApplied: productOptions.isPromotionalPriceApplied,
    isPWDSCPriceApplied: productOptions.isPWDSCPriceApplied,
    originalPrice: productOptions.originalPrice
  };
}

export function setAddressBook(addressBook) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PROFILES)
      .doc(getCurrentUserUid())
      .set({ addressBook }, { merge: true })
      .then(() => resolve(addressBook))
      .catch(err => reject(err));
  });
}

export function updateAddressBook(addressDetails, index) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PROFILES)
      .doc(getCurrentUserUid())
      .set({ addressBook: { [index]: { ...addressDetails } } }, { merge: true })
      .then(() => resolve(addressDetails))
      .catch(err => reject(err));
  });
}

export function deleteAddressBook(index) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PROFILES)
      .doc(getCurrentUserUid())
      .set(
        {
          addressBook: {
            [index]: firebase.firestore.FieldValue.delete()
          }
        },
        { merge: true }
      )
      .then(() => resolve(index))
      .catch(err => reject(err));
  });
}

export function loadProfileAddress() {
  return new Promise((resolve, reject) => {
    db.collection(DB.PROFILES)
      .doc(getCurrentUserUid())
      .get()
      .then(doc => resolve(doc.data()))
      .catch(err => reject(err));
  });
}
