<template>
  <v-container fluid class="productcontainer">
    <h3 class="text-center">View Products</h3>

    <ViewProductContainer />

    <BackButton @click="routeBack()" />
  </v-container>
</template>

<script>
import { routeBack } from "@/router";

import BackButton from "@/components/Controls/BackButton.vue";
import ViewProductContainer from "@/eTindahan/components/ViewProductContainer.vue";

export default {
  components: {
    BackButton,
    ViewProductContainer
  },

  data: () => ({
    // functions
    routeBack: routeBack
  })
};
</script>

<style scoped>
.productcontainer {
  max-width: 1250px;
}
</style>
