<template>
  <v-container fluid class="main-container">
    <v-stepper v-model="step" flat>
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Personal Information
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2">
          Identification
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 3" step="3">
          Confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form
            @submit.prevent
            class="mx-10"
            ref="form1"
            v-model="valid1"
            :lazy-validation="false"
          >
            <v-row>
              <v-col cols="12">
                <h4>Personal Information</h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-n4" cols="12" sm="4">
                <v-text-field
                  v-model="firstname"
                  label="First Name"
                  :rules="firstNameRules"
                />
              </v-col>
              <v-col class="mt-n4" cols="12" sm="4">
                <v-text-field
                  v-model="middlename"
                  label="Middle Name"
                  :rules="middleNameRules"
                />
              </v-col>
              <v-col class="mt-n4" cols="12" sm="4">
                <v-text-field
                  v-model="lastname"
                  label="Last Name"
                  :rules="lastNameRules"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-n4" cols="12" sm="4">
                <v-autocomplete
                  v-model="sex"
                  label="Sex"
                  :items="sexChoices"
                  :rules="sexRules"
                />
              </v-col>
              <v-col class="mt-n3 " cols="12" sm="4">
                <v-text-field
                  v-model="birthdate"
                  label="Birthdate"
                  hint="Sample Format: 2010-01-30"
                />
              </v-col>

              <v-col class="mt-n4" cols="12" sm="4">
                <v-text-field
                  v-if="isSenior"
                  v-model="seniorID"
                  label="Senior Citizen ID no."
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-n4" cols="12" sm="4">
                <v-text-field
                  v-model="contact"
                  label="Contact Number"
                  :rules="contactRules"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-n4" cols="12" sm="4">
                <v-checkbox
                  class="floaterl primary--text"
                  v-model="isPWD"
                  :value="true"
                  label="PWD?"
                />
              </v-col>
            </v-row>

            <v-row v-if="isPWD">
              <v-img
                max-width="200"
                :contain="true"
                :src="downloadUrlPWD"
                height="200"
                class="upload-container"
              />
            </v-row>

            <v-row v-if="isPWD">
              <v-col cols="12" style="text-align: center;">
                <div class="upload-container">
                  <v-btn
                    color="primary"
                    @click.stop="dialogPWD = true"
                    type="submit"
                  >
                    Upload
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col cols="12" style="text-align: right;">
                <v-btn
                  color="primary"
                  @click="validate1()"
                  :disabled="!valid1"
                  type="submit"
                >
                  Next
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form
            @submit.prevent
            class="mx-10"
            ref="form3"
            v-model="valid3"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <h4>Identification</h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-n4" cols="12" sm="4">
                <v-autocomplete
                  v-model="idtype"
                  name="idtype"
                  :items="idTypes"
                  label="ID Type"
                  :rules="idTypeRules"
                />
              </v-col>

              <v-col class="mt-n4" cols="12" sm="4">
                <v-text-field
                  v-model="idnumber"
                  label="ID Number"
                  :rules="idNumberRules"
                />
              </v-col>

              <v-col class="mt-n4" cols="12" sm="4">
                <v-text-field
                  v-model="expirationadate"
                  label="Expiration Date (optional)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <p>
                  Tip: Make sure the image is clear and the whole card is
                  visible.
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-img
                max-width="200"
                :contain="true"
                :src="downloadUrl"
                height="200"
                class="upload-container"
              />
            </v-row>

            <v-row>
              <v-col cols="12" style="text-align: center;">
                <div class="upload-container">
                  <v-btn
                    color="primary"
                    @click.stop="dialog = true"
                    type="submit"
                  >
                    Upload
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="4">
                <v-btn color="primary" @click="step = 2">Back</v-btn>
              </v-col>
              <v-col cols="4"></v-col>
              <v-col cols="4" style="text-align: right;">
                <v-btn
                  color="primary"
                  @click="onSubmit"
                  :disabled="!valid3 || !downloadUrl.length"
                  type="submit"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col class="pt-8">
              <p>
                Your details have been submitted for confirmation. It normally
                takes 12 to 24 hours to complete this process.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="text-align: right;">
              <v-btn color="primary" @click="onNext" type="submit">Done</v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="dialog">
      <v-row align="center" justify="center">
        <UploadPhoto v-on:upload-photo="uploadPhoto" :forID="true" />
      </v-row>
    </v-dialog>

    <v-dialog v-model="dialogPWD">
      <v-row align="center" justify="center">
        <UploadPhoto v-on:upload-photo="uploadPhotoPWD" :forID="true" />
      </v-row>
    </v-dialog>
  </v-container>
</template>

<script>
import { STATUS_REQUESTED } from "@/common/utilities/validation";

import { debugLog } from "@/common/main";
import {
  loadUserValidationData,
  SetUserValidationData
} from "@/store/user_validation";
import { setIdPhotoUrl } from "@/store";

import UploadPhoto from "@/components/Controls/UploadPhoto.vue";

export default {
  name: "Validate",

  components: {
    UploadPhoto
  },

  props: {
    target: String
  },

  data: () => ({
    componentKey: 0,
    valid1: false,
    dialog: false,
    dialogPWD: false,
    isPWD: false,
    isSenior: false,
    seniorID: "",
    age: "",
    firstname: "",
    firstNameRules: [n => !!n || "First Name is required"],

    middlename: "",
    middleNameRules: [m => !!m || "Middle Name is required"],

    lastname: "",
    lastNameRules: [l => !!l || "Last Name is required"],

    birthdate: "",
    birthDateRules: [b => !!b || "Birth Date is required"],

    sex: "",
    sexChoices: ["Male", "Female"],

    contact: "",
    contactRules: [c => !!c || "Contact Number is required"],

    valid3: false,

    idtype: "",
    idTypes: [
      "Driver's License",
      "Office ID (with SSS details)",
      "Passport",
      "Postal ID (issued 2015 onwards)",
      "PRC ID",
      "SSS ID (digitized, with photo)",
      "TIN ID",
      "UMID",
      "Voter's ID"
    ],

    idnumber: "",
    idNumberRules: [z => !!z || "ID Number is required"],

    expirationadate: "",

    downloadUrl: "",
    idPhoto: null,

    downloadUrlPWD: "",
    idPhotoPWD: null,

    step: 1,
    date: null,
    menu: false,
    activePicker: null
  }),

  computed: {
    idTypeRules() {
      return [
        i => !!i || "ID Type is required",
        i => this.idTypes.includes(i) || "ID Type is required"
      ];
    },

    sexRules() {
      return [
        s => !!s || "Sex is required",
        s => this.sexChoices.includes(s) || "Sex is required"
      ];
    }
  },

  mounted() {
    this.loadUserData();
  },

  methods: {
    loadUserData() {
      loadUserValidationData(this.target).then(data => {
        this.loadUserData1(data);
        this.loadUserData3(data);
      });
    },

    loadUserData1(data) {
      this.firstname = data.firstname;
      this.middlename = data.middlename;
      this.lastname = data.lastname;
      this.contact = data.contact;
      this.birthdate = data.birthdate;
      this.sex = data.sex;
      this.isPWD = data.isPWD;
      this.isSenior = data.isSenior;
      this.seniorID = data.seniorID;
      this.componentKey += 1;
    },

    loadUserData3(data) {
      this.idtype = data.idtype;
      this.idnumber = data.idnumber;
      this.expirationadate = data.expirationadate;
      this.downloadUrl = data.idUrl;
      this.downloadUrlPWD = data.idUrlPWD;
    },

    onNext() {
      if (this.target) {
        this.$emit("validationSubmitted");
      } else {
        this.$router.replace({ name: "Profile" }).catch(err => {
          debugLog(err);
        });
      }
    },

    onSubmit() {
      this.$store.dispatch("resetSecondsIdle").then(() => {
        if (this.updateUserValidationData()) {
          this.$store.dispatch("updateVerificationRequest", this.target);

          if (!this.target) {
            this.$store.dispatch("loadProfile");
          }

          this.step = 3;
        }
      });
    },

    sanitizeText(text) {
      return text ? text : "";
    },

    save(date) {
      this.$refs.menu.save(date);
    },

    submit() {
      this.$refs.form.validate();
    },

    updateUserValidationData() {
      if (this.$refs.form3.validate()) {
        SetUserValidationData({
          firstname: this.firstname,
          middlename: this.middlename,
          lastname: this.lastname,
          birthdate: this.birthdate,
          contact: this.contact,
          sex: this.sex,

          idtype: this.idtype,
          idnumber: this.idnumber,
          expirationadate: this.sanitizeText(this.expirationadate),
          idUrl: this.downloadUrl,

          requestedOn: new Date(),
          verificationStatus: STATUS_REQUESTED,

          isPWD: this.isPWD ? this.isPWD : false,
          isSenior: this.isSenior ? this.isSenior : false,
          seniorID: this.seniorID ? this.seniorID : "",
          target: this.target
        });

        return true;
      }

      return false;
    },

    async uploadPhoto(file) {
      this.idPhoto = file;
      setIdPhotoUrl(file, this.target).then(url => {
        this.downloadUrl = url;
        this.dialog = false;
      });
    },
    async uploadPhotoPWD(file) {
      this.idPhotoPWD = file;
      this.$store.dispatch("setIdPhotoUrlPWD", file).then(url => {
        this.downloadUrlPWD = url;
        this.dialogPWD = false;
      });
    },
    validate1() {
      if (this.$refs.form1.validate()) {
        this.step = 2;
      }
    }
  },

  watch: {
    birthdate() {
      var today = new Date();
      var birthDate = new Date(this.birthdate);
      this.age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        this.age--;
      }
      this.isSenior = this.age >= 60;
    },

    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },

    selectedLocation(location) {
      if (!this.target) {
        this.$store.dispatch("saveProfileLastSelectedLocation", location);
      }
    }
  }
};
</script>

<style scoped>
.upload-container {
  margin: auto;
  width: 200px;
}
</style>
