<template>
  <div>
    <v-row>
      <v-col>
        <strong>Patient:</strong> {{ name }} ({{ sex }}{{ isPWD }})
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" md="8">
        <strong>Birthday:</strong> {{ data["birthday"] }} ({{ age }} years old)
      </v-col>
      <v-col cols="6" md="4">
        <span v-if="age > 60 || isPWD">
          <strong>Senior Citizen / PWD ID:</strong> {{ data["seniorOrPwdId"] }}
        </span>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" md="8">
        <strong>Attending Physician:</strong>
        {{ data["attendingPhysician"] || "None" }}
      </v-col>
    </v-row>

    <v-row class="mt-0" align="center" justify="center">
      <v-btn
        @click="$emit('triggerUpdate')"
        color="primary"
        x-small
        rounded
        tile
      >
        Update Details
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { getAge } from "@/utilities/profile";
import { getFullName } from "@/common/utilities/profile";
import { valueOrEmptyString } from "@/common/utilities/text";

export default {
  name: "PatientInfo",

  props: {
    data: Object
  },

  computed: {
    age() {
      return getAge(new Date(this.data.birthday));
    },

    isPWD() {
      return this.data.isPWD ? " - PWD" : "";
    },

    name() {
      return getFullName(this.data);
    },

    sex() {
      return valueOrEmptyString(this.data.sex);
    }
  }
};
</script>
