<template>
  <div class="pa-0">
    <v-card flat class="d-flex justify-center">
      <v-radio-group v-model="selectedRefundOption" row>
        <v-radio :label="OPTION_BANTAY_KARD" :value="OPTION_BANTAY_KARD" />

        <v-radio
          v-show="Math.abs(getTotalRefund()) >= Number(bankCharge)"
          :label="
            `${OPTION_BANK_ACCOUNT} (less P${Number(bankCharge)} bank charge)`
          "
          :value="OPTION_BANK_ACCOUNT"
        />
      </v-radio-group>
    </v-card>

    <p class="text-center">
      <PesoAmount :amount="getTotalRefund()" />
    </p>

    <v-card
      v-show="OPTION_BANK_ACCOUNT === selectedRefundOption"
      flat
      class="mx-auto"
      max-width="344"
    >
      <v-autocomplete
        v-model="accountBank"
        :items="banks"
        dense
        filled
        label="Bank"
      />
      <v-text-field
        v-model="accountNumber"
        label="Account Number"
        :rules="fieldRules"
      />
      <v-text-field
        v-model="accountName"
        label="Account Name"
        :rules="fieldRules"
      />
      <v-text-field v-model="contact" label="Contact" :rules="fieldRules" />
    </v-card>
  </div>
</template>

<script>
import {
  OPTION_BANTAY_KARD,
  OPTION_BANK_ACCOUNT
} from "@/common/utilities/payment.js";

import { banks } from "@/eTindahan/utilities/banks.js";
import { loadBankCharge } from "@/common/store";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "RefundPaymentOptions",

  components: {
    PesoAmount
  },

  props: {
    balance: Number,
    latestReturnRefund: Object
  },

  data: () => ({
    accountBank: "",
    accountName: "",
    accountNumber: "",
    bankCharge: 0,
    banks: banks.sort(),
    contact: "",
    fieldRules: [v => !!v || "field required"],
    selectedRefundOption: "",

    OPTION_BANTAY_KARD: OPTION_BANTAY_KARD,
    OPTION_BANK_ACCOUNT: OPTION_BANK_ACCOUNT
  }),

  mounted() {
    if (this.latestReturnRefund) {
      this.selectedRefundOption = this.latestReturnRefund.selectedRefundOption;
      this.accountBank = this.latestReturnRefund.accountBank;
      this.accountNumber = this.latestReturnRefund.accountNumber;
      this.accountName = this.latestReturnRefund.accountName;
      this.contact = this.latestReturnRefund.contact;
    }

    this.loadBankCharge();
  },

  watch: {
    accountBank(value) {
      this.$emit("selected-accountBank", value);
      this.$emit("isValid", this.isValid());
    },

    accountName(value) {
      this.$emit("selected-accountName", value);
      this.$emit("isValid", this.isValid());
    },

    accountNumber(value) {
      this.$emit("selected-accountNumber", value);
      this.$emit("isValid", this.isValid());
    },

    contact(value) {
      this.$emit("selected-contact", value);
      this.$emit("isValid", this.isValid());
    },

    selectedRefundOption(value) {
      this.$emit("selected-refundOption", value);
      this.$emit("isValid", this.isValid());
    }
  },

  methods: {
    isValid() {
      return OPTION_BANK_ACCOUNT === this.selectedRefundOption
        ? this.accountBank !== "" &&
            this.accountNumber !== "" &&
            this.accountName !== "" &&
            this.contact !== ""
        : true;
    },

    getTotalRefund() {
      return OPTION_BANK_ACCOUNT === this.selectedRefundOption
        ? this.balance - Number(this.bankCharge)
        : this.balance;
    },

    loadBankCharge() {
      loadBankCharge().then(docs => {
        this.bankCharge = docs.bankCharge;
      });
    }
  }
};
</script>
