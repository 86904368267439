<template>
  <div
    :class="{
      'px-5': $vuetify.breakpoint.mobile,
      'px-15': !$vuetify.breakpoint.mobile
    }"
  >
    <h3 class="mb-15 mt-10 text-center">Walk-in Registration Recorded</h3>

    <p class="text-center">
      Natanggap na po namin ang inyong <strong>Walk-in Registration</strong>.
      <br />
      Maaari na po kayong tumuloy sa opisina upang magpasuri.
    </p>

    <p class="mt-10 text-center">
      Maraming salamat po!
    </p>

    <NextButton
      @click="routeTo(ROUTE_DASHBOARD)"
      text="Return Home"
      class="mb-5 mt-15"
    />
  </div>
</template>

<script>
import { ROUTE_DASHBOARD, routeTo } from "@/router";

import NextButton from "@/components/Controls/NextButton.vue";

export default {
  name: "Completed",

  components: {
    NextButton
  },

  data: () => ({
    ROUTE_DASHBOARD: ROUTE_DASHBOARD,

    // functions
    routeTo: routeTo
  })
};
</script>
