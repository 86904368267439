import { familyHistory } from "./family_history";
import { medicalHistory } from "./medical_history";
import { personalSocialHistory } from "./personal_social_history";
import { vitalSigns } from "./vital_signs";

export const healthRecords = {
  modules: {
    familyHistory: {
      namespaced: true,
      ...familyHistory
    },

    medicalHistory: {
      namespaced: true,
      ...medicalHistory
    },

    personalSocialHistory: {
      namespaced: true,
      ...personalSocialHistory
    },

    vitalSigns: {
      namespaced: true,
      ...vitalSigns
    }
  },

  state: () => ({
    step: 0
  }),

  getters: {
    getStep(state) {
      return state.step;
    }
  },

  mutations: {
    SET_STEP(state, value) {
      state.step = value;
    }
  },

  actions: {
    resetContent({ dispatch }) {
      dispatch("familyHistory/resetContent");
      dispatch("medicalHistory/resetContent");
      dispatch("personalSocialHistory/resetContent");
      dispatch("vitalSigns/resetContent");
    },

    setStep({ commit }, value) {
      return new Promise(resolve => {
        commit("SET_STEP", value);
        resolve();
      });
    }
  }
};
