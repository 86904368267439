<template>
  <v-card align="center" flat>
    <p v-if="id === 'Patient 0'" class="text-center">
      <strong>Primary</strong>
    </p>
    <p v-else class="text-center">
      <strong>Member</strong>
      <v-btn text small fab color="error" @click="removeRegistrant(id)">
        x
      </v-btn>
    </p>

    <v-row>
      <v-col class="py-0" sm="4" cols="12">
        <v-text-field
          v-model="firstName"
          :rules="[f => !!f || 'First name is required']"
          label="First Name"
          required
          @input="assignDetail(FIRST_NAME, firstName)"
        />
      </v-col>
      <v-col class="py-0" sm="4" cols="12">
        <v-text-field
          v-model="middleName"
          :rules="[l => !!l || 'Last name is required']"
          label="Middle Name"
          required
          @input="assignDetail(MIDDLE_NAME, middleName)"
        />
      </v-col>
      <v-col class="py-0" sm="4" cols="12">
        <v-text-field
          v-model="lastName"
          :rules="[l => !!l || 'Last name is required']"
          label="Last Name"
          required
          @input="assignDetail(LAST_NAME, lastName)"
        />
      </v-col>
      <v-col class="py-0" sm="4" cols="12">
        <v-select
          :items="['Male', 'Female']"
          label="Gender"
          v-model="gender"
          :rules="[l => !!l || 'Gender is required']"
          @input="assignDetail(GENDER, gender)"
        />
      </v-col>

      <v-col class="py-0" sm="3" cols="12">
        <SimpleDatePicker text="Date Taken" v-model="birthDate" />
      </v-col>

      <v-col class="py-0" sm="3" cols="12">
        <v-select
          :items="['Primary', 'Dependent']"
          label="Type"
          v-model="type"
          :rules="[l => !!l || 'Type is required']"
          @input="assignDetail(TYPE, type)"
        />
      </v-col>
      <v-col class="py-0" sm="2" cols="12">
        <v-checkbox
          v-model="isFree"
          label="Free Konsulta"
          @change="assignDetail(IS_FREE, isFree)"
        />
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import SimpleDatePicker from "@/common/components/controls/SimpleDatePicker.vue";

const FIRST_NAME = "firstName";
const MIDDLE_NAME = "middleName";
const LAST_NAME = "lastName";
const BIRTH_DATE = "birthDate";
const GENDER = "gender";
const CONTACT_NUMBER = "contactNumber";
const EMAIL = "email";
const TYPE = "type";
const IS_FREE = "isFree";

export default {
  name: "OffsiteRegistrants",

  components: {
    ConfirmDialog,
    SimpleDatePicker
  },

  props: {
    id: String,
    registrants: Object
  },

  data: () => ({
    firstName: "",
    middleName: "",
    lastName: "",

    isFree: false,
    birthDate: "",
    gender: "",
    type: "",

    FIRST_NAME: FIRST_NAME,
    MIDDLE_NAME: MIDDLE_NAME,
    LAST_NAME: LAST_NAME,
    BIRTH_DATE: BIRTH_DATE,
    GENDER: GENDER,
    CONTACT_NUMBER: CONTACT_NUMBER,
    EMAIL: EMAIL,
    TYPE: TYPE,
    IS_FREE: IS_FREE
  }),

  watch: {
    birthDate(value) {
      this.assignDetail(BIRTH_DATE, value);
    }
  },

  methods: {
    assignDetail(value, input) {
      this.registrants[this.id][value] = input;
    },

    async removeRegistrant(id) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to remove the member?",
          { noconfirm: false }
        )
      ) {
        delete this.registrants[id];
        this.$emit("removeRegistrant", this.registrants);
      }
    }
  }
};
</script>
