export function emptyBooleanText() {
  return {
    value: false,
    comment: "",
    lastUpdate: ""
  };
}

export function emptyText() {
  return {
    value: "",
    lastUpdate: ""
  };
}

export function emptyTextText() {
  return {
    value: "",
    comment: "",
    lastUpdate: ""
  };
}

export function getTextText(data) {
  return {
    value: data.value,
    comment: data.comment,
    lastUpdate: data.lastUpdate
  };
}
