<template>
  <v-container>
    <Header />
    <h1 class="text-center fontLarge colored py-8">
      Payment Cancelled
    </h1>

    <p class="fontMedium py-8">
      Please click <a href="https://medisureonline.com/isda">here</a> to be
      redirected to our sponsorship page and re-enter your sponsor details once
      more. Or please check with your card provider... Thank You.
    </p>
    <Footer />
  </v-container>
</template>

<script>
import Header from "@/ISDA/components/Header.vue";
import Footer from "@/ISDA/components/Footer.vue";
export default {
  name: "PaymentCancelled",
  components: {
    Header,
    Footer
  },

  data: () => ({
    orderReference: ""
  }),

  mounted() {
    this.orderReference = this.$route.query.Ref;
  }
};
</script>
<style scoped>
.wrap {
  margin: auto;
}
.select-container >>> input {
  text-align: center;
}
.colored {
  color: #283d19;
}

p {
  font-family: "Avenir", Helvetica, sans-serif;
}

.fontLarge {
  font-size: 60px;
}
.fontMedium {
  font-size: 22px;
}
</style>
