<template>
  <v-row>
    <v-col
      :class="{ 'pl-15': !$vuetify.breakpoint.mobile }"
      v-if="highestShippingFee <= 0"
    >
      <v-card flat>
        <v-card-title class="subtitle-2">
          <v-icon class="px-1" color="primary"> mdi-truck-fast-outline</v-icon>
          Shipping fee
        </v-card-title>
        <v-card-subtitle class="caption">
          * {{ FREE_SHIPPING_PROMO }}
        </v-card-subtitle>
      </v-card>
    </v-col>

    <v-col :class="{ 'pl-15': !$vuetify.breakpoint.mobile }" v-else>
      <v-card flat>
        <v-card-title class="subtitle-2">
          <v-icon class="px-1" color="primary"> mdi-truck-fast-outline </v-icon>
          Shipping fee
        </v-card-title>
        <v-card-subtitle class="caption">
          {{ STANDARD_SHIPPING }}
        </v-card-subtitle>
      </v-card>
    </v-col>

    <v-col v-if="hasPromotionalActive">
      <v-card flat>
        <v-card-title class="subtitle-2">
          <v-icon class="px-1" color="primary">
            mdi-tag-multiple-outline
          </v-icon>
          Promotion
        </v-card-title>
        <v-card-subtitle class="caption">* {{ PROMO }}</v-card-subtitle>
      </v-card>
    </v-col>

    <v-col v-else>
      <v-card flat>
        <v-card-title class="subtitle-2">
          <v-icon class="px-1" color="primary">
            mdi-tag-multiple-outline
          </v-icon>
          Promotion
        </v-card-title>
        <v-card-subtitle class="caption">* {{ STANDARD }}</v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EventDisplay",

  props: {
    hasPromotionalActive: Boolean,
    highestShippingFee: Number
  },

  data: () => ({
    FREE_SHIPPING_PROMO: "Eligible for FREE STANDARD SHIPPING!",
    STANDARD_SHIPPING: "Free standard shipping on orders over ₱300.",
    PROMO:
      "Eligible for promotions. Checkout the item before the promo expires!",
    STANDARD: "Promotion available on selected items."
  })
};
</script>
