import { DB, getCurrentUser, getCurrentUserUid } from "@/common/store";

import { db } from "@/main";

// document fields
const CANCELLED_AT = "cancelledAt";
const CART_STATUS = "cartStatus";
const CATEGORY = "category";
const COMMENT = "comment";
const DESCRIPTION = "description";
const DISCOUNT = "discount";
const DISCOUNT_UNTIL = "discountUntil";
const FULL_QR = "fullqr";
const HAS_VARIANT = "hasVariant";
const ID = "id";
const IS_PROMOTIONAL_PRICE_APPLIED = "isPromotionalPriceApplied";
const IS_PULLED = "isPulled";
const IS_PWDSC_PRICE_APPLIED = "isPWDSCPriceApplied";
const IMAGE_URL = "imageUrl";
const ITEM_SHIPPING_FEE = "itemShippingFee";
const MERCHANT_NAME = "merchantName";
const NAME = "name";
const ORDER_ID = "orderId";
const ORIGINAL_PRICE = "originalPrice";
const OWNER = "owner";
const PHOTO_URL = "photoUrl";
const PREORDER_FROM = "preOrderFrom";
const PREORDER_TO = "preOrderTo";
const PRICE = "price";
const PRODUCT_SKU = "productSKU";
const PROMO_AVAILED = "promoAvailed";
const PROMOTIONAL_DISCOUNT = "promotionalDiscount";
const PROMOTIONAL_PRICE = "promotionalPrice";
const PWDSC = "pwdSc";
const QUANTITY = "quantity";
const RATED_AT = "ratedAt";
const RATED_BY = "ratedBy";
const RATER_IMAGE = "raterImage";
const RATING = "rating";
const REFUND_AMOUNT = "refundAmount";
const SERIAL_NUMBER = "serialNumber";
const SOLD_ON = "soldOn";
const SOLD_TO = "soldTo";
const VARIANT_NAME = "variantname";
const VARIATION = "variation";
const VPRODUCT_SKU = "vproductSKU";

const CART_STATUS__ON_CART = "oncart";
const CART_STATUS__SOLD = "sold";

export const TEMPORARY_CART = "temporaryCart";
export const TEMPORARY_WISHLIST = "temporaryWishlist";

function constructCartDetails(details) {
  return {
    // these are items from catalog
    [PRODUCT_SKU]: details.productSKU,
    [NAME]: details.name,
    [DESCRIPTION]: details.description,
    [IMAGE_URL]: details.imageUrl,
    [CATEGORY]: details.category,

    [PREORDER_FROM]: details.preOrderFrom,
    [PREORDER_TO]: details.preOrderTo,

    [OWNER]: details.owner, // what is difference with merchantName
    [MERCHANT_NAME]: details.merchantName,

    [IS_PULLED]: true, // ?
    [DISCOUNT_UNTIL]: details.discountUntil, // ?

    [ITEM_SHIPPING_FEE]: details.itemShippingFee,

    // these items are from catalog if item is not yet sold but can be
    // updated during actual sale transaction
    [CART_STATUS]: CART_STATUS__ON_CART,
    [PRICE]: details.price,
    [ORIGINAL_PRICE]: details.originalPrice,
    [DISCOUNT]: details.discount,
    [PROMOTIONAL_DISCOUNT]: details.promotionalDiscount,
    [PROMOTIONAL_PRICE]: details.promotionalPrice,
    [IS_PROMOTIONAL_PRICE_APPLIED]: details.isPromotionalPriceApplied,
    [IS_PWDSC_PRICE_APPLIED]: details.isPWDSCPriceApplied,
    [QUANTITY]: details.quantity,

    [HAS_VARIANT]: details.hasVariant, // ?
    [VARIATION]: details.variation, // ?
    [VARIANT_NAME]: details.variantname, // ?
    [VPRODUCT_SKU]: details.vproductSKU, // ?

    [SOLD_TO]: details.soldTo,
    [SOLD_ON]: new Date(),
    [PWDSC]: details.pwdSc
  };
}

export function addToCart(details) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .add(constructCartDetails(details))
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function assignRemainingStock(id, vstock) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .doc(id)
      .set({ quantity: vstock || 1 }, { merge: true })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function cancelItem(itemId, itemPrice, itemQuantity) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .doc(itemId)
      .set(
        {
          [ID]: itemId,
          [REFUND_AMOUNT]: itemPrice * itemQuantity,
          [CANCELLED_AT]: new Date()
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function deleteItemFromCart(itemId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .doc(itemId)
      .delete()
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function getCartContents(status = CART_STATUS__ON_CART) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .where(SOLD_TO, "==", getCurrentUserUid())
      .where(CART_STATUS, "==", status)
      .get()
      .then(snapshot => resolve(snapshot))
      .catch(err => reject(err));
  });
}

export function getReviews(productSKU) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .where(PRODUCT_SKU, "==", productSKU)
      .where(RATING, "!=", "")
      .get()
      .then(snapshot => {
        let reviews = [];
        snapshot.forEach(doc => {
          reviews.push({
            id: doc.id,
            data: doc.data()
          });
        });

        resolve(reviews);
      })
      .catch(err => reject(err));
  });
}

export function hasOrderId(orderId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .where(FULL_QR, "==", orderId)
      .limit(1)
      .get()
      .then(snapshot => resolve(snapshot.size > 0))
      .catch(err => reject(err));
  });
}

export function loadAccountCart(orderId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .where(ORDER_ID, "==", orderId)
      .get()
      .then(snapshot => {
        let cartorders = [];
        snapshot.forEach(doc => {
          cartorders.push({
            id: doc.id,
            ...doc.data()
          });
        });
        resolve(cartorders);
      })
      .catch(err => reject(err));
  });
}

// TO DO: This seems to be wrong. Fix later.
export function setItemQuantity(itemId, quantity) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .doc(itemId)
      .set({ [QUANTITY]: quantity }, { merge: true })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function setItemRating(itemId, rating, comment) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .doc(itemId)
      .set(
        {
          [RATING]: rating,
          [COMMENT]: comment
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

function getSerialNumber(vproductSKU, serialNums) {
  return new Promise(resolve => {
    let serialNumbers = [];

    serialNums.forEach(key => {
      if (vproductSKU === key.vproductSKU) {
        serialNumbers.push(key.serialNumber);
      }
    });

    resolve(serialNumbers);
  });
}

export function setItemsSold(orderId, fullqr, serialNums) {
  return new Promise((resolve, reject) => {
    getCartContents()
      .then(querySnapshot => {
        // TO DO!!! Should this run sequential?
        querySnapshot.forEach(doc => {
          getSerialNumber(doc.data().vproductSKU, serialNums).then(
            serialNumbers => {
              db.collection(DB.ADDTOCART)
                .doc(doc.id)
                .set(
                  {
                    [SERIAL_NUMBER]: serialNumbers,
                    [CART_STATUS]: CART_STATUS__SOLD,
                    [ORDER_ID]: orderId,
                    [FULL_QR]: fullqr
                  },
                  { merge: true }
                );
            }
          );
        });
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function setPromoAvailed(itemId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .doc(itemId)
      .set({ [PROMO_AVAILED]: true }, { merge: true })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function setRating(details) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .doc(details.id)
      .set(
        {
          [PHOTO_URL]: details.photoUrl,
          [RATER_IMAGE]: details.raterImage,
          [RATED_BY]: getCurrentUser(),
          [RATING]: details.rating,
          [COMMENT]: details.comment,
          [RATED_AT]: new Date()
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function updateCart(vproductSku, quantity) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .where(SOLD_TO, "==", getCurrentUserUid())
      .where(VPRODUCT_SKU, "==", vproductSku)
      .where(CART_STATUS, "==", CART_STATUS__ON_CART)
      .get()
      .then(query => {
        const thing = query.docs[0];
        return thing.ref.update({
          [QUANTITY]: quantity,
          [SOLD_ON]: new Date()
        });
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function updateCartItem(details) {
  return new Promise((resolve, reject) => {
    db.collection(DB.ADDTOCART)
      .doc(details.id)
      .set(
        {
          [NAME]: details.name,
          [QUANTITY]: details.quantity,
          [PRICE]: details.price,
          [VPRODUCT_SKU]: details.vproductSKU
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}
