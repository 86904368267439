<template>
  <div class="pa-0">
    <v-card>
      <v-container fluid>
        <v-timeline
          v-for="(transaction, index) in transactions"
          :key="index"
          dense
          align-top
        >
          <v-timeline-item color="primary" small>
            <v-row class="pt-1">
              <v-col class="text-left" cols="5">
                <strong>{{ getDateTime(transaction.data.timestamp) }}</strong>
              </v-col>
              <v-col class="text-right" cols="7">
                <strong>
                  <PesoAmount
                    :amount="
                      Math.round(
                        transaction.data.newValues.total ||
                          transaction.data.newValues.payment.total
                      )
                    "
                  />
                </strong>
                <div class="text-caption">
                  {{ transaction.data.operation }}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { getDateTime } from "@/common/utilities/date";
import { getPaymentTransaction } from "@/common/store";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "PaymentTransaction",

  components: {
    PesoAmount
  },

  data: () => ({
    transactions: []
  }),

  mounted() {
    this.loadPaymentTransaction();
  },

  methods: {
    getDateTime(timestamp) {
      return getDateTime(timestamp);
    },

    loadPaymentTransaction() {
      getPaymentTransaction().then(docs => {
        this.transactions = docs;
      });
    }
  }
};
</script>
