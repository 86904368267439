<template>
  <v-card>
    <v-img
      :src="require('@/assets/logoCBIG.jpg')"
      class="white--text align-center "
      contain
      height="300px"
    />
  </v-card>
</template>

<script>
export default {
  name: "HomeOurPartners"
};
</script>
