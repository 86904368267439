<template>
  <v-card>
    <v-simple-table v-for="(cart, clist) in cartorders" :key="clist">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center" scope="col">ITEM</th>
            <th class="text-center" scope="col">PRICE</th>
            <th class="text-center" scope="col">QUANTITY</th>
            <th class="text-center" scope="col">TOTAL</th>
            <th class="text-center" scope="col" />
          </tr>
        </thead>
        <tbody>
          <tr height="50px">
            <td class="px-6">
              <v-list-item key="1">
                <v-list-item-avatar>
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="16 / 9"
                    height="80px"
                    :src="cart.data.imageUrl"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ cart.data.name }}</v-list-item-title>
                  <v-list-item-title class="caption">
                    ORDER ID:{{ cart.id }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ cart.data.category }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ cart.data.variation }}
                  </v-list-item-subtitle>
                  <div
                    class="text-center mt-1"
                    v-if="
                      ORDER_STATUS_COMPLETED === cart.data.cartStatus &&
                        !cart.data.rating
                    "
                  >
                    <v-rating
                      v-model="rating[clist]"
                      color="yellow darken-3"
                      background-color="grey darken-1"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                      x-small
                    />
                    <v-text-field v-model="comment[clist]" label="comment" />

                    <v-card-actions class="pa-0 justify-center">
                      <v-btn
                        x-small
                        color="primary"
                        @click="rateSelectedItem(cart.id, clist)"
                      >
                        Give Rating
                      </v-btn>
                    </v-card-actions>
                  </div>
                  <div class="text-center mt-1" v-else-if="cart.data.rating">
                    <v-rating
                      v-model="cart.data.rating"
                      color="yellow darken-3"
                      background-color="grey darken-1"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                      x-small
                      readonly
                    />
                    <v-text-field
                      v-model="cart.data.comment"
                      label="comment"
                      readonly
                    />
                  </div>
                </v-list-item-content>
              </v-list-item>
            </td>
            <td class="pt-5" style="width: 130px;">
              <PesoAmount :amount="cart.data.price" />
            </td>
            <td>
              <v-text-field
                class="pt-5"
                style="width: 90px;"
                single-line
                v-model="cart.data.quantity"
                type="number"
                flat
                solo
                readonly
              />
            </td>
            <td class="pt-5" style="width: 130px;">
              <PesoAmount :amount="cart.data.price * cart.data.quantity" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { ORDER_STATUS_COMPLETED } from "@/common/utilities/order";

import { loadCheckoutId, loadSelectedCart } from "@/store";
import { setItemRating } from "@/utilities/collection/addtocart";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "OrderHistory",

  components: {
    PesoAmount
  },

  data: () => ({
    rating: [],
    comment: [],
    label: "Value",
    checkouts: [],
    cartorders: [],

    ORDER_STATUS_COMPLETED: ORDER_STATUS_COMPLETED
  }),

  mounted() {
    this.loadCheckoutId();
  },

  methods: {
    loadCheckoutId() {
      loadCheckoutId()
        .then(docs => {
          this.checkouts = docs;
          return loadSelectedCart();
        })
        .then(docs => {
          this.cartorders = docs;
        });
    },

    rateSelectedItem(cartid, index) {
      setItemRating(cartid, this.rating[index], this.comment[index]);
    }
  }
};
</script>
