<template>
  <div class="pt-4">
    <v-btn
      v-if="statusPending.includes(paymentStatus)"
      color="primary"
      @click="setNewPayment()"
    >
      Add Payment
    </v-btn>

    <v-btn v-else color="primary" @click="setRefund()">
      Request Refund
    </v-btn>
  </div>
</template>

<script>
import {
  INVALID_REFERENCE_NO,
  PAYMENT_INSUFFICIENT_AMOUNT
} from "@/common/utilities/payment";

import { loadAccountCart } from "@/utilities/collection/addtocart";
import { routeTo, ROUTE_CHECKOUT, ROUTE_REFUND_EXCESS } from "@/router";

const CHECKOUT = "etindahan/checkout/";

export default {
  name: "StatusOrder",

  props: {
    balance: Number,
    checkout: Object,
    paymentMade: Number,
    paymentStatus: String,
    totalVerifiedPayments: Number
  },

  data: () => ({
    cartorders: [],
    statusPending: [INVALID_REFERENCE_NO, PAYMENT_INSUFFICIENT_AMOUNT]
  }),

  mounted() {
    if (this.checkout) {
      loadAccountCart(this.checkout.id).then(docs => {
        this.cartorders = docs;
      });
    }
  },

  methods: {
    setNewPayment() {
      this.$store.commit(`${CHECKOUT}setBalance`, this.balance);
      this.$store.commit(`${CHECKOUT}setCartItem`, this.cartorders);
      this.$store.commit(`${CHECKOUT}setCartTotal`, this.checkout.subtotal);
      this.$store.commit(`${CHECKOUT}setCheckoutDetails`, this.checkout);
      this.$store.commit(`${CHECKOUT}setPaymentMade`, this.paymentMade);
      this.$store.commit(`${CHECKOUT}setpaymentStatus`, this.paymentStatus);
      this.$store.commit(
        `${CHECKOUT}setTotalVerifiedPayments`,
        this.totalVerifiedPayments
      );

      routeTo(ROUTE_CHECKOUT);
    },

    setRefund() {
      let refundDetails = { checkout: this.checkout };
      refundDetails.checkout["balance"] = this.balance;

      this.$store.dispatch(`${CHECKOUT}setRefundDetails`, refundDetails);
      routeTo(ROUTE_REFUND_EXCESS);
    }
  }
};
</script>
