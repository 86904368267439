<template>
  <div class="pa-0">
    <v-card class="pa-2" elevation="0" v-if="modeofpayment === 'Bantay Kard'">
      <div class="text-center text-justify">
        {{ modeofpayment }} is a type of transaction where the recipient pays
        for a good online can be simply defined as the exchange of money online,
        or the payment for goods or services over the internet.
      </div>
    </v-card>

  </div>
</template>

<script>
import {
  PAYMENT_ON_SITE,
  PAYMENT_CASH_ON_DELIVERY
} from "@/common/utilities/payment";

export default {
  name: "Instruction",

  props: {
    modeofpayment: String
  },

  computed: {
    forCash() {
      return [PAYMENT_ON_SITE, PAYMENT_CASH_ON_DELIVERY].includes(
        this.modeofpayment
      );
    }
  }
};
</script>
