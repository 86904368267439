<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <BackButton @click="$emit('back')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isUploading">
        <v-container style="height: 400px;">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Submitting Prescription
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col v-else>
        <v-img :src="prescriptionImage" contain max-height="400" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <NextButton @click="submitPrescription" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackButton from "@/components/Controls/BackButton.vue";
import NextButton from "@/components/Controls/NextButton.vue";

export default {
  name: "RxPhotoReview",

  props: ["prescriptionImage"],

  data: () => ({
    isUploading: false
  }),

  components: {
    BackButton,
    NextButton
  },

  methods: {
    submitPrescription() {
      this.isUploading = true;
      this.uploadPrescription().then(submissionId => {
        this.$emit("onRxSubmission", {
          submissionId: submissionId,
          prescriptionImage: this.prescriptionImage
        });
      });
    },

    uploadPrescription() {
      return new Promise(resolve => {
        // TODO: replace code below that actually uploads the prescription
        setTimeout(() => {
          const submissionId = "RX-0001ABCD"; // this is an id from a submission REST api
          resolve(submissionId);
        }, 2000);
      });
    }
  }
};
</script>
