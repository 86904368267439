<template>
  <div v-show="isVisible" class="background-grey py-2">
    You will be logged out in {{ minutes }}:{{ seconds }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { onAuthStateChanged } from "@/main";

import firebase from "firebase/app";

export default {
  data: () => ({
    checkSessionTimeout: null
  }),

  mounted() {
    this.checkSessionTimeout = setInterval(this.checkSessionTimeoutFunc, 1000);
  },

  beforeUnmount() {
    clearInterval(this.checkSessionTimeout);
  },

  computed: {
    ...mapGetters(["getSecondsToLogout", "isLoggedIn"]),

    isVisible() {
      return (
        this.isLoggedIn &&
        this.getSecondsToLogout > 0 &&
        this.getSecondsToLogout < 120
      );
    },

    minutes() {
      return ((this.getSecondsToLogout - (this.getSecondsToLogout % 60)) / 60)
        .toString()
        .padStart(2, "0");
    },

    seconds() {
      return (this.getSecondsToLogout % 60).toString().padStart(2, "0");
    }
  },

  methods: {
    ...mapActions(["resetContent", "shouldLogout"]),

    checkSessionTimeoutFunc() {
      this.shouldLogout().then(value => {
        if (value) {
          firebase
            .auth()
            .signOut()
            .finally(() => {
              return this.resetContent();
            })
            .finally(() => {
              onAuthStateChanged(null);
            });
        }
      });
    }
  }
};
</script>
