<template>
  <v-row v-if="hasPadding">
    <v-col cols="2" />
    <v-col cols="5">{{ this.header }}</v-col>
    <v-col cols="3">{{ this.content }}</v-col>
  </v-row>
  <v-row v-else>
    <v-col class="ml-4" cols="5">{{ this.header }}</v-col>
    <v-col cols="5">{{ this.content }}</v-col>
  </v-row>
</template>

<script>
export default {
  name: "BenefitElement",

  props: {
    header: String,
    content: String
  },

  computed: {
    hasPadding() {
      return window.innerWidth > 500;
    }
  }
};
</script>
