<template>
  <div>
    <h4 class="mb-7 text-center">
      <u>{{ header }}</u>
    </h4>

    <v-row
      v-for="(illness, i) in illnesses"
      :key="i"
      class="ma-0 px-2 py-5"
      :class="{ 'background-grey': !(Object.keys(illnesses).indexOf(i) % 2) }"
    >
      <v-col :cols="setWidth(4, 3, 2, 0, 0)" v-show="setWidth(4, 3, 2, 0, 0)" />
      <v-col :cols="setWidth(6, 7, 9, 12, 12)" class="d-inline-flex pa-0">
        <v-simple-checkbox
          @input="onIllnessChecked(i, $event)"
          :value="illnessValue(i)"
          :ripple="false"
          class="ma-0 minimized pt-0"
        />

        {{ i }}
      </v-col>

      <v-col
        :cols="setWidth(4, 3, 2, 1, 1)"
        v-show="illnessValue(i)"
        class="pa-0"
      />
      <v-col
        :cols="setWidth(6, 7, 9, 11, 11)"
        v-show="illnessValue(i)"
        class="pa-0 pt-2"
      >
        <v-text-field
          @input="onIllnessComment(i, $event)"
          :value="illness.comment"
          :disabled="!illness.value"
          :placeholder="'Others' === i ? 'Please specify' : placeholder"
          v-if="showComments || 'Others' === i"
          class="illness-comment ml-2 mt-0 py-0 px-2"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { isXLOrLarger, setWidth } from "@/utilities/display";

export default {
  name: "Illnesses",

  props: {
    header: String,

    namespace: String,
    getValue: String,
    getValues: String,
    setValue: String,
    setComment: String,

    placeholder: {
      type: String,
      default: "Please specify"
    },

    showComments: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    illnessValue(key) {
      return this.$store.getters[`${this.namespace}/${this.getValue}`](key);
    },

    onIllnessChecked(illness, value) {
      this.$store.commit(`${this.namespace}/${this.setValue}`, {
        key: illness,
        value: value
      });
    },

    onIllnessComment(illness, value) {
      this.$store.commit(`${this.namespace}/${this.setComment}`, {
        key: illness,
        value: value
      });
    },

    setWidth(xl, lg, md, sm, fall) {
      return setWidth(this.$vuetify.breakpoint.width, xl, lg, md, sm, fall);
    }
  },

  computed: {
    illnesses: {
      get() {
        return this.$store.getters[`${this.namespace}/${this.getValues}`];
      }
    },

    isXLOrLarger() {
      return isXLOrLarger(this.$vuetify.breakpoint.width);
    }
  }
};
</script>

<style scoped>
.illness-comment >>> .v-input__slot {
  margin-bottom: 0;
}
</style>
