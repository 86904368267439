<template>
  <v-card class="elevation-12">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title class="centerThis">Payment</v-toolbar-title>
    </v-toolbar>

    <div v-if="premium > balance">
      <v-row>
        <v-col cols="2" />
        <v-col cols="8">
          You do not have sufficient balance in your Bantay Kard to complete
          this transaction.
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <PaymentElement
        class="mt-3"
        header="Current Balance"
        :content="balance"
      />
      <PaymentElement class="mt-n3" header="LESS: Premium" :content="premium" />

      <v-row class="mt-n4">
        <v-col cols="7" />
        <v-col class="text-right" cols="3"><v-divider /></v-col>
      </v-row>

      <PaymentElement
        class="mt-n3"
        header="New Balance"
        :content="balance - premium"
      />

      <v-row class="mt-2 pb-6">
        <v-col class="centerThis">
          <v-btn @click="confirmPayment" class="packages success" type="submit">
            Confirm Payment
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import PaymentElement from "./PaymentElement.vue";

export default {
  name: "PaymentInformation",

  components: {
    PaymentElement
  },

  props: {
    premium: Number
  },

  data: () => ({
    balance: 0
  }),

  mounted() {
    this.balance = this.$store.state.profile.bantayKardBalance;
  },

  methods: {
    confirmPayment() {
      this.$emit("close-dialog");
    }
  }
};
</script>

<style scoped>
.centerThis {
  margin: auto;
  text-align: center;
}
</style>
