<template>
  <div>
    <AppHeader header="Covid Vaccine Pre-Booking" />

    <v-container fluid class="container">
      <CovidVaccinePreBookingDetails
        v-if="0 === pageMode"
        @next="pageMode = 1"
      />

      <CovidVaccinePreBookingInformation
        v-if="1 === pageMode"
        @back="pageMode = 0"
      />

      <CovidVaccineMedicalHistory v-if="2 === pageMode" />
    </v-container>
  </div>
</template>

<script>
import CovidVaccineMedicalHistory from "@/components/CovidVaccine/MedicalHistory.vue";
import CovidVaccinePreBookingDetails from "@/components/CovidVaccine/PreBookingDetails.vue";
import CovidVaccinePreBookingInformation from "@/components/CovidVaccine/PreBookingInformation.vue";

export default {
  name: "CovidVaccine",

  components: {
    CovidVaccineMedicalHistory,
    CovidVaccinePreBookingDetails,
    CovidVaccinePreBookingInformation
  },

  data: () => ({
    pageMode: 0
  })
};
</script>
