<template>
  <v-container fill-height>
    <v-row class="justify-center align-center">
      <v-col cols="12" md="6">
        <p>
          Your submission receipt is <strong>{{ rxSubmissionId }}</strong>
          . Our pharmacist will review the prescription and you will receive a
          notification right after. If stocks are available, your cart will be
          updated with the medicines. You may review your cart and update the
          quantity before check-out.
        </p>
        <p>
          Feel free to navigate to other parts of the MediSure app. Click
          <a :href="ROUTE_DASHBOARD">here</a> to go back to your dashboard.
          Thank you.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ROUTE_DASHBOARD } from "@/router";

export default {
  name: "RxSubmissionReceipt",

  props: ["rxSubmissionId"],

  data: () => ({
    ROUTE_DASHBOARD: ROUTE_DASHBOARD
  })
};
</script>
