<template>
  <v-container fluid class="main-container">
    <v-alert type="error" v-if="errorText">
      {{ errorText }}{{ altErrorText }}
    </v-alert>

    <div v-if="waitSpinner" class="mt-15 text-center">
      <CircularProgress />
    </div>

    <div v-else>
      <v-card class="mx-auto">
        <v-card-title class="headline">Offsite Member Information</v-card-title>
        <v-form ref="formDetails" v-model="validDetails">
          <v-card-text>
            <v-text-field
              v-model="email"
              :rules="[
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
              ]"
              label="Email"
              required
            />
            <v-text-field
              v-model="contactNumber"
              label="Contact Number"
              :rules="[l => !!l || 'Contact Number is required']"
            />
            <SelectLocation
              class="pb-4"
              dbLocation="homeServiceLocations"
              :selectedLocation.sync="selectedLocation"
            />

            <v-text-field
              v-model="address"
              :rules="[l => !!l || 'Address is required']"
              label="House Number / Street"
              @change="clearErrors"
              required
            />

            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[passwordRules.required, passwordRules.min]"
              :type="showPassword ? 'text' : 'password'"
              name="password"
              label="Password"
              hint="At least 8 characters"
              counter
              @click:append="showPassword = !showPassword"
            />

            <v-text-field
              v-model="rePassword"
              :append-icon="showRepassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[passwordRules.required, passwordConfirmationRule]"
              :type="showRepassword ? 'text' : 'password'"
              name="rePassword"
              label="Re-enter Password"
              @click:append="showRepassword = !showRepassword"
            />

            <div v-for="(registrant, list) in registrants" :key="list">
              <OffsiteRegistrants
                :id="list"
                :registrants="registrants"
                :registrant="registrant"
                @removeRegistrant="removeRegistrant($event)"
              />
            </div>
            <v-btn
              class="floaterr ma-1"
              small
              fab
              color="primary"
              @click="addRegistrant(keyList)"
            >
              +
            </v-btn>

            <div class="alignCenter">
              <v-btn
                :disabled="!validDetails"
                color="success"
                class="mb-3 ml-2 mr-4"
                @click="setProvider('email')"
              >
                Register
              </v-btn>
            </div>
            <div class="alignCenter pb-4">
              You may create a new account or sign in using your existing
              accounts.
              <v-divider />
            </div>

            <AuthenticationAlternativeSignIn
              :aria-disabled="!validDetails"
              @facebookLogin="setProvider('FB')"
              @googleLogin="setProvider('Google')"
              @phoneLogin="setProvider('Phone')"
            />
          </v-card-text>
        </v-form>
      </v-card>

      <v-dialog v-model="dialog" width="800">
        <RegistrationTerms v-on:continue-registration="continueRegistration" />
      </v-dialog>

      <v-dialog v-model="phoneDialog" width="400">
        <AuthenticationPhoneSignin
          class="ma-0"
          @onClose="phoneDialog = false"
          @onSuccess="onPhoneLogin()"
        />
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { LOCALE_TL } from "@/common/utilities/locale";
import { ROUTE_LOADING, routeTo } from "@/router";
import { STATUS_UNREAD } from "@/common/utilities/message";

import { getCurrentUserUid } from "@/common/store";
import { onAuthStateChanged } from "@/main";
import {
  sendMessage,
  setOffsiteMembers,
  updateRegistrationDetailsOffsite
} from "@/store";
import { setRegistrationDetails } from "@/utilities/session";

import firebase from "firebase/app";
import "firebase/auth";

import AuthenticationAlternativeSignIn from "@/components/Authentication/AlternativeSignIn.vue";
import AuthenticationPhoneSignin from "@/components/Authentication/PhoneSignin.vue";
import CircularProgress from "@/components/CircularProgress.vue";
import OffsiteRegistrants from "@/components/OffsiteRegistrants.vue";
import RegistrationTerms from "@/components/RegistrationTerms.vue";
import SelectLocation from "@/components/SelectLocation.vue";

export default {
  name: "Register",

  components: {
    AuthenticationAlternativeSignIn,
    AuthenticationPhoneSignin,
    CircularProgress,
    OffsiteRegistrants,
    RegistrationTerms,
    SelectLocation
  },

  data: () => ({
    valid: false,
    validDetails: false,

    address: "",
    email: "",
    contactNumber: "",

    showPassword: false,
    password: "",
    passwordRules: {
      required: value => !!value || "Pasword Required.",
      min: v => v.length >= 8 || "Min 8 characters"
    },
    showRepassword: false,
    rePassword: "",

    altErrorText: "",
    errorText: "",

    phoneDialog: false,
    dialog: false,
    provider: "",

    waitSpinner: false,
    selectedLocation: {},

    registrants: {},

    keyList: -1
  }),

  mounted() {
    this.addRegistrant();
    this.valid = false;
    this.validDetails = false;
  },

  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.rePassword || "Password must match";
    }
  },

  methods: {
    addRegistrant() {
      this.keyList++;
      this.registrants["Patient " + this.keyList] = {
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        birthDate: "",
        type: "",
        isFree: false
      };
      let temp = this.registrants;
      this.registrants = {};
      this.registrants = temp;
    },

    clearErrors() {
      this.altErrorText = "";
      this.errorText = "";
    },

    continueRegistration() {
      this.dialog = false;

      if ("Phone" === this.provider) {
        this.phoneDialog = true;
      } else {
        this.waitSpinner = true;
        this.setRegistrationDetails();

        if ("email" === this.provider) {
          this.registerNew();
        } else {
          const provider =
            "FB" === this.provider
              ? new firebase.auth.FacebookAuthProvider()
              : new firebase.auth.GoogleAuthProvider();
          this.loginWithProvider(provider);
        }
      }
    },

    getPosition(member) {
      return member === "Patient 0" ? "Primary" : "Member";
    },

    iterateMembers(registrants, uid) {
      Object.keys(registrants).forEach(member => {
        let fullAddress = {
          houseAdrress: this.address,
          ...this.selectedLocation
        };

        registrants[member]["primaryId"] = uid;
        registrants[member]["membership"] = this.getPosition(member);
        registrants[member]["fullAddress"] = fullAddress;
        registrants[member]["email"] = this.email;
        registrants[member]["registrationNumber"] = this.contactNumber;

        setOffsiteMembers(registrants[member], uid);
      });
    },

    loginWithProvider(provider) {
      window.sessionStorage.setItem("sendRegistrationMessage", true);

      routeTo(ROUTE_LOADING);
      firebase.auth().signInWithRedirect(provider);
    },

    onPhoneLogin() {
      this.setRegistrationDetails();

      firebase
        .auth()
        .currentUser.updateProfile({
          displayName: `${this.firstName} ${this.lastName}`
          // TODO: photoURL: "https://example.com/jane-q-user/profile.jpg"
        })
        .then(() => {
          this.waitSpinner = false;
          this.sendWelcomeMessage();
        })
        .catch(error => {
          this.waitSpinner = false;
          this.errorText = error;
        });

      this.sendWelcomeMessage();
    },

    registerNew() {
      this.registerOffsitePrimaryMember();
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(result => {
          this.registerOffsitePrimaryMember(result.user.uid);
          result.user
            .updateProfile({
              displayName: `${this.firstName} ${this.middleName} ${this.lastName}`
              // TODO: photoURL: "https://example.com/jane-q-user/profile.jpg"
            })
            .catch(error => {
              this.errorText = error;
            })
            .finally(() => {
              this.sendWelcomeMessage();
              onAuthStateChanged(result.user);
              this.waitSpinner = false;
            });
        })
        .catch(error => {
          this.waitSpinner = false;
          this.errorText = error;
        });
    },

    registerOffsitePrimaryMember(uid) {
      let primary = this.registrants["Patient 0"];

      let fullAddress = {
        houseAdrress: this.address,
        ...this.selectedLocation
      };
      let details = {
        roles: firebase.firestore.FieldValue.arrayUnion(
          "Offsite Primary Member"
        ),
        displayName: `${primary.firstName} ${primary.middleName} ${primary.lastName}`,
        gender: primary.gender,
        birthDate: primary.birthDate,
        fullAddress: fullAddress,
        email: this.email,
        registrationNumber: this.contactNumber
      };
      updateRegistrationDetailsOffsite(details, uid);

      this.iterateMembers(this.registrants, uid);
    },

    removeRegistrant(id) {
      delete this.registrants[id];
      let temp = this.registrants;
      this.keyList--;
      this.registrants = {};
      this.registrants = temp;
    },

    sendWelcomeMessage() {
      let body = `Salamat sa pagsali sa MediSure!!!

Nais po lamang naming kunin ang pagkakataon na ito upang iparating ang aming malugod na pagtanggap sa inyo bilang bagong miyembro.

Mabuhay po kayo!!!

Mula sa MediSure Team`;

      sendMessage({
        body: body,
        header:
          "https://firebasestorage.googleapis.com/v0/b/medisure-275117.appspot.com/o/images%2F53f56840-f3ba-11ea-ba6a-5bc4aecd9fe6.png?alt=media&token=f1229dce-47f4-41eb-831f-db9510805e13",
        name: "Admin",
        receiver: getCurrentUserUid(),
        sender: "Admin", //Sender AdminSide
        status: STATUS_UNREAD,
        subject: "Welcome to Medisure Plus!",
        timestamp: new Date()
      });
    },

    setProvider(provider) {
      this.altErrorText = "";
      this.errorText = "";

      if (this.$refs.formDetails.validate()) {
        if ("email" === provider && !this.$refs.formDetails.validate()) {
          return;
        }

        this.provider = provider;
        this.dialog = true;
      } else {
        const message = "Please fill up missing Member Information details.";
        if ("email" === provider) {
          this.errorText = message;
        } else {
          this.altErrorText = message;
        }
      }
    },

    setRegistrationDetails() {
      setRegistrationDetails(
        this.firstName,
        this.lastName,
        this.contactNumber,
        this.email,
        LOCALE_TL
      );
    }
  }
};
</script>

<style scoped>
.alignCenter {
  text-align: center;
}

.floaterr {
  float: right;
}
</style>
