<template>
  <div>
    <h3 class="text-center">Past Medical History</h3>

    <h5 class="mb-5 mt-10 text-center"><u>Previous Illnesses</u></h5>

    <div v-if="Object.keys(previousIllnesses).length">
      <div
        v-for="(illness, i) in previousIllnesses"
        :key="i"
        class="px-2"
        :class="{ 'background-grey': !(i % 2) }"
      >
        <InfoEntry
          :header="illness.value"
          :content="illness.comment"
          class="my-2 py-2"
        />
      </div>
    </div>

    <div v-else class="text-center">None</div>

    <h5 class="mb-5 mt-10 text-center"><u>Hospitalizations</u></h5>

    <div v-if="hospitalizations.length">
      <div
        v-for="(hospitalization, i) in hospitalizations"
        :key="i"
        class="px-2"
        :class="{
          'background-grey': !(i % 2)
        }"
      >
        <InfoEntry
          :header="hospitalization.hospitalization"
          :content="hospitalization.date + ' - ' + hospitalization.reasons"
          class="my-2 py-2"
        />
      </div>
    </div>

    <div v-else class="text-center">None</div>
  </div>
</template>

<script>
import { NS_HEALTHRECORDS_MEDICALHISTORY as NAMESPACE } from "@/store";

import InfoEntry from "@/components/Controls/InfoEntry.vue";

export default {
  name: "PhilHealthInformation",

  components: {
    InfoEntry
  },

  computed: {
    hospitalizations() {
      return this.$store.getters[`${NAMESPACE}/getHospitalizations`];
    },

    previousIllnesses() {
      const illnesses = this.$store.getters[`${NAMESPACE}/getIllnesses`];
      const values = [];

      for (const key of Object.keys(illnesses)) {
        if (illnesses[key].value) {
          values.push({ value: key, comment: illnesses[key].comment });
        }
      }

      return values;
    }
  }
};
</script>
