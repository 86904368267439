<template>
  <v-container>
    <BackButton @click="$emit('back')" />

    <p class="text-center pt-3 mt-7">
      Visit our laboratory for your checkup. Click NEXT to continue.
    </p>

    <div class="googlemap py-2">
      <iframe
        title="Map to Primary Health Care Center"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.4412587432607!2d121.05378681486127!3d14.57391428163728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c9db2fa1a295%3A0x242f3aa20983f47a!2sMedisure%20Plus%20Diagnostic%20Laboratory!5e0!3m2!1sen!2sph!4v1620607813604!5m2!1sen!2sph"
        width="100%"
        height="400"
      />
    </div>

    <NextButton @click="$emit('next')" />
  </v-container>
</template>

<script>
import BackButton from "@/components/Controls/BackButton.vue";
import NextButton from "@/components/Controls/NextButton.vue";

export default {
  name: "MainOffice",

  components: {
    BackButton,
    NextButton
  }
};
</script>

<style scoped>
.googlemap {
  border: 0;

  @media only screen and (max-width: 599px) {
    height: 300px;
  }
}
</style>
