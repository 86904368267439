<template>
  <div>
    <h3 class="mb-5 mt-10 text-center">Personal and Social History</h3>

    <div class="background-grey mt-0 px-2">
      <InfoEntry header="Smoking" :content="smokingValue" class="my-2 py-2" />
    </div>

    <div class="mt-0 px-2">
      <InfoEntry header="Alcohol" :content="alcoholValue" class="my-2 py-2" />
    </div>

    <div class="background-grey mt-0 px-2">
      <InfoEntry
        header="Illicit Drugs"
        :content="illicitDrugsValue"
        class="my-2 py-2"
      />
    </div>

    <h5 class="mb-5 mt-10 text-center"><u>Present Illnesses</u></h5>

    <div v-if="Object.keys(presentIllnesses).length">
      <div
        v-for="(illness, i) in presentIllnesses"
        :key="i"
        class="mt-0 px-2"
        :class="{ 'background-grey': !(i % 2) }"
      >
        <InfoEntry
          :header="illness.value"
          :content="illness.comment"
          class="my-2 py-2"
        />
      </div>
    </div>

    <div v-else class="text-center">None</div>

    <h5 class="mb-5 mt-10 text-center"><u>Immunization History</u></h5>

    <div v-if="Object.keys(immunizations).length">
      <div
        v-for="(immunization, i) in immunizations"
        :key="i"
        class="mt-0 px-2"
        :class="{ 'background-grey': !(i % 2) }"
      >
        <InfoEntry
          :header="immunization.value"
          :content="immunization.comment"
          class="my-2 py-2"
        />
      </div>
    </div>

    <div v-else class="text-center">None</div>

    <h5 class="mb-5 mt-10 text-center"><u>Maintenance Medication</u></h5>

    <div class="text-center">
      {{ maintenanceMedications || "None" }}
    </div>
  </div>
</template>

<script>
import { NS_HEALTHRECORDS_PERSONALSOCIALHISTORY as NAMESPACE } from "@/store";

import InfoEntry from "@/components/Controls/InfoEntry.vue";

import {
  KEY_ALCOHOL,
  KEY_ILLICIT_DRUGS,
  KEY_SMOKING
} from "@/HealthRecords/store/personal_social_history";

const I_DONT_DRINK = "I don't drink";
const SMOKER = "Smoker";
const STOPPED_USING = "Stopped using";

export default {
  name: "FamilyHistory",

  components: {
    InfoEntry
  },

  data: () => ({
    STOPPED_SMOKING: "Stopped smoking"
  }),

  methods: {
    showAlcoholDetails(value) {
      switch (value) {
        case "":
        case I_DONT_DRINK:
          return false;
      }

      return true;
    },

    showIllicitDrugsDetails(value) {
      return STOPPED_USING === value;
    },

    showSmokingDetails(value) {
      return SMOKER === value;
    }
  },

  computed: {
    alcohol() {
      return this.$store.getters[`${NAMESPACE}/getValue`](KEY_ALCOHOL);
    },

    alcoholGlasses() {
      return this.$store.getters[`${NAMESPACE}/getAlcoholGlasses`];
    },

    alcoholValue() {
      let value = this.alcohol;

      if (this.showAlcoholDetails(this.alcohol)) {
        value += ` (${this.alcoholGlasses} glass/es ${this.timePeriod} - Last intake: ${this.lastIntake})`;
      }

      return value;
    },

    illicitDrugs() {
      return this.$store.getters[`${NAMESPACE}/getValue`](KEY_ILLICIT_DRUGS);
    },

    illicitDrugsDetails() {
      return this.$store.getters[`${NAMESPACE}/getComment`](KEY_ILLICIT_DRUGS);
    },

    illicitDrugsValue() {
      let value = this.illicitDrugs;

      if (this.showIllicitDrugsDetails(this.illicitDrugs)) {
        value += ` since ${this.illicitDrugsDetails}`;
      }

      return value;
    },

    immunizations() {
      const items = this.$store.getters[`${NAMESPACE}/getImmunizations`];
      const values = [];

      for (const key of Object.keys(items)) {
        if (items[key].value) {
          values.push({
            value: key,
            comment: items[key].comment
          });
        }
      }

      return values;
    },

    lastIntake() {
      return this.$store.getters[`${NAMESPACE}/getAlcoholLastIntake`];
    },

    maintenanceMedications() {
      return this.$store.getters[`${NAMESPACE}/getMaintenanceMedications`];
    },

    presentIllnesses() {
      const illnesses = this.$store.getters[`${NAMESPACE}/getIllnesses`];
      const values = [];

      for (const key of Object.keys(illnesses)) {
        if (illnesses[key].value) {
          values.push({
            value: key,
            comment: illnesses[key].comment
          });
        }
      }

      return values;
    },

    smokerSelection() {
      return this.$store.getters[`${NAMESPACE}/getComment`](KEY_SMOKING);
    },

    smoking() {
      return this.$store.getters[`${NAMESPACE}/getValue`](KEY_SMOKING);
    },

    smokingDetails() {
      return this.$store.getters[`${NAMESPACE}/getSmokingDetails`];
    },

    smokingValue() {
      let value = this.smoking;

      if (this.showSmokingDetails(this.smoking)) {
        value += ` (${this.smokerSelection}`;
        if (this.STOPPED_SMOKING === this.smokerSelection) {
          value += ` since ${this.smokingDetails}`;
        }
        value += ")";
      }

      return value;
    },

    timePeriod() {
      return this.$store.getters[`${NAMESPACE}/getAlcoholPeriod`];
    }
  }
};
</script>
