<template>
  <v-container>
    <div class="col-xs-12 py-0">
      <v-row dense>
        <v-col cols="12" sm="6" class="py-1">
          <v-text-field
            v-model="search"
            hide-details
            dense
            label="Search"
            solo
            @input="unsearch()"
            @keydown.enter="searchProduct()"
          >
            <template v-slot:append>
              <v-btn
                class="mx-0"
                color="primary"
                fab
                x-small
                @click="searchProduct()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col cols="12" sm="2" class="py-1">
          <div style="float:right;" class="px-5">
            <v-menu open-on-hover offset-y>
              <template v-slot:activator="{ on }">
                <v-btn outlined v-on="on" color="primary">
                  <span>Sort By</span>
                </v-btn>
              </template>
              <v-card
                class="caption primary--text mx-auto"
                max-width="344"
                outlined
              >
                <v-list-item @click="loadRelevance()">
                  <v-list-item-title class="caption primary--text">
                    Relevance
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="loadProductBoxRating()">
                  <v-list-item-title class="caption primary--text my-0">
                    Popular
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="loadProductBoxSold()">
                  <v-list-item-title class="caption primary--text">
                    Top Sales
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="loadProductBoxPrice('asc')">
                  <v-list-item-title class="caption primary--text">
                    Price (Low to High)
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="loadProductBoxPrice('desc')">
                  <v-list-item-title class="caption primary--text">
                    Price (High to Low)
                  </v-list-item-title>
                </v-list-item>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>

      <div class="row text-center">
        <div
          class="col-md-3 col-sm-3 col-xs-12 pr-1"
          v-for="(boxlist, blist) in searchedProducts"
          :key="blist"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card @click="loadata(boxlist)" v-model="hisid" class="mb-2">
              <v-img
                class="white--text align-end"
                :aspect-ratio="2 / 2"
                :src="boxlist.imageUrl"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                    style="height: 100%;"
                  >
                    <v-btn v-if="boxlist.inventory !== '0'" outlined>
                      Stock:
                      {{
                        Number(boxlist.inventory) -
                          Number(arrReserved[blist]) +
                          Number(arrCurrentUserReserved[blist])
                      }}
                    </v-btn>
                    <v-btn v-else outlined>
                      Out of Stock
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-img>

              <v-card-text class="pa-0">
                <div>
                  <strong
                    class="black--text"
                    href="/product"
                    style="text-decoration: none"
                  >
                    {{ boxlist.name }}
                  </strong>
                </div>

                <CatalogRating :rating="boxlist.rating" :sold="boxlist.sold" />

                <strong v-if="boxlist.priceRange" class="primary--text mb-1">
                  {{ boxlist.priceRange }}
                </strong>
                <strong v-else class="primary--text mb-1">
                  <PesoAmount :amount="Number(boxlist.price)" />
                </strong>
              </v-card-text>
            </v-card>
          </v-hover>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import {
  ROUTE_PRODUCTDETAILS,
  ROUTE_TRACKORDER,
  ROUTE_WISHLISTS,
  ROUTE_ADDRESS_BOOK,
  ROUTE_STORES,
  routeTo
} from "@/router";
import { SERVICE_TINDAHAN } from "@/common/utilities/services";

import {
  getCurrentUserReservedItemsCount,
  getReservedItemsCount
} from "@/utilities/collection/products";
import { logUserActivity } from "@/common/store";
import { mapState } from "vuex";
import { normalizeTags } from "@/common/utilities/text";

import CatalogRating from "@/eTindahan/components/CatalogRating.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  components: {
    CatalogRating,
    PesoAmount
  },

  data: () => ({
    hisid: "",
    name: "",
    search: "",

    isSortedPrice: false,
    isSortedSold: false,
    isSortedRating: false,

    arrReserved: [],
    arrCurrentUserReserved: [],

    routeTo: routeTo,

    ROUTE_TRACKORDER: ROUTE_TRACKORDER,
    ROUTE_WISHLISTS: ROUTE_WISHLISTS,
    ROUTE_ADDRESS_BOOK: ROUTE_ADDRESS_BOOK,
    goRead: false,
    holdData: []
  }),

  computed: {
    ...mapState([
      "productbox",
      "productboxSearch",
      "productboxsoldSearch",
      "productboxpriceSearch",
      "productboxratingSearch"
    ]),

    searchedProducts() {
      if (this.goRead) {
        if (this.search) {
          return this.filteredSearch();
        } else {
          routeTo(ROUTE_STORES);
          return [];
        }
      }

      return this.holdData;
    }
  },

  created() {
    this.search = this.$store.getters["getSearchedProducts"];
    this.searchProduct();
  },

  watch: {
    searchedProducts() {
      this.arrCurrentUserReserved = [];
      this.arrReserved = [];

      if (this.searchedProducts) {
        this.searchedProducts.forEach(item => {
          getCurrentUserReservedItemsCount(item.id).then(size => {
            this.arrCurrentUserReserved.push(size);
          });

          getReservedItemsCount(item.id).then(size => {
            this.arrReserved.push(size);
          });
        });
      }
    }
  },

  methods: {
    constructProductDetails(boxlist) {
      let productDetails = {
        docid: boxlist.id,
        owner: boxlist.owner,
        selectedItemrating: boxlist.rating,
        selectedItemsold: boxlist.sold,
        name: boxlist.name,
        description: boxlist.description,
        downloadUrl: boxlist.imageUrl,
        category: boxlist.category,

        isPulled: boxlist.isPulled,

        priceRange: boxlist.priceRange || 0,
        price: Number(boxlist.price),

        inventory: Number(boxlist.inventory),

        itemShippingFee: boxlist.itemShippingFee,
        merchantName: boxlist.merchantName,
        merchantShippingFee: boxlist.merchantShippingFee,
        preOrderFrom: boxlist.preOrderFrom,
        preOrderTo: boxlist.preOrderTo,

        stockWarning: boxlist.stockWarning,
        pwdSc: boxlist.pwdSc,
        wishedBy: boxlist.wishedBy || []
      };

      if (boxlist.discount) {
        productDetails["discount"] = Number(boxlist.discount);
      }

      if (boxlist.discountUntil && boxlist.discountUntil.seconds) {
        productDetails["discountUntil"] = new Date(
          boxlist.discountUntil.seconds * 1000
        );
      }

      return productDetails;
    },

    filterContent(content) {
      return content.filter(entry => {
        return entry.hideItem;
      });
    },

    filteredSearch() {
      if (this.isSortedSold) {
        let content = this.filterContent(this.productboxsoldSearch);
        this.holdData =
          content.length > 0 ? content : this.filterContent(this.productbox);
      }

      if (this.isSortedRating) {
        let content = this.filterContent(this.productboxratingSearch);
        this.holdData =
          content.length > 0 ? content : this.filterContent(this.productbox);
      }

      if (this.isSortedPrice) {
        this.holdData = this.filterContent(this.productboxpriceSearch);
      }

      if (!this.isSortedSold && !this.isSortedRating && !this.isSortedPrice) {
        this.holdData = this.filterContent(this.productboxSearch);
      }

      return this.holdData;
    },

    loadata(boxlist) {
      // TO DO!!! Handle discounts

      this.$store.commit(
        "SET_PRODUCT_DETAILS",
        this.constructProductDetails(boxlist),
        { root: true }
      );

      logUserActivity("View Product", { target: this.hisid }).then(() => {
        routeTo(ROUTE_PRODUCTDETAILS);
      });
    },

    loadProductBoxPrice(direction) {
      if (this.search) {
        this.loadProductBoxPriceSearched(direction);
      }
    },

    loadProductBoxPriceSearched(direction) {
      this.isSortedSold = false;
      this.isSortedRating = false;
      this.isSortedPrice = true;

      let arraySearch = this.search.split(" ");

      let content = {
        direction: direction,
        section: SERVICE_TINDAHAN,
        search: normalizeTags(arraySearch)
      };

      this.$store.dispatch("loadProductBoxPriceSearched", {
        ...content
      });
    },

    loadProductBoxRating() {
      if (this.search) {
        this.loadProductBoxRatingSearched();
      }
    },

    loadProductBoxRatingSearched() {
      this.isSortedSold = false;
      this.isSortedRating = true;
      this.isSortedPrice = false;
      this.loadProductBoxSearched("loadProductBoxRatingSearched");
    },

    loadProductBoxSearched(dispatch) {
      let arraySearch = this.search.split(" ");
      let content = {
        section: SERVICE_TINDAHAN,
        search: normalizeTags(arraySearch)
      };
      this.$store.dispatch(dispatch, content);
    },

    loadProductBoxSold() {
      if (this.search) {
        this.loadProductBoxSoldSearched();
      }
    },

    loadProductBoxSoldSearched() {
      this.isSortedSold = true;
      this.isSortedRating = false;
      this.isSortedPrice = false;
      this.loadProductBoxSearched("loadProductBoxSoldSearched");
    },

    loadRelevance() {
      this.isSortedSold = false;
      this.isSortedRating = false;
      this.isSortedPrice = false;
    },

    searchProduct() {
      this.isSortedSold = false;
      this.isSortedSold = false;
      this.isSortedRating = false;
      this.goRead = true;
      let arraySearch = this.search.split(" ");
      this.$store.dispatch("loadProductBoxSearched", {
        section: SERVICE_TINDAHAN,
        search: normalizeTags(arraySearch)
      });
    },

    unsearch() {
      this.goRead = false;
    }
  }
};
</script>

<style>
.hidden {
  overflow: hidden;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}

@media (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
