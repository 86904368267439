import { dbGetLocations } from "./store";
import { titleCase } from "./utilities/text";

function normalizeLocationEntry(entry) {
  const customs = {
    "MABINI-J. RIZAL": "Mabini-J. Rizal",
    "TAWI-TAWI": "Tawi-Tawi",
    "WACK-WACK GREENHILLS": "Wack-Wack Greenhills"
  };

  return entry in customs ? customs[entry] : titleCase(entry);
}

function addRegionToLocations(locations, doc) {
  locations[doc.id] = {};
  let data = doc.data();

  for (const p in data) {
    const province = normalizeLocationEntry(p);
    locations[doc.id][province] = {};

    for (const m in data[p]) {
      const municipality = normalizeLocationEntry(m);
      locations[doc.id][province][municipality] = [];

      for (const barangay of data[p][m]) {
        locations[doc.id][province][municipality].push(
          normalizeLocationEntry(barangay)
        );
      }
    }
  }
}

export function getLocations(dbLocations) {
  return new Promise((resolve, reject) => {
    let locations = {};

    dbGetLocations(dbLocations)
      .then(snapshot => {
        snapshot.forEach(doc => {
          addRegionToLocations(locations, doc);
        });

        resolve(locations);
      })
      .catch(err => reject(err));
  });
}

export function getBarangays(locations, region, province, municipality) {
  let barangays = [];

  for (let b of locations[region][province][municipality]) {
    barangays.push({
      label: b,
      details: {
        region: region,
        province: province,
        municipality: municipality,
        barangay: b
      }
    });
  }

  let collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base"
  });
  barangays.sort((a, b) => collator.compare(a.label, b.label));

  return barangays;
}

export function getNCRBarangays(locations, municipality) {
  return getBarangays(
    locations,
    "NCR",
    "National Capital Region",
    municipality
  );
}

export function getMunicipalities(locations, region, province) {
  let municipalities = [];

  for (let m in locations[region][province]) {
    municipalities.push({
      label: m,
      details: {
        region: region,
        province: province,
        municipality: m
      }
    });
  }

  municipalities.sort((a, b) => (a.label > b.label ? 1 : -1));
  return municipalities;
}

export function getNCRMunicipalities(locations) {
  let src0 = getMunicipalities(locations, "NCR", "National Capital Region");
  let src1 = getMunicipalities(locations, "REGION IV-A", "Cavite");
  let src2 = getMunicipalities(locations, "REGION IV-A", "Laguna");
  let src3 = getMunicipalities(locations, "REGION IV-A", "Rizal");
  let src4 = getMunicipalities(locations, "REGION III", "Bulacan");

  let merged = [];
  merged.push(...src0, ...src1, ...src2, ...src3, ...src4);
  merged.sort((a, b) => (a.label > b.label ? 1 : -1));
  return merged;
}

function getProvince(region, province) {
  return {
    label: province,
    details: {
      region: region,
      province: province
    }
  };
}

export function getProvinces(locations) {
  let provinces = [];

  for (let r in locations) {
    for (let p in locations[r]) {
      if ("National Capital Region" !== p) {
        provinces.push(getProvince(r, p));
      }
    }
  }

  provinces.sort((a, b) => (a.label > b.label ? 1 : -1));
  provinces.unshift({
    label: "NCR",
    details: {
      region: "NCR",
      province: "National Capital Region"
    }
  });

  return provinces;
}

function areSame(field1, field2) {
  return field1 === field2;
}

export function areSameLocations(location1, location2) {
  return (
    location1 &&
    location2 &&
    areSame(location1.region, location2.region) &&
    areSame(location1.province, location2.province) &&
    areSame(location1.municipality, location2.municipality) &&
    areSame(location1.barangay, location2.barangay)
  );
}
