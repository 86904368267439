import { titleCase, valueOrEmptyString } from "./text";

export function getFullAddress(location) {
  return [
    [valueOrEmptyString(location.addressLine), location.barangay]
      .join(" ")
      .trim(),
    location.municipality,
    location.province
  ].join(", ");
}

export function normalizeLocation(location) {
  if (location.startsWith("CITY OF ")) {
    location = location.substr(8);
  }

  return titleCase(location);
}

export function normalizeLocationCaps(province, municipality, barangay) {
  return [
    titleCase(province),
    titleCase(municipality),
    titleCase(barangay)
  ].join(", ");
}

export function getFullAddress2(loc) {
  return loc
    ? [loc.addressLine, loc.municipality, loc.province].join(", ")
    : null;
}

export function getFullAddress3(location) {
  return location
    ? [location.barangay, location.municipality, location.province].join(", ")
    : null;
}

export function getAddress(address) {
  return address
    ? [address.houseNumber, address.street, address.landmark].join(" ")
    : null;
}
