import { DB } from "@/common/store";

import { db } from "@/main";

// document fields
const PRODUCT_SKU = "productSKU";
const VSTOCK = "vstock";

function getCurrentStock(data) {
  let currentStock = data.vstock ? Number(data.vstock) : 0;
  return currentStock > 0 ? currentStock : 0;
}

export function getItem(itemId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PRODUCTVARIANT)
      .doc(itemId)
      .get()
      .then(doc => resolve(doc))
      .catch(err => reject(err));
  });
}

export function increaseItemQuantity(itemId, quantity) {
  return new Promise((resolve, reject) => {
    let docRef = db.collection(DB.PRODUCTVARIANT).doc(itemId);

    db.runTransaction(transaction => {
      return transaction.get(docRef).then(doc => {
        if (!doc.exists) {
          return Promise.reject("Document does not exist!");
        } else {
          return transaction.update(docRef, {
            [VSTOCK]: getCurrentStock(doc.data()) + quantity
          });
        }
      });
    })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function loadItems(sku) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PRODUCTVARIANT)
      .where(PRODUCT_SKU, "==", sku)
      .get()
      .then(snapshot => {
        let items = [];

        snapshot.forEach(doc => {
          items.push({
            id: doc.id,
            data: doc.data()
          });
        });

        resolve(items);
      })
      .catch(err => reject(err));
  });
}

export function reduceItemQuantity(itemId, quantity) {
  return new Promise(resolve => {
    let docRef = db.collection(DB.PRODUCTVARIANT).doc(itemId);

    db.runTransaction(transaction => {
      return transaction.get(docRef).then(doc => {
        if (!doc.exists) {
          return Promise.reject("Document does not exist!");
        } else {
          let currentStock = Number(getCurrentStock(doc.data()));
          quantity = currentStock >= quantity ? quantity : currentStock;

          return transaction.update(docRef, {
            [VSTOCK]: currentStock - Number(quantity)
          });
        }
      });
    })
      .then(() => resolve(quantity))
      .catch(() => resolve(0));
  });
}
