import {
  DOCTYPE_CONTACTINFO,
  getDocumentHeader,
  IndexedDocument
} from "@/utilities/user_collection";

import { getLocation } from "@/utilities/location";

function getData(state) {
  return {
    address: { ...state.address },
    contactNumber: state.contactNumber,
    email: state.email
  };
}

function getDocumentContent(state) {
  return {
    header: getDocumentHeader(DOCTYPE_CONTACTINFO, state),
    data: getData(state)
  };
}

var contactInfoBase = new IndexedDocument(
  DOCTYPE_CONTACTINFO,
  getDocumentContent
);
export const contactInfo = {
  state: () => ({
    ...contactInfoBase.state,

    address: getLocation("", ""),

    contactNumber: "",
    email: ""
  }),

  getters: {
    ...contactInfoBase.getters,

    getAddress(state) {
      return state.address;
    },

    getAddressLine1(state) {
      return state.address.addressLine1;
    },

    getAddressBarangay(state) {
      return state.address.barangay;
    },

    getAddressMunicipality(state) {
      return state.address.municipality;
    },

    getAddressProvince(state) {
      return state.address.province;
    },

    getAddressRegion(state) {
      return state.address.region;
    },

    getContactNumber(state) {
      return state.contactNumber;
    },

    getEmail(state) {
      return state.email;
    },

    isComplete(state) {
      return state.address.barangay &&
        state.address.municipality &&
        state.address.province &&
        state.address.region &&
        state.contactNumber &&
        state.email
        ? true
        : false;
    }
  },

  mutations: {
    ...contactInfoBase.mutations,

    RESET_STATE(state) {
      state.address = getLocation("", "");
      state.contactNumber = "";
      state.email = "";
    },

    setAddressLine1(state, value) {
      state.address.addressLine1 = value;
    },

    setAddressBarangay(state, value) {
      state.address.barangay = value;
    },

    setAddressMunicipality(state, value) {
      state.address.municipality = value;
    },

    setAddressProvince(state, value) {
      state.address.province = value;
    },

    setAddressRegion(state, value) {
      state.address.region = value;
    },

    setContactNumber(state, value) {
      state.contactNumber = value;
    },

    setData(state, data) {
      state.address = getLocation(
        data.address.region,
        data.address.province,
        data.address.municipality,
        data.address.barangay,
        data.address.addressLine1
      );

      state.contactNumber = data.contactNumber;
      state.email = data.email;
    },

    setEmail(state, value) {
      state.email = value;
    }
  },

  actions: {
    ...contactInfoBase.actions,

    resetContent({ commit, dispatch }) {
      dispatch("resetDocument");
      commit("RESET_STATE");
    }
  }
};
