<template>
  <v-card>
    <div class="pa-5" :class="{ 'ma-2': $vuetify.breakpoint.mobile }">
      <h3 class="text-center">{{ selectedService }}</h3>
      <p class="mt-10">
        Ang bahaging ito ng site ay para sa mga pasyenteng magpapasuri sa
        opisina ng Medisure.
      </p>

      <p>
        Para sa mga katanungan, huwag po kayong mag-atubiling mag-email sa
        <a href="mailto:mso.admin@medisureonline.com">
          mso.admin@medisureonline.com
        </a>
        o tumawag sa <a href="tel:+639668769058">(0966) 876 9058</a>.
      </p>

      <p>
        I-click ang "Submit" para magpatuloy.
      </p>

      <v-row v-show="!hasAgreedToPolicyFromStore" class="pt-4" align="center">
        <v-col class="d-flex" cols="12">
          <v-checkbox v-model="hasAgreedToPolicy" hide-details class="mt-0" />
          <p class="pt-1">
            I acknowledge that I have read and agree to the
            <span class="underline primary--text" @click="privacyDialog = true">
              Terms and Conditions
            </span>
            and
            <span class="underline primary--text" @click="privacyDialog = true">
              Privacy Policy.
            </span>
          </p>
        </v-col>
      </v-row>

      <v-dialog v-model="privacyDialog">
        <PrivacyPolicy @closeDialog="privacyDialog = false" />
      </v-dialog>
    </div>

    <div class="pb-2" align="center">
      <v-btn color="primary" @click="next">Submit</v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

export default {
  name: "Main",

  components: {
    PrivacyPolicy
  },

  props: {
    selectedService: String
  },

  data: () => ({
    hasAgreedToPolicy: false,
    privacyDialog: false
  }),

  mounted() {
    this.hasAgreedToPolicy = this.hasAgreedToPolicyFromStore || false;
    this.$emit("hasAgreedToPolicy", !this.hasAgreedToPolicy);
  },

  watch: {
    hasAgreedToPolicy() {
      this.$emit("hasAgreedToPolicy", !this.hasAgreedToPolicy);
    }
  },

  computed: {
    ...mapGetters({ hasAgreedToPolicyFromStore: "getHasAgreedToPolicy" })
  },

  methods: {
    async next() {
      if (this.hasAgreedToPolicy !== this.hasAgreedToPolicyFromStore) {
        try {
          await this.$store.dispatch("agreeToPolicy", this.hasAgreedToPolicy);
        } finally {
          // intentionally empty
        }
      }
      this.$emit("next");
    }
  }
};
</script>

<style scoped>
.cardColor {
  background-color: rgba(255, 255, 255, 0.6) !important;
  border-color: white !important;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}
</style>
