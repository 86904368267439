<template>
  <div class="pt-4 pb-4">
    <v-row v-if="!messagebox.length">
      <v-col class="text-center">
        You have no {{ filter ? filter + "&nbsp;" : "" }} messages.
      </v-col>
    </v-row>

    <v-row class="px-2" v-for="(boxlist, blist) in messagebox" :key="blist">
      <v-col class="py-0">
        <v-layout :class="{ 'unread-list': isUnread(boxlist.status) }">
          <v-card
            height="100%"
            width="100%"
            @click="loadata(boxlist, blist)"
            @click.stop="dialog = true"
            v-model="hisid"
            outlined
          >
            <v-list-item two-lines class="mx-6">
              <v-list-item-content class="mx-3">
                <v-list-item-title class="mb-1">
                  {{ boxlist.subject }}

                  <span class="caption green--text" v-if="boxlist.fileAttached">
                    {{ "with attachment" }}
                  </span>
                  <span class="caption green--text" v-if="boxlist.homeService">
                    {{ "with confirmation" }}
                  </span>
                </v-list-item-title>

                <v-list-item-subtitle class="caption">
                  {{ getDate(boxlist.timestamp) }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mt-3">
                  {{ getNormalizedMessageBody(boxlist.body) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="900">
      <MessageBox
        :key="hisid"
        @close-dialog="closeDialog"
        :docid="hisid"
        :msgIndex="msgIndex"
        :name="name"
        :subject="subject"
        :body="body"
        :receiver="receiver"
        :sender="sender"
        :status="status"
        :timestamp="timestamp"
        :recieveqr="recieveqr"
        :schedule="schedule"
        :fileAttached="fileAttached"
        :homeService="homeService"
        :individualPrices="individualPrices"
        :packagePrices="packagePrices"
        :message="message"
        :isHtml="isHtml"
      />
    </v-dialog>
  </div>
</template>

<script>
import { STATUS_UNREAD } from "@/common/utilities/message";

import {
  getDateFromTimestamp,
  getDayDateTimeFromTimestamp
} from "@/common/utilities/date";
import { mapState } from "vuex";
import { statusRead } from "@/store";

import MessageBox from "@/components/MessageBox.vue";

export default {
  name: "MessageBoxContainer",

  components: {
    MessageBox
  },

  props: {
    filter: String
  },

  computed: {
    ...mapState(["messagebox"])
  },

  data: () => ({
    dialog: false,
    hisid: "",

    messages: [],
    fileAttached: [],
    homeService: "",
    mobileStationary: "",
    name: "",
    subject: "",
    body: "",
    receiver: "",
    sender: "",
    status: "",
    timestamp: "",
    recieveqr: "",
    schedule: "",
    read: "read",
    msgIndex: 0,
    individualPrices: [0],
    packagePrices: [0],

    message: {},
    isHtml: false,

    // functions
    getDate: getDayDateTimeFromTimestamp
  }),

  created() {
    this.$store.dispatch("loadMessageBox");
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },

    getNormalizedMessageBody(body) {
      return body
        .replaceAll("<br />", "")
        .replaceAll("<b>", "")
        .replaceAll("</b>", "");
    },

    isUnread(status) {
      return STATUS_UNREAD === status;
    },

    loadata(boxlist, index) {
      this.hisid = boxlist.id;
      this.name = boxlist.name;
      this.subject = boxlist.subject;
      this.body = boxlist.body;
      this.receiver = boxlist.receiver;
      this.sender = boxlist.sender;
      this.status = boxlist.status;
      this.timestamp = getDateFromTimestamp(boxlist.timestamp);
      this.recieveqr = boxlist.recieveqr || boxlist.qrCode;
      this.schedule = boxlist.schedule;
      this.fileAttached = boxlist.fileAttached;
      this.homeService = boxlist.homeService
        ? boxlist.homeService
        : boxlist.mobileStationary;
      this.msgIndex = index;
      if (boxlist.homeService == null) {
        statusRead({
          status: "read",
          docId: this.hisid
        });
        this.read = "read";
      }

      this.message = { ...boxlist };
      this.isHtml = boxlist.isHtml || false;
    }
  }
};
</script>

<style scoped>
.unread-list {
  font-weight: bolder;
  border-right: 3px solid green;
  border-bottom: 1px solid green;
}
</style>
