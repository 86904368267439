<template>
  <div>
    <div align="center" v-if="searching && !doctorAppointments.length">
      <v-progress-circular
        :size="150"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else-if="!doctorAppointments.length">
      <p>
        Sorry. No doctor appointment available based on your search filters.
      </p>
    </div>
    <div v-else>
      <v-list-item-group color="primary">
        <v-list-item v-for="(doc, i) in doctorAppointments" :key="i">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="doc.title"
              @click="selectDoctorAppointment(doc)"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </div>
  </div>
</template>

<script>
import { loadDoctorAppointments } from "@/store";

import moment from "moment";

export default {
  name: "SelectDoctorAppointment",

  props: {
    currentStep: Number,
    selectedSpecialization: {
      type: String,
      required: true
    },
    selectedVisitType: {
      type: String,
      required: true
    },
    showNearFirst: {
      type: Boolean,
      required: true
    },
    selectedLocation: Object,
    selectedDoctorAppointment: Object
  },

  data: () => ({
    doctorAppointments: [],
    searching: false,
    bottom: false,
    lastVisibleDoctorAppointment: null,
    locationFilter: null
  }),

  mounted() {
    window.addEventListener("scroll", this.scrollListener);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollListener);
  },

  watch: {
    currentStep(step, prev) {
      if (prev == 1 && step == 2) {
        this.doctorAppointments = [];
        this.lastVisibleDoctorAppointment = null;
        if (this.showNearFirst) {
          this.locationFilter = { ...this.selectedLocation };
        } else {
          this.locationFilter = null;
        }
        this.loadDoctorAppointments();
      } else if (step == 2) {
        this.$emit("update:selectedDoctorAppointment", null);
      }
    },

    bottom(bottom) {
      if (bottom) {
        this.loadDoctorAppointments();
      }
    }
  },

  methods: {
    loadDoctorAppointments() {
      this.searching = true;
      let filters = {
        specialization: this.selectedSpecialization,
        visitType: this.selectedVisitType,
        showNearFirst: true,
        location: this.locationFilter,
        docsPerLoad: 20,
        lastVisible: this.lastVisibleDoctorAppointment
      };

      loadDoctorAppointments(filters).then(ret => {
        if (ret.appointmentsCount > 0) {
          this.appendSearchResults(ret);
        } else if (
          this.locationFilter !== null &&
          this.locationFilter.barangay !== ""
        ) {
          this.locationFilter.barangay = "";
          loadDoctorAppointments(filters).then(ret => {
            if (ret.appointmentsCount > 0) {
              this.appendSearchResults(ret);
            }
          });
        }
        this.searching = false;
      });
    },

    appendSearchResults(ret) {
      ret.appointments.forEach(item => {
        let appointment = { ...item };
        appointment.title =
          moment(appointment.data.start.toDate()).format("MMM D, YYYY LT") +
          " - " +
          appointment.data.host.displayName;
        this.doctorAppointments.push(appointment);
      });
      this.lastVisibleDoctorAppointment = ret.lastVisible;
    },

    scrollListener() {
      if (this.currentStep == 2) {
        this.bottom = this.bottomVisible();
      }
    },

    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    },

    selectDoctorAppointment(doc) {
      this.$emit("update:selectedDoctorAppointment", doc);
    }
  }
};
</script>
