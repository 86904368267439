<template>
  <v-container fluid class="pa-0">
    <span class="bg"></span>

    <v-parallax
      v-if="!isLoggedIn"
      height="700"
      dark
      :src="require('@/assets/homehead1.jpg')"
    >
      <v-layout class="card--flex-toolbar" align-center column justify-center>
        <RegisterAd />
      </v-layout>
    </v-parallax>

    <SectionHeader title="- Services -" />
    <OurServices />

    <div class="mx-0 pa-4 row">
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card>
          <v-img
            :src="require('../assets/pharmacy.jpg')"
            class="white--text align-center"
            gradient="to bottom, rgba(0,100,0,.2), rgba(0,100,0,.6)"
            height="400px"
          >
            <h1 class="text-center font-size">
              e-B<v-icon color="white">mdi-pill</v-icon>tika
            </h1>
            <div class="text-center">
              <v-btn :to="ROUTE_SIGNIN" class="white--text" outlined>
                SHOP NOW
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </div>

      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card>
          <v-img
            :src="require('../assets/shop.jpg')"
            class="white--text align-center"
            gradient="to bottom, rgba(0,100,0,.2), rgba(0,100,0,.6)"
            height="400px"
          >
            <h1 class="text-center font-size">
              e-Tind<v-icon color="white">mdi-store</v-icon>han
            </h1>
            <div class="text-center">
              <v-btn
                id="videosection"
                :to="ROUTE_STORES"
                class="white--text"
                outlined
              >
                SHOP NOW
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </div>
    </div>

    <SectionHeader
      class="pointer"
      @click.native="routeTo(ROUTE_ABOUTUS)"
      title="- Our Story -"
    />

    <v-container fluid class="homecontainer ">
      <v-row>
        <v-col :cols="fbWidth" align="center" class="px-0 py-3">
          <iframe
            title="Medisure FB Page"
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmedisureph&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="300"
            height="500"
            style="border:none;overflow:hidden"
            allow="encrypted-media"
          />
        </v-col>

        <v-col :cols="youtubeWidth" align="center" class="px-0 pb-3">
          <div class="mx-3 videoParent">
            <youtube fitParent resize video-id="3zx4GZ38X14"></youtube>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <SectionHeader title="- Our Partners -" />
    <OurPartners />

    <SectionHeader title="- Main Office -" />
    <div class="googlemap">
      <iframe
        title="Map to Main Office"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7722.367563135024!2d121.03915876045691!3d14.58860131056998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c98af84e173f%3A0xa8eb87fa7977cdae!2sMedisure%20Plus!5e0!3m2!1sen!2sph!4v1620607439093!5m2!1sen!2sph"
        width="100%"
        height="450"
      />
    </div>
  </v-container>
</template>

<script>
import { ROUTE_ABOUTUS, ROUTE_SIGNIN, ROUTE_STORES, routeTo } from "@/router";

import { setPathToLoadAfterLogin } from "@/utilities/session";
import { setWidth } from "@/utilities/display";

import OurPartners from "@/components/Home/OurPartners.vue";
import OurServices from "@/components/Home/OurServices.vue";
import RegisterAd from "@/components/Home/RegisterAd.vue";
import SectionHeader from "@/components/Home/SectionHeader.vue";

export default {
  name: "Home",

  components: {
    OurPartners,
    OurServices,
    RegisterAd,
    SectionHeader
  },

  data: () => ({
    windowWidth: 0,

    ROUTE_ABOUTUS: ROUTE_ABOUTUS,
    ROUTE_SIGNIN: ROUTE_SIGNIN,
    ROUTE_STORES: ROUTE_STORES,

    routeTo: routeTo
  }),

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");

    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);
      this.getWindowWidth();
    });
  },

  methods: {
    pathAfterLog(cpath, directTo) {
      setPathToLoadAfterLogin(directTo);
      routeTo(cpath);
    },

    redirect() {
      routeTo(ROUTE_ABOUTUS);
    },

    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    }
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["isLoggedIn"];
    },

    fbWidth() {
      return setWidth(this.$vuetify.breakpoint.width, 4, 12, 12, 12, 12);
    },

    youtubeWidth() {
      return setWidth(this.$vuetify.breakpoint.width, 8, 12, 12, 12, 12);
    }
  }
};
</script>

<style scoped>
.googlemap {
  border: 0;

  @media only screen and (max-width: 599px) {
    height: 300px;
  }
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
}

.card--flex-toolbar {
  margin-top: -280px;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.homecontainer {
  max-width: 1500px;
}

.videoparent {
  max-width: 640px;
}
.pointer {
  cursor: pointer;
}
</style>
