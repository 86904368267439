<template>
  <v-card class="ma-1 pb-5" elevation="3">
    <div class="mx-0 text-right">
      <v-btn @click="$emit('onDelete', data)" color="red" dark small>
        <v-icon>mdi-delete-forever</v-icon>
      </v-btn>
    </div>

    <v-row class="pt-2 pb-0">
      <v-col class="pb-0 text-center">
        <v-header
          class="font-weight-bold text-center"
          style="font-family: monospace; font-size: larger;"
        >
          {{ data.patient.userCode }}
        </v-header>
      </v-col>
    </v-row>

    <PatientInfo
      :data="data.patient"
      @triggerUpdate="$emit('triggerPatientUpdate')"
      :key="data.patient"
      class="ma-3 mt-2"
    />

    <v-divider class="mt-5 mx-5" style="border-color: gray;" />

    <v-dialog v-model="dialogPackage" width="800">
      <Packages
        :subheader="fullName"
        :clinicType="$store.getters['lab/appointment/getClinicType']"
        :selectedPackages="packages"
        :key="dialogPackage"
        @onPackageSelected="onPackageSelected"
      />
    </v-dialog>

    <v-dialog v-model="dialogIndividual" width="800">
      <IndividualTest
        :subheader="fullName"
        :clinicType="$store.getters['lab/appointment/getClinicType']"
        :selectedTests="tests"
        :key="dialogIndividual"
        @onTestsSelected="onTestsSelected"
      />
    </v-dialog>

    <v-dialog v-model="dialogDiscount" width="800">
      <Discounts
        :subheader="fullName"
        :discounts="discounts"
        :isPWD="data.patient.isPWD"
        :isSenior="isSenior"
        :promo="promo"
        :subtotal="subtotal"
        @setPromo="setPromo"
      />
    </v-dialog>

    <v-row class="mb-12 mt-2 px-2" justify="center">
      <div class="my-2">
        <v-btn
          width="190"
          @click="dialogPackage = true"
          class="mx-2"
          small
          rounded
          tile
          color="primary"
        >
          <v-icon left> mdi-plus </v-icon> Add Package
        </v-btn>
      </div>
      <div class="my-2">
        <v-btn
          width="190"
          @click="dialogIndividual = true"
          class="mx-2"
          small
          rounded
          tile
          color="primary"
        >
          <v-icon left> mdi-plus </v-icon> Add Individual Test
        </v-btn>
      </div>
      <div class="my-2">
        <v-btn
          width="190"
          @click="dialogDiscount = true"
          v-show="packages.length || tests.length"
          class="mx-2"
          small
          rounded
          tile
          color="primary"
        >
          <v-icon left> mdi-plus </v-icon> Set Discount
        </v-btn>
      </div>
    </v-row>

    <div v-show="packages.length || tests.length" class="mt-10 mx-5">
      <SelectedTests
        :selectedTests="packages"
        @removeTest="removePackage"
        class="mt-5"
      />

      <SelectedTests
        :selectedTests="tests"
        @removeTest="removeTest"
        class="mt-5"
      />

      <TotalAmount
        :promo="promo"
        :subtotal="subtotal"
        class="px-0 mt-2"
        :class="{ 'pr-9': !$vuetify.breakpoint.mobile }"
      />
    </div>
  </v-card>
</template>

<script>
import { ComputeDiscount } from "@/utilities/discounts";
import { getLabScheduleFullName } from "@/utilities/profile";

import Discounts from "./Discounts.vue";
import IndividualTest from "./IndividualTest.vue";
import Packages from "./Packages.vue";
import PatientInfo from "./PatientInfo.vue";
import SelectedTests from "./SelectedTests.vue";
import TotalAmount from "./TotalAmount.vue";

export default {
  name: "PatientCard",

  components: {
    Discounts,
    IndividualTest,
    Packages,
    PatientInfo,
    SelectedTests,
    TotalAmount
  },

  props: {
    data: Object,
    discounts: Array
  },

  data: () => ({
    dialogIndividual: false,
    dialogPackage: false,
    dialogDiscount: false,

    packages: [],
    tests: [],

    promo: {},

    subtotal: 0,
    total: 0
  }),

  mounted() {
    this.packages = this.data.packages ? [...this.data.packages] : [];
    this.promo = this.data.promo ? { ...this.data.promo } : {};
    this.tests = this.data.tests ? [...this.data.tests] : [];
    this.updateTotals();
  },

  methods: {
    emitOnUpdate() {
      this.updateTotals();

      this.$store.commit(
        "lab/appointment/SET_PATIENT_AUXILIARY_DATA",
        {
          patient: { ...this.data.patient },
          tests: this.tests ? [...this.tests] : [],
          packages: this.packages ? [...this.packages] : [],
          subtotal: this.subtotal,
          promo: this.promo ? { ...this.promo } : {},
          total: this.total
        },
        { root: true }
      );
      this.$store.commit("lab/appointment/UPDATE_BILL_TOTAL", "", {
        root: true
      });

      this.$emit("onUpdate");
    },

    getTotalPrice(collection) {
      return collection.reduce((sum, e) => sum + e.price, 0);
    },

    onPackageSelected(packages) {
      this.packages = [...packages];
      this.dialogPackage = false;
      this.emitOnUpdate();
    },

    onTestsSelected(tests) {
      this.tests = [...tests];
      this.dialogIndividual = false;
      this.emitOnUpdate();
    },

    removePackage(index) {
      this.packages.splice(index, 1);
      this.emitOnUpdate();
    },

    removeTest(index) {
      this.tests.splice(index, 1);
      this.emitOnUpdate();
    },

    setPromo(promo) {
      this.promo = { ...promo };
      this.dialogDiscount = false;

      this.emitOnUpdate();
    },

    updateTotals() {
      this.subtotal =
        this.getTotalPrice(this.packages) + this.getTotalPrice(this.tests);

      this.promo.amount = ComputeDiscount(
        this.discounts,
        this.subtotal,
        this.promo
      );

      this.total = this.subtotal + this.promo.amount;
      this.renderTotal = !this.renderTotal;
    }
  },

  computed: {
    fullName() {
      return getLabScheduleFullName(this.data.patient);
    },

    isSenior() {
      return this.age >= 60;
    }
  }
};
</script>
