<template>
  <v-card class="hide-scrollbar">
    <h4 class="mt-3 text-center primary--text pt-2">
      Select Packages
    </h4>
    <p class="text-center">{{ subheader }}</p>

    <div class="hide-scrollbar selection-container-parent">
      <div class="mt-3 selection-container">
        <v-expansion-panels accordion class="mt-5">
          <v-expansion-panel
            class="col-md-6 col-sm-6 col-xs-12 px-0"
            v-for="(details, t) in packageList"
            :key="t"
          >
            <v-expansion-panel-header class="py-0">
              <v-row>
                <v-col cols="8" :class="{ 'px-0': $vuetify.breakpoint.mobile }">
                  <v-checkbox
                    v-model="packageList[t].isSelected"
                    :label="details.data.testname"
                    @click="$event.cancelBubble = true"
                  />
                </v-col>
                <v-col
                  cols="4"
                  :class="{ 'px-0': $vuetify.breakpoint.mobile }"
                  class="mt-6 text-right"
                >
                  <PesoAmount
                    :amount="getTestPrice(clinicType, details.data)"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div>
                <ol class="ml-7">
                  <li
                    v-for="(test, t) in details.data.tests"
                    :key="t"
                    class="pl-2"
                  >
                    {{ test }}
                  </li>
                </ol>
              </div>

              <div class="ml-7 mt-5">
                <strong>Description:</strong> {{ details.data.description }}
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>

    <div class="text-center">
      <v-btn small rounded @click="onConfirm" tile color="success" class="my-4">
        Confirm
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { getTestPrice } from "@/utilities/appointment";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "Packages",

  components: {
    PesoAmount
  },

  props: {
    clinicType: String,
    selectedPackages: Array,
    subheader: String
  },

  mounted() {
    this.loadTests();
  },

  data: () => ({
    packageList: [],

    getTestPrice: getTestPrice
  }),

  methods: {
    loadTests() {
      this.packageList = [
        ...this.$store.getters["lab/appointment/getPackages"]
      ];

      for (let i in this.packageList) {
        this.packageList[i].isSelected = false;

        if (this.selectedPackages) {
          for (let item of this.selectedPackages) {
            if (item.name === this.packageList[i].data.testname) {
              this.packageList[i].isSelected = true;
              break;
            }
          }
        }
      }
    },

    onConfirm() {
      let packagesSelected = [];

      for (let entry of this.packageList) {
        if (entry.isSelected) {
          packagesSelected.push({
            name: entry.data.testname,
            price: getTestPrice(this.clinicType, entry.data),
            tests: [...entry.data.tests],
            description: entry.data.description
          });
        }
      }

      this.$emit("onPackageSelected", packagesSelected);
    }
  }
};
</script>

<style scoped>
.hide-scrollbar {
  overflow: hidden;
}

.selection-container-parent {
  height: 300px;
  width: 100%;
}

.selection-container {
  height: 102%;
  width: 102%;
  overflow: scroll;
}
</style>
