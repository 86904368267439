import { timeStampToDate } from "@/common/utilities/date";

import store from "@/store";

export const PROMO_CODE = "promoCode";
export const PWD = "PWD";
export const SENIOR_CITIZEN = "Senior Citizen";

export function getPromo(cart) {
  if (cart.isPromotionalPriceApplied && cart.discount > 0) {
    return `(${cart.discount}% Off)`;
  } else if (!cart.isPWDSCPriceApplied || cart.discount <= 0) {
    return "";
  }

  return cart.pwdSc ? "(PWD/SC Price)" : `(${cart.discount}% Off)`;
}

function promoExpired() {
  return {
    isMatch: true,
    quitEvaluation: false,
    amount: 0,
    error: "Promo has expired"
  };
}

function promoNotStarted() {
  return {
    isMatch: true,
    quitEvaluation: false,
    amount: 0,
    error: "Promo not yet started"
  };
}

function promoValid(discount, amount) {
  let value =
    "percentage" === discount.discountType
      ? (amount * discount.value) / 100
      : discount.value;

  return {
    isMatch: true,
    quitEvaluation: true,
    amount: -1 * value,
    description: discount.discountName,
    error: ""
  };
}

function evaluateDiscount(discount, promoCode, amount) {
  if (discount.discountCode !== promoCode) {
    return { isMatch: false };
  }

  let startDate = timeStampToDate(discount.effectiveDate.from);
  let endDate = timeStampToDate(discount.effectiveDate.to);
  let today = new Date();

  if (startDate > today) {
    return promoNotStarted();
  }

  if (endDate <= today) {
    return promoExpired();
  }

  return promoValid(discount, amount);
}

function evaluateDiscounts(discounts, promoCode, amount) {
  let lastResult = {
    amount: 0,
    error: `${promoCode} is not a valid promo code.`
  };

  for (let discount of discounts) {
    let result = evaluateDiscount(discount, promoCode, amount);

    if (result.isMatch) {
      lastResult = { amount: result.amount, error: result.error };
      if (result.quitEvaluation) {
        break;
      }
    }
  }

  return lastResult;
}

export function ComputePromoCodeDiscount(discounts, promoCode, amount) {
  return discounts && discounts.length
    ? evaluateDiscounts(discounts, promoCode, amount)
    : { amount: 0, error: `There are no discounts available.` };
}

export function GetDiscountValue(promoCode, amount) {
  return new Promise((resolve, reject) => {
    store
      .dispatch("loadDiscounts")
      .then(discounts =>
        resolve(ComputePromoCodeDiscount(discounts, promoCode, amount))
      )
      .catch(err => reject(err));
  });
}

export function ComputeDiscount(discounts, subtotal, promo) {
  if (promo) {
    switch (promo.name) {
      case PWD:
      case SENIOR_CITIZEN:
        return subtotal * -0.2;
    }
  }

  return ComputePromoCodeDiscount(discounts, subtotal, promo).amount;
}
