<template>
  <div>
    <v-card flat class="pa-5" :class="{ 'pa-1': $vuetify.breakpoint.mobile }">
      <BackButton @click="routeBack()" class="mt-3 mb-2" />

      <div class="row py-4">
        <div class="col-md-4 col-sm-4 col-xs-12">
          <v-carousel
            v-if="imagecollect.length > 1"
            hide-delimiters
            hide-delimiter-background
            show-arrows
            height="100%"
            width="100%"
          >
            <v-carousel-item
              v-for="(images, dlist) in imagecollect"
              :key="dlist"
              :src="images.data.imageUrl"
              @click="openImage(images.data.imageUrl)"
            />
          </v-carousel>

          <v-img
            v-else-if="1 === imagecollect"
            class="white--text align-end mt-4"
            :src="imagecollect[0].data.imageUrl"
            height="100%"
            width="100%"
          />
          <v-img
            height="100%"
            width="100%"
            v-else
            class="white--text align-end mt-4"
            :src="downloadUrl"
          />
        </div>

        <div class="col-md-7 col-sm-7 col-xs-12">
          <p class="display-1 mb-0 primary--text">{{ name }}</p>

          <v-card-actions class="pa-0">
            <p
              class="headline font-weight-bolder pt-3"
              v-if="priceRange && !variantActive"
            >
              {{ priceRange }}
            </p>

            <p
              class="headline font-weight-bolder pt-3"
              v-else-if="
                (discount > 0 && isNotExpiredPromo(discountUntil)) ||
                  isPWDSCPriceApplied
              "
            >
              <del class="caption mr-1 py-0">
                <PesoAmount :amount="originalPrice" />
              </del>
              <PesoAmount :amount="price" class="mr-1" />

              <strong class="pr-1 white--text discount-text">
                {{ discountText }}
              </strong>
            </p>

            <p class="headline font-weight-bolder pt-3" v-else>
              <PesoAmount :amount="originalPrice" />
            </p>

            <v-spacer />

            <v-btn
              :color="onWishlist ? 'red' : 'gray'"
              @click="addWishlist(!onWishlist)"
              x-small
              icon
            >
              <v-icon>mdi-heart</v-icon>
            </v-btn>
          </v-card-actions>

          <p
            v-show="preOrderFrom > 0"
            class="subtitle-2 primary--text  text-right"
          >
            {{ preOrder }}
          </p>
          <p class="body-2  text-right">{{ category }}</p>

          <p class="subtitle-1 font-weight-thin text-justify">
            {{ description }}
          </p>

          <v-radio-group v-model="optionSelected" class="my-1" row>
            <div class="my-1" v-for="(variant, vlist) in variants" :key="vlist">
              <img
                alt="Product Image"
                v-if="!variant.data.image"
                @click="openVeriantImage(require('@/assets/shopItem.jpg'))"
                :src="require('@/assets/shopItem.jpg')"
                style="width: 50px; height: 50px"
                :contain="true"
              />
              <img
                alt="Product Image"
                v-else
                @click="openVeriantImage(variant.data.image)"
                :src="variant.data.image || ''"
                style="width: 50px; height: 50px"
              />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-radio
                      @change="changetoVariantVal(variant)"
                      :label="variant.data.voption"
                      :value="variant.data.voption"
                    />
                  </span>
                </template>
                <span>{{ variant.data.vname }}</span>
              </v-tooltip>
            </div>
          </v-radio-group>

          <v-checkbox
            v-show="pwdSc"
            v-model="checkboxPwdSc"
            @change="selectPwdSc()"
          >
            <template v-slot:label>
              <span class="checkboxLabel">
                PWD/Senior Citizen
                <span class="statusLabel">{{ isVerifiedPwdSc }}</span>
              </span>
            </template>
          </v-checkbox>

          <div :class="{ centerThis: $vuetify.breakpoint.mobile }">
            <span
              v-if="remaining - reserved + currentUserReserved > 0"
              class="subtitle-2 primary--text"
            >
              Stock: {{ remaining - reserved + currentUserReserved }}
            </span>
          </div>

          <v-text-field
            class="select-container"
            :class="{ centerThis: $vuetify.breakpoint.mobile }"
            outlined
            style="width:100px"
            v-model="quantity"
            dense
            type="number"
            min="0"
            max="100"
          />

          <div
            v-if="hasPrescription"
            :class="{ 'text-center': $vuetify.breakpoint.mobile }"
          >
            <v-btn
              @click="routeTo(ROUTE_RXPRESCRIPTION)"
              class="primary white--text mb-2"
              outlined
              tile
              dense
            >
              <span style="white-space: pre;">Send Prescription</span>
            </v-btn>
          </div>

          <div v-else :class="{ 'text-center': $vuetify.breakpoint.mobile }">
            <v-btn
              width="190"
              @click="runActions"
              class="primary white--text ma-2"
              :disabled="!isInventory || !notZero"
              outlined
              tile
              dense
            >
              <v-icon>mdi-cart</v-icon>
              ADD TO CART
            </v-btn>

            <v-btn
              width="190"
              @click="buyNow"
              class="primary white--text ma-2"
              :class="{ 'ml-2': !$vuetify.breakpoint.mobile }"
              :disabled="!isInventory || !notZero"
              outlined
              tile
              dense
            >
              BUY NOW
            </v-btn>
          </div>
        </div>
      </div>

      <v-row>
        <v-col cols="12" sm="8">
          <Reviews :productSKU="docid" :key="docid" />
        </v-col>

        <v-col cols="12" sm="4">
          <EventPolicy />
        </v-col>
      </v-row>

      <v-dialog v-model="dialogPreview" width="600">
        <ImagePreview
          class="pa-0"
          :key="selectedImage"
          :imageUrl="selectedImage"
          @close-dialog="closeDialogs"
        />
      </v-dialog>

      <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="dialog"
        width="600"
      >
        <UploadPwdCs
          :type="type"
          @close-dialog="closeDialog"
          @close-dialog2="closeDialog2"
        />
      </v-dialog>

      <v-snackbar top right color="primary" v-model="snackbar" :timeout="1000">
        {{ snackbarText }}
      </v-snackbar>

      <ConfirmDialog ref="confirm" />
    </v-card>
  </div>
</template>

<script>
import {
  ROUTE_CART,
  ROUTE_RXPRESCRIPTION,
  ROUTE_SIGNIN,
  ROUTE_STORES,
  routeBack,
  routeTo
} from "@/router";
import {
  TEMPORARY_CART,
  TEMPORARY_WISHLIST,
  updateCart
} from "@/utilities/collection/addtocart";

import { buildTemporaryDetails } from "@/utilities/tindahan";
import {
  getCurrentUserReservedItemsByVariantCount,
  getReservedItemsByVariantCount
} from "@/utilities/collection/products";
import { getCurrentUserUid, logUserActivity } from "@/common/store";
import { isNotExpiredPromo } from "../utilities/discounts";
import { loadItems } from "@/utilities/collection/productvariant";
import { loadProductImg } from "@/store";
import { mapActions, mapGetters } from "vuex";
import { updateWishlist } from "@/utilities/collection/catalog";

import BackButton from "@/components/Controls/BackButton.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import EventPolicy from "@/eTindahan/components/EventPolicy.vue";
import ImagePreview from "@/eTindahan/components/ImagePreview.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";
import Reviews from "@/eTindahan/components/Reviews.vue";
import UploadPwdCs from "@/eTindahan/components/UploadPwdCs.vue";

export default {
  name: "eTindahanProductDetails",

  components: {
    BackButton,
    ConfirmDialog,
    EventPolicy,
    ImagePreview,
    PesoAmount,
    Reviews,
    UploadPwdCs
  },

  data() {
    return {
      category: "",
      checkboxPwdSc: false,
      currentUserReserved: 0,
      description: "",
      dialog: false,
      dialogPreview: false,
      discount: 0,
      discountText: "",
      discountUntil: null,
      docid: "",
      downloadUrl: "",
      imagecollect: [],
      iname: "",
      inventory: 0,
      isPromotionalPriceApplied: false,
      isPWDSCPriceApplied: false,
      itemShippingFee: 0,
      merchantName: "",
      name: "",
      onWishlist: false,
      optionSelected: "",
      originalPrice: 0,
      owner: "",
      preOrderFrom: 0,
      preOrderTo: 0,
      price: 0,
      priceRange: "",
      promotionalDiscount: 0,
      promotionalPrice: 0,
      pwdSc: false,
      quantity: 0,
      reserved: 0,
      selectedImage: "",
      snackbar: false,
      snackbarText: "",
      type: "",
      variantActive: false,
      variantname: "",
      variantoption: "",
      variantPrice: 0,
      variants: [],
      vproductSKU: "",
      wishedBy: [],

      ROUTE_RXPRESCRIPTION: ROUTE_RXPRESCRIPTION,

      // functions
      isNotExpiredPromo: isNotExpiredPromo,
      routeBack: routeBack,
      routeTo: routeTo
    };
  },

  mounted() {
    this.iname = getCurrentUserUid();
    let productDetails = this.getProductDetails;
    this.docid = productDetails.docid;
    this.owner = productDetails.owner || this.iname;
    this.name = productDetails.name;
    this.description = productDetails.description;
    this.downloadUrl = productDetails.downloadUrl;
    this.category = productDetails.category;

    this.itemShippingFee = productDetails.itemShippingFee;
    this.merchantName = productDetails.merchantName;
    this.preOrderFrom = productDetails.preOrderFrom;
    this.preOrderTo = productDetails.preOrderTo;

    this.priceRange = productDetails.priceRange;
    this.price = productDetails.price;
    this.originalPrice = productDetails.price;
    this.discount = productDetails.discount;
    this.discountUntil = productDetails.discountUntil;

    this.inventory = productDetails.inventory;
    this.pwdSc = productDetails.pwdSc;
    this.onWishlist = this.wishedBy.includes(this.iname);
    this.wishedBy = productDetails.wishedBy;

    loadItems(this.docid).then(docs => {
      this.variants = docs;
      if (this.variants.length) {
        this.inventory = 0;
      }
    });

    this.loadProductImg();

    if (this.isVerifiedPwdSc) {
      this.checkboxPwdSc = this.isVerifiedPwdSc;
    }
  },

  computed: {
    ...mapGetters(["getProductDetails", "getPwdScDetails", "isLoggedIn"]),

    hasPrescription() {
      return "Prescription Drugs (Rx)" === this.category;
    },

    hasVariant() {
      return this.variants.length;
    },

    isInventory() {
      return this.inventory >= this.quantity;
    },

    isVerifiedPwdSc() {
      return this.isVerified(this.getPwdScDetails);
    },

    notZero() {
      return this.quantity > 0;
    },

    preOrder() {
      return `Pre-order ${this.preOrderFrom} - ${this.preOrderTo} days`;
    },

    remaining() {
      return this.inventory;
    }
  },

  watch: {
    optionSelected() {
      if (this.checkboxPwdSc && this.pwdSc) {
        this.setPwdScDiscount();
      } else {
        this.setDiscount();
      }
    }
  },

  methods: {
    ...mapActions(["loadCart"]),

    addItemsAndReloadCart() {
      return new Promise((resolve, reject) => {
        let details = this.constructCartDetails();
        this.$store
          .dispatch("addToCart", details)
          .then(() => {
            return this.loadCart();
          })
          .then(() => resolve(details))
          .catch(err => reject(err));
      });
    },

    addWishlist(onWishlist) {
      if (!this.isLoggedIn) {
        window.sessionStorage.setItem(
          TEMPORARY_WISHLIST,
          JSON.stringify({
            docid: this.docid,
            onWishlist: onWishlist
          })
        );
        routeTo(ROUTE_SIGNIN);
      } else {
        this.onWishlist = onWishlist;
        this.snackbarText =
          (onWishlist ? "Added to" : "Removed from") + " Wishlist";
        this.snackbar = true;

        updateWishlist({
          docid: this.docid,
          iname: this.iname,
          onWishlist: onWishlist
        });
      }
    },

    buyNow() {
      if (!this.isLoggedIn) {
        this.buyNowAnonymous();
      } else {
        this.addItemsAndReloadCart()
          .then(details => {
            return logUserActivity("Buy Now", details);
          })
          .then(() => {
            routeTo(ROUTE_CART);
          });
      }
    },

    buyNowAnonymous() {
      let options = {
        variantoption: this.variantoption,
        variantname: this.variantname,
        hasVariant: this.hasVariant,
        quantity: this.quantity,
        vproductSKU: this.vproductSKU
      };

      window.sessionStorage.setItem(
        TEMPORARY_CART,
        JSON.stringify({
          productDetails: this.getProductDetails,
          options: options
        })
      );

      window.sessionStorage.setItem("routeAfterLogin", ROUTE_CART);

      routeTo(ROUTE_SIGNIN);
    },

    calcPwdScDetails(originalPrice) {
      return originalPrice * 0.8;
    },

    changetoVariantVal(variant) {
      this.variantActive = true;
      this.vproductSKU = variant.id;
      this.originalPrice = variant.data.vprice;
      this.price = variant.data.vprice;
      this.inventory = variant.data.vstock;
      this.variantoption = variant.data.voption;
      this.variantname = variant.data.vname;
      this.promotionalDiscount = variant.data.vdiscount;
      this.discount = variant.data.vdiscount;
      this.discountUntil = new Date(variant.data.vdiscountUntil.seconds * 1000);

      getReservedItemsByVariantCount(variant.id).then(size => {
        this.reserved = size;
      });

      getCurrentUserReservedItemsByVariantCount(variant.id).then(size => {
        this.currentUserReserved = size;
      });

      let now = new Date();
      if (
        this.discountUntil > now &&
        Number(this.discount) > 0 &&
        this.discount
      ) {
        this.price = (Number(this.price) * (100 - this.discount)) / 100;
        this.promotionalPrice = this.price;
      }

      this.variantDiscount = this.discount;
      this.variantPrice = this.price;
    },

    checkAndAddToCart() {
      return new Promise(resolve => {
        this.loadCart()
          .then(docs => {
            let existed = false;

            for (let key of docs) {
              if (this.vproductSKU === key.vproductSKU) {
                updateCart(key.vproductSKU, Number(key.quantity));
                existed = true;
              }
            }

            if (!existed || !docs.length) {
              this.addItemsAndReloadCart();
            }

            resolve(existed);
          })
          .catch(() => resolve(false));
      });
    },

    closeDialog() {
      this.dialog = false;
      this.checkboxPwdSc = false;
      this.selectPwdSc();
    },

    closeDialog2() {
      this.dialog = false;
      this.selectPwdSc();
      this.showConfirmationMessageSuccess();
    },

    closeDialogs() {
      this.dialogPreview = false;
    },

    constructCartDetails() {
      return {
        // these are items from catalog
        productSKU: this.docid,
        name: this.name,
        description: this.description,
        imageUrl: this.downloadUrl,
        category: this.category,
        isPulled: true,
        owner: this.owner,
        discountUntil: this.discountUntil,

        itemShippingFee: this.itemShippingFee,
        merchantName: this.merchantName,
        preOrderFrom: this.preOrderFrom,
        preOrderTo: this.preOrderTo,

        // these items are from catalog if item is not yet sold but can be
        // updated during actual sale transaction
        cartStatus: "oncart",
        originalPrice: this.originalPrice,
        price: this.price,
        discount: this.discount,
        promotionalDiscount: this.promotionalDiscount,
        promotionalPrice: this.promotionalPrice,
        isPromotionalPriceApplied: this.isPromotionalPriceApplied,
        isPWDSCPriceApplied: this.isPWDSCPriceApplied,
        quantity: this.quantity,
        soldTo: this.iname,
        soldOn: new Date(),
        hasVariant: this.hasVariant,
        variation: this.variantoption,
        variantname: this.variantname,
        vproductSKU: this.vproductSKU,
        pwdSc: this.pwdSc
      };
    },

    isVerified(details) {
      if (0 === Object.keys(details).length) {
        return "";
      } else {
        return details.isVerified
          ? "(ID Verified)"
          : "(Pending ID Verification)";
      }
    },

    loadProductImg() {
      loadProductImg(this.docid).then(docs => {
        this.imagecollect = docs;
      });
    },

    openVeriantImage(image) {
      this.selectedImage = image;
      this.dialogPreview = true;
    },

    runActions() {
      if (!this.isLoggedIn) {
        let options = {
          variantoption: this.variantoption,
          variantname: this.variantname,
          hasVariant: this.hasVariant,
          quantity: this.quantity,
          vproductSKU: this.vproductSKU,
          originalPrice: this.originalPrice,
          price: this.price,
          discount: this.discount,
          discountUntil: this.discountUntil,

          promotionalDiscount: this.promotionalDiscount,
          promotionalPrice: this.promotionalPrice,
          isPromotionalPriceApplied: this.isPromotionalPriceApplied,
          isPWDSCPriceApplied: this.isPWDSCPriceApplied
        };

        let productDetails = buildTemporaryDetails({
          productDetails: this.getProductDetails,
          options: options
        });

        window.sessionStorage.setItem(
          TEMPORARY_CART,
          JSON.stringify(productDetails)
        );

        window.sessionStorage.setItem("routeAfterLogin", ROUTE_STORES);
        routeTo(ROUTE_SIGNIN);
        return;
      }

      this.checkAndAddToCart().then(existed => {
        this.snackbarText = existed ? "Item Updated" : "Added to Cart";
        this.snackbar = true;
      });
    },

    selectPwdSc() {
      if (!this.checkboxPwdSc) {
        this.setDiscount();
      } else {
        this.setPwdScDiscount();
        if (!this.isVerifiedPwdSc) {
          this.dialog = true;
        }
      }
    },

    setDiscount() {
      this.isPWDSCPriceApplied = false;
      this.discount = this.variantDiscount;
      this.isPromotionalPriceApplied =
        this.discount > 0 && isNotExpiredPromo(this.discountUntil);
      this.price = this.variantPrice;
      this.discountText = `${this.discount}% Off `;
    },

    setPwdScDiscount() {
      this.isPWDSCPriceApplied = true;
      this.isPromotionalPriceApplied =
        this.discount > 0 && isNotExpiredPromo(this.discountUntil);
      this.price = this.calcPwdScDetails(this.originalPrice);
      this.discount = 20;
      this.discountText = "20% Off PWD/SC Discount";
    },

    showConfirmationMessageSuccess() {
      this.$refs.confirm.open(
        "Confirmed",
        `Na-submit na po ang inyong PWD/SC ID. Maraming salamat po!`,
        { noconfirm: true }
      );
    }
  }
};
</script>

<style scoped>
.centerThis {
  margin: auto;
  text-align: center;
  width: 150px;
}

.checkboxLabel {
  color: #558b2f;
  font-size: 14px;
}

.col-sm-4 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.discount-text {
  background-color: #558b2f;
  font-style: italic;
  font-size: 11px;
}

.right {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 3;
}

.select-container >>> input {
  text-align: center;
}

.statusLabel {
  color: #558b2f;
  font-style: italic;
  font-size: 12px;
}
</style>
