import { DB, logPaymentTransaction } from "@/common/store";
import {
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_PAYMENT_VERIFICATION,
  ORDER_STATUS_PROCESSING
} from "@/common/utilities/order";
import {
  OPTION_BANTAY_KARD,
  PAYMENT_BANTAY_KARD,
  PAYMENT_CASH_ON_DELIVERY,
  PAYMENT_EXCESS_AMOUNT,
  PAYMENT_REFUND,
  PAYMENT_STATUS_FOR_VERIFICATION,
  PAYMENT_STATUS_VERIFIED,
  STATUS_SUCCESS,
  TARGET_NEW_PAYMENT,
  TARGET_PAYMENT,
  TARGET_REFUND_PAYMENT,
  TARGET_RETURN_REFUND_PAYMENT,
  TYPE_REFUND
} from "@/common/utilities/payment";

import { db } from "@/main";
import { IsCashOnDelivery } from "../common/utilities/payment";
import { setTransactionHistory } from "@/eTindahan/utilities/orders";

import store from ".";

const GCASH_USED = "processed";

function setPaymentCheckout(payment, paymentId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.CHECKOUT)
      .doc(payment.transactionDocId)
      .set(
        {
          payments: {
            [paymentId]: payment
          }
        },
        { merge: true }
      )
      .then(() => resolve(payment))
      .catch(err => reject(err));
  });
}

function setBantayKard(details, paymentId, type, status) {
  if (OPTION_BANTAY_KARD === details.isExcessPayment && details.paymentOption) {
    let transaction = {
      amount: Math.abs(details.excessAmount),
      description: PAYMENT_EXCESS_AMOUNT,
      referenceNumber: paymentId,
      type: type,
      status: status
    };

    store.dispatch("addBantayKardTransaction", transaction);
  }
}

function addPaymentRecord(timestamp, content, passThroughPayment, operation) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PAYMENT)
      .add({ ...passThroughPayment })
      .then(doc => {
        setBantayKard(content, doc.id, TYPE_REFUND, STATUS_SUCCESS);
        return setPaymentCheckout(passThroughPayment, doc.id);
      })
      .then(() => {
        return logPaymentTransaction(timestamp, operation, content);
      })
      .then(() => resolve(passThroughPayment))
      .catch(err => reject(err));
  });
}

function getDefaultPaymentStatus(modeOfPayment, target) {
  if (
    [TARGET_PAYMENT, TARGET_NEW_PAYMENT, TARGET_REFUND_PAYMENT].includes(target)
  ) {
    switch (modeOfPayment) {
      case PAYMENT_BANTAY_KARD:
      case PAYMENT_CASH_ON_DELIVERY:
        return PAYMENT_STATUS_VERIFIED;
    }
  }

  return PAYMENT_STATUS_FOR_VERIFICATION;
}

function getModeOfPayment(data) {
  return data.isExcessPayment || data.refundAmount
    ? PAYMENT_REFUND
    : data.modeOfPayment;
}

function getSubset(data, keys) {
  return keys.reduce((result, key) => {
    if (data[key]) {
      result[key] = data[key];
    }

    return result;
  }, {});
}

function getSpecificPaymentDetails(data) {
  return getSubset(data, [
    "accountBank",
    "accountContact",
    "accountName",
    "accountNumber",
    "excessAmount",
    "isExcessPayment",
    "paymentOption",
    "referenceNumber",
    "bankCharge"
  ]);
}

function getTindahanSpecificDetails(data) {
  return getSubset(data, [
    "houseNumber",
    "landmark",
    "paymentBalance",
    "paymentMade",
    "receivingOption",
    "referenceNumber",
    "selectedLocation",
    "street"
  ]);
}

function getTotalVerifiedPayment(data, target) {
  switch (target) {
    case TARGET_PAYMENT:
    case TARGET_REFUND_PAYMENT:
      return Number(data.total);

    case TARGET_RETURN_REFUND_PAYMENT:
      return Number(data.totalVerifiedPayments);

    case TARGET_NEW_PAYMENT:
      return (
        Number(data.totalVerifiedPayments) +
        (IsCashOnDelivery(data.modeOfPayment) ? data.paymentBalance : 0)
      );

    default:
      return 0;
  }
}

function getTransactionAmount(details, target) {
  switch (target) {
    case TARGET_NEW_PAYMENT:
      return details.paymentBalance;

    case TARGET_PAYMENT:
      return details.total;

    case TARGET_REFUND_PAYMENT:
      return details.excessAmount;

    case TARGET_RETURN_REFUND_PAYMENT:
      return details.refundAmount;

    default:
      return 0;
  }
}

function constructCheckoutPaymentDetails(data, timestamp, target) {
  return {
    ...getTindahanSpecificDetails(data),

    name: data.name,
    soldTo: data.userUid || data.soldTo,
    soldOn: timestamp,

    houseNumber: data.houseNumber,
    street: data.street,
    landmark: data.landmark,
    billingAddress: data.billingAddress,

    contact: data.contact,
    courier: data.courier,

    promoType: data.promoType,
    discountValue: data.discountValue,

    subtotal: data.subtotal,
    shippingFee: data.shippingFee,
    total: data.total,

    status: data.status,
    modeOfPayment: getModeOfPayment(data),

    totalVerifiedPayments: getTotalVerifiedPayment(data, target),
    trackingNumber: data.trackingNumber,
    fullqr: data.fullqr
  };
}

function constructPaymentData(timestamp, operation, details, target) {
  return {
    ...getSpecificPaymentDetails(details),
    name: details.name,
    userUid: details.userUid || details.soldTo,

    operation: operation,
    transactionDocId: details.transactionDocId,
    transactionNumber: details.fullqr,
    transactionAmount: getTransactionAmount(details, target),
    transactionTimestamp: timestamp,

    modeOfPayment: getModeOfPayment(details),

    status: getDefaultPaymentStatus(details.modeOfPayment, target),
    target: target
  };
}

export function checkOutPayment(details) {
  return new Promise((resolve, reject) => {
    let target = TARGET_PAYMENT;
    let timestamp = new Date();
    let content = constructCheckoutPaymentDetails(details, timestamp, target);
    let operation = "e-Tindahan Payment";
    db.collection(DB.CHECKOUT)
      .add({ ...content })
      .then(doc => {
        content.transactionDocId = doc.id;
        details.transactionDocId = doc.id;

        let payments = constructPaymentData(
          timestamp,
          operation,
          details,
          target
        );

        return addPaymentRecord(
          timestamp,
          content,
          { id: doc.id, ...payments },
          operation
        );
      })
      .then(doc => resolve(doc))
      .catch(err => reject(err));
  });
}

function getOrderStatus(modeOfPayment, target) {
  if (
    [TARGET_PAYMENT, TARGET_NEW_PAYMENT, TARGET_REFUND_PAYMENT].includes(
      target
    ) &&
    PAYMENT_CASH_ON_DELIVERY === modeOfPayment
  ) {
    return ORDER_STATUS_PROCESSING;
  }

  return TARGET_RETURN_REFUND_PAYMENT === target
    ? ORDER_STATUS_COMPLETED
    : ORDER_STATUS_PAYMENT_VERIFICATION;
}

function setOrderStatus(content, target) {
  return new Promise((resolve, reject) => {
    db.collection(DB.CHECKOUT)
      .doc(content.transactionDocId)
      .set(
        {
          status: getOrderStatus(content.modeOfPayment, target),
          paymentStatus: target
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function checkOutNewPayment(details) {
  let target = TARGET_NEW_PAYMENT;
  let timestamp = new Date();
  let operation = "e-Tindahan Payment";
  let comment = `New payment was made through ${details.modeOfPayment}.`;

  let content = constructCheckoutPaymentDetails(details, timestamp, target);

  content.transactionDocId = details.id;
  details.transactionDocId = details.id;

  let payments = constructPaymentData(timestamp, operation, details, target);
  setOrderStatus(content, target);
  setTransactionHistory(details, timestamp, comment);

  return addPaymentRecord(
    timestamp,
    content,
    {
      id: details.id,
      ...payments
    },
    operation
  );
}

export function checkOutRefundPayment(details) {
  const target = TARGET_REFUND_PAYMENT;
  const timestamp = new Date();
  let content = constructCheckoutPaymentDetails(details, timestamp, target);
  if (details.isExcessPayment && OPTION_BANTAY_KARD === details.paymentOption) {
    content.totalVerifiedPayments =
      details.totalVerifiedPayments - Math.abs(details.excessAmount);
  }
  content.transactionDocId = details.id;

  const operation = "e-Tindahan Payment";
  let payments = constructPaymentData(timestamp, operation, details, target);
  payments.transactionDocId = details.id;

  const comment = `Refund ${details.excessAmount} Excess Amount to ${details.paymentOption}.`;
  setTransactionHistory(details, timestamp, comment);
  setOrderStatus(content, target);

  return addPaymentRecord(
    timestamp,
    content,
    {
      id: details.id,
      ...payments
    },
    operation
  );
}

export function checkOutReturnRefundPayment(details) {
  details.transactionDocId = details.id;

  const comment = `${details.reinstateOption} request through ${details.paymentOption} transfer is being process.`;

  let content = details;
  content.transactionDocId = details.id;
  const operation = "e-Tindahan Payment";
  const target = TARGET_RETURN_REFUND_PAYMENT;
  const timestamp = new Date();
  const payments = constructPaymentData(timestamp, operation, details, target);

  setOrderStatus(content, target);
  setTransactionHistory(details, timestamp, comment);

  return addPaymentRecord(
    timestamp,
    content,
    {
      id: details.id,
      ...payments
    },
    operation
  );
}

export function getUnusedGCashTransaction(referenceNumber) {
  return new Promise((resolve, reject) => {
    db.collection(DB.GCASH_RECEIPT)
      .where("referenceNumber", "==", referenceNumber)
      .get()
      .then(snapshot => {
        let content = null;

        snapshot.forEach(doc => {
          let docData = doc.data();

          if (!content && (!docData.status || GCASH_USED !== docData.status)) {
            content = { id: doc.id, ...docData };
          }
        });

        resolve(content);
      })
      .catch(err => reject(err));
  });
}

export function setGcashToProcessed(docId, orderId) {
  return new Promise((resolve, reject) => {
    db.collection(DB.GCASH_RECEIPT)
      .doc(docId)
      .set(
        {
          orderId: orderId,
          status: GCASH_USED,
          usedOn: new Date()
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}
