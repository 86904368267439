<template>
  <div>
    <v-card
      v-for="(address, index) in addressBook"
      :key="index"
      class="mx-auto my-1"
      max-width="444"
    >
      <v-card-text>
        <div class="text--primary">
          {{ address.name }}, {{ address.contact }}
        </div>

        <div>
          {{ getDeliveryAddress(address) }}
        </div>
      </v-card-text>

      <v-card-actions v-if="isSelectMode">
        <v-spacer />
        <v-btn
          text
          outlined
          x-small
          color="primary"
          @click="selectAddress(address, `${ROUTE_CHECKOUT}/addressSelected`)"
        >
          Select
        </v-btn>
      </v-card-actions>

      <v-card-actions v-else>
        <v-btn
          disabled
          v-if="defaultAddress === index"
          rounded
          x-small
          color="primary"
        >
          Default Address
        </v-btn>
        <v-btn
          v-else
          rounded
          x-small
          color="primary"
          @click="makeDefaultAddress(index, address)"
        >
          Make default
        </v-btn>

        <v-spacer />

        <v-btn
          rounded
          x-small
          color="primary"
          @click="openEdit(address, index, defaultAddress === index)"
        >
          Edit
        </v-btn>
        <v-btn
          :disabled="defaultAddress === index"
          text
          outlined
          x-small
          color="red"
          @click="deleteAddress(index)"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>

    <ConfirmDialog ref="confirm" @onClose="$emit('close-dialog')" />
  </div>
</template>

<script>
import { ROUTE_CHECKOUT, routeTo } from "@/router";

import { deleteAddressBook, loadProfileAddress } from "@/utilities/tindahan";
import { getAddress, getFullAddress } from "@/common/utilities/location";
import { makeDefault } from "@/store";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";

export default {
  name: "AddressInput",

  components: {
    ConfirmDialog
  },

  props: {
    mode: String
  },

  data: () => ({
    addressBook: [],
    defaultAddress: [],

    ROUTE_CHECKOUT: ROUTE_CHECKOUT
  }),

  mounted() {
    this.loadProfile();
  },

  computed: {
    isSelectMode() {
      return "select" === this.mode;
    }
  },

  methods: {
    async deleteAddress(index) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to remove on your Address Book?",
          { noconfirm: false }
        )
      ) {
        deleteAddressBook(index).then(() => {
          this.loadProfile();
        });
      }
    },

    getDeliveryAddress(address) {
      return `${getFullAddress(address.selectedLocation)}, ${getAddress(
        address
      )}`;
    },

    loadProfile() {
      loadProfileAddress().then(profile => {
        this.addressBook = profile.addressBook;
        this.defaultAddress = profile.defaultAddress;
        let topDefault = [];

        if (this.addressBook) {
          Object.keys(this.addressBook).forEach(key => {
            if (
              1 === Object.keys(this.addressBook).length &&
              !profile.defaultAddress
            ) {
              this.makeDefaultAddress(key, profile.addressBook[key]);
              return;
            }

            if (key === profile.defaultAddress) {
              topDefault.push({ [key]: profile.addressBook[key] });
              delete profile.addressBook[key];
            }
            this.addressBook = { ...topDefault[0], ...this.addressBook };
          });
        }
      });
    },

    makeDefaultAddress(index, address) {
      makeDefault({ defaultAddress: index, address: address }).then(() => {
        this.loadProfile();
      });
    },

    openEdit(address, index, isDefault) {
      this.$emit("open-edit", {
        address: address,
        index: index,
        isDefault: isDefault
      });
    },

    async selectAddress(address, page) {
      await this.$store.dispatch("setSelectedAddress", address);
      routeTo(page);
    }
  }
};
</script>
