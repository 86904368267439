<template>
  <v-container>
    <BackButton @click="$emit('back')" />

    <p class="text-center pt-3 mt-7">
      Please select a location where you want to avail laboratory services then
      click NEXT to continue.
    </p>

    <SelectLocation
      :dbLocation="dbLocations"
      :selectedLocation.sync="selectedLocation"
    />

    <div v-if="isHomeServiceClinic">
      <v-text-field
        v-model="housenumber"
        :rules="[v => !!v || 'Address details required']"
        label="House No. / Building No., Subdivisiom"
      ></v-text-field>

      <v-text-field
        v-model="contact"
        label="Contact Number"
        :rules="[v => !!v || 'Contact number required']"
      ></v-text-field>
    </div>

    <NextButton
      :disabled="
        !selectedLocation ||
          !selectedLocation.barangay ||
          (isHomeServiceClinic && (!contact || !housenumber))
      "
      @click="emitNext()"
    />
  </v-container>
</template>

<script>
import BackButton from "@/components/Controls/BackButton.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import SelectLocation from "@/components/SelectLocation.vue";

export default {
  name: "RemoteServices",

  components: {
    BackButton,
    NextButton,
    SelectLocation
  },

  props: {
    dbLocations: String,
    isHomeServiceClinic: Boolean
  },

  data: () => ({
    contact: "",
    housenumber: "",

    selectedLocation: null
  }),

  methods: {
    emitNext() {
      this.$emit("next", {
        contact: this.contact,
        housenumber: this.housenumber,
        selectedLocation: this.selectedLocation
      });
    }
  }
};
</script>
