import { STATUS_REQUESTED } from "@/common/utilities/appointments";

import { getCurrentUser, getCurrentUserUid } from "@/common/store";

export function ConstructDataEntry(data) {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  let returnData = {
    patients: [...data.patients],
    clinicType: data.clinicType,
    appointment: { ...data.appointment },
    schedule: { ...data.schedule },
    bill: {
      ...data.bill,
      dateToPay: tomorrow
    },
    payment: { ...data.payment },
    service: { ...data.service },
    status: STATUS_REQUESTED,
    timestamp: new Date(),
    user: {
      id: getCurrentUserUid(),
      name: getCurrentUser()
    }
  };

  if (data.id) {
    returnData["id"] = data.id;
  }

  return returnData;
}
