import { valueOrEmptyString } from "./text";

export function getFullName(userData) {
  return [
    valueOrEmptyString(userData.firstName),
    valueOrEmptyString(userData.middleName),
    valueOrEmptyString(userData.lastName)
  ].join(" ");
}

export function getFullNameLoaded(userData) {
  return [
    valueOrEmptyString(userData.firstname),
    valueOrEmptyString(userData.middlename),
    valueOrEmptyString(userData.lastname)
  ].join(" ");
}

export function getLabScheduleProfile(userData) {
  return {
    userCode: valueOrEmptyString(userData.userCode),
    firstName: valueOrEmptyString(userData.firstName),
    middleName: valueOrEmptyString(userData.middleName),
    lastName: valueOrEmptyString(userData.lastName),

    sex: valueOrEmptyString(userData.sex),
    birthday: valueOrEmptyString(userData.birthday),

    isPWD: userData.isPWD || false,
    seniorOrPwdId: valueOrEmptyString(userData.seniorOrPwdId),

    contactNumber: valueOrEmptyString(userData.contactNumber),
    attendingPhysician: valueOrEmptyString(userData.attendingPhysician)
  };
}
