const CODE_LENGTH = 5;
const CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function generateUserCode() {
  let result = "";
  const charactersLength = CHARACTERS.length;
  for (let i = 0; i < CODE_LENGTH; i++) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
  }

  result = String(new Date().getFullYear() % 100) + result;

  let crc = 0;
  for (let i = 0; i < result.length; i++) {
    crc += result.charCodeAt(i);
  }
  crc %= 10;

  return result + String(crc);
}

export function verifyUserCode(userCode) {
  if (CODE_LENGTH + 2 + 1 !== userCode.length) {
    return false;
  }

  if (isNaN(userCode.charAt(0)) || isNaN(userCode.charAt(1))) {
    return false;
  }

  let crc = userCode.charCodeAt(0) + userCode.charCodeAt(1);
  for (let i = 2; i < userCode.length - 1; i++) {
    if (!CHARACTERS.includes(userCode.charAt(i))) {
      return false;
    }

    crc += userCode.charCodeAt(i);
  }
  crc %= 10;

  return String(crc) === userCode.charAt(userCode.length - 1);
}
