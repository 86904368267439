<template>
  <v-container>
    <div>
      <v-row style="height: 10px;">
        <v-col cols="9" sm="6" />
        <v-col
          cols="3"
          sm="6"
          class="py-0 pr-8 text-right"
          :class="{ 'px-0 text-center': $vuetify.breakpoint.mobile }"
        >
          <v-divider />
        </v-col>
      </v-row>

      <v-row class="font-weight-bold">
        <v-col md="9" sm="6" class="pt-0 text-right">
          {{ promo && (promo.promoCode || promo.name) ? "Subtotal" : "Total" }}
        </v-col>
        <v-col
          md="3"
          sm="6"
          class="pt-0 text-right"
          :class="{ 'px-0 text-center': $vuetify.breakpoint.mobile }"
        >
          <PesoAmount :amount="subtotal" />
        </v-col>
      </v-row>
    </div>

    <div v-show="promo && (promo.promoCode || promo.name)" class="mb-2 mt-5">
      <v-row>
        <v-col cols="9" sm="6" class="font-weight-bold pt-0">
          LESS: {{ promo.description }}
        </v-col>
        <v-col
          cols="3"
          sm="6"
          class="pt-0  text-right"
          :class="{ 'px-0 text-center': $vuetify.breakpoint.mobile }"
        >
          <PesoAmount :amount="promo.amount" />
        </v-col>
      </v-row>
    </div>

    <div v-show="promo.promoCode || promo.name">
      <v-row style="height: 10px;">
        <v-col md="9" sm="6"></v-col>
        <v-col
          md="3"
          sm="6"
          class="py-0  text-right"
          :class="{ 'px-0 text-center': $vuetify.breakpoint.mobile }"
        >
          <v-divider />
        </v-col>
      </v-row>

      <v-row class="font-weight-bold">
        <v-col cols="9" sm="6" class="py-0 text-right">
          Total
        </v-col>
        <v-col
          cols="3"
          sm="6"
          class="py-0  text-right"
          :class="{ 'px-0 text-center': $vuetify.breakpoint.mobile }"
        >
          <PesoAmount :amount="subtotal + promo.amount" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "TotalAmount",

  components: {
    PesoAmount
  },

  props: {
    promo: Object,
    subtotal: Number
  }
};
</script>
