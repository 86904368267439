<template>
  <div>
    <v-parallax height="250" dark :src="require('../assets/parallax.png')">
      <v-layout align-center column justify-center>
        <h1 class="display-2 font-weight-thin mb-3">
          <strong ref="porto">MediSure Plus Services </strong>
        </h1>
      </v-layout>
    </v-parallax>

    <v-container>
      <v-layout row>
        <v-flex xs12 md12>
          <div text class="card--flex-toolbar">
            <v-container fluid grid-list-lg>
              <v-layout row wrap>
                <v-flex xs12>
                  <h2 class="white--text">Discover MediSure</h2>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm6 md6 lg4 v-for="item in links" :key="item.id">
                  <v-hover>
                    <v-card
                      slot-scope="{ hover }"
                      :class="`elevation-${hover ? 12 : 2}`"
                    >
                      <v-card
                        flat
                        aspect-ratio="2.75"
                        height="44"
                        :color="item.color"
                      >
                        <h3 class="white--text headline mb-0 text-center pa-2">
                          {{ item.title }}
                        </h3>
                      </v-card>

                      <v-card-text>
                        <div>
                          <div class="text-justify body-1">
                            <v-icon
                              size="33"
                              class="mr-2 mt-2 float-left"
                              :color="item.color"
                            >
                              {{ item.icon }}
                            </v-icon>
                            {{ item.description }}
                          </div>
                        </div>
                      </v-card-text>

                      <v-card-actions>
                        <a href="#" v-scroll-to="item.linkgetstarted">
                          <v-btn text color="primary" class="learn-more-btn">
                            Learn more
                          </v-btn>
                        </a>
                        <v-spacer />
                        <v-btn
                          id="insurancesection"
                          text
                          color="primary"
                          :to="item.link"
                        >
                          Get started
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <v-parallax height="20" dark :src="require('../assets/parallax.png')">
      <v-layout align-center column justify-center />
    </v-parallax>

    <v-card color="secondary" dark>
      <v-row
        color="grey lighten-4"
        align="center"
        justify="center"
        class="fill-height"
      >
        <v-card
          flat
          color="secondary"
          class="headline text-center px-0 py-3"
          width="900"
        >
          e-Insurance

          <v-card-text dark>
            <div class="text-justify body-1">
              <p>
                Piso lamang kada araw o ₱365 ang "Alalay sa Buhay" life
                insurance na handog sa inyo ng Country Bankers. Insured ka na ng
                ₱ 25,000 sa isang taon.
              </p>
            </div>

            <div class="text-justify body-1">
              <p>
                Mag rehistro at piliin ang e-Insurance, at isulat lamang ang
                lahat ng detalye na kakailanganin. Isumite rin ang inyong
                pinakabagong larawan. Piliin ang mga paraan sa pagbayad at
                hintayin ang "verification". Matatanggap ninyo ang inyong
                insurance policy mula sa Country Bankers.
              </p>
            </div>

            <div class="text-justify body-1">
              <p>
                Maaari rin kayong pumili ng mas mataas na insurance coverage sa
                halagang abot-kaya. Makikita niyo ang iba't-ibang insurance
                coverage sa e-Insurance.
              </p>
            </div>

            <div class="text-justify body-1">
              <p>
                Para sa mga karagdagang katanungan, makipag-ugnayan lamang sa FB
                Messenger, WhatsApp at Viber ng MediSure Team.
              </p>
            </div>

            <div class="text-center body-1">
              <a class="linkcolor">
                https://life.countrybankers.com/alalay-sa-buhay/
              </a>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn outlined :to="ROUTE_INSURANCE">
              Enroll Now
            </v-btn>
          </v-card-actions>
        </v-card>

        <br />
      </v-row>
    </v-card>

    <v-container>
      <v-row no-gutters>
        <v-card-text class="text-center" tile outlined>
          <div class="row">
            <div
              class="col-12 col-md-3 col-sm-6 col-xs-6 text-center"
              v-for="item in insurances"
              :key="item.id"
            >
              <v-hover v-slot:default="{ hover }" open-delay="200">
                <v-card height="400" :elevation="hover ? 16 : 2">
                  <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="require('../assets/' + item.imgurl)"
                  >
                  </v-img>

                  <v-card-text class="text--primary text-center">
                    <strong>{{ item.benefits }}</strong>
                    <div class="text-left mt-4">{{ item.texts }}</div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </div>
          </div>
          <div id="laboratorysection"></div>
        </v-card-text>
      </v-row>
    </v-container>
    <v-parallax height="20" dark :src="require('../assets/parallax.png')">
      <v-layout align-center column justify-center> </v-layout>
    </v-parallax>

    <v-card>
      <v-row>
        <v-col
          class="fill-height"
          cols="12"
          sm="6"
          style="margin-bottom: -12px; margin-top: -12px;"
        >
          <v-row
            align="center"
            class="fill-height"
            color="grey lighten-4"
            style="margin: auto;"
          >
            <v-card
              class="display-1 white--text pa-5 hidden-sm-only"
              color="secondary"
              flat
            >
              <div class="headline text-center">
                {{ SERVICE_LABORATORY }}

                <v-card-text dark class="text-justify body-1">
                  <div class="body-1">
                    <p>
                      ₱1,999 "Bantay Kalusugan" package lamang para maumpisahan
                      ang pangangalaga sa inyong kalusugan. May mga packages din
                      para sa Bantay Baga, Bantay Puso, Bantay Bato, Bantay
                      Diabetes at iba pa sa abot-kayang halaga. Lahat ng ito ay
                      pwedeng gawin sa inyong pamamahay ng walang karagdagang
                      bayad.
                    </p>
                  </div>

                  <div class="body-1">
                    <p>
                      Huwag hintaying magkasakit, ugaliing magpasuri ng dugo
                      isang beses kada taon at kumonsulta sa isang general
                      medicine doctor para sa taunang physical check-up para sa
                      inyong primary care o preventive care. Ang agarang
                      pagbabantay ng inyong kalusugan ay mas mahusay kaysa
                      magkasakit at magpagamot.
                    </p>
                  </div>

                  <div class="body-1">
                    <p>
                      Aming sisiguruhin na ang inyong medical record ay pribado
                      at confidential, at maaari ninyong tingnan kahit anong
                      oras. Tandaan lamang ang inyong username at password
                      tuwing sign-up.
                    </p>
                  </div>

                  <div class="body-1">
                    <p>
                      Para sa mga karagdagang serbisyo at katanungan,
                      makipag-ugnayan lamang sa FB Messenger, WhatsApp at Viber
                      ng MediSure Team.
                    </p>
                  </div>
                </v-card-text>
              </div>

              <v-card-actions class="justify-center">
                <v-btn outlined :to="ROUTE_INSURANCE" class="white--text">
                  Coming Soon!
                </v-btn>
              </v-card-actions>
            </v-card>

            <br />
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" style="margin: auto;">
          <v-card color="white" dark>
            <v-carousel cycle hide-delimiters>
              <v-carousel-item
                v-for="(item, dlist) in links"
                :key="dlist"
                :src="require('../assets/' + item.urls + '.jpg')"
              >
              </v-carousel-item>
              <div id="consultationsection"></div>
            </v-carousel>
            <div id="consultationsection"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-parallax height="20" dark :src="require('../assets/parallax.png')">
      <v-layout align-center column justify-center> </v-layout>
    </v-parallax>

    <v-card color="secondary" dark height="220">
      <v-row
        color="grey lighten-4"
        align="center"
        justify="center"
        class="fill-height"
      >
        <v-card
          flat
          color="secondary"
          class="headline text-center px-0"
          width="900"
        >
          e-Konsulta

          <v-card-text dark class="text-justify body-1">
            Avail of the expertise of Filipino physicians from Luzon, Visayas
            and Mindanao at the comforts of your home. You can choose a
            physician that can speak your dialect. Ranges of consultation rates
            are published.
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn outlined link :to="ROUTE_INSURANCE">
              Coming Soon!
            </v-btn>
          </v-card-actions>
        </v-card>

        <br />
      </v-row>
    </v-card>

    <v-card class="pa-4">
      <v-row>
        <v-col class="pa-0" cols="12" sm="4">
          <v-img :src="require('../assets/laboratory.jpg')" height="500" />
        </v-col>
        <v-col class="pa-0" cols="12" sm="8">
          <v-card-text class="body-1 pa-8 text-justify">
            <div class="body-1">
              <p>
                Bago pumili ng espeyalistang doktor, siguraduhing kumpleto na
                ang mga impormasyon sa inyong medical history record upang
                mapabilis ang inyong konsultasyon. Mas maigi ring meron na
                kayong resulta ng inyong blood test para mas wasto ang pagsusuri
                ng inyong doktor.
              </p>
            </div>

            <div class="body-1">
              <p>
                Ang aming doktor sa MediSure ang magbabasa ng inyong resulta sa
                blood test at siya na rin ang magbibigay ng kanyang opinyon kung
                anong espeyalistang doktor ang naaangkop sa inyo.
              </p>
            </div>

            <div class="body-1">
              <p>
                Halimbawa, kung mataas ang inyong blood glucose o blood sugar,
                ipapasa kayo sa isang diabetes specialist o endocrinologist.
                Kung may problema kayo sa baga, sa isang pulmologist doctor kayo
                dapat magpasuri.
              </p>
            </div>

            <div class="body-1">
              <p>
                Para sa mga karagdagang serbisyo at katanungan, makipag-ugnayan
                lamang sa FB Messenger, WhatsApp at Viber ng MediSure Team.
              </p>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { ROUTE_INSURANCE } from "@/router";
import { SERVICE_LABORATORY } from "@/common/utilities/services";

export default {
  name: "Landing",

  data: () => ({
    insurances: [
      {
        benefits: "Death Benefits",
        imgurl: "death_benefit.png",
        texts: "Loss of life due to old age or sickness"
      },
      {
        benefits: "Accidental Death",
        imgurl: "accidental_death.jpg",
        texts:
          "Loss of life due to accidents, or unforeseen events, or injury sustained in an accident"
      },
      {
        benefits: "Instant Abuloy",
        imgurl: "instant_abuloy.jpg",
        texts:
          "Immediate burial assistance given to bereaved families upon submission of death certificates of insured by authorize claimants"
      },
      {
        benefits: "Daily Confinement for 30 Days",
        imgurl: "daily_confinement.jpg",
        texts:
          "Paid for a period of required confinement in any hospital in the Philippines, due to sickness or accident"
      }
    ],

    links: [
      {
        id: "1",
        title: "Insurance",
        description:
          "A life insurance for as low as ₱ 365 a year offered by Country Bankers Insurance Group.  No medical certificate required, just fill up and submit the required details online",
        color: "primary",
        icon: "mdi-hand-heart",
        link: ROUTE_INSURANCE,
        linkgetstarted: "#insurancesection",
        urls: "laboratory_landing"
      },
      {
        id: "2",
        title: SERVICE_LABORATORY,
        description:
          "Low cost with installment payment options.   Getting your annual blood test is an important component of knowing your medical history, and allow you to follow the appropriate lifestyle choice to stay healthy",
        color: "primary",
        icon: "mdi-microscope",
        link: ROUTE_INSURANCE,
        linkgetstarted: "#laboratorysection",
        urls: "accudetek"
      },
      {
        id: "3",
        title: "e-Konsulta",
        description:
          "Avail of the expertise of Filipino physicians from Luzon, Visayas and Mindanao at the comforts of your home.  You can choose a physician that can speak your dialect. Ranges of consultation rates are published",
        color: "primary",
        icon: "mdi-stethoscope",
        link: ROUTE_INSURANCE,
        linkgetstarted: "#consultationsection",
        urls: "homehead3"
      }
    ],

    ROUTE_INSURANCE: ROUTE_INSURANCE
  }),

  mounted() {
    var section = this.$router.currentRoute.hash.replace("#", "");
    if (section) {
      this.$nextTick(() =>
        window.document.getElementById(section).scrollIntoView()
      );
    }
  }
};
</script>

<style scoped>
.card--flex-toolbar {
  margin-top: -124px;
}

.float-left {
  float: left;
}

.learn-more-btn {
  text-transform: initial;
  text-decoration: underline;
}

.linkcolor {
  background-color: transparent;
  color: white;
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.v-card__title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  height: 160px;
}
</style>
