<template>
  <v-card flat>
    <v-divider></v-divider>
    <h4 class="primary--text py-4 text-center">Reviews ({{ reviewCount }})</h4>

    <div class="primary--text text-center">
      <v-rating
        :value="ratings"
        color="yellow darken-3"
        background-color="grey darken-1"
        empty-icon="$ratingFull"
        half-increments
        hover
        readonly
      />
    </div>

    <v-list-item v-for="(review, index) in reviews" :key="index" class="px-1">
      <v-list-item-content>
        <v-divider v-show="index > 0"></v-divider>
        <v-list-item-subtitle class="primary--text">
          <v-card flat>
            <v-card-actions class="pa-0">
              <v-list-item-avatar>
                <v-img
                  v-if="review.data.raterImage"
                  class="white--text align-end"
                  :aspect-ratio="16 / 9"
                  height="80px"
                  :src="review.data.raterImage"
                />
                <v-img
                  v-else
                  class="white--text align-end"
                  :aspect-ratio="16 / 9"
                  height="80px"
                  :src="require('@/assets/avatar.jpg')"
                />
              </v-list-item-avatar>

              <v-card-subtitle class="pl-0 py-0 text-left primary--text">
                {{ review.data.ratedBy || "Anonymous" }}
              </v-card-subtitle>
            </v-card-actions>

            <div class="font-weight-light pb-2">
              <v-rating
                readonly
                :value="review.data.rating"
                background-color="primary lighten-3"
                color="orange"
                small
              />
              <span class="caption"> {{ getDate(review.data.ratedAt) }} </span>
              <span class="caption" v-if="review.data.variation">
                | {{ review.data.variantname }}({{ review.data.variation }})
              </span>
              <p>{{ review.data.comment }}</p>
              <v-row>
                <v-col
                  v-for="n in review.data.photoUrl"
                  :key="n"
                  class="d-flex child-flex"
                  cols="4"
                  sm="2"
                  style="min-height: 80px"
                >
                  <img v-if="n" :src="n" aspect-ratio="1" alt="Review Image" />
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import { getDayDateTimeFromTimestamp } from "@/common/utilities/date";
import { getReviews } from "@/utilities/collection/addtocart";

export default {
  name: "Reviews",

  props: {
    productSKU: String
  },

  data: () => ({
    ratings: 0,
    reviews: []
  }),

  mounted() {
    getReviews(this.productSKU).then(reviews => {
      this.reviews = [...reviews];
      this.reviews.sort((a, b) => {
        return new Date(b.data.ratedAt) - new Date(a.data.ratedAt);
      });
    });
  },

  computed: {
    reviewCount() {
      return this.reviews.length;
    }
  },

  watch: {
    reviewCount() {
      this.getRating();
    }
  },

  methods: {
    getDate(timestamp) {
      return getDayDateTimeFromTimestamp(timestamp);
    },

    getRating() {
      let ratings = [];
      this.reviews.forEach(review => {
        ratings.push(review.data.rating);
      });
      this.ratings = ratings.reduce((a, b) => a + b, 0) / ratings.length;
    }
  }
};
</script>
