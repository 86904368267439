<template>
  <v-card>
    <input
      ref="input"
      type="file"
      accept="image/*"
      @change="setImage"
      style="visibility: hidden;"
    />

    <div class="content">
      <section class="cropper-area">
        <div style="text-align: center;">
          <vue-cropper
            v-if="forID"
            ref="cropper"
            :aspect-ratio="4.5 / 3"
            preview=".preview"
            alt=" "
          />
          <vue-cropper
            v-else
            ref="cropper"
            :aspect-ratio="1 / 1"
            preview=".preview"
            alt=" "
          />
        </div>

        <div class="actions">
          <a href="#" role="button" @click.prevent="showFileChooser">
            Upload Image
          </a>

          <a href="#" role="button" @click.prevent="zoom(0.2)">
            Zoom In
          </a>

          <a href="#" role="button" @click.prevent="zoom(-0.2)">
            Zoom Out
          </a>

          <a href="#" role="button" @click.prevent="reset">
            Reset
          </a>

          <a
            href="#"
            role="button"
            @click.prevent="cropImage"
            :disabled="$refs.cropper"
          >
            Crop & Save
          </a>
        </div>
      </section>
    </div>
  </v-card>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "UploadPhoto",

  components: {
    VueCropper
  },

  props: {
    forID: Boolean
  },

  data: () => ({
    cropImg: "",
    data: null,
    imgSrc: ""
  }),

  mounted() {
    this.showFileChooser();
  },

  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();

      this.$store.dispatch("resetSecondsIdle").then(() => {
      this.$emit("upload-photo", this.cropImg);
      });
    },

    reset() {
      this.$refs.cropper.reset();
    },

    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },

    showFileChooser() {
      this.$refs.input.click();
    },

    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    }
  }
};
</script>

<style scoped>
.actions {
  margin-top: 1rem;
  text-align: center;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

input[type="file"] {
  display: none;
}

.preview {
  min-height: 200px;
  min-width: 200px;
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
</style>
