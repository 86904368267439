<template>
  <v-card flat class="pa-5">
    <BackButton @click="routeBack()" class="mt-3 mb-2" />

    <div class="row py-4">
      <div class="col-md-4 col-sm-4 col-xs-12">
        <v-carousel
          v-if="imagecollect.length > 0"
          hide-delimiters
          :aspect-ratio="3 / 2"
          height="200px"
          width="300px"
          hide-delimiter-background
          show-arrows
        >
          <v-carousel-item
            v-for="(images, dlist) in imagecollect"
            :key="dlist"
            :src="images.data.imageUrl"
            @click="openImage(images.data.imageUrl)"
          />
        </v-carousel>

        <v-img
          v-else
          class="white--text align-end"
          :aspect-ratio="3 / 2"
          :src="downloadUrl"
          @click="openImage(downloadUrl)"
        />
      </div>

      <div class="col-md-7 col-sm-7 col-xs-12">
        <div class="pl-1">
          <p class="display-1 mb-0 primary--text">{{ name }}</p>
          <v-card-actions class="pa-0">
            <p
              class="headline font-weight-bolder pt-3"
              v-if="discount > 0 && discountUntil > new Date() && discount"
            >
              <del class="caption py-0">
                <PesoAmount :amount="originalPrice" />
              </del>
              <PesoAmount :amount="price" />

              <strong
                style="background-color: primary;"
                class="caption white--text pa-1"
              >
                {{ discount }}% Off
              </strong>
            </p>

            <p class="headline font-weight-bolder pt-3" v-else>
              <PesoAmount :amount="price" />
            </p>

            <v-spacer />

            <span class="body-2	font-weight-thin"> {{ category }} </span>
          </v-card-actions>

          <p class="subtitle-1 font-weight-thin">
            {{ description }}
          </p>
          <v-radio-group v-model="optionSelected" class="my-1" row>
            <div class="my-1" v-for="(variant, vlist) in variants" :key="vlist">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-radio
                      @change="changetoVariantVal(variant)"
                      :label="variant.data.voption"
                      :value="variant.data.voption"
                    />
                  </span>
                </template>
                <span>{{ variant.data.vname }} </span>
              </v-tooltip>
            </div>
          </v-radio-group>

          <span v-if="inventory >= 0" class="subtitle-1	font-weight-thin">
            Stocks: {{ inventory }}
          </span>
          <span
            v-else-if="inventory < 0"
            class="subtitle-1 font-weight-thin red--text"
          >
            Out of Stock
          </span>

          <v-text-field
            outlined
            style="width:100px"
            v-model="quantity"
            dense
            type="number"
            min="0"
            max="100"
          />

          <v-btn
            @click="updateCartItem()"
            class="primary white--text"
            :disabled="!isInventory || !notZero"
            outlined
            tile
            dense
          >
            <v-icon>mdi-update</v-icon>
            <span style="white-space: pre;">&nbsp;&nbsp; Update</span>
          </v-btn>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogpreview" width="900">
      <ImagePreview
        :key="selectedimage"
        :imageUrl="selectedimage"
        @close-dialog="closeDialogs"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { ROUTE_CART, routeBack, routeTo } from "@/router";

import { getItem, loadItems } from "@/utilities/collection/productvariant";
import { loadProductImg } from "@/store";
import { mapGetters } from "vuex";
import { updateCartItem } from "@/utilities/collection/addtocart";

import BackButton from "@/components/Controls/BackButton.vue";
import ImagePreview from "@/eTindahan/components/ImagePreview.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "eTindahanProductDetails",

  components: {
    BackButton,
    ImagePreview,
    PesoAmount
  },

  data() {
    return {
      cartDetails: [],
      category: "",
      description: "",
      dialogpreview: false,
      discount: 0,
      discountUntil: 0,
      downloadUrl: "",
      id: "",
      imagecollect: [],
      inventory: 0,
      name: "",
      optionSelected: "",
      originalPrice: 0,
      price: 0,
      quantity: 0,
      selectedimage: "",
      variants: [],
      vproductSKU: "",

      // functions
      routeBack: routeBack
    };
  },

  mounted() {
    this.cartDetails = this.getCartItem;
    this.inventory = this.getCartItemInventory;
    this.assignDetails(this.cartDetails);

    loadItems(this.cartDetails.productSKU).then(docs => {
      this.variants = docs;
    });

    this.loadProductImg(this.cartDetails.productSKU);
  },

  computed: {
    ...mapGetters(["getCartItem", "getCartItemInventory"]),

    isInventory() {
      return this.inventory >= this.quantity;
    },

    notZero() {
      return this.quantity > 0;
    }
  },

  methods: {
    assignDetails(cartDetails) {
      this.id = cartDetails.id;
      this.productSKU = cartDetails.productSKU;
      this.vproductSKU = cartDetails.vproductSKU;
      this.owner = cartDetails.owner;
      this.name = cartDetails.name;
      this.description = cartDetails.description;
      this.downloadUrl = cartDetails.imageUrl;
      this.category = cartDetails.category;
      this.isPulled = cartDetails.isPulled;
      this.price = cartDetails.price;
      this.originalPrice = cartDetails.price;
      this.discount = cartDetails.discount;
      this.discountUntil = cartDetails.discountUntil;
      this.quantity = cartDetails.quantity;
      this.selectedimage = cartDetails.imageUrl;
      this.optionSelected = cartDetails.variation;

      if (this.vproductSKU) {
        this.getSelectedVariantStock(this.vproductSKU);
      }
    },

    changetoVariantVal(variant) {
      this.vproductSKU = variant.id;
      this.originalPrice = variant.data.vprice;
      this.price = variant.data.vprice;
      this.inventory = variant.data.vstock;
      this.optionSelected = variant.data.voption;
      this.discount = variant.data.vdiscount;
      this.discountUntil = new Date(variant.data.vdiscountUntil.seconds * 1000);

      let now = new Date();
      let vdiscountUntil = new Date(variant.data.vdiscountUntil.seconds * 1000);
      if (vdiscountUntil > now && Number(this.discount) > 0 && this.discount) {
        var numVal = Number(this.discount) / 100;
        var totalValue = Number(this.price) * (1 - numVal);
        this.price = totalValue;
      }
    },

    closeDialogs() {
      this.dialogpreview = false;
    },

    getSelectedVariantStock(vproductSKU) {
      getItem(vproductSKU).then(doc => {
        if (doc && doc.exists) {
          this.inventory = doc.data().vstock;
        }
      });
    },

    loadProductImg(productSKU) {
      loadProductImg(productSKU).then(docs => {
        this.imagecollect = docs;
      });
    },

    openImage() {
      this.dialogpreview = true;
    },

    updateCartItem() {
      updateCartItem({
        id: this.id,
        name: this.name,
        quantity: this.quantity,
        price: this.price,
        vproductSKU: this.vproductSKU
      }).then(() => {
        routeTo(ROUTE_CART);
      });
    }
  }
};
</script>

<style scoped>
.col-sm-4 {
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
