<template>
  <div class="text-center">
    <div class="pa-0">
      <v-select
        v-model="receivingOption"
        class="px-2"
        :items="items"
        label="Receiving Option"
      />
      <v-autocomplete
        v-show="!withCourier && isDelivery"
        label="Select Courier"
        v-model="courier"
        prepend-inner-icon="mdi-truck"
        class="py-1 px-2"
        :items="couriers"
        :rules="[c => !!c || 'Courier is required']"
      />
    </div>

    <AddressBox
      class="py-1 px-2"
      v-show="isDelivery"
      :addressSelected="addressSelected"
      :isAddressSelected="isAddressSelected"
    />

    <div v-show="!isDelivery" class="googlemap py-2">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            class="px-1"
            label="Name"
            v-model="name"
            :rules="fieldRules"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="px-1"
            label="Contact"
            v-model="contact"
            :rules="fieldRules"
          />
        </v-col>
      </v-row>

      <iframe
        title="Map for Pickup"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.1433413486725!2d121.03590751484013!3d14.590906789808633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c83215c1fb13%3A0xd5dd00a01b94549a!2s928%20Luna%20Mencias%2C%20Mandaluyong%2C%201556%20Kalakhang%20Maynila!5e0!3m2!1sen!2sph!4v1648543990433!5m2!1sen!2sph"
        width="100%"
        height="400"
      />
    </div>
  </div>
</template>

<script>
import { DB } from "@/common/store";
import { FOR_DELIVERY, FOR_PICK_UP } from "../utilities/orders";
import {
  SERVICE_LABORATORY,
  SERVICE_DOCTOR
} from "@/common/utilities/services";

import { db } from "@/main";
import { mapGetters } from "vuex";

import AddressBox from "@/eTindahan/components/AddressBox.vue";

export default {
  name: "CartItem",

  components: {
    AddressBox
  },

  props: {
    addressSelected: Object,
    isAddressSelected: Boolean,
    partialContact: String,
    partialName: String
  },

  data: () => ({
    contact: "",
    courier: "MediSure",
    couriers: [],
    fieldRules: [v => !!v || "field required"],
    houseNumber: "",
    items: [FOR_PICK_UP, FOR_DELIVERY],
    landmark: "",
    name: "",
    receivingOption: "",
    shippingFee: 50,
    street: ""
  }),

  mounted() {
    this.loadAddress();
    this.getCouriers();
    this.receivingOption = FOR_DELIVERY;
  },

  watch: {
    contact(value) {
      this.$emit("contact", value);
      this.$emit("isValid", this.isValid);
    },

    courier(value) {
      this.$emit("courier", value);
    },

    name(value) {
      this.$emit("name", value);
      this.$emit("isValid", this.isValid);
    },

    partialName(value) {
      if (this.isPickup) {
        this.name = value;
        this.contact = this.partialContact;
      }
    },

    receivingOption(value) {
      this.shippingFee = this.isDelivery ? 50 : 0;
      this.$emit("isValid", this.isDelivery || this.isAddressSelected);
      this.$emit("receivingOption", value);
    },

    shippingFee(value) {
      this.$emit("shippingFee", value);
    }
  },

  computed: {
    ...mapGetters({
      selectedAddress: "getLastSelectedAddress"
    }),

    isDelivery() {
      return FOR_DELIVERY === this.receivingOption;
    },

    isPickup() {
      return FOR_PICK_UP === this.receivingOption;
    },

    isValid() {
      return (
        (this.isDelivery &&
          this.houseNumber !== "" &&
          this.street !== "" &&
          this.landmark !== "") ||
        (this.isPickup && this.name !== "" && this.contact !== "")
      );
    },

    withCourier() {
      return [SERVICE_LABORATORY, SERVICE_DOCTOR].includes(this.payref);
    }
  },

  methods: {
    getCouriers() {
      db.collection(DB.STORESETTINGS)
        .doc("courier")
        .get()
        .then(doc => {
          this.couriers = [];

          if (doc.exists) {
            const data = doc.data().couriers;
            if (data) {
              this.couriers = [...data];
            }
          }

          if (this.couriers.length > 0 && this.couriers[0]) {
            this.courier = this.couriers[0];
          }
        });
    },

    loadAddress() {
      this.houseNumber = this.selectedAddress.houseNumber;
      this.street = this.selectedAddress.street;
      this.landmark = this.selectedAddress.landmark;
    }
  }
};
</script>
