<template>
  <v-card elevation="0" class="container">
    <v-row align="center" justify="center">
      <v-col sm="6">
        <DiscountOptions
          :key="0"
          :total="serviceFee"
          @discountValue="discountValue = $event"
          @discountedTotal="discountedTotal = $event"
        />
      </v-col>
      <v-col sm="6">
        <CostBreakdown
          :key="serviceFee"
          :serviceFee="serviceFee"
          :discountValue="discountValue"
          :discountedTotal="discountedTotal"
        />
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12" style="text-align: right;">
        <v-btn color="primary" @click="confirmCost">
          Next
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CostBreakdown from "@/components/Konsulta/CostBreakdown.vue";
import DiscountOptions from "@/components/Payment/DiscountOptions.vue";

export default {
  name: "Checkout",

  components: {
    CostBreakdown,
    DiscountOptions
  },

  props: {
    serviceFee: Number
  },

  data: () => ({
    discountedTotal: 0,
    discountValue: 0
  }),

  methods: {
    confirmCost() {
      let cost = {
        serviceFee: this.serviceFee,
        discountValue: this.discountValue,
        discountedTotal: this.discountedTotal
      };
      this.$emit("costDetails", cost);
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 900px;
}
</style>
