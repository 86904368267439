<template>
  <div class="pa-5">
    <v-stepper v-model="step" flat>
      <v-stepper-header style="overflow: hidden;">
        <v-stepper-step :complete="step > 1" step="1">
          Select Service
        </v-stepper-step>
        <v-stepper-step :complete="step > 1" step="2">
          Personal Information
        </v-stepper-step>
        <v-stepper-step step="3">
          Confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <SelectServices
            :collection="COLLECTION"
            @next="step = 2"
            class="mt-5 px-0"
          />
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <MemberInfo
            :collection="COLLECTION"
            @back="step = 1"
            @next="step = 3"
            class="mt-5 px-0"
          />
        </v-stepper-content>

        <v-stepper-content step="3" class="pa-0">
          <Confirmation @back="step = 2" @next="onSubmit" class="mt-5 px-0" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { DB } from "@/common/store";
import { NS_HEALTHRECORDS } from "@/store";

import Confirmation from "./Confirmation.vue";
import SelectServices from "./SelectServices.vue";
import MemberInfo from "./MemberInfo.vue";

export default {
  name: "MedicalHistoryFlow",

  components: {
    Confirmation,
    MemberInfo,
    SelectServices
  },

  data: () => ({
    step: 1,

    COLLECTION: DB.MEDICALHISTORY_INDEX
  }),

  mounted() {
    if (this.$store.getters[`${NS_HEALTHRECORDS}/getStep`]) {
      this.step = this.$store.getters[`${NS_HEALTHRECORDS}/getStep`];
    }
  },

  watch: {
    step(value) {
      this.$store.dispatch(`${NS_HEALTHRECORDS}/setStep`, value);
    }
  },

  methods: {
    onSubmit() {
      this.$store.dispatch(`${NS_HEALTHRECORDS}/setStep`, 0);
      this.$emit("onSubmit");
    }
  }
};
</script>
