<template>
  <v-parallax height="600" dark :src="require('@/assets/homehead1.jpg')">
    <v-card class="pa-4 mx-auto" align="center">
      <v-card flat v-if="!providerSelected" width="374">
        <p class="pt-2 mb-0 primary--text">Create Account</p>
        <v-alert type="error" v-if="errorText">{{ errorText }}</v-alert>
        <v-btn
          @click="selectProvider(GOOGLE)"
          width="300"
          class="ma-2"
          large
          color="primary"
          dark
        >
          <v-icon class="pa-2"> mdi-google</v-icon>oogle
        </v-btn>

        <v-btn
          @click="selectProvider(PHONE)"
          width="300"
          class="ma-2"
          large
          color="primary"
          dark
        >
          <v-icon class="pa-2">
            mdi-phone
          </v-icon>
          Phone
        </v-btn>

        <v-btn
          @click="selectProvider(EMAIL)"
          width="300"
          class="ma-2"
          large
          color="primary"
          dark
        >
          <v-icon class="pa-2">
            mdi-email
          </v-icon>
          E-MAIL
        </v-btn>
      </v-card>

      <v-card flat width="500" v-else>
        <p v-show="provider === GOOGLE" class="primary--text pa-0">
          Member Information for:
          <v-icon color="primary">mdi-google</v-icon>oogle
        </p>
        <p v-show="provider === PHONE" class="primary--text pa-0">
          Member Information for:
          <v-icon color="primary">mdi-phone</v-icon> Phone
        </p>
        <p v-show="provider === EMAIL" class="primary--text pa-0">
          Member Information for:
          <v-icon color="primary">mdi-email</v-icon> E-Mail
        </p>
        <v-form ref="formDetails" v-model="valid" lazy-validation>
          <v-row class="py-0">
            <v-col class="pb-0" sm="4" cols="12">
              <v-text-field
                v-model="firstName"
                :rules="fieldRules"
                label="First Name"
                required
              />
            </v-col>
            <v-col class="pb-0" sm="4" cols="12">
              <v-text-field
                v-model="middleName"
                :rules="fieldRules"
                label="Middle Name"
                required
              />
            </v-col>

            <v-col class="pb-0" sm="4" cols="12">
              <v-text-field
                v-model="lastName"
                :rules="fieldRules"
                label="Last Name"
                required
              />
            </v-col>

            <v-col v-show="provider === EMAIL" cols="12" class="py-0">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
              />

              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[passwordRules.required, passwordRules.min]"
                :type="showPassword ? 'text' : 'password'"
                name="password"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="showPassword = !showPassword"
              />
            </v-col>

            <v-col class="py-0" v-show="provider === PHONE" cols="12">
              <AuthenticationPhoneSignin
                :createAccount="true"
                class="ma-0"
                @phoneNumber="phoneNumber = $event"
                @onSuccess="onPhoneLogin($event)"
              />
            </v-col>
          </v-row>

          <v-btn
            small
            color="primary"
            class="ma-2 mt-4"
            @click="providerSelected = false"
          >
            Back
          </v-btn>
          <v-btn
            v-show="[EMAIL, GOOGLE].includes(provider)"
            :disabled="!isValid()"
            color="primary"
            class="ma-2 mt-4"
            @click="registerAccount"
          >
            Confirm
          </v-btn>
        </v-form>
      </v-card>

      <v-dialog v-model="dialog" width="800">
        <RegistrationTerms v-on:continue-registration="continueRegistration" />
      </v-dialog>
    </v-card>
  </v-parallax>
</template>

<script>
import { LOCALE_TL } from "@/common/utilities/locale";
import { ROUTE_LOADING, routeTo } from "@/router";
import { STATUS_UNREAD } from "@/common/utilities/message";

import { getCurrentUserUid } from "@/common/store";
import { onAuthStateChanged } from "@/main";
import { sendMessage } from "@/store";
import { setRegistrationDetails } from "@/utilities/session";

import firebase from "firebase/app";
import "firebase/auth";

import AuthenticationPhoneSignin from "@/components/Authentication/PhoneSignin.vue";
import RegistrationTerms from "@/components/RegistrationTerms.vue";

export default {
  name: "Register",

  components: {
    AuthenticationPhoneSignin,
    RegistrationTerms
  },

  mounted() {
    this.valid = false;
  },

  data: () => ({
    phoneNumber: 0,

    firstName: "",
    middleName: "",
    lastName: "",

    contactNumber: "",

    showPassword: false,
    password: "",
    passwordRules: {
      required: value => !!value || "Pasword Required.",
      min: v => v.length >= 8 || "Min 8 characters"
    },
    showRepassword: false,
    rePassword: "",

    errorText: "",

    phoneDialog: false,
    dialog: false,
    provider: "",

    waitSpinner: false,

    PHONE: "phone",
    EMAIL: "email",
    GOOGLE: "google",

    providerSelected: false,

    valid: true,
    name: "",
    fieldRules: [v => !!v || "Field is required"],
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false
  }),

  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.rePassword || "Password must match";
    }
  },

  methods: {
    clearErrors() {
      this.errorText = "";
    },

    continueRegistration() {
      this.dialog = false;

      this.waitSpinner = true;
      this.setRegistrationDetails();

      if (this.EMAIL === this.provider) {
        this.registerNew();
      } else {
        const provider =
          "FB" === this.provider
            ? new firebase.auth.FacebookAuthProvider()
            : new firebase.auth.GoogleAuthProvider();

        this.loginWithProvider(provider);
      }
    },

    isValid() {
      if (this.provider === this.GOOGLE) {
        return (
          this.firstName != "" && this.middleName != "" && this.lastName != ""
        );
      } else if (this.provider === this.EMAIL) {
        return (
          this.firstName != "" &&
          this.middleName != "" &&
          this.lastName != "" &&
          this.email != "" &&
          this.password != ""
        );
      }

      return false;
    },

    loginWithProvider(provider) {
      window.sessionStorage.setItem("sendRegistrationMessage", true);

      routeTo(ROUTE_LOADING);
      firebase.auth().signInWithRedirect(provider);
    },

    onPhoneLogin(result) {
      this.setRegistrationDetails(result.user);

      firebase
        .auth()
        .currentUser.updateProfile({
          displayName: result.user.phoneNumber
        })
        .then(() => {
          this.waitSpinner = false;
        })
        .catch(error => {
          this.waitSpinner = false;
          this.errorText = error;
        })
        .finally(() => {
          onAuthStateChanged(result.user);
          this.sendWelcomeMessage();
        });
    },

    registerAccount() {
      this.errorText = "";
      if (this.isValid()) {
        this.dialog = true;
      } else {
        const message = "Please fill up missing Member Information details.";
        if (this.EMAIL === this.provider) {
          this.errorText = message;
        }
      }
    },

    registerNew() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(result => {
          result.user
            .updateProfile({
              displayName: `${this.firstName} ${this.lastName}`
              // TODO: photoURL: "https://example.com/jane-q-user/profile.jpg"
            })
            .catch(error => {
              this.errorText = error;
            })
            .finally(() => {
              this.sendWelcomeMessage();
              onAuthStateChanged(result.user);
              this.waitSpinner = false;
            });
        })
        .catch(error => {
          this.waitSpinner = false;
          this.errorText = error;
        });
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    selectProvider(provider) {
      this.provider = provider;
      this.providerSelected = true;
    },

    sendWelcomeMessage() {
      let body = `Salamat sa pagsali sa MediSure!!!

Nais po lamang naming kunin ang pagkakataon na ito upang iparating ang aming malugod na pagtanggap sa inyo bilang bagong miyembro.

Mabuhay po kayo!!!

Mula sa MediSure Team`;

      sendMessage({
        body: body,
        header:
          "https://firebasestorage.googleapis.com/v0/b/medisure-275117.appspot.com/o/images%2F53f56840-f3ba-11ea-ba6a-5bc4aecd9fe6.png?alt=media&token=f1229dce-47f4-41eb-831f-db9510805e13",
        name: "Admin",
        receiver: getCurrentUserUid(),
        sender: "Admin", //Sender AdminSide
        status: STATUS_UNREAD,
        subject: "Welcome to Medisure Plus!",
        timestamp: new Date()
      });
    },

    setRegistrationDetails() {
      setRegistrationDetails(
        this.firstName,
        this.lastName,
        this.contactNumber,
        this.email,
        LOCALE_TL
      );
    }
  }
};
</script>
