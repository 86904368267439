<template>
  <div>
    <Banner />
    <Content />
  </div>
</template>

<script>
import Banner from "@/components/MemberLanding/Banner.vue";
import Content from "@/components/MemberLanding/Content.vue";

export default {
  name: "Menu",

  components: {
    Banner,
    Content
  },

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");
  }
};
</script>
