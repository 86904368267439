import { capitalizeFirstLetter } from "@/common/utilities/text";

export const ORDER_STATUS_PAYMENT_VERIFICATION = "payment verification";
export const ORDER_STATUS_PROCESSING = "processing";
export const ORDER_STATUS_SHIPPING = "shipping";
export const ORDER_STATUS_TOSHIP = "toship";
export const ORDER_STATUS_COMPLETED = "completed";
export const ORDER_STATUS_CANCELLED = "cancelled";
export const ORDER_STATUS_VOID = "void";
export const ORDER_STATUS_RETURNREFUND = "return/refund";
export const ORDER_STATUS_RETURN = "return";
export const ORDER_STATUS_REFUND = "refund";

// return reason
const REASON_DAMAGED = "Damaged / Faulty Item (Electronics and Gadgets only)";
const REASON_COUNTERFEIT = "Counterfeit Item";
const REASON_EXPIRED = "Expired Item";
const REASON_WRONG = "Wrong Item";
const REASON_DIFFERENT_BRAND = "Same Item Different Brand";
const REASON_DOES_NOT_MATCH = "Item does not match description/picture";
const REASON_MISSING_ACCESORIES = "Missing Accesories";
const REASON_MISSING_ITEM = "Missing Item";

// refund reason
const REASON_NOT_RECEIVED = "Order Not Received";
const REASON_EMPTY_FRAUDULENT = "Empty Package or Fraudulent Item";

// pickup time
const PICKUP_MORNING = "Morning";
const PICKUP_AFTERNOON = "Afternoon";

export const CLEARANCE_SALE = "Clearance Sale";

export function orderStatus() {
  return [
    ORDER_STATUS_PAYMENT_VERIFICATION,
    ORDER_STATUS_PROCESSING,
    ORDER_STATUS_TOSHIP,
    ORDER_STATUS_SHIPPING,
    ORDER_STATUS_COMPLETED,
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_VOID,
    ORDER_STATUS_RETURNREFUND
  ];
}

export function instateOption() {
  return [
    {
      lCase: ORDER_STATUS_RETURN,
      uCase: capitalizeFirstLetter(ORDER_STATUS_RETURN)
    },
    {
      lCase: ORDER_STATUS_REFUND,
      uCase: capitalizeFirstLetter(ORDER_STATUS_REFUND)
    }
  ];
}

export function getReasonsReturn() {
  return [
    REASON_DAMAGED,
    REASON_COUNTERFEIT,
    REASON_EXPIRED,
    REASON_WRONG,
    REASON_DIFFERENT_BRAND,
    REASON_DOES_NOT_MATCH,
    REASON_MISSING_ACCESORIES,
    REASON_MISSING_ITEM
  ];
}

export function getReasonsRefund() {
  return [REASON_NOT_RECEIVED, REASON_EMPTY_FRAUDULENT];
}

export function isStatusCancelled(status) {
  return ORDER_STATUS_CANCELLED === status;
}

export function getPickupTime() {
  return [PICKUP_MORNING, PICKUP_AFTERNOON];
}
