<template>
  <div class="hidden">
    <v-row dense>
      <v-col class="pa-0" cols="12" sm="12">
        <v-carousel
          hide-delimiters
          height="380px"
          cycle
          interval="5000"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(displaylist, dlist) in displayheader"
            :key="dlist"
            :src="displaylist.imageUrl"
          >
            <v-row
              class="fill-height"
              color="grey lighten-4"
              align="center"
              justify="center"
            >
              <div
                :class="displaylist.colour"
                class="display-2 white--text pl-5 pr-5 hidden-sm-only"
              >
                <strong>{{ displaylist.nameheader }}</strong>
              </div>
              <br />
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <v-container>
      <div class="row pt-4">
        <div class="col-md-2 col-sm-2 col-xs-12 pa-1">
          <v-card outlined>
            <v-card-title>Categories</v-card-title>
            <v-divider />
            <template>
              <v-treeview
                activatable
                dense
                item-key="name"
                :items="categories"
                open-on-click
                v-model="tree"
              >
                <template slot="label" slot-scope="{ item }">
                  <a @click="content(item)">{{ item.name }}</a>
                </template>
              </v-treeview>
            </template>
          </v-card>
        </div>

        <div class="col-md-10 col-sm-10 col-xs-12 py-0">
          <v-row dense>
            <v-col cols="12" sm="6" class="py-1">
              <v-text-field
                v-model="search"
                solo
                label="Search"
                prepend-inner-icon="mdi-file-find-outline"
              />
            </v-col>

            <v-col cols="12" sm="4" class="py-1">
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" color="primary">
                    <span>My Purchases</span>
                  </v-btn>
                </template>
                <v-card
                  class="caption primary--text mx-auto"
                  max-width="344"
                  outlined
                >
                  <v-list-item @click.stop="routeTo(ROUTE_TRACKORDER)">
                    <v-list-item-title class="caption primary--text">
                      Track Orders
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="ROUTE_ORDERHISTORY">
                    <v-list-item-title class="caption primary--text my-0">
                      Rate Orders
                    </v-list-item-title>
                  </v-list-item>
                </v-card>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="2" class="py-1">
              <div style="float:right;" class="px-5">
                <v-menu open-on-hover offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined v-on="on" color="primary">
                      <span>Sort By</span>
                    </v-btn>
                  </template>
                  <v-card
                    class="caption primary--text mx-auto"
                    max-width="344"
                    outlined
                  >
                    <v-list-item @click="loadRelevance()">
                      <v-list-item-title class="caption primary--text">
                        Relevance
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="loadProductBoxRating()">
                      <v-list-item-title class="caption primary--text my-0">
                        Popular
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="loadProductBoxSold()">
                      <v-list-item-title class="caption primary--text">
                        Top Sales
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="loadProductBoxPriceLtH()">
                      <v-list-item-title class="caption primary--text">
                        Price (Low to High)
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="loadProductBoxPriceHtL()">
                      <v-list-item-title class="caption primary--text">
                        Price (High to Low)
                      </v-list-item-title>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </div>
            </v-col>
            <v-breadcrumbs
              :items="breadcrumbs"
              v-bind:style="{ cursor: 'pointer' }"
              class="pa-0"
            >
              <v-breadcrumbs-item
                class="primary--text pa-0"
                slot="item"
                slot-scope="{ item }"
                exact
                @click="openSearch(item)"
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </v-breadcrumbs>
          </v-row>

          <div class="row text-center">
            <div
              class="col-md-3 col-sm-3 col-xs-12 pa-1"
              v-show="isPrescribedCategory"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card @click="routeTo(ROUTE_RXPRESCRIPTION)" class="mx-auto">
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="3 / 2"
                    src="@/assets/prescription.jpg"
                  >
                    <v-card-title class="caption primary--text pl-0">
                      <strong
                        style="background-color: primary; text-align: left;"
                        class="caption white--text pa-1"
                      />
                    </v-card-title>
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-btn outlined>
                          Upload
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="pa-1">
                    <div>
                      <strong class="black--text" style="text-decoration: none">
                        Send Prescription
                      </strong>
                    </div>
                    <p class="caption mb-0">
                      Give the names of all the medications, along with dosage
                      and Rx numbers.
                    </p>
                  </v-card-text>
                </v-card>
              </v-hover>
            </div>

            <div
              class="col-md-3 col-sm-3 col-xs-12 pa-1"
              v-for="(boxlist, blist) in filteredStat"
              :key="blist"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  @click="
                    loadata(
                      boxlist,
                      Number(boxlist.price) -
                        Number(boxlist.price) * (Number(boxlist.discount) / 100)
                    )
                  "
                  @click.stop="dialog = true"
                  v-model="hisid"
                  class="mx-auto"
                >
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="3 / 2"
                    :src="boxlist.imageUrl"
                  >
                    <v-card-title
                      class="caption primary--text pl-0"
                      v-if="boxlist.discount"
                    >
                      <strong
                        v-if="hasDiscount(boxlist, discount)"
                        style="background-color: primary; text-align: left;"
                        class="caption white--text pa-1"
                      >
                        {{ boxlist.discount }}% Off
                      </strong>
                    </v-card-title>
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-btn v-if="boxlist.inventory != '0'" outlined>
                          Stock: {{ boxlist.inventory }}
                        </v-btn>
                        <v-btn v-else-if="boxlist.inventory == '0'" outlined>
                          Out of Stock
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>

                  <v-card-text class="pa-0">
                    <div>
                      <strong
                        class="black--text"
                        href="/product"
                        style="text-decoration: none"
                      >
                        {{ boxlist.name }}
                      </strong>
                    </div>
                    <p class="caption mb-0" v-if="boxlist.rating != 0">
                      Rating: {{ boxlist.rating }} | Sold: {{ boxlist.sold }}
                    </p>
                    <p class="caption mb-0" v-else>
                      Try this Product
                    </p>
                    <v-rating
                      v-model="boxlist.rating"
                      color="yellow darken-3"
                      background-color="grey darken-1"
                      empty-icon="$ratingFull"
                      half-increments
                      hover
                      x-small
                      readonly
                    />

                    <strong
                      v-if="boxlist.priceRange"
                      class="primary--text mb-1"
                    >
                      {{ boxlist.priceRange }}
                    </strong>

                    <strong
                      v-else-if="hasDiscount(boxlist, discount)"
                      class="primary--text mb-1"
                    >
                      <del class="caption">
                        <PesoAmount :amount="Number(boxlist.price)" />
                      </del>
                      <PesoAmount :amount="getDiscountedPrice(boxlist)" />
                    </strong>

                    <strong v-else class="primary--text mb-1">
                      <PesoAmount :amount="Number(boxlist.price)" />
                    </strong>
                  </v-card-text>
                </v-card>
              </v-hover>
            </div>
          </div>
        </div>
      </div>
    </v-container>

    <v-container class="pt-15 px-0">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col class="pl-0" cols="2" align="right">
              <v-icon class="display-1 primary--text">mdi-truck</v-icon>
            </v-col>
            <v-col class="px-0" cols="10">
              <h3 class="font-weight-light primary--text">
                FREE SHIPPING
              </h3>
              <p class="font-weight-thin">Free shipping on selected items</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col class="pl-0" cols="2" align="right">
              <v-icon class="display-1 primary--text">mdi-headset</v-icon>
            </v-col>
            <v-col class="px-0" cols="10">
              <h3 class="font-weight-light primary--text">
                mso.tindahan@medisureonline.com
              </h3>
              <p class="font-weight-thin">
                Support available from 8:00 AM to 5:00 PM (Monday to Friday)
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-card color="primary" class="caption text-center white--text" flat>
      <v-card-text class="white--text pa-0">
        Same day delivery for orders taken before 10 AM Philippine Time in Metro
        Manila
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  ROUTE_ORDERHISTORY,
  ROUTE_PRODUCTDETAILS,
  ROUTE_RXPRESCRIPTION,
  ROUTE_TRACKORDER,
  routeTo
} from "@/router";
import { SERVICE_BOTIKA } from "@/common/utilities/services";

import { loadProductCategories, logUserActivity } from "@/common/store";
import { mapState } from "vuex";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  components: {
    PesoAmount
  },

  data: () => ({
    breadcrumbs: [
      {
        text: "All"
      }
    ],
    dialog: false,

    hisid: "",

    name: "",
    category: "",

    price: Number,
    discount: Number,
    discountUntil: String,
    inventory: Number,

    search: "",
    categories: [],

    discountedPrice: 0,

    isSortedPrice: false,
    isSortedSold: false,
    isSortedRating: false,
    isCategorize: false,
    tree: [],
    arrname: [],
    isPrescribedCategory: false,

    routeTo: routeTo,

    ROUTE_ORDERHISTORY: ROUTE_ORDERHISTORY,
    ROUTE_RXPRESCRIPTION: ROUTE_RXPRESCRIPTION,
    ROUTE_TRACKORDER: ROUTE_TRACKORDER
  }),

  computed: {
    ...mapState([
      "displayheader",
      "productbox",
      "productboxbycat",
      "productboxcatprice",
      "productboxcatrating",
      "productboxcatsold",
      "productboxprice",
      "productboxrating",
      "productboxsold"
    ]),

    filteredStat() {
      if (this.isSortedSold) {
        return this.filterContent(
          this.isCategorize ? this.productboxcatsold : this.productboxsold
        );
      } else if (this.isSortedRating) {
        return this.filterContent(
          this.isCategorize ? this.productboxcatrating : this.productboxrating
        );
      } else if (this.isSortedPrice) {
        return this.filterContent(
          this.isCategorize ? this.productboxcatprice : this.productboxprice
        );
      } else {
        return this.filterContent(
          this.isCategorize ? this.productboxbycat : this.productbox
        );
      }
    }
  },

  created() {
    this.loadDisplaylist();
    this.search = "";
    this.$store.dispatch("loadProductBox", { section: SERVICE_BOTIKA });

    loadProductCategories(true).then(docs => {
      this.categories = docs;
    });
  },

  methods: {
    content(item) {
      if (item.name === "Prescription Drugs (Rx)") {
        routeTo({ path: ROUTE_RXPRESCRIPTION });
        return;
      }

      this.isCategorize = true;

      let fullPath = (item.parent ? item.parent + ">" : "") + item.name;
      let items = fullPath.split(">");

      this.breadcrumbs = [{ text: "All" }];
      for (let i of items) {
        this.breadcrumbs.push({ text: i });
      }

      this.arrname = [];
      this.populateCategories(item);

      if (this.productboxcatsold && this.isSortedSold) {
        this.loadProductBoxSold();
      } else if (this.productboxcatrating && this.isSortedRating) {
        this.loadProductBoxRating();
      } else if (this.productboxcatprice && this.isSortedPrice) {
        this.loadProductBoxPriceLtH();
      } else {
        this.loadBoxListbyCat();
      }
    },

    filterContent(content) {
      if (this.search) {
        return content.filter(entry => {
          return entry.name.toLowerCase().includes(this.search.toLowerCase());
        });
      } else {
        return content;
      }
    },

    getDiscountedPrice(boxlist) {
      let discount = Number(boxlist.discount);
      let price = Number(boxlist.price);

      return price * ((100 - discount) / 100);
    },

    hasDiscount(boxlist, discount) {
      return (
        Number(boxlist.discount) > 0 &&
        new Date(boxlist.discountUntil.seconds * 1000) > new Date() &&
        discount
      );
    },

    loadata(boxlist, discountedPrice) {
      let now = new Date();
      this.discountedPrice =
        this.discountUntil > now && Number(this.discount) > 0 && this.discount
          ? discountedPrice
          : this.price;

      let productDetails = {
        docid: boxlist.id,
        owner: boxlist.owner,
        selectedItemrating: boxlist.rating,
        selectedItemsold: boxlist.sold,
        name: boxlist.name,
        description: boxlist.description,
        downloadUrl: boxlist.imageUrl,
        category: boxlist.category,

        isPulled: boxlist.isPulled,

        priceRange: boxlist.priceRange,
        price: Number(boxlist.price),
        discountedPrice: Number(this.discountedPrice),
        discount: Number(boxlist.discount),
        discountUntil: new Date(boxlist.discountUntil.seconds * 1000),

        inventory: Number(boxlist.inventory),

        preOrder: boxlist.preOrder,
        stockWarning: boxlist.stockWarning
      };

      this.$store.commit("SET_PRODUCT_DETAILS", productDetails, { root: true });
      logUserActivity("View Product", { target: this.hisid }).then(() => {
        routeTo(ROUTE_PRODUCTDETAILS);
      });
    },

    loadBoxListbyCat() {
      this.isSortedSold = false;
      this.isSortedRating = false;
      this.isSortedPrice = false;

      this.$store.dispatch("loadProductBoxbyCat", {
        arrname: this.arrname,
        section: SERVICE_BOTIKA
      });
    },

    loadDisplaylist() {
      this.$store.dispatch("loadDisplay", "displayheader");
    },

    loadProductBoxPrice(direction) {
      this.isSortedRating = false;
      this.isSortedSold = false;
      this.isSortedPrice = true;

      let content = {
        direction: direction,
        prod: this.isCategorize ? "productboxcatprice" : "productboxprice",
        section: SERVICE_BOTIKA
      };
      if (this.isCategorize) {
        content["arrname"] = this.arrname;
      }

      let dispatch = this.isCategorize
        ? "loadProductBoxCatSortPrice"
        : "loadProductBoxPrice";

      this.$store.dispatch(dispatch, content);
    },

    loadProductBoxPriceHtL() {
      this.loadProductBoxPrice("desc");
    },

    loadProductBoxPriceLtH() {
      this.loadProductBoxPrice("asc");
    },

    loadProductBoxRating() {
      this.isSortedRating = true;
      this.isSortedSold = false;
      this.isSortedPrice = false;

      let dispatch = this.isCategorize
        ? "loadProductBoxCatSortRating"
        : "loadProductBoxRating";

      let content = {
        prod: this.isCategorize ? "productboxcatrating" : "productboxrating",
        section: SERVICE_BOTIKA
      };
      if (this.isCategorize) {
        content["arrname"] = [...this.arrname];
      }

      this.$store.dispatch(dispatch, content);
    },

    loadProductBoxSold() {
      this.isSortedSold = true;
      this.isSortedRating = false;
      this.isSortedPrice = false;

      let dispatch = this.isCategorize
        ? "loadProductBoxCatSortSold"
        : "loadProductBoxSold";

      let content = { section: SERVICE_BOTIKA };
      if (this.isCategorize) {
        content["arrname"] = this.arrname;
      }

      this.$store.dispatch(dispatch, content);
    },

    loadRelevance() {
      if (this.isCategorize) {
        this.loadBoxListbyCat();
      } else {
        this.isSortedSold = false;
        this.isSortedRating = false;
        this.isSortedPrice = false;
      }
    },

    openSearch(item) {
      if (this.item) {
        this.breadcrumbs.push({ item });
      }
      if ("All" === item.text) {
        this.breadcrumbs = [{ text: "All" }];
        this.isSortedSold = false;
        this.isSortedRating = false;
        this.isSortedPrice = false;
        this.isCategorize = false;
      }
    },

    populateCategories(category) {
      let fullPath =
        (category.parent ? category.parent + ">" : "") + category.name;
      this.arrname.push(fullPath);
      if (category.children) {
        for (let c in category.children) {
          this.populateCategories(category.children[c]);
        }
      }
    }
  }
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}

.hidden {
  overflow: hidden;
}

@media (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
