<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center px-1" scope="col">Item</th>
            <th
              v-show="status !== ORDER_STATUS_CANCELLED"
              class="text-center px-1"
              scope="col"
            >
              Total
            </th>
            <th class="text-center px-1" scope="col" />
          </tr>
        </thead>

        <tbody v-for="(cart, clist) in cartItems" :key="clist">
          <tr v-if="showItems(cart)">
            <td>
              <v-list-item class="px-1">
                <v-list-item-avatar>
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="16 / 9"
                    height="80px"
                    :src="cart.imageUrl"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="primary--text truncate">
                    {{ cart.name }}
                    <strong
                      v-if="isExpired(cart) || cart.isPWDSCPriceApplied"
                      style="background-color: primary;"
                      class="caption primary--text pa-1"
                    >
                      {{ getPromo(cart) }}
                    </strong>
                  </v-list-item-title>
                  <v-list-item-subtitle v-text="cart.category" />
                  <v-list-item-subtitle v-text="cart.variation" />
                  <v-list-item-subtitle>
                    {{ cart.quantity }} x
                    <span
                      v-if="isExpired(cart)"
                      class="mr-1 text-decoration-line-through"
                    >
                      <PesoAmount :amount="Number(cart.originalPrice)" />
                    </span>
                    <span>
                      <PesoAmount :amount="Number(cart.price)" />
                    </span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle class="primary--text">
                    <v-btn
                      v-show="
                        !cart.reason &&
                          !cart.refundAmount &&
                          ORDER_STATUS_PROCESSING === status
                      "
                      class="error--text"
                      outlined
                      x-small
                      @click="cancelItem(cart)"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      v-show="
                        !cart.rating &&
                          !cart.refundAmount &&
                          !cart.rejectedAmount &&
                          ORDER_STATUS_COMPLETED === status
                      "
                      class="primary--text ml-1"
                      outlined
                      x-small
                      @click="rateItem(cart)"
                    >
                      Rate
                    </v-btn>

                    <ItemRating
                      v-show="cart.rating && ORDER_STATUS_COMPLETED === status"
                      :rating="cart.rating"
                      :comment="cart.comment"
                      :photoUrl="cart.photoUrl"
                    />
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-show="cart.reason"
                    class="error--text"
                  >
                    Reason: {{ cart.reason }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-show="cart.rejectedAmount"
                    class="error--text"
                  >
                    Voided:
                    <PesoAmount :amount="cart.rejectedAmount" />
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-show="cart.refundAmount"
                    class="error--text"
                  >
                    Cancelled: <PesoAmount :amount="cart.refundAmount" />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </td>

            <td
              v-show="status !== ORDER_STATUS_CANCELLED && !cart.rejectedAmount"
              :class="{ 'pr-7': !$vuetify.breakpoint.mobile }"
              style="width: 125px;"
              class="text-right px-0"
            >
              <PesoAmount :amount="cart.price * cart.quantity" />
            </td>
          </tr>

          <tr
            v-else-if="
              cart.latestReturnRefund && ORDER_STATUS_RETURNREFUND === status
            "
          >
            <td>
              <v-list-item class="px-1">
                <v-list-item-avatar>
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="16 / 9"
                    height="80px"
                    :src="cart.imageUrl"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="primary--text truncate">
                    {{ cart.name }}
                    <strong
                      v-if="isExpired(cart) || cart.isPWDSCPriceApplied"
                      style="background-color: primary;"
                      class="caption primary--text pa-1"
                    >
                      {{ getPromo(cart) }}
                    </strong>
                  </v-list-item-title>
                  <v-list-item-subtitle v-text="cart.category" />
                  <v-list-item-subtitle v-text="cart.variation" />
                  <v-list-item-subtitle>
                    {{ cart.latestReturnRefund.returnQuantity }} x
                    <span
                      v-if="isExpired(cart)"
                      class="mr-1 text-decoration-line-through"
                    >
                      <PesoAmount :amount="Number(cart.originalPrice)" />
                    </span>
                    <span>
                      <PesoAmount :amount="Number(cart.price)" />
                    </span>
                    <span
                      v-if="STATUS_DONE === cart.latestReturnRefund.status"
                      class="mr-1 error--text"
                    >
                      was returned/refunded.
                    </span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-show="cart.reason"
                    class="error--text"
                  >
                    Reason: {{ cart.reason }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-show="cart.latestReturnRefund"
                    class="primary--text"
                  >
                    <v-btn
                      @click="viewReturnRefund(cart.latestReturnRefund)"
                      x-small
                      color="primary"
                    >
                      {{ getDisplayRefund(cart) }}
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <TotalBreakdown
      v-show="!hideTotal"
      :checkout="checkout"
      :orderId="checkout.orderId"
      :paymentStatus="checkout.paymentStatus"
      :status="checkout.status"
      :modeOfPayment="modeOfPayment"
      :discountAfterRefund="checkout.discountAfterRefund"
      :subtotal="checkout.subtotal"
      :discountValue="checkout.discountValue"
      :promoType="checkout.promoType"
      :shippingFee="checkout.shippingFee"
      :total="checkout.total"
      :totalVerifiedPayments="checkout.totalVerifiedPayments"
      :totalRefund="checkout.totalRefund"
      :isVerifyingPwdScId="displayAsterisk"
      :paymentMade="checkout.totalVerifiedPayments"
      :returnRefundDetails="checkout.latestReturnRefund"
      :key="checkout.length"
    />
    <div v-if="checkout.hasReturnRefund">
      {{ returnRefundDisplaybyStatus(checkout.latestReturnRefund) }}
    </div>

    <div v-if="showReturnRefund(checkout)" class="pt-4">
      <v-btn small color="primary" @click="returnOrder()">
        Return/Refund
      </v-btn>
    </div>

    <v-dialog v-model="dialog" width="600">
      <RatingInput
        :selectedCartItem="selectedCartItem"
        :checkoutId="checkout.id"
        @rated-refresh="ratedRefresh"
      />
    </v-dialog>

    <v-dialog v-model="dialogReturnRefund" width="400">
      <ReturnRefundDetails :selectedReturnRefund="selectedReturnRefund" />
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { DB, loadShippingPromo } from "@/common/store";
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_RETURNREFUND,
  ORDER_STATUS_VOID,
  isStatusCancelled
} from "@/common/utilities/order";
import {
  RECEIVE_FOR_PICK_UP,
  STATUS_DONE,
  STATUS_REFUND_REQUESTED,
  IsNotCashOnDelivery
} from "@/common/utilities/payment";

import { cancelItem, loadAccountCart } from "@/utilities/collection/addtocart";
import { db } from "@/main";
import { getPromo, isExpired } from "../utilities/discounts";
import { getVerboseDateStringFromTimestamp } from "@/common/utilities/date";
import { increaseItemQuantity as catalogIncreaseItemQuantity } from "@/utilities/collection/catalog";
import { increaseItemQuantity as productVariantIncreaseItemQuantity } from "@/utilities/collection/productvariant";
import { makeProductsAvailable } from "@/utilities/collection/products";
import { mapGetters } from "vuex";
import { routeTo, ROUTE_RETURN_REFUND_INPUT } from "@/router";
import {
  setTransactionHistory,
  returnRefundDisplaybyStatus
} from "../utilities/orders";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import ItemRating from "@/eTindahan/components/ItemRating.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";
import RatingInput from "@/eTindahan/components/RatingInput.vue";
import ReturnRefundDetails from "@/eTindahan/components/ReturnRefundDetails.vue";
import TotalBreakdown from "@/eTindahan/components/TotalBreakdown.vue";

export default {
  name: "CartItem",

  components: {
    ConfirmDialog,
    ItemRating,
    PesoAmount,
    RatingInput,
    ReturnRefundDetails,
    TotalBreakdown
  },

  props: {
    checkout: Object,
    discountValue: Number,
    fullqr: String,
    id: String,
    modeOfPayment: String,
    receivingOption: String,
    shippingFee: Number,
    status: String
  },

  data: () => ({
    search: "",
    dialog: false,
    cartItems: [],
    cartItemsRefund: [],
    checkedItems: [],
    totalRefund: 0,
    subtotalAfterRefund: 0,
    discountAfterRefund: 0,
    priceAfterRefund: 0,
    selectedCartItem: {},
    promo: 0,
    startingAmount: 0,
    hasPwdSc: false,
    latestRefundCart: {},

    dialogReturnRefund: false,
    selectedReturnRefund: {},
    componentKey: 0,
    cartorderDetails: [],

    // functions
    getPromo: getPromo,
    isExpired: isExpired,
    returnRefundDisplaybyStatus: returnRefundDisplaybyStatus,

    ORDER_STATUS_CANCELLED: ORDER_STATUS_CANCELLED,
    ORDER_STATUS_COMPLETED: ORDER_STATUS_COMPLETED,
    ORDER_STATUS_PROCESSING: ORDER_STATUS_PROCESSING,
    ORDER_STATUS_RETURNREFUND: ORDER_STATUS_RETURNREFUND,
    STATUS_DONE: STATUS_DONE
  }),

  mounted() {
    this.loadShippingPromo();
    this.loadSelectedCart();
  },

  computed: {
    ...mapGetters(["getPwdScDetails"]),

    displayAsterisk() {
      return (
        this.status === ORDER_STATUS_PROCESSING &&
        this.hasPwdSc &&
        !this.getPwdScDetails.isVerified
      );
    },

    hideTotal() {
      return [ORDER_STATUS_CANCELLED, ORDER_STATUS_VOID].includes(this.status);
    }
  },

  watch: {
    cartItems(value) {
      this.hasPwdSc = false;

      value.forEach(item => {
        if (
          item.isPWDSCPriceApplied &&
          !item.refundAmount &&
          !item.rejectedAmount
        ) {
          this.hasPwdSc = true;
        }
        this.cartorderDetails.push({ ...item, id: item.id });
      });
    }
  },

  methods: {
    async cancelItem(cart) {
      if (
        await this.$refs.confirm.open(
          this.$t("dialog.confirmation"),
          "Sigurado ho ba kayong nais niyong i-cancel ang item na ito? Maaring magbago ang 'Shipping Calculation'."
        )
      ) {
        this.updateInventory(
          cart.productSKU,
          cart.vproductSKU,
          Number(cart.quantity)
        );

        cancelItem(cart.id, cart.price, cart.quantity).then(() => {
          let timestamp = new Date();
          let comment = `You Cancelled Your Order ${
            cart.name
          } on ${getVerboseDateStringFromTimestamp(timestamp)}`;
          setTransactionHistory(this.checkout, timestamp, comment);

          this.tagCancellingItem(cart);
        });
      }
    },

    cartCheckCancelled(cartItems) {
      return new Promise(resolve => {
        this.checkedItems = [];

        cartItems.forEach(cart => {
          if (cart.refundAmount || cart.reason) {
            this.checkedItems.push(cart.refundAmount);
          }
        });

        resolve(this.checkedItems);
      });
    },

    getDisplayRefund(cart) {
      if (cart.latestReturnRefund) {
        return `Item ${cart.latestReturnRefund.status} for ${cart.latestReturnRefund.reinstateOption}`;
      }
    },

    getNewPrice(shippingAfterRefund) {
      return this.cartItems.length === this.checkedItems.length
        ? this.totalRefund
        : Number(this.subtotalAfterRefund) + shippingAfterRefund;
    },

    getSetCancelledItemOrder(checkout) {
      return new Promise((resolve, reject) => {
        this.loadSelectedCart()
          .then(() => {
            return this.getSetPayment(Number(checkout.priceAfterRefund));
          })
          .then(() => {
            return db
              .collection(DB.CHECKOUT)
              .doc(checkout.id)
              .get();
          })
          .then(doc => {
            let cancelledItems = doc.data().cancelledItems || [];
            cancelledItems.push(checkout.cartId);

            let newValues = {
              cancelledAt: new Date(),
              cancelledItems: [...new Set(cancelledItems)],
              totalRefund: Number(checkout.totalRefund),
              subtotal: Number(checkout.subtotalAfterRefund),
              discountAfterRefund: Number(checkout.discountAfterRefund),
              total: Number(checkout.priceAfterRefund),
              totalVerifiedPayments: Number(checkout.priceAfterRefund),
              shippingFee: Number(checkout.shippingAfterRefund)
            };
            if (this.cartItems.length === this.checkedItems.length) {
              newValues["status"] = ORDER_STATUS_CANCELLED;
            }

            return db
              .collection(DB.CHECKOUT)
              .doc(checkout.id)
              .set({ ...newValues }, { merge: true });
          })
          .then(() => resolve(checkout))
          .catch(err => reject(err));
      });
    },

    getSetPayment(priceAfterRefund) {
      return new Promise((resolve, reject) => {
        db.collection(DB.PAYMENT)
          .where("transactionNumber", "==", this.fullqr)
          .get()
          .then(querySnapshot => {
            let batch = db.batch();

            querySnapshot.forEach(doc => {
              batch.set(
                db.collection(DB.PAYMENT).doc(doc.id),
                { transactionAmount: priceAfterRefund },
                { merge: true }
              );
            });

            return batch.commit();
          })
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },

    getShippingFee() {
      return new Promise(resolve => {
        if (RECEIVE_FOR_PICK_UP === this.receivingOption) {
          resolve(0);
        } else {
          let itemShippingFees = [];
          let defaultItemShippingFees = [];
          let total = 0;
          this.cartItems.forEach(cart => {
            defaultItemShippingFees.push(Number(cart.itemShippingFee));
            if (!cart.refundAmount && !cart.rejectedAmount) {
              itemShippingFees.push(Number(cart.itemShippingFee));
              total += cart.price * cart.quantity;
            }
          });

          if (this.cartItems.length === this.checkedItems.length) {
            resolve(Math.max(...defaultItemShippingFees)); // get Original Shipping
          } else {
            resolve(
              this.promo &&
                (0 === itemShippingFees.length || total >= this.startingAmount)
                ? 0
                : Math.max(...itemShippingFees)
            );
          }
        }
      });
    },

    returnOrder() {
      this.componentKey++;

      let refundDetails = { checkout: this.checkout };
      refundDetails.checkout["balance"] =
        this.checkout.total - this.checkout.totalVerifiedPayments || 0;

      const NAMESPACE = "etindahan/checkout/";

      this.$store
        .dispatch(`${NAMESPACE}setCartDetails`, this.cartorderDetails)
        .then(() => {
          return this.$store.dispatch(
            `${NAMESPACE}setRefundDetails`,
            refundDetails
          );
        })
        .then(() => {
          routeTo(ROUTE_RETURN_REFUND_INPUT);
        });
    },

    showItems(cart) {
      return (
        this.status !== ORDER_STATUS_RETURNREFUND ||
        (!cart.latestReturnRefund &&
          IsNotCashOnDelivery(this.modeOfPayment) &&
          isStatusCancelled(this.status))
      );
    },

    showReturnRefund(checkout) {
      if (checkout.latestReturnRefund) {
        return STATUS_REFUND_REQUESTED === checkout.latestReturnRefund.status;
      }

      return ORDER_STATUS_COMPLETED === checkout.status;
    },

    viewReturnRefund(selectedReturnRefund) {
      this.selectedReturnRefund = selectedReturnRefund;
      this.dialogReturnRefund = true;
    },

    getTotalCancelledItem(cartItems) {
      return new Promise(resolve => {
        this.totalRefund = 0;
        this.subtotalAfterRefund = 0;
        this.discountAfterRefund = 0;

        cartItems.forEach(cart => {
          if (cart.refundAmount || cart.rejectedAmount) {
            this.totalRefund += cart.price * cart.quantity;
          } else {
            this.subtotalAfterRefund += cart.price * cart.quantity;
          }
        });
        this.discountAfterRefund = this.discountValue / cartItems.length;

        resolve(cartItems); // passthrough cartItems
      });
    },

    iterateCart(cartItem) {
      if (cartItem.latestReturnRefund) {
        this.cartItemsRefund.push({
          ...cartItem,
          status: ORDER_STATUS_RETURNREFUND,
          fullqr: `${cartItem.fullqr}-${ORDER_STATUS_RETURNREFUND}`,
          id: `${cartItem.id}-${ORDER_STATUS_RETURNREFUND}`,
          orderId: `${cartItem.orderId}-${ORDER_STATUS_RETURNREFUND}`
        });
      }
    },

    loadSelectedCart() {
      return new Promise(resolve => {
        loadAccountCart(this.id)
          .then(docs => {
            this.cartItems = docs;
            this.$emit("cart-orders", this.cartItems);
            return this.getTotalCancelledItem(docs);
          })
          .then(docs => {
            return this.cartCheckCancelled(docs);
          })
          .then(() => resolve());
      });
    },

    loadShippingPromo() {
      return new Promise(resolve => {
        loadShippingPromo().then(docs => {
          this.promo = docs.promo;
          this.startingAmount = Number(docs.startingAmount);
          resolve(docs);
        });
      });
    },

    ratedRefresh() {
      this.loadSelectedCart();
      this.dialog = false;
    },

    rateItem(cart) {
      this.selectedCartItem = cart;
      this.dialog = true;
    },

    tagCancellingItem(cart) {
      this.loadSelectedCart()
        .then(() => {
          return this.getShippingFee();
        })
        .then(shippingAfterRefund => {
          if (this.cartItems.length === this.checkedItems.length) {
            this.totalRefund += shippingAfterRefund;
          } else {
            if (
              this.subtotalAfterRefund < this.startingAmount &&
              this.shippingFee === 0
            ) {
              this.totalRefund -= shippingAfterRefund; // deduct refund
            }
          }
          this.priceAfterRefund = this.getNewPrice(shippingAfterRefund);

          return this.getSetCancelledItemOrder({
            id: this.id,
            cartId: cart.id,
            totalRefund: this.totalRefund,

            subtotalAfterRefund: this.subtotalAfterRefund.toFixed(2),
            discountAfterRefund: this.discountAfterRefund.toFixed(2), //51.75
            priceAfterRefund: this.priceAfterRefund.toFixed(2),
            shippingAfterRefund: shippingAfterRefund.toFixed(2)
          });
        })
        .then(() => {
          this.loadSelectedCart();
        });
    },

    updateInventory(prSKU, vprSKU, qty) {
      productVariantIncreaseItemQuantity(vprSKU, qty);
      catalogIncreaseItemQuantity(prSKU, qty);
      makeProductsAvailable(vprSKU, qty);
    }
  }
};
</script>

<style scoped>
.truncate {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
