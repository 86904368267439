<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card
          class="mx-auto"
          max-width="400"
          @click="takePrescriptionPhoto()"
        >
          <v-img
            contain
            max-width="400"
            src="@/assets/cellphone-on-a-right-hand-being-touched-by-a-finger-of-other-right-hand-svgrepo-com.svg"
          />
          <v-card-title class="justify-center">
            Take Prescription Photo
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card
          class="mx-auto"
          max-width="400"
          @click="uploadPrescriptionPhoto()"
        >
          <v-img
            contain
            max-width="390"
            src="@/assets/upload-svgrepo-com.svg"
          />
          <v-card-title class="justify-center">
            Upload Prescription
          </v-card-title>
        </v-card>
        <input
          ref="file_upload_input"
          type="file"
          accept="image/*"
          @change="setImage"
          style="visibility: hidden"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ROUTE_CAPTURE, routeTo } from "@/router";

export default {
  name: "RxPhotoCapture",

  methods: {
    takePrescriptionPhoto() {
      routeTo(ROUTE_CAPTURE);
    },

    uploadPrescriptionPhoto() {
      this.$refs.file_upload_input.click();
    },

    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.$refs.file_upload_input.value = "";
          this.$emit("onPhotoCapture", event.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>
