<template>
  <div>
    <v-container fluid class="pa-0">
      <span class="bg" />

      <v-parallax
        dark
        height="700"
        :src="require('../assets/homeheadus.jpg')"
        style="margin-top: 0px;"
      >
        <v-layout align-center column />
      </v-parallax>

      <v-container>
        <v-card flat class="pa-0">
          <v-card-title class="headline px-0 primary--text">
            Our Story
          </v-card-title>
          <v-card-text class="pa-0">
            <div>
              <div class="text-justify body-1">
                <p>
                  The story of MediSure Plus started in March 2020 when Metro
                  Manila was declared on lockdown due to the pandemic. A group
                  of friends, with backgrounds from medicine and microfinance,
                  realized the lack of an affordable online healthcare service
                  that caters to the primary care needs of the lower middle
                  income and low income market. One of them took in a software
                  developer as partner and several video calls later, MediSure
                  Plus was conceptualized with the mission of bringing
                  affordable primary care to the homes of the target market,
                  accessed through a web based app for ease and convenience.
                </p>
              </div>

              <div class="text-justify body-1">
                <p>
                  MediSure Plus is the first e-commerce store that offers
                  affordable products and services focused on primary care for
                  the family, personal health and well-being plus other items
                  for the home. Our company philosophy is “compassion, nurturing
                  and in an infinite mission to be the best”.
                </p>
              </div>
            </div>
          </v-card-text>
          <v-card-actions />
        </v-card>

        <v-divider class="pa-5" />

        <v-card-title class="headline primary--text">
          MediSure Story
        </v-card-title>
        <v-timeline>
          <v-timeline-item
            v-for="(year, i) in years"
            :key="i"
            :color="year.color"
            small
          >
            <template v-slot:opposite>
              <span
                :class="`headline font-weight-bold ${year.color}--text`"
                v-text="year.year"
              />
            </template>
            <div v-if="i % 2 == 1" class="py-4" style="text-align: right;">
              <div>
                {{ year.text }}
              </div>
            </div>
            <div v-else class="py-4">
              <div>
                {{ year.text }}
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    windowWidth: 0,
    years: [
      {
        color: "primary",
        year: "January 9, 2021",
        text: "Pre-launch of web-app though the e-Tindahan"
      },
      {
        color: "primary",
        year: "August 18, 2020",
        text: "Officially Approved as a Corporation"
      },
      {
        color: "primary",
        year: "July 2020",
        text: "Business plan completed"
      },
      {
        color: "primary",
        year: "April 2020",
        text: "Software development of MediSure Plus Starts"
      },
      {
        color: "primary",
        year: "March 2020",
        text: "MediSure plus was conceptualized"
      }
    ]
  }),

  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);

      //Init
      this.getWindowWidth();
    });
  },

  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
};
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}
</style>
