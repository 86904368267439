<template>
  <div class="pa-4">
    <Header class="mb-5" header="CLINICAL MICROSCOPY" subheader="Urinalysis" />
    <table class="text-left">
      <tr>
        <td width="150"><strong>MACROSCOPIC</strong></td>
        <td width="150"><strong>Results</strong></td>
        <td width="150">
          <strong>Reference Range</strong>
        </td>
        <td>
          <strong>CHEMICAL EXAMINATION</strong>
        </td>
      </tr>
    </table>

    <table class="text-left">
      <tr>
        <td width="150">Color</td>
        <td width="150">{{ urinalysisData.color }}</td>
        <td width="150" />
        <td width="150">Urobilinogen</td>
        <td>{{ urinalysisData.urobilinogen }}</td>
      </tr>

      <tr>
        <td>Clarity</td>
        <td>{{ urinalysisData.clarity }}</td>
        <td />
        <td>Bilirubin</td>
        <td>{{ urinalysisData.bilirubin }}</td>
      </tr>

      <tr>
        <td />
        <td />
        <td />
        <td>Protein</td>
        <td>{{ urinalysisData.protein }}</td>
      </tr>

      <tr>
        <td><strong>MICROSCOPIC</strong></td>
        <td />
        <td />
        <td>Blood</td>
        <td>{{ urinalysisData.blood }}</td>
      </tr>

      <tr>
        <td>WBC</td>
        <td>{{ urinalysisData.wbc }}</td>
        <td><strong>0-4/HPO</strong></td>
        <td>Ketone</td>
        <td>{{ urinalysisData.ketone }}</td>
      </tr>

      <tr>
        <td>RBC</td>
        <td>{{ urinalysisData.rbc }}</td>
        <td><strong>0-3/HPO</strong></td>
        <td>Nitrite</td>
        <td>{{ urinalysisData.nitrite }}</td>
      </tr>

      <tr>
        <td>Epithelial Cells</td>
        <td>{{ urinalysisData.epithelialCells }}</td>
        <td />
        <td>WBC</td>
        <td>{{ urinalysisData.wbcChemicalExamination }}</td>
      </tr>

      <tr>
        <td>Bacteria</td>
        <td>{{ urinalysisData.bacteria }}</td>
        <td />
        <td>Glucose</td>
        <td>{{ urinalysisData.glucose }}</td>
      </tr>

      <tr>
        <td>Mucus Thread</td>
        <td>{{ urinalysisData.mucusThread }}</td>
        <td />
        <td>Specific Gravity</td>
        <td>{{ urinalysisData.specificGravity }}</td>
      </tr>

      <tr>
        <td>Amorphous Urates</td>
        <td>{{ urinalysisData.amorphousUrates }}</td>
        <td />
        <td>pH</td>
        <td>{{ urinalysisData.ph }}</td>
      </tr>

      <tr>
        <td>Amorphous Phosphate</td>
        <td>{{ urinalysisData.amorphousPhosphate }}</td>
        <td />
        <td />
        <td />
      </tr>

      <tr>
        <td>Yeast Cells</td>
        <td>{{ urinalysisData.yeastCells }}</td>
        <td />
        <td />
        <td />
      </tr>
    </table>

    <div class="mt-5 show-borders" style="height: 100px;">
      <div class="ma-3 smaller-bold">REMARKS</div>

      <div class="ma-3 text-left">{{ urinalysisData.remarks }}</div>
    </div>

    <div class="mt-12">
      <v-img src="@/assets/Results/signatures.png" alt="signatures" />
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
export default {
  components: {
    Header
  },

  data: () => ({
    urinalysisData: {}
  }),

  mounted() {
    this.urinalysisData = this.$store.getters["getUrinalysisData"];
  }
};
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.show-borders {
  border: 1px solid black;
  border-collapse: collapse;
}

.smaller-bold {
  font-size: small;
  font-weight: bold;
}
</style>
