<template>
  <div class="hidden">
    <v-row dense>
      <v-col class="pa-0" cols="12" sm="12">
        <v-carousel
          hide-delimiters
          height="380px"
          cycle
          interval="5000"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(displaylist, dlist) in displayheader"
            :key="dlist"
            :src="displaylist.imageUrl"
          >
            <v-row
              class="fill-height"
              color="grey lighten-4"
              align="center"
              justify="center"
            >
              <div
                :class="displaylist.colour"
                class="display-2 white--text pl-5 pr-5 hidden-sm-only"
              >
                <strong>{{ displaylist.nameheader }}</strong>
              </div>
              <br />
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <v-container>
      <div class="row pt-4">
        <div class="col-md-2 col-sm-2 col-xs-12 pa-0">
          <v-card outlined>
            <v-card-title>Categories</v-card-title>
            <v-divider />
            <template>
              <v-treeview
                activatable
                dense
                item-key="name"
                :items="categories"
                open-on-click
              >
                <template slot="label" slot-scope="{ item }">
                  <a @click="content(item)">{{ item.name }}</a>
                </template>
              </v-treeview>
            </template>
          </v-card>
        </div>

        <div class="col-md-10 col-sm-10 col-xs-12 py-0">
          <v-row dense>
            <v-col cols="12" sm="6" class="py-1">
              <v-text-field
                v-model="search"
                hide-details
                dense
                label="Search"
                solo
                @keydown.enter="searchProduct()"
              >
                <template v-slot:append>
                  <v-btn
                    class="mx-0"
                    color="primary"
                    fab
                    x-small
                    @click="searchProduct()"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="py-1">
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" color="primary">
                    <span>My Purchases</span>
                  </v-btn>
                </template>
                <v-card
                  class="caption primary--text mx-auto"
                  max-width="344"
                  outlined
                >
                  <v-list-item @click.stop="routeTo(ROUTE_TRACKORDER)">
                    <v-list-item-title class="caption primary--text">
                      Track Orders
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="ROUTE_WISHLISTS">
                    <v-list-item-title class="caption primary--text my-0">
                      Wishlist
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="ROUTE_ADDRESS_BOOK">
                    <v-list-item-title class="caption primary--text my-0">
                      Address Book
                    </v-list-item-title>
                  </v-list-item>
                </v-card>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="2" class="py-1">
              <div style="float:right;" class="px-5">
                <v-menu open-on-hover offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined v-on="on" color="primary">
                      <span>Sort By</span>
                    </v-btn>
                  </template>
                  <v-card
                    class="caption primary--text mx-auto"
                    max-width="344"
                    outlined
                  >
                    <v-list-item @click="loadRelevance()">
                      <v-list-item-title class="caption primary--text">
                        Relevance
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="loadProductBoxRating()">
                      <v-list-item-title class="caption primary--text my-0">
                        Popular
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="loadProductBoxSold()">
                      <v-list-item-title class="caption primary--text">
                        Top Sales
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="loadProductBoxPriceLtH()">
                      <v-list-item-title class="caption primary--text">
                        Price (Low to High)
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="loadProductBoxPrice('desc')">
                      <v-list-item-title class="caption primary--text">
                        Price (High to Low)
                      </v-list-item-title>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </div>
            </v-col>

            <v-breadcrumbs
              :items="breadcrumbs"
              v-bind:style="{ cursor: 'pointer' }"
              class="pa-0"
            >
              <v-breadcrumbs-item
                class="primary--text pa-0"
                slot="item"
                slot-scope="{ item }"
                exact
                @click="openSearch(item)"
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </v-breadcrumbs>
          </v-row>

          <div class="row text-center">
            <div
              class="col-md-3 col-sm-3 col-xs-12 pr-0"
              v-for="(boxlist, blist) in filteredStat"
              :key="blist"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  @click="loadata(boxlist)"
                  v-model="hisid"
                  class="mx-auto"
                >
                  <v-img
                    class="white--text align-end"
                    :aspect-ratio="2 / 2"
                    :src="boxlist.imageUrl"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-btn v-if="boxlist.inventory !== '0'" outlined>
                          Stock:
                          {{
                            Number(boxlist.inventory) -
                              Number(arrReserved[blist]) +
                              Number(arrCurrentUserReserved[blist])
                          }}
                        </v-btn>
                        <v-btn v-else outlined>
                          Out of Stock
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>

                  <v-card-text class="pa-0">
                    <div>
                      <strong
                        class="black--text"
                        href="/product"
                        style="text-decoration: none"
                      >
                        {{ boxlist.name }}
                      </strong>
                    </div>

                    <CatalogRating
                      :rating="boxlist.rating"
                      :sold="Number(boxlist.sold)"
                    />

                    <strong
                      v-if="boxlist.priceRange"
                      class="primary--text mb-1"
                    >
                      {{ boxlist.priceRange }}
                    </strong>
                    <strong v-else class="primary--text mb-1">
                      <PesoAmount :amount="Number(boxlist.price)" />
                    </strong>
                  </v-card-text>
                </v-card>
              </v-hover>
            </div>
          </div>
        </div>
      </div>
    </v-container>

    <v-container class="pt-15 px-0">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col class="pl-0" cols="2" align="right">
              <v-icon class="display-1 primary--text">mdi-truck</v-icon>
            </v-col>
            <v-col class="px-0" cols="10">
              <h3 class="font-weight-light primary--text">
                FREE SHIPPING
              </h3>
              <p class="font-weight-thin">Free shipping on selected items</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col class="pl-0" cols="2" align="right">
              <v-icon class="display-1 primary--text">mdi-headset</v-icon>
            </v-col>
            <v-col class="px-0" cols="10">
              <h3 class="font-weight-light primary--text">
                mso.tindahan@medisureonline.com
              </h3>
              <p class="font-weight-thin">
                Support available from 8:00 AM to 5:00 PM (Monday to Friday)
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-card color="primary" class="caption text-center white--text" flat>
      <v-card-text class="white--text pa-0">
        Same day delivery for orders taken before 10 AM Philippine Time in Metro
        Manila
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  ROUTE_PRODUCTDETAILS,
  ROUTE_TRACKORDER,
  ROUTE_WISHLISTS,
  ROUTE_ADDRESS_BOOK,
  ROUTE_SEARCHED_PRODUCTS,
  routeTo
} from "@/router";
import { SERVICE_TINDAHAN } from "@/common/utilities/services";

import {
  getCurrentUserReservedItemsCount,
  getReservedItemsCount
} from "@/utilities/collection/products";
import { loadProductCategories, logUserActivity } from "@/common/store";
import { mapState } from "vuex";

import CatalogRating from "@/eTindahan/components/CatalogRating.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  components: {
    CatalogRating,
    PesoAmount
  },

  data: () => ({
    breadcrumbs: [
      {
        text: "All"
      }
    ],
    categories: [],
    hisid: "",
    name: "",
    search: "",
    searcheableArrName: [],

    arrCurrentUserReserved: [],
    arrname: [],
    arrReserved: [],

    isCategorize: false,
    isSortedPrice: false,
    isSortedRating: false,
    isSortedSold: false,

    ROUTE_ADDRESS_BOOK: ROUTE_ADDRESS_BOOK,
    ROUTE_TRACKORDER: ROUTE_TRACKORDER,
    ROUTE_WISHLISTS: ROUTE_WISHLISTS,

    routeTo: routeTo
  }),

  computed: {
    ...mapState([
      "displayheader",
      "productbox",
      "productboxbycat",
      "productboxcatprice",
      "productboxcatrating",
      "productboxcatsold",
      "productboxprice",
      "productboxrating",
      "productboxsold"
    ]),

    filteredStat() {
      if (this.isSortedSold) {
        return this.filterSold();
      } else if (this.isSortedRating) {
        return this.filterRating();
      } else if (this.isSortedPrice) {
        return this.filterContent(
          this.isCategorize ? this.productboxcatprice : this.productboxprice
        );
      }

      return this.filterContent(
        this.isCategorize ? this.productboxbycat : this.productbox
      );
    }
  },

  created() {
    this.$store.dispatch("loadDisplay", "displayheader");
    this.$store.dispatch("loadProductBox", { section: SERVICE_TINDAHAN });

    this.search = "";
    loadProductCategories(false).then(docs => {
      this.categories = docs;
    });
  },

  watch: {
    filteredStat(value) {
      this.arrCurrentUserReserved = [];
      this.arrReserved = [];

      value.forEach(item => {
        getCurrentUserReservedItemsCount(item.id).then(size => {
          this.arrCurrentUserReserved.push(size);
        });

        getReservedItemsCount(item.id).then(size => {
          this.arrReserved.push(size);
        });
      });
    }
  },

  methods: {
    constructProductDetails(boxlist) {
      let productDetails = {
        docid: boxlist.id,
        owner: boxlist.owner,
        selectedItemrating: boxlist.rating,
        selectedItemsold: boxlist.sold,
        name: boxlist.name,
        description: boxlist.description,
        downloadUrl: boxlist.imageUrl,
        category: boxlist.category,

        isPulled: boxlist.isPulled,

        priceRange: boxlist.priceRange || 0,
        price: Number(boxlist.price),

        inventory: Number(boxlist.inventory),

        itemShippingFee: boxlist.itemShippingFee,
        merchantName: boxlist.merchantName,
        merchantShippingFee: boxlist.merchantShippingFee,
        preOrderFrom: boxlist.preOrderFrom,
        preOrderTo: boxlist.preOrderTo,

        stockWarning: boxlist.stockWarning,
        pwdSc: boxlist.pwdSc,
        wishedBy: boxlist.wishedBy || []
      };

      if (boxlist.discount) {
        productDetails["discount"] = Number(boxlist.discount);
      }

      if (boxlist.discountUntil && boxlist.discountUntil.seconds) {
        productDetails["discountUntil"] = new Date(
          boxlist.discountUntil.seconds * 1000
        );
      }

      return productDetails;
    },

    content(item) {
      this.isCategorize = true;

      let fullPath = (item.parent ? item.parent + ">" : "") + item.name;
      let items = fullPath.split(">");

      this.breadcrumbs = [{ text: "All" }];
      for (let i of items) {
        this.breadcrumbs.push({ text: i });
      }

      this.arrname = [];
      this.searcheableArrName = [];
      this.populateCategories(item);

      if (this.productboxcatsold && this.isSortedSold) {
        this.loadProductBoxSold();
      } else if (this.productboxcatrating && this.isSortedRating) {
        this.loadProductBoxRating();
      } else if (this.productboxcatprice && this.isSortedPrice) {
        this.loadProductBoxPriceLtH();
      } else {
        this.loadBoxListbyCat();
      }
    },

    filterContent(content) {
      return content.filter(entry => {
        return entry.hideItem;
      });
    },

    filterRating() {
      let content = this.filterContent(
        this.isCategorize ? this.productboxcatrating : this.productboxrating
      );

      return content.length > 0 ? content : this.filterContent(this.productbox);
    },

    filterSold() {
      let content = this.filterContent(
        this.isCategorize ? this.productboxcatsold : this.productboxsold
      );

      return content.length > 0 ? content : this.filterContent(this.productbox);
    },

    loadata(boxlist) {
      // TO DO!!! Handle discounts

      this.$store.commit(
        "SET_PRODUCT_DETAILS",
        this.constructProductDetails(boxlist),
        { root: true }
      );

      logUserActivity("View Product", { target: this.hisid }).then(() => {
        routeTo(ROUTE_PRODUCTDETAILS);
      });
    },

    loadBoxListbyCat() {
      this.isSortedSold = false;
      this.isSortedRating = false;
      this.isSortedPrice = false;

      this.$store.dispatch("loadProductBoxbyCat", {
        arrname: this.searcheableArrName,
        section: SERVICE_TINDAHAN
      });
    },

    loadProductBoxPrice(direction) {
      if (this.search) {
        this.loadProductBoxPriceSearched(direction);
      } else {
        this.isSortedRating = false;
        this.isSortedSold = false;
        this.isSortedPrice = true;

        let content = {
          direction: direction,
          section: SERVICE_TINDAHAN
        };

        if (this.isCategorize) {
          this.$store.dispatch("loadProductBoxCatSortPrice", {
            ...content,
            arrname: this.searcheableArrName
          });
        } else {
          this.$store.dispatch("loadProductBoxPrice", { ...content });
        }
      }
    },

    loadProductBoxPriceLtH() {
      this.loadProductBoxPrice("asc");
    },

    loadProductBoxRating() {
      this.isSortedSold = false;
      this.isSortedRating = true;
      this.isSortedPrice = false;

      let dispatch = this.isCategorize
        ? "loadProductBoxCatSortRating"
        : "loadProductBoxRating";
      let content = { section: SERVICE_TINDAHAN };
      if (this.isCategorize) {
        content["arrname"] = [...this.searcheableArrName];
      }

      this.$store.dispatch(dispatch, content);
    },

    loadProductBoxSold() {
      this.isSortedSold = true;
      this.isSortedRating = false;
      this.isSortedPrice = false;

      let dispatch = this.isCategorize
        ? "loadProductBoxCatSortSold"
        : "loadProductBoxSold";

      let content = { section: SERVICE_TINDAHAN };
      if (this.isCategorize) {
        content["arrname"] = [...this.searcheableArrName];
      }

      this.$store.dispatch(dispatch, content);
    },

    loadRelevance() {
      if (this.isCategorize) {
        this.loadBoxListbyCat();
      } else {
        this.isSortedSold = false;
        this.isSortedRating = false;
        this.isSortedPrice = false;
      }
    },

    openSearch(item) {
      if (this.item) {
        this.breadcrumbs.push({ item });
      }

      if ("All" === item.text) {
        this.breadcrumbs = [{ text: "All" }];
        this.isSortedSold = false;
        this.isSortedRating = false;
        this.isSortedPrice = false;
        this.isCategorize = false;
      }
    },

    populateCategories(category) {
      let fullPath =
        (category.parent ? category.parent + ">" : "") + category.name;
      this.arrname.push(fullPath);
      this.searcheableArrName.push(category.name);
      if (category.children) {
        for (let c in category.children) {
          this.populateCategories(category.children[c]);
        }
      }
    },

    searchProduct() {
      this.$store.commit("SET_SEARCHED_PRODUCTS", this.search);
      routeTo(ROUTE_SEARCHED_PRODUCTS);
    }
  }
};
</script>

<style>
.hidden {
  overflow: hidden;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}

@media (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
