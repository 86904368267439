<template>
  <v-card class="pt-4 pb-4">
    <v-row v-if="!insurances.length">
      <v-col class="text-center">You have no existing coverage.</v-col>
    </v-row>
    <v-row v-else v-for="(insurance, i) in insurances" :key="i">
      <v-col>
        <v-card
          class="ml-4 mr-4"
          min-width="320"
          @click="load(i)"
          @click.stop="dialog = true"
        >
          <v-card-title>
            {{ insurance.name }} -
            {{ toMoneyString(insurance.premium) }}
          </v-card-title>
          <v-card-subtitle>
            Valid until
            {{ toDate(new Date(insurance.status.expiration.seconds * 1000)) }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="800">
      <SubscriptionInfo :insurances="insurances" :index="index" />
    </v-dialog>
  </v-card>
</template>

<script>
import { loadInsuranceAccounts } from "@/store";

import SubscriptionInfo from "./SubscriptionInfo.vue";

export default {
  name: "InsuranceHistoryContainer",

  components: {
    SubscriptionInfo
  },

  data: () => ({
    index: 0,
    insurances: [],
    dialog: false
  }),

  created() {
    this.reload();
  },

  methods: {
    load(index) {
      this.index = index;
    },

    reload() {
      loadInsuranceAccounts().then(insurances => {
        this.insurances = insurances;
      });
    },

    toDate(datetime) {
      let month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];

      return `${
        month[datetime.getMonth()]
      } ${datetime.getDate().toString()} ${datetime.getFullYear().toString()}`;
    },

    toMoneyString(number) {
      if (!number) {
        number = 0;
      }

      let balance = number.toFixed(2);
      for (let i = 6; balance.length > i; i += 4) {
        balance = balance.slice(0, -1 * i) + "," + balance.slice(-1 * i);
      }

      return "₱ " + balance;
    }
  }
};
</script>
