<template>
  <div class="pa-0">
    <v-card>
      <v-card-actions class="justify-center px-5">
        <v-row align="center" justify="center">
          <v-col cols="12" md="8" sm="8" class="py-15">
            <div class="text-center primary--text">
              {{ message }}
            </div>
          </v-col>
        </v-row>
      </v-card-actions>

      <div class="text-center">
        <v-btn
          class="ma-2"
          color="primary"
          @click="routeTo(prevRoute.name.replaceAll(/\s/g, ''))"
        >
          Go to {{ prevRoute.name }}
        </v-btn>

        <v-btn color="primary" class="ma-2" @click="routeTo(ROUTE_DASHBOARD)">
          Menu
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ROUTE_DASHBOARD, routeTo } from "@/router";

import { mapGetters } from "vuex";

export default {
  name: "Summary",

  data: () => ({
    message: "",
    prevRoute: "",

    routeTo: routeTo,

    ROUTE_DASHBOARD: ROUTE_DASHBOARD
  }),

  mounted() {
    this.message = this.getSummaryMessage;
    this.prevRoute = this.getPreviousRoute;
  },

  computed: {
    ...mapGetters(["getPreviousRoute", "getSummaryMessage"])
  }
};
</script>
