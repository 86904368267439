<template>
  <v-card class="pa-5" align="center">
    <v-row class="pa-2" align="center" justify="center">
      <h3 class="text-center primary--text">
        <PesoAmount :amount="amount" />
      </h3>
    </v-row>

    <v-row align="center" justify="center">
      <v-col class="pt-0" cols="6" sm="3">
        <v-text-field
          class="select-container"
          :label="`Type ${paidThru} Reference  No.`"
          v-model="referenceNo"
          :rules="fieldRules"
        />
      </v-col>
    </v-row>

    <div class="text-right">
      <NextButton
        text="Submit"
        :disabled="hasReferenceNo"
        @click="addBantayKardBalance"
        class="mt-3"
      />
    </div>
  </v-card>
</template>

<script>
import { ROUTE_SUMMARY, routeTo } from "@/router";
import { STATUS_PAYMENT_VERIFICATION } from "@/common/utilities/appointments";

import { getCurrentUser, getCurrentUserUid } from "@/common/store";
import { mapActions } from "vuex";

import NextButton from "@/components/Controls/NextButton.vue";
import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "LoadBantayKard",

  components: {
    PesoAmount,
    NextButton
  },

  props: {
    paidThru: String,
    amount: Number
  },

  data: () => ({
    fieldRules: [v => !!v || "field required"],
    referenceNo: ""
  }),

  watch: {
    referenceNo() {
      this.$emit("referenceNoTyping", this.referenceNo);
    },

    paidThru() {
      this.$emit("paymentOptionsSelected", this.paidThru);
    }
  },

  computed: {
    hasReferenceNo() {
      return this.referenceNo === "";
    }
  },

  methods: {
    ...mapActions(["setBantayKardConfirmation"]),

    addBantayKardBalance() {
      let details = {
        name: getCurrentUser(),
        id: getCurrentUserUid(),
        referenceNo: this.referenceNo,
        paidThru: this.paidThru,
        amount: this.amount,
        total: this.amount,
        status: STATUS_PAYMENT_VERIFICATION,
        timestamp: new Date()
      };
      this.setBantayKardConfirmation(details)
        .then(() => {
          let message =
            "Maaring lang pong mag antay ng 24 oras habang aming ireview ang reference number, Naitala na po ang detalye ng inyong BantayKard.";
          this.$store.commit("setSummaryMessage", message);

          return this.$store.dispatch("resetSecondsIdle");
        })
        .then(() => {
          routeTo(ROUTE_SUMMARY);
        });
    }
  }
};
</script>

<style scoped>
.select-container >>> input {
  text-align: center;
}
</style>
