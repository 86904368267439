<template>
  <v-container fluid>
    <v-list shaped>
      <div v-if="!appointmentList.length">
        <p class="text-center">{{ noScheduleNotice }}</p>
      </div>

      <v-list-item-group color="primary">
        <div v-for="(item, i) in appointmentList" :key="i">
          <AppointmentEntry
            :entry="item"
            @onCancel="cancelAppointment"
            @onConfirm="$emit('onConfirm', $event)"
            @onSelect="onTimeSlotSelect"
            @onUpdate="onUpdate"
          />
        </div>
      </v-list-item-group>
    </v-list>

    <v-dialog v-model="dialog" width="400">
      <v-card class="py-5">
        <v-card-actions class="justify-center">
          <qrcode-vue
            v-if="appointmentQrCode"
            :value="appointmentQrCode"
            :size="300"
            level="H"
          >
          </qrcode-vue>
        </v-card-actions>
        <div class="pa-1">
          <p class="mb-0 text-center">{{ appointmentLocation }}</p>
          <p class="mb-0 text-center">{{ appointmentTime }}</p>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { DB, getCurrentUserUid } from "@/common/store";
import { SERVICE_LABORATORY } from "@/common/utilities/services";
import {
  STATUS_CANCELLED,
  getScheduleString
} from "@/common/utilities/appointments";

import { db } from "@/main";
import { getCancelAppointmentText } from "@/common/utilities/appointments/dialog";
import { getFullAddress } from "@/common/utilities/location";
import { routeTo } from "@/router";

import ConfirmDialog from "@/common/components/ConfirmDialog.vue";
import QrcodeVue from "qrcode.vue";

export default {
  name: "DiagnosticClinics",

  components: {
    ConfirmDialog,
    QrcodeVue
  },

  data: () => ({
    appointmentList: [],
    appointmentLocation: "",
    appointmentQrCode: "",
    appointmentTime: "",
    dialog: false
  }),

  mounted() {
    this.loadAppointments();
  },

  methods: {
    async cancelAppointment(appointment) {
      if (
        await this.$refs.confirm.open(
          this.$t("dialog.confirmation"),
          this.cancelAppointmentText(appointment)
        )
      ) {
        this.cancelAppointmentInDb(appointment.id).then(() =>
          this.loadAppointments()
        );
      }
    },

    cancelAppointmentInDb(appointmentId) {
      return new Promise((resolve, reject) => {
        db.collection(DB.DIAGNOSTIC_APPOINTMENTS)
          .doc(appointmentId)
          .set(
            { status: STATUS_CANCELLED, timestamp: new Date() },
            { merge: true }
          )
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },

    cancelAppointmentText(appointment) {
      return getCancelAppointmentText(
        this.$t("laboratory.appointmentCancelText"),
        appointment,
        this.$i18n.locale
      );
    },

    loadAppointments() {
      this.loadNextAppointments().then(docs => {
        this.appointmentList = [...docs];
      });
    },

    loadNextAppointments() {
      return new Promise((resolve, reject) => {
        let today = new Date();
        today.setHours(0, 0, 0);

        db.collection(DB.DIAGNOSTIC_APPOINTMENTS)
          .where("user.id", "==", getCurrentUserUid())
          .where("schedule.start", ">=", today)
          .orderBy("schedule.start", "asc")
          .get()
          .then(snapshot => {
            let data = [];

            snapshot.forEach(doc => {
              const entry = doc.data();
              if (entry.status !== STATUS_CANCELLED) {
                data.push({ ...entry, id: doc.id });
              }
            });

            resolve(data);
          })
          .catch(err => reject(err));
      });
    },

    onTimeSlotSelect(data) {
      this.appointmentQrCode = `${data.id}`;
      this.appointmentLocation = `${data.clinicType} sa ${getFullAddress(
        data.service.location
      )}`;
      this.appointmentTime = getScheduleString(data.schedule);
      this.dialog = true;
    },

    onUpdate(appointment) {
      routeTo(`/laboratory/4/${appointment.id}`);
    }
  },

  computed: {
    noScheduleNotice() {
      return `Wala po kayong nakalistang ${SERVICE_LABORATORY} appointment.`;
    }
  }
};
</script>
