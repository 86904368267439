import {
  DOCTYPE_FAMILYHISTORY,
  getDocumentHeader,
  IndexedDocument
} from "@/utilities/user_collection";

import { emptyBooleanText } from "@/utilities/fields";

export const ALLERGY = "Allergy";
export const ARTHRITIS = "Arthritis";
export const CANCER = "Cancer";
export const DEMENTIA_ALZHEIMERS = "Dementia / Alzheimer's";
export const DIABETES = "Diabetes";
export const GASTROINTESTINAL_DISORDER = "Gastrointestinal Disorder";
export const HEART_DISEASE = "Heart Disease";
export const HIGH_BLOOD_PRESSURE = "High Blood Pressure";
export const HIGH_CHOLESTEROL = "High Cholesterol";
export const KIDNEY_DISEASE = "Kidney Disease";
export const LUNG_DISEASE = "Lung Disease";
export const PSYCHOLOGICAL_DISORDER = "Psychological Disorder";
export const STROKE = "Stroke";
export const THYROID_DISEASE = "Thyroid Disease";
export const OTHERS = "Others";

function emptyHistory() {
  return {
    [HIGH_BLOOD_PRESSURE]: false,
    [HIGH_CHOLESTEROL]: false,
    [HEART_DISEASE]: false,
    [STROKE]: false,
    [GASTROINTESTINAL_DISORDER]: emptyBooleanText(),
    [DIABETES]: emptyBooleanText(),
    [DEMENTIA_ALZHEIMERS]: emptyBooleanText(),
    [KIDNEY_DISEASE]: emptyBooleanText(),
    [LUNG_DISEASE]: emptyBooleanText(),
    [PSYCHOLOGICAL_DISORDER]: emptyBooleanText(),
    [THYROID_DISEASE]: emptyBooleanText(),
    [ARTHRITIS]: emptyBooleanText(),
    [CANCER]: emptyBooleanText(),
    [ALLERGY]: emptyBooleanText(),
    [OTHERS]: emptyBooleanText()
  };
}

const CHECKED_KEYS = [
  HIGH_BLOOD_PRESSURE,
  HIGH_CHOLESTEROL,
  HEART_DISEASE,
  STROKE
];

const TEXT_KEYS = [
  GASTROINTESTINAL_DISORDER,
  DIABETES,
  DEMENTIA_ALZHEIMERS,
  KIDNEY_DISEASE,
  LUNG_DISEASE,
  PSYCHOLOGICAL_DISORDER,
  THYROID_DISEASE,
  ARTHRITIS,
  CANCER,
  ALLERGY,
  OTHERS
];

function getDocumentContent(state) {
  return {
    header: getDocumentHeader(DOCTYPE_FAMILYHISTORY, state),
    data: {
      father: { ...state.father },
      mother: { ...state.mother }
    }
  };
}

var familyHistoryBase = new IndexedDocument(
  DOCTYPE_FAMILYHISTORY,
  getDocumentContent
);
export const familyHistory = {
  state: () => ({
    ...familyHistoryBase.state,

    father: emptyHistory(),
    mother: emptyHistory()
  }),

  getters: {
    ...familyHistoryBase.getters,

    getCheckedHistory(state) {
      let history = [];
      CHECKED_KEYS.forEach(key =>
        history.push({
          illness: key,
          father: state.father[key],
          mother: state.mother[key]
        })
      );

      return history;
    },

    getFatherChecked: state => key => {
      return state.father[key].value;
    },

    getFatherComment: state => key => {
      return state.father[key].comment;
    },

    getMotherChecked: state => key => {
      return state.mother[key].value;
    },

    getMotherComment: state => key => {
      return state.mother[key].comment;
    },

    getTextHistory(state) {
      let history = [];
      TEXT_KEYS.forEach(key =>
        history.push({
          illness: key,
          father: { ...state.father[key] },
          mother: { ...state.mother[key] }
        })
      );

      return history;
    }
  },

  mutations: {
    ...familyHistoryBase.mutations,

    RESET_STATE(state) {
      state.father = emptyHistory();
      state.mother = emptyHistory();
    },

    setData(state, data) {
      state.father = data.father || emptyHistory();
      state.mother = data.mother || emptyHistory();
    },

    setFatherChecked(state, data) {
      state.isSaved =
        state.isSaved && state.father[data.key].value === data.value;
      state.father[data.key].value = data.value;
    },

    setFatherComment(state, data) {
      state.isSaved =
        state.isSaved && state.father[data.key].comment === data.value;
      state.father[data.key].comment = data.value;
    },

    setFatherData(state, data) {
      state.isSaved = state.isSaved && state.father[data.key] === data.value;
      state.father[data.key] = data.value;
    },

    setMotherChecked(state, data) {
      state.isSaved =
        state.isSaved && state.mother[data.key].value === data.value;
      state.mother[data.key].value = data.value;
    },

    setMotherComment(state, data) {
      state.isSaved =
        state.isSaved && state.mother[data.key].comment === data.value;
      state.mother[data.key].comment = data.value;
    },

    setMotherData(state, data) {
      state.isSaved = state.isSaved && state.mother[data.key] === data.value;
      state.mother[data.key] = data.value;
    }
  },

  actions: {
    ...familyHistoryBase.actions,

    resetContent({ commit, dispatch }) {
      dispatch("resetDocument");
      commit("RESET_STATE");
    }
  }
};
