<template>
  <div class="text-center">
    <v-progress-circular :size="150" :width="7" color="primary" indeterminate />
  </div>
</template>

<script>
export default {
  name: "CircularProgress"
};
</script>
