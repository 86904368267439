import { getDateFromTimestamp } from "@/common/utilities/date";
import { GetShortTimeFromTimestamp } from "@/common/utilities/time";

export function DisplayAppointmentTime(appointment) {
  return `${getDateFromTimestamp(
    appointment.start
  )} ${GetShortTimeFromTimestamp(
    appointment.start
  )} - ${GetShortTimeFromTimestamp(appointment.end)}`;
}

// TO DO: This is a dummy function that should be fleshed out
export function getTestPrice() {
  return 0;
}
