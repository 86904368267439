<template>
  <div>
    <v-stepper v-model="step" flat>
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          {{ stepHeader1 }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="2"> {{ stepHeader2 }} </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" class=" pt-0">
          <h3
            v-show="$vuetify.breakpoint.mobile"
            class="text-center primary--text pt-2"
          >
            - {{ stepHeader1 }} -
          </h3>
          <SelectSchedule
            ref="selectSchedule"
            :location="location"
            @timeslot-selected="timeslotSelected"
          />
        </v-stepper-content>

        <v-stepper-content step="2" class="pt-0">
          <h3
            v-show="$vuetify.breakpoint.mobile"
            class="text-center primary--text pt-2"
          >
            - {{ stepHeader2 }} -
          </h3>
          <ConfirmSchedule
            :details="selectedTimeSlot"
            @on-back="step = 1"
            @on-confirm="scheduleConfirmed"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import ConfirmSchedule from "@/components/LguServices/ConfirmSchedule.vue";
import SelectSchedule from "@/components/LguServices/SelectSchedule.vue";

export default {
  name: "LguAppointment",

  components: {
    ConfirmSchedule,
    SelectSchedule
  },

  props: {
    location: String
  },

  data: () => ({
    selectedTimeSlot: null,
    step: 1,
    stepHeader1: "Pumili ng Schedule",
    stepHeader2: this.$t("dialog.confirmation")
  }),

  methods: {
    scheduleConfirmed() {
      this.$refs.selectSchedule.reload();
      this.step = 1;
    },

    timeslotSelected(timeslot) {
      this.selectedTimeSlot = timeslot;
      this.step = 2;
    }
  }
};
</script>
