<template>
  <v-card class="pa-5">
    <div class="mb-5 text-center">
      <v-header>
        <strong>{{ header }}</strong>
      </v-header>
      <div v-if="userData.userCode">{{ userData.userCode }}</div>
      <div v-show="isSaving" style="font-size: smaller; color: red;">
        Saving patient data. Please wait...
      </div>
    </div>

    <v-row>
      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-model="userData.firstName"
          @change="checkUserDataCompleteness"
          label="First Name"
          :disabled="isSaving"
          :rules="fieldRules"
        />
      </v-col>

      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-model="userData.middleName"
          @change="checkUserDataCompleteness"
          label="Middle Name"
          :disabled="isSaving"
          :rules="fieldRules"
          class="px-1"
        />
      </v-col>

      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-model="userData.lastName"
          @change="checkUserDataCompleteness"
          label="Last Name"
          :disabled="isSaving"
          :rules="fieldRules"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" sm="4" class="py-0">
        <v-autocomplete
          v-model="userData.sex"
          @change="checkUserDataCompleteness"
          label="Sex"
          :disabled="isSaving"
          :items="['Male', 'Female']"
          :rules="fieldRules"
          prepend-inner-icon="mdi-gender-male-female"
        />
      </v-col>

      <v-col cols="12" sm="4" class="py-0">
        <SelectDate
          v-model="userData.birthday"
          :disabled="isSaving"
          @input="saveBirthDate"
        />
      </v-col>

      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-if="userData.isPWD || isSenior"
          v-model="userData.seniorId"
          @change="checkUserDataCompleteness"
          label="PWD / Senior Citizen ID No."
          :disabled="isSaving"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-model="userData.contactNumber"
          @change="checkUserDataCompleteness"
          label="Contact"
          :disabled="isSaving"
          :rules="fieldRules"
          class="px-1"
        />
      </v-col>

      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-model="userData.attendingPhysician"
          @change="checkUserDataCompleteness"
          label="Attending Physician (optional)"
          :disabled="isSaving"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" sm="4" class="py-0">
        <v-checkbox
          v-model="userData.isPWD"
          @change="checkUserDataCompleteness"
          label="PWD?"
          :disabled="isSaving"
          class="px-1"
        />
      </v-col>
    </v-row>

    <div class="mt-5 text-right">
      <v-btn
        color="primary"
        @click="saveData"
        :disabled="!isCompleteUserData || isSaving"
      >
        Save
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { DB, getCurrentUserUid } from "@/common/store";

import { db } from "@/main";
import { ensureUserHasUserCode } from "@/store/profile";
import { getAge } from "@/utilities/profile";
import { getLabScheduleProfile } from "@/common/utilities/profile";

import SelectDate from "@/common/components/controls/SelectDate.vue";

export default {
  name: "UpdatePatientData",

  components: {
    SelectDate
  },

  props: {
    data: Object
  },

  data: () => ({
    header: String,

    userData: {
      userCode: "",

      firstName: "",
      middleName: "",
      lastName: "",

      sex: "",
      birthday: "",

      isPWD: false,

      contactNumber: "",
      attendingPhysician: "",

      id: ""
    },

    isCompleteUserData: false,
    isSaving: false,

    fieldRules: [v => !!v || "This field is required"]
  }),

  mounted() {
    this.userData = { ...this.data };
    this.header = `${this.userData.userCode ? "Update" : "Add"} Patient Data`;
  },

  methods: {
    checkUserDataCompleteness() {
      this.isCompleteUserData =
        this.userData.firstName &&
        this.userData.middleName &&
        this.userData.lastName &&
        this.userData.sex &&
        this.userData.birthday &&
        this.userData.contactNumber;
    },

    createAssignedUser(details, ownerId) {
      return new Promise((resolve, reject) => {
        let docRef = db.collection(DB.PROFILES).doc();
        docRef
          .set({
            ...getLabScheduleProfile(details),
            assignedTo: [ownerId]
          })
          .then(() => resolve(docRef.id))
          .catch(err => reject(err));
      });
    },

    createUser() {
      this.createAssignedUser(this.userData, getCurrentUserUid())
        .then(userId => {
          return ensureUserHasUserCode(userId, {
            ...this.userData,
            id: userId
          });
        })
        .then(userData => {
          this.$store.commit(
            "lab/appointment/ADD_PATIENT",
            {
              patient: { ...userData }
            },
            { root: true }
          );
          this.emitSave({ isAdded: true, data: { ...userData } });
        })
        .catch(err => this.emitError(err));
    },

    emitError(err) {
      this.isSaving = false;
      this.$emit("onError", err);
    },

    emitSave(saveDetails) {
      this.isSaving = false;
      this.$emit("onSave", saveDetails);
    },

    saveBirthDate() {
      this.checkUserDataCompleteness();
    },

    saveData() {
      this.isSaving = true;

      if (this.userData.userCode) {
        this.updateUser();
      } else {
        this.createUser();
      }
    },

    updateUser() {
      this.updateUserData(this.userData)
        .then(userData => {
          this.$store.commit("lab/appointment/SET_PATIENT_DATA", userData);
          this.emitSave({ isAdded: false, data: { ...userData } });
        })
        .catch(err => this.emitError(err));
    },

    updateUserData(userData) {
      return new Promise((resolve, reject) => {
        db.collection(DB.PROFILES)
          .doc(userData.id)
          .set({ ...userData }, { merge: true })
          .then(() => resolve(userData))
          .catch(err => reject(err));
      });
    }
  },

  computed: {
    isSenior() {
      return getAge(new Date(this.userData.birthday)) >= 60;
    }
  }
};
</script>
