<template>
  <div>
    <v-row>
      <v-col :cols="setWidth(3, 4, 4, 12, 12)" class="py-0">
        <v-text-field
          v-model="lastName"
          label="Last Name"
          :rules="fieldRules"
        />
      </v-col>

      <v-col :cols="setWidth(3, 4, 4, 12, 12)" class="py-0">
        <v-text-field
          v-model="firstName"
          label="First Name"
          :rules="fieldRules"
        />
      </v-col>

      <v-col :cols="setWidth(2, 4, 4, 6, 12)" class="py-0">
        <v-text-field
          v-model="middleName"
          label="Middle Name"
          :rules="fieldRules"
        />
      </v-col>

      <v-col :cols="setWidth(2, 2, 4, 6, 12)" class="py-0">
        <v-text-field v-model="extensionName" label="Extension" />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="setWidth(3, 4, 6, 8, 12)" class="py-0">
        <SimpleDatePicker text="Birth Date" v-model="birthday" />
      </v-col>

      <v-col :cols="setWidth(2, 2, 4, 6, 12)" class="py-0">
        <v-autocomplete
          v-model="sex"
          label="Gender"
          :items="['Male', 'Female']"
          :rules="fieldRules"
        />
      </v-col>

      <v-col :cols="setWidth(2, 2, 4, 6, 12)" class="py-0">
        <v-text-field v-model="companyName" label="Company(optional)" />
      </v-col>

      <v-col :cols="setWidth(2, 2, 4, 6, 12)" class="py-0">
        <v-autocomplete
          v-model="civilStatus"
          label="Civil Status"
          :items="['Single', 'Married', 'Widowed', 'Separated']"
          :rules="fieldRules"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { NS_MEMBERINFO as NAMESPACE } from "@/store";

import { setWidth } from "@/utilities/display";

import SimpleDatePicker from "@/common/components/controls/SimpleDatePicker.vue";

export default {
  name: "EditableMemberInfo",

  components: {
    SimpleDatePicker
  },

  data: () => ({
    fieldRules: [v => !!v || "This field is required"]
  }),

  mounted() {
    this.$store.dispatch(`${NAMESPACE}/load`).then(() => {
      if (!this.birthday) {
        this.birthday = this.$store.getters["getBirthday"];
      }

      if (!this.firstName) {
        this.firstName = this.$store.getters["getFirstName"];
      }

      if (!this.lastName) {
        this.lastName = this.$store.getters["getLastName"];
      }

      if (!this.middleName) {
        this.middleName = this.$store.getters["getMiddleName"];
      }

      if (!this.sex) {
        this.sex = this.$store.getters["getSex"];
      }

      if (!this.companyName) {
        this.companyName = this.$store.getters["getCompanyName"];
      }

      if (!this.civilStatus) {
        this.civilStatus = this.$store.getters["getCivilStatus"];
      }

      this.onChange();
    });
  },

  methods: {
    onChange() {
      this.$emit("onChange", this.$store.getters[`${NAMESPACE}/isComplete`]);
    },

    setWidth(xl, lg, md, sm, fall) {
      return setWidth(this.$vuetify.breakpoint.width, xl, lg, md, sm, fall);
    }
  },

  computed: {
    birthday: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getBirthday`];
      },

      set(value) {
        this.$store.commit(`${NAMESPACE}/setBirthday`, value);
        this.onChange();
      }
    },

    civilStatus: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getCivilStatus`];
      },

      set(value) {
        this.$store.commit(`${NAMESPACE}/setCivilStatus`, value);
        this.onChange();
      }
    },

    companyName: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getCompanyName`];
      },

      set(value) {
        this.$store.commit(`${NAMESPACE}/setCompanyName`, value);
        this.onChange();
      }
    },

    firstName: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getFirstName`];
      },

      set(value) {
        this.$store.commit(`${NAMESPACE}/setFirstName`, value);
        this.onChange();
      }
    },

    middleName: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getMiddleName`];
      },

      set(value) {
        this.$store.commit(`${NAMESPACE}/setMiddleName`, value);
        this.onChange();
      }
    },

    lastName: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getLastName`];
      },

      set(value) {
        this.$store.commit(`${NAMESPACE}/setLastName`, value);
        this.onChange();
      }
    },

    extensionName: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getExtensionName`];
      },

      set(value) {
        this.$store.commit(`${NAMESPACE}/setExtensionName`, value);
        this.onChange();
      }
    },

    sex: {
      get() {
        return this.$store.getters[`${NAMESPACE}/getSex`];
      },

      set(value) {
        this.$store.commit(`${NAMESPACE}/setSex`, value);
        this.onChange();
      }
    }
  }
};
</script>
