<template>
  <div>
    <Appointment
      :appointmentId="appointmentId"
      :selectedTab="selectedTab"
      :key="selectedTab"
    />
  </div>
</template>

<script>
import { SERVICE_LABORATORY } from "@/common/utilities/services";

import Appointment from "@/components/Laboratory/Appointment.vue";

export default {
  name: "MenuItems",

  components: {
    Appointment
  },

  props: {
    inputTab: String,
    appointmentId: String
  },

  data: () => ({
    selectedTab: 1,

    SERVICE_LABORATORY: SERVICE_LABORATORY
  }),

  mounted() {
    this.$store.dispatch("reloadPageIfOldVersion");

    if (this.inputTab) {
      const value = parseInt(this.inputTab);
      this.selectedTab = value > 4 ? 4 : value;
    }
  }
};
</script>
