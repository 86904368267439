<template>
  <v-row class="pa-5">
    <v-col class="pa-0" cols="12" sm="8">
      <v-card outlined class="my-2 mx-1">
        <v-list-item three-line>
          <v-list-item-avatar tile size="80" color="grey">
            <v-img :src="avatarUrl" />
          </v-list-item-avatar>
          <v-list-item-content class="pl-0 py-2">
            <v-list-item-title>
              {{ fullName }}
              <v-btn
                @click="openDialog(true)"
                class="ma-2"
                x-small
                fab
                color="primary"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-title>
            <v-list-item-subtitle>{{ sex }}</v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ birthday }} ({{ age }} years old)
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-card outlined class="my-2 mx-1">
        <v-toolbar
          flat
          color="primary"
          class="text-center white--text pa-0"
          height="30"
        >
          <v-list-item-title class="text-center">
            Present Illness
          </v-list-item-title>
        </v-toolbar>

        <div v-if="Object.keys(presentIllnesses).length">
          <div
            v-for="(illness, i) in presentIllnesses"
            :key="i"
            class="mt-0 px-2"
            :class="{ 'background-grey': !(i % 2) }"
          >
            <div>
              <InfoEntry
                :header="illness.value"
                :content="illness.comment"
                class="my-1"
              />
            </div>
          </div>
        </div>

        <div v-else class="text-center my-1">None</div>
      </v-card>

      <v-card outlined class="my-2 mx-1">
        <v-toolbar
          flat
          color="primary"
          class="text-center white--text pa-0"
          height="30"
        >
          <v-list-item-title class="text-center">
            Previous Illnesses
          </v-list-item-title>
        </v-toolbar>

        <div v-if="Object.keys(previousIllnesses).length">
          <div
            v-for="(illness, i) in previousIllnesses"
            :key="i"
            class="px-2"
            :class="{ 'background-grey': !(i % 2) }"
          >
            <InfoEntry
              :header="illness.value"
              :content="illness.comment"
              class="my-1"
            />
          </div>
        </div>

        <div v-else class="text-center my-1">None</div>
      </v-card>

      <v-card outlined class="my-2 mx-1">
        <v-toolbar
          flat
          color="primary"
          class="text-center white--text pa-0"
          height="30"
        >
          <v-list-item-title class="text-center">
            Family History
          </v-list-item-title>
        </v-toolbar>

        <FamilyHistory :hasHeader="false" />
      </v-card>

      <v-card outlined class="my-2 mx-1">
        <v-toolbar
          flat
          color="primary"
          class="text-center white--text pa-0"
          height="30"
        >
          <v-list-item-title class="text-center">
            Hospitalizations
          </v-list-item-title>
        </v-toolbar>

        <div v-if="hospitalizations.length">
          <div
            v-for="(hospitalization, i) in hospitalizations"
            :key="i"
            class="px-2"
            :class="{
              'background-grey': !(i % 2)
            }"
          >
            <InfoEntry
              :header="hospitalization.hospitalization"
              :content="hospitalization.date + ' - ' + hospitalization.reasons"
              class="my-1"
            />
          </div>
        </div>

        <div v-else class="text-center my-1">None</div>
      </v-card>

      <v-card outlined class="my-2 mx-1">
        <v-toolbar
          flat
          color="primary"
          class="text-center white--text pa-0"
          height="30"
        >
          <v-list-item-title class="text-center">
            Immunization History
          </v-list-item-title>
        </v-toolbar>

        <div v-if="Object.keys(immunizations).length">
          <div
            v-for="(immunization, i) in immunizations"
            :key="i"
            class="mt-0 px-2"
            :class="{ 'background-grey': !(i % 2) }"
          >
            <InfoEntry
              :header="immunization.value"
              :content="immunization.comment"
              class="my-1"
            />
          </div>
        </div>

        <div v-else class="text-center my-1">None</div>
      </v-card>
    </v-col>

    <v-col class="pa-0" cols="12" sm="4">
      <v-card outlined class="my-2 mx-1">
        <v-toolbar
          flat
          color="primary"
          class="text-center white--text pa-0"
          height="30"
        >
          <v-list-item-title class="text-center ">
            Philhealth Membership
            <v-btn
              @click="openDialog(false)"
              class="ma-2"
              x-small
              outlined
              fab
              color="white"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-title>
        </v-toolbar>

        <div>
          <p v-show="!hasPhilhealth" class="text-center pa-0 mb-0 caption">
            If you are member of Philhealth Konsulta Program, kindly fill up the
            following fields.
          </p>
          <v-list-item three-line>
            <v-list-item-content class="pl-0 py-2">
              <v-list-item-title>
                PhilHealth ID: {{ philhealthId }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Client Type: {{ clientType }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Membership Category: {{ membershipCategory }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>

      <v-card outlined class="my-2 mx-1">
        <v-toolbar
          flat
          color="primary"
          class="text-center white--text pa-0"
          height="30"
        >
          <v-list-item-title class="text-center">
            Medication
          </v-list-item-title>
        </v-toolbar>

        <div class="py-1 px-3">
          {{ maintenanceMedications || "None" }}
        </div>
      </v-card>

      <TestResults
        v-show="hasTestResults"
        :userCode="userCode"
        @hasTest="hasTestResults = $event"
      />
    </v-col>

    <v-dialog v-model="updateDialog" width="600">
      <PhilhealthInfo
        :isPersonalInfo="isPersonalInfo"
        :collection="COLLECTION"
        @updateDialog="updateDialog = $event"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import { DB } from "@/common/store";
import {
  NS_HEALTHRECORDS_MEDICALHISTORY as NAMESPACE,
  NS_MEMBERINFO as MEMBERINFO,
  NS_HEALTHRECORDS_PERSONALSOCIALHISTORY as PERSONAL,
  NS_HEALTHRECORDS_VITALSIGNS as VITALSIGNS,
  NS_PHILHEALTHINFO as PHILHEALTHINFO
} from "@/store";
import { ROUTE_MEDICALHISTORY, ROUTE_PHILHEALTH } from "@/router";

import { getAge } from "@/utilities/profile";
import { getDateTime1 } from "@/common/utilities/date";
import { mapGetters } from "vuex";

import FamilyHistory from "@/HealthRecords/components/Confirmation/FamilyHistory.vue";
import InfoEntry from "@/components/Controls/InfoEntry.vue";
import PhilhealthInfo from "@/PhilHealth/components/PhilhealthInfo.vue";
import TestResults from "@/HealthRecords/components/TestResults.vue";

export default {
  name: "HealthProfile",

  components: {
    FamilyHistory,
    InfoEntry,
    PhilhealthInfo,
    TestResults
  },

  data: () => ({
    COLLECTION: DB.PHILHEALTH_INDEX,
    hasTestResults: false,
    userCode: "",

    ROUTE_MEDICALHISTORY: ROUTE_MEDICALHISTORY,
    ROUTE_PHILHEALTH: ROUTE_PHILHEALTH,
    updateDialog: false,
    isPersonalInfo: false
  }),

  mounted() {
    this.userCode = this.$store.state.profile.userCode;

    this.$store.dispatch(`${MEMBERINFO}/load`).then(() => {
      if (!this.fullName) {
        this.isPersonalInfo = true;
        this.updateDialog = true;
      } else {
        this.$store.dispatch(`${NAMESPACE}/load`);
        this.$store.dispatch(`${PERSONAL}/load`);
        this.$store.dispatch(`${VITALSIGNS}/load`);
        this.$store.dispatch(`${PHILHEALTHINFO}/load`);
      }
    });
  },

  methods: {
    openDialog(isPersonalInfo) {
      this.isPersonalInfo = isPersonalInfo;
      this.updateDialog = true;
    }
  },

  computed: {
    ...mapGetters({
      additionalNotes: `${VITALSIGNS}/getAdditionalNotes`,
      avatarUrl: "getAvatarUrl",
      birthday: `${MEMBERINFO}/getBirthday`,
      bloodPressure: `${VITALSIGNS}/getBloodPressure`,
      bmi: `${VITALSIGNS}/getBMI`,
      fullName: `${MEMBERINFO}/getFullName`,
      getAddedOn: `${PERSONAL}/getAddedOn`,
      getImmunizations: `${PERSONAL}/getImmunizations`,
      getPresentIllnesses: `${PERSONAL}/getIllnesses`,
      getPreviousIllnesses: `${NAMESPACE}/getIllnesses`,
      heartRate: `${VITALSIGNS}/getHeartRate`,
      height: `${VITALSIGNS}/getHeight`,
      hospitalizations: `${NAMESPACE}/getHospitalizations`,
      maintenanceMedications: `${PERSONAL}/getMaintenanceMedications`,
      medicalHistoryAddedOn: `${NAMESPACE}/getAddedOn`,
      middleUpperArmCircumference: `${VITALSIGNS}/getMiddleUpperArmCircumference`,
      respiratoryRate: `${VITALSIGNS}/getRespiratoryRate`,
      sex: `${MEMBERINFO}/getSex`,
      temperature: `${VITALSIGNS}/getTemperature`,
      weight: `${VITALSIGNS}/getWeight`,
      zscore: `${VITALSIGNS}/getZscore`,
      membershipCategory: `${PHILHEALTHINFO}/getMembershipCategory`,
      philhealthId: `${PHILHEALTHINFO}/getPhilHealthId`,
      clientType: `${PHILHEALTHINFO}/getClientType`
    }),

    age() {
      return getAge(new Date(this.birthday));
    },

    hasPhilhealth() {
      return this.philhealthId ? true : false;
    },

    immunizations() {
      const items = this.getImmunizations;
      const values = [];

      for (const key of Object.keys(items)) {
        if (items[key].value) {
          values.push({
            value: key,
            comment: items[key].comment
          });
        }
      }

      return values;
    },

    personalAddedOn() {
      return getDateTime1(this.getAddedOn);
    },

    presentIllnesses() {
      const illnesses = this.getPresentIllnesses;
      const values = [];

      for (const key of Object.keys(illnesses)) {
        if (illnesses[key].value) {
          values.push({
            value: key,
            comment: illnesses[key].comment
          });
        }
      }

      return values;
    },

    previousIllnesses() {
      const illnesses = this.getPreviousIllnesses;
      const values = [];

      for (const key of Object.keys(illnesses)) {
        if (illnesses[key].value) {
          values.push({ value: key, comment: illnesses[key].comment });
        }
      }

      return values;
    }
  }
};
</script>

<style scoped>
.link {
  text-decoration: none;
}
</style>
