<template>
  <div>
    <v-card-text
      class="mt-n4"
      v-if="!hasFacebookLogin || !hasGoogleLogin || !hasPhoneLogin"
    >
      <strong>Link your other accounts:</strong>
      <br />

      <v-alert type="error" v-if="errorText">{{ errorText }}</v-alert>

      <span class="displayInLine">
        <span @click="linkGoogleAccount">
          <v-icon
            v-if="!hasGoogleLogin"
            class="ml-2"
            color="primary"
            size="32px"
          >
            mdi-google
          </v-icon>
        </span>
        <span @click="linkFBAccount">
          <v-icon
            v-if="!hasFacebookLogin"
            class="ml-2"
            color="primary"
            size="32px"
          >
            mdi-facebook
          </v-icon>
        </span>
        <span @click="showPhoneSignin = true">
          <v-icon
            v-if="!hasPhoneLogin"
            class="ml-2"
            color="primary"
            size="32px"
          >
            mdi-phone
          </v-icon>
        </span>
      </span>
    </v-card-text>

    <v-card-text
      class="mt-n4"
      v-if="
        (hasFacebookLogin || hasGoogleLogin || hasPhoneLogin) &&
          providerCount > 1
      "
    >
      <strong>Unlink accounts:</strong>
      <br />

      <span class="displayInLine">
        <span @click="unlink('Google account')">
          <v-icon
            v-if="hasGoogleLogin"
            class="ml-2"
            color="primary"
            size="32px"
          >
            mdi-google
          </v-icon>
        </span>
        <span @click="unlink('Facebook account')">
          <v-icon
            v-if="hasFacebookLogin"
            class="ml-2"
            color="primary"
            size="32px"
          >
            mdi-facebook
          </v-icon>
        </span>
        <span @click="unlink('cellphone')">
          <v-icon v-if="hasPhoneLogin" class="ml-2" color="primary" size="32px">
            mdi-phone
          </v-icon>
        </span>
      </span>
    </v-card-text>

    <ConfirmDialog ref="confirm" />

    <v-dialog v-model="showPhoneSignin" width="400">
      <AuthenticationPhoneSignin
        :linkToAccount="true"
        @onClose="showPhoneSignin = false"
        @onSuccess="showPhoneSignin = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

import {
  getCredentialLinkFailed,
  setCredentialLinkFailed
} from "@/utilities/session";

import AuthenticationPhoneSignin from "@/components/Authentication/PhoneSignin.vue";
import ConfirmDialog from "@/common/components/ConfirmDialog.vue";

export default {
  name: "ProfileLinkAccount",

  components: {
    AuthenticationPhoneSignin,
    ConfirmDialog
  },

  data: () => ({
    errorText: "",
    hasFacebookLogin: false,
    hasGoogleLogin: false,
    hasPhoneLogin: false,
    providerCount: 0,
    showPhoneSignin: false
  }),

  mounted() {
    for (let i in firebase.auth().currentUser.providerData) {
      const providerData = firebase.auth().currentUser.providerData[i];
      const providerId = providerData.providerId;

      if ("facebook.com" === providerId) {
        this.hasFacebookLogin = true;
      } else if ("google.com" === providerId) {
        this.hasGoogleLogin = true;
      } else if ("phone" === providerId) {
        this.hasPhoneLogin = true;
      }

      this.providerCount += 1;
    }

    if (getCredentialLinkFailed()) {
      this.errorText =
        "The credential is already associated with a different user account.";
      setCredentialLinkFailed();
    }
  },

  updated() {
    this.isVerificationRequested = this.$store.state.profile.verificationRequested;
  },

  methods: {
    linkFBAccount() {
      const provider = new firebase.auth.FacebookAuthProvider();
      firebase.auth().currentUser.linkWithRedirect(provider);
    },

    linkGoogleAccount() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().currentUser.linkWithRedirect(provider);
    },

    async unlink(provider) {
      if (
        await this.$refs.confirm.open(
          this.$t("dialog.confirmation"),
          `Sigurado ba kayong ayaw na ninyong maka-login sa Medisure gamit ang inyong ${provider}?`
        )
      ) {
        if ("Facebook account" === provider) {
          this.unlinkFacebook();
        } else if ("Google account" === provider) {
          this.unlinkGoogle();
        } else {
          this.unlinkPhone();
        }
      }
    },

    unlinkFacebook() {
      firebase
        .auth()
        .currentUser.unlink(firebase.auth.FacebookAuthProvider.PROVIDER_ID)
        .then(() => {
          this.hasFacebookLogin = false;
          this.providerCount -= 1;
        });
    },

    unlinkGoogle() {
      firebase
        .auth()
        .currentUser.unlink(firebase.auth.GoogleAuthProvider.PROVIDER_ID)
        .then(() => {
          this.hasGoogleLogin = false;
          this.providerCount -= 1;
        });
    },

    unlinkPhone() {
      firebase
        .auth()
        .currentUser.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID)
        .then(() => {
          this.hasPhoneLogin = false;
          this.providerCount -= 1;
        });
    }
  }
};
</script>

<style scoped>
.displayInLine {
  display: inline-flex;
}
</style>
