<template>
  <div>
    <BackButton @click="$emit('back')" />

    <h3 class="mt-10 text-center">Past Medical History</h3>

    <PreviousIllnesses
      header="Previous Illnesses"
      :namespace="NAMESPACE"
      getValue="getIllnessValue"
      getValues="getIllnesses"
      hasValueChanged="hasIllnessValueChanged"
      setValue="setIllnessValue"
      setComment="setIllnessComment"
      :showComments="false"
      :key="NAMESPACE"
      class="mt-15"
    />

    <h4
      class="mt-15 text-center"
      :class="{ 'mb-3': isXLOrLarger, 'mb-5': !isXLOrLarger }"
    >
      <u>Hospitalizations</u>
    </h4>

    <v-row
      v-for="(hospitalization, i) in hospitalizations"
      :key="i"
      class="py-5"
      :class="{
        'background-grey': !(i % 2),
        'mt-1': isXLOrLarger,
        'mt-10': !isXLOrLarger
      }"
    >
      <v-col :cols="setWidth(6, 8, 8, 12, 12)" class="d-inline-flex py-0">
        <span class="pt-2">{{ i }}.</span>
        <v-text-field
          class="mt-0 py-0 px-2"
          v-model="hospitalizations[i].hospitalization"
          placeholder="Hospitalization"
        />
      </v-col>
      <v-col :cols="setWidth(2, 4, 4, 12, 12)" class="py-0">
        <v-text-field
          class="centered-input mt-0 py-0 px-2"
          v-model="hospitalizations[i].date"
          placeholder="Date"
        />
      </v-col>
      <v-col :cols="setWidth(4, 12, 12, 12, 12)" class="d-inline-flex py-0">
        <v-text-field
          v-model="hospitalizations[i].reasons"
          class="mt-0 py-0 px-2"
          placeholder="Reason"
        />
        <v-btn
          @click="deleteRow(i)"
          color="red"
          dark
          small
          style="min-width: 26px; padding: 0;"
        >
          <v-icon>mdi-delete-forever</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row :class="{ 'mt-1': isXLOrLarger, 'mt-5': !isXLOrLarger }">
      <v-col cols="12" class="text-center">
        <v-btn @click="addRow" color="primary">Add New</v-btn>
      </v-col>
    </v-row>

    <NextButton @click="onNext" class="mb-5 mt-10" />
  </div>
</template>

<script>
import { NS_HEALTHRECORDS_MEDICALHISTORY } from "@/store";

import { getCurrentUserUid } from "@/common/store";
import { isXLOrLarger, setWidth } from "@/utilities/display";

import BackButton from "@/components/Controls/BackButton.vue";
import NextButton from "@/components/Controls/NextButton.vue";
import PreviousIllnesses from "./PreviousIllnesses.vue";

export default {
  name: "PastMedicalHistory",

  components: {
    BackButton,
    NextButton,
    PreviousIllnesses
  },

  props: {
    collection: String
  },

  data: () => ({
    NAMESPACE: NS_HEALTHRECORDS_MEDICALHISTORY
  }),

  mounted() {
    this.$store.dispatch(`${this.NAMESPACE}/load`);
  },

  methods: {
    addRow() {
      this.$store.commit(`${this.NAMESPACE}/addHospitalization`, {
        hospitalization: "",
        date: "",
        reasons: ""
      });
    },

    deleteRow(index) {
      this.$store.commit(`${this.NAMESPACE}/deleteHospitalization`, index);
    },

    onNext() {
      this.$store
        .dispatch(`${this.NAMESPACE}/save`, {
          collection: this.collection,
          docId: getCurrentUserUid()
        })
        .then(() => {
          this.$emit("next");
        });
    },

    setWidth(xl, lg, md, sm, fall) {
      return setWidth(this.$vuetify.breakpoint.width, xl, lg, md, sm, fall);
    }
  },

  computed: {
    hospitalizations() {
      return this.$store.getters[`${this.NAMESPACE}/getHospitalizations`];
    },

    isXLOrLarger() {
      return isXLOrLarger(this.$vuetify.breakpoint.width);
    }
  }
};
</script>
