<template>
  <v-row class="caption mt-5 primary--text" style="color: primary;">
    <v-col v-if="!isSmallerThanMD" cols="6" />
    <v-col :cols="isSmallerThanMD ? 12 : 6" class="text-left">
      * This order has items for PWD/SC discount. The displayed price is
      provisional subject to verification of your submitted PWD/SC ID.
    </v-col>
  </v-row>
</template>

<script>
import { isSmallerThanMD } from "@/common/utilities/display";

export default {
  name: "PwdScMessage",

  computed: {
    isSmallerThanMD() {
      return isSmallerThanMD(this.$vuetify.breakpoint.width);
    }
  }
};
</script>
