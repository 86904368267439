import { LOCALE_EN } from "../locale";

import { getFullAddress } from "../location";
import { getScheduleText } from "./schedule_type";

export function getCancelAppointmentText(
  prefix,
  appointment,
  locale = LOCALE_EN
) {
  let scheduleText = getScheduleText(appointment.schedule, locale);
  scheduleText =
    scheduleText.substring(0, 1).toUpperCase() + scheduleText.substring(1);

  return `
${prefix}<br><br>
&nbsp;&nbsp;&nbsp;&nbsp;${appointment.clinicType.toUpperCase()}<br> 
&nbsp;&nbsp;&nbsp;&nbsp;${getFullAddress(appointment.service.location)}<br>
&nbsp;&nbsp;&nbsp;&nbsp;${scheduleText}
`;
}
