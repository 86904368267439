export const CONTACT_LANDLINE = "Landline";
export const CONTACT_PHONE = "Phone";

export const contactTypes = [CONTACT_LANDLINE, CONTACT_PHONE];

export function isLandline(service) {
  return CONTACT_LANDLINE === service;
}

export function isPhone(service) {
  return CONTACT_PHONE === service;
}
