import PhysicalExam from "@/HealthRecords/views/Results/PhysicalExam.vue";
import Fecalysis from "@/HealthRecords/views/Results/Fecalysis.vue";
import Urinalysis from "@/HealthRecords/views/Results/Urinalysis.vue";

// Ignore SonarLint warnings for lines below
//import MobileVanSignup from "@/components/Laboratory/BhcAppointment/MobileVanSignup.vue";

export const ROUTE_PHYSICAL_EXAM = "/physicalexam";
export const ROUTE_FECALYSIS = "/fecalysis";
export const ROUTE_URINALYSIS = "/urinalysis";

export function routes(guard) {
  return [
    {
      path: ROUTE_PHYSICAL_EXAM,
      name: "Physical Exam",
      component: PhysicalExam,
      beforeEnter: guard // Using guard before entering the route
    },

    {
      path: ROUTE_FECALYSIS,
      name: "Fecalysis",
      component: Fecalysis,
      beforeEnter: guard // Using guard before entering the route
    },

    {
      path: ROUTE_URINALYSIS,
      name: "Urinalysis",
      component: Urinalysis,
      beforeEnter: guard // Using guard before entering the route
    }
  ];
}
