<template>
  <div class="ma-5">
    <BackButton @click="routeBack()" class="mb-10" />

    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="6">
        <v-form @submit.prevent="sendEmailReset" ref="form" lazy-validation>
          <v-text-field
            label="Email"
            v-model="email"
            prepend-icon="mdi-email"
            type="text"
            :rules="emailRules"
          />

          <v-btn text type="submit" class="green--text mt-6">
            <v-icon class="mr-2">mdi-wallet-plus</v-icon>
            Send Password Reset Email
          </v-btn>
        </v-form>

        <v-spacer />

        <p v-show="linkSent" class="text-center red--text mt-4">
          The reset password link has been sent to your email.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { routeBack } from "@/router";

import firebase from "firebase/app";
import "firebase/auth";

import BackButton from "@/components/Controls/BackButton.vue";

export default {
  name: "ForgotPassword",

  components: {
    BackButton
  },

  data: () => ({
    email: "",
    emailRules: [
      v => !!v || "Email is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    linkSent: false,

    // functions
    routeBack: routeBack
  }),

  methods: {
    sendEmailReset() {
      if (this.$refs.form.validate()) {
        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .then(() => {
            this.linkSent = true;
          });
      }
    }
  }
};
</script>
