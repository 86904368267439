import { DB, getCurrentUserUid, getCurrentUser } from "@/common/store";
import { db } from "@/main";

import { getDateTimeFromDateAsKey } from "@/common/utilities/date";

// document fields
const RESERVED_BY = "reservedBy";
const CURRENT_VARIANTID = "currentVariant.variantID";
const PRODUCT_SKU = "productSKU";
const STATE = "state";
const TIMESTAMP_UPDATED = "timestampUpdated";
const TRANSACTION_HISTORY = "transactionHistory";

const STATE__AVAILABLE = "available";
export const STATE__RESERVED = "reserved";
export const STATE__SOLD = "sold";

function getTransactionDetails(operation, state) {
  return {
    initiatedByID: getCurrentUserUid(),
    initiatedByName: getCurrentUser(),
    action: operation,
    state: state
  };
}

function availableItem(itemId, timestamp) {
  return new Promise(resolve => {
    let docRef = db.collection(DB.PRODUCTS).doc(itemId);

    db.runTransaction(transaction => {
      return transaction.get(docRef).then(doc => {
        if (!doc.exists) {
          return Promise.reject("Document does not exist!");
        } else {
          let data = doc.data();
          if (data.state && STATE__AVAILABLE === data.state) {
            return Promise.reject();
          } else {
            return transaction.set(
              docRef,
              {
                [RESERVED_BY]: "",
                [STATE]: STATE__AVAILABLE,
                [TIMESTAMP_UPDATED]: timestamp,
                [TRANSACTION_HISTORY]: {
                  [getDateTimeFromDateAsKey()]: {
                    ...getTransactionDetails("cancel", STATE__AVAILABLE)
                  }
                }
              },
              { merge: true }
            );
          }
        }
      });
    })
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });
}

export function getAvailableItems(vproductSku, limit) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PRODUCTS)
      .where(CURRENT_VARIANTID, "==", vproductSku)
      .where(STATE, "==", STATE__AVAILABLE)
      .limit(limit)
      .get()
      .then(snapshot => resolve(snapshot))
      .catch(err => reject(err));
  });
}

export function getCurrentUserReservedItemsCount(productSku) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PRODUCTS)
      .where(PRODUCT_SKU, "==", productSku)
      .where(STATE, "==", STATE__RESERVED)
      .where(RESERVED_BY, "==", getCurrentUserUid())
      .get()
      .then(querySnapshot => resolve(querySnapshot.size))
      .catch(err => reject(err));
  });
}

export function getCurrentUserReservedItemsByVariantCount(variantSku) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PRODUCTS)
      .where(CURRENT_VARIANTID, "==", variantSku)
      .where(STATE, "==", STATE__RESERVED)
      .where(RESERVED_BY, "==", getCurrentUserUid())
      .get()
      .then(querySnapshot => resolve(querySnapshot.size))
      .catch(err => reject(err));
  });
}

export function getReservedItemsCount(productSku) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PRODUCTS)
      .where(PRODUCT_SKU, "==", productSku)
      .where(STATE, "==", STATE__RESERVED)
      .get()
      .then(querySnapshot => resolve(querySnapshot.size))
      .catch(err => reject(err));
  });
}

export function getReservedItemsByVariantCount(variantSku) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PRODUCTS)
      .where(CURRENT_VARIANTID, "==", variantSku)
      .where(STATE, "==", STATE__RESERVED)
      .get()
      .then(querySnapshot => resolve(querySnapshot.size))
      .catch(err => reject(err));
  });
}

function getSoldItems(vproductSku, limit) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PRODUCTS)
      .where(CURRENT_VARIANTID, "==", vproductSku)
      .where(STATE, "==", STATE__SOLD)
      .limit(limit)
      .get()
      .then(snapshot => resolve(snapshot))
      .catch(err => reject(err));
  });
}

export function makeProductsAvailable(variantSku, quantity) {
  getSoldItems(variantSku, quantity).then(snapshot => {
    snapshot.forEach(doc => {
      availableItem(doc.id, new Date());
    });
  });
}

export function reserveItem(itemId, timestamp) {
  return new Promise(resolve => {
    let docRef = db.collection(DB.PRODUCTS).doc(itemId);

    db.runTransaction(transaction => {
      return transaction.get(docRef).then(doc => {
        if (!doc.exists) {
          return Promise.reject("Document does not exist!");
        } else {
          let data = doc.data();
          if (data.state && STATE__RESERVED === data.state) {
            return Promise.reject();
          } else {
            return transaction.set(
              docRef,
              {
                [RESERVED_BY]: getCurrentUserUid(),
                [STATE]: STATE__RESERVED,
                [TIMESTAMP_UPDATED]: timestamp,
                [TRANSACTION_HISTORY]: {
                  [getDateTimeFromDateAsKey()]: {
                    ...getTransactionDetails("proceed cart", STATE__RESERVED)
                  }
                }
              },
              { merge: true }
            );
          }
        }
      });
    })
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });
}

export function soldItem(itemId, timestamp) {
  return new Promise(resolve => {
    let docRef = db.collection(DB.PRODUCTS).doc(itemId);
    db.runTransaction(transaction => {
      return transaction.get(docRef).then(doc => {
        if (!doc.exists) {
          return Promise.reject("Document does not exist!");
        } else {
          let data = doc.data();
          if (data.state && STATE__SOLD === data.state) {
            return Promise.reject();
          } else {
            return transaction.set(
              docRef,
              {
                [STATE]: STATE__SOLD,
                [TIMESTAMP_UPDATED]: timestamp,
                [TRANSACTION_HISTORY]: {
                  [getDateTimeFromDateAsKey()]: {
                    ...getTransactionDetails("checkout", STATE__SOLD)
                  }
                }
              },
              { merge: true }
            );
          }
        }
      });
    })
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });
}
