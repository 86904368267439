<template>
  <v-card class="my-4 pb-4">
    <v-form ref="form" :lazy-validation="false">
      <h4 class="text-center py-4 primary--text">
        Mode of Payment: {{ modeofpayment }}
      </h4>

      <div class="ma-2" v-if="isBantayKard()">
        <v-layout>
          <v-row>
            <v-col cols="3" v-if="!$vuetify.breakpoint.mobile" />
            <v-col
              :cols="cols6Or3"
              class="text-left"
              :class="{ 'pr-0': $vuetify.breakpoint.mobile }"
            >
              Balance
            </v-col>

            <v-col
              cols="2"
              v-if="!$vuetify.breakpoint.mobile"
              class="text-right"
            >
              <PesoAmount :amount="balance" />
            </v-col>
            <v-col
              cols="1"
              v-if="!$vuetify.breakpoint.mobile"
              class="text-right"
            />
            <v-col cols="6" v-else class="text-right">
              <PesoAmount :amount="balance" />
            </v-col>

            <v-col cols="3" v-if="!$vuetify.breakpoint.mobile" />
            <v-col cols="3" v-if="!$vuetify.breakpoint.mobile" />

            <v-col
              :cols="cols6Or3"
              class="text-left"
              :class="{ 'pr-0': $vuetify.breakpoint.mobile }"
            >
              <div v-if="isNotEnoughBalance" class="pa-0">
                Not Enough Balance
              </div>
              <div v-else class="pa-0">Remaining Balance</div>
            </v-col>

            <v-col
              cols="2"
              v-if="!$vuetify.breakpoint.mobile"
              class="text-right"
            >
              <PesoAmount :amount="remainingbalance" />
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 6 : 1"
              class="text-right"
            >
              <v-btn
                x-small
                class="ma-1"
                v-if="isNotEnoughBalance"
                color="primary"
                @click="routeTo(ROUTE_ADD_BANTAYKARD_POINTS)"
              >
                Reload
              </v-btn>
            </v-col>
            <v-col cols="3" v-if="!$vuetify.breakpoint.mobile" />
          </v-row>
        </v-layout>
      </div>

      <div v-else-if="isGCashPayment(modeofpayment)" class="pa-4">
        <v-row>
          <v-col align="center">
            <v-img
              src="@/assets/gcash_medisure_plus_main_b.png"
              alt="GCash scan to pay"
              max-width="400px"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="paymentSpacerCol" />
          <v-col :cols="paymentDescriptionCol" class="pl-3 pr-5 text-left">
            <ol>
              <li>Scan the QR Code above using your GCash app.</li>
              <li>Follow the instructions in the app.</li>
              <li>Type the Reference Number below then click CHECKOUT.</li>
            </ol>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col :cols="referenceNumberCol">
            <v-text-field
              label="Type Reference No."
              v-model="referenceNo"
              class="centered-input"
            />
          </v-col>
        </v-row>
      </div>

      <div v-else-if="isPaymentForValidation(modeofpayment)" class="pa-4">
        <v-row align="center" justify="center">
          <v-col :cols="referenceNumberCol">
            <v-text-field
              label="Type Reference No."
              v-model="referenceNo"
              class="centered-input"
            />
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <v-row>
          <v-col cols="3" v-if="!$vuetify.breakpoint.mobile" />
          <v-col
            :cols="cols6Or3"
            class="text-left pl-6"
            :class="{ 'pr-0': $vuetify.breakpoint.mobile }"
          >
            Amount to Pay<strong> ({{ paymentSuffix }})</strong>
          </v-col>
          <v-col v-if="paymentBalance > 0" :cols="cols6Or3" class="pr-6">
            <PesoAmount :amount="paymentBalance" />
          </v-col>
          <v-col v-else :cols="cols6Or3" class="pr-6">
            <PesoAmount :amount="finTotal" />
          </v-col>
          <v-col cols="3" v-if="!$vuetify.breakpoint.mobile" />
        </v-row>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { ROUTE_ADD_BANTAYKARD_POINTS, routeTo } from "@/router";

import {
  isBantayKardPayment,
  isGCashPayment,
  isPaymentForValidation,
  isPaymentOnSite
} from "@/common/utilities/payment";
import { setWidth } from "@/utilities/display";

import PesoAmount from "@/common/components/PesoAmount.vue";

export default {
  name: "MOPSummary",

  components: {
    PesoAmount
  },

  props: {
    modeofpayment: String,
    balance: Number,
    finTotal: Number,
    paymentBalance: Number
  },

  data: () => ({
    amount: 0,
    referenceNo: "",

    isGCashPayment: isGCashPayment,
    isPaymentForValidation: isPaymentForValidation,
    routeTo: routeTo,

    ROUTE_ADD_BANTAYKARD_POINTS: ROUTE_ADD_BANTAYKARD_POINTS
  }),

  computed: {
    cols6Or3() {
      return this.$vuetify.breakpoint.mobile ? 6 : 3;
    },

    isNotEnoughBalance() {
      return (
        isBantayKardPayment(this.modeofpayment) && this.remainingbalance < 0
      );
    },

    paymentDescriptionCol() {
      return setWidth(this.$vuetify.breakpoint.width, 9, 10, 11, 12, 12);
    },

    paymentSpacerCol() {
      return setWidth(this.$vuetify.breakpoint.width, 3, 2, 1, 12, 12);
    },

    paymentSuffix() {
      return isPaymentOnSite(this.modeofpayment) ? "Payment on Site" : "COD";
    },

    referenceNumberCol() {
      return setWidth(this.$vuetify.breakpoint.width, 3, 3, 5, 7, 8);
    },

    remainingbalance() {
      return Number(this.balance) - Number(this.finTotal); // total getters
    }
  },

  watch: {
    modeofpayment() {
      this.$emit("isValid", this.isValid());
    },

    referenceNo() {
      this.$emit("referenceNoTyping", this.referenceNo);
      this.$emit("isValid", this.isValid());
    }
  },

  methods: {
    isBantayKard() {
      return isBantayKardPayment(this.modeofpayment);
    },

    isValid() {
      return (
        !isPaymentForValidation(this.modeofpayment) || this.referenceNo !== ""
      );
    }
  }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
