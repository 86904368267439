<template>
  <v-card class="pa-10">
    <v-row class="mb-10">{{ $t("lgu.notice") }}</v-row>

    <SelectLocation @update:selectedLocation="selectedLocation" />

    <div v-if="selectedLgu.municipality" class="pa-0 ma-0">
      <v-checkbox v-model="terms" type="checkbox" :label="termsText" />
      <v-checkbox v-model="agreement" type="checkbox" :label="agreementText" />

      <div class="mt-4" style="text-align: center;">
        <v-btn
          :disabled="!isAgreed"
          color="primary"
          @click="startLguValidation"
          type="submit"
        >
          Gusto kong sumali dito
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { DB } from "@/common/store";

import { db } from "@/main";
import { titleCase } from "@/common/utilities/text";

import SelectLocation from "../SelectLocation.vue";

export default {
  name: "SelectLgu",

  components: {
    SelectLocation
  },

  data: () => ({
    selectedLgu: {},

    agreement: false,
    terms: false,

    filter: null
  }),

  mounted() {
    this.getLGULocations().then(filter => {
      this.filter = { ...filter };
    });
  },

  computed: {
    agreementText() {
      return this.$t("lgu.agreement").replace("%s", this.lguLocation);
    },

    lguLocation() {
      let municipality = this.selectedLgu.municipality.startsWith("CITY OF ")
        ? this.selectedLgu.municipality.substr(8)
        : this.selectedLgu.municipality;
      let value = titleCase(`${this.selectedLgu.barangay}, ${municipality}`);
      this.$emit("locationSelected", value);
      return value;
    },

    isAgreed() {
      return this.agreement && this.terms && this.selectedLgu.municipality;
    },

    termsText() {
      return this.$t("lgu.terms").replace("%s", this.lguLocation);
    }
  },

  methods: {
    getLGULocations() {
      return new Promise(resolve => {
        db.collection(DB.LGU_LOCATIONS)
          .get()
          .then(querySnapshot => {
            let locations = {};

            querySnapshot.forEach(doc => {
              locations[doc.id] = doc.data();
            });

            resolve(locations);
          })
          .catch(() => resolve({}));
      });
    },

    selectedLocation(val) {
      this.selectedLgu = val;
    },

    startLguValidation() {
      this.$emit("validationStarted");
    }
  }
};
</script>
